import React, { Component } from "react";
import Loader from "./Loader/Loader";
import { Router, Link } from "react-router-dom";
import Apilib from "../api";
import Notify from "../Notify";

export default class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      success: false,
      email: "",
    };
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      error: "",
      loading: true,
    });
    let API = new Apilib();
    console.log(this.state.email);
    API.forgetPassword(1, this.state.email)
      .then((result) => {
        let res = result.data
        console.log("forgotpwd", res)
        // if (res.ok) {
        this.setState({
          success: true,
          loading: false,
          email: "",
        });
        Notify({
          type: "success",
          alert: true,
          title: "Password Reset Link has been sent to your email",
        });
        this.props.history.push("/Login");
        // }
        // else {
        //   res.json().then((data) => {
        //     this.setState({
        //       loading: false,
        //       email: "",
        //       error: data.code == "auth/internal-error" ? "Email not found" : "Error in sending password reset link",
        //     });
        //   });
        // }
      })
      .catch((error) => {
        console.log("forgotpwd err", error)
        this.setState({
          email: "",
          error: error.response.data.code == "auth/internal-error" ? "Email does not exist" : error.message,
          loading: false,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="account-box my-5 ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h4 className="page-title">Forgot Password</h4>
              </div>
            </div>
            <form onSubmit={(e) => this.onSubmit(e)}>
              <div className="account-logo">
                <a>
                  <img src="assets/img/logo-dark.png" alt="Medvatic" />
                </a>
              </div>
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Please check your email
                </div>
              )}
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Email Address<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="email"
                      required
                      onChange={(e) => this.onChange(e)}
                      type="email"
                      placeholder="Enter Email"
                    />
                  </div>
                  <div class="form-group text-left">
                    <Link className="" to="/Login">
                      Back to login
                    </Link>
                  </div>
                </div>
              </div>
              <div className="m-t-20 text-center">
                <button className="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
