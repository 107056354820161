import React, { Component } from 'react'

export default class EU_GDPR extends Component {
    render() {
        return (
            <section className="section section-padding">
                <div className="section-header2">
                    <h3 className="header-title text-center">PRIVACY POLICY</h3>
                    <div className="line"></div>
                    <div class="policy" >
                    <ol>
                        <li class="policy_heading" >We respect your privacy</li>
                        <ol>
                            <li class="policy_heading">Medvatic respects your right to privacy and is committed to safeguarding the
                                privacy of our customers and website visitors. This policy sets out how we collect
                                and treat your personal information
                            </li>
                            <li class="policy_heading">We adhere to the Australian Privacy Principles contained in the Privacy Act 1988
                                (Cth) and to the extent applicable, the EU General Data Protection Regulation
                                (GDPR).
                            </li>
                            <li class="policy_heading">
                                "Personal information" is information we hold which is identifiable as being about
                                  you. This includes information such as your name, email address, identification
                                  number, or any other type of information that can reasonably identify an
                                  individual, either directly or indirectly.
                            </li>
                            <li class="policy_heading">You may contact us in writing at Level 1, 68 Northbourne Avenue Canberra ACT 2601 for further information about this Privacy Policy.
                            </li>
                        </ol>


                        <li class="policy_heading">What personal information is collected</li>
                        <ol>
                            <li class="policy_heading">Medvatic will, from time to time, receive and store personal information you
                                submit to our website, provided to us directly or given to us in other forms.
                            </li>
                            <li class="policy_heading">You may provide basic information such as your name, phone number, address
                                and email address to enable us to send you information, provide updates and
                                process your product or service order.
                            </li>
                            <li class="policy_heading">We may collect additional information at other times, including but not limited to,
                                when you provide feedback, when you provide information about your personal
                                or business affairs, change your content or email preference, respond to surveys
                                and/or promotions, provide financial or credit card information, or communicate
                                with our customer support.
                            </li>
                            <li class="policy_heading">Additionally, we may also collect any other information you provide while
                                interacting with us.
                            </li>
                        </ol>
                        <li class="policy_heading">How we collect your personal information</li>
                        <ol>
                            <li class="policy_heading">Medvatic collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website and when we engage in business activities with you. We may receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy.
                            </li>
                            <li class="policy_heading">By providing us with personal information, you consent to the supply of that
                                information subject to the terms of this Privacy Policy.
                            </li>
                        </ol>


                        <li class="policy_heading">How we use your personal information</li>
                        <ol>
                            <li class="policy_heading">Medvatic may use personal information collected from you to provide you with information about our products or services. We may also make you aware of
new and additional products, services and opportunities available to you.

                            </li>
                            <li class="policy_heading">Medvatic will use personal information only for the purposes that you consent
                                to. This may include to:
                                <ol type="a">
                                    <li> provide you with products and services during the usual course of our business
                                activities;</li>
                                    <li class="policy_heading">administer our business activities;</li>
                                    <li class="policy_heading">manage, research and develop our products and services;</li>
                                    <li class="policy_heading">provide you with information about our products and services;
                                    </li>
                                    <li class="policy_heading">communicate with you by a variety of measures including, but not limited to, by
                                    telephone, email, sms or mail; and
                                    </li>
                                    <li class="policy_heading">investigate any complaints.
                                    </li>
                                </ol>
                                If you withhold your personal information, it may not be possible for us to provide you
                                with our products and services or for you to fully access our website.
                            </li>
                            <li class="policy_heading">
                                We may disclose your personal information to comply with a legal requirement,
                                such as a law, regulation, court order, subpoena, warrant, legal proceedings or
                                in response to a law enforcement agency request.
                            </li>
                            <li class="policy_heading">If there is a change of control in our business or a sale or transfer of business
                                assets, we reserve the right to transfer to the extent permissible at law our user
                                databases, together with any personal information and non-personal information
                                contained in those databases.
                            </li>
                        </ol>


                        <li class="policy_heading">Disclosure of your personal information</li>
                        <ol>
                            <li class="policy_heading"> Medvatic may disclose your personal information to any of our employees,
                                officers, insurers, professional advisers, agents, suppliers or subcontractors
                                insofar as reasonably necessary for the purposes set out in this privacy policy.
                            </li>
                            <li class="policy_heading">
                                If we do disclose your personal information to a third party, we will protect it in
                                accordance with this privacy policy.
                            </li>
                        </ol>


                        <li class="policy_heading"> General Data Protection Regulation (GDPR) for the European Union (EU)</li>
                        <ol>
                            <li class="policy_heading">Medvatic will comply with the principles of data protection set out in the GDPR
                                for the purpose of fairness, transparency and lawful data collection and use.
                            </li>
                            <li class="policy_heading">
                              We process your personal information as a Processor and/or to the extent that
                              we are a Controller as defined in the GDPR.
                            </li>
                            <li class="policy_heading"> We must establish a lawful basis for processing your personal information. The
                                legal basis for which we collect your personal information depends on the data
                                that we collect and how we use it.
                            </li>
                            <li class="policy_heading">We will only collect your personal information with your express consent for a
                                specific purpose and any data collected will be to the extent necessary and not
                                excessive for its purpose. We will keep your data safe and secure.
                            </li>
                            <li class="policy_heading">We will also process your personal information if it is necessary for our legitimate
                                interests, or to fulfil a contractual or legal obligation.
                            </li>
                            <li class="policy_heading">We process your personal information if it is necessary to protect your life or in a
                                medical situation, it is necessary to carry out a public function, a task of public
                                interest or if the function has a clear basis in law
                            </li>
                            <li class="policy_heading">We do not collect or process any personal information from you that is
                                considered "Sensitive Personal Information" under the GDPR, such as personal
                                information relating to your sexual orientation or ethnic origin unless we have
                                obtained your explicit consent, or if it is being collected subject to and in
                                accordance with the GDPR.
                            </li>
                            <li class="policy_heading">You must not provide us with your personal information if you are under the age
                                of 16 without the consent of your parent or someone who has parental authority
                                for you. We do not knowingly collect or process the personal information of
                                children.
                            </li>
                        </ol>


                        <li class="policy_heading">Your rights under the GDPR</li>
                        <ol>
                            <li class="policy_heading">If you are an individual residing in the EU, you have certain rights as to how your
                                personal information is obtained and used. Medvatic complies with your rights
                                under the GDPR as to how your personal information is used and controlled if
                                you are an individual residing in the EU
                            </li>
                            <li class="policy_heading">Except as otherwise provided in the GDPR, you have the following rights:
                                <ol type="a">
                                    <li class="policy_heading"> to be informed how your personal information is being used;</li>
                                    <li class="policy_heading">access your personal information (we will provide you with a free copy of it);</li>
                                    <li class="policy_heading">to correct your personal information if it is inaccurate or incomplete;</li>
                                    <li class="policy_heading"> to delete your personal information (also known as "the right to be forgotten");</li>
                                    <li class="policy_heading">to restrict processing of your personal information;</li>
                                    <li class="policy_heading">to retain and reuse your personal information for your own purposes;</li>
                                    <li class="policy_heading">to object to your personal information being used; and</li>
                                    <li class="policy_heading">to object against automated decision making and profiling.</li>
                                </ol>
                            </li>
                                    <li class="policy_heading">Please contact us at any time to exercise your rights under the GDPR at the
                                    contact details in this Privacy Policy.</li>
                                    <li class="policy_heading">We may ask you to verify your identity before acting on any of your requests.
                                    </li>
                        </ol>


                        <li class="policy_heading">Hosting and International Data Transfers</li>
                        <ol>
                            <li class="policy_heading">Information that we collect may from time to time be stored, processed in or
                                transferred between parties or sites located in countries outside of Australia.
                                These may include, but are not limited to Australia, USA, Singapore, Israel, India.
                            </li>
                            <li class="policy_heading">We and our other group companies have offices and/or facilities in Australia ,
                                USA,Singapore, Israel, India. Transfers to each of these countries will be protected by
                                appropriate safeguards, these include one or more of the following: the use of
                                standard data protection clauses adopted or approved by the European
                                Commission which you can obtain from the European Commission Website; the
                                use of binding corporate rules, a copy of which you can obtain from
                                Medvatic's Data Protection Officer.
                            </li>
                            <li class="policy_heading">The hosting facilities for our website are situated in Australia, USA, Singapore,
                                Israel, India. Transfers to each of these Countries will be protected by appropriate
                                safeguards, these include one or more of the following: the use of standard data
                                protection clauses adopted or approved by the European Commission which you
                                can obtain from the European Commission Website; the use of binding corporate
                                rules, a copy of which you can obtain from Medvatic's Data Protection Officer.
                            </li>
                            <li class="policy_heading">Our Suppliers and Contractors are situated in Australia, USA, Singapore, Israel, India.
                                Transfers to each of these Countries will be protected by appropriate
                                safeguards, these include one or more of the following: the use of standard data
                                protection clauses adopted or approved by the European Commission which you
                                can obtain from the European Commission Website; the use of binding corporate
                                rules, a copy of which you can obtain from Medvatic's Data Protection Officer.
                            </li>
                            <li class="policy_heading">You acknowledge that personal data that you submit for publication through our
                                website or services may be available, via the internet, around the world. We
                                cannot prevent the use (or misuse) of such personal data by others.
                            </li>
                        </ol>


                        <li class="policy_heading">Security of your personal information</li>
                        <ol>
                            <li class="policy_heading">Medvatic is committed to ensuring that the information you provide to us is
                                secure. In order to prevent unauthorised access or disclosure, we have put in
                                place suitable physical, electronic and managerial procedures to safeguard and
                                secure information and protect it from misuse, interference, loss and
                                unauthorised access, modification and disclosure.
                            </li>
                            <li class="policy_heading">Where we employ data processors to process personal information on our
                                behalf, we only do so on the basis that such data processors comply with the
                                requirements under the GDPR and that have adequate technical measures in
                                place to protect personal information against unauthorised use, loss and theft.
                            </li>
                            <li class="policy_heading">The transmission and exchange of information is carried out at your own risk.
                                We cannot guarantee the security of any information that you transmit to us, or
                                receive from us. Although we take measures to safeguard against unauthorised
                                disclosures of information, we cannot assure you that personal information that
                                we collect will not be disclosed in a manner that is inconsistent with this Privacy
                                Policy.
                            </li>
                        </ol>


                        <li class="policy_heading"> Access to your personal information</li>
                        <ol>
                            <li class="policy_heading">You may request details of personal information that we hold about you in
                                accordance with the provisions of the Privacy Act 1988 (Cth), and to the extent
                                applicable the EU GDPR. If you would like a copy of the information which we
                                hold about you or believe that any information we hold on you is inaccurate, out
                                of date, incomplete, irrelevant or misleading, please email us at
                                info@medvatic.com.au.
                            </li>
                            <li class="policy_heading">We reserve the right to refuse to provide you with information that we hold about
                                you, in certain circumstances set out in the Privacy Act or any other applicable
                                law.
                            </li>
                        </ol>


                        <li class="policy_heading">Complaints about privacy</li>
                            <ol>
                                <li class="policy_heading">
                                    If you have any complaints about our privacy practices, please feel free to send
                                    in details of your complaints to info@medvatic.com.au. We take complaints
                                    very seriously and will respond shortly after receiving written notice of your
                                    complaint.
                                </li>
                            </ol>


                        <li class="policy_heading">Changes to Privacy Policy</li>
                        <ol>
                            <li class="policy_heading">
                                Please be aware that we may change this Privacy Policy in the future. We may
                                modify this Policy at any time, in our sole discretion and all modifications will be
                                19th December 2018
                                effective immediately upon our posting of the modifications on our website or
                                notice board. Please check back from time to time to review our Privacy Policy.
                            </li>
                        </ol>



                        <li class="policy_heading"> Website</li>
                            <ol>
                                <li class="policy_heading">When you visit our website
                                    When you come to our website (www.medvatic.com) we may collect certain
                                    information such as browser type, operating system, website visited immediately
                                    before coming to our site, etc. This information is used in an aggregated manner
                                    to analyse how people use our site, such that we can improve our service.
                                </li>
                                <li class="policy_heading">
                                    When you visit our website
                                    When you come to our website (www.medvatic.com) we may collect certain
                                    information such as browser type, operating system, website visited immediately
                                    before coming to our site, etc. This information is used in an aggregated manner
                                    to analyse how people use our site, such that we can improve our service.
                                </li>
                                <li class="policy_heading">Third party sites
                                    Our site may from time to time have links to other websites not owned or
                                    controlled by us. These links are meant for your convenience only. Links to third
                                    party websites do not constitute sponsorship or endorsement or approval of
                                    these websites. Please be aware that Medvatic is not responsible for the
                                    privacy practises of other such websites. We encourage our users to be aware,
                                    when they leave our website, to read the privacy statements of each and every
                                    website that collects personal identifiable information.
                                </li>
                            </ol>
                            This Data Privacy Policy was last updated on December 12, 2020.
                    </ol>
                </div>
                </div>

            </section>
        )
    }
}
