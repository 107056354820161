import React, { useState } from 'react';
import message from '../../assets/img/message.png'
import call from '../../assets/img/call.png'
import blood_cells from '../../assets/img/blood-cells.png'
import blood_saver from '../../assets/img/blood-saver.png'
import glucose from '../../assets/img/glucose.png'
import blood_pressure from '../../assets/img/blood-pressure.png'
import DatePicker from "react-horizontal-datepicker";
import { CheckBox } from '@material-ui/icons';
import { Switch } from '@material-ui/core';
import Apilib from "../../api";


const RenderMedicines = ({ title, medicines }) => <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
    {title}

    {medicines.map((x) => {
        return <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center', margin: '3px' }}>
            <div style={{ width: '90%' }}>
                {x.name}
                <div>{x.meal}</div>
            </div>
            <div style={{ width: '10%' }}>
                <img
                    src={x.img}
                    alt="new"
                    style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                />
            </div>
        </div>
    })}
</div>


const DrugScheduling = () => {

    const [selectDay, setselectDay] = useState([false, false, false, false, false, false, true])
    const [tselectDay, settselectDay] = useState(false)
    const [selectByDay, setselectByDay] = useState(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])
    const [todays, settodays] = useState({
        morning: [{ name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }, { name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }], afternoon: [{ name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }, { name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }], evening: [{ name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }, { name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }], night: [{ name: 'Pantronix 20 - tab', meal: 'Before meal', img: blood_cells }]
    })
    const selectedDay = val => {
        console.log(val);
    };
    const [checked, setChecked] = useState(false)

    const schedulingDetailing = { patient_name: 'ramesh', patientAge: '39', patientWeight: '70kg', hh: '130/20', email: 'ram.interior1@gmail.com', mobile: '+918565856523', addrs: 'rohili,barmer,raj', glucoseLevel: '88-75', bloodCoount: '9500/ml', hemoglobin: '15.5mg/dl', schedule: [], medicines: [] }

    return (
        <div style={{ backgroundColor: '#EAFCFF', height: '100%', width: '100%' }}>
            <div style={{ padding: '10px', borderRadius: '10px', margin: '10px', backgroundColor: '#fff' }}>
                Drug scheduling
            </div>
            <div style={{ height: '100%', width: '100%', backgroundColor: '#fff', display: 'flex', borderRadius: '10px', border: 'solid 1px #fcfcfc' }}>

                <div style={{ height: '100%', width: '50%', backgroundColor: '#f2fdff', borderRadius: '10px', border: 'solid 1px #ccc' }}>

                    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
                        <div>
                            <img
                                src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE5fPhctwNLodS9VmAniEw_UiLWHgKs0fs1w&usqp=CAU'}
                                alt="new"
                                style={{ width: '45px', height: '45px', margin: '5px', borderRadius: '50px' }}
                            />
                        </div>
                        <div style={{ width: '60%', }}>

                            <div >
                                patient Name
                            </div>
                            <div >
                                William Johnson
                            </div>
                        </div>
                        <div>
                            <img
                                src={call}
                                alt="new"
                                style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                            />
                            <img
                                src={message}
                                alt="new"
                                style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                            />
                        </div>
                    </div>
                    <div style={{ marginLeft: '55px' }}>
                        27 years
                    </div>
                    <div style={{ marginLeft: '55px' }}>
                        info@medvatic.com
                    </div>
                    <div style={{ marginLeft: '55px' }}>
                        74 parks road,Mitchell, Australia
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                        <div style={{ width: '49%' }}>
                            <img
                                src={blood_pressure}
                                alt="new"
                                style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                            /> Blood Pressure
                        </div>
                        <div style={{ width: '49%' }}>
                            <img
                                src={glucose}
                                alt="new"
                                style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                            /> Glucose Level
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ width: '49%' }}>
                            <img
                                src={blood_saver}
                                alt="new"
                                style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                            /> Blood Count
                        </div>
                        <div style={{ width: '49%' }}>
                            <img
                                src={blood_cells}
                                alt="new"
                                style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                            /> Hemoglobin
                        </div>

                    </div>

                </div>


                {/* <div style={{}}> */}

                <div style={{ width: '50%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                Reminders
                            </div>
                            <div style={{ backgroundColor: '#00a1ba', borderRadius: '5px', color: '#fff', paddingRight: '10px', paddingLeft: '10px' }}>
                                set Reminder
                                {/* <img
                                    src={call}
                                    alt="new"
                                    style={{ width: '15px', height: '15px', margin: '5px', borderRadius: '3px' }}
                                /> */}
                                <i className={`fa fa-bell-o`} style={{ fontSize: '14px', color: '#fff' }}></i>
                            </div>
                        </div>
                        <div style={{ height: '80px', width: '100%' }}>
                            <DatePicker getSelectedDay={selectedDay}
                                // endDate={17}
                                selectDate={new Date("2020-04-30")}
                                labelFormat={"MMMM"}
                                color={"#374e8c"}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'space-between', alignItems: 'center' }}>
                        Notification   <Switch

                            color='#ccc'
                            checked={checked}
                            onClick={() => setChecked(!checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        // type="checkbox"
                        // checked={false}
                        // onClick={(e) => setIsOn(e.target.val)}
                        />
                    </div>
                    <div style={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'space-between', }}>
                        {

                            selectByDay.map((x, index) => {
                                const element = selectByDay[index];
                                console.log(element)
                                return <div style={{ padding: '18px', backgroundColor: '#f2fdff', borderRadius: '10px' }} onClick={() => {

                                    console.log('clicked', index, x, selectByDay[index], selectDay)
                                    let tempArr = [...selectDay]
                                    tempArr[index] = !tempArr[index]
                                    setselectDay(tempArr)
                                    settselectDay(x)
                                    // setselectByDay(selectByDay[index])
                                }} >
                                    {x}<br />
                                    <input type='checkbox'
                                        // x == selectByDay[index]
                                        checked={selectDay[index]}
                                        style={{ borderWidth: '5px', borderRadius: '10px', borderRadius: '10px' }}
                                    // onChange={() => {

                                    //     console.log('clicked', index, x, selectByDay[index], selectDay)
                                    //     let tempArr = [...selectDay]
                                    //     tempArr[index] = !tempArr[index]
                                    //     setselectDay(tempArr)
                                    //     settselectDay(x)
                                    //     // setselectByDay(selectByDay[index])
                                    // }}
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>

            </div>

            <div style={{ height: '100%', width: '100%', backgroundColor: '#fff', display: 'flex', borderRadius: '10px', border: 'solid 1px #fcfcfc' }}>

                <div style={{ height: '100%', width: '50%', backgroundColor: '#f2fdff', borderRadius: '10px', border: 'solid 1px #ccc', }}>
                    <div style={{ alignItems: 'center' }}>

                        Today's Activities
                    </div>
                    <hr />
                    {/* <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Morning

                        {todays.morning.map((x) => {
                            return <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                                <div style={{ width: '90%' }}>
                                    {x.name}
                                    <div>{x.meal}</div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <img
                                        src={x.img}
                                        alt="new"
                                        style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                    />
                                </div>
                            </div>
                        })}
                    </div> */}

                    <RenderMedicines title={'Morning'} medicines={todays.morning} />
                    <RenderMedicines title={'Afternoon'} medicines={todays.afternoon} />
                    <RenderMedicines title={'Evening'} medicines={todays.evening} />
                    <RenderMedicines title={'Night'} medicines={todays.night} />

                    {/* 
                    <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Afternoon


                        {todays.afternoon.map((x) => {
                            return <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                                <div style={{ width: '90%' }}>
                                    {x.name}
                                    <div>{x.meal}</div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <img
                                        src={x.img}
                                        alt="new"
                                        style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                    />
                                </div>
                            </div>
                        })}
                    </div>
                    <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Evening

                        {todays.evening.map((x) => {
                            return <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                                <div style={{ width: '90%' }}>
                                    {x.name}
                                    <div>{x.meal}</div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <img
                                        src={x.img}
                                        alt="new"
                                        style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                    />
                                </div>
                            </div>
                        })}
                    </div>
                    <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Night

                        {todays?.night?.map((x) => {
                            return <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                                <div style={{ width: '90%' }}>
                                    {x.name}
                                    <div>{x.meal}</div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <img
                                        src={x.img}
                                        alt="new"
                                        style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                    />
                                </div>
                            </div>
                        })}
                    </div> */}
                    {/* <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Morning
                        <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                            <div style={{ width: '90%' }}>
                                Pantronix 20-tab
                                <div>Before meal</div>
                            </div>
                            <div style={{ width: '10%' }}>
                                <img
                                    src={blood_cells}
                                    alt="new"
                                    style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                />
                            </div>
                        </div>
                        <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                            <div style={{ width: '90%' }}>
                                Pantronix 20-tab
                                <div>Before meal</div>
                            </div>
                            <div style={{ width: '10%' }}>
                                <img
                                    src={blood_cells}
                                    alt="new"
                                    style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                />
                            </div>
                        </div>

                    </div> */}
                    {/* <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Afternoon

                        <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #27c0d8', display: 'flex', borderRadius: '3px', alignItems: 'center' }}>
                            <div style={{ width: '90%' }}>
                                Pantronix 20-tab
                                <div>Before meal</div>
                            </div>
                            <div style={{ width: '10%' }}>
                                <img
                                    src={blood_cells}
                                    alt="new"
                                    style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Evening

                        <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #000', display: 'flex', alignItems: 'center', }}>
                            <div style={{ width: '90%' }}>
                                Pantronix 20-tab
                                <div>Before meal</div>
                            </div>
                            <div style={{ width: '10%' }}>
                                <img
                                    src={blood_cells}
                                    alt="new"
                                    style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* <div style={{ margin: '5px', padding: '10px', fontSize: '18px' }}>
                        Night

                        <div style={{ border: 'solid .5px #ccc', borderLeft: 'solid 3px #000', display: 'flex', alignItems: 'center', }}>
                            <div style={{ width: '90%' }}>
                                Pantronix 20-tab
                                <div>Before meal</div>
                            </div>
                            <div style={{ width: '10%' }}>
                                <img
                                    src={blood_cells}
                                    alt="new"
                                    style={{ width: '30px', height: '30px', margin: '5px', borderRadius: '3px' }}
                                />
                            </div>
                        </div>

                    </div> */}

                </div>
                <div style={{ width: '50%' }}>
                    <div style={{ width: '100%', backgroundColor: '#3dcce2', borderRadius: '10px', border: 'solid 1px #ccc', color: '#fff', display: 'flex', justifyContent: 'space-evenly', padding: '15px', margin: '5px', }}>
                        <div style={{ width: '30%', textAlign: 'center' }}>Medicine</div>
                        <div style={{ width: '15%', textAlign: 'center' }}>Total No. of dose</div>
                        <div style={{ width: '15%', textAlign: 'center' }}>Dose Complete</div>
                        <div style={{ width: '15%', textAlign: 'center' }}>Dose Left</div>
                        <div style={{ width: '15%', textAlign: 'center' }}>Dose Time</div>

                    </div>

                    {[10, 20, 30, 40].map(x => {
                        return <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: '10px', border: 'solid 1px #ccc', color: '#000', display: 'flex', justifyContent: 'space-evenly', padding: '15px', marginRight: '4%', marginLeft: '5%', alignItems: 'center', margin: '5px' }}>
                            <div style={{ width: '30%', textAlign: 'center' }}>paracetamol 20</div>
                            <div style={{ width: '14%', textAlign: 'center' }}>10</div>
                            <div style={{ width: '13%', textAlign: 'center' }}>10</div>
                            <div style={{ width: '13%', textAlign: 'center' }}>15</div>
                            <div style={{ width: '20%', textAlign: 'center', fontSize: '11px' }}>Morning 9 am</div>

                        </div>
                    }
                    )}

                </div>

            </div>
        </div >
    );
}

export default DrugScheduling;
