// jshint esversion:6

import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { Link, Route, useParams, useHistory } from "react-router-dom";
import { storage } from "../../firebase";
// import "./style.css";
import Axios from "axios";
import { useStateValue } from "../../StateProvider";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Apilib from "../../api";
import cart from '../../assets/img/cart.png'

const SearchMedicine = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchQuery, setSearchQuery] = useState();
    const [{ lat, long }, dispatch] = useStateValue();
    const [noResults, setNoResults] = useState(false);
    const [pp_url, setpp_url] = useState("");
    const [geoLocation, setGeoLocation] = useState();
    const [cartItemsLength, setcartItemsLength] = useState(0)
    const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData'))?.length > 0 ? JSON.parse(localStorage.getItem('cartData')) : [])
    useEffect(() => {
        console.log('useeffect executed')
        setcartItemsLength(cartItems.length)
    }, [cartItems]);


    if (!props.location.search) {
        props.history.push("/");
    }

    useEffect(() => {

        if (lat && long) {
            const urlParams = new URLSearchParams(props.location.search);
            const search = urlParams.get("search");
            setSearchQuery(search);
        }
    }, [lat, long, props.location.search]);


    useEffect(() => {
        if (searchQuery) {
            console.log(searchQuery, 'searchquery')
            setNoResults(false);
            setLoading(true);
            let API = new Apilib();

            API.getMedicines({})
                .then(async (res) => {
                    console.log(res)
                    console.log('this is response')
                    console.log('this is response', res.data.medicines.medicines)

                    let dataAPI = [];
                    dataAPI = res.data.result;
                    setLoading(false);
                    let data = []
                    res.data.medicines.medicines.map(x => {
                        if (x.Medicine_Name.toLowerCase() == searchQuery.toLowerCase()) data.push(x)
                    })
                    if (data.length === 0) {
                        setSearchResult([]);
                        setNoResults(true);

                    } else {
                        if (noResults) {
                            setSearchResult([]);
                        }
                        setSearchResult(data);
                    }
                })
                .catch((err) => {
                    setLoading(false);

                    setSearchResult([]);
                    setNoResults(false);
                });

            // Axios.get(
            //     "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/searchForAppointment?name=" + searchQuery + "&lat=" + lat + "&longitude=" + long
            // )
            //     .then((res) => {

            //         let dataAPI = [];
            //         dataAPI = res.data.result;
            //         setLoading(false);
            //         if (dataAPI.length === 0) {
            //             setSearchResult([]);
            //             setNoResults(true);

            //         } else {
            //             if (noResults) {
            //                 setSearchResult([]);
            //             }
            //             setSearchResult(dataAPI);
            //         }
            //     })
            //     .catch((err) => {
            //         setLoading(false);

            //         setSearchResult([]);
            //         setNoResults(false);
            //     });
        }
    }, [searchQuery]);

    // async function makeDownloadUrl(id, type) {
    //   let imageUrl = null;
    //   
    //   let userType;
    //   if (type == 5) {
    //     userType = "patients";
    //   } else if (type == 3) {
    //     userType = "doctors";
    //   } else if (type == 4) {
    //     userType = "receptionist";
    //   }
    //   const path = `profile_images/Fdoctors/${id}`;
    //   let gsReference = storage.refFromURL(
    //     "gs://medvatic-438af.appspot.com/" + path
    //   );
    //   await gsReference
    //     .getDownloadURL()
    //     .then((url) => {
    //       console.log("chat user img", url);
    //       imageUrl = url
    //       setpp_url(url);
    //     })
    //     .catch((err) => {
    //       console.log("chat user err", err);
    //     });
    //   if (imageUrl === null) return null
    //   return imageUrl
    // }

    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    // const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData')).length == 0 ? null : JSON.parse(localStorage.getItem('cartData')))

    function itemExist(arr, arr1) {
        const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);

        return found;
    }

    const settingcart = (data) => {
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        data.qty = 1
        if (itemnow == -1) pr.push(data)
        else pr[itemnow].qty += 1
        console.log(itemnow, 'itemnow herer')

        setcartItems(pr)

        localStorage.setItem('cartData', JSON.stringify(pr));

    }


    return (
        <>
            <div className="container">
                {/* <div className="search-bar">
          <>
            <div className="location-bar">
              <GoogleMap
                style={{ width: 100 }}
                value={geoLocation}
                onChange={(e) => {}}
                getAddress={(address) => {
                  setGeoLocation(address);
                }}
              />
            </div>
            <div>
              <SearchBar />
            </div>
          </>
        </div> */}
            </div>
            <div className="main-content account-content">
                <script src="https://use.fontawesome.com/cee0220b65.js"></script>
                {loading ? <Loader /> : null}
                <div className="content">
                    <div style={{ textAlign: 'center', fontWeight: '600', fontSize: '28px', padding: '10px' }}>Search Result</div>
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-12 appointment-cards">
                                    {noResults && <h3>No search result found!</h3>}
                                    {searchResult.length !== 0 ? (
                                        searchResult.map((item) => {
                                            let imageUrl = item.imageLink

                                            // let imageUrl = await makeDownloadUrl(item.uid, item.type);
                                            return (
                                                <div className="card mb-3" key={Math.random()}>
                                                    <div className="row">
                                                        <div className="date col-md-2">
                                                            {/* <p>Doctor</p>
                              <img src="../../../public/assets/img/Dr_drake_ramoray-.jpg" /> */}
                                                            <img
                                                                className="img-fluid"
                                                                alt=""
                                                                src={
                                                                    imageUrl !== null && imageUrl !== undefined
                                                                        ? imageUrl
                                                                        : '../../assets/img/medical-prescription1.png'

                                                                }
                                                            ></img>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="card-body">
                                                                <h5 className="card-text speciality">
                                                                    {item.type}
                                                                </h5>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                    className="head-card"
                                                                >
                                                                    <h5 className="card-title2 m-b-10">
                                                                        {item.Medicine_Name}
                                                                    </h5>

                                                                </div>

                                                                <div>Price : {item.Price}</div>
                                                                <div onClick={() => {
                                                                    settingcart(item)
                                                                    console.log(cartItems)
                                                                }} ><img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} /> add to cart
                                                                </div>
                                                                {/* <p className="card-text speciality">
                                  ₹1800 Consultation fee at clinic
                                </p> */}
                                                                {/* <hr />
                                <div className="card-text ">
                                  <p className="card-end">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit longer.
                                  </p>
                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : noResults ? null : (
                                        <h3>No search result found!</h3>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<div className="ChatSupport">
                <div className="ChatSupport__container">
                    {cartItemsLength && <button className="ChatSupport__cta-btn" onClick={async () => {

                        console.log('yes the cafrt', cartItems.length)
                        // history.push({ pathName: "/Order", state: { cart: cartItems } })

                        localStorage.setItem('cartData', JSON.stringify(cartItems));
                        const items = JSON.parse(localStorage.getItem('cartData'));
                        console.log(items, 'this i s otems')
                        history.push({
                            pathname: "/EditCart",
                            state: { cart: cartItems },
                        });



                    }} style={{ position: 'fixed', top: '13%' }}>
                        {/* <WhatsAppIcon /> &nbsp; Hi! How can I help? */}
                        Checkout {cartItemsLength} items

                    </button>}
                </div>
            </div >}
        </>
    );
};

export default SearchMedicine;
