import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import BaseSelect from "react-select/creatable";
import RequiredSelect from "../../Helpers/requiredSelect";
import { Link } from "react-router-dom";
import csc from "country-state-city";
import { useStateValue } from "../../StateProvider";
import Notify from "../../Notify";
import Noimg from "../../assets/img/Noimg.png";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

function Edit_profileR(props) {
  const profilePictureRef = React.createRef();

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);
  const [change_pp, setChange_pp] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPharmacies, setAllPharmacies] = useState([]);
  const [preferredPharmacies, setPreferredPharmacies] = useState([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [pp_url, setpp_url] = useState();
  const [country_id, setCountry_id] = useState("");
  const [state_id, setState_id] = useState("");
  const [city_id, setCity_id] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [preferredPharmacyId, setPreferredPharmacyId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [statePharmacies, setStatePharmacies] = useState([]);
  const [{ userPP }, dispatch] = useStateValue();

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      phoneNo: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "First Name should contain alphabets and may contain '"
        )
        .label("First Name"),

      lastName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Last Name should contain alphabets and may contain '"
        )
        .label("Last Name"),
    }),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits'")
      .min(8)
      
      .label("Emergency Contact no"),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 5));
    },
  });

  const makeDownloadUrl = async (path) => {
    setLoading(true);
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    try {
      const url_1 = await gsReference.getDownloadURL();
      console.log(url_1);
      setpp_url(url_1);
      dispatch({
        type: "SET_USER_PP",
        url: url_1,
      });
      setChange_pp(false);
      setLoading(false);
    } catch (err) {
      setChange_pp(true);
      setLoading(false);
      Notify({
        alert: true,
        type: "error",
        title: err.message,
      });
    }
  };

  useEffect(() => {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(true);
        API.getUserDetails(user.displayName, user.uid)
                .then((result) => {
        let res = result.data

            console.log("ressss", res.user);
            setLoading(false);
            setUserDetails(res.user);
            // props.updateUserData(res.user);
            if (res.user.profile_picture) {
              console.log("Make ")
              makeDownloadUrl(res.user.profile_picture);
            }
            console.log("response1",res.user.profile_picture,res.user)
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    });
  }, []);

  let button = change_pp ? (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleUpload(e);
      }}
      className="btn custom-btn text-white"
      style={{ backgroundColor: "#31d4eb" }}
    >
      Save Image
    </button>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();
        setChange_pp(true);
      }}
      className="btn custom-btn btn-primary"
    >
      Change Profile Pic
    </button>
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (!formik.isValid) return;
    setError("");
    setLoading(true);

    let API = new Apilib();
    let data = {
      ...userDetails,
      type: 4,
      uid: props.user.uid,
    };
    delete data.loading;
    delete data.error;
    delete data.success;
    API.updateUser(JSON.stringify(data))
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setLoading(false);
          setUserDetails(null);
          setError("");
          setSuccess(true);
          props.history.push("/my-profile");
        } else {
          res.text().then((data) => {
            setError(JSON.parse(data).message);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };
  const onChange = (e) => {
    let data = { ...userDetails };
    // for date, e will be directly the date value, thus e.target will be undefined. PS. sorry for bad code.
    let inputName = e.target.name;
    let value = "";

    switch (
      inputName

      // no default
    ) {
    }
    if (
      inputName === "country" ||
      inputName === "State" ||
      inputName === "city" ||
      inputName === "preferredPharmacy"
    ) {
      data[inputName] = value;
    } else if (inputName === "phoneNo") {
      data[inputName] = e.target.value;
    } else if (inputName === "email") {
      data[inputName] = e.target.value;
    } else {
      data[inputName] = e.target.value;
    }
    setUserDetails(data);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl(1);
    if (PP.state.status == "EMPTY") {
      return;
    }
    console.log("file", PP);

    const ref = storage.ref();
    if (imageAsDataURL) {
      const name = userDetails.uid;
      const path = "profile_images/receptionists/" + name;
      setpp_url(imageAsDataURL);
      const task = ref.child(path).putString(imageAsDataURL, "data_url");
      task
        .then(() => {
          setLoading(true);
          setError("");
          let API = new Apilib();
          let data = {
            ...userDetails,
            type: 4,
            uid: props.user.uid,
            profile_picture: path,
          };
          API.updateUser(JSON.stringify(data))
            .then(async (res) => {
              setLoading(false);

              if (res.ok) {
                setLoading(false);
                setChange_pp(false);
                await makeDownloadUrl(path);
                Notify({
                  alert: true,
                  type: "success",
                  title: "Profile picture uploaded successfully!",
                });
              } else {
                res.text().then((data) => {
                  setError(JSON.parse(data).message);
                  setChange_pp(false);
                  Notify({
                    alert: true,
                    type: "error",
                    title: JSON.parse(data).message,
                  });
                });
              }
            })
            .catch((error) => {
              setError(error.message);
              setChange_pp(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
          setChange_pp(false);
          Notify({
            alert: true,
            type: "error",
            title: err.message,
          });
        });
    }
  };

  console.log("PP-URL",pp_url)

  useEffect(() => {
    if (userDetails)
      formik.setValues({
        firstName: userDetails.firstName,
        mobile: userDetails.mobile,
        lastName: userDetails.lastName,
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-header profile-header">
        <div className="content profile-content edit-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="page-title about-doctor">Edit Profile</h4>
              </div>
            </div>
            {userDetails ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    onSubmit={(e) => {
                      if (
                        document.activeElement.classList.contains(
                          "eXEoGG26iwSi6UiZD8FI6"
                        )
                      ) {
                        e.preventDefault();
                        return;
                      }
                      onSubmit(e);
                    }}
                  >
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        {error}
                      </div>
                    )}
                    {success && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        Profile Updated successfully
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12 row p-0 m-0">
                        <div className="col-sm-3">
                          <div
                            className="form-group"
                            style={{ textAlign: "center" }}
                          >
                            {change_pp ? (
                              <ProfilePicture
                                minImageSize={0}
                                ref={profilePictureRef}
                                useHelper={true}
                                frameFormat="rounded-square"
                                image={pp_url !== "" ? pp_url : Noimg}
                              />
                            ) : (
                              <img className="Profile_pic" src={pp_url !== "" ? pp_url : Noimg}  />
                            )}
                            {button}
                          </div>
                        </div>
                        <div className="col-sm-9 row pr-0 m-0">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="firstName"
                                value={userDetails.firstName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "firstName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.firstName}
                              </small>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <input
                                name="lastName"
                                value={userDetails.lastName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "lastName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.lastName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Mobile Number</label>
                              <input
                                name="mobile"
                                value={userDetails.mobile}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "mobile",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                className="form-control"
                                type="phone"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.mobile}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                name="email"
                                readOnly
                                value={userDetails.email}
                                onChange={(e) => onChange(e)}
                                disabled
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-t-20 text-center">
                      <Link to="/my-profile">
                        <button className="btn custom-btn btn-secondary mr-3">
                          Close
                        </button>
                      </Link>
                      <button
                        className="btn custom-btn btn-primary"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Edit_profileR;
