// jshint esversion:6

import "bootstrap/dist/js/bootstrap.js";
import React, { Component, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactGA from "react-ga";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import AddHospital from "./Components/Admin/AddHospital";
import Plugin from "./Components/Admin/Plugin";
import AddHospitalChain from "./Components/Admin/AddHospitalChain";
import AddSpeciality from "./Components/Admin/AddSpeciality";
import AddUser from "./Components/Admin/AddUser";
import EditHospital from "./Components/Admin/EditHospital";

/** Components Admin **/
import HospitalList from "./Components/Admin/HospitalList";
import UsersList from "./Components/Admin/UsersList";
import AgoraUi from "./Components/Agora/Agora_UI";
import RouteChangeTracker from "./Components/Analytics/RouteChangeTracker";
import ChangePassword from "./Components/ChangePassword";
import Comp404 from "./Components/comp404";
import AddAppointment from "./Components/Doctor/AddAppointment";
import AddPatient from "./Components/Doctor/AddPatient";
import AddSchedule from "./Components/Doctor/AddSchedule";
import AppointmentsList from "./Components/Doctor/AppointmentsList";
import DoctorRegister from "./Components/Doctor/DoctorRegister";
import EditAppointment from "./Components/Doctor/EditAppointment";
import EditPatient from "./Components/Doctor/EditPatient";
import PatientSingleAppointments from "./Components/Doctor/PatientAppointments";
/**Components Doctor **/
import PatientsList from "./Components/Doctor/PatientsList";
import ForgetPassword from "./Components/ForgetPassword";
import AddDoctor from "./Components/Hospital/AddDoctor";
import AddReceptionist from "./Components/Hospital/AddReceptionist";
import ProfileD from "./Components/Doctor/Profile";
import EditProfileD from "./Components/Doctor/Edit_profile";
/** Components Hospital **/
import DoctorsList from "./Components/Hospital/DoctorsList";
import EditProfileHospital from "./Components/Hospital/EditProfileHospital";
import ReceptionistsList from "./Components/Hospital/ReceptionistsList";
import ViewRequests from "./Components/Hospital/ViewRequests";
import Patient from "./Components/Hospital/Patient";
import ViewPatient from "./Components/Hospital/ViewPatient";
import AppointmentsListH from "./Components/Hospital/AppointmentsList";
import EditAppoinmentH from "./Components/Hospital/EditAppoinment";
import PatientsListH from "./Components/Hospital/PatientsList";
import ProfileH from "./Components/Hospital/Profile";
/** Components General **/
import LoginPage from "./Components/Login/Login";
import AddAppointmentP from "./Components/Patient/AddAppointments";
import AddFamilyMember from "./Components/Patient/AddFamilyMember";
import PatientAppointments from "./Components/Patient/Appointments";
import Appointment_details from "./Components/Patient/Appointment_details";
import Compliance from "./Components/Patient/Compliance";
import Contact from "./Components/Patient/Contact";
import Edit_profile from "./Components/Patient/Edit_profile";
import EUGDPR from "./Components/Patient/EU-GDPR";
import FamilyAccount from "./Components/Patient/FamilyAccount";
import FamilyAppointment from "./Components/Patient/FamilyAppointment";
/**Components Receptionist **/
// import AppointmentsListP from './Components/Patient_old/AppointmentsList';
// import Profile from './Components/Patient_Old/Profile';
import Homepage from "./Components/Patient/Homepage";
import PatientLogin from "./Components/Patient/Login";
import MobileAppTerms from "./Components/Patient/MobileTerms";
import Monitoring from "./Components/Patient/Monitoring";
import NewS from "./Components/Patient/new";
import NewSearch from "./Components/Patient/newsearch";
import Otp from "./Components/Patient/Otp";
import Practitioner from "./Components/Patient/Practitioner_benefits";
import Prescriptions from "./Components/Patient/Prescription";
import PrivacyPolicy from "./Components/Patient/PrivacyPolicy";
import PrivacyPolicyAus from "./Components/Patient/PrivacyPolicyAus";
import Product from "./Components/Patient/Product";
import Payment from "./Components/Patient/Payment";
import PatientProfile from "./Components/Patient/Profile";
import Register from "./Components/Patient/Register";
import Requests from "./Components/Patient/Requests";
import Scanned from "./Components/Patient/Scanned";
import SearchResults from "./Components/Patient/SearchResults";
import Team from "./Components/Patient/Team";
import Treatment from "./Components/Patient/Treatment";
import USHippaPolicy from "./Components/Patient/USHippaPolicy";
import WebsiteTerms from "./Components/Patient/WebsiteTerms";
/**Components Receptionist **/
import AddAppointmentR from "./Components/Receptionist/AddAppointment";
import AddPatientR from "./Components/Receptionist/AddPatient";
import AppointmentsListR from "./Components/Receptionist/AppointmentsList";
import PatientsListR from "./Components/Receptionist/PatientsList";
import DashboardLayoutRoute from "./Layouts/DashboardLayout";
import EditAppoinmentR from "./Components/Receptionist/EditAppoinment";
import ProfileR from "./Components/Receptionist/Profile";
import DoctorAppointments from "./Components/Receptionist/DoctorAppointments";
import Edit_profileR from "./Components/Receptionist/Edit_profileR";
import View_Appointment from "./Components/Receptionist/View_Appointment";
/** Layouts **/
import LoginLayoutRoute from "./Layouts/LoginLayout";
import MobileLayoutRoute from "./Layouts/MobileLayout";
import PatientLayoutRoute from "./Layouts/PatientLayout";
import OpenLayoutRoute from "./Layouts/OpenLayout";
import ResetPassword from "./Components/Patient/Reset-Password";
import ChangePassword2 from "../src/Components/Patient/ChangePassword";
import DeleteAccount from "../src/Components/Patient/DeleteAccount";
import PublicPrescriptions from "./Components/Patient/PublicPrescription";
import Orders from "./Components/Pharmacy/Orders";
import OrderDetails from "./Components/Pharmacy/OrderDetails";
import Pharmacy from "./Components/Patient/Pharmacy";
import PublicOrderDetails from "./Components/Pharmacy/PublicOrderDetails";
import ViewAppointment from "./Components/Doctor/ViewAppointment";
import PluginHospital from "./Components/Hospital/PluginHospital";
import VideoCallLive from "./Components/Doctor/VideoCallLive";
import AddPharmacy from "./Components/Admin/AddPharmacy";
import EditPharmacy from "./Components/Admin/EditPharmacy";
import RegistrationForm from "./Components/Pharmacy/RegistrationForm";
import LoginForm from "./Components/Pharmacy/LoginForm";
import PharmacyHome from "./Components/Pharmacy/PharmacyHome";
import Prescription2 from "./Components/Pharmacy/Prescription2";
import EPrescription2 from "./Components/Pharmacy/EPrescription2";
import EPrescriptionForm from "./Components/Pharmacy/EPrescriptionForm";
import AddDoctorDetailsPrescriber from "./Components/Doctor/AddDoctorDetailsPrescriber";
import GeneratePrescriptionForm from "./Components/Doctor/GeneratePrescriptionForm";
import EPrescription from "./Components/Patient/EPrescription";
import Prescribers from "./Components/Doctor/Prescribers";
import NewPrescription from "./Components/Doctor/NewPrescription";
import InvoicePage from "./Components/Pharmacy/InvoicePage";
import DashboardLayoutRoutes from "./Layouts/DashboardLayouts";
import AddPatientPharmacy from "./Components/Pharmacy/AddPatientPharmacy";
import InvoiceForm from "./Components/Pharmacy/InvoiceForm";
import PharmacyInvoiceList from "./Components/Pharmacy/PharmacyInvoiceList";
import PharmacyCustomersList from "./Components/Pharmacy/PharmacyCustomersList";
import Department from "./Components/Pharmacy/Department";
import AddSupplier from "./Components/Pharmacy/AddSupplier";
import SupplierInfo from "./Components/Pharmacy/SupplierInfo";
import Suppliers from "./Components/Pharmacy/Suppliers";
import AddMedicine from "./Components/Pharmacy/AddMedicine";
import PharmacistDashboard from "./Components/Pharmacy/PharmacistDashboard";
import Medication from "./Components/Pharmacy/Medication";
import DrugRefill from "./Components/Pharmacy/DrugRefill";
import Medicines from "./Components/Pharmacy/Medicines";
import EditMedicine from "./Components/Pharmacy/EditMedicine";
import DrugScheduling from "./Components/Pharmacy/DrugScheduling";
import PatientsListPharmacy from "./Components/Pharmacy/PatientsListPharmacy";
import InvoiceOrderList from "./Components/Pharmacy/InvoiceOrderList";
import Medicine from "./Components/PharmacyOrder/Medicine";
import Order from "./Components/PharmacyOrder/Order";
import EditCart from "./Components/PharmacyOrder/EditCart";
import SearchMedicine from "./Components/PharmacyOrder/SearchMedicine";
import AllMedicines from "./Components/PharmacyOrder/AllMedicines";
import HealthcareDevices from "./Components/PharmacyOrder/HealthcareDevices";
import HealthResourceCenter from "./Components/PharmacyOrder/HealthResourceCenter";
import VitaminsNutritions from "./Components/PharmacyOrder/VitaminsNutritions";
import PersonalCare from "./Components/PharmacyOrder/PersonalCare";
import LabTests from "./Components/PharmacyOrder/LabTests";
import OrderHistory from "./Components/PharmacyOrder/OrderHistory";
import OrderDetail from "./Components/PharmacyOrder/OrderDetail";
import ProductDetails from "./Components/PharmacyOrder/ProductDetails";
import Analysis from "./Components/Pharmacy/Analysis";
import Appointment from "./Components/Pharmacy/Appointment";
import Prescription from "./Components/Pharmacy/Prescription";
import Service from "./Components/Pharmacy/Service";
import Chat from "./Components/Pharmacy/Chat";
import Support from "./Components/Pharmacy/Support";
import Setting from "./Components/Pharmacy/Setting";
import PrescriptionMedicine from "./Components/Pharmacy/PrescriptionMedicine";
import LabTest from "./Components/PharmacyOrder/LabTest";
import PaymentOrder from "./Components/PharmacyOrder/PaymentOrder";
import LabTestDetails from "./Components/PharmacyOrder/LabTestDetails";
import LabTestCreation from "./Components/Pharmacy/LabTestCreation";
import ConsultDoctor from "./Components/Patient/ConsultDoctor";
import AllDoctors from "./Components/Patient/AllDoctors";
import HowMedvaticWorks from "./Components/PharmacyOrder/HowMedvaticWorks";
import Services from "./Components/PharmacyOrder/Services";

class Helloworld extends Component {
  render() {
    return <div>Coming Soon</div>;
  }
}

// basename="/demos/medvatic"
/*
   App
 */
function App() {
  const [values, setValues] = React.useState({ alert: false });

  useEffect(() => {
    ReactGA.initialize("G-1S539P1ENZ");
    return () => { };
  }, []);

  //Listen to your custom event
  window.addEventListener("showAlert", function (e) {
    // console.log('printer state changed', e);
    const { detail } = e;
    // console.log(detail, "detail");
    if (detail) {
      setValues({
        ...values,
        alert: true,
        type: detail.type,
        title: detail.title,
      });
    }
  });

  const hideAlert = () => {
    window.removeEventListener("showAlert", function () { }, false);
    setValues({
      ...values,
      alert: false,
    });
  };
  const alertFunc = () => {
    if (values.alert && values.type == "info") {
      return <SweetAlert info title="Info" onConfirm={hideAlert}>
        {" "}
        {values.title}{" "}
      </SweetAlert>
    }

    return values.alert ? (
      values.type === "success" ? (
        <SweetAlert success title="Success" onConfirm={hideAlert}>
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : values.type === "error" ? (
        <SweetAlert error title="Error" onConfirm={hideAlert}>
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : null
    ) : null;
  };

  return (
    <>
      <Router>
        <RouteChangeTracker />
        <Switch>
          <PatientLayoutRoute
            exact
            path="/"
            component={Homepage}
          ></PatientLayoutRoute>

          <LoginLayoutRoute path="/admin/login" component={LoginPage} />

          <DashboardLayoutRoute
            userType="1"
            path="/admin/hospitals"
            component={HospitalList}
          />

          <DashboardLayoutRoute
            userType="1"
            path="/admin/plugin"
            component={Plugin}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-hospital"
            component={AddHospital}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/edit-hospital"
            component={EditHospital}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-hospital-chain"
            component={AddHospitalChain}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-speciality"
            component={AddSpeciality}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-pharmacy"
            component={AddPharmacy}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/edit-pharmacy"
            component={EditPharmacy}
          />
          {/* <PatientLayoutRoute
            userType="5"
            path="/RegistrationForm"
            component={RegistrationForm}
          /> */}
          <PatientLayoutRoute
            userType="5"
            path="/RegistrationForm"
            component={RegistrationForm}
          />
          <PatientLayoutRoute
            userType="5"
            path="/LoginForm"
            component={LoginForm}
          />
          <PatientLayoutRoute
            userType="5"
            path="/PharmacyHome"
            component={PharmacyHome}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/AddDoctorDetailsPrescriber"
            component={AddDoctorDetailsPrescriber}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/GeneratePrescriptionForm"
            component={GeneratePrescriptionForm}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/Prescribers"
            component={Prescribers}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/NewPrescription"
            component={NewPrescription}
          />
          <PatientLayoutRoute
            userType="5"
            path="/EPrescription2"
            component={EPrescription2}
          />
          <PatientLayoutRoute
            userType="5"
            path="/EPrescriptionForm"
            component={EPrescriptionForm}
          />

          <DashboardLayoutRoute
            userType="1"
            path="/admin/users"
            component={UsersList}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-user"
            component={AddUser}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/change-password"
            component={ChangePassword}
          />

          <LoginLayoutRoute path="/hospital/login" component={LoginPage} />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/doctors"
            component={DoctorsList}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/add-doctor"
            component={AddDoctor}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/receptionists"
            component={ReceptionistsList}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/plugin"
            component={PluginHospital}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/appointment-list"
            component={AppointmentsListH}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/edit-appoinment"
            component={EditAppoinmentR}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/patients-list"
            component={PatientsListH}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/profile"
            component={ProfileH}
          />

          <DashboardLayoutRoute
            userType="2"
            path="/hospital/patient"
            component={Patient}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/add-receptionist"
            component={AddReceptionist}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/view-requests"
            component={ViewRequests}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/view-patients"
            component={ViewPatient}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/change-password"
            component={ChangePassword}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/add-patient"
            component={AddPatientR}
          />

          <DashboardLayoutRoute
            userType="2"
            path="/edit-profile-Hospital"
            component={EditProfileHospital}
          />



          <LoginLayoutRoute path="/doctor/login" component={LoginPage} />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/patients"
            component={PatientsList}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/add-patient"
            component={AddPatient}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/appointments"
            component={AppointmentsList}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/add-appointment"
            component={AddAppointment}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/edit-appointment"
            component={EditAppointment}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/edit-patient"
            component={EditPatient}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/patient-appointment/:uid"
            component={PatientSingleAppointments}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/add-schedule"
            component={AddSchedule}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/edit-profile"
            component={EditProfileD}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/view-schedule"
            component={Helloworld}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/view-appointment/:appointmentId"
            component={ViewAppointment}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/change-password"
            component={ChangePassword}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/my-profile"
            component={ProfileD}
          />
          <LoginLayoutRoute path="/receptionist/login" component={LoginPage} />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/patients"
            component={PatientsListR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/add-patient"
            component={AddPatientR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/profile"
            component={ProfileR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/view-appointment/:appointmentId"
            component={View_Appointment}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/edit-profile"
            component={Edit_profileR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/appointments"
            component={AppointmentsListR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/add-appointment"
            component={AddAppointmentR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/change-password"
            component={ChangePassword}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/AppointmentsList"
            component={FamilyAppointment}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/view-appointment/:appointmentId"
            component={ViewAppointment}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/edit-appointment"
            component={EditAppointment}
          />

          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/edit-patient"
            component={EditPatient}
          />

          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/edit-appoinment"
            component={EditAppoinmentR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/add-schedule"
            component={AddSchedule}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/doctor-appointment/:uid"
            component={DoctorAppointments}
          />

          <PatientLayoutRoute
            userType="5"
            path="/patient/change-password"
            component={ChangePassword2}
          />
          <PatientLayoutRoute
            userType="5"
            path="/DeleteAccount"
            component={DeleteAccount}
          />
          <PatientLayoutRoute userType="5" path="/team" component={Team} />
          <PatientLayoutRoute
            userType="5"
            path="/product"
            component={Product}
          />
          <PatientLayoutRoute
            userType="5"
            path="/practitioner"
            component={Practitioner}
          />
          <PatientLayoutRoute
            userType="5"
            path="/treatment"
            component={Treatment}
          />
          <PatientLayoutRoute
            userType="5"
            path="/monitoring"
            component={Monitoring}
          />
          <PatientLayoutRoute
            userType="5"
            path="/familyAppointment"
            component={FamilyAppointment}
          />
          <PatientLayoutRoute
            userType="5"
            path="/contact"
            component={Contact}
          />
          <PatientLayoutRoute
            userType="5"
            title="Login"
            path="/login"
            component={PatientLogin}
          />
          <PatientLayoutRoute
            userType="5"
            title="Reset Password"
            path="/passwordReset"
            component={ResetPassword}
          />

          <PatientLayoutRoute
            userType="5"
            title="Forgot Password"
            path="/forget-password"
            component={ForgetPassword}
          />

          <PatientLayoutRoute
            userType="5"
            title="Welcome Doctor!"
            path="/doctor/welcome"
            component={PatientLogin}
          />
          <PatientLayoutRoute
            userType="5"
            path="/register"
            component={Register}
          />
          <PatientLayoutRoute
            userType="5"
            path="/doctor/register"
            component={DoctorRegister}
          />
          <PatientLayoutRoute userType="5" path="/otp" component={Otp} />
          <PatientLayoutRoute userType="5" path="/news" component={NewSearch} />
          <PatientLayoutRoute
            userType="5"
            path="/my-profile"
            component={PatientProfile}
          />
          <PatientLayoutRoute
            userType="5"
            path="/requests"
            component={Requests}
          />
          <PatientLayoutRoute
            userType="5"
            path="/family-account"
            component={FamilyAccount}
          />
          <PatientLayoutRoute
            userType="5"
            path="/add-member"
            component={AddFamilyMember}
          />
          <PatientLayoutRoute
            userType="5"
            path="/edit-profile"
            component={Edit_profile}
          />
          <PatientLayoutRoute
            userType="5"
            exact
            path="/my-appointments"
            component={PatientAppointments}
          />
          <PatientLayoutRoute
            userType="5"
            exact
            path="/my-pharmacy"
            component={Pharmacy}
          />
          <PatientLayoutRoute
            userType="5"
            exact
            path="/PaymentOrder"
            component={PaymentOrder}
          />
          <PatientLayoutRoute
            userType="5"
            exact
            path="/my-appointments/:id"
            component={Appointment_details}
          />
          <OpenLayoutRoute
            path="/prescriptions/:id"
            component={PublicPrescriptions}
          />
          <OpenLayoutRoute
            path="/pharmacy/order/:uid/:orderNumber"
            component={PublicOrderDetails}
          />
          <PatientLayoutRoute
            userType="5"
            path="/my-appointments/:id/prescriptions"
            component={Prescriptions}
          />
          <PatientLayoutRoute
            userType="5"
            path="/my-appointments/:id/EPrescription"
            component={EPrescription}
          />
          <PatientLayoutRoute
            userType="5"
            path="/add-appointment"
            component={AddAppointmentP}
          />
          <PatientLayoutRoute
            userType="5"
            path="/privacy-policy-india"
            component={PrivacyPolicy}
          />

          <PatientLayoutRoute
            userType="5"
            path="/privacy-policy-australia"
            component={PrivacyPolicyAus}
          />
          <PatientLayoutRoute
            userType="5"
            path="/us-hippa-policy"
            component={USHippaPolicy}
          />
          <PatientLayoutRoute userType="5" path="/EU-GDPR" component={EUGDPR} />
          <PatientLayoutRoute
            userType="5"
            path="/Compliance"
            component={Compliance}
          />
          <PatientLayoutRoute
            userType="5"
            path="/MobileAppTerms"
            component={MobileAppTerms}
          />
          <PatientLayoutRoute
            userType="5"
            path="/WebsiteTerms"
            component={WebsiteTerms}
          />
          <PatientLayoutRoute
            userType="5"
            path="/searched"
            component={SearchResults}
          />
          <PatientLayoutRoute
            userType="5"
            path="/AllDoctors"
            component={AllDoctors}
          />
          <PatientLayoutRoute
            userType="5"
            path="/SearchMedicine"
            component={SearchMedicine}
          />

          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/orders/order-details/:uid/:orderNumber"
            component={OrderDetails}
          />
          {/* <DashboardLayoutRoute
            userType="6"
            path="/pharmacy/orders"
            component={Orders}
          /> */}
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/orders"
            component={Orders}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/InvoiceForm"
            component={InvoiceForm}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/PharmacyInvoiceList"
            component={PharmacyInvoiceList}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Department"
            component={Department}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Patients"
            component={PharmacyCustomersList}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/add-patient"
            component={AddPatientPharmacy}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Suppliers"
            component={Suppliers}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/SupplierInfo"
            component={SupplierInfo}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/AddMedicine"
            component={AddMedicine}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Medicines"
            component={Medicines}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/EditMedicine"
            component={EditMedicine}
          />
          <PatientLayoutRoute userType="5" path="/search2" component={NewS} />
          <MobileLayoutRoute userType="5" path="/scanned" component={Scanned} />

          <PatientLayoutRoute
            userType="5"
            path="/payment"
            component={Payment}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/InvoicePage"
            component={InvoicePage}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/AddSupplier"
            component={AddSupplier}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Medication"
            component={Medication}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/DrugRequests"
            component={DrugRefill}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/LabTestCreation"
            component={LabTestCreation}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/DrugScheduling"
            component={DrugScheduling}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/PharmacistDashboard"
            component={PharmacistDashboard}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/PatientsList"
            component={PatientsListPharmacy}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/InvoiceOrderList"
            component={InvoiceOrderList}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Analysis"
            component={Analysis}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Appointment"
            component={Appointment}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Prescription"
            component={Prescription}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Service"
            component={Service}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Chat"
            component={Chat}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Support"
            component={Support}
          />
          <DashboardLayoutRoutes
            userType="6"
            path="/pharmacy/Settings"
            component={Setting}
          />

          <PatientLayoutRoute
            userType="5"
            path="/Medicine"
            component={Medicine}
          />
          <PatientLayoutRoute
            userType="5"
            path="/ConsultDoctor"
            component={ConsultDoctor}
          />
          <PatientLayoutRoute
            userType="5"
            path="/Order"
            component={Order}
          />
          <PatientLayoutRoute
            userType="5"
            path="/Editcart"
            component={EditCart}
          />
          <PatientLayoutRoute
            userType="5"
            path="/PrescriptionMedicine"
            component={PrescriptionMedicine}
          />
          <PatientLayoutRoute
            userType="5"
            path="/LabTest"
            component={LabTest}
          />
          <PatientLayoutRoute
            userType="5"
            path="/AllMedicines"
            component={AllMedicines}
          />
          <PatientLayoutRoute
            userType="5"
            path="/HowMedvaticWorks"
            component={HowMedvaticWorks}
          />
          <PatientLayoutRoute
            userType="5"
            path="/Services"
            component={Services}
          />
          <PatientLayoutRoute
            userType="5"
            path="/HealthcareDevices"
            component={HealthcareDevices}
          />
          <PatientLayoutRoute
            userType="5"
            path="/HealthResourceCenter"
            component={HealthResourceCenter}
          />
          <PatientLayoutRoute
            userType="5"
            path="/VitaminsNutritions"
            component={VitaminsNutritions}
          />
          <PatientLayoutRoute
            userType="5"
            path="/PersonalCare"
            component={PersonalCare}
          />
          <PatientLayoutRoute
            userType="5"
            path="/LabTests"
            component={LabTests}
          />
          <PatientLayoutRoute
            userType="5"
            path="/LabTestDetails/:testcode"
            component={LabTestDetails}
          />
          <PatientLayoutRoute
            userType="5"
            path="/OrderHistory"
            component={OrderHistory}
          />
          <PatientLayoutRoute
            userType="5"
            path="/OrderDetail/:uid"
            component={OrderDetail}
          />
          <PatientLayoutRoute
            userType="5"
            path="/ProductDetails"
            component={ProductDetails}
          />



          <Route path="/videocall" component={VideoCallLive} />
          {/* <Route path="/agora-ui" component={AgoraUi} /> */}

          <Route component={Comp404} />
        </Switch>
        {alertFunc()}{" "}
      </Router>
    </>
  );
}

export default App;
