import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';
// import { getApptData } from './data';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Apilib from "../../api";
import GoogleMap from '../Agora/utils/GoogleMap';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  margin: 10px 10px;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 5px;
  margin-left:5px;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 95%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
width:50%;
  display: inline-grid;
  align-items: center;
`;

const Div1 = styled.div`
  width: 95%;
`;

const Div2 = styled.div`
  width: 95%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content:flex-start;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: #50d8fa;
`;


const Field = styled.div`
  margin-bottom: 16px;
`;

function GenerateAuthority_script_number(n) {
    return (n - 1) % 9 + 1;
}

const AddSupplier = (route) => {

    const [loading, setloading] = useState(false);

    const [supplierName, setsupplierName] = useState('');
    const [mobileNumber, setmobileNumber] = useState('');
    const [Email, setEmail] = useState('');
    const [number, setnumber] = useState('');
    const [address, setAddress] = useState('');
    const [geoLocation, setgeoLocation] = useState('');

    const history = useHistory();



    const [fullName, setFullName] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [date, setDate] = useState(new Date());
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [patientList, setPatientList] = useState(null)

    useEffect(() => {
        let API = new Apilib();
        console.log(route, 'this is route')
        API.getUsers(5, route.userData.hospitalId)
            .then((result) => {
                let res = result.data
                console.log(res);

                let sorted = res.patients;
                sorted.sort((a, b) => {
                    return b.created_at - a.created_at;
                })
                setPatientList(sorted)


            })
            .catch((error) => {
                // this.setState({
                //     loading: false,
                // });
            });
    }, [])


    const handleObjectChange = (event) => {
        const objectId = event.target.value
        console.log(objectId)
        const selected = patientList.find((obj) => obj.uid === objectId);
        console.log(selected)
        setSelectedPatient(selected);
        // address, Patient_Medicare_No

    };

    const handleAddBox = () => {
        const values = [...inputBoxes];
        values.push({
            'itemName': '',
            'itemDescription': '',
            'quantity': '',
            'price': '',
        });
        setInputBoxes(values);
    };

    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
    };


    useEffect(() => {
        let newDate = date
        setDate(newDate)
    }, [date])



    const reusableInputState = (label, valueVar, onChaneVar, type) => {
        return <Containers>
            <Div1>
                <Label>{label}</Label>
            </Div1>
            <Div2>
                <Input type={type} value={valueVar} onChange={onChaneVar} />
            </Div2>
        </Containers>
    }

    const reusableDivState = (label) => {
        return <Div1>
            <Label>{label}</Label>
        </Div1>
    }

    const reusableMenuState = (label, onClickHandleMethod, icon) => {
        return <SidebarOption onClick={() => onClickHandleMethod()}>
            <SidebarOptionText> <i className={icon}></i>{label}</SidebarOptionText>
        </SidebarOption>
    }
    const onClickHandle = () => {
        console.log('hi there')
    }

    const handleInputChange = (index, event, field) => {

        console.log(inputBoxes)
        const values = [...inputBoxes];
        values[index][field] = event.target.value;
        setInputBoxes(values);
    };

    const [inputBoxes, setInputBoxes] = useState([{
        'itemName': '',
        'itemDescription': '',
        'quantity': '',
        'price': '',
        'itemId': '',
    }]);




    return (
        <Container>
            {loading && <Loader />}
            <div style={{ display: 'flex' }}>

                <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
                    <Content>

                        <div style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '95%', backgroundColor: '#fff' }} >


                            <Heading>Add Supplier</Heading>
                            <div style={{ border: 'solid #000 .05px' }}></div>

                            <div>

                                {reusableInputState('Supplier Name:', supplierName, (e) => setsupplierName(e.target.value), 'text')}
                                {reusableInputState('Mobile Number:', mobileNumber, (e) => setmobileNumber(e.target.value), 'text')}
                                {reusableInputState('Email:', Email, (e) => setEmail(e.target.value), 'text')}
                                {reusableInputState('Unique ID:', number, (e) => setnumber(e.target.value), 'number')}
                                {reusableInputState('Address:', address, (e) => setAddress(e.target.value), 'text')}
                                <Containers>
                                    <Div1>
                                        <Label>Date :</Label>
                                    </Div1>
                                    <Div2>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                inputVariant="outlined"
                                                value={date}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    setDate(e)
                                                }}
                                                animateYearScrolling
                                                name="dateOfBirth"
                                                disablePast
                                                format="dd/MM/yyyy"
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Div2>
                                </Containers>
                                <Containers>
                                    <Div1>
                                        <Label>City/State/Country</Label>
                                    </Div1>
                                    <Div2>
                                        <GoogleMap
                                            style={{
                                                width: '95%',
                                                padding: '18px',
                                                margin: '10px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                backgroundColor: '#EAFCFF',
                                                justifyContent: 'flex-end',
                                            }}
                                            value={geoLocation}
                                            getAddress={(address) => setgeoLocation(address)}
                                        />
                                    </Div2>
                                </Containers>



                                {inputBoxes.map((inputState, index) => (
                                    <div key={index} style={{ backgroundColor: '#EAFCFF', padding: '10px', margin: '5px', borderRadius: '11px' }}>

                                        <Containers>
                                            {reusableDivState('Medicine Name')}
                                            <Div2>
                                                <Input type="text" value={inputState.itemName} onChange={(event) => handleInputChange(index, event, 'itemName')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Medicine Description')}
                                            <Div2>
                                                <Input type="text" value={inputState.itemDescription} onChange={(event) => handleInputChange(index, event, 'itemDescription')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Quantity')}
                                            <Div2>
                                                <Input type="text" value={inputState.quantity} onChange={(event) => handleInputChange(index, event, 'quantity')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Price')}
                                            <Div2>
                                                <Input type="text" value={inputState.price} onChange={(event) => handleInputChange(index, event, 'price')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Medicine Id')}
                                            <Div2>
                                                <Input type="text" value={inputState.itemId} onChange={(event) => handleInputChange(index, event, 'itemId')} />
                                            </Div2>
                                        </Containers>
                                    </div>))}

                                <Button onClick={handleAddBox}>Add new medicine</Button>

                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={agreeTerms}
                                        onChange={() => {
                                            console.log(agreeTerms)
                                            setAgreeTerms(!agreeTerms)
                                        }} />
                                    Agree with the terms and conditions
                                </CheckboxLabel>

                            </div>
                            <ButtonGroup>
                                <Button onClick={handleAddBox}>Add new medicine</Button>
                                <Button style={{ fontWeight: '400', fontSize: '16px' }}
                                    onClick={async () => {
                                        if (!agreeTerms) {
                                            alert('Please agree to the terms and conditions')
                                            return
                                        }
                                        let m = { pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1', medicines: { supplierMedicines: inputBoxes }, supplierName: 'ram', mobileNumber: '972224616', Email: 'ram@gmail.com', number: '451236', address: 'rohili,barmer', city: 'barmer', pharmacy: 'KAoOnOt37RMTwAxsr6sqi1aOler1' }
                                        // medicines.supplierMedicines
                                        // body.pharmacy_id
                                        // body.uid
                                        // body.pharmacy
                                        //         supplierName: body.supplierName,
                                        //             mobileNumber:body.mobileNumber,
                                        // Email:body.Email,
                                        // number:body.number,
                                        // address:body.address,
                                        // city:body.city,
                                        // date:new Date.now(),
                                        // medicines:medicines,

                                        // https://australia-southeast1-medvatic-438af.cloudfunctions.net/pharmacy/test
                                        let API = new Apilib();

                                        // { pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1', medicines: {supplierMedicines: inputBoxes }, supplierName: 'ram', mobileNumber: '972224616', Email: 'ram@gmail.com', number: '451236', address: 'rohili,barmer', city: 'barmer', pharmacy: 'KAoOnOt37RMTwAxsr6sqi1aOler1' }

                                        let data = {
                                            supplierName: supplierName,
                                            mobileNumber: mobileNumber,
                                            Email: Email,
                                            number: number,
                                            medicines: { supplierMedicines: inputBoxes },
                                            address: address,
                                            city: geoLocation,
                                            pharmacyId: route.userData.uid,
                                            pharmacy: route.userData.uid
                                        }


                                        console.log(data)

                                        API.createSupplierPharmacy(data)
                                            .then(async (res) => {
                                                console.log(res)
                                                console.log('this is response')
                                                if (res.data.code == 200) alert('Supplier saved successfully')
                                            })
                                            .catch((error) => {
                                                console.log('error', error)
                                            });

                                    }}
                                >
                                    Save Supplier</Button>
                                <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }} onClick={() => {
                                    history.goBack()
                                }}>
                                    Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </Content>
                </div>
            </div>
        </Container >
    );
};

export default AddSupplier;
