import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cart from '../../assets/img/cart.png'
import BottomContainer from "./BottomContainer";
import Apilib from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader";



function PrescriptionMedicine(props) {

  const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData'))?.length > 0 ? JSON.parse(localStorage.getItem('cartData')) : [])
  const [cartItemsLength, setcartItemsLength] = useState(0)
  const [loading, setloading] = useState(false);

  useEffect(() => {
    console.log('useeffect executed')
    setcartItemsLength(cartItems.length)
  }, [cartItems]);

  const history = useHistory()

  function itemExist(arr, arr1) {
    let index = 0
    const { length } = arr;
    const id = length + 1;
    const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);
    console.log(found, 'this is found')
    // if (!found) arr.push(arr1);
    return found;
  }

  const notify = () => toast.success("Item Added into cart!");
  const settingcart = (data) => {
    notify()
    let pr = [...cartItems]
    let itemnow = itemExist(pr, data)
    data.qty = 1
    if (itemnow == -1) pr.push(data)
    else pr[itemnow].qty += 1
    console.log(itemnow, 'itemnow herer')

    setcartItems(pr)
    localStorage.setItem('cartData', JSON.stringify(cartItems));
  }



  let ReturnColumn = (x) => <Column key={Math.random()}>
    <Div35>
      <Div36>
        <Img12
          loading="lazy"
          srcSet={x.imageLink ? x.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'}
        />
      </Div36>
      <Div37>
        <Div38>{x.type}</Div38>
        <Div39>{x.Medicine_Name}</Div39>
        {
          x.rating.stars ? <Div40>
            <Div41>
              <Div42>{x.rating.stars}</Div42>
              &#9733;
            </Div41>
            <Div43>{x.rating.count} Ratings</Div43>
          </Div40>
            : <Div40>
              <Div41>
                <Div42>No rating available</Div42>
              </Div41>
            </Div40>
        }
        <Div44>
          <Div45>
            <Div46>
              <Div48>${Number(x.Price * 0.95).toFixed(2)}</Div48>
              <Div47>${x.Price}</Div47>
            </Div46>
            {/* <Div49>Stock Level</Div49> */}
          </Div45>
          <Div50 onClick={() => {
            console.log('clickced item', x)
          }}>
            <Img14
              // loading="lazy"
              src={cart}
              onClick={() => {
                console.log('added to cart ' + x.title)
                // let cart = cartItems
                // cart.push(x)
                // setcartItems(() => cart)
                settingcart(x)
                console.log(cartItems)
              }}
            />
            {/* <Img23
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/28cf528e-1e18-4d9f-8bb1-9e71c4800e5d?apiKey=04f85002d68744bd9623fe1bdb31055b&"
                    /> */}
          </Div50>
        </Div44>
      </Div37>
    </Div35>
  </Column>

  function categorizeArray(inputArray) {
    // Create an object to store categories
    const categorizedData = {};

    // Iterate over the inputArray
    inputArray.forEach(item => {
      // Check if the category exists in the categorizedData
      if (!categorizedData.hasOwnProperty(item.category)) {
        // If not, create an array for the category
        if (!item.category && item.isPrescription == 'yes') {

          if (!categorizedData['Prescription Medicine']) categorizedData['Prescription Medicine'] = [];
        }
        else if (!item.category && (item.isPrescription == 'no' || item.isPrescription == 'prescription required (no)')) {

          if (!categorizedData['No prescription required']) categorizedData["No prescription required"] = [];
        }
        else categorizedData[item.category] = [];
      }
      if (!item.rating) item.rating = { stars: 0, count: 0 }
      else {
        let col = item.rating.length
        let totalrating = item?.rating?.reduce(function (acc, obj) { return acc + Number(obj.rating) }, 0);
        let countedStars = 0
        if (item?.rating?.length) countedStars = totalrating / item?.rating?.length
        item.rating = { stars: countedStars, count: item?.rating?.length }
      }

      // Push the item to the corresponding category array

      // Batch_Number:"45122452"
      // Dose:"NA"
      // ExpDate:"2023-12-28T10:39:00.000Z"
      // Medicine_Name:"Digital Thermometer"
      // MfgDate:"2023-10-01T10:39:00.000Z"
      // Price:"300"
      // Quantity:"10"
      // Units:"5"
      // imageLink:"https://cdn.britannica.com/77/80577-050-8E18C22A/thermometer.jpg"
      // isPrescription:"no"
      // pharmacyId:"KAoOnOt37RMTwAxsr6sqi1aOler1"
      // rating:{ stars: 0, count: 0 }
      // type:"Device"



      let datatopush = item
      console.log(categorizedData, 'categorizedDatacategorizedData');
      // If not, create an array for the category
      if (!item.category && item.isPrescription == 'yes') {
        console.log('in 1', item);
        categorizedData['Prescription Medicine'].push(datatopush);
      }
      else if (!item.category && (item.isPrescription == 'no' || item.isPrescription == 'prescription required (no)')) {
        console.log('in 2', item);
        categorizedData["No prescription required"].push(datatopush);
      }
      else {
        console.log('in 3', item);
        categorizedData[item.category].push(datatopush);
      }



      // if (!item.category) categorizedData[item.category].push(datatopush);
      // // console.log('after yes but no', categorizedData[item.category]);
      // else categorizedData[item.category].push(datatopush);
    });

    // Convert the categorizedData object to an array
    const resultArray = Object.entries(categorizedData).map(([category, items]) => ({
      category,
      items
    }));

    return resultArray;
  }

  // Example usage
  // const inputArray = dataa


  const [medicineList, setmedicineList] = useState([])
  // const resultArray = categorizeArray(medicineList);
  // console.log(resultArray, 'this is run');

  useEffect(() => {
    let API = new Apilib();
    setloading(true)
    API.getMedicines({})
      .then(async (res) => {
        console.log(res)
        console.log('this is response')
        console.log('this is response', res.data.medicines.medicines)
        setmedicineList(categorizeArray(res.data.medicines.medicines))
        setloading(false)
        // medicineList
      })
      .catch((error) => {
        setloading(false)
        console.log('error', error)
      });


  }, []);



  return (
    <Div>
      {loading && <Loader />}

      <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
        theme="light"
      />
      <Div25>

        {medicineList.length > 0 ? medicineList.map(x => {
          return <Div33 key={Math.random()}>
            <Div30>
              <Div31>{x.category}</Div31>
              <Div32>View All</Div32>
            </Div30>
            <Div34>
              {x.items.map(y => {
                return ReturnColumn(y)
              })}
            </Div34>
          </Div33>
        }) :
          <div>
            No medicine available to show
          </div>}


      </Div25>
      <Div306>
        <BottomContainer />
      </Div306>

      {
        <div className="ChatSupport">
          <div className="ChatSupport__container">
            {cartItemsLength && <button className="ChatSupport__cta-btn" onClick={async () => {

              console.log('yes the cafrt', cartItems.length)
              // history.push({ pathName: "/Order", state: { cart: cartItems } })

              localStorage.setItem('cartData', JSON.stringify(cartItems));
              const items = JSON.parse(localStorage.getItem('cartData'));
              console.log(items, 'this i s otems')
              history.push({
                pathname: "/EditCart",
                state: { cart: cartItems },
              });



            }} style={{ position: 'fixed', top: '13%' }}>
              {/* <WhatsAppIcon /> &nbsp; Hi! How can I help? */}
              Checkout {cartItemsLength} items

            </button>}
          </div>
        </div >
      }
    </Div>
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Div25 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 15px;
  width: 100%;
  max-width: 1410px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;


const Div30 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 57px;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const Div31 = styled.div`
  color: #041b1f;
  text-transform: uppercase;
  flex-grow: 1;
  flex-basis: auto;
  font: 500 26px/32px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  color: #26d4ff;
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div33 = styled.div`
  align-self: stretch;
  margin-top: 19px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div34 = styled.div`
  gap: 20px;
  display: flex;
  overflow-x: auto;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
    overflow-y: auto;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div35 = styled.div`
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  padding-bottom: 21px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Div36 = styled.div`
  background-color: #f4faff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 20px;
`;

const Img12 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 252px;
  overflow: hidden;
  max-width: 100%;
`;

const Div37 = styled.div`
  display: flex;
  margin-top: 21px;
  flex-direction: column;
  padding: 0 15px 0 16px;
`;

const Div38 = styled.div`
  color: #737373;
  white-space: nowrap;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div39 = styled.div`
  color: #007587;
  margin-top: 19px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div40 = styled.div`
  display: flex;
  margin-top: 11px;
  justify-content: space-between;
  gap: 10px;
`;

const Div41 = styled.div`
  border-radius: 3px;
  background-color: #00869a;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 6px 5px;
`;

const Div42 = styled.div`
  color: #fff;
  font: 500 18px/21px Roboto, sans-serif;
`;

const Img13 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 15px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div43 = styled.div`
  color: #737373;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div44 = styled.div`
  display: flex;
  margin-top: 7px;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
`;

const Div45 = styled.div`
  display: flex;
  margin-top: 10px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div46 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;

const Div47 = styled.div`
  color: #26d4ff;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div48 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 500 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div49 = styled.div`
  color: #737373;
  margin-top: 22px;
  white-space: nowrap;
  font: 500 16px/19px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div50 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  align-items: end;
`;

const Img14 = styled.img`
  aspect-ratio: 1.0;
  object-fit: contain;
  object-position: center;
  width: 37px;
  overflow: hidden;
  max-width: 30px;
`;

const Img23 = styled.img`
  aspect-ratio: 13.88;
  object-fit: contain;
  object-position: center;
  width: 111px;
  overflow: hidden;
  align-self: center;
  margin-top: 20px;
`;

const Div306 = styled.div`
  background-color: #effdff;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 37px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;



export default PrescriptionMedicine;