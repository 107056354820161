import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import Apilib from "../../api";
import cart from '../../assets/img/cart.png'
import './ProductList.css';
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import AutoSuggest from './AutoSuggest';


const AllMedicines = () => {
    const history = useHistory();
    const [loading, setloading] = useState(false)

    const [products, setproducts] = useState([])
    const [productsearch, setproductsearch] = useState([])
    const [onsearch, setonsearch] = useState(false)
    const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData'))?.length > 0 ? JSON.parse(localStorage.getItem('cartData')) : [])
    const [cartItemsLength, setcartItemsLength] = useState(0)

    useEffect(() => {
        let API = new Apilib();
        setloading(true)
        API.getMedicines({})
            .then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log('this is response', res.data.medicines.medicines)
                setproducts(res.data.medicines.medicines)
                setproductsearch(res.data.medicines.medicines)
                // medicineList
            })
            .catch((error) => {
                console.log('error', error)
            });
        setloading(false)


    }, []);

    useEffect(() => {
        console.log('useeffect executed')
        const items = JSON.parse(localStorage.getItem('cartData'));
        if (items) setcartItemsLength(items.length)
    }, [cartItems]);

    function itemExist(arr, arr1) {
        let index = 0
        const { length } = arr;
        const id = length + 1;
        const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);
        console.log(found, 'this is found')
        // if (!found) arr.push(arr1);
        return found;
    }

    const settingcart = (data) => {
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        data.qty = 1
        if (itemnow == -1) pr.push(data)
        else pr[itemnow].qty += 1
        console.log(itemnow, 'itemnow herer')

        localStorage.setItem('cartData', JSON.stringify(pr));

        setcartItems(pr)

    }



    const getSuggestions = (value) => {
        console.log(value, 'this is valeu')
        const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim()
        const regex = new RegExp('^' + escapedValue, 'i');

        const spResult = products.filter(language => regex.test(language.Medicine_Name)).slice(0, 3);

        if (spResult.length === 0 && !!value) {
            console.log(value, 'item not found')
            return [{ speciality: value }]
        }

        if (escapedValue === '') {
            console.log(value, 'this issadaaleu')
            setproductsearch(products)
            return products;
        }
        //if(!!value===false) return products;
        console.log('this is executing on change')
        let news = products.filter(language => regex.test(language.Medicine_Name)).slice(0, 5)
        setproductsearch(news)
        return products.filter(language => regex.test(language.Medicine_Name)).slice(0, 5);
    }


    if (loading) return <Loader />
    else return (
        <div style={{ width: '100%', alignItems: 'center', textAlign: 'center' }}>
            <div style={{ fontSize: '30px' }}>All Medicines</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* {products && <AutoSuggest userList={products} placeHolderText={'Search...'} />} */}


                {products && <input
                    style={{ border: 'solid 1px #007587', width: '60%', height: '40px', margin: '5px', padding: '5px' }}
                    onChange={(e) => {
                        getSuggestions(e.target.value)
                    }}
                    placeholder={'Search...'}

                    onFocus={() => setonsearch(true)}

                // onBlur={() => setonsearch(false)}
                />}

                {products && <div style={{ color: '#fff', padding: '8px', borderRadius: '5px', backgroundColor: '#41d9ff', width: '100px', cursor: 'pointer', textAlign: 'center', alignSelf: 'center' }}>Search</div>}
            </div>

            {!onsearch && <div className="product-grid" style={{ justifyContent: 'center', alignItems: 'center' }}>
                {products.map((product) => (
                    <div
                        id={Math.random()}
                        className="card"
                        style={{
                            width: '260px',
                            justifyContent: 'space-evenly',
                            padding: '10px',
                            margin: '10px'
                        }}
                        tabIndex={0}>
                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }}
                            onClick={() => {
                                history.push('/ProductDetails')
                                product.qty = 1
                                localStorage.setItem('productDetails', JSON.stringify(product));

                            }}
                        >
                            <img src={product.imageLink ? product.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product 1" style={{ width: '230px', height: '150px', }} />
                        </div>
                        <div style={{ textAlign: 'left', }}>

                            <p style={{ width: '100%', marginBottom: '3px' }}>{product.type} </p>
                            <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{product.Medicine_Name} </p>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <p style={{ width: '50%', marginBottom: '3px', color: '#000', fontSize: '15px', display: 'flex', alignItems: 'center' }}>MRP :  <p style={{ width: '50%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>  {product.Price} </p></p>
                                <img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                                    console.log('added to cart ' + product.title)
                                    // let cart = cartItems
                                    // cart.push(x)
                                    // setcartItems(() => cart)
                                    settingcart(product)
                                    console.log(product)
                                }} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
            {onsearch && <div className="product-grid" style={{ justifyContent: 'center', alignItems: 'center' }}>
                {productsearch.map((product) => (
                    // <div key={product.id} className="product-card">
                    //     <h3>{product.name}</h3>
                    //     <p>Price: ${product.price}</p>
                    //     <p>{product.description}</p>
                    // </div>
                    <div
                        id={Math.random()}
                        className="card"
                        style={{
                            width: '260px',
                            justifyContent: 'space-evenly',
                            padding: '10px',
                            margin: '10px'
                        }}
                        tabIndex={0}>
                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }}>
                            <img src={product.imageLink ? product.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product 1" style={{ width: '230px', height: '150px', }} />
                        </div>
                        <div style={{ textAlign: 'left', }}>

                            <p style={{ width: '100%', marginBottom: '3px' }}>{product.type} </p>
                            <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{product.Medicine_Name} </p>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <p style={{ width: '50%', marginBottom: '3px', color: '#000', fontSize: '15px', display: 'flex', alignItems: 'center' }}>MRP :  <p style={{ width: '50%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>  {product.Price} </p></p>
                                add to cart<img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                                    console.log('added to cart ' + product.title)
                                    // let cart = cartItems
                                    // cart.push(x)
                                    // setcartItems(() => cart)
                                    settingcart(product)
                                    console.log(product)
                                }} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>}

            {<div className="ChatSupport">
                <div className="ChatSupport__container">
                    {cartItemsLength && <button className="ChatSupport__cta-btn" onClick={async () => {

                        console.log('yes the cafrt', cartItems.length)
                        // history.push({ pathName: "/Order", state: { cart: cartItems } })

                        localStorage.setItem('cartData', JSON.stringify(cartItems));
                        const items = JSON.parse(localStorage.getItem('cartData'));
                        console.log(items, 'this i s otems')
                        history.push({
                            pathname: "/EditCart",
                            state: { cart: items },
                        });
                    }} style={{ position: 'fixed', top: '13%' }}>
                        {/* <WhatsAppIcon /> &nbsp; Hi! How can I help? */}
                        Checkout {cartItemsLength} items

                    </button>}
                </div>
            </div >}

        </div>
    );
}

export default AllMedicines;
