import React from 'react';

const Service = () => {
    return (
        <div>
            We are currently working on this.
        </div>
    );
}

export default Service;
