import axios from "axios";
import { auth } from "./firebase";

const instance = axios.create({
  baseURL: "https://australia-southeast1-medvatic-438af.cloudfunctions.net/", //Prod
  // baseURL: "https://australia-southeast1-medvatic-dev.cloudfunctions.net/", //Dev
});

instance.interceptors.request.use(async (config) => {
  const token = await auth?.currentUser
    ?.getIdToken() // here we force a refresh if boolean set is true
    .then((token) => {
      console.log("Token nnik", token);
      return token;
    })
    .catch((error) => {
      console.log("Token error", error);
    });
  config.headers = {
    "authorization": token,
    "Content-Type": "application/json"
  }

  return config;
});

export default instance;