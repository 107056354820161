import React from 'react';

const Prescribers = () => {
    return (
        <div>
            <div>a list of prescribers will appear</div>
        </div>
    );
}

export default Prescribers;
