// LabTestCreation.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Apilib from '../../api';
import Notify from '../../Notify';
import Loader from '../Loader/Loader';
import BaseSelect from "react-select";
import RequiredSelect from '../../Helpers/requiredSelect';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #00a1ba;
  &:hover {
    background-color: #33c5ea;
  }
`;

const LabTestCreation = (route) => {
    const [formData, setFormData] = useState({
        testName: '',
        testCode: '',
        testCategory: '',
        description: '',
        preparationInstructions: '',
        expectedResults: '',
        risksAndConsiderations: '',
        price: '',
        duration: '',
        Miscellaneous: '',
        daysAvailable: [],
        timeSlot: '',
        from: '',
        to: ''
    });
    const Select = (props) => (
        <RequiredSelect
            {...props}
            SelectComponent={BaseSelect}
            options={props.options}
        />
    );
    // let category = [
    //     'Blood Tests',
    //     'Diagnostic Imaging',
    //     'Genetic Testing',
    //     'Infectious Disease Tests',
    //     'Hormone Tests',
    //     'Cancer Screening',
    //     'Allergy Tests',
    //     'Urinalysis',]
    const [category, setcategory] = useState([
        { type: 'Blood Tests' },
        { type: 'Diagnostic Imaging' },
        { type: 'Genetic Testing' },
        { type: 'Infectious Disease Tests' },
        { type: 'Hormone Tests' },
        { type: 'Cancer Screening' },
        { type: 'Allergy Tests' },
        { type: 'Urinalysis' },
    ]);

    let available_days = [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
    ];

    const [selectedDaysObj, setselectedDaysObj] = useState(null)
    const [selectedDays, setselectedDays] = useState(null)
    const [selectedSlotDuration, setselectedSlotDuration] = useState(0)
    const [slot, setslot] = useState(null)
    const [startTimeCustom, setstartTimeCustom] = useState(null)
    const [endTimeCustom, setendTimeCustom] = useState(null)

    const onTimeSelect = (value, label) => {
        console.log("Value:", value, "Label:", label);
        let time = moment(value).format("h:mm a");
        this.setState({
            [label]: time,
            [label + "Custom"]: value,
        });
    }

    const onDaySelect = (day) => {
        let arr = [];
        day.map(x => arr.push(x?.value));
        // this.setState({
        //     selectedDays: arr,
        //     selectedDaysObj: day,
        // });
        setselectedDays(arr)
        setselectedDaysObj(day)
        console.log(arr, day)
        setFormData({
            ...formData,
            ['daysAvailable']: arr,
        });
    }


    let slotsToShow = [
        { label: "30", value: 30 },
        { label: "60", value: 60 },
        { label: "90", value: 90 },
        { label: "120", value: 120 },
        { label: "150", value: 150 },
    ];


    const onSlotSelect = (slot) => {
        console.log("Slot:", slot);
        // this.setState({
        //     selectedSlotDuration: slot,
        //     slot: slot.value,
        // });
        setselectedSlotDuration(slot)
        setslot(slot.value)

        setFormData({
            ...formData,
            ['timeSlot']: slot.value,
        });
    }

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [Loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputChanges = (event, field) => {
        console.log(category, formData)
        const values = [...category];
        // if (field == 'MfgDate' || field == 'ExpDate') {
        //     // values[index][field] = event;
        //     return
        // }
        // values[0][field] = event.target.value;
        // setcategory(values);
        setFormData({
            ...formData,
            ['testCategory']: event.target.value,
        });
    };

    const handleSubmit = (e) => {
        // e.preventDefault();
        // Add logic to submit data to the backend or perform any necessary actions
        if (!agreeTerms) {
            alert('please agree to the terms and condition, before  uploading lab test')
            return
        }
        setLoading(true)
        const API = new Apilib();
        function submit() {
            const data = formData
            data.pharmacyId = route.userData.uid
            data.pharmacy_name = route.userData.pharmacyName
            data.pharmacy_email = route.userData.email


            API.createLabTest({ medicines: [data], pharmacyId: route.userData.uid })
                .then(async (res) => {
                    console.log(res)
                    console.log('this is response')
                    // res.data.code==200
                    if (res.data.code == 200) Notify({
                        type: "success",
                        alert: true,
                        title: "Lab test added successfully",
                    })
                    else Notify({
                        type: "error",
                        alert: true,
                        title: "There was an error processing your request, Please try again",
                    })

                    setLoading(false)
                })
                .catch((error) => {
                    console.log('error', error)
                    setLoading(false)
                    Notify({
                        type: "error",
                        alert: true,
                        title: "There was an error processing your request, Please try again",
                    })
                });
            setFormData({
                testName: '',
                testCode: '',
                testCategory: '',
                description: '',
                preparationInstructions: '',
                expectedResults: '',
                risksAndConsiderations: '',
                price: '',
                duration: '',
                Miscellaneous: '',
                daysAvailable: [],
                timeSlot: '',
                from: '',
                to: ''
            })
            setselectedDaysObj(null)
            setselectedDays(null)
            setselectedSlotDuration(0)
            setslot(null)
            setstartTimeCustom(null)
            setendTimeCustom(null)


        }
        submit()
        console.log('Form submitted:', formData);
    };
    let daysToShow = [];
    available_days.map((day, i) => {
        // if(!daysBooked.includes(day.value)){
        daysToShow.push(day);
        // }
    });

    return (<Div>
        {Loading && <Loader />}
        <Div2>Add Labtest</Div2>
        <Div3>
            {/* <Img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8f39047-ef7d-4c32-b6af-1fb8d1a44c6e?"
            /> */}
            <Div4>
                <Div7>Lab Test Creation</Div7>



                <Div27>
                    <Div28>
                        <DivText>Test title</DivText>
                        <Div9

                            type="text"
                            name="testName"
                            value={formData.testName}
                            onChange={handleInputChange}
                            required
                        />
                    </Div28>
                    <Div31>
                        <DivText>Test Code:</DivText>
                        <Div9

                            type="text"
                            name="testCode"
                            value={formData.testCode}
                            onChange={handleInputChange}
                            required
                        />
                    </Div31>
                </Div27>

            </Div4>

            <Div27>
                <Div28>
                    <DivText>Test Category:</DivText>
                    {/* <Div9

                        type="text"
                        name="testCategory"
                        value={formData.testCategory}
                        onChange={handleInputChange}
                        required
                    />
                     */}

                    <div style={{ justifyContent: 'flex-start', display: 'grid', }}>
                        <select style={{ padding: '5px', margin: '5px', padding: '10px', paddingRight: '140px' }} value={formData.category} onChange={(event) => handleInputChanges(event, 'type')} required>
                            <option value="">Type</option>
                            {category.map((obj) => (
                                <option key={obj.type} value={obj.type}>
                                    {obj.type}
                                </option>
                            ))}
                        </select>

                    </div>

                </Div28>


                <Div28>
                    <DivText>Duration:</DivText>
                    <Div9
                        min="0"
                        placeholder='In minutes'
                        type="number"
                        name="duration"
                        value={formData.duration}
                        onChange={handleInputChange}
                        required
                    />
                </Div28>
            </Div27>
            <Div27>
                <Div28>
                    <DivText>Preparation Instructions:</DivText>
                    <Div90

                        type="text"
                        name="preparationInstructions"
                        value={formData.preparationInstructions}
                        onChange={handleInputChange}
                        required
                    />
                </Div28>
                <Div31>
                    <DivText>Expected Results:</DivText>
                    <Div90

                        type="text"
                        name="expectedResults"
                        value={formData.expectedResults}
                        onChange={handleInputChange}
                        required
                    />
                </Div31>
            </Div27>
            <Div27>
                <Div28>
                    <DivText>Risks and Considerations:</DivText>
                    <Div90

                        type="text"
                        name="risksAndConsiderations"
                        value={formData.risksAndConsiderations}
                        onChange={handleInputChange}
                        required
                    />
                </Div28>

                <Div31>
                    <DivText>Description:</DivText>
                    <Div90

                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                    />
                </Div31>

            </Div27>
            <Div27>
                <Div31>
                    <DivText>Price:</DivText>
                    <Div9
                        type="number"
                        min="0"
                        name="price"
                        value={formData.price}
                        onChange={handleInputChange}
                        required
                    />
                </Div31>
                <Div31>
                    <DivText>Miscellaneous detail:</DivText>
                    <Div9

                        type="text"
                        name="Miscellaneous"
                        value={formData.Miscellaneous}
                        onChange={handleInputChange}
                        required
                    />
                </Div31>
            </Div27>


            <Div27>
                <Div31>
                    <label>
                        Available Days
                        <span className="required">*</span>
                    </label>
                    <Select
                        required
                        onChange={(val) => onDaySelect(val)}
                        value={selectedDaysObj}
                        options={daysToShow}
                        noOptionsMessage={() => "All Days Booked"}
                        isMulti={true}
                        closeMenuOnSelect={false}
                    />
                </Div31>


                {/* <div className="form-group"> */}
                <Div31>
                    <label>
                        Slot Duration (mins)
                        <span className="required">*</span>
                    </label>

                    <Select
                        onChange={(val) => onSlotSelect(val)}
                        required
                        value={selectedSlotDuration}
                        options={slotsToShow}
                        noOptionsMessage={() => "Slots unavailable"}
                        isMulti={false}
                        closeMenuOnSelect={true}
                    />
                </Div31>
                {/* </div> */}

            </Div27>

            <Div27>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Div31>
                        {/* <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="form-group"> */}
                        <label>Start Time</label>
                        {/* <div className="schedule-time-wrapper"> */}
                        <KeyboardTimePicker
                            inputProps={{ required: true }}
                            margin="normal"
                            id="time-picker"
                            emptyLabel="Select start time"
                            placeholder="08:00 AM"
                            inputVariant="outlined"
                            format="h:mm a"
                            value={startTimeCustom}
                            onChange={(val) => {
                                // onTimeSelect(val, "startTime");
                                let time = moment(val).format("h:mm a");
                                console.log(val)
                                setstartTimeCustom(val)
                                setFormData({
                                    ...formData,
                                    ['from']: time,
                                });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change time",
                            }}
                        />
                    </Div31>
                    {/* </div>

                            </div>
                        </div> */}
                    {/* <div className="col-md-6">
                            <div className="form-group"> */}
                    <Div31>
                        <label>End Time</label>
                        {/* <div className="schedule-time-wrapper"> */}
                        <KeyboardTimePicker
                            inputProps={{ required: true }}
                            margin="normal"
                            id="time-picker"
                            emptyLabel="Select end time"
                            placeholder="12:00 PM"
                            format="h:mm a"
                            inputVariant="outlined"
                            value={endTimeCustom}
                            onChange={(val) => {
                                // onTimeSelect(val, "endTime");
                                let time = moment(val).format("h:mm a");
                                setendTimeCustom(val)
                                setFormData({
                                    ...formData,
                                    ['to']: time,
                                });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change time",
                            }}
                        />
                        {/* </div>
                            </div>
                        </div>
                    </div> */}
                    </Div31>
                </MuiPickersUtilsProvider>

            </Div27>


            <Div34>

                <Checkbox type="checkbox" checked={agreeTerms}
                    onChange={() => {
                        console.log(agreeTerms)
                        setAgreeTerms(!agreeTerms)
                    }} />

                <Div35> Agree with the terms and conditions</Div35>
            </Div34>
            <Div36>
                <Button type="submit" onClick={() => {
                    handleSubmit()
                }}>Submit</Button>
                <Button onClick={() => {
                    console.log('ram', selectedDaysObj)
                }}>Cancel</Button>
            </Div36>
        </Div3>
    </Div>
    );
};



const Checkbox = styled.input`
  margin-right: 5px;
`;
const Div = styled.div`
            display: flex;
            flex-direction: column;
            `;

const Div2 = styled.label`
            color: #000;
            white-space: nowrap;
            box-shadow: 0px 0px 2px 0px #00a1ba;
            background-color: #fff;
            width: 100%;
            justify-content: center;
            align-items: start;
            padding: 23px 15px;
            font: 600 25px Roboto, sans-serif;
            @media (max-width: 991px) {
                white-space: initial;
            max-width: 100%;
  }
            `;
const DivText = styled.label`
            color: #000;
            width: 100%;
            justify-content: center;
            align-items: start;
            @media (max-width: 991px) {
            max-width: 100%;
            }
            `;

const Div3 = styled.div`
            disply: flex;
            flex-direction: column;
            fill: #fff;
            overflow: hidden;
            position: relative;
            display: flex;
            min-height: 723px;
            margin-top: 30px;
            width: 100%;
            padding: 27px 22px 50px;
            @media (max-width: 991px) {
                max - width: 100%;
            padding: 0 20px;
  }
            `;

const Img = styled.img`
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            `;

const Div4 = styled.div`
            position: relative;
            align-self: stretch;
            @media (max-width: 991px) {
                max - width: 100%;
  }
            `;

const Div7 = styled.div`
            color: #000;
            white-space: nowrap;
            font: 700 20px Roboto, sans-serif;
            @media (max-width: 991px) {
                max - width: 100%;
            white-space: initial;
  }
            `;

const Div9 = styled.input`
            border-radius: 5px;
            background-color: #fff;
            display: flex;
            margin-top: 11px;
            filter: drop-shadow(0px 0px 2px #000);
            height: 50px;
            flex-direction: column;
            @media (max-width: 991px) {
                max - width: 100%;
  }
            `;

const Div90 = styled.textarea`
            border-radius: 5px;
            background-color: #fff;
            display: flex;
            margin-top: 11px;
            height: 50px;
            flex-direction: column;
            @media (max-width: 991px) {
                max - width: 100%;
  }
            `;

const Div27 = styled.div`
            position: relative;
            align-self: stretch;
            display: flex;
            margin-top: 25px;
            justify-content: space-between;
            gap: 20px;
            @media (max-width: 991px) {
                max - width: 100%;
            flex-wrap: wrap;
  }
            `;

const Div28 = styled.div`
            display: flex;
            flex-grow: 1;
            flex-basis: 0%;
            flex-direction: column;
            @media (max-width: 991px) {
                max - width: 100%;
  }
            `;

// const DivText = styled.div`
//             color: #000;
//             white-space: nowrap;
//             @media (max-width: 991px) {
//                 max - width: 100%;
//             white-space: initial;
//   }
//             `;

const Div31 = styled.div`
            display: flex;
            flex-grow: 1;
            flex-basis: 0%;
            flex-direction: column;
            @media (max-width: 991px) {
                max - width: 100%;
  }
            `;

// const DivText = styled.div`
//             color: #000;
//             @media (max-width: 991px) {
//                 max - width: 100%;
//             white-space: initial;
//   }
//             `;

const Div34 = styled.div`
            position: relative;
            align-self: start;
            display: flex;
            margin-top: 35px;
            width: 294px;
            max-width: 100%;
            align-items: start;
            gap: 9px;
            `;

const Img3 = styled.img`
            aspect-ratio: 1.11;
            object-fit: contain;
            object-position: center;
            width: 21px;
            overflow: hidden;
            max-width: 100%;
            `;

const Div35 = styled.div`
            color: #7a7a7a;
            flex-grow: 1;
            white-space: nowrap;
            font: 300 18px Roboto, sans-serif;
            @media (max-width: 991px) {
                white - space: initial;
  }
            `;

const Div36 = styled.div`
            position: relative;
            align-self: start;
            display: flex;
            width: 169px;
            max-width: 100%;
            gap: 11px;
            margin: 88px 0 33px;
            @media (max-width: 991px) {
                margin - top: 40px;
  }
            `;

export default LabTestCreation;
