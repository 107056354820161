import React, { Component } from "react";
import Loader from "../Loader/Loader";
import { auth } from "../../firebase";
import { Router, Link } from "react-router-dom";
import { Usertypes } from "../../Helpers/constants";
import Modal from "react-bootstrap/Modal";
import Apilib from "../../api";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: "",
      showModal: false,
    };
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  navigateToChoice(type){
    this.setState({
      loading: true
    });
    let API = new Apilib();
    API.getUserDetails(type, this.state.fbRes.user.uid)
           .then((result) => {
        let res = result.data

        this.setState({
          loading: false,
        });
        console.log("doctor login working")
        localStorage.setItem("@userData", JSON.stringify(res));
        localStorage.setItem("@displayName", type);
        this.props.history.push(Usertypes[type - 1][1]);
    });
  } 

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      error: "",
      loading: true,
    });
    auth.signInWithEmailAndPassword(this.state.email, this.state.password).then((res) => {
      console.log('ONSUBMIT RES user:', res.user)
      localStorage.setItem("@displayName", res.user.displayName.split(',')[0]);
      let API = new Apilib();
      console.log('Auth',res);
      this.setState({
        fbRes: res
      });
      if(res.user.displayName.indexOf(",") > -1){
        this.setState({
          showModal: true,
          loading: false,
        });
      }else{
        API.getUserDetails(res.user.displayName, res.user.uid)
                .then((result) => {
        let res = result.data

            this.setState({
              loading: false,
            });
            console.log("doctor")
            localStorage.setItem("@userData", JSON.stringify(res));
            localStorage.setItem("@displayName", res.user.type);
            console.log(this.props.userData, res.user.type);
            // this.props.history.push("/my-profile");
            
            this.props.history.push(Usertypes[res.user.type - 1][1]);
        });
      }}).catch((error) => {
        let errorMsg = "";
        if ((error.code = "auth/user-not-found")) {
          errorMsg = "The Email id or Password you entered is incorrect ";
        } else {
          errorMsg = error.message;
        }
        this.setState({
          error: errorMsg,
          loading: false,
        });
      });
  };

  render() {
    return (
      <div className="account-box">
        {this.state.loading && <Loader />}
        <form onSubmit={(e) => this.onSubmit(e)} className="form-signin">
          <div className="account-logo">
            <a>
              <img src="assets/img/logo-dark.png" alt="Medvatic" />
            </a>
          </div>
          <Modal
            backdrop="static"
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            // onHide={handleClose}
           
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Choose Your Preference</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="account-title">
                <h3 className="account-head">Login As</h3>
              </div>
            </Modal.Body>
            <div className="preference-buttons">
              <button
                className="btn btn-primary preference-btn account-btn"
                onClick={() => this.navigateToChoice(2)}
                style={{ marginRight: "10px" }}
              >
                Business Admin
              </button>
              <button
                className="btn btn-primary preference-btn account-btn"
                onClick={() => this.navigateToChoice(3)}
                style={{ marginLeft: "10px" }}
              >
                Professional
              </button>
            </div>
          </Modal>
          {this.state.error && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
              {this.state.error}
            </div>
          )}
          <div className="form-group">
            <label>Email Address</label>
            <input
              value={this.state.email}
              name="email"
              onChange={(e) => this.onChange(e)}
              type="email"
              autoFocus=""
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              value={this.state.password}
              name="password"
              onChange={(e) => this.onChange(e)}
              type="password"
              className="form-control"
              minLength="6"
              required=""
              maxLength="128"
              placeholder="Password*"
            />
          </div>

          <div className="form-group text-right">
            <Link className="" to="/forget-password">
              Forgot Password?
            </Link>
          </div>

          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary account-btn">
              Login
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default Login;
