import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import BaseSelect from "react-select";
import RequiredSelect from "../../Helpers/requiredSelect";
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import React, { Component } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import moment from "moment";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { isValid } from "date-fns";
// import "../../assets/css/FullCalendar.css";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class ReceptionistsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      receptionists: [],
      modalopen: false,
      openmodal: false,
      deleteModal: false,
      validContact: true,
      editedRecep: [],
      doctors: [],
      userData: { value: 1, label: "All Professionals" },
      calendar: [],
      eventGuid: 0,
      // calendarVisible: false,
      isDeleteModalOpen: false,
      deleteEvent: null,
      deleted: false,
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(4, this.props.userData.hospitalId)
           .then((result) => {
        let res = result.data

        console.log(res);
        if (this._isMounted) {
          this.setState(
            {
              receptionists: res.receptionists,
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  columnDefs: [
                    { orderable: false, targets: 4, width: "200px" },
                  ],
                });
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      })
      .finally(() => this.setState(
        {
          loading: false,
        }))
    this.fetchDoctors();
  }

  fetchDoctors() {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(3, this.props.userData.hospitalId)
           .then((result) => {
        let res = result.data


        if (res?.doctors) {
          this.setState(
            {
              doctors: res.doctors,
            },
            () => {
              this.createCalendarEventForAll(this.state.doctors);
              console.log("Professionals", this.state.doctors);
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({});
        }
      })
      .finally(() => this.setState(
        {
          loading: false,
        }))
  }

  onSelect = (val, name) => {
    if (name === "doctorId") {
      if (val.value === 1) {
        this.createCalendarEventForAll(this.state.doctors);
      }

      this.setState({
        userData: { ...val },
        // calendarVisible: true,
      });
      this.createCalendarEvents(val);
    }
  };

  createEventId() {
    let count = this.state.eventGuid;
    count++;
    this.setState({ eventGuid: count });
    return String(this.state.eventGuid);
  }

  openDeleteModal = (e) => {
    this.setState({
      isDeleteModalOpen: true,
      deleteEvent: e,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  };

  //------- Start of FullCalendar --------//
  createCalendarEvents(userData) {
    console.log({ userData });
    // set Calendar Schedule
    let daysBooked = userData.days || [];
    let calendarEvents = [];
    if (userData?.days) {
      daysBooked.map((user_day) => {
        var start = moment(),
          end = moment().add(90, "days"),
          day = user_day !== 7 ? user_day : 0;

        var result = [];
        var current = start.clone().day(day);

        if (current.isSameOrAfter(start)) {
          result.push(current.clone());
        }

        while (current.day(7 + day).isBefore(end)) {
          result.push(current.clone());
        }

        console.log("results", result);

        result.map((res) => {
          console.log(
            "test date:",
            start.format("YYYY-MM-DD") == res.format("YYYY-MM-DD"),
            start === res
          );
          userData.from[user_day].map((time, i) => {
            let valid = true;
            if (start.format("YYYY-MM-DD") === res.format("YYYY-MM-DD")) {
              if (moment() > moment(userData.to[user_day][i], "h:mm a")) {
                valid = false;
              }
            }

            // check if slot end time is not greater than current time(temporary check)
            if (valid) {
              calendarEvents.push({
                id: this.createEventId(),
                title: "",
                start:
                  res.format("YYYY-MM-DD") +
                  "T" +
                  moment(userData.from[user_day][i], "h:mm a").format("H:mm"),
                end:
                  res.format("YYYY-MM-DD") +
                  "T" +
                  moment(userData.to[user_day][i], "h:mm a").format("H:mm"),
                data: {
                  // Delete Body
                  uid: userData.uid,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  days: [user_day],
                  from: {
                    [user_day]: [userData.from[user_day][i]],
                  },
                  to: {
                    [user_day]: [userData.to[user_day][i]],
                  },
                  date: res,
                },
                appointments: [],
              });
            }
          });
        });
      });
      console.log("Calendar events", { calendarEvents });
      this.setState({
        calendar: calendarEvents,
      });
    }
  }

  createCalendarEventForAll(doctors) {
    // set Calendar Schedule
    this.setState({
      loading: true,
    });
    let calendarEvents = [];
    for (let i = 0; i < doctors.length; i++) {
      if (doctors[i]?.days) {
        let daysBooked = doctors[i].days || [];
        daysBooked.map((user_day) => {
          var start = moment(),
            end = moment().add(90, "days"),
            day = user_day !== 7 ? user_day : 0;

          var result = [];
          var current = start.clone().day(day);

          if (current.isSameOrAfter(start)) {
            result.push(current.clone());
          }

          while (current.day(7 + day).isBefore(end)) {
            result.push(current.clone());
          }

          result.map((res) => {
            console.log(
              "test date:",
              start.format("YYYY-MM-DD") === res.format("YYYY-MM-DD"),
              start === res
            );
            // console.log({ doctors }, { user_day }, i, doctors[i].from[user_day]);
            if (doctors[i]?.from?.[user_day]) {
              doctors[i].from[user_day].map((time, index) => {
                let valid = true;
                if (start.format("YYYY-MM-DD") === res.format("YYYY-MM-DD")) {
                  if (
                    moment() > moment(doctors[i].to[user_day][index], "h:mm a")
                  ) {
                    valid = false;
                  }
                }

                // check if slot end time is not greater than current time(temporary check)
                if (valid) {
                  calendarEvents.push({
                    id: this.createEventId(),
                    title: "",
                    start:
                      res.format("YYYY-MM-DD") +
                      "T" +
                      moment(doctors[i].from[user_day][index], "h:mm a").format(
                        "H:mm"
                      ),
                    end:
                      res.format("YYYY-MM-DD") +
                      "T" +
                      moment(doctors[i].to[user_day][index], "h:mm a").format(
                        "H:mm"
                      ),
                    data: {
                      // Delete Body
                      uid: doctors[i].uid,
                      firstName: doctors[i].firstName,
                      lastName: doctors[i].lastName,
                      days: [user_day],
                      from: {
                        [user_day]: [doctors[i].from[user_day][index]],
                      },
                      to: {
                        [user_day]: [doctors[i].to[user_day][index]],
                      },
                      date: res,
                    },
                  });
                }
              });
            }
          });
        });
        console.log({ calendarEvents });
        this.setState({
          calendar: calendarEvents,
          loading: false,
        });
      }
    }
  }

  handleDateSelect(selectInfo) {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  }

  renderEventContent = (eventInfo) => {
    console.log("renderEventContent", eventInfo);
    let data = eventInfo?.event?._def?.extendedProps?.data,
      day = data.days[0],
      date = data.date;
    const popover = (
      <Popover id="popover-basic" style={{ minWidth: "200px", zIndex: 99999 }}>
        <Popover.Title as="h5" style={{ margin: 0 }}>
          {/* {eventInfo.event.title} */}
          <>{moment(date).format("dddd, MMMM Do YYYY")}</>
        </Popover.Title>
        <Popover.Content>
          <Row className="mb-2">
            <Col>
              <b>Doctor: </b>
              {data.firstName} {data.lastName}
            </Col>
          </Row>
          <Row>
            <Col lg="5">
              <b>From:</b> {data.from[day][0]}
              <br />
              <b>To:</b> {data.to[day][0]}
            </Col>
            <Col lg="7">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.openDeleteModal(data);
                }}
                className="btn btn-outline-danger btn-sm  px-3 text-sm"
              >
                Delete Schedule
              </button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
    console.log("renderEventContent2");
    return (
      <OverlayTrigger
        placement="left"
        overlay={popover}
        trigger="click"
        rootClose
      >
        <div className="hide-overflow" style={{ width: "100%" }}>
          <span className="fc-event-title">
            {data.from[day][0]} - {data.to[day][0]}
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  onChange = (e) => {
    let formObj = { ...this.state.editedRecep };
    formObj[e.target.name] = e.target.value;
    this.setState({
      editedRecep: formObj,
    });
    if (e.target.name == "mobile") {
      var pattern = /^[0-9]{8,15}$/;
      let isValid = pattern.test(e.target.value);
      this.setState({
        validContact: isValid
      });

    }
  };

  editedReceptionists(value) {
    if (!this.state.validContact) {
      Notify({
        alert: true,
        type: "error",
        title: "Invalid Mobile Number",
      });
      return
    }
    let data = {
      ...value,
    };
    delete data.formIsValid;
    delete data.formData;
    console.log("Edited recep:::", data);
    let API = new Apilib();
    API.updateUser(JSON.stringify(data)).then((result) => {
     let res = result.data
     this.componentDidMount();
        this.setState({
          loading: false,
          receptionists: [],
          modalOpen: false,
          editedRecep: [],
        });
       
        Notify({
          alert: true,
          type: "success",
          title: "Receptionist Updated Successfully",
        });
   
    });
  }
  deleteReceptionists(value) {
    let data = {
      type: 4,
      uid: value.uid,
    };
    console.log("Edited recep:::", data);
    let API = new Apilib();
    this.setState({
      loading: true,
    });
    API.deleteReceptionist(JSON.stringify(data)).then((result) => {
       let res = result.data
        Notify({
          title: "Receptionist deleted successfully",
          alert: true,
          type: "success",
        });
        this.setState({
          loading: false,
          receptionists: [],
          deleteModal: false,
          editedRecep: [],
        });
        this.componentDidMount();
    
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleOpen(e, index) {
    e.preventDefault();
    console.log("selected doctor", this.state.receptionists[index]);
    this.setState({
      modalOpen: true,
      editedRecep: this.state.receptionists[index],
    });
  }
  handleOpens(e, index) {
    e.preventDefault();
    console.log("selected doctor", this.state.receptionists[index]);
    this.setState({
      openModal: true,
      editedRecep: this.state.receptionists[index],
    });
  }
  handleClose() {
    this.setState({
      modalOpen: false,
      openModal: false,
    });
  }
  handleCloses() {
    this.setState({
      openModal: false,
    });
  }
  hideAlerts() {
    setTimeout(() => {
      this.setState({
        deleted: false,
      });
    }, 5000);
  }

  handleDelete(e) {
    let data = {
      ...e,
      type: 3,
    };
    delete data.date;
    console.log(data);
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.deleteSchedule(JSON.stringify(data))
      .then((res) => {
        if (res.ok) {
          let API = new Apilib();
          API.getUserDetails(this.state.userData.type, this.state.userData.uid)
                  .then((result) => {
        let res = result.data

              console.log("userrrrr", res.user);
              Notify({
                type: "success",
                alert: true,
                title: "Schedule deleted successfully",
              });
              this.setState(
                {
                  isDeleteModalOpen: false,
                  loading: false,
                  // deleted: true,
                  userData: res.user,
                },
                () => {
                  this.props.updateUser(res.user);
                  this.resetState();
                }
              );
              if (this.props?.type == 4) {
                this.fetchDoctors();
              }
            })
            .catch((error) => {
              console.log(error);
              Notify({
                type: "error",
                alert: true,
                title: "Failed to delete schedule",
              });
            });
        } else {
          res.text().then((data) => {
            this.setState(
              {
                loading: false,
                // error: JSON.parse(data).message,
              },
              () =>
                Notify({
                  type: "error",
                  alert: true,
                  title: "Failed to delete schedule",
                })
            );
          });
        }
        this.hideAlerts();
      })
      .catch((error) => {
        this.hideAlerts();
        console.log(error);
      });
  }

  render() {
    let daysTxt = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let doctors = [];
    doctors.push({ value: 1, label: "All Professionals" });
    if (this.state.doctors.length) {
      this.state.doctors.map((item, i) => {
        doctors.push({
          ...item,
          value: item.uid,
          label: item.firstName + " " + item.lastName,
        });
      });
    }

    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          {this.state.deleted && (
            <div className="alert alert-success fade show" role="alert">
              Schedule deleted successfully
            </div>
          )}
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Receptionists</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link
                to="/hospital/add-receptionist"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Receptionist
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email Address</th>
                      <th>Mobile No</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.receptionists &&
                      this.state.receptionists.map((receptionist, index) => (
                        <tr key={index}>
                          <td className="full_name">
                            <a
                              href="#"
                              onClick={(e) => this.handleOpens(e, index)}
                            >
                              {receptionist.firstName}
                            </a>
                          </td>
                          <td>{receptionist.lastName}</td>
                          <td>{receptionist.email}</td>
                          <td>{receptionist.mobile}</td>
                          <td>
                            <button
                              onClick={(e) => {
                                this.handleOpen(e);
                                this.setState({
                                  editedRecep: receptionist,
                                });
                              }}
                              className="btn btn-small btn-secondary mr-2"
                            >
                              <span
                                class="iconify"
                                data-icon="akar-icons:edit"
                                data-inline="false"
                                style={{
                                  marginRight: ".5em",
                                  marginTop: "-.1em",
                                  display: "inline-block",
                                }}
                              ></span>
                              Edit
                            </button>
                            <button
                              onClick={(e) => {
                                this.setState({
                                  deleteModal: true,
                                  editedRecep: receptionist,
                                });
                              }}
                              className="btn btn-danger btn-small mr-2"
                            >
                              <span
                                class="iconify"
                                data-icon="ant-design:delete-twotone"
                                data-inline="false"
                                style={{
                                  marginRight: ".5em",
                                  marginTop: "-.1em",
                                  display: "inline-block",
                                }}
                              ></span>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="row mt-5">
            <h4 className="page-title">Schedule of Professionals</h4>
          </div> */}
          {/* <div className="row mt-3">
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Doctor <span className="text-danger">*</span>
                </label>
                <Select
                  required
                  value={this.state.userData}
                  onChange={(item) => this.onSelect(item, "doctorId")}
                  options={doctors}
                />
              </div>
            </div>
          </div> */}
          {/* 
          <div className="row mb-5">
            <div className="full-calendar">
              <div className="full-calendar-main">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  initialView="timeGridWeek"
                  editable={false}
                  selectable={false}
                  selectMirror={true}
                  dayMaxEvents={2}
                  weekends={true}
                  initialEvents={[]}
                  select={this.handleDateSelect}
                  eventContent={this.renderEventContent}
                  events={this.state.calendar}
                  contentHeight="auto"
                />
              </div>
            </div>
          </div> */}

          <Modal
            backdrop="static"
            show={this.state.openModal}
            onHide={() => this.handleClose()}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>View Receptionist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      value={this.state.editedRecep.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      value={this.state.editedRecep.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      readOnly
                      value={this.state.editedRecep.email}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                    <input
                      name="mobile"
                      value={this.state.editedRecep.mobile}
                      onChange={(e) => this.onChange(e)}
                      required
                      readOnly
                      className="form-control"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            backdrop="static"
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Receptionist Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      value={this.state.editedRecep.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      value={this.state.editedRecep.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      readOnly
                      value={this.state.editedRecep.email}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                    <input
                      name="mobile"
                      value={this.state.editedRecep.mobile}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="number"
                      pattern="^[0-9]{8,}$"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Conatct no should be minimum 8 digits"
                          );
                        }
                      }}
                    />
                    <div style={{ color: "red" }}>
                      {
                        this.state.validContact ? null : 'Invalid Mobile No.'
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleClose()}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => this.editedReceptionists(this.state.editedRecep)}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delete Modal */}
          <Modal
            backdrop="static"
            show={this.state.deleteModal}
            onHide={() =>
              this.setState({
                deleteModal: false,
              })
            }
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Receptionist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                Are you sure you want delete receptionist{" "}
                <b>{`${this.state.editedRecep.firstName} ${this.state.editedRecep.lastName}`}</b>
                ?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({
                    deleteModal: false,
                  })
                }
              >
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => this.deleteReceptionists(this.state.editedRecep)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            backdrop="static"
            show={this.state.isDeleteModalOpen}
            onHide={this.closeDeleteModal}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                Are you sure you want delete schedule of{" "}
                <b>{daysTxt[+this.state.deleteEvent?.days[0] - 1]}</b>{" "}
                <b>{`${this.state.deleteEvent?.from[
                  this.state.deleteEvent?.days[0]
                ][0]
                  } to ${this.state.deleteEvent?.to[this.state.deleteEvent?.days[0]][0]
                  }`}</b>
                ?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDeleteModal}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(this.state.deleteEvent)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
