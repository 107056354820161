import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo1.png'
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import Apilib from "../../api";
import { useLocation, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

// Dummy data for the invoice
// const invoiceData = {
//     companyIcon: 'company_icon_url',
//     invoiceNumber: 'INV-123',
//     date: '2023-07-18',
//     customer: {
//         name: 'John Doe',
//         address: '123 Main Street, City',
//     },
//     pharmacy: {
//         name: 'Example Pharmacy',
//         address: '456 Pharmacy Avenue, City',
//     },
//     items: [
//         { name: 'Item 1', description: 'Item 1 description', quantity: 2, price: 10, total: 20 },
//         { name: 'Item 2', description: 'Item 2 description', quantity: 1, price: 15, total: 15 },
//     ],
//     subTotal: 35,
//     tax: 5,
//     total: 40,
// };

const h2 = styled.h2`
  align-items: center;
  @media (max-width: 100%) {
  }
`;
const SectionHeading = styled.h3`
  color: #000;
`;
const P = styled.p`
  color: #111;
  margin-bottom:3px;
`;



const InvoiceTableWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
`;

const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: 100px;
  }

  /* Apply additional styles for smaller screens */
  @media (max-width: 768px) {
    th,
    td {
      font-size: 14px;
      white-space: nowrap;
    }
  
    tr {
      display: block;
      margin-bottom: 0.625em;
    }
  
    th,
    td::before {
      content: attr(data-label);
      display: inline-block;
      font-weight: bold;
      width: 100px;
    }
  
    td {
      display: block;
      text-align: right;
      position: relative;
      padding-left: 100px;
    }
  
    td::before {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 0;
      padding-left: 4px;
    }
  }
`;
const CompanyIcon = () => {
    return (
        <div>
            {/* Replace with company logo or image */}
            <img style={{ width: '150px', height: '50px' }} src={logo} alt="Company Logo" />
        </div>
    );
};

const InvoiceHeader = ({ invoiceData }) => {
    return (
        <div style={{ width: '100%', textAlign: 'end' }}>
            <h1>Invoice</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ border: 'solid #39D5FF 4px', height: '3px', width: '50%', borderRadius: '3px' }}></div>
                <P>Invoice Number: {invoiceData.invoiceNumber}</P>
                <P>Date: {invoiceData.date}</P>
            </div>
        </div>
    );
};

const CustomerDetails = ({ invoiceData }) => {
    const { customer } = invoiceData;
    return (
        <div style={{ textAlign: 'end' }}>
            <SectionHeading>Invoice To:</SectionHeading>
            <P>Patient name: {customer.name}</P>
            <P>Addr. :{customer.address}</P>
        </div>
    );
};

const PharmacyDetails = ({ invoiceData }) => {
    const { pharmacy } = invoiceData;
    return (
        <div style={{ margin: '1px' }}>
            <SectionHeading>Invoice From:</SectionHeading>
            <P>Pharmacy Name: {pharmacy.name}</P>
            <P>Pharmacy Address: {pharmacy.address}</P>
        </div>
    );
};

// const InvoiceTable = () => {
//     return (
//         <table style={{ textAlign: 'center' }}>
//             <thead >
//                 <tr style={{ borderRadius: '5px', backgroundColor: '#81e2fc' }}>
//                     <th >Item</th>
//                     <th>Item Description</th>
//                     <th>Qty</th>
//                     <th>Price</th>
//                     <th>Total</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {invoiceData.items.map((item, index) => (
//                     <tr key={index} style={{ borderBottom: 'solid #aeb3b5 .5px' }}>
//                         <td>{item.name}</td>
//                         <td>{item.description}</td>
//                         <td>{item.quantity}</td>
//                         <td>{item.price}</td>
//                         <td>{item.total}</td>
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     );
// };

const InvoiceTotals = ({ invoiceData }) => {
    const { subTotal, tax, total } = invoiceData;
    return (
        <div style={{ textAlign: 'end' }}>
            <P>Sub Total: {subTotal}</P>
            <P>Tax: {tax}</P>
            <hr />
            <SectionHeading>Total: {total}</SectionHeading>
        </div>
    );
};

const Medicines = (route) => {
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [items, setitems] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(route, 'this is here route')
    const location = useLocation();


    let invoiceId = location.state

    let [invoiceData, setinvoiceData] = useState(null)
    let [medicines, setmedicines] = useState(null)
    useEffect(() => {
        let API = new Apilib();

        let data = {
            "pharmacyId": route.userData.uid,
        }

        console.log(data)

        API.getAllMedicines(data)
            .then(async (res) => {
                console.log(res)
                console.log('this is response')

                // setinvoiceData(invoiceDataDb)
                setmedicines(res.data.medicines[0].medicines)
            })
            .catch((error) => {
                console.log('error', error)
            });
        console.log('useeffect executed')
    }, []);



    return (
        <div >
            <img style={{ width: '150px', height: '50px', position: 'absolute' }} src={logo} alt="Company Logo" />
            {medicines && <div style={{ backgroundColor: '#f1faff', padding: '5%', borderRadius: '15px' }}>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {"<InvoiceHeader invoiceData={'invoiceData'} />"}
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>

                    {/* <PharmacyDetails invoiceData={invoiceData} />
                    <CustomerDetails invoiceData={invoiceData} /> */}
                </div>
                {/* <InvoiceTable />
             */}

                <InvoiceTableWrapper>
                    {/* <InvoiceTable>
                        <thead>
                            <tr>
                                <th>Medicine Name</th>
                                <th>Batch Number</th>
                                <th>MfgDate</th>
                                <th>ExpDate</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Dose</th>
                                <th>Units</th>
                            </tr>
                        </thead>
                        <tbody>
                            {medicines.map((item, index) => (
                                <tr key={index} style={{ cursor: 'pointer' }} onClick={() => {
                                    console.log('name', item.Medicine_Name)
                                    history.push({ pathname: 'EditMedicine', state: { Medicine_Name: item, index } })
                                }}>
                                    <td>{item.Medicine_Name}</td>
                                    <td>{item.Batch_Number}</td>
                                    <td>{moment(item.MfgDate).format("DD-MM-YYYY")}</td>
                                    <td>{moment(item.ExpDate).format("DD-MM-YYYY")}</td>
                                    <td>{item.Price}</td>
                                    <td>{item.Quantity}</td>
                                    <td>{item.Dose}</td>
                                    <td>{item.Units}</td>
                                </tr>
                            ))}
                        </tbody>


                    </InvoiceTable> */}

                    <div style={{ display: 'flex', width: '100%', }}>
                        <div style={{ width: '25%', padding: '5px', fontSize: '18px', fontWeight: '600', color: '#000' }}>Medicine Name</div>
                        <div style={{ width: '18%', padding: '5px', textAlign: 'center', fontSize: '18px', fontWeight: '600', color: '#000' }}>Batch Number</div>
                        <div style={{ width: '16%', padding: '5px', textAlign: 'center', fontSize: '18px', fontWeight: '600', color: '#000' }}>ExpDate</div>
                        <div style={{ width: '16%', padding: '5px', textAlign: 'center', fontSize: '18px', fontWeight: '600', color: '#000' }}>Quantity</div>
                    </div>
                    {medicines.map((item, index) => (
                        <div key={index} style={{ cursor: '-moz-grab', display: 'flex', width: '100%', padding: '2px', }} >

                            <div style={{ width: '25%', padding: '5px', }}>{item.Medicine_Name}</div>
                            <div style={{ width: '18%', padding: '5px', textAlign: 'center' }}>{item.Batch_Number}</div>
                            <div style={{ width: '16%', padding: '5px', textAlign: 'center' }}>{moment(item.ExpDate).format("DD-MM-YYYY")}</div>
                            <div style={{ width: '16%', padding: '5px', textAlign: 'center' }}>{item.Quantity}</div>

                            <div style={{ width: '11%', padding: '5px', textAlign: 'center', borderRadius: '8px', backgroundColor: '#81e2fc', margin: '3px', cursor: 'pointer', }} onClick={() => {
                                console.log('name', item.Medicine_Name)
                                // history.push({ pathname: 'EditMedicine', state: { Medicine_Name: item, index } })
                                setitems(item)
                                handleShow()

                            }}>View</div>
                            <div style={{ width: '11%', padding: '5px', textAlign: 'center', borderRadius: '8px', backgroundColor: '#81e2fc', margin: '3px', cursor: 'pointer', }} onClick={() => {
                                console.log('name', item.Medicine_Name)
                                history.push({ pathname: 'EditMedicine', state: { Medicine_Name: item, index } })
                            }}>Edit</div>
                        </div>
                    ))}

                </InvoiceTableWrapper>
            </div>}


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{items && <div>{items.Medicine_Name}</div>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {items && <div>


                        <div style={{ fontSize: '18px', padding: '10px' }}>Batch Number: {items.Batch_Number}</div>
                        <div style={{ fontSize: '18px', padding: '10px' }}>MfgDate: {moment(items.MfgDate).format("DD-MM-YYYY")}</div>
                        <div style={{ fontSize: '18px', padding: '10px' }}>ExpDate: {moment(items.ExpDate).format("DD-MM-YYYY")}</div>
                        <div style={{ fontSize: '18px', padding: '10px' }}>Price: {items.Price}</div>
                        <div style={{ fontSize: '18px', padding: '10px' }}>Quantity: {items.Quantity}</div>
                        <div style={{ fontSize: '18px', padding: '10px' }}>Dose: {items.Dose}</div>
                        <div style={{ fontSize: '18px', padding: '10px' }}>Units: {items.Units}</div>

                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default Medicines;