import React, { Component } from "react";
import BaseSelect from "react-select";
import Apilib from "../../api";
import { storage } from "../../firebase";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import { customAlphabet } from "nanoid";
import { isValid } from "date-fns";
import GoogleMap from "../Agora/utils/GoogleMap";

import { Input } from "@material-ui/core";
import "./Admin.css";
let allowedImages = ".jpg,.png,.jpeg";
let allowedCert = ".pdf";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class AddHospital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: "",
      success: false,
      form_data: {},
      geoLocation: "",
      contact_nos: 1,
      country: "",
      state: "",
      citiy: "",
      validContact: true,
      selectedFiles: [],
      hospital_chains: [],
      seletedChain: null,
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      auth_token: "",
      inputFileData: [],
      fileExtError: "",
      fileSizeError: "",
      inputFileData_pdf: [],
      fileExtError_pdf: "",
      fileSizeError_pdf: "",
      isGST: false,
      imageArr: [],
      imageNameArr: [],
    };
  }

  componentDidMount() {
    let API = new Apilib();

    API.getCountries()
      .then((result) => {
        let res = result.data

        // this.setState({
        //   auth_token: "",
        //   countries: res.data.map((country) => ({
        //     country_name: country.name,
        //   })),
        // });
        API.viewHospitalChain()
          .then((result) => {
            let res = result.data

            this.setState({
              hospital_chains: res.hospitals,
              loading: false,
            });
          });
      });

    // API.getCountries().then(res => res.json()).then(res => {
    //     this.setState({
    //         countries:res,
    //         loading:false
    //     })
    // });
  }

  onSelectChain = (val) => {
    let formObj = { ...this.state.form_data };
    formObj["hospital_chain"] = val.label;
    formObj["hospitalChainId"] = val.value;
    this.setState({
      form_data: formObj,
      selectedChain: val,
    });
  };

  onSelectCountry = (val) => {
    let formObj = { ...this.state.form_data };
    formObj["country"] = val.value;
    this.setState(
      {
        form_data: formObj,
        selectedCountry: val,
      },
      () => {
        let API = new Apilib();
        this.setState({
          loading: true,
          selectedState: null,
          selectedCity: null,
        });
        API.getStates(val.value)
          .then((result) => {
            let res = result.data

            this.setState({
              states: res.data.states.map((state) => ({
                state_name: state.name,
              })),
              loading: false,
            });
          });
      }
    );
  };

  onSelectState = (val) => {
    let formObj = { ...this.state.form_data };
    formObj["state"] = val.value;
    this.setState(
      {
        form_data: formObj,
        selectedState: val,
      },
      () => {
        let API = new Apilib();
        this.setState({
          loading: true,
          selectedCity: null,
        });
        API.getCities(this.state.selectedCountry.value, val.value)
          .then((result) => {
            let res = result.data

            this.setState({
              cities: res.data.map((city) => ({ city_name: city })),
              loading: false,
            });
          });
      }
    );
  };

  // onSelectCity = (val) => {
  //   let formObj = { ...this.state.form_data };
  //   formObj["city"] = val.value;
  //   this.setState({
  //     form_data: formObj,
  //     selectedCity: val,
  //   });
  // };

  fetchStates() { }

  onChange = (e) => {
    e.preventDefault();
    let form_data = { ...this.state.form_data };
    if (e.target.name == "emergency_contact") {
      var pattern = /^[0-9]{8,15}$/;
      let isValid = pattern.test(e.target.value);
      this.setState({
        validContact: isValid,
      });
    }
    form_data[e.target.name] = e.target.value;
    this.setState({
      form_data,
    });
  };
  // setData = (event, inputName) => {
  //   const updatedForm = { ...formData };
  //   if (inputName == "country") {
  //     console.log("statevalue", updatedForm.state.value);
  //     updatedForm.state.value = "";
  //   }
  //   const updatedFormElement = { ...updatedForm[inputName] };
  //   updatedForm[inputName] = updatedFormElement;
  //   updatedFormElement.value = event;
  //   updatedForm[inputName].valid = checkValidity(
  //     updatedForm[inputName].value,
  //     updatedForm[inputName].validation
  //   );
  //   setFormData(updatedForm);
  // };

  validateFileSize(file) {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      this.setState(
        {
          fileSizeError: "Sorry, Max filesize allowed is 5MB",
        },
        () => {
          return false;
        }
      );
    } else {
      return true;
    }
  }

  validateFileSize_pdf(file) {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      this.setState(
        {
          fileSizeError_pdf: "Sorry, Max filesize allowed is 5MB",
        },
        () => {
          return false;
        }
      );
    } else {
      return true;
    }
  }

  validateFileExtension(file) {
    var _validFileExtensions = allowedImages.split(",");
    var sFileName = file.name;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        this.setState(
          {
            fileExtError:
              sFileName +
              " is not supported; acceptable formats to add Hospital image is " +
              _validFileExtensions.join(", "),
          },
          () => {
            return false;
          }
        );
      } else {
        return true;
      }
    }
  }

  validateFileExtension_pdf(file) {
    var _validFileExtensions = allowedCert.split(",");
    var sFileName = file.name;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        this.setState(
          {
            fileExtError_pdf:
              sFileName +
              " is not supported; acceptable formats to upload certificate is " +
              _validFileExtensions.join(", "),
          },
          () => {
            return false;
          }
        );
      } else {
        return true;
      }
    }
  }

  handleUploadImage(e, id) {
    e.preventDefault();
    const files = document.getElementById(id).files;
    console.log("Imageupload ", files, e.target.file);
    // let files = e.target.files;
    const formData = new FormData();
    this.setState({
      fileExtError: "",
      fileSizeError: "",
    });
    let AllFiles = [];
    for (let file of files) {
      AllFiles.push(file);
    }

    if (this.state.fileExtError == "" && this.state.fileSizeError == "") {
      this.setState({
        inputFileData: AllFiles,
      });
    }
  }

  handleUploadCert(e) {
    // console.log(e.target.files[0]);
    let files = e.target.files[0];
    const formData = new FormData();
    let fileArr = [];
    this.setState({
      fileExtError_pdf: "",
      fileSizeError_pdf: "",
    });
    for (let i = 0; i < files.length; i++) {
      if (this.validateFileExtension_pdf(files[i])) {
        // if (this.validateFileSize_pdf(files[i])) {
        //   // fileArr.push(file);
        //   formData.append(`pdf[${i}]`, files[i]);
        // }
        formData.append(`pdf[${i}]`, files[i]);
      }
    }
    if (
      this.state.fileExtError_pdf == "" &&
      this.state.fileSizeError_pdf == ""
    ) {
      this.setState({
        inputFileData_pdf: files,
      });
    }
  }

  uploadAllImages(hospitalId) {
    if (
      this.state.fileExtError == "" &&
      this.state.fileSizeError == "" &&
      this.state.inputFileData !== ""
    ) {
      let chainId = this.state.form_data.hospitalChainId;
      let files = this.state.inputFileData;
      console.log("files", files);
      const promises = [];
      this.setState(
        {
          loading: true,
        },
        () => {
          files.forEach((file, i) => {
            const uploadTask = storage
              .ref(`/${chainId}/${hospitalId}/hospitalimages/${file.name}`)
              .put(file);
            promises.push(uploadTask);
            uploadTask.on(
              "state_changed",
              (snapShot) => {
                console.log(snapShot);
              },
              (err) => {
                console.log(err);
              },
              () => { }
            );
          });
          Promise.all(promises)
            .then(() => {
              this.setState({
                loading: false,
                success: true,
                form_data: {},
                inputFileData: [],
              });
            })
            .catch((err) => {
              this.setState({
                loading: false,
                success: true,
                form_data: {},
              });
              console.log(err.code);
            });
        }
      );
    }
  }

  uploadCert(hospitalId) {
    if (
      this.state.fileExtError_pdf == "" &&
      this.state.fileSizeError_pdf == "" &&
      this.state.inputFileData_pdf !== ""
    ) {
      let chainId = this.state.form_data.hospitalChainId;
      let files = this.state.inputFileData_pdf;
      const promises = [];
      this.setState(
        {
          loading: true,
        },
        () => {
          files.forEach((file, i) => {
            const uploadTask = storage
              .ref(`/${chainId}/${hospitalId}/certificates/${file.name}`)
              .put(file);
            promises.push(uploadTask);
            uploadTask.on(
              "state_changed",
              (snapShot) => {
                console.log(snapShot);
              },
              (err) => {
                console.log(err);
              },
              () => { }
            );
          });
          Promise.all(promises)
            .then(() => {
              this.setState({
                loading: false,
                success: true,
                form_data: {},
                inputFileData_pdf: [],
              });
            })
            .catch((err) => {
              this.setState({
                loading: false,
                success: true,
                form_data: {},
              });
              console.log(err.code);
            });
        }
      );
    }
  }

  onChangeContact = (e) => {
    e.preventDefault();
    let form_data = { ...this.state.form_data };
    let contact = form_data.contact || "";
    if (contact) {
      contact += "," + e.target.value;
    } else {
      contact = e.target.value;
    }
    form_data[e.target.name] = e.target.value;
    this.setState({
      form_data,
    });
  };

  addContactField(e) {
    e.preventDefault();
    this.setState({
      contact_nos: this.state.contact_nos + 1,
    });
  }

  removeContact(e, index) {
    console.log(index);
    e.preventDefault();
    let form_data = { ...this.state.form_data };
    let contact = form_data["contact_" + (index + 1)] || "";
    if (contact) {
      if (index == 1) {
        let value = form_data["contact_3"] || "";
        form_data["contact_2"] = value;
        delete form_data["contact_3"];
      } else if (index == 2) {
        delete form_data["contact_3"];
      }
      this.setState({
        form_data,
        contact_nos: this.state.contact_nos - 1,
      });
    } else {
      this.setState({
        contact_nos: this.state.contact_nos - 1,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    // if (!(this.state.country && this.state.state)) {
    //   Notify({
    //     alert: true,
    //     type: "error",
    //     title: "Fill all required fields",
    //   });
    //   return;
    // }
    if (!this.state.validContact) {
      Notify({
        alert: true,
        type: "error",
        title: "Invalid Contact Number",
      });
      return;
    }
    this.setState({
      error: "",
      loading: true,
    });

    const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
    let API = new Apilib();
    let data = {
      ...this.state.form_data,
      type: 2,
      uid: this.props.user.uid,
      password: nanoid(),
      contact_nos: this.state.contact_nos,
      state: this.state.state,
      country: this.state.country,
      // created_at : Date.parse(new Date())
      // time: new Date().parse
    };
    data.geoLocation = this.state.geoLocation;
    console.log("data", data);
    API.addHospital(
      JSON.stringify({
        hospital_chain_id: this.state.form_data.hospitalChainId,
        name: this.state.form_data.hospital_name,

        created_at: Date.parse(new Date()),
      })
    )
      .then((result) => {
        let res = result.data

        this.setState({
          loading: false,
        });
        if (res.code === 200) {
          // this.setState({
          //   success: true,
          //   form_data: {},
          // });
          let hospital_images = this.state.inputFileData.map((file) => {
            return `/${this.state.form_data.hospitalChainId}/${res.hospital_id}/hospitalimages/${file.name}`;
          });
          let hospital_cerificate = `/${this.state.form_data.hospitalChainId}/${res.hospital_id}/certificates/${this.state.inputFileData_pdf.name}`;
          data.hospital_code = res.hospital_code;
          data.hospitalId = res.hospital_id;
          data.hospital_images = hospital_images;
          data.hospital_cerificate = hospital_cerificate;
          API.create(JSON.stringify(data))
            .then((result) => {
              let res = result.data

              if (
                this.state.inputFileData !== [] &&
                this.state.fileSizeError == "" &&
                this.state.fileExtError == ""
              ) {
                console.log("-----Uploading Images---------");
                this.uploadAllImages(data.hospitalId);
              } else {
                this.setState({
                  loading: false,
                  success: true,
                  form_data: {},
                });
              }
              if (
                this.state.inputFileData_pdf !== [] &&
                this.state.fileSizeError_pdf == "" &&
                this.state.fileExtError_pdf == ""
              ) {
                console.log("-----Uploading Cert---------");
                this.uploadCert(data.hospitalId);
              } else {
                this.setState({
                  loading: false,
                  success: true,
                  form_data: {},
                });
              }
              this.props.history.push("/admin/hospitals");
              Notify({
                alert: true,
                type: "success",
                title: "Hospital added successfully",
              });
              // document.getElementById("imageInput").value = "";
              // document.getElementById("certInput").value = "";
            });

          // window.history.back();
        } else {
          res.text().then((data) => {
            this.setState({
              loading: false,
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.code == "auth/email-already-exists") {
          this.setState({
            error: error.response.data.message,
            loading: false,
          })
          Notify({
            type: "error",
            alert: true,
            title: "An account with same email is already present, please use other email to create an account",
          })
        }
        else this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  // File Handler
  fileHandler = (imageFile, type) => {
    let err;
    let fileName = [...this.state.imageNameArr];
    let count = 1;
    if (imageFile) {
      if (type == "multiple") {
        for (let img of imageFile) {
          if (!img.name.match(/\.(pdf)$/)) {
            err = "Please select valid PDF.";
          } else if (img.size / 1000 > 5000) {
            err = "PDF size should be less than 5mb.";
          }

          if (err) {
            Notify({
              alert: true,
              type: "error",
              title: err,
            });
            return;
          }
          fileName.push(img.name);
          // arr.push(String(Date.now() + count++));  //unique name for pdf file
        }
        this.setState({ imageArr: [...imageFile] });
        this.setState({ imageNameArr: fileName });
        console.log("fileName", fileName);
      } else {
        // if (!imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPEG|JPG)$/)) {
        //   err = "Please select valid image.";
        // } else if (imageFile.size / 1000 > 5000) {
        //   err = "Image size should be less than 5mb.";
        // }
        // if (err) {
        //   Notify({
        //     alert: true,
        //     type: "error",
        //     title: err,
        //   });
        //   return;
        // }
        // console.log("setimage..", imageFile);
        // const objectUrl = URL.createObjectURL(imageFile);
        // setPreview(objectUrl);
        // setImg(imageFile);
      }
    }
    // console.log("fileHandler", imageFile);
    // console.log("image description", image);
  };

  handleDelete(i) {
    let arr = this.state.inputFileData;

    arr.splice(i, 1);
    this.setState({ inputFileData: [...arr] });
  }

  render() {
    console.log("img", this.state.inputFileData_pdf);
    let chains = [];
    if (this.state.hospital_chains.length) {
      this.state.hospital_chains.map((item, i) => {
        chains.push({
          value: item.id,
          label: item.chain_name,
        });
      });
    }
    // let countries = [];
    // if (this.state.countries.length) {
    //   this.state.countries.map((item, i) => {
    //     countries.push({
    //       value: item.country_name,
    //       label: item.country_name,
    //     });
    //   });
    // }
    // let states = [];
    // if (this.state.states.length) {
    //   this.state.states.map((item, i) => {
    //     states.push({
    //       value: item.state_name,
    //       label: item.state_name,
    //     });
    //   });
    // }
    // let cities = [];
    // if (this.state.cities.length) {
    //   this.state.cities.map((item, i) => {
    //     cities.push({
    //       value: item.city_name,
    //       label: item.city_name,
    //     });
    //   });
    // }
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Add Hospital</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={(e) => this.onSubmit(e)}>
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Hospital added successfully
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Hospital Chain Name<span className="text-danger">*</span>
                    </label>
                    {/* <input className="form-control" required name="hospital_chain" onChange={(e) => this.onChange(e)} type="text" value={this.state.form_data.hospital_chain || ''} /> */}
                    <Select
                      required
                      value={this.state.selectedChain}
                      onChange={(item) => this.onSelectChain(item)}
                      options={chains}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Hospital Name<span className="text-danger">*</span>
                    </label>
                    <input
                      onInvalid="setCustomValidity('Please enter Alphabets.')"
                      className="form-control"
                      required
                      name="hospital_name"
                      onChange={(e) => this.onChange(e)}
                      type="text"
                      value={this.state.form_data.hospital_name || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Hospital Registration Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      required
                      name="hospital_regno"
                      onChange={(e) => this.onChange(e)}
                      type="text"
                      value={this.state.form_data.hospital_regno || ""}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <label>Gst number (Only for india hospital )</label>
                  </div>

                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onChange={() =>
                          this.setState({
                            isGST: true,
                          })
                        }
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        YES
                      </label>
                    </div>
                    <div style={{ marginLeft: "2%" }} class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onChange={() =>
                          this.setState({
                            isGST: false,
                          })
                        }
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        NO
                      </label>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "2%" }}
                    className={`form-group ${this.state.isGST ? "d-block" : "d-none"
                      }`}
                  >
                    <label>
                      Hospital GST Number<span className="text-danger">*</span>
                    </label>
                    <input
                      required={this.state.isGST}
                      pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                      className="form-control"
                      name="hospital_gst"
                      onChange={(e) => this.onChange(e)}
                      type="text"
                      value={this.state.form_data.hospital_gst || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Contact Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      pattern="^[0-9]{8,15}$"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Conatct no should be minimum 8 digits"
                          );
                        }
                      }}
                      className="form-control"
                      name="emergency_contact"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.form_data.emergency_contact || ""}
                    />
                    <div style={{ color: "red" }}>
                      {this.state.validContact
                        ? null
                        : "Invalid Contact Number"}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Country/State/City <span className="text-danger">*</span>
                    </label>
                    <GoogleMap
                      style={{ width: 100 }}
                      // setGeoLocation={(e) => {
                      //   this.setState({
                      //     geoLocation: { lat: e.lat, lng: e.lng },
                      //   });
                      // }}
                      // geoLocation={this.state.geoLocation}
                      value={this.state.geoLocation}
                      onChange={(e) =>
                        // this.setState({
                        //   geoLocation: this.state.address,
                        // })
                        console.log("aaa")
                      }
                      getAddress={(address) => {
                        this.setState(
                          {
                            geoLocation: address,
                          },
                          () => {
                            console.log("MApAddress", this.state.geoLocation);
                          }
                        );
                        console.log("GoogleMapsAddress", address);
                      }}
                    />
                    {/* <CountryDropdown
                      value={this.state.country}
                      className="form-control"
                      onChange={(val) => {
                        console.log(val);
                        this.setState({
                          country: val,
                        });
                      }}
                      defaultOptionLabel="Select Country"
                    /> */}
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      State <span className="text-danger">*</span>
                    </label>
                    <RegionDropdown
                      country={this.state.country}
                      className="form-control"
                      placeholder="select state"
                      value={this.state.state}
                      defaultOptionLabel="select state"
                      onChange={(val) => {
                        this.setState({
                          state: val,
                        });
                        console.log(val);
                      }}
                    />
                  </div>
                </div> */}
              </div>

              <div className="row">
                {/* <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      City<span className="text-danger">*</span>
                    </label>
                    <input
                      name="city"
                      type="text"
                      required
                      onChange={(e) => this.onChange(e)}
                      value={this.state.form_data.city || ""}
                      className="form-control"
                    />
                  </div>
                </div> */}

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Pincode<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      required
                      name="pincode"
                      onChange={(e) => this.onChange(e)}
                      type="text"
                      value={this.state.form_data.pincode || ""}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Address<span className="text-danger">*</span>
                    </label>
                    <textarea
                      name="address"
                      required
                      onChange={(e) => this.onChange(e)}
                      value={this.state.form_data.address || ""}
                      cols="12"
                      rows="4"
                      className="form-control"
                    >
                      {this.state.form_data.address || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Date<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      required
                      name="date"
                      onChange={(e) => this.onChange(e)}
                      type="date"
                      value={this.state.form_data.date || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Email Address<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      required
                      name="email"
                      onChange={(e) => this.onChange(e)}
                      type="email"
                      value={this.state.form_data.email || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label>Add Hospital Certificate</label>
                  <div className="upload-input">
                    <label class="custom-file-upload">
                      <input
                        type="file"
                        id="certInput"
                        // multiple={false}
                        onChange={(e) => this.handleUploadCert(e)}
                        accept={allowedCert}
                      />
                      Upload Certificate
                    </label>
                  </div>
                  <div className="ml-2 mt-2 mb-2 row">
                    <div className="mr-2 mt-1">
                      {" "}
                      {this.state.inputFileData_pdf.name}
                    </div>{" "}
                    {this.state.inputFileData_pdf.name && (
                      <button
                        type="button"
                        style={{
                          backgroundColor: "red",
                          marginLeft: 15,
                          color: "white",
                        }}
                        className="mb-1"
                        onClick={() => {
                          this.setState({ inputFileData_pdf: [] });
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                  {/* <div className="">
                      {this.state.inputFileData_pdf.map((file, i) => {
                        return (
                          <div className="ml-2 mt-2">
                            {i + 1}.{file.name}{" "}
                            {
                              <button
                                type="button"
                                style={{
                                  backgroundColor: "red",
                                  marginLeft: 15,
                                  color: "white",
                                }}
                                onClick={() => {
                                  this.handleDelete(i);
                                  // this.setState({ inputFileData_pdf: newarr });
                                }}
                              >
                                Delete
                              </button>
                            }
                          </div>
                        );
                      })}
                    </div> */}
                  <div
                    style={{
                      backgroundColor: "#d4edda",
                      width: "320px",
                      borderColor: "#c3e6cb",
                    }}
                  >
                    <span>Supported format is .pdf</span>
                  </div>
                  {this.state.fileExtError_pdf && (
                    <div
                      className="alert alert-danger mt-2 fade show"
                      role="alert"
                    >
                      {this.state.fileExtError_pdf}
                    </div>
                  )}
                  {this.state.fileSizeError_pdf && (
                    <div
                      className="alert alert-danger mt-2 fade show"
                      role="alert"
                    >
                      {this.state.fileSizeError_pdf}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  {this.state.contact_nos < 3 ? (
                    <button
                      type="button"
                      onClick={(e) => this.addContactField(e)}
                      className="btn btn-primary"
                    >
                      Add More Contact
                    </button>
                  ) : null}
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-6" style={{ marginTop: "15px" }}>
                  {/* <img id="blah" src="#" alt="your image" /> */}
                  <div className="form-group mt-2">
                    <label>Add Hospital Images</label>

                    <div className="upload-input">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          id="imageInput"
                          multiple
                          accept="image/*"
                          // multiple={true}
                          onChange={(e) =>
                            this.handleUploadImage(e, "imageInput")
                          }
                        />
                        Upload Images
                      </label>
                    </div>
                    <div className="">
                      {this.state.inputFileData.map((file, i) => {
                        return (
                          <div className="ml-2 mt-2">
                            {i + 1}.{file.name}{" "}
                            {
                              <button
                                type="button"
                                style={{
                                  backgroundColor: "red",
                                  marginLeft: 15,
                                  color: "white",
                                }}
                                onClick={() => {
                                  this.handleDelete(i);
                                  // this.setState({ inputFileData: newarr });
                                }}
                              >
                                Delete
                              </button>
                            }
                          </div>
                        );
                      })}
                    </div>
                    {console.log("FormData", this.state)}
                    <div
                      style={{
                        backgroundColor: "#d4edda",
                        width: "320px",
                        borderColor: "#c3e6cb",
                      }}
                    >
                      <span>Supported format is .jpg, or .png, or .jpeg</span>
                    </div>
                    {this.state.fileExtError && (
                      <div
                        className="alert alert-danger mt-2 fade show"
                        role="alert"
                      >
                        {this.state.fileExtError}
                      </div>
                    )}
                    {this.state.fileSizeError && (
                      <div
                        className="alert alert-danger mt-2 fade show"
                        role="alert"
                      >
                        {this.state.fileSizeError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  {[...Array(this.state.contact_nos)].map((item, index) => (
                    <div key={index} className="form-group">
                      <div className="d-flex flex-row justify-content-start aligh-items-center">
                        <div>
                          <label>Contact Number {index + 1}</label>
                          {index > 0 ? (
                            <button
                              onClick={(e) => this.removeContact(e, index)}
                              type="button"
                              className="ml-2 mb-2 btn btn-danger btn-sm py-0 px-1"
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          ) : null}
                        </div>
                        <div>
                          {index == 0 ? (
                            <>
                              <div className="row">
                                <div
                                  style={{
                                    marginBottom: "10%",
                                    marginLeft: "5%",
                                  }}
                                  className="col-md-6"
                                >
                                  {this.state.contact_nos < 3 ? (
                                    <button
                                      type="button"
                                      onClick={(e) => this.addContactField(e)}
                                      className="btn btn-primary"
                                    >
                                      Add More Contact
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      <input
                        pattern="^[0-9]{8,}$"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity(
                              "Conatct no should be minimum 8 digits"
                            );
                          }
                        }}
                        className="form-control"
                        name={`contact_${index + 1}`}
                        onChange={(e) => this.onChange(e)}
                        type="text"
                        value={
                          this.state.form_data[`contact_${index + 1}`] || ""
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  {this.state.contact_nos < 3 ? (
                    <button
                      type="button"
                      onClick={(e) => this.addContactField(e)}
                      className="btn btn-primary"
                    >
                      Add More Contact
                    </button>
                  ) : null}
                </div>
              </div> */}
              <div className="m-t-20 text-center">
                <button type="submit" className="btn btn-primary submit-btn">
                  Add Hospital
                </button>
              </div>
              {/* {this.state.selectedCity ? (
                <div className="m-t-20 text-center">
                  <button type="submit" className="btn btn-primary submit-btn">
                    Add Hospital
                  </button>
                </div>
              ) : null} */}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
