import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import { Formik } from "formik";
import "jquery/dist/jquery.min.js";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { default as Api, default as Apilib } from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";

export default class AddHospitalChain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      success: false,
      chain_name: "",
      hospitalChains: [],
      modalLoading: false,
      selectedChain: { chain_name: "", id: "" },
      modalOpen: false,
      validationError: "",

      confirmModelLoading: false,
      confirmModalOpen: false,
    };
  }
  getHospitalChains = () => {

    this.setState({ loading: true });
    const API = new Api();
    API.viewHospitalChain()
      .then((result) => {
        let res = result.data
        let sorttData = [];
        sorttData = res.hospitals;
        sorttData.sort((a, b) => b.created_at - a.created_at);
        this.setState({ hospitalChains: sorttData, loading: false });
      })

      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      })
  };

  componentDidMount() {
    this.getHospitalChains();
  }
  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  handleChainNameSubmit = (formikValues, formikHelpers) => {
    let baseState = { ...this.state };
    this.setState({
      error: "",
      loading: true,
    });
    let API = new Apilib();
    let data = {
      chain_name: formikValues.chain_name,
    };
    API.addHospitalChain(JSON.stringify(data))
      .then((result) => {
        let res = result.data
        console.log("ghghgh")
        this.setState({
          loading: false,
        });
        this.setState({
          chain_name: "",
        });
        Notify({
          type: "success",
          alert: true,
          title: "Hospital Chain added successfully",
        });
        formikHelpers.resetForm();
        this.getHospitalChains();

      })
      .catch((error) => {
        this.setState(
          {
            loading: false,
          },
          () =>
            Notify({
              type: "error",
              alert: true,
              title: error.message,
            })
        );
      });
  };
  // deletedUser = (value) => {
  //   this.setState({
  //     loading: true,
  //   });
  //   let API = new Apilib();
  //   API.deleteAdmin(JSON.stringify({ type: value.type, uid: value.uid })).then(
  //     async (res) => {
  //       if (res.ok) {
  //         let MSG = await res.json();
  //         Notify({
  //           alert: true,
  //           type: "success",
  //           title: MSG.msg,
  //         });
  //         this.setState({
  //           show: false,
  //         });

  // componentDidMount(); {
  //   this.getHospitalChains();
  // };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Add Hospital Chain</h4>
          </div>
        </div>
        {this.state.error && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            {this.state.error}
          </div>
        )}
        {this.state.success && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            Hospital Chain added successfully
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <Formik
              validationSchema={Yup.object().shape({
                chain_name: Yup.string()
                  .required()
                  .min(3, "Chain name should be of minimum 3 characters")
                  .label("Hospital Chain Name")
                  .trim(),
              })}
              onSubmit={this.handleChainNameSubmit}
              initialValues={{ chain_name: "" }}
            >
              {({ handleSubmit, values, errors, handleChange, setErrors }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>
                      Hospital Chain Name
                      <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          name="chain_name"
                          value={values.chain_name}
                          onChange={handleChange("chain_name")}
                          type="text"
                          onBlur={() => {
                            if (!!values.chain_name === false)
                              setErrors({ chain_name: "" });
                          }}
                          placeholder="Enter Hospital Chain Name"
                        />
                        <small className="form-text text-danger">
                          {errors?.chain_name}
                        </small>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-left pt-2 pt-sm-0">
                          <button className="btn btn-primary submit-btn">
                            Create Hospital Chain
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6 form-group d-flex align-items-end"></div> */}
                </form>
              )}
            </Formik>
          </div>
          {!this.state.loading && (
            <Table
              tableOptions={{
                order: [],
                columnDefs: [{ orderable: false, targets: 1, width: "140px" }],
              }}
              columns={["Hospital Chain", "Action"]}
              rows={this.state.hospitalChains.map((chain) => (
                <tr>
                  <td>{chain.chain_name}</td>
                  <td classname="row" style={{ width: "20%" }}>
                    <button
                      onClick={() => {
                        this.setState({
                          modalOpen: true,
                          selectedChain: chain,
                        });
                      }}
                      className="btn btn-small btn-secondary mr-2"
                    >
                      <span
                        class="iconify"
                        data-icon="akar-icons:edit"
                        data-inline="false"
                        style={{
                          marginRight: ".5em",
                          marginTop: "-.1em",
                          display: "inline-block",
                        }}
                      ></span>
                      Edit
                    </button>
                    {/* <button
                      //  disabled={this.props.userData.uid == user.uid}
                      //  onClick={() => {
                      //    this.setState({
                      //      show: true,
                      //      deletedUser: user,
                      //    });
                      //  }}
                      type="button"
                      class="btn btn-danger"
                      onClick={() => {
                        this.deleteHospitalChains();
                      }}
                      className="btn btn-small btn-danger mr-2"
                    >
                      <span
                        style={{
                          marginRight: ".5em",
                          marginTop: "-.1em",
                          display: "inline-block",
                        }}
                        class="iconify"
                        data-icon="ant-design:delete-twotone"
                        data-inline="false"
                      ></span>
                      Delete
                    </button> */}
                  </td>
                </tr>
              ))}
            />
          )}

          <Modal
            backdrop="static"
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>Hospital Chain Update</Modal.Title>
            </Modal.Header>
            <Formik
              validationSchema={Yup.object().shape({
                chain_name: Yup.string()
                  .required()
                  .min(3, "Chain name should be of minimum 3 characters")
                  .label("Hospital Chain Name")
                  .trim(),
              })}
              onSubmit={(values) => {
                let baseState = values;
                this.setState({
                  error: "",
                  loading: true,
                });
                let API = new Apilib();
                let data = {
                  chain_name: baseState.chain_name,
                  chain_id: baseState.id,
                };
                API.editHospitalChain(JSON.stringify(data))
                  .then((result) => {
                    let res = result.data
                    this.setState({
                      loading: false,
                    });
                    console.log("chain update response", res);
                    Notify({
                      alert: true,
                      type: "success",
                      title: "Hospital chain updated successfully ",
                    });
                    this.handleClose();
                    this.getHospitalChains();
                  })
                  .catch((error) => {
                    console.log("update error", error);
                    this.setState({
                      loading: false,
                    });
                    Notify({
                      alert: true,
                      type: "error",
                      title: error.message,
                    });
                  });
              }}
              initialValues={{ ...this.state.selectedChain }}
            >
              {({
                handleSubmit,
                setFieldValue,
                values,
                errors,
                handleChange,
                dirty,
              }) => (
                <>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            name="name"
                            value={values.chain_name}
                            required
                            onChange={(e) =>
                              setFieldValue(
                                "chain_name",
                                e.target.value.toUpperCase()
                              )
                            }
                            type="text"
                            placeholder="Enter New Name"
                          />
                          <span className="form-text text-danger">
                            {errors.chain_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        this.handleClose();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={!dirty}
                    >
                      Update
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </Modal>

          {/* delete modal */}
          <Modal
            backdrop="static"
            show={this.state.confirmModalOpen}
            onHide={() => this.handleConfirmModalClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>Hospital Chain Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <h5>Are you sure you want to delete?</h5>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.handleClose();
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  let baseState = { ...this.state.selectedChain };
                  this.setState({
                    error: "",
                    loading: true,
                  });
                  let API = new Apilib();
                  let data = {
                    chain_name: baseState.chain_name,
                    chain_id: baseState.id,
                  };
                  API.editHospitalChain(JSON.stringify(data))
                    .then((res) => {
                      this.setState({
                        loading: false,
                      });

                      Notify({
                        alert: true,
                        type: "success",
                        title: "Update successful",
                      });
                      this.handleClose();
                      this.getHospitalChains();
                    })
                    .catch((error) => {
                      this.setState({
                        loading: false,
                      });
                      Notify({
                        alert: true,
                        type: "error",
                        title: error.message,
                      });
                    });
                }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
