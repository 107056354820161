import React, { useState } from "react";
import './StarRating.css'
import Modal from "react-bootstrap/Modal";
import Apilib from "../../api";


const styless = {
    inputBoxStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '0.469rem 0.75rem',
        // border-color: #eaeaea;
        fontSize: '14px',
        minHeight: '35px',
        minWidth: '50%',
        width: '90%',
        margin: '10px'
    },
}

const StarRating = (route) => {
    console.log(route)
    const [rating, setRating] = useState(route.stars);
    const [Review, setReview] = useState('');
    const [prefModalOpen, setprefModalOpen] = useState(false);
    let hovers = route.hover
    const [hover, setHover] = useState(0);
    const [items, setitems] = useState(JSON.parse(localStorage.getItem('productDetails')) ? JSON.parse(localStorage.getItem('productDetails')) : null)
    const setRatingFun = (index) => {
        if (hovers) setprefModalOpen(true)
        if (hovers) setRating(index)
        console.log(items)

    }

    return (
        <div className="star-rating">
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <button
                        type="button"
                        key={index}
                        className={index <= (hover || rating) ? "on" : "off"}
                        onClick={() => setRatingFun(index)}
                        onMouseEnter={() => hovers ? setHover(index) : null}
                        onMouseLeave={() => hovers ? setHover(rating) : null}
                    >
                        <span className="star" style={{ fontSize: '25px' }}>&#9733;</span>
                    </button>
                );
            })}


            <Modal
                backdrop="static"
                show={prefModalOpen}
                onHide={() => {
                    setprefModalOpen(false);
                    // rejector(new Error("No preference chosen"));
                    // createPharmacyMedicineReview
                    // rating
                    // Review
                    // {}



                }}
                // onHide={handleClose}
                keyboard={false}
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Rating</Modal.Title>
                </Modal.Header>
                <div className="preference-buttons">

                    <div style={{ fontSize: '13px', textAlign: 'left' }}>Write about product</div>
                    <input
                        // className="form-control col-sm-5 row"
                        name="Review"
                        placeholder={'Review'}
                        value={Review}
                        onChange={e => setReview(e.target.value)}
                        style={styless.inputBoxStyle}
                    />

                    <button
                        className="btn btn-primary preference-btn account-btn"
                        onClick={() => {
                            setprefModalOpen(false)

                            let API = new Apilib();

                            // let pharmacyId = body.pharmacyId;
                            // const batchNumber = body.medicine.Batch_Number
                            // const medicineName = body.medicine.Medicine_Name
                            // const uid = body.comment.uid
                            // const comment = body.comment.comment
                            // const rating = body.comment.rating
                            // const name = body.comment.name
                            // const createdAt = Date.now()

                            let obj = {
                                pharmacyId: items.pharmacyId,
                                medicine: items,
                                comment: {
                                    uid: route.uid,
                                    comment: Review,
                                    rating: rating,
                                    name: route.name
                                }
                            }
                            API.createPharmacyMedicineReview(obj)
                                .then(async (res) => {
                                    console.log(res)
                                    let ml = []
                                    if (!res.data.orders.length) return
                                    res.data.orders.map(x => {
                                        let obj = x.prescription.find(o => {
                                            if (o.Medicine_Name === items.Medicine_Name && o.Batch_Number === items.Batch_Number) return o
                                        });

                                        if (obj) setisProductBought(true)
                                    })
                                })
                                .catch((error) => {
                                    console.log('error', error)
                                });


                            alert('Thank you for providing review about the product')
                        }}
                        style={{ marginRight: "10px" }}
                    >
                        Add Review
                    </button>
                </div>
            </Modal >


        </div>
    );
};
export default StarRating;