import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: "",

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 19.0,
        lng: 72.83,
      },
    };
  }

  handleChange = (address) => {
    this.setState({ address });
    this.props.getAddress(address);
  };

  handleSelect = (address) => {
    this.setState({ address });
    this.props.getAddress(address);
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Map Success", latLng, address);
        let data = {
          "longitude": latLng.lng,
          "latitude": latLng.lat
        }
        // update center state
        this.setState({ mapCenter: latLng });
        this.props.setGeoLocation(data);
      })
      .catch((error) => console.error("Map Error", error));
  };


  componentDidMount(prevProp, prevState) {
    console.log("Prev", prevState, prevProp)
    this.setState({
      address: this.props.value
    })
    return
    if (
      prevState.newSpeciality !== this.state.newSpeciality ||
      prevState.email !== this.state.email
    ) {
      let valid = !!this.state.newSpeciality && !!this.state.email
      this.setState({
        addSpecialityIsValid: valid,
      });
    }
  }

  // onMapClicked = (mapProps, map, e) => {
  //   this.props.setGeoLocation({
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng(),
  //   });
  // };


  render() {

    console.log("Locations", this.state.address)
    return (
      <div id="googleMaps">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
            >
              <input
                placeholder={this.props.placeholder}
                onChange={(e) => console.log("test", e)}
                className="form-control"
                {...getInputProps({


                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDxRcxxNJ1vbm2eLoznyYF-Bh3hud_5ZeM",
})(MapContainer);