// jshint esversion:6

import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import * as Datetime from "react-datetime";
import BaseSelect from "react-select";
import moment from "moment";
import Notify from "../../Notify";
import "./style.css";

const options = [
  { value: 1, label: "1 - One" },
  { value: 2, label: "2 - Two" },
  { value: 3, label: "3 - Three" },
];
const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const API = new Apilib();

const AddAppointmentP = (props) => {
  const [mainLoading, setMainLoading] = useState(false);
  const [doctorData, setDoctorData] = useState();
  const [formData, setFormData] = useState({
    doctorId: "",
    doctorName: "",
    hospitalId: "",
    slot_date: moment().format("DD/MM/YYYY"),
    slot_time: "",
    appointmentType: 1,
  });
  const [appointmentType, setAppointmentType] = useState(null);
  const [appointmentpayment, setAppointmentpayment] = useState(null);
  const [slot_date, setSlot_date] = useState(moment().format("DD/MM/YYYY"));
  const [slot_time, setSlot_time] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [slots, setSlots] = useState({});
  const [selectedSlots, setSelectedSlots] = useState([]);
  console.log("props-->", props);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && props.userData && props.type) {
      } else {
        props.history.push("/login");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    console.log(props.history);
    if (!props.location.state) {
      props.history.goBack();
    } else {
      setMainLoading(true);
      const data = props.location.state.item;
      let type = 3;
      let uid = data.uid;
      API.getUserDetails(type, uid)
        .then((result) => {
          let res = result.data

          console.log(res);
          setMainLoading(false);
          data.hospitalId = res.user.hospitalId;
          setDoctorData(res.user);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (doctorData && props.type && props.userData) {
      setMainLoading(true);
      fetchSchedule();
      let doctor = { ...doctorData };
      const data = { ...formData };
      API.getUserDetails(doctor.type, doctor.uid)
        .then((result) => {
          let res = result.data

          console.log(res);
          setMainLoading(false);
          data.hospitalId = res.user.hospitalId;
        });
      data.name = props.userData.firstName + " " + props.userData.lastName;
      data.doctorId = doctor.uid;
      data.doctorName = doctor.firstName + " " + doctor.lastName;
      data.slot_time = slot_time;
      data.appointmentType = appointmentType;
      data.userType = props.type;
      data.userId = props.userData.uid;
      data.patientId = props.userData.uid;
      data.email = props.userData.email;
      data.base_fee = doctor.base_fee;
      data.slot_duration = doctor.slot_duration
      // data.basefee = doctor.basefee;
      data.doctor_email = doctor.email;
      data.for_family_member = false;
      data.patient_email = props.userData.email;
      data.phoneno = props.userData.phoneno;
      data.country_code = props.userData.country_code;
      console.log(doctor.slot_duration, 'slot_duration');
      data.from = moment(
        formData.slot_date + " " + formData.slot_time,
        "DD/MM/YYYY h:mm a"
      ).valueOf();
      data.to =
        moment(
          formData.slot_date + " " + formData.slot_time,
          "DD/MM/YYYY h:mm a"
        ).valueOf() +
        slot_time.split(":")[0] * 60000;
      console.log("all data", data, doctor);
      setFormData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorData]);

  console.log("setFormData", formData);
  const fetchSchedule = () => {
    // let from = moment().valueOf();
    // let to = moment().add(30,'days').valueOf();
    let aestTimeTo = new Date().toLocaleString("en-US", {
      timeZone: "Australia/Brisbane",
    });
    //"10/1/2020, 5:42:38 PM"
    let from = moment(aestTimeTo, "M/D/YYYY, H:mm:ss a").valueOf();
    from = from / 1000;
    // let from = 1598929783;
    let to = "";

    setMainLoading(true);
    API.listSchedule(doctorData.uid, from, to)
      .then((result) => {
        let res = result.data
        console.log(result, "this is resp once");
        if (res.code == 200) {
          setMainLoading(false);
          console.log();
          setSlots(res);
        } else if (res.code == 202) {
          setMainLoading(false);
          setError("No schedule available");
        }
      })
      .catch((error) => {
        console.log(error);
        setMainLoading(false);
        // if (this._isMounted) {
        //   this.setState({
        //     mainLoading: false,
        //   });
        // }
      });
  };

  // console.log(this.props.location.state.item)
  const appointment_types = [
    { value: "1", label: "Physical" },
    { value: "2", label: "Video Conference" },
  ];
  const appointment_payment = [
    { value: "1", label: "Cash" },
    { value: "2", label: "Card" },
  ];

  const onDateSelect = (value) => {
    console.log("onDateselect", Date.parse(value._d));

    let formObj = { ...formData };
    formObj["slot_date"] = value.format("DD/MM/YYYY");
    formObj["slot_time"] = null;
    setFormData(formObj);
    setSlot_time(null);
    setSlot_date(value.format("DD/MM/YYYY"));
  };

  let daysTxt = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let daysNum = [];
  if (slots.data) {
    slots.data.map((value) => {
      daysNum.push(daysTxt.indexOf(value.day));
    });
  }
  let yesterday = Datetime.moment().subtract(1, "day");
  let afterMonth = Datetime.moment().add(30, "years");
  let valid = function (current) {
    return (
      current.isAfter(yesterday) &&
      current.isBefore(afterMonth) &&
      daysNum.includes(current.day())
    );
  };


  const onSelect = (val, name) => {
    let formObj = { ...formData };
    formObj[name] = val.value;
    setFormData(formObj);
    if (name === "slot_time") {
      setSlot_time(val);
    }
    if (name === "appointmentType") {
      setAppointmentType(val);
    }
    if (name === "appointmentpayment") {
      setAppointmentpayment(val);
    }
    console.log("formdata", formObj, name + ":" + val.value);
  };
  useEffect(() => {
    const from = moment(formData.slot_date, "DD/MM/YYYY").valueOf();
    const to = moment(formData.slot_time, "h:mm a");
    console.log("from and to", from, to);
  }, [formData]);

  useEffect(() => {
    let slots1 = [];
    // setSelectedSlots(slots1);
    let slotsArr = [];
    if (slots.data) {
      slots.data.map((value, index) => {
        daysNum.push(daysTxt.indexOf(value.day) - 1);
      });
      slotsArr = slots.data.filter((value, map) => value.date == slot_date);
      console.log("slotsArr", slotsArr);
      if (slotsArr.length) {
        slotsArr[0].slots.map((value, index) => {
          console.log("Slots state :: Available", value);
          if (!value.booked) {
            slots1.push({ value: value.time, label: value.time });
          }
        });
        console.log("Slots state :: slots1", selectedSlots, slots1);
        setSelectedSlots(slots1);
      }
      console.log("Slots state ::", selectedSlots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slot_date]);

  const [formIsValid, setFormIsValid] = useState(false);
  useEffect(() => {
    if (formData) {
      let valid = [];
      for (let key in formData) {
        if (formData[key] == "") {
          valid.push(false);
        } else {
          valid.push(true);
        }
      }
      setFormIsValid(!valid.includes(false));
    }
  }, [formData]);

  const [sendRequest, setSendRequest] = useState(false);
  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (true) {
      setSendRequest(true);
    }
  };

  useEffect(() => {
    if (sendRequest) {

      setMainLoading(true);



      let data = {
        ...formData, created_at: Date.parse(new Date()),
        appointment_payment: appointmentpayment,
        from: moment(
          formData.slot_date + " " + formData.slot_time,
          "DD/MM/YYYY h:mm a"
        ).valueOf(),
        to: moment(
          formData.slot_date + " " + formData.slot_time,
          "DD/MM/YYYY h:mm a"
        )
          .add(doctorData.slot_duration.split(":")[0] < 12 ? doctorData.slot_duration.split(":")[0] * 60 : doctorData.slot_duration.split(":")[0], "minutes")
          .valueOf(),
      };
      console.log(doctorData.slot_duration.split(":")[0] < 12 ? doctorData.slot_duration.split(":")[0] * 60 : doctorData.slot_duration);
      console.log(data, 'this is data');
      API.addAppointment(JSON.stringify(data))
        .then(async (res) => {
          setSendRequest(false);
          console.log(res, 'this is response from saving');
          if (res.status) {
            setMainLoading(false);
            setAppointmentType(null);
            setSlot_date(moment().format("DD/MM/YYYY"));
            setSlot_time("");
            setError("");
            setFormIsValid(false);

            let appointmentId = res.data.data.appointmentId
            console.log(appointmentpayment, 'appointmentpaymentappointmentpayment');
            if (appointmentpayment.label == 'Card') {
              setMainLoading(true);

              let showModal = async (e) => {
                async function randomString(len, charSet) {
                  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                  var randomString = '';
                  for (var i = 0; i < len; i++) {
                    var randomPoz = Math.floor(Math.random() * charSet.length);
                    randomString += charSet.substring(randomPoz, randomPoz + 1);
                  }
                  return randomString;
                }
                let tempid = await randomString(12);
                let API = new Apilib();
                console.log('calling function to save payment id');
                // })
                try {

                  await API.saveDataToAppointment(JSON.stringify({
                    paymentId: tempid, appointmentId: appointmentId,
                  })
                  )
                } catch (e) {
                  console.log(e, 'this is error');
                }

                // e.preventDefault();
                // window.open
                var newWindow = window.open(
                  window.location.origin +
                  "/#/payment?id=" +
                  tempid +
                  "&amount=" +
                  doctorData.base_fee +
                  "&uid=" +
                  props.userData.uid,
                  "_blank"
                );
                newWindow.my_special_setting = false;
                setInterval(async () => {
                  let isIt = newWindow.my_special_setting
                  let API = new Apilib();
                  if (isIt === true) {
                    setMainLoading(false);
                    console.log('yes its working');
                    alert('payment successful')
                    // write databse manipulaion command
                  } else if (isIt == 'failed') {
                    setMainLoading(false);
                    alert('Payment failed')
                    console.log('yes no its working');
                    await API.getAppointmentDetails(appointmentId)
                      .then((result) => {
                        let res = result.data
                        console.log(res, 'tresure');
                        console.log(result, 'result');
                      })
                    // write databse manipulaion command
                    // alert('payment was not successful')
                  }
                  else {
                    console.log('nope some error in this');
                  }
                }, 5000);
              };
              await showModal()
              setMainLoading(false);
            }

            props.history.push("/my-appointments");
            Notify({
              alert: true,
              type: "success",
              title: "Appointment added successfully",
            });


          } else {
            res.text().then((data) => {
              setError(JSON.parse(data).message);
              Notify({
                alert: true,
                type: "error",
                title: JSON.parse(data).message,
              });
            });
          }
        })
        .then((err) => {
          setMainLoading(false);
          setSendRequest(false);
          console.log(err);
        }).catch(e => {
          setMainLoading(false)
          console.log('error in catch', e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  console.log("propssssss", props);

  return (
    <>
      <div className="main-content account-content">
        <div className="content">
          <div className="container">
            <div className="account-box">
              {mainLoading && <Loader />}
              <form
                onSubmit={formSubmitHandler}
                className="form-signin"
                action="#"
              >
                <div className="account-title">
                  <h3>Add Appointment</h3>
                </div>
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {error}
                  </div>
                )}
                {success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    Appointment added successfully
                  </div>
                )}
                <div className="form-group">
                  {/* <label htmlFor="email">Doctor Name</label>
                  <input
                    type="text"
                    id="docname"
                    className="form-control patient-apointment-fields"
                    autoFocus
                    required
                    value={formData.doctorName}
                    name="email"
                    // onChange={(e) => this.onChange(e)}
                  /> */}
                  <p>Doctor Name: {formData.doctorName}</p>
                </div>

                <div className="form-group">
                  <label>
                    Date <span className="text-danger">*</span>
                  </label>
                  {/* <input name="date" min={moment().format('YYYY-MM-DD')} value={this.state.date} onChange={(e) => this.onChange(e)} required className="form-control" type="date" /> */}
                  <Datetime
                    isValidDate={valid}
                    closeOnSelect={true}
                    dateFormat="DD/MM/YYYY"
                    onChange={(val) => onDateSelect(val)}
                    value={slot_date}
                    inputProps={{ required: true }}
                    input={true}
                    timeFormat={false}
                    viewMode="days"
                  />
                </div>
                <div className="form-group">
                  <div className="form-group">
                    <label>
                      Available Slots <span className="text-danger">*</span>
                    </label>
                    <Select
                      required
                      value={slot_time}
                      onChange={(item) => onSelect(item, "slot_time")}
                      options={selectedSlots}
                      placeholder={slots.length ? "Select a slot" : "No slots available"
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Appointment Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    required
                    value={appointmentType}
                    onChange={(val) => onSelect(val, "appointmentType")}
                    isSearchable={false}
                    options={appointment_types}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Payment Options <span className="text-danger">*</span>
                  </label>
                  <Select
                    required
                    value={appointmentpayment}
                    onChange={(val) => onSelect(val, "appointmentpayment")}
                    isSearchable={false}
                    options={appointment_payment}
                  />
                </div>
                {/* <div className="form-group text-right">
                  <a>Forgot your password?</a>
                </div> */}
                <div className="form-group text-center mt-50">
                  <button
                    type="submit"
                    //  disabled={!formIsValid}
                    className="btn btn-primary submit-btn account-btn"
                  >
                    Add Appointment
                  </button>
                </div>
                <div className="text-center register-link">
                  {/* <Link to={Register}> Register Now </Link> */}
                  {/* Register Now */}
                  <Link
                    to="/my-appointments"
                    className="login-signup-redirect-link"
                  >
                    {" "}
                    Back to Appointments List{" "}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAppointmentP;