import React, { Component } from "react";
import "./Loader.scss";
import loader from "./med_loader_xor.gif";
import splashLoader from "./med_loader.gif";

export default class Loader extends Component {
  render() {
    return (
      // <div className="loader-overlay">
      //   <div className="loader"></div>
      // </div>
      <div className="splashscreen">
        {/* <div className="loader"></div> */}
        <img className="" src={loader}></img>
      </div>
    );
  }
}

export class SplashLoader extends Component {
  render() {
    return (
      <div className="splashscreen">
        {/* <div className="loader"></div> */}
        <img className="splashscreen-size" src={splashLoader}></img>
      </div>
    );
  }
}
