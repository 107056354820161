import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

function Services(props) {
    const history = useHistory()
    return (
        <Div>
            <Div25>Services</Div25>
            <Div26>
                Discover a spectrum of premier healthcare services at Medvatic. From expert consultations, diagnostics, and personalized treatments to convenient medicine delivery, we are committed to providing comprehensive and compassionate care. Trust Medvatic for a seamless and holistic healthcare experience tailored to your well-being.
            </Div26>
            <Div27>
                <Div29 />
                <Div30 />
                <Div31 />
            </Div27>


            <Div33>
                <Div34>
                    {[
                        { link: 'Medicine', icon: 'medkit', text: 'Simplifying Medicine Orders', subtext: 'Experience effortless medication management with Medvatic. Simply place your medicine orders online, ensuring a quick and secure process. Our streamlined service ensures your prescribed medications are promptly delivered to your doorstep, prioritizing your health and convenience. Trust Medvatic for a hassle-free and reliable medicine ordering experience.', },
                        { link: 'ConsultDoctor', icon: 'bed', text: "Expert Consultations, Your Health Priority", subtext: "Connect with leading healthcare professionals at Medvatic for expert consultations tailored to your needs. Our consultant doctors prioritize your well-being, providing personalized medical advice and guidance. Experience virtual healthcare excellence with Medvatic's dedicated team of consultant doctors, ensuring your health journey is in capable hands.", },
                        { link: 'LabTest', icon: 'flask', text: 'Streamlined Lab Testing Services', subtext: "Simplify your health check-ups with Medvatic's efficient lab testing services. Schedule and undergo diagnostic tests seamlessly, ensuring timely and accurate results. Trust Medvatic to make your health monitoring journey convenient and reliable.", },
                    ].map(x => <Column>
                        <Div35>
                            <i class={`fa fa-${x.icon}`} aria-hidden="true"></i>
                            <Div36>{x.text}</Div36>
                            <Div37>
                                {x.subtext}
                            </Div37>
                            <i onClick={() => { history.push(`/${x.link}`) }} class="fa fa-arrow-right" aria-hidden="true"></i>
                        </Div35>
                    </Column>)}

                </Div34>
            </Div33>


            <Div33>
                <Div34>

                    {[
                        {
                            link: 'ConsultDoctor', icon: 'tint', text: 'Precision Pathology Services', subtext: "Rely on Medvatic for precise and comprehensive pathology services. Our advanced diagnostic facilities ensure accurate results, empowering you with valuable insights for informed healthcare decisions. Trust Medvatic's commitment to excellence in pathology for your well-being.",
                        },
                        {
                            link: 'ConsultDoctor', icon: 'user-md', text: "Surgery", subtext: "Experience surgical care at its finest with Medvatic. Our skilled surgeons blend expertise with compassion, ensuring a personalized and supportive journey throughout your surgical experience. Trust Medvatic for excellence in surgical care, where your health and comfort take center stage.",
                        },
                        { link: 'ConsultDoctor', icon: 'plus-circle', text: 'Comprehensive Healthcare Solutions', subtext: "Explore a range of healthcare services beyond the conventional with Medvatic. From preventive care to specialized treatments, we offer a diverse array of services designed to cater to your unique health needs. Trust Medvatic for a holistic approach to well-being that goes beyond expectations.", },
                    ].map(x => <Column>
                        <Div35>
                            <i class={`fa fa-${x.icon}`} aria-hidden="true"></i>
                            <Div36>{x.text}</Div36>
                            <Div37>
                                {x.subtext}
                            </Div37>
                            <i onClick={() => { history.push(`/${x.link}`) }} class="fa fa-arrow-right" aria-hidden="true"></i>
                        </Div35>
                    </Column>)}

                </Div34>
            </Div33>
        </Div>
    );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
const Div25 = styled.div`
  color: #313239;
  text-align: center;
  align-self: center;
  margin-top: 71px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div26 = styled.div`
  color: #525252;
  text-align: center;
  align-self: center;
  margin-top: 28px;
  max-width: 945px;
  font: 400 24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div27 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 26px;
  align-items: center;
  gap: 0px;
  padding: 0 20px;
`;

const Div28 = styled.div`
  background-color: #cacbcd;
  width: 139px;
  height: 1px;
  margin: auto 0;
`;

const Div29 = styled.div`
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 20px;
  height: 10px;
  flex-direction: column;
`;

const Div30 = styled.div`
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 10px;
  height: 10px;
  flex-direction: column;
`;

const Div31 = styled.div`
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 20px;
  height: 10px;
  flex-direction: column;
`;

const Div32 = styled.div`
  background-color: #cacbcd;
  width: 139px;
  height: 1px;
  margin: auto 0;
`;

const Div33 = styled.div`
  align-self: center;
  margin-top: 50px;
  width: 100%;
  max-width: 1410px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div34 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  border:solid 3px #5cdefe;
  border-radius:5px;
  border-bottom:0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div35 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 534px;
  flex-grow: 1;
  padding: 50px 25px;
  align-items:center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img10 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img11 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 123px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

const Div36 = styled.div`
  position: relative;
  color: #06235c;
  text-align: center;
  align-self: center;
  margin-top: 42px;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div37 = styled.div`
  position: relative;
  color: #5f5f5f;
  text-align: justify;
  margin: 31px 0 19px;
  font: 400 18px Roboto, sans-serif;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div38 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 534px;
  flex-grow: 1;
  padding: 50px 25px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img12 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img13 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 123px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

const Div39 = styled.div`
  position: relative;
  color: #06235c;
  text-align: center;
  align-self: center;
  margin-top: 42px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div40 = styled.div`
  position: relative;
  color: #5f5f5f;
  text-align: justify;
  margin: 31px 0 19px;
  font: 400 18px Roboto, sans-serif;
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div41 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 534px;
  flex-grow: 1;
  padding: 50px 25px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img14 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img15 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 123px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

const Div42 = styled.div`
  position: relative;
  color: #06235c;
  text-align: center;
  align-self: center;
  margin-top: 42px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div43 = styled.div`
  position: relative;
  color: #5f5f5f;
  text-align: justify;
  margin: 31px 0 19px;
  font: 400 18px Roboto, sans-serif;
`;

const Div44 = styled.div`
  align-self: center;
  z-index: 1;
  display: flex;
  margin-top: -35px;
  width: 1030px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Img16 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;

const Img17 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;

const Img18 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;

const Div45 = styled.div`
  align-self: center;
  margin-top: 100px;
  width: 100%;
  max-width: 1410px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div46 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Div47 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 534px;
  flex-grow: 1;
  padding: 50px 25px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img19 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img20 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 123px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

const Div48 = styled.div`
  position: relative;
  color: #06235c;
  text-align: center;
  align-self: center;
  margin-top: 42px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div49 = styled.div`
  position: relative;
  color: #5f5f5f;
  text-align: justify;
  margin: 23px 0 19px;
  font: 400 18px Roboto, sans-serif;
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div50 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 534px;
  flex-grow: 1;
  padding: 50px 25px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img21 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img22 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 123px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

const Div51 = styled.div`
  position: relative;
  color: #06235c;
  text-align: center;
  align-self: center;
  margin-top: 43px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div52 = styled.div`
  position: relative;
  color: #5f5f5f;
  text-align: justify;
  margin: 23px 0 19px;
  font: 400 18px Roboto, sans-serif;
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div53 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 534px;
  flex-grow: 1;
  padding: 50px 25px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img23 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img24 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 123px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

const Div54 = styled.div`
  position: relative;
  color: #06235c;
  text-align: center;
  align-self: center;
  margin-top: 42px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div55 = styled.div`
  position: relative;
  color: #5f5f5f;
  text-align: justify;
  margin: 31px 0 19px;
  font: 400 18px Roboto, sans-serif;
`;

const Div56 = styled.div`
  align-self: center;
  z-index: 1;
  display: flex;
  margin-top: -35px;
  width: 1030px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Img25 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;

const Img26 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;

const Img27 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;










const Div57 = styled.div`
  background-color: #f5f5f5;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 49px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div58 = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  max-width: 1404px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img28 = styled.img`
  aspect-ratio: 1.19;
  object-fit: contain;
  object-position: center;
  width: 170px;
  overflow: hidden;
  max-width: 100%;
`;

const Div59 = styled.div`
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div60 = styled.div`
  color: #2b2c2d;
  font: 400 20px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div61 = styled.div`
  color: #2b2540;
  margin-top: 25px;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div62 = styled.div`
  border-radius: 8px;
  box-shadow: 4px 6px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  align-self: center;
  display: flex;
  padding-left: 21px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-left: 20px;
  }
`;


export default Services;
