const Notify = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('showAlert', { detail: obj });
    window.dispatchEvent(evt);
}

export const updateData = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('updateData', { detail: obj });
    window.dispatchEvent(evt);
}

export const filterDropdown = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('filterDropdown', { detail: obj });
    window.dispatchEvent(evt);
}

export default Notify;