import React, { useState } from 'react';
import './QAComponent.css'; // Import your CSS file for styling if needed
import styled from 'styled-components';

const QAComponent = ({ question, answer }) => {
    const [isExpanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!isExpanded);
    };

    return (
        <div className="qa-container">
            {/* <div className="question" onClick={toggleExpansion}>
                {question}<i class="fa-solid fa-chevron-up"></i>
            </div> */}


            <Div124>
                <Div125 onClick={toggleExpansion}>
                    {question}<i style={{ fontSize: '16px' }} class={isExpanded ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                </Div125>
                {/* <Img28
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c1101bb2-e751-4d2a-8d1c-c1f41be4cd88?apiKey=04f85002d68744bd9623fe1bdb31055b&"
                /> */}
            </Div124>
            {isExpanded && (
                <div className="answer">
                    {answer}
                </div>
            )}
        </div>
    );
};

const Div125 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  

  display: flex;
  justify-content: space-between;



  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div79 = styled.div`
  color: #041b1f;
  align-self: stretch;
  white-space: nowrap;
  margin: 99px -20px 0 0;
  font: 700 36px/34px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
  }
`;
const Div124 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 19px 10px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export default QAComponent;