import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import prescriptionImg from '../../../src/assets/img/medical-prescription1.png';
import scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img from '../../../src/assets/img/prescription1.png';
import { Link } from "react-router-dom";
// import { Routes, Route, useNavigate } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom'
// import firebase from "firebase/app";
import { auth, storage } from "../../firebase";
import Apilib from "../../api";
import Notify from "../../Notify";



const Container = styled.div`
  align-items: center;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0px;
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  padding: 20px;
  width: 100%;
`;


const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 25%;
`;

const Form = styled.form`
  width: 100%;
  max-width: 95%;
  padding: 10px;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 20px;
  text-align: left;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #EAFCFF;
`;

const Containers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Div1 = styled.div`
  width: 35%;
`;

const Div2 = styled.div`
  width: 65%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: #333;
  margin-bottom: 10px;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: ${(props) => props.disabled ? 'red' : '#50d8fa'};
  pointer-events:${(props) => props.disabled ? 'none' : null};
`;


const AddDoctorDetailsPrescriber = (route) => {
  const [fullName, setFullName] = useState(!!route?.userData?.prescriberData?.fullName ? route.userData.prescriberData?.fullName : route.userData.firstName + ' ' + route.userData.lastName);
  const [includeDr, setIncludeDr] = useState(!!route?.userData?.prescriberData?.fullName ? route.userData.prescriberData?.includeDr : false);
  const [abbreviatedQualifications, setAbbreviatedQualifications] = useState(!!route?.userData?.prescriberData?.fullName ? route.userData.prescriberData?.abbreviatedQualifications : '');
  const [practiceName, setPracticeName] = useState(!!route?.userData?.prescriberData?.fullName ? route.userData.prescriberData?.practiceName : route.userData.lastName);
  const [streetAddress, setStreetAddress] = useState(!!route?.userData?.prescriberData?.fullName ? route.userData.prescriberData?.streetAddress : route.userData.geoLocation);
  const [prescriberNumber, setPrescriberNumber] = useState(!!route?.userData?.prescriberData?.fullName ? route.userData.prescriberData?.prescriberNumber : '');
  const [isPrescriberData, setisPrescriberData] = useState(false);
  // const navigate = useNavigate();
  const history = useHistory();
  // Handles input change event and updates state
  const [image, setImages] = useState(null);
  const [progress, setProgress] = useState(0);

  const profilePictureRef = React.createRef();

  useEffect(() => {
    const isPrescriberDataAvailable = () => {
      const isdata = !!route?.userData?.prescriberData?.fullName
      console.log('this is  which is true or false isdata', isdata)
      console.log(isdata)
      setisPrescriberData(isdata)
    }
    isPrescriberDataAvailable()
    return () => {
      isPrescriberDataAvailable
    };
  }, []);


  const handleImageChange = (e) => {
    e.preventDefault();
    console.log("event", e);
    // const ProductImg = e.target.files;
    const ProductImg = [...e.target.files];


    const images = ProductImg[0]
    // const images = ProductImg.map((image) => URL.createObjectURL(image));
    console.log("images", images);
    console.log("ProductImg", ProductImg);
    setImages(images);
    // handleUpload(e, images)
  };


  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleIncludeDrChange = (e) => {
    setIncludeDr(e.target.checked);
  };

  const handleAbbreviatedQualificationsChange = (e) => {
    setAbbreviatedQualifications(e.target.value);
  };

  const handlePracticeNameChange = (e) => {
    setPracticeName(e.target.value);
  };

  const handleStreetAddressChange = (e) => {
    setStreetAddress(e.target.value);
  };

  const handlePrescriberNumberChange = (e) => {
    setPrescriberNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission
    // ...
    console.log('saving this')
  };


  const onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img)
      });
    }
  };
  const reusableInputState = (label, valueVar, onChaneVar) => {
    return <Containers>
      <Div1>
        <Label>{label}</Label>
      </Div1>
      <Div2>
        <Input type="text" value={valueVar} onChange={onChaneVar} />
      </Div2>
    </Containers>
  }
  const reusableMenuState = (label, onClickHandleMethod, icon) => {
    return <SidebarOption onClick={() => onClickHandleMethod()}>
      <i className={icon}></i><SidebarOptionText>{label}</SidebarOptionText>
    </SidebarOption>
  }
  const onClickHandle = () => {
    console.log('hi there')
  }



  const handleUpload = (e, images) => {
    e.preventDefault();
    // const PP = profilePictureRef.current;
    // const imageData = PP.getData();
    // const file = imageData.file;
    // const imageAsDataURL = PP.getImageAsDataUrl(1);
    const imageAsDataURL = images
    // const imageAsDataURL = images[0];
    // if (PP.state.status == "EMPTY") {
    //   return
    // }
    // console.log("file", PP);

    // const ref = storage.ref();
    // fetch(filePath)
    //   .then(response => {
    //     return response.blob();
    //   })
    //   .then(blob => {
    const name = route.userData.uid;
    const storageRef = storage.ref().child('profile_images/Fdoctors/signature/' + name + '.png');
    storageRef.put(imageAsDataURL).then(snapshot => {

      console.log('Uploaded a blob or file!', snapshot);
    });
    // });
    // if (imageAsDataURL) {
    //   const name = '7q6GWjtjZ6bjiqjdgmaEN2boYRt1';
    //   // const name = userDetails.uid;

    //   const path = "profile_images/Fdoctors/signature" + name;
    //   // setpp_url(imageAsDataURL);
    //   const task = ref.child(path).putString(imageAsDataURL, "data_url");
    // task
    //   .then(async () => {
    //     let picDownloadLink = await makeDownloadUrl(path).catch(e => console.log(e));

    //     console.log('picDownloadLinkpicDownloadLinkpicDownloadLink')
    //     console.log(picDownloadLink)
    // setLoading(true);
    // setError("");
    // let API = new Apilib();
    // let data = {
    //   ...userDetails,
    //   consultation_reasons: formik.values.consultation_reasons,
    //   description: formik.values.description,
    //   language_spoken: formik.values.language_spoken,
    //   area_of_interest: formik.values.area_of_interest,
    //   profile_picture: picDownloadLink,
    //   // UNNECESSARY CODE
    //   // type: 4,
    //   // uid: props.user.uid,
    // };

    // API.updateUser(data)
    //   .then(async (res) => {
    //     setLoading(false);

    //     console.log('fine')
    //     console.log(res)
    //     console.log('fine')
    // if (res.status == 200) {
    //   setLoading(false);
    //   setChange_pp(false);
    //   // await makeDownloadUrl(path);
    //   Notify({
    //     alert: true,
    //     type: "success",
    //     title: "Profile picture uploaded successfully!",
    //   });
    // } else {
    //   console.log('in else part if error')
    //   res.text().then((data) => {
    //     setError(JSON.parse(data).message);
    //     setChange_pp(false);
    //     Notify({
    //       alert: true,
    //       type: "error",
    //       title: JSON.parse(data).message,
    //     });
    //   });
    // }
    // })
    // .catch((error) => {
    //   console.log('in else part if error catch')
    //   setError(error.message);
    //   setChange_pp(false);
    //   setLoading(false);
    // });


    // })
    // .catch((err) => {
    //   console.log('in else part if error catch another')
    //   setLoading(false);
    //   setChange_pp(false);
    //   Notify({
    //     alert: true,
    //     type: "error",
    //     title: err.message,
    //   });
    // });
    // }
  };


  return (
    <Container>

      <div style={{ display: 'flex' }}>
        {/* <div style={{ width: '20%' }}>

                    <Sidebar>
                        <SidebarHeading>Medvatic</SidebarHeading>

                        {reusableMenuState('Admin Dashboard', onClickHandle, "fa fa-address-card")}
                        {reusableMenuState('Doctor Dashboard', onClickHandle, "fa fa-user-plus")}
                        {reusableMenuState('Patient Dashboard', onClickHandle, "fa fa-home")}
                        {reusableMenuState('Pharmacist Dashboard', onClickHandle, "fa fa-home")}
                        {reusableMenuState('Analysis', onClickHandle, "fa fa-line-chart")}
                        {reusableMenuState('Appointment', onClickHandle, "fa fa-stethoscope")}
                        {reusableMenuState('Doctors', onClickHandle, "fa fa-user-md")}
                        {reusableMenuState('Patients', onClickHandle, "fa fa-male")}
                        {reusableMenuState('Prescription', onClickHandle, "fa fa-clipboard")}
                        {reusableMenuState('Service', onClickHandle, "fa fa-newspaper-o")}
                        {reusableMenuState('Department', onClickHandle, "fa fa-outdent")}
                        {reusableMenuState('Pharmacy', onClickHandle, "fa fa-medkit")}
                        {reusableMenuState('Finance', onClickHandle, "fa fa-money")}
                        {reusableMenuState('Support', onClickHandle, "fa fa-phone-square")}
                        {reusableMenuState('Chat', onClickHandle, "fa fa-comments-o")}
                        {reusableMenuState('Settings', onClickHandle, "fa fa-cog")}
                        {reusableMenuState('Logout', onClickHandle, "fa fa-sign-out")}

                    </Sidebar>
                </div> */}
        <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
          <Content>

            <ButtonGroup>
              {/* scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img */}
              <Button style={{ fontWeight: '400', fontSize: '21px' }}
                onClick={() => { console.log('new rx') }}
              >
                <img
                  src={prescription1Img}
                  alt="new"
                  style={{ width: '40px', height: '40px' }}
                />
                New Rx</Button>
              <Button style={{ fontWeight: '400', fontSize: '21px' }}

                onClick={() => { console.log('scripts') }}
              >

                <img
                  src={scriptImg}
                  alt="new"
                  style={{ width: '40px', height: '40px' }}
                />
                Scripts</Button>
              <Button style={{ backgroundColor: '#FF8E3C', fontWeight: '400', fontSize: '21px' }}
                onClick={() => {
                  // navigate('/contacts');
                  // navigate('/doctor/add-patient');

                  history.push(`/doctor/Prescribers`);
                  // <Redirect to="/doctor/add-patient" />
                  console.log('prescribers')
                }}


              >
                {/* <Link
                  to="/doctor/add-patient"
                > */}
                <img
                  src={prescriptionImg}
                  alt="new"
                  style={{ width: '40px', height: '40px' }}
                />
                Prescribers
                {/* </Link> */}

              </Button>
            </ButtonGroup>
            {/* <ButtonGroup2>
              <Label1>Prescriber Details</Label1>
              <ButtonNormal onClick={() => console.log('adding new prescriber')}>Add New prescriber</ButtonNormal>
            </ButtonGroup2> */}

            <Form style={{ borderWidth: '3px', borderColor: 'red', border: 'solid #ccc', margin: '30px', padding: '30px', width: '120%', backgroundColor: '#fff' }} >
              <Heading>Add Prescriber</Heading>
              <SubNote>Prescriber details will appear on your prescriptions</SubNote>
              <div style={{ borderBottom: '1px solid', marginBottom: '10px' }}></div>
              <div>
                <Heading>Prescriber details</Heading>
                {/* <Label>
                  Full Name:
                  <Input type="text" value={fullName} onChange={handleFullNameChange} />
                </Label>
                <Containers>
                  <Div1>
                    <Label>
                      Full Name:
                    </Label>
                  </Div1>
                  <Div2>
                    <Input type="text" value={fullName} onChange={handleFullNameChange} />
                  </Div2>
                </Containers> */}
                {reusableInputState('Full Name:', fullName, handleFullNameChange)}
                <CheckboxLabel>
                  <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                  Include Dr in prescriber name
                </CheckboxLabel>



                {/* <Label>
                  Street Address:
                  <Input type="text" value={streetAddress} onChange={handleStreetAddressChange} />
                </Label> */}
                {reusableInputState('Abbreviated Qualifications (optional):', abbreviatedQualifications, handleAbbreviatedQualificationsChange)}
                {reusableInputState('Practice Name (optional):', practiceName, handlePracticeNameChange)}
                {reusableInputState('Street Address:', streetAddress, handleStreetAddressChange)}
                {reusableInputState('Prescriber Number:', prescriberNumber, handlePrescriberNumberChange)}
                {/* <Label>
                  Prescriber Number:
                  <Input type="text" value={prescriberNumber} onChange={handlePrescriberNumberChange} />
                </Label> */}
              </div>

              <form>
                <p>
                  <label style={{ backgroundColor: '#50d8fa', padding: '15px', margin: '10px', borderRadius: '5px', color: '#fff' }} htmlFor="file">Upload Signature</label>
                  <input
                    ref={profilePictureRef}
                    type="file"
                    id="file"
                    onChange={handleImageChange}
                    accept="image/png, image/jpg, image/jpeg"
                  // multiple
                  />
                </p>
              </form>
              <label style={{ display: 'inline-flex' }}><label style={{ color: 'red' }}>*</label>Signature should be on white paper and in black ink and clear supported format JPEG,PNG</label>
              <label style={{ display: 'inline-flex' }}>Signature will be used for prescription signing, By uploading signature you agree to the terms and conditions of Medvatic</label>

            </Form>


            <div style={{ borderBottom: '1px solid', marginBottom: '10px' }}></div>

            <div style={{ justifyContent: 'space-evenly', display: 'flex', width: '100%' }}>

              <SubmitButton
                disabled={isPrescriberData}
                onClick={async () => {
                  let sigUpload = false
                  if (fullName == '' || abbreviatedQualifications == '' || practiceName == '' || streetAddress == '' || prescriberNumber == '') { alert('All the fields are required'); return }
                  else {


                    // const imageAsDataURL = image
                    // const imageAsDataURL = images[0];
                    // if (PP.state.status == "EMPTY") {
                    //   return
                    // }
                    // console.log("file", PP);

                    // const ref = storage.ref();
                    // fetch(filePath)
                    //   .then(response => {
                    //     return response.blob();
                    //   })
                    //   .then(blob => {
                    if (image == null) { alert('Please upload signature file'); return }
                    const name = route.userData.uid;
                    const storageRef = storage.ref().child('profile_images/Fdoctors/signature/' + name + '.png');
                    await storageRef.put(image).then(snapshot => {
                      sigUpload = true
                      console.log('Uploaded a blob or file!', snapshot);
                    }).catch((e) => {
                      sigUpload = false
                    })
                    if (sigUpload == false) { alert('Signature upload failed'); return }
                    let body = { prescriberData: { fullName: fullName, includeDr: includeDr, abbreviatedQualifications: abbreviatedQualifications, practiceName: practiceName, streetAddress: streetAddress, prescriberNumber: prescriberNumber, signatureLink: '', signature: sigUpload }, userId: route.userData.uid, userType: '3' }
                    console.log(
                      'fullName', fullName, 'includeDr', includeDr, 'abbreviatedQualifications', abbreviatedQualifications, 'practiceName', practiceName, 'streetAddress', streetAddress, 'prescriberNumber', prescriberNumber
                    )

                    let API = new Apilib();
                    // let data = {
                    //   ...userDetails,
                    //   consultation_reasons: formik.values.consultation_reasons,
                    //   description: formik.values.description,
                    //   language_spoken: formik.values.language_spoken,
                    //   area_of_interest: formik.values.area_of_interest,
                    //   profile_picture: picDownloadLink,
                    //   // UNNECESSARY CODE
                    //   // type: 4,
                    //   // uid: props.user.uid,
                    // };

                    API.updateUserPrescriber(body)
                      .then(async (res) => {
                        // setLoading(false);

                        console.log('fine')
                        console.log(res)
                        console.log('fine')
                        if (res.status == 200) {
                          // setLoading(false);
                          // setChange_pp(false);
                          // await makeDownloadUrl(path);
                          Notify({
                            alert: true,
                            type: "success",
                            title: "Prescriber data saved successfully!",
                          });
                          history.push(`/doctor/patients`);
                        } else {
                          console.log('in else part if error')
                          res.text().then((data) => {
                            // setError(JSON.parse(data).message);
                            // setChange_pp(false);
                            Notify({
                              alert: true,
                              type: "error",
                              title: JSON.parse(data).message,
                            });
                          });
                        }
                      })
                      .catch((error) => {
                        console.log('in else part if error catch', error)
                        // setError(error.message);
                        // setChange_pp(false);
                        // setLoading(false);
                      });
                  }

                }}>Add Prescriber</SubmitButton>
              <SubmitButton style={{ backgroundColor: '#c7c7c7' }} onClick={() => {
                console.log('exiting')
                // history.push(`/doctor/patients`);











                let body = { userId: route.userData.uid, userType: '3' }
                console.log(
                  'fullName cooooo'
                )

                // let API = new Apilib();
                // API.getDigitalSignature(body)
                //   .then(async (res) => {
                //     // setLoading(false);

                //     console.log('fine')
                //     console.log(res)
                //     console.log('fine')
                //     if (res.status == 200) {
                //       // setLoading(false);
                //       // setChange_pp(false);
                //       // await makeDownloadUrl(path);
                //       Notify({
                //         alert: true,
                //         type: "success",
                //         title: "Prescriber data saved successfully!",
                //       });
                //       // history.push(`/doctor/patients`);

                //       console.log('in else part if no error error')

                //     } else {
                //       console.log('in else part if error')
                //       res.text().then((data) => {
                //         // setError(JSON.parse(data).message);
                //         // setChange_pp(false);
                //         Notify({
                //           alert: true,
                //           type: "error",
                //           title: JSON.parse(data).message,
                //         });
                //       });
                //     }
                //   })
                //   .catch((error) => {
                //     console.log('in else part if error catch', error)
                //     // setError(error.message);
                //     // setChange_pp(false);
                //     // setLoading(false);
                //   });










              }}>Cancel</SubmitButton>
            </div>
          </Content></div>
      </div>
    </Container>
  );
};

export default AddDoctorDetailsPrescriber;
