// jshint esversion:6

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Apilib from "../../api";
import { SplashLoader } from "../Loader/Loader";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import "./style.css";
import Notify from "../../Notify";
import medvaticImg from "../../assets/medical-computer.svg";
var QRCode = require("qrcode.react");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderDetails({ userData }) {
  const classes = useStyles();
  const { uid, orderNumber } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [doctor, setDoctor] = useState({});
  const [hospital, setHospital] = useState({});

  let [medName, setmedName] = useState([]);
  let [medQuantity, setmedQuantity] = useState([]);

  const componentRef = useRef();

  let history = useHistory();

  useEffect(() => {
    let API = new Apilib();
    setLoading(true);
    console.log(uid, 'this is uid from deorderdetails')
    API.getOrderByUid(uid)
      .then((result) => {
        let res = result.data
        console.log('the data', res.data)

        setData(res.data);

        if (res.data.prescription) {
          setmedName(res.data?.prescription.map((med) => med?.Medicine_Name))

          setmedQuantity(res.data?.prescription.map((med) => med?.qty))
        }

        setLoading(false);
      }).catch(e => {
        console.log('hi there', e)
        setLoading(false);
      });
    setLoading(false);

    return () => { };
  }, []);

  useEffect(() => {
    let API = new Apilib();
    setLoading(true);
    console.log(data, 'datadatadatadata');
    if (!data?.doctorId) setDoctor({ doctorName: null })
    else API.getUserDetails(3, data.doctorId)
      .then((result) => {
        let res = result.data

        setDoctor(res.user);
      });


    if (!data?.hospitalId) setHospital(null)
    else API.getUsers(2, data.hospitalId)
      .then((result) => {
        let res = result.data

        setHospital(res.hospital_admins[0]);
      });
    // setLoading(false);
  }, [data]);




  console.log("hospital", hospital);

  let medNameArr = medName?.toString().split(",");
  let medQuantityArr = medQuantity

  if (loading) return <SplashLoader />;

  const onButtonClick = async (e, changedStatus) => {
    let API = new Apilib();

    let data = {};
    data.uid = uid;
    data.status = changedStatus;

    console.log(data, 'this is data console');
    let res = await API.changeOrderStatus(JSON.stringify(data));
    console.log(res)
    if (res.status === 200) {
      Notify({
        alert: true,
        type: "success",
        title: `Order ${changedStatus}`,
      });
      history.push("/pharmacy/orders");
    }
  };

  return (
    <div className="container mx-4">
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute' }} onClick={() => history.goBack()}>
        <i className="fa fa-arrow-left"></i>Back
      </div>
      <div className="download px-5 d-flex justify-content-end">
        <ReactToPrint
          className="ml-auto"
          trigger={() => (
            <Button>
              <i className="fa fa-download"></i> Download
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div ref={componentRef}>
        <div className="row mt-4 px-5">
          <div className="col-3 d-flex justify-content-start">
            <img width="80%" height="150px" src={medvaticImg} alt="Hospital" />
          </div>
          <div className="col-6">
            <div className="center">
              <div className="hospitalName">
                {hospital?.hospital_name || hospital?.hospitalName ? hospital?.hospital_name || hospital?.hospitalName : null}
              </div>
              {!data?.doctorName == 'NA' && <div className="doctorName">{`Dr. ${data.doctorName}`}</div>}
              <div className="qualify">
                {doctor?.qualifications && doctor?.qualifications.join(", ")}
              </div>
            </div>
            <div className="details">
              {doctor?.regnumber && <div>
                <span>Reg No :</span> {doctor?.regnumber}
              </div>}
              <div className="d-flex justify-content-between">
                {doctor?.landline && <div>
                  <span>Tel:</span> {doctor?.landline}
                </div>}
                {doctor?.email && <div>
                  Email :<span></span> {doctor?.email}
                </div>}
              </div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <QRCode
              className="qr-code"
              value={`${window.location.origin + "/#/pharmacy/order/"}${uid}`}
            />
          </div>
        </div>
        <div className="row my-5 px-5">
          <div className="col-12 details">
            <div className="d-flex">
              <div style={{ width: "70%" }}>
                <span className="doctor-detail-value">Patient Name :</span>{" "}
                <span className="doctor-detail-presc">{`${data?.patientName}`}</span>
              </div>
              <div style={{ width: "30%" }}>
                <span className="doctor-detail-value">Email Id :</span>{" "}
                <span className="doctor-detail-presc">{`${data?.patient_email}`}</span>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ width: "70%" }}>
                <span className="doctor-detail-value">Order Id :</span>{" "}
                <span className="doctor-detail-presc">MED{orderNumber}</span>
              </div>
              <div style={{ width: "30%" }}>
                <span className="doctor-detail-value">Contact :</span>{" "}
                <span className="doctor-detail-presc">{`${data?.patientPhone}`}</span>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ width: "70%" }}>
                <span className="doctor-detail-value">Order Status :</span>{" "}
                <span className="doctor-detail-presc">{data?.orderStatus}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-5">
          <div className="col p-0">
            <div className="medicine-table">
              <h4>Medicines</h4>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Sr. No.</StyledTableCell>
                      <StyledTableCell align="center">
                        Medicine Name
                      </StyledTableCell>
                      <StyledTableCell align="center">Quantity</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {medNameArr && medNameArr.length === 0 && (
                      <tr className="d-flex justify-content-center align-items-center w-100 p-3">
                        <span>No medicines available</span>
                      </tr>
                    )}
                    {medNameArr &&
                      medNameArr.map((med, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            width={10}
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {medName[index]}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {medQuantity[index]}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        {hospital?.hospitalName && <div className="row mb-5 px-5">
          <div className="col-8">
            <div className="footer-head">
              {hospital.hospitalName || hospital.hospital_name}
            </div>
            <div>
              <span className="footer-head">Email :</span>{" "}
              <span className="footer-subhead">{hospital.email}</span>
            </div>
            <div>
              <span className="footer-head">Contact :</span>{" "}
              <span className="footer-subhead">
                {hospital.emergency_contact}
              </span>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <div>
              <div className="footer-head">Address</div>
              <div className="footer-subhead">
                {hospital.city}, {hospital.state}
              </div>
              <div className="footer-subhead">
                {hospital.address} - {hospital.pincode}
              </div>
            </div>
          </div>
        </div>}
      </div>
      {data.orderStatus === "Pending" ? (
        <div className="d-flex justify-content-center">
          <Button
            onClick={(e) => onButtonClick(e, "Completed")}
            className="m-3"
          >
            Accept
          </Button>
          <Button
            onClick={(e) => onButtonClick(e, "Rejected")}
            style={{ backgroundColor: "red" }}
            className="m-3"
          >
            Reject
          </Button>
        </div>
      ) : (
        ""
      )}


    </div>
  );
}
