import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as Datetime from "react-datetime";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import "./schedule.css";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import "../../assets/css/FullCalendar.css";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Print_button from "../../assets/img/Print_button.png";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { CustomInput } from "reactstrap";
import { map } from "jquery";

let available_days = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

let deleteEvent = null;

export default class AddSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      selectedDaysObj: null,
      selectedSlotDuration: 0,
      startTimeCustom: null,
      endTimeCustom: null,
      startTime: "",
      endTime: "",
      slot: "",
      loading: false,
      userData: this.props.userData,
      success: false,
      deleted: false,
      deleteEvent: null,
      isDeleteModalOpen: false,
      doctors: [],
      // Full Calendar
      weekendsVisible: true,
      currentEvents: [],
      eventGuid: 0,
      calendar: [],
    };
    // this.renderEventContent = this.renderEventContent.bind(this);
  }

  componentDidMount() {
    this.fetchDoctors();
    this.createCalendarEvents(this.state.userData);
  }

  openDeleteModal = (e) => {
    this.setState({
      isDeleteModalOpen: true,
      deleteEvent: e,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  };

  componentDidUpdate() {
    console.log("state con", this.state, this.props);
  }

  onDaySelect(day) {
    let arr = [];
    arr.push(day?.value);
    this.setState({
      selectedDays: arr,
      selectedDaysObj: day,
    });
  }
  onSlotSelect(slot) {
    console.log("Slot:", slot);
    this.setState({
      selectedSlotDuration: slot,
      slot: slot.value,
    });
  }

  onTimeSelect(value, label) {
    console.log("Value:", value, "Label:", label);
    // this.setState({
    //   [label]: value.format("h:mm a"),
    // });
    let time = moment(value).format("h:mm a");
    this.setState({
      [label]: time,
      [label + "Custom"]: value,
    });
  }

  resetState() {
    this.setState({
      selectedDays: [],
      selectedDaysObj: null,
      startTime: new Date(),
      endTime: new Date(),
      slot: "",
    });
  }

  hideAlerts() {
    setTimeout(() => {
      this.setState({
        success: false,
        deleted: false,
        error: "",
      });
    }, 5000);
  }

  onSubmit(e) {
    e.preventDefault();
    if (
      !(
        this.state.selectedSlotDuration &&
        this.state.selectedDaysObj &&
        this.state.startTime &&
        this.state.endTime
      )
    )
      return;
    var beginningTime = moment(this.state.startTime, "h:mm a");
    var endTime = moment(this.state.endTime, "h:mm a");
    if (endTime.isBefore(beginningTime)) {
      alert("Start Time should be less than end time");
    } else {
      let millis = endTime.diff(beginningTime);
      let mins = millis / 60000;
      if (mins < 60) {
        alert("Start and end time duration should be atleast 60 mins");
      } else {
        const promises = [];
        let API = new Apilib();
        console.log("1st->", this.state.selectedDaysObj);
        console.log("2nd->", this.state.endTime, this.state.startTime);
        for (let obj of this.state.selectedDaysObj) {
          // let fromObj = {};
          // let toObj = {};
          // this.state.selectedDays.map((day, index) => {
          //   fromObj[day.value] = [this.state.startTime];
          //   toObj[day.value] = [this.state.endTime];
          // });
          let data = {
            type: 3,
            uid: this.state.userData.uid,
            days: [obj.value],
            slot_duration: this.state.slot + ":00",
            from: { [obj.value]: [this.state.startTime] },
            to: { [obj.value]: [this.state.endTime] },
          };
          console.log("data123", data);
          promises.push(API.addSchedule(data));
        }

        this.setState({
          loading: true,
        });
        let err = [];

        Promise.all(promises)
          .then((res) => {
            console.log("response schedule", res);

            let API = new Apilib();
            API.getUserDetails(
              this.state.userData.type,
              this.state.userData.uid
            )
              .then((result) => {
                let res = result.data

                console.log("aaammm", res);
                this.createCalendarEvents(res.user);
                Notify({
                  type: "success",
                  alert: true,
                  title: "Schedule added successfully",
                });
                this.setState(
                  {
                    loading: false,
                    userData: res.user,
                  },
                  () => {
                    // this.fetchDoctors();
                    this.resetState();

                    // this.props.updateUser(res.user);
                  }
                );
                // only receptionist can call this API
                if (this.props?.type == 4) {
                  this.fetchDoctors();
                }
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  loading: false,
                });
              });
            // } else {
            //   res.text().then((data) => {
            //     Notify({
            //       type: "error",
            //       alert: true,
            //       title: "Failed to add schedule",
            //     });
            //     this.setState({
            //       loading: false,
            //       // error: JSON.parse(data).message,
            //     });
            //   });
            // }
            this.hideAlerts();
          })
          .catch((error) => {
            console.log("schedule err", error);

            this.setState({
              error: error.message,
              loading: false,
            });
            this.hideAlerts();
          });
      }
    }
  }

  handleDelete(e) {
    // let from = e.from.slice(-1)
    // let data = {
    //   ...e,
    //   type: 3,
    // };
    // delete data.date;

    // delete data.days;
    console.log("dataa", this.state.userData);
    console.log("dataa1", Object.keys(e.from))
    let item = Object.keys(e.from)
    console.log("dataa2", e.from[item])
    let item2 = e.from[item]
    console.log("dataa3", this.state.userData.from[item].indexOf(item2[0]))
    let removeIndex = this.state.userData.from[item].indexOf(item2[0])
    let Arr = this.state.userData.from[item]
    Arr.splice(removeIndex, 1)
    console.log("dataa4", Arr)
    let updatedFrom = this.state.userData.from
    let FROM = {
      ...this.state.userData,
      [updatedFrom[item[0]]]: Arr
    }
    console.log("dataa40", FROM)


    console.log("dataa5", Object.keys(e.to))
    let item3 = Object.keys(e.to)
    console.log("dataa6", e.to[item3])
    let item4 = e.to[item3]
    console.log("dataa7", this.state.userData.to[item3].indexOf(item4[0]))
    let removeIndex1 = this.state.userData.to[item3].indexOf(item4[0])
    let Arr1 = this.state.userData.to[item3]
    Arr1.splice(removeIndex1, 1)
    console.log("dataa8", Arr)
    let updatedTo = this.state.userData.to
    console.log("dataa48", updatedTo, item3[0], updatedTo[item3[0]])

    let TO = {
      ...this.state.userData,
      [updatedTo[item3[0]]]: Arr1
    }
    console.log("dataa40", TO)




    let data = {
      ...this.state.userData,
      ...FROM,
      ...TO
    }
    delete data[""]
    console.log("dataa45", this.state.userData, FROM, TO)


    console.log("Dataa41", data)
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.updateUser(JSON.stringify(data))
      .then((result) => {
        let res = result.data
        // if (res.ok) {
        let API = new Apilib();
        API.getUserDetails(this.state.userData.type, this.state.userData.uid)
          .then((result) => {
            let res = result.data

            console.log("userrrrr", res.user);
            this.createCalendarEvents(res.user);
            Notify({
              type: "success",
              alert: true,
              title: "Schedule deleted successfully",
            });
            this.setState(
              {
                isDeleteModalOpen: false,
                loading: false,
                // deleted: true,
                userData: res.user,
              }
              // () => {
              //   this.props.updateUser(res.user);
              //   this.resetState();
              // }
            );
            if (this.props?.type == 4) {
              this.fetchDoctors();
            }
          })
          .catch((error) => {
            console.log(error);
            Notify({
              type: "error",
              alert: true,
              title: "Failed to delete schedule",
            });
          });
        // }
        //  else {
        //   res.text().then((data) => {
        //     this.setState(
        //       {
        //         loading: false,
        //         // error: JSON.parse(data).message,
        //       },
        //       () =>
        //         Notify({
        //           type: "error",
        //           alert: true,
        //           title: "Failed to delete schedule",
        //         })
        //     );
        //   });
        // }
        this.hideAlerts();
      })
      .catch((error) => {
        this.hideAlerts();
        console.log(error);
      });
  }

  renderSchedule(from, to, day) {
    if (from) {
      return (
        <>
          {from.map((d, i) => (

            <>
              <div key={i} className="schedule-row">
                <p>
                  {d} - {to[i]}
                </p>
                <button
                  data-day={day + 1}
                  data-from={d}
                  data-to={to[i]}
                  onClick={(e) => {
                    e.preventDefault();
                    this.openDeleteModal({
                      target: { dataset: { ...e.target.dataset } },
                    });
                  }}
                  className="btn btn-outline-danger btn-sm  px-3 text-sm"
                >
                  Delete Schedule
                </button>
              </div>
              {i !== from.length - 1 && (
                <div className="dropdown-divider"></div>
              )}
            </>
          ))}
        </>
      );
    } else {
      return null;
    }
  }

  fetchDoctors() {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(3, this.props.userData.hospitalId)
      .then((result) => {
        let res = result.data

        console.log("userDataaq", res.data);

        if (res?.doctors) {
          this.setState({
            doctors: res.doctors,
          });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({});
        }
      })
      .finally(() =>
        this.setState({ loading: false })
      )
  }

  onSelect = (val, name) => {
    console.log("val-->", val);

    if (name == "doctorId") {
      this.setState({
        userData: { ...val },
      });
      this.createCalendarEvents(val);
    }
  };

  //------- Start of FullCalendar --------//

  colorArray = [
    "rgb(255,190,105)",
    "rgb(255,157,188)",
    "rgb(108,214,204)",
    "rgb(166,223,248)",
  ];

  createCalendarEvents(userData) {
    let count = 0;
    console.log("userdataaa", { userData });
    // set Calendar Schedule
    let daysBooked = userData.days || [];
    console.log("DayBooked", daysBooked);
    let calendarEvents = [];
    if (userData?.days) {
      daysBooked.map((user_day) => {
        var start = moment(),
          end = moment().add(7, "days"),
          day = user_day != 7 ? user_day : 0;
        console.log("daya", user_day, end, start);

        var result = [];
        var current = start.clone().day(day);
        result.push(current.clone());
        console.log("result", result);

        // if ( current.isAfter(start)) {
        //   result.push(current.clone());
        //   console.log("afterWorkings")
        // }

        while (current.day(7 + day).isBefore(end)) {
          result.push(current.clone());
          console.log("afterWorking");
        }

        result.map((res, index) => {
          console.log(
            "test date:",
            start.format("YYYY-MM-DD") == res.format("YYYY-MM-DD"),
            start == res,
            index
          );
          console.log("ASDF", userData.from[user_day], userData.from, user_day);

          userData.from[user_day].map((time, i) => {
            console.log("Time1234", time);
            let valid = true;
            console.log("CalenderOne");
            if (start.format("YYYY-MM-DD") == res.format("YYYY-MM-DD")) {
              console.log("if 1 working");
              if (moment() > moment(userData.to[user_day][i], "h:mm a")) {
                console.log("if 2 working");
                valid = false;
              }
            }
            console.log("isValid", valid, res);

            // check if slot end time is not greater than current time(temporary check)
            if (valid) {
              console.log(
                "if 3 working",
                res.format("YYYY-MM-DD") +
                "T" +
                moment(userData.from[user_day][i], "h:mm a").format("HH:mm"),
                res.format("YYYY-MM-DD") +
                "T" +
                moment(userData.to[user_day][i], "h:mm a").format("HH:mm")
              );
              console.log("Calender2", index);
              calendarEvents.push({
                id: this.createEventId(),
                title: "",
                start:
                  res.format("YYYY-MM-DD") +
                  "T" +
                  moment(userData.from[user_day][i], "h:mm a").format("HH:mm"),

                end:
                  res.format("YYYY-MM-DD") +
                  "T" +
                  moment(userData.to[user_day][i], "h:mm a").format("HH:mm"),
                data: {
                  // Delete Body
                  uid: userData.uid,
                  days: [user_day],
                  from: {
                    [user_day]: [userData.from[user_day][i]],
                  },
                  to: {
                    [user_day]: [userData.to[user_day][i]],
                  },
                  date: res,
                },
                // backgroundColor: this.colorArray[Math.floor((Math.random()*this.colorArray.length))],
                backgroundColor:
                  count > this.colorArray.length
                    ? this.colorArray[count % this.colorArray.length]
                    : this.colorArray[count],
              });
              count++;
            }
          });
        });
      });

      console.log("CalenderEvent", { calendarEvents });
      this.setState({
        calendar: calendarEvents,
      });
    }
    console.log("calendar event called");
  }

  handleDateSelect(selectInfo) {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  }

  renderEventContent = (eventInfo) => {
    console.log("renderEventContent", eventInfo);
    let data = eventInfo?.event?._def?.extendedProps?.data,
      day = data.days[0],
      date = data.date;
    const popover = (
      <Popover id="popover-basic" style={{ minWidth: "200px" }}>
        <Popover.Title as="h5" style={{ margin: 0 }}>
          {/* {eventInfo.event.title} */}
          <>{moment(date).format("dddd, MMMM Do YYYY")}</>
        </Popover.Title>
        <Popover.Content>
          <Row>
            <Col lg="5">
              <b>From:</b> {data.from[day][0]}
              <br />
              <b>To:</b> {data.to[day][0]}
            </Col>
            <Col lg="7">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  console.log("dataaaaaa", data)
                  this.openDeleteModal(data);
                  // this.cl

                }}
                className="btn btn-outline-danger btn-sm  px-3 text-sm"
              >
                Delete Schedule
              </button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
    // console.log("renderEventContent2");
    return (
      <OverlayTrigger
        placement="left"
        overlay={popover}
        trigger="click"
        rootClose
      >
        <div
          className="hide-overflow"
          style={{ width: "100%", fontFamily: "sans-serif", color: "black" }}
        >
          <span className="fc-event-title-New">
            <span>
              {" "}
              {data.from[day][0].replace("am", "").replace("pm", "")} -{" "}
              {data.to[day][0].replace("am", "").replace("pm", "")}{" "}
            </span>{" "}
            <br></br>
            <div className="cardMargins">
              {" "}
              <b>
                {" "}
                {this.state.userData.firstName} {this.state.userData.lastName}{" "}
              </b>{" "}
            </div>
            <span> {this.state.userData.specialities[0]} </span>
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  newDateFormat(date) {
    let newDate =
      moment(date.start).format("D") +
      " - " +
      moment(date.end).format("D MMM, YYYY");
    return newDate;
  }

  createEventId() {
    this.setState({ eventGuid: this.state.eventGuid++ });
    return String(this.state.eventGuid);
  }
  // End of FullCalendar

  render() {
    let daysTxt = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let userData = this.state.userData;
    let daysBooked = userData.days || [];
    if (daysBooked.length) {
      daysBooked.sort();
    }
    let daysToShow = [];
    available_days.map((day, i) => {
      // if(!daysBooked.includes(day.value)){
      daysToShow.push(day);
      // }
    });
    //slots to show
    let slotsToShow = [
      { label: "30", value: 30 },
      { label: "60", value: 60 },
      { label: "120", value: 120 },
    ];
    // console.log(daysToShow);
    daysTxt.map((value, index) => {
      console.log("-----Schedule--------", value);
      let dayArr = userData.from ? userData.from[index + 1] : [];
      console.log(dayArr);
    });
    let doctors = [];
    if (this.state.doctors.length) {
      this.state.doctors.map((item, i) => {
        doctors.push({
          ...item,
          value: item.uid,
          label: item.firstName + " " + item.lastName,
        });
      });
    }

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {this.state.loading ? <Loader /> : null}
          <div className="row">
            <div className="col-md-12 noprint">
              {this.state.error && (
                <div className="alert alert-danger fade show" role="alert">
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div className="alert alert-success fade show" role="alert">
                  Schedule added successfully
                </div>
              )}
              {this.state.deleted && (
                <div className="alert alert-success fade show" role="alert">
                  Schedule deleted successfully
                </div>
              )}
              <form method="post" onSubmit={(e) => this.onSubmit(e)} action="#">
                <div className="row">
                  {this.props?.type == 4 && (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Doctor <span className="text-danger">*</span>
                        </label>
                        <Select
                          required
                          value={this.state.userData}
                          onChange={(item) => this.onSelect(item, "doctorId")}
                          options={doctors}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Available Days
                        <span className="required">*</span>
                      </label>
                      <Select
                        required
                        onChange={(val) => this.onDaySelect(val)}
                        value={this.state.selectedDaysObj}
                        options={daysToShow}
                        noOptionsMessage={() => "All Days Booked"}
                        isMulti={true}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Slot Duration (mins)
                        <span className="required">*</span>
                      </label>

                      <Select
                        onChange={(val) => this.onSlotSelect(val)}
                        required
                        value={this.state.selectedSlotDuration}
                        options={slotsToShow}
                        noOptionsMessage={() => "Slots unavailable"}
                        isMulti={false}
                        closeMenuOnSelect={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Start Time</label>
                      <div className="schedule-time-wrapper">
                        <KeyboardTimePicker
                          inputProps={{ required: true }}
                          margin="normal"
                          id="time-picker"
                          label="Time picker"
                          emptyLabel="Select start time"
                          placeholder="08:00 AM"
                          inputVariant="outlined"
                          format="h:mm a"
                          value={this.state.startTimeCustom}
                          onChange={(val) => {
                            this.onTimeSelect(val, "startTime");
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </div>
                      {/* <div className="time-icon">
                        <Datetime
                          inputProps={{ required: true }}
                          value={this.state.startTime}
                          onChange={(val) =>
                            this.onTimeSelect(val, "startTime")
                          }
                          input={false}
                          dateFormat={false}
                          viewMode="time"
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>End Time</label>
                      <div className="schedule-time-wrapper">
                        <KeyboardTimePicker
                          inputProps={{ required: true }}
                          margin="normal"
                          id="time-picker"
                          emptyLabel="Select end time"
                          label="Time picker"
                          placeholder="12:00 PM"
                          format="h:mm a"
                          inputVariant="outlined"
                          value={this.state.endTimeCustom}
                          onChange={(val) => {
                            this.onTimeSelect(val, "endTime");
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-t-20 text-center">
                  <button className="btn btn-primary submit-btn">
                    Save Schedule
                  </button>
                </div>
              </form>
              <div className="d-flex justify-content-between noprint">
                <h3>Current Schedule</h3>
                <div className="slotandPrint">
                  <div className="windowPrint">
                    <button
                      className="PrintBtn mr-2 py-1 pl-2 pr-1"
                      onClick={() => window.print()}
                    >
                      <span className="printtextcss"> PRINT</span>

                      <img src={Print_button} height="19" width="19"></img>
                    </button>
                  </div>
                  <div
                    className="py-1 px-3 ml-1"
                    style={{
                      backgroundColor: "rgb(249 249 249)",
                      boxSizing: "border-box",
                      borderRadius: 5,
                      fontWeight: "600",
                      fontSize: "1rem",
                      color: "#828282",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    Slot Duration : {userData.slot_duration} mins
                  </div>
                </div>
              </div>
            </div>
            {userData.days ? (
              <div className="col-md-12 mt-3 " id="section-to-print">
                <div>
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: "prev,today,next",
                      center: "title",

                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    titleFormat={{
                      month: "long",
                      year: "numeric",
                    }}
                    dayHeaderFormat={{
                      weekday: "long",
                      day: "numeric",
                    }}

                    dayHeaderContent={({ date }) => {
                      console.log("date11112", date);

                      let isToday =
                        moment(date).format("D") == moment().format("D");

                      let htmlTime = (
                        <div className="dayHeaderContentDiv">

                          <div
                            className={
                              isToday ? "isTodayDateHeader" : "dateHeader"
                            }
                          >
                            {" "}
                            {moment(date).format("D")}{" "}
                          </div>
                          <div
                            className={
                              isToday ? "isTodayDayHeader" : "dayHeader"
                            }
                          >
                            {" "}
                            {moment(date).format("dddd")}{" "}
                          </div>
                        </div>
                      );
                      return htmlTime;
                    }}

                    slotLabelContent={({ date }) => {
                      console.log("date1111", date);
                      let htmlTime = (
                        <div>
                          <div className="sideTime">
                            {" "}
                            {moment(date).format("h:mm")}{" "}
                          </div>

                          <div className="sideMeridian">
                            {" "}
                            {moment(date).format("a")}{" "}
                          </div>
                        </div>
                      );
                      return htmlTime;
                    }}
                    slotDuration="01:00:00"
                    initialView="timeGridWeek"
                    slotEventOverlap={true}
                    dayHeaders={true}
                    editable={true}
                    selectable={false}
                    allDaySlot={false}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    initialEvents={[]} // alternatively, use the `events` setting to fetch from a feed
                    select={this.handleDateSelect}
                    eventContent={this.renderEventContent} // custom render function
                    events={this.state.calendar}
                    eventDisplay="block"
                    // eventColor="rgb(166,223,248)"
                    contentHeight="auto"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <Modal
            backdrop="static"
            show={this.state.isDeleteModalOpen}
            onHide={this.closeDeleteModal}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                Are you sure you want delete schedule of{" "}
                <b>{daysTxt[+this.state.deleteEvent?.days[0] - 1]}</b>{" "}
                <b>{`${this.state.deleteEvent?.from[
                  this.state.deleteEvent?.days[0]
                ][0]
                  } to ${this.state.deleteEvent?.to[this.state.deleteEvent?.days[0]][0]
                  }`}</b>
                ?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeDeleteModal}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(this.state.deleteEvent)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }
}
