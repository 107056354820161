import React from 'react';
import Autosuggest from 'react-autosuggest';
import Apilib from '../../api'

// Imagine you have a list of languages that you'd like to autosuggest.
const languages = [
  {
    title: 'Maharashtra',
    languages: [
      {
        name: 'Pune',
      },
      {
        name: 'Mumbai',
      },
      {
        name: 'Nagpur',
      }
    ]
  },
  {
    title: 'Mumbai',
    languages: [
      {
        name: 'Kushal'
      }
    ]
  },
  {
    title: '1990s',
    languages: [
      {
        name: 'Haskell',
        year: 1990
      },
      {
        name: 'Python',
        year: 1991
      },
      {
        name: 'Java',
        year: 1995
      },
      {
        name: 'Javascript',
        year: 1995
      },
      {
        name: 'PHP',
        year: 1995
      },
      {
        name: 'Ruby',
        year: 1995
      }
    ]
  },
  {
    title: '2000s',
    languages: [
      {
        name: 'C#',
        year: 2000
      },
      {
        name: 'Scala',
        year: 2003
      },
      {
        name: 'Clojure',
        year: 2007
      },
      {
        name: 'Go',
        year: 2009
      }
    ]
  },
  {
    title: '2010s',
    languages: [
      {
        name: 'Elm',
        year: 2012
      }
    ]
  }
];

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value) {
  const escapedValue = escapeRegexCharacters(value.trim());
  
  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp('^' + escapedValue, 'i');

  return languages
    .map(section => {
      return {
        title: section.title,
        languages: section.languages.filter(language => regex.test(language.name))
      };
    })
    .filter(section => section.languages.length > 0);
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span style={{fontWeight:"500"}}>{suggestion.name}</span>
  );
}

function renderSectionTitle(section) {
  return (
    <div style={{backgroundColor:"#4FD8FA",borderRadius:"2px"}}>
      <h6 style={{padding:"2%"}}>{section.title}</h6>
    </div>
    // <strong>{section.title}</strong>
  );
}

function getSectionSuggestions(section) {
  return section.languages;
}

class SearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      location:null
    };    
  }

  getLocation = () =>{
    let API = new Apilib();
    API.getLocation().then((res) =>{

    res.json().then(jsonData => {
      console.log("JsonData>>>>>>>>>>",jsonData) //pune
      this.setState({
        value: jsonData.city
      })
    })

    }).catch((err) =>{
      console.log("Location error: ",err)
    })
  }
  
  componentDidMount = () =>{
    this.getLocation();
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest 
        // multiSection={true}
        
        suggestions={[]}
        // onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        // onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        // getSuggestionValue={getSuggestionValue}
        // renderSuggestion={renderSuggestion}
        // renderSectionTitle={renderSectionTitle}
        // getSectionSuggestions={getSectionSuggestions}
        inputProps={{value : this.state.value}}
        />
    );
  }
}

export default SearchBar;
