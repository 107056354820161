import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "jquery/dist/jquery.min.js";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";
import { isValid } from "date-fns";
import GoogleMap from "../Agora/utils/GoogleMap";
import { firestore } from "../../firebase";
import { storage } from "../../firebase";
import { ImageGroup } from "semantic-ui-react";
const Select = (props) => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

export default class HospitalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: "",
            loading: false,
            isGST: false,
            modalLoading: false,
            hospitals: [],
            currentHospital: [],
            geoLocation: "",
            validContact: true,
            currentIndex: "",
            countries: [],
            date: Date().toLocaleString(),
            states: [],
            cities: [],
            hospital_chains: [],
            selectedChain: null,
            selectedCountry: null,
            selectedState: null,
            selectedCity: null,
            modalOpen: false,
            viewmodalOpen: false,
            isGST: false,
            images: [],
            openModal: false,
            verifyModalOpen: false,
            success: false,
            show: false,
            deleteHospital: null,
        };
        this._isMounted = false;
    }

    fetchUsers = () => {
        this.setState({
            loading: true,
        });
        let API = new Apilib();
        API.getUsers(2)
            .then((result) => {
                let res = result.data;

                console.log("Response:", res.hospital_admins);
                if (this._isMounted) {
                    let data = [];
                    data = res.hospital_admins;
                    console.log("DATA>>>>>>>", data);
                    data.sort((a, b) => b.created_at - a.created_at); //created_at
                    console.log("SORT DATA>>>>>>>", data);
                    this.setState(
                        {
                            loading: false,
                            hospitals: [...data],
                        },
                        () => { }
                    );
                }
            })

            .catch((error) => {
                console.log("error: ", error);
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                    });
                }
            });
    };

    componentDidMount() {
        this._isMounted = true;

        this.fetchUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange = (e) => {
        let formObj = { ...this.state.currentHospital };
        formObj[e.target.name] = e.target.value;
        this.setState({
            currentHospital: formObj,
        });
        if (e.target.name == "emergency_contact") {
            var pattern = /^[0-9]{8,15}$/;
            let isValid = pattern.test(e.target.value);
            this.setState({
                validContact: isValid,
            });
        }
    };

    onSelectChain = (val) => {
        let formObj = { ...this.state.form_data };
        formObj["hospital_chain"] = val.label;
        formObj["hospitalChainId"] = val.value;
        this.setState({
            form_data: formObj,
            currentHospital: {
                ...this.state.currentHospital,
                hospital_chain: val.label,

                hospitalChainId: val.value,
            },
            selectedChain: val,
        });
    };

    onSelectCountry = (val) => {
        let formObj = { ...this.state.form_data };
        formObj["country"] = val.value;

        this.setState(
            {
                form_data: formObj,
                currentHospital: {
                    ...this.state.currentHospital,
                    country: val.value,
                },
                selectedCountry: val,
            },
            () => {
                let API = new Apilib();
                this.setState({
                    loading: true,
                    currentHospital: {
                        ...this.state.currentHospital,
                        state: "",
                    },
                    selectedState: null,
                    selectedCity: null,
                });
                API.getStates(val.value).then((result) => {
                    let res = result.data;

                    this.setState({
                        states: res.data.states.map((state) => ({
                            state_name: state.name,
                        })),
                        loading: false,
                    });
                });
            }
        );
    };

    onSelectState = (val) => {
        let formObj = { ...this.state.form_data };
        formObj["state"] = val.value;
        this.setState(
            {
                form_data: formObj,
                currentHospital: {
                    ...this.state.currentHospital,
                    state: val.value,
                },
                selectedState: val,
            },
            () => {
                let API = new Apilib();
                this.setState({
                    loading: true,
                    selectedCity: null,
                });
                API.getCities(this.state.selectedCountry.value, val.value).then(
                    (result) => {
                        let res = result.data;

                        this.setState({
                            cities: res.data.map((city) => ({
                                city_name: city,
                            })),
                            loading: false,
                        });
                    }
                );
            }
        );
    };

    updateData = (e) => {
        e.preventDefault();
        if (!this.state.validContact) {
            Notify({
                alert: true,
                type: "error",
                title: "Invalid Contact Number",
            });
            return;
        }
        let data = { ...this.state.currentHospital };
        console.log("DataData", data);

        let hospitals = [...this.state.hospitals];
        hospitals[this.state.currentIndex] = data;
        this.setState({
            modalLoading: true,
            hospitals,
        });
        //delete data.email;
        let API = new Apilib();
        API.updateUser(JSON.stringify(data))
            .then((result) => {
                let res = result.data;
                this.setState({
                    modalLoading: false,
                });
                // if (res.ok) {
                // console.log("Milega response",res)
                this.setState({
                    modalLoading: false,
                    currentHospital: {},
                    selectedCountry: null,
                    selectedState: null,
                    modalOpen: false,
                    viewmodalOpen: false,
                    openModal: false,
                    success: true,
                });
                Notify({
                    alert: true,
                    type: "success",
                    title: "Edit Successfully",
                });
                // } else {
                //   res.text().then((data) => {
                //     this.setState({
                //       modalOpen: false,
                //       viewmodalOpen: false,

                //       openModal: false,
                //       error: JSON.parse(data).message,
                //     });
                //   });
                // }
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                    loading: false,
                });
            });
    };

    handleOpen(e, index) {
        e.preventDefault();
        // const imgarr = this.state.hospitals[index].hospital_images.map((img) => {
        //   this.makeDownloadUrl(img);
        // });
        // this.setState({ images: imgarr });
        this.setState({
            modalOpen: true,
            currentHospital: this.state.hospitals[index],
            currentIndex: index,
            error: null,
            success: false,
        });
        // console.log("imagess", this.state.images);
    }

    handleVerifyOpen(e, index) {
        e.preventDefault();
        this.setState({
            verifyModalOpen: true,
            currentHospital: this.state.hospitals[index],
            currentIndex: index,
            error: null,
            success: false,
        });
    }

    viewhandleOpen(e, index) {
        e.preventDefault();
        console.log("business-<", this.state.hospitals[index]);
        this.setState({
            viewmodalOpen: true,
            currentHospital: this.state.hospitals[index],
            currentIndex: index,
            error: null,
            success: false,
        });
    }
    openHandle(e, index) {
        e.preventDefault();
        this.setState({
            openModal: true,
            currentHospital: this.state.hospitals[index],
            currentIndex: index,
            isGST: this.state.hospitals[index]?.hospital_gst ? true : false,
            error: null,
            success: false,
        });
    }
    handleClose() {
        this.setState({
            modalOpen: false,
            currentHospital: {},
            selectedCountry: null,
            selectedState: null,
            currentIndex: "",
        });
    }
    handleVerifyClose = () => {
        this.setState({
            verifyModalOpen: false,
            currentHospital: {},
            selectedCountry: null,
            selectedState: null,
            currentIndex: "",
        });
    };

    viewhandleClose() {
        this.setState({
            viewmodalOpen: false,
            currentHospital: {},
            selectedCountry: null,
            selectedState: null,
            currentIndex: "",
        });
    }

    deleteHospital = (value) => {
        this.setState({
            loading: true,
        });
        console.log("deleted business>>>>>>>", value);
        let API = new Apilib();
        API.deleteHospital({ type: value.type, uid: value.uid })
            .then((result) => {
                let res = result.data;
                // if (res.ok) {
                //   let MSG = await res.json();
                this.setState(
                    {
                        show: false,
                    },
                    () => {
                        Notify({
                            alert: true,
                            type: "success",
                            title: "business Deleted Successfully",
                        });
                        // this.getSpeciality();
                        this.componentDidMount();
                    }
                );
            })
            .catch((error) => {
                console.log("error: ", error);
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                    });
                }
            });
        //recollect data

        // } else {
        //   Notify({
        //     alert: true,
        //     type: "danger",
        //     title: "Error occured",
        //   });
        // }
    };

    makeDownloadUrl(path) {
        let p = [];
        let gsReference = storage.refFromURL(
            "gs://medvatic-438af.appspot.com" + path
        );
        gsReference
            .getDownloadURL()
            .then((url) => {
                console.log("chat user img", url);
            })
            .catch((err) => {
                console.log("chat user err", err);
            });
    }
    render() {
        console.log("current dats", this.state.currentHospital);
        console.log("images", this.state.images);

        let formData = {
            hospiChain: this.state.currentHospital.hospiChain,
        };
        let chains = [];
        if (this.state.hospital_chains.length) {
            this.state.hospital_chains.map((item, i) => {
                chains.push({
                    value: item.id,
                    label: item.chain_name,
                });
            });
        }

        let countries = [];
        if (this.state.countries.length) {
            this.state.countries.map((item, i) => {
                countries.push({
                    value: item.country_name,
                    label: item.country_name,
                });
            });
        }

        let states = [];
        if (this.state.states.length) {
            this.state.states.map((item, i) => {
                states.push({
                    value: item.state_name,
                    label: item.state_name,
                });
            });
        }
        if (this.state.loading) {
            return <Loader />;
        } else {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title">Businesses</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link
                                to="/admin/add-hospital"
                                className="btn btn-primary float-right btn-rounded"
                            >
                                <i className="fa fa-plus"></i> Add Business
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.error && (
                                <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                    {this.state.error}
                                </div>
                            )}
                            {/* {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Hospital updated successfully
                </div>
              )} */}
                            <div className="table-responsive">
                                {!this.state.loading && (
                                    <Table
                                        tableOptions={{
                                            order: [],
                                            columnDefs: [
                                                {
                                                    orderable: false,
                                                    targets: 6,
                                                },
                                            ],
                                        }}
                                        columns={[
                                            "Business Chain Name",
                                            "Business Name",
                                            "Location",
                                            "Date",
                                            "Business Code",
                                            "Pin Code",
                                            "Verification",
                                            "Actions",
                                        ]}
                                        rows={this.state.hospitals.map(
                                            (hospital, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {hospital.hospital_chain ||
                                                            hospital.hospitalName}
                                                    </td>
                                                    <td
                                                        onClick={(e) =>
                                                            this.viewhandleOpen(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <Link>
                                                            {" "}
                                                            {hospital.hospital_name ||
                                                                hospital.hospitalName}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {hospital.geoLocation ==
                                                            ""
                                                            ? "N/A"
                                                            : hospital.geoLocation}
                                                    </td>
                                                    <td>
                                                        {hospital.date == ""
                                                            ? "N/A"
                                                            : hospital.date}
                                                    </td>
                                                    <td>
                                                        {hospital.hospital_code}
                                                    </td>
                                                    <td>{hospital.pincode}</td>
                                                    <td>
                                                        {console.log(hospital, 'hospital?.verification?.status')}
                                                        {hospital?.verification
                                                            ?.status ==
                                                            "verified" ? (
                                                            <span class="badge badge-pill badge-success">
                                                                VERIFIED
                                                            </span>
                                                        ) : hospital
                                                            ?.verification
                                                            ?.status ==
                                                            "rejected" ? (
                                                            <span class="badge badge-pill badge-danger">
                                                                REJECTED
                                                            </span>
                                                        ) : (
                                                            <span class="badge badge-pill badge-warning">
                                                                PENDING
                                                            </span>
                                                        )}
                                                        <button
                                                            onClick={(e) =>
                                                                this.handleVerifyOpen(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            type="button"
                                                            class="btn ml-2 btn-sm btn-outline-secondary"
                                                        >
                                                            Edit
                                                        </button>
                                                        {hospital?.verification?.verified_by?.name && <button
                                                            onClick={(e) =>
                                                                Notify({
                                                                    type: "info",
                                                                    title: `Last Verification Status Updated By ${hospital?.verification?.verified_by?.name ?? "None"}`
                                                                })
                                                            }
                                                            type="button"
                                                            class="btn ml-2 btn-sm btn-outline-info"
                                                        >
                                                            Info
                                                        </button>}
                                                    </td>
                                                    <td>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "start",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <button
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.handleOpen(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    class="btn btn-secondary"
                                                                >
                                                                    <span
                                                                        class="iconify"
                                                                        data-icon="akar-icons:edit"
                                                                        data-inline="false"
                                                                    ></span>
                                                                    Edit
                                                                </button>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                <button
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        this.setState(
                                                                            {
                                                                                show: true,
                                                                                deleteHospital:
                                                                                    hospital,
                                                                            }
                                                                        );
                                                                    }}
                                                                    type="button"
                                                                    class="btn btn-danger"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            marginBottom:
                                                                                "1%",
                                                                        }}
                                                                        class="iconify"
                                                                        data-icon="ant-design:delete-twotone"
                                                                        data-inline="false"
                                                                    ></span>
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <Modal
                        backdrop="static"
                        show={this.state.show}
                        onHide={() =>
                            this.setState({
                                show: false,
                            })
                        }
                        animation={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Delete business</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure, You want to delete Business
                            {this.state.hospital ? (
                                <>
                                    <strong>
                                        {" " + this.state.hospital.hospitalName}
                                    </strong>
                                </>
                            ) : null}{" "}
                            ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={() =>
                                    this.deleteHospital(
                                        this.state.deleteHospital
                                    )
                                }
                            >
                                Yes
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() =>
                                    this.setState({
                                        show: false,
                                    })
                                }
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        backdrop="static"
                        show={this.state.modalOpen}
                        onHide={() => this.handleClose()}
                        animation={false}
                    >
                        {this.state.modalLoading && <Loader />}
                        <form onSubmit={(e) => this.updateData(e)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Business Update</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Chain Name</label>
                                            <input
                                                required
                                                readOnly
                                                className="form-control"
                                                name="hospitalName"
                                                type="hospitalName"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_chain
                                                }
                                            />
                                            {console.log(
                                                "current state",
                                                this.state
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Name</label>
                                            <input
                                                className="form-control"
                                                required
                                                name="hospital_name"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_name ||
                                                    this.state.currentHospital
                                                        .hospitalName
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Business Registration Number
                                            </label>
                                            <input
                                                className="form-control"
                                                required
                                                name="hospital_regno"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_regno ||
                                                    this.state.currentHospital
                                                        .hospitalName
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <label>
                                                Gst number (Only for india
                                                business )
                                            </label>
                                        </div>

                                        <div className="d-flex flex-row justify-content-start align-items-center">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    checked={
                                                        this.state
                                                            .currentHospital
                                                            ?.hospital_gst
                                                    }
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onChange={() =>
                                                        this.setState({
                                                            isGST: true,
                                                        })
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexRadioDefault1"
                                                >
                                                    YES
                                                </label>
                                            </div>
                                            <div
                                                style={{ marginLeft: "2%" }}
                                                class="form-check"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    checked={
                                                        !this.state
                                                            .currentHospital
                                                            ?.hospital_gst
                                                    }
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    onChange={() =>
                                                        this.setState({
                                                            isGST: false,
                                                        })
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexRadioDefault2"
                                                >
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            style={{ marginTop: "2%" }}
                                            className={`form-group ${this.state.isGST
                                                ? "d-block"
                                                : "d-none"
                                                }`}
                                        >
                                            <label>
                                                Business GST Number
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                                className="form-control"
                                                name="hospital_gst"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_gst || ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Address</label>
                                    <textarea
                                        name="address"
                                        required
                                        onChange={(e) => this.onChange(e)}
                                        value={
                                            this.state.currentHospital.address
                                        }
                                        cols="30"
                                        rows="4"
                                        className="form-control"
                                    >
                                        {this.state.currentHospital.address}
                                    </textarea>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Country/State/City{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <GoogleMap
                                                style={{ width: 100 }}
                                                // setGeoLocation={(e) => {
                                                //   this.setState({
                                                //     geoLocation: { lat: e.lat, lng: e.lng },
                                                //   });
                                                // }}
                                                // geoLocation={this.state.geoLocation}
                                                disabled
                                                isDisabled={true}
                                                value={
                                                    this.state.currentHospital
                                                        .geoLocation
                                                }
                                                onChange={(e) =>
                                                    // this.setState({
                                                    //   geoLocation: this.state.address,
                                                    // })
                                                    console.log("Place API", e)
                                                }
                                                getAddress={(address) => {
                                                    this.setState(
                                                        {
                                                            currentHospital: {
                                                                ...this.state
                                                                    .currentHospital,
                                                                geoLocation:
                                                                    address,
                                                            },
                                                        },
                                                        () => {
                                                            console.log(
                                                                "MApAddress",
                                                                this.state
                                                                    .currentHospital
                                                                    .geoLocation
                                                            );
                                                        }
                                                    );
                                                    console.log(
                                                        "GoogleMapsAddress",
                                                        address
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Pincode</label>
                                            <input
                                                className="form-control"
                                                required
                                                name="pincode"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="number"
                                                value={
                                                    this.state.currentHospital
                                                        .pincode
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Date
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="form-control"
                                                required
                                                readOnly
                                                name="date"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="date"
                                                value={
                                                    this.state.currentHospital
                                                        .date || ""
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Code</label>
                                            <input
                                                className="form-control"
                                                required
                                                readOnly
                                                name="hospital_code"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_code
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input
                                                required
                                                className="form-control"
                                                name="email"
                                                type="email"
                                                readOnly
                                                //onChange={(e) => this.onChange(e)}
                                                value={
                                                    this.state.currentHospital
                                                        .email
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contact Number</label>
                                            <input
                                                className="form-control"
                                                required
                                                name="emergency_contact"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .emergency_contact
                                                }
                                                pattern="^[0-9]{8,}$"
                                                onInvalid={function (e) {
                                                    e.target.setCustomValidity(
                                                        ""
                                                    );
                                                    if (
                                                        !e.target.validity.valid
                                                    ) {
                                                        e.target.setCustomValidity(
                                                            "Conatct no should be minimum 8 digits"
                                                        );
                                                    }
                                                }}
                                            />
                                            <div style={{ color: "red" }}>
                                                {this.state.validContact
                                                    ? null
                                                    : "Invalid Contact Number"}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                    <div className="form-group">
                      <label>Hospital Images</label>
                      {this.state.currentHospital?.hospital_images &&
                        this.state.currentHospital?.hospital_images.map(
                          (img) => {
                            let path = this.makeDownloadUrl(img);
                            return <img src={path} />;
                          }
                        )}
                    </div>
                  </div> */}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => this.handleClose()}
                                >
                                    Close
                                </Button>
                                <button
                                    class="btn btn-primary"
                                    type="submit"
                                    variant="primary"
                                >
                                    Update Business
                                </button>
                            </Modal.Footer>
                        </form>
                        {/* // )}}

            // </Formik> */}
                    </Modal>

                    {/* view Modal */}

                    <Modal
                        backdrop="static"
                        show={this.state.viewmodalOpen}
                        onHide={() => this.viewhandleClose()}
                        animation={false}
                    >
                        {this.state.modalLoading && <Loader />}
                        <form onSubmit={(e) => this.updateData(e)}>
                            <Modal.Header closeButton>
                                <Modal.Title>View Business</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Chain Name</label>
                                            <input
                                                required
                                                className="form-control"
                                                readOnly
                                                name="hospitalName"
                                                type="hospitalName"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_chain
                                                }
                                            />
                                            {console.log(
                                                "current state",
                                                this.state
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Name</label>
                                            <input
                                                className="form-control"
                                                readOnly
                                                required
                                                name="hospital_name"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_name ||
                                                    this.state.currentHospital
                                                        .hospitalName
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Business Registration Number
                                            </label>
                                            <input
                                                className="form-control"
                                                readOnly
                                                required
                                                name="hospital_name"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_regno ||
                                                    this.state.currentHospital
                                                        .hospitalName
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <label>
                                                Gst number (Only for india
                                                business )
                                            </label>
                                        </div>

                                        <div className="d-flex flex-row justify-content-start align-items-center">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    readOnly
                                                    disabled
                                                    type="radio"
                                                    checked={
                                                        this.state
                                                            .currentHospital
                                                            ?.hospital_gst
                                                    }
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onChange={() =>
                                                        this.setState({
                                                            isGST: true,
                                                        })
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexRadioDefault1"
                                                >
                                                    YES
                                                </label>
                                            </div>
                                            <div
                                                style={{ marginLeft: "2%" }}
                                                class="form-check"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    readOnly
                                                    disabled
                                                    type="radio"
                                                    checked={
                                                        !this.state
                                                            .currentHospital
                                                            ?.hospital_gst
                                                    }
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    onChange={() =>
                                                        this.setState({
                                                            isGST: false,
                                                        })
                                                    }
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexRadioDefault2"
                                                >
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            style={{ marginTop: "2%" }}
                                            className={`form-group ${this.state.currentHospital
                                                .hospital_gst
                                                ? "d-block"
                                                : "d-none"
                                                }`}
                                        >
                                            <label>
                                                Business GST Number
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                readOnly
                                                pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                                className="form-control"
                                                name="hospital_gst"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_gst || ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Address</label>
                                    <textarea
                                        name="address"
                                        readOnly
                                        required
                                        onChange={(e) => this.onChange(e)}
                                        value={
                                            this.state.currentHospital.address
                                        }
                                        cols="30"
                                        rows="4"
                                        className="form-control"
                                    >
                                        {this.state.currentHospital.address}
                                    </textarea>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country/State/City </label>
                                            <input
                                                readOnly
                                                className="form-control"
                                                // setGeoLocation={(e) => {
                                                //   this.setState({
                                                //     geoLocation: { lat: e.lat, lng: e.lng },
                                                //   });
                                                // }}
                                                // geoLocation={this.state.geoLocation}
                                                disabled
                                                isDisabled={true}
                                                value={
                                                    this.state.currentHospital
                                                        .geoLocation
                                                }
                                                onChange={(e) =>
                                                    // this.setState({
                                                    //   geoLocation: this.state.address,
                                                    // })
                                                    console.log("aaa")
                                                }
                                                getAddress={(address) => {
                                                    this.setState(
                                                        {
                                                            currentHospital: {
                                                                ...this.state
                                                                    .currentHospital,
                                                                geoLocation:
                                                                    address,
                                                            },
                                                        },
                                                        () => {
                                                            console.log(
                                                                "MApAddress",
                                                                this.state
                                                                    .currentHospital
                                                                    .geoLocation
                                                            );
                                                        }
                                                    );
                                                    console.log(
                                                        "GoogleMapsAddress",
                                                        address
                                                    );
                                                }}
                                            />
                                            {/* <CountryDropdown
                      value={this.state.country}
                      className="form-control"
                      onChange={(val) => {
                        console.log(val);
                        this.setState({
                          country: val,
                        });
                      }}
                      defaultOptionLabel="Select Country"
                    /> */}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Country<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        name="country"
                        readOnly
                        onChange={(e) => this.onChange(e)}
                        type="text"
                        value={this.state.currentHospital?.country || ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        State<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        readOnly
                        name="state"
                        onChange={(e) => this.onChange(e)}
                        type="text"
                        value={this.state.currentHospital?.state || ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        City<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        readOnly
                        name="city"
                        onChange={(e) => this.onChange(e)}
                        type="text"
                        value={this.state.currentHospital?.city || ""}
                      />
                    </div>
                  </div> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Pincode</label>
                                            <input
                                                readOnly
                                                className="form-control"
                                                required
                                                name="pincode"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="number"
                                                value={
                                                    this.state.currentHospital
                                                        .pincode
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Code</label>
                                            <input
                                                readOnly
                                                className="form-control"
                                                required
                                                name="hospital_code"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .hospital_code
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Date
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="form-control"
                                                readOnly
                                                required
                                                name="date"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="date"
                                                value={
                                                    this.state.currentHospital
                                                        .date || ""
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input
                                                required
                                                className="form-control"
                                                name="email"
                                                type="email"
                                                readOnly
                                                //onChange={(e) => this.onChange(e)}
                                                value={
                                                    this.state.currentHospital
                                                        .email
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contact Number</label>
                                            <input
                                                className="form-control"
                                                required
                                                readOnly
                                                name="emergency_contact"
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                type="text"
                                                value={
                                                    this.state.currentHospital
                                                        .emergency_contact
                                                }
                                                pattern="^[0-9]{8,}$"
                                                onInvalid={function (e) {
                                                    e.target.setCustomValidity(
                                                        ""
                                                    );
                                                    if (
                                                        !e.target.validity.valid
                                                    ) {
                                                        e.target.setCustomValidity(
                                                            "Conatct no should be minimum 8 digits"
                                                        );
                                                    }
                                                }}
                                            />
                                            <div style={{ color: "red" }}>
                                                {this.state.validContact
                                                    ? null
                                                    : "Invalid Contact Number"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => this.viewhandleClose()}
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </form>
                        {/* // )}}

            // </Formik> */}
                    </Modal>

                    <Modal
                        backdrop="static"
                        show={this.state.verifyModalOpen}
                        onHide={this.handleVerifyClose}
                        animation={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Business Verification</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div class="form-group">
                                    <label for="status">Status</label>
                                    <select
                                        class="form-control"
                                        id="status"
                                        value={
                                            this.state.currentHospital
                                                ?.verification?.status ??
                                            "pending"
                                        }
                                        onChange={(e) => {
                                            this.setState({
                                                currentHospital: {
                                                    ...this.state
                                                        .currentHospital,
                                                    verification: {
                                                        ...(this.state
                                                            .currentHospital
                                                            ?.verification ??
                                                            {}),
                                                        status: e.target.value,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="verified">
                                            Verified
                                        </option>
                                        <option value="rejected">
                                            Rejected
                                        </option>
                                    </select>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={async () => {
                                    try {
                                        this.setState({
                                            loading: true,
                                        });
                                        console.log(this.state.currentHospital, 'this.state.currentHospital')
                                        const API = new Apilib();
                                        await API.updateHospitalVerification({
                                            hospital_id:
                                                this.state.currentHospital
                                                    .hospitalId,
                                            hospital_admin_id:
                                                this.state.currentHospital.uid,
                                            verification: {
                                                status:
                                                    this.state.currentHospital
                                                        .verification.status,
                                                verifier_info: {
                                                    name: this.props?.userData
                                                        ?.name,
                                                    type: this.props?.userData
                                                        ?.type,
                                                    uid: this.props?.userData?.uid,
                                                    verified_at:
                                                        new Date().toISOString(),
                                                },
                                            }
                                        });
                                        this.setState({
                                            loading: false,
                                        });
                                        Notify({
                                            title: "Status Updated Successfully",
                                            type: "success",
                                        });
                                        this.handleVerifyClose();
                                        this.fetchUsers();
                                    } catch (error) {
                                        this.setState({
                                            loading: false,
                                        });
                                        Notify({
                                            title: "Failed to update status",
                                            type: "error",
                                        });
                                    }
                                }}
                            >
                                Update
                            </Button>
                            <Button
                                variant="danger"
                                onClick={this.handleVerifyClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            );
        }
    }
}
