// jshint esversion:6

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apilib from "../../api";
import { SplashLoader } from "../Loader/Loader";
import "./style.css";
var QRCode = require("qrcode.react");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function PublicOrderDetails({ userData }) {
  const classes = useStyles();
  const { uid } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [doctor, setDoctor] = useState({});

  useEffect(() => {
    let API = new Apilib();
    setLoading(true);
    API.getOrderByUid(uid)
           .then((result) => {
        let res = result.data

        setData(res.data);
      });

    return () => {};
  }, []);

  useEffect(() => {
    let API = new Apilib();
    API.getUserDetails(3, data.doctorId)
           .then((result) => {
        let res = result.data

        console.log("ressss-=-=-", res);
        setDoctor(res.user);
        setLoading(false);
      });
  }, [data]);

  console.log("doctor-=-=", doctor);

  let medName = [];
  let medQuantity = [];

  medName =
    data?.prescription &&
    data?.prescription.map((pres) =>
      pres.medicines.map((med) => {
        return med?.name;
      })
    );
  medQuantity =
    data?.prescription &&
    data?.prescription.map((pres) =>
      pres.medicines.map((med) => {
        return med?.qty;
      })
    );

  let medNameArr = medName?.toString().split(",");
  let medQuantityArr = medQuantity?.toString().split(",");

  if (loading) return <SplashLoader />;

  return (
    <>
      <ul className="newul2 container">
        <li className="newli vertical-height doc-info">
          <h4 className="doctor-name-presc">{`Dr. ${data.doctorName}`}</h4>
          <p className="doctor-number-presc">Tel: {doctor?.landline}</p>
          <p className="doctor-number-presc">Email: {doctor?.email}</p>
        </li>
        <li className="newli2">
          <QRCode
            className="qr-code"
            value={`${
              window.location.origin + "/#/pharmacy/orders/order-details/"
            }${uid}`}
          />
        </li>
        <li className="newli2 vertical-height">
          <h4 className="doctor-detail-presc">Reg Number</h4>
          <p className="doctor-detail-value">{doctor?.regnumber}</p>
        </li>
        <li className="newli2 vertical-height">
          <h4 className="doctor-detail-presc">Qualification</h4>
          <p className="doctor-detail-value">
            {doctor?.qualifications && doctor?.qualifications.join(", ")}
          </p>
        </li>
      </ul>
      <div className="second-component">
        <ul
          className="newul container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Order Id</h4>
              <p className="doctor-detail-value">{`${data.uid}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Patient Name</h4>
              <p className="doctor-detail-value">{`${data.patientName}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Patient Email</h4>
              <p className="doctor-detail-value">{`${data.patient_email}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Patient Phone</h4>
              <p className="doctor-detail-value">{`${data.patientPhone}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Order Status</h4>
              <p className="doctor-detail-value">
                {data.isOrderComplete ? "Done" : "Pending"}
              </p>
            </div>
          </li>
        </ul>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col">
              <div className="medicine-table">
                <h4>Medicines:</h4>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="centre">
                          Sr. No.
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Medicine Name
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Quantity
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {medNameArr && medNameArr.length === 0 && (
                        <tr className="d-flex justify-content-center align-items-center w-100 p-3">
                          <span>No medicines available</span>
                        </tr>
                      )}
                      {medNameArr &&
                        medNameArr.map((med, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              width={10}
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {med}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {medQuantityArr[index]}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
