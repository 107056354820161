import React from 'react';
import { auth } from '../firebase';
import { Router, Redirect } from 'react-router-dom';

export default function LoginCheck() {
    let user = auth.onAuthStateChanged(user => {
        console.log(user);
        if (user == null) {
            // alert('redirec');
            return (<Redirect to={'/Login'} />);
        }
    });
}