// jshint esversion:6

import React, { Component, useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Header2 from "../Components/Patient/Header";

import Sidebar from "../Components/Sidebar/Sidebar";
import { auth } from "../firebase";
import { Router, Redirect, withRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import { Usertypes } from "../Helpers/constants";
import Apilib from "../api";
// import './style.css';
import "./style.scss";
import { SplashLoader } from "../Components/Loader/Loader";
import { useStateValue } from "../StateProvider";

const DashboardLayout = ({ children, type, path, user, userData, ...rest }) => {
  console.log("dashboard layout", type, path, user, userData);
  return (
    <>
      <link rel="stylesheet" type="text/css" href={"assets/css/style.css"} />
      <div className="main-wrapper">
        {<Header path={path} user={user} userData={userData} type={type} />}
        <Sidebar path={path} type={type} />
        <div className="page-wrapper ">
          <div className="content">{children}</div>
        </div>
      </div>
      <div className="sidebar-overlay" data-reff=""></div>
    </>
  );
};

const DashboardLayoutRoute = ({
  component: Component,
  userType,
  path,
  ...rest
}) => {
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState();
  const [userData, setUserData] = useState("loading");
  const [userDisplayName, setUserDisplayName] = useState(true);
  const [{ userPP }, dispatch] = useStateValue();

  function updateUser(newUser) {
    setUserData(newUser);
  }


  function logoutUser(e) {
    console.log(logoutUser)
    // e.preventDefault();
    auth
      .signOut()
      .then(() => {
        let type = localStorage.getItem("@displayName");
        localStorage.removeItem("@userData");
        localStorage.removeItem("@displayName");
        localStorage.removeItem("@prefLogin");
        // this.setState({
        //   redirect: "/",
        //   loading: false,
        // });

      })
      .catch((e) =>
        // this.setState({
        //   loading: false,
        // })
        console.log(e)
      );
  }

  useEffect(() => {
    let isSubscribed = true;
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("user", user);
        if (localStorage.getItem("@displayName") == userType) {
          API.getUserDetails(localStorage.getItem("@displayName"), user.uid)
            .then((res) => {
              if (userType == 3 && res.data.user.status.value == "inactive") {
                logoutUser()
                return
              }
              // else 
              if (isSubscribed) {
                setUser(user);
                setUserDisplayName(user.displayName);
                setUserData(res.data.user);
                setRedirect(null);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (isSubscribed) {
            setUser(null);
            setRedirect(Usertypes[localStorage.getItem("@displayName") - 1][1]);
          }
        }
      } else {
        setRedirect("/Login");
      }
    });
    API.getLocation()
      .then((result) => {
        let res = result.data

        console.log("api workingggg")
        dispatch({
          type: "SET_LOCATION",
          lat: res.latitude,
          long: res.longitude,
          country_code: res.country_code,
        });
      })
      .catch((err) => {
        console.log("geoError dashboard", err);
      });
    return () => (isSubscribed = false);
  }, [dispatch, userType]);

  if (redirect) {
    return <Redirect to={redirect} />;
  } else if (userData == "loading") {
    return (
      <>
        <SplashLoader />
      </>
    );
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <DashboardLayout
            user={user}
            userData={userData}
            path={path}
            type={userDisplayName}
          >
            <Component
              type={userDisplayName}
              userData={userData}
              updateUser={updateUser}
              user={user}
              {...matchProps}
            />
          </DashboardLayout>
        )}
      />
    );
  }
};

export default DashboardLayoutRoute;
