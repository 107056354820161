import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import prescriptionImg from '../../../src/assets/img/medical-prescription1.png';
import scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img from '../../../src/assets/img/prescription1.png';
import moment from 'moment';
import Apilib from "../../api";
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';
// import { getApptData } from './data';

const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 5px;
  margin-left:5px;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 95%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Div1 = styled.div`
  width: 35%;
`;

const Div2 = styled.div`
  width: 65%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content:flex-start;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: #50d8fa;
`;

function GenerateAuthority_script_number(n) {
    return (n - 1) % 9 + 1;
}

const GeneratePrescriptionForm = ({ route }) => {
    const location = useLocation();

    // let da = location.json()
    let mk = location.hash.substring(1)
    console.log('this is prescription form appointmentDatas and dat here', mk, location)
    var obj = Object.fromEntries(new URLSearchParams(mk));
    // var myobj = JSON.parse(obj);
    let ku = Object.keys(obj)[0];
    var previousData = JSON.parse(ku);
    // console.log('this is prescription form appointmentDatas and', typeof obj)
    // console.log('this is prescription form appointmentDatas and', location)
    // console.log('this is prescription form appointmentDatas and', obj)
    console.log('this is prescription form appointmentDatas and', previousData)
    // const appointmentDatas = getApptData();

    // console.log(appointmentDatas, 'this is prescription form appointmentDatas')

    // let appointmentData = route.appointmentData
    let appointmentData = {
        "doctor_email": "aimoolaram@gmail.com",
        "appointment_status": "booked",
        "patientId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
        "created_at": 1675785635000,
        "appointment_payment": {
            "label": "Cash",
            "value": "1"
        },
        "slot_duration": "30:00",
        "doctorName": "Moola Ram",
        "hospitalId": "71ZNVAMJgBHhgAFb1mrP",
        "doctorId": "7q6GWjtjZ6bjiqjdgmaEN2boYRt1",
        "from": 1675823400000,
        "slot_time": "08:00 AM",
        "email": "ridmal2009@gmail.com",
        "hospital_address": "",
        "doctor_specialities": [
            "General Physician"
        ],
        "hospital_name": "Clinic",
        "appointmentType": "2",
        "slot_date": "08/02/2023",
        "doctor_experience": "3",
        "for_family_member": false,
        "userId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
        "phoneno": "919672699969",
        "base_fee": "300",
        "country_code": "",
        "appointmentpayment": "1",
        "patient_email": "ridmal2009@gmail.com",
        "name": "moola borawat",
        "userType": "5",
        "to": 1675825200000,
        "join_url": "http://53digital.com/demos/medvatic/#/call?meetingid=DCnMpVXqf_&appointmentid=09gTO6OAdhv6VexSmhTT",
        "appointmentId": "09gTO6OAdhv6VexSmhTT",
        "meetingid": "DCnMpVXqf_",
        "token": "0067b9fa21ad2ed4216a5e0edad08e119fdIAApgfv2FmbvZM7APYqIU+uHmDNfkrygiixxX9oS7Txn9ZrE8VwAAAAAIgD2xicBqhjlYwQAAQAwdONjAgAwdONjAwAwdONjBAAwdONj"
    }
    const [fullName, setFullName] = useState('');
    const [includeDr, setIncludeDr] = useState(false);
    const [is18, setis18] = useState(false);
    const [loading, setloading] = useState(false);
    const [abbreviatedQualifications, setAbbreviatedQualifications] = useState('');
    const [Patient_Medicare_No, setPatient_Medicare_No] = useState('');
    const [practiceName, setPracticeName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [Dates, setDates] = useState(moment().format('DD MMM, YYYY'));
    const [prescriberNumber, setPrescriberNumber] = useState('');
    // TODO: fetch Authority_script_number from database and provide to this
    const [Authority_script_number, setAuthority_script_number] = useState('1178451');

    const [inputBoxes, setInputBoxes] = useState([{
        "drugName": "",
        "Dosage_directions": "",
        "Quantity": "",
        "Repeats": "",
        "Authority_code": "",
        "Clinical_justification": "",
        "PBS": false,
        "includeDrAuth": false,
        "medAuth": false,
        'morning': false,
        'afternoon': false,
        'evening': false,
        'night': false,
        'AfterFood': false,
        'Additional_Instructions': '',
        'duration': 1
    }]);

    useEffect(() => {
        let theNum = GenerateAuthority_script_number(1178451);
        setAuthority_script_number('' + Authority_script_number + theNum)


        let newPres = []
        previousData.prescriptions[0].medicines.map(x => {
            let objToPush = {
                "drugName": x.name,
                "Dosage_directions": x.qty,
                "Quantity": "",
                "Repeats": "",
                "Authority_code": "",
                "Clinical_justification": "",
                "PBS": false,
                "includeDrAuth": false,
                "medAuth": false,
                'morning': x.intake_time.MN,
                'afternoon': x.intake_time.AF,
                'evening': x.intake_time.EV,
                'night': x.intake_time.NT,
                'AfterFood': x.am_bm_stat == "Before",
                'Additional_Instructions': x.subtitle,
                'duration': x.num_days
            }

            newPres.push(objToPush)
        })
        if (previousData.prescriptions[0].medicines.length) setInputBoxes(newPres)

    }, []);








    const handleInputChange = (index, event, field) => {
        if (field == 'PBS' || field == 'includeDrAuth' || field == 'medAuth') {
            const values = [...inputBoxes];
            values[index][field] = !values[index][field];
            setInputBoxes(values);
            return
        }
        console.log(inputBoxes)
        const values = [...inputBoxes];
        values[index][field] = event.target.value;
        setInputBoxes(values);
    };
    const handleInputChangeCheck = (index, event, field) => {
        const values = [...inputBoxes];
        values[index][field] = !values[index][field];
        setInputBoxes(values);
    };
    const handleAge = (event) => {
        console.log(is18)
        setis18(!is18);
    };

    const handleAddBox = () => {
        const values = [...inputBoxes];
        values.push({
            "drugName": "",
            "Dosage_directions": "",
            "Quantity": "",
            "Repeats": "",
            "Authority_code": "",
            "Clinical_justification": "",
            "PBS": false,
            "includeDrAuth": false,
            "medAuth": false,
            'morning': false,
            'afternoon': false,
            'evening': false,
            'night': false,
            'Additional_Instructions': '',
            'AfterFood': false,
            'duration': 1
        });
        setInputBoxes(values);
    };

    const handleRemoveBox = (index) => {
        const values = [...inputBoxes];
        values.splice(index, 1);
        setInputBoxes(values);
    };


    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
    };

    const handleIncludeDrChange = (e) => {
        setIncludeDr(e.target.checked);
    };

    const handleAbbreviatedQualificationsChange = (e) => {
        setAbbreviatedQualifications(e.target.value);
    };

    const handlePracticeNameChange = (e) => {
        setPracticeName(e.target.value);
    };
    const handlePatient_Medicare_No = (e) => {
        setPatient_Medicare_No(e.target.value);
    };

    const handleStreetAddressChange = (e) => {
        setStreetAddress(e.target.value);
    };

    const handlePrescriberNumberChange = (e) => {
        setPrescriberNumber(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to handle form submission
        // ...
    };

    const reusableInputState = (label, valueVar, onChaneVar) => {
        return <Containers>
            <Div1>
                <Label>{label}</Label>
            </Div1>
            <Div2>
                <Input type="text" value={valueVar} onChange={onChaneVar} />
            </Div2>
        </Containers>
    }

    const reusableDivState = (label) => {
        return <Div1>
            <Label>{label}</Label>
        </Div1>
    }

    const reusableMenuState = (label, onClickHandleMethod, icon) => {
        return <SidebarOption onClick={() => onClickHandleMethod()}>
            <i className={icon}></i><SidebarOptionText>{label}</SidebarOptionText>
        </SidebarOption>
    }
    const onClickHandle = () => {
        console.log('hi there')
    }
    return (
        <Container>
            {loading && <Loader />}
            <div style={{ display: 'flex' }}>
                {/* <div style={{ width: '20%' }}>

                    <Sidebar>
                        <SidebarHeading>Medvatic</SidebarHeading>

                        {reusableMenuState('Admin Dashboard', onClickHandle, "fa fa-address-card")}
                        {reusableMenuState('Doctor Dashboard', onClickHandle, "fa fa-user-plus")}
                        {reusableMenuState('Patient Dashboard', onClickHandle, "fa fa-home")}
                        {reusableMenuState('Pharmacist Dashboard', onClickHandle, "fa fa-home")}
                        {reusableMenuState('Analysis', onClickHandle, "fa fa-line-chart")}
                        {reusableMenuState('Appointment', onClickHandle, "fa fa-stethoscope")}
                        {reusableMenuState('Doctors', onClickHandle, "fa fa-user-md")}
                        {reusableMenuState('Patients', onClickHandle, "fa fa-male")}
                        {reusableMenuState('Prescription', onClickHandle, "fa fa-clipboard")}
                        {reusableMenuState('Service', onClickHandle, "fa fa-newspaper-o")}
                        {reusableMenuState('Department', onClickHandle, "fa fa-outdent")}
                        {reusableMenuState('Pharmacy', onClickHandle, "fa fa-medkit")}
                        {reusableMenuState('Finance', onClickHandle, "fa fa-money")}
                        {reusableMenuState('Support', onClickHandle, "fa fa-phone-square")}
                        {reusableMenuState('Chat', onClickHandle, "fa fa-comments-o")}
                        {reusableMenuState('Settings', onClickHandle, "fa fa-cog")}
                        {reusableMenuState('Logout', onClickHandle, "fa fa-sign-out")}

                    </Sidebar>
                </div> */}
                <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
                    <Content>

                        <ButtonGroup>
                            {/* scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img */}
                            <Button style={{ fontWeight: '400', fontSize: '21px' }}
                                onClick={() => {
                                    history.push('/doctor/NewPrescription')
                                }}
                            >
                                <img
                                    src={prescription1Img}
                                    alt="new"
                                    style={{ width: '40px', height: '40px' }}
                                />
                                New Rx</Button>
                            <Button style={{ fontWeight: '400', fontSize: '21px' }}>

                                <img
                                    src={scriptImg}
                                    alt="new"
                                    style={{ width: '40px', height: '40px' }}
                                />
                                Scripts</Button>
                            <Button style={{ backgroundColor: '#FF8E3C', fontWeight: '400', fontSize: '21px' }}>
                                <img
                                    src={prescriptionImg}
                                    alt="new"
                                    style={{ width: '40px', height: '40px' }}
                                />
                                Prescribers</Button>
                        </ButtonGroup>
                        <ButtonGroup2>
                            <Label1>Prescriber Details</Label1>
                            <ButtonNormal onClick={() => history.push('/doctor/Prescribers')}>Add New prescriber</ButtonNormal>
                        </ButtonGroup2>

                        <Form style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '100%', backgroundColor: '#fff' }} onSubmit={handleSubmit}>
                            <Heading>New Prescription</Heading>
                            <SubNote>Complete all sections required for your prescription</SubNote>
                            <SubNote style={{ textAlign: 'right' }}>Script number: {' ' + Authority_script_number}</SubNote>

                            <div style={{ border: 'solid #000 .05px' }}></div>
                            <div>
                                <Heading>Patient Details</Heading>

                                {reusableInputState('Full Name:', fullName, handleFullNameChange)}
                                {reusableInputState('Street Address:', streetAddress, handleStreetAddressChange)}

                                {reusableInputState('Medicare Number:', Patient_Medicare_No, handlePatient_Medicare_No)}
                                {reusableInputState('IRN:', practiceName, handlePracticeNameChange)}




                                <Heading>Medication Details</Heading>

                                {/* 
                                {reusableInputState('Active ingredient:', streetAddress, handleStreetAddressChange)}
                                {reusableInputState('Dosage directions:', streetAddress, handleStreetAddressChange)}
                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                                    PBS Prescription <br />
                                </CheckboxLabel>
                                <SubNote>Select a medication from the dropdown list for PBS information</SubNote>
                                {reusableInputState('Quantity:', streetAddress, handleStreetAddressChange)}
                                {reusableInputState('Repeats:', streetAddress, handleStreetAddressChange)}
                                <div style={{ borderWidth: '3px', borderColor: '#000' }}></div>

                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                                    Authority required
                                </CheckboxLabel>
                                <SubNote>Select a medication from the dropdown list for authority information</SubNote>
                                <SubNote>Authority script number: 0000555</SubNote>
                                {reusableInputState('Authority code (where applicable):', streetAddress, handleStreetAddressChange)}
                                {reusableInputState('Clinical justification for use of item:', streetAddress, handleStreetAddressChange)}
                                {reusableInputState("Patient's age if under 18:", streetAddress, handleStreetAddressChange)}

                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                                    Patient has received authority for this medicine before
                                </CheckboxLabel> */}



                                <div>
                                    {inputBoxes.map((inputState, index) => (
                                        <div key={index} style={{ backgroundColor: '#e6fdf9', padding: '10px', margin: '5px', borderRadius: '11px' }}>

                                            <Containers>
                                                {reusableDivState('Drug Name')}
                                                <Div2>
                                                    <Input type="text" value={inputState.drugName} onChange={(event) => handleInputChange(index, event, 'drugName')} />
                                                </Div2>
                                            </Containers>

                                            <Containers>
                                                {reusableDivState('Dosage directions')}
                                                <Div2>
                                                    <Input type="text" value={inputState.Dosage_directions} onChange={(event) => handleInputChange(index, event, 'Dosage_directions')} />
                                                </Div2>
                                            </Containers>




                                            {/* <Containers>
                                                {reusableDivState('Quantity')}
                                                <Div2>
                                                    <Input type="text" value={inputState.Quantity} onChange={(event) => handleInputChange(index, event, 'Quantity')} />
                                                </Div2>
                                            </Containers> */}

                                            <Containers>
                                                {reusableDivState('Repeats')}
                                                <Div2>
                                                    <Input type="text" value={inputState.Repeats} onChange={(event) => handleInputChange(index, event, 'Repeats')} />
                                                </Div2>
                                            </Containers>
                                            <Containers>
                                                {reusableDivState('Duration (in days)')}
                                                <Div2>
                                                    <Input type="number" value={inputState.duration} onChange={(event) => handleInputChange(index, event, 'duration')} />
                                                </Div2>
                                            </Containers>

                                            <div style={{ borderWidth: '3px', borderColor: '#000' }}></div>












                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'space-evenly',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                INTERVAL
                                                <CheckboxLabel>

                                                    <Checkbox type="checkbox" checked={inputState.morning}
                                                        onChange={(event) => handleInputChangeCheck(index, event, 'morning')}

                                                    />MORNING
                                                </CheckboxLabel>
                                                <CheckboxLabel>

                                                    <Checkbox type="checkbox" checked={inputState.afternoon}
                                                        onChange={(event) => handleInputChangeCheck(index, event, 'afternoon')}
                                                    />AFTERNOON
                                                </CheckboxLabel>


                                                <CheckboxLabel>

                                                    <Checkbox type="checkbox" checked={inputState.evening}
                                                        onChange={(event) => handleInputChangeCheck(index, event, 'evening')}
                                                    />EVENING
                                                </CheckboxLabel>
                                                <CheckboxLabel>

                                                    <Checkbox type="checkbox" checked={inputState.night}
                                                        onChange={(event) => handleInputChangeCheck(index, event, 'night')}
                                                    />NIGHT
                                                </CheckboxLabel>
                                                <CheckboxLabel>

                                                    <Checkbox type="checkbox" checked={inputState.AfterFood}
                                                        onChange={(event) => handleInputChangeCheck(index, event, 'AfterFood')}
                                                    />After Food
                                                </CheckboxLabel>

                                            </div>












                                            <Containers>
                                                {reusableDivState('Authority code')}
                                                <Div2>
                                                    <Input type="text" value={inputState.Authority_code} onChange={(event) => handleInputChange(index, event, 'Authority_code')} />
                                                </Div2>
                                            </Containers>

                                            <Containers>
                                                {reusableDivState('Clinical justification')}
                                                <Div2>
                                                    <Input type="text" value={inputState.Clinical_justification} onChange={(event) => handleInputChange(index, event, 'Clinical_justification')} />
                                                </Div2>
                                            </Containers>
                                            <Containers>
                                                {reusableDivState('Additional Instructions')}
                                                <Div2>
                                                    <Input type="text" value={inputState.Additional_Instructions} onChange={(event) => handleInputChange(index, event, 'Additional_Instructions')} />
                                                </Div2>
                                            </Containers>


                                            {/* <SubNote>Authority script number: 0000555</SubNote> */}

                                            <CheckboxLabel>
                                                <Checkbox type="checkbox" checked={inputState.PBS} onChange={(event) => handleInputChange(index, event, 'PBS')} />
                                                PBS Prescription
                                                <SubNote>Select a medication from the dropdown list for PBS information</SubNote>
                                            </CheckboxLabel>


                                            <CheckboxLabel>
                                                <Checkbox type="checkbox" checked={inputState.includeDrAuth} onChange={(event) => handleInputChange(index, event, 'includeDrAuth')} />
                                                Authority required
                                                <SubNote> Select a medication from the dropdown list for authority information</SubNote>
                                            </CheckboxLabel>




                                            <CheckboxLabel>
                                                <Checkbox type="checkbox" checked={inputState.medAuth} onChange={(event) => handleInputChange(index, event, 'medAuth')} />
                                                Patient has received authority for this medicine Before
                                            </CheckboxLabel>
                                            <br />

                                            <CheckboxLabel style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                <SubNote></SubNote>
                                                <Button style={{ backgroundColor: 'red', justifyContent: 'flex-end' }} onClick={() => handleRemoveBox(index)}>Delete Medicine</Button>
                                            </CheckboxLabel>
                                            {/* 
                                            <CheckboxLabel>
                                                <SubNote></SubNote>

                                                <Button style={{ backgroundColor: 'red', justifyContent: 'flex-end' }} onClick={() => handleRemoveBox(index)}>Delete Medicine</Button>

                                            </CheckboxLabel> */}



                                            {/* {reusableInputState('Active ingredient:', box.drugName, handleInputChange(index, event, 'drugName'))} */}

                                            {/* {reusableInputState('Dosage directions:', streetAddress, handleStreetAddressChange)}
                                            <CheckboxLabel>
                                                <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                                                PBS Prescription <br />
                                            </CheckboxLabel>
                                            <SubNote>Select a medication from the dropdown list for PBS information</SubNote>
                                            {reusableInputState('Quantity:', streetAddress, handleStreetAddressChange)}
                                            {reusableInputState('Repeats:', streetAddress, handleStreetAddressChange)}
                                            <div style={{ borderWidth: '3px', borderColor: '#000' }}></div>

                                            <CheckboxLabel>
                                                <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                                                Authority required
                                            </CheckboxLabel>
                                            <SubNote>Select a medication from the dropdown list for authority information</SubNote>
                                            <SubNote>Authority script number: 0000555</SubNote>
                                            {reusableInputState('Authority code (where applicable):', streetAddress, handleStreetAddressChange)}
                                            {reusableInputState('Clinical justification for use of item:', streetAddress, handleStreetAddressChange)}
                                            {reusableInputState("Patient's age if under 18:", streetAddress, handleStreetAddressChange)}

                                            <CheckboxLabel>
                                                <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                                                Patient has received authority for this medicine before
                                            </CheckboxLabel> */}



                                            {/* <div style={{ borderWidth: '3px', borderColor: 'red', border: 'solid #000 0.03px' }}></div> */}
                                        </div>
                                    ))}
                                    <Button onClick={handleAddBox}>Add new medicine</Button>
                                </div>





                                {/* <SubNote>Complete all sections required for your prescription</SubNote> */}

                                < CheckboxLabel style={{ marginTop: '10px' }}>

                                    <Checkbox type="checkbox" checked={is18} onChange={handleAge} />
                                    Patient's age if under 18
                                </CheckboxLabel>
                                <CheckboxLabel style={{ marginTop: '10px' }}>

                                    {reusableDivState('Date')}
                                    {reusableDivState(Dates)}
                                </CheckboxLabel>
                                {/* {reusableInputState("Date", streetAddress, handleStreetAddressChange)} */}

                                {/* <Label>
                                                    Prescriber Number:
                                                    <Input type="text" value={prescriberNumber} onChange={handlePrescriberNumberChange} />
                                                    </Label> */}
                            </div>

                            {/* <SubmitButton type="submit">Generate Prescription</SubmitButton>
                                                <SubmitButton type="submit">Cancel</SubmitButton> */}
                            <ButtonGroup>
                                {/* scriptImg from '../../../src/assets/img/script1.png';
                                    import prescription1Img */}
                                <Button style={{ fontWeight: '400', fontSize: '16px' }}
                                    onClick={async () => {
                                        console.log('this is it')

                                        setloading(true)
                                        let presDet = []
                                        let presDetFinal = []
                                        let additionalDetails = []

                                        try {
                                            let API = new Apilib()
                                            let presc = [], dosage = [], timetotakemedicine = [], Notes = [], presNote = [];
                                            let i = 0;
                                            let durationArray = []
                                            let beforeorafterarray = []
                                            // let localDataRecord = { name: prescriptionFormControl, dosage: inputDataDosage, timing: inputDataTiming, prescriptionNote: prescriptionNote, beforeorafter: beforeorafter, duration: duration }

                                            let druggs = {
                                                "Quantity": "",
                                                "Repeats": "",
                                                "Authority_code": "",
                                                "Clinical_justification": "",
                                                "PBS": false,
                                                "includeDrAuth": false,
                                                "medAuth": false,
                                                'duration': '',
                                            }


                                            inputBoxes.map(drugs => {
                                                presc.push(drugs.drugName)
                                                dosage.push(drugs.Dosage_directions)
                                                presNote.push(drugs.Additional_Instructions)
                                                let temp = { morning: drugs.morning, afternoon: drugs.afternoon, evening: drugs.evening, night: drugs.night }
                                                timetotakemedicine.push(temp)
                                                beforeorafterarray.push(drugs.AfterFood)
                                                durationArray.push(drugs.duration)

                                                additionalDetails.push({
                                                    Quantity: drugs.Quantity,
                                                    Repeats: drugs.Repeats,
                                                    Authority_code: drugs.Authority_code,
                                                    Clinical_justification: drugs.Clinical_justification,
                                                    PBS: drugs.PBS,
                                                    includeDrAuth: drugs.includeDrAuth,
                                                    medAuth: drugs.medAuth,
                                                    duration: drugs.duration,
                                                })
                                            })


                                            // localDataRecord.map((currentElement) => {
                                            //     // presc.push(currentElement.name)
                                            //     // dosage.push(currentElement.dosage)
                                            //     // presNote.push(currentElement.prescriptionNote)
                                            //     i++;
                                            //     return currentElement
                                            // })

                                            // to do still``
                                            // newItems.map((currentElement) => {
                                            //     Notes.push(currentElement.name)
                                            //     return currentElement
                                            // })
                                            // optionss.map((currentElement) => {
                                            //     let temp = { morning: currentElement.morning, afternoon: currentElement.afternoon, evening: currentElement.evening, night: currentElement.night }
                                            //     timetotakemedicine.push(temp)
                                            //     return currentElement
                                            // })


                                            for (let l = 0; l < presc.length; l++) {
                                                let timingDay = {
                                                    MN: !!timetotakemedicine[l].morning,
                                                    AF: !!timetotakemedicine[l].afternoon,
                                                    EV: !!timetotakemedicine[l].evening,
                                                    NT: !!timetotakemedicine[l].night,
                                                }
                                                let ismorning = timetotakemedicine[l].morning, isafternoon = timetotakemedicine[l].afternoon, isevening = timetotakemedicine[l].evening, isnight = timetotakemedicine[l].night;



                                                if (ismorning) { timingDay.MN = !!timetotakemedicine[l].morning }
                                                if (isafternoon) { timingDay.AF = !!timetotakemedicine[l].afternoon }
                                                if (isevening) { timingDay.EV = !!timetotakemedicine[l].evening }
                                                if (isnight) { timingDay.NT = !!timetotakemedicine[l].night }

                                                let numberdays = (Number(timingDay.MN) + Number(timingDay.AF) + Number(timingDay.EV) + Number(timingDay.NT)) * (durationArray[l] || 1)

                                                let ambm = beforeorafterarray[l] ? 'Before' : 'After'



                                                let saveData = {
                                                    name: presc[l],//this is medicine name
                                                    intake_time: timingDay,
                                                    am_bm_stat: { "AF": ambm, "MN": ambm, "EV": ambm, "NT": ambm },
                                                    num_days: numberdays,
                                                    qty: dosage[l],
                                                    subtitle: presNote[l],
                                                    additionalDetails: additionalDetails[l]
                                                }
                                                console.log('saveData')
                                                console.log(saveData)
                                                presDet.push(saveData)
                                                console.log(presDet)
                                                console.log('presDet')

                                            }

                                            presDetFinal = [
                                                {
                                                    medicines: presDet
                                                }
                                            ]
                                            let data = {
                                                prescription: presc, patientNote: Notes, dosage: dosage, prescriptionNote: presNote, timeToTakeMedicine: timetotakemedicine,
                                                saveAppointmentData: appointmentData,
                                                prescriptions: presDetFinal,
                                                type: appointmentData.userType,
                                                additionalDetails: additionalDetails
                                            }


                                            await API.saveAppointmentData(JSON.stringify(data))
                                            // console.log(JSON.stringify(data), 'this is data sent');
                                            // let tokenn = await API.saveAppointmentData(data)
                                            alert('Appointment Completed')

                                            console.log(JSON.stringify(data), 'this is data sent');

                                            history.push(`/doctor/patients`);


                                        } catch (error) {
                                            console.log(error, 'here is s the error');
                                        }
                                        setloading(false)
                                    }}
                                >
                                    Generate Prescription</Button>
                                <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }}>
                                    Cancel</Button>
                            </ButtonGroup>
                        </Form>
                    </Content></div>
            </div>
        </Container >
    );
};

export default GeneratePrescriptionForm;
