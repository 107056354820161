// jshint esversion:6

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../api";
import Notify from "../../Notify";
import { SplashLoader } from "../Loader/Loader";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(no, name, frequency, time, duration, quantity, comments) {
  return { no, name, frequency, time, duration, quantity, comments };
}

const rows = [
  createData(1, "54gddf", "MN - NT", "After - After", 20, 4),
  createData(2, "rgter", "MN - NT", "After - Before", 37, 5),
  createData(3, "dsfs", "MN - NT", "Before - Before", 24, 2),
  createData(4, "rtdrte", "MN - NT", "After - After", 67, 7),
  createData(5, "Odexa-m", "NT", "After", 49, 9),
];

export default function PublicPrescriptions() {
  const classes = useStyles();
  const {id: appointmentId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [appointment, setAppointment] = useState(null);
  const [userData, setUserData] = useState(null);
  const [sequence, setSequence] = useState(["MN","AF","EV","NT"]);

  const fetchAppointment = async (appointmentId) => {
    try {
      setIsLoading(true);
      let API = new Api();
      const appointmentDetails = await (
        await API.getAppointmentDetails(appointmentId)
      ).json();
      const doctorDetails = await (
        await API.getUserDetails(
          3,
          appointmentDetails.appointment_details.doctorId
        )
      ).json();
      const patientDetails = await (
        await API.getUserDetails(
          5,
          appointmentDetails.appointment_details.patientId
        )
      ).json();
      console.log({appointmentDetails, doctorDetails, patientDetails});
      setAppointment({ data: appointmentDetails, doctor: doctorDetails?.user });
      setUserData(patientDetails?.user);
      setIsLoading(false);
    } catch (error) {
      console.log({error});
      setIsLoading(false);
      Notify({
        alert: true,
        title: "Failed to fetch prescription details",
        type: "error",
      });
    }
  };
  useEffect(() => {
    console.log({appointmentId})
    if(appointmentId){
      fetchAppointment(appointmentId);
    }
    return () => {};
  }, [appointmentId]);

  if (isLoading) return <SplashLoader />;

 

  return (
    <>
    <button onClick={() => window.print()}>Save</button>
    <header>
        <div
          className="container"
          style={{ padding: "1em 0 1.3em 0" }}
        >
          <div className="d-flex justify-content-between align-items-end">
            <div className="mr-3">
              <div className="logo">
                {/* <a href="/"> */}
                  <img
                    // className="top_logo"
                    src="assets/img/logo1.png"
                    alt="Medvatic"
                    // className="medvatic-logo"
                  />
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ height: "1.3em", backgroundColor: "#E8E8E8" }}></div> */}
      </header>
      <ul className="newul2 container">
        <li className="newli vertical-height doc-info">
          <h4 className="doctor-name-presc">{`Dr. ${appointment.doctor.firstName} ${appointment.doctor.lastName}`}</h4>
          <p className="doctor-number-presc">
            Tel: {appointment.doctor.mobile}
          </p>
          <p className="doctor-number-presc">
            Email: {appointment.doctor.email}
          </p>
        </li>
        <li className="newli2 vertical-height">
          <h4 className="doctor-detail-presc">Reg Number</h4>
          <p className="doctor-detail-value">{appointment.doctor.regnumber}</p>
        </li>

        <li className="newli2 vertical-height">
          <h4 className="doctor-detail-presc">Qualification</h4>
          <p className="doctor-detail-value">
            {appointment.doctor.qualifications.join(", ")}
          </p>
        </li>
      </ul>
      <div className="second-component">
        <ul
          className="newul container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Patient Name</h4>
              <p className="doctor-detail-value">{`${userData.firstName} ${userData.lastName}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Gender</h4>
              <p
                style={{ textTransform: "capitalize" }}
                className="doctor-detail-value"
              >{`${userData.gender}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Height</h4>
              <p className="doctor-detail-value">{`${userData.height}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Weight</h4>
              <p className="doctor-detail-value">{`${userData.weight}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Day</h4>
              <p className="doctor-detail-value">
                {moment(
                  appointment.data.appointment_details.slot_date,
                  "DD/MM/YYYY"
                ).format("LL")}
              </p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Time</h4>
              <p className="doctor-detail-value">
                {moment(appointment.data.appointment_details.from).format("LT")}
                -{moment(appointment.data.appointment_details.to).format("LT")}
              </p>
            </div>
          </li>
        </ul>
        <div class="container">
          <div class="row">
            <div class="col">
              <div className="medicine-table">
                <div className="d-flex justify-content-between align-items-end">
                  <h4>Prescription:</h4>
                  <p className="doctor-number-presc">Prescription Id: {appointmentId}</p>
                </div>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="centre">No</StyledTableCell>
                        <StyledTableCell align="centre">
                          Medicine
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Frequency <br/>
                          (MN - AF - EN - NT)
                        </StyledTableCell>
                        {/* <StyledTableCell align="centre">
                          Intake Time
                        </StyledTableCell> */}
                        <StyledTableCell align="centre">
                          Duration
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Comments
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointment.data.prescription_details.length === 0 && (
                        <tr className="d-flex justify-content-center align-items-center w-100 p-3">
                          <span>No medicines available</span>
                        </tr>
                      )}
                      {appointment.data.prescription_details
                        .reduce((acc, curr) => {
                          acc = [...acc, ...curr.medicines];
                          return acc;
                        }, [])
                        .map((med, index) =>
                          createData(
                            index + 1,
                            med.name,
                            med.intake_time,
                            med.am_bm_stat,
                            med.num_days,
                            med.qty,
                            med.subtitle
                          )
                        )
                        .map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              width={10}
                            >
                              {row.no}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              { sequence
                                // .filter((key) => !!row.frequency[key])
                                .map((key) => (row.frequency[key] ? row.frequency[key] : 0)).join(' - ')
                              }
                                <hr className="my-1"/>
                              { sequence
                                // .filter((key) => row.time[key] !== "null")
                                .map(key => row.time[key] !== "null" ? row.time[key] : "0")
                                .join(" - ")
                              }
                            </StyledTableCell>
                            {/* <StyledTableCell align="centre">
                              { sequence
                                .filter((key) => row.time[key] !== "null")
                                .map(key => row.time[key])
                                .join("-")
                              }
                            </StyledTableCell> */}
                            <StyledTableCell align="centre">
                              {row.duration}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {row.quantity}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {!!row.comments ? row.comments : "-"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
