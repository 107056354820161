import React, { useState } from 'react';
import styled from 'styled-components';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const StatContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => props.color || '#ffffff'};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
`;

const StatLabel = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
`;

const StatValue = styled.span`
  font-size: 24px;
  color: #fff;
`;

const randomColor = () => {
    const letters = '123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 10)];
    }
    return '#50d8fa';
};

const ActionButton = styled.button`
  background-color: #50d8fa;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  z-index:99;
  
`;

const ModalButton = styled.button`
  background-color: #50d8fa;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
`;
const styles = {
    input: {
        backgroundColor: '#e1e1e1',
        color: '#000',
        border: '1px',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        marginLeft: '10px',
    }
}
let schedulingId = null;
const PharmacyHome = () => {
    const [modalContent, setModalContent] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    // const [isModalOpen, setModalOpen] = useState(false);
    const [drugSchedule, setdrugSchedule] = useState([{
        name: 'ram', drugsToTake: '2',
        drugs: [{ id: 1, name: 'paracetamol', contents: ['N-acetyl-p-aminophenol', '4-hydroxyacetanilide'], time: [{ morning: true, afternoon: false, evening: false, night: true }] }, { id: 2, name: 'Cough Syrup', contents: ['Dextromethorphan ', 'Guaifenesin', 'Phenylephrine', 'Diphenhydramine', 'Codeine'], time: [{ morning: false, afternoon: true, evening: true, night: false }] }
        ]
    }, {
        name: 'George', drugsToTake: '1', drugs: [{ name: 'Cough Syrup', contents: ['Dextromethorphan ', 'Guaifenesin', 'Phenylephrine', 'Diphenhydramine', 'Codeine'], time: [{ morning: false, afternoon: true, evening: true, night: true }] }
        ]
    }]);

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const createNewInvoice = () => {
        // Logic to create a new invoice
        // ...
        closeModal();
    };

    const addNewCustomer = () => {
        // Logic to add a new customer
        // ...
        closeModal();
    };

    const addNewMedicine = () => {
        // Logic to add a new medicine
        // ...
        closeModal();
    };

    const addNewSupplier = () => {
        // Logic to add a new supplier
        // ...
        closeModal();
    };

    return (
        <DashboardContainer>
            <SectionTitle>Pharmacy Manager Dashboard</SectionTitle>
            <SectionTitle onClick={() => openModal('New Invoice')}>open </SectionTitle>
            <div style={{ width: '100%', backgroundColor: 'red', border: '3px', maxHeight: '3px', height: '3px', margin: '10px' }} > </div>
            <SectionTitle>Medicines Details</SectionTitle>

            <StatContainer>
                <StatCard color={randomColor()}>
                    <StatLabel>Total Orders</StatLabel>
                    <StatValue>500</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Pending Orders</StatLabel>
                    <StatValue>20</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Revenue</StatLabel>
                    <StatValue>$15000</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Total Patients</StatLabel>
                    <StatValue>300</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Expired Drugs</StatLabel>
                    <StatValue>10</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Drugs Out of Stock</StatLabel>
                    <StatValue>5</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Stocked Drugs</StatLabel>
                    <StatValue>1500</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Total Pharmacies</StatLabel>
                    <StatValue>20</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Total Pharmacy Clerks</StatLabel>
                    <StatValue>50</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Total Doctors</StatLabel>
                    <StatValue>10</StatValue>
                </StatCard>
                <StatCard color={randomColor()}>
                    <StatLabel>Total Patients Admitted</StatLabel>
                    <StatValue>15</StatValue>
                </StatCard>
            </StatContainer>
            <div style={{ width: '100%', backgroundColor: 'red', border: '3px', maxHeight: '3px', height: '3px', margin: '10px' }} > </div>


            {isModalOpen && (
                <Modal>
                    <ModalContent>
                        <ModalTitle>{modalContent}</ModalTitle>
                        {modalContent === 'New Invoice' && (
                            <form>
                                <label>
                                    Invoice Number:
                                    <input style={styles.input} type="text" name="invoiceNumber" required />
                                </label>
                                <label>
                                    Customer Name:
                                    <input style={styles.input} type="text" name="customerName" required />
                                </label>
                                {/* Add more fields as needed */}
                                <ModalButtonContainer>
                                    <ModalButton onClick={createNewInvoice}>Save</ModalButton>
                                    <ModalButton onClick={closeModal}>Cancel</ModalButton>
                                </ModalButtonContainer>
                            </form>
                        )}

                        {modalContent === 'Add New Customer' && (
                            <form>
                                <label>
                                    Customer Name:
                                    <input style={styles.input}
                                        type="text" name="customerName" required />
                                </label>
                                <label>
                                    Address:
                                    <input style={styles.input} type="text" name="address" required />
                                </label>
                                {/* Add more fields as needed */}
                                <ModalButtonContainer>
                                    <ModalButton onClick={addNewCustomer}>Save</ModalButton>
                                    <ModalButton onClick={closeModal}>Cancel</ModalButton>
                                </ModalButtonContainer>
                            </form>
                        )}

                        {modalContent === 'Add New Medicine' && (
                            <form>
                                <label>
                                    Medicine Name:
                                    <input style={styles.input} type="text" name="medicineName" required />
                                </label>
                                <label>
                                    Manufacturer:
                                    <input style={styles.input} type="text" name="manufacturer" required />
                                </label>
                                {/* Add more fields as needed */}
                                <ModalButtonContainer>
                                    <ModalButton onClick={addNewMedicine}>Save</ModalButton>
                                    <ModalButton onClick={closeModal}>Cancel</ModalButton>
                                </ModalButtonContainer>
                            </form>
                        )}

                        {modalContent === 'Add New Supplier' && (
                            <form>
                                <label>
                                    Supplier Name:
                                    <input style={styles.input} type="text" name="supplierName" required />
                                </label>
                                <label>
                                    Contact Person:
                                    <input style={styles.input} type="text" name="contactPerson" required />
                                </label>
                                {/* Add more fields as needed */}
                                <ModalButtonContainer>
                                    <ModalButton onClick={addNewSupplier}>Save</ModalButton>
                                    <ModalButton onClick={closeModal}>Cancel</ModalButton>
                                </ModalButtonContainer>
                            </form>
                        )}
                        {modalContent === 'Drug Scheduling' && (
                            <form>
                                {/* <label>
                                    Supplier Name:
                                    <input style={styles.input} type="text" name="supplierName" required />
                                </label>
                                <label>
                                    Contact Person:
                                    <input style={styles.input} type="text" name="contactPerson" required />
                                </label> */}
                                <StatContainer>
                                    <StatCard><StatLabel> Drug Scheduling</StatLabel> </StatCard>
                                    {drugSchedule.map(x => {

                                        if (schedulingId === x.id)
                                            return <StatCard>
                                                {console.log(x.drugs)}
                                                <StatLabel>{x.name}</StatLabel>
                                                {/* {x.drugs.time.map(p => <div><span>timing</span><StatLabel>morning{p.morning ? 'yes' : 'no'},afternoon {p.afternoon ? 'yes' : 'no'},evening{p.evening ? 'yes' : 'no'},night{p.night ? 'yes' : 'no'}</StatLabel></div>)} */}

                                                {/* {name: 'ram', drugsToTake: '2',drugs: [{ id: 1, name: 'paracetamol', contents: ['N-acetyl-p-aminophenol', '4-hydroxyacetanilide'], time: [{ morning: true, afternoon: false, evening: false, night: true }] }, { id: 2, name: 'Cough Syrup', contents: ['Dextromethorphan ', 'Guaifenesin', 'Phenylephrine', 'Diphenhydramine', 'Codeine'], time: [{ morning: false, afternoon: true, evening: true, night: false }] }]} */}
                                                <div>
                                                    <span>Composition</span>{x.drugs.map(y => y.contents.map(q => <StatLabel> {q} <br /></StatLabel>))}
                                                </div>
                                            </StatCard>
                                    }
                                    )}

                                </StatContainer>
                                {/* Add more fields as needed */}
                                <ModalButtonContainer>
                                    <ModalButton onClick={addNewSupplier}>Save</ModalButton>
                                    <ModalButton onClick={closeModal}>Cancel</ModalButton>
                                </ModalButtonContainer>
                            </form>
                        )}

                        {/* Add more modals and their respective forms here */}
                    </ModalContent>
                </Modal>
            )}

            {/* <StatCard color={randomColor()}>
                <StatLabel>Drugs Out of Stock</StatLabel>
                </StatCard> */}
            <SectionTitle>Manage Details</SectionTitle>

            <StatContainer>
                <StatCard color={randomColor} onClick={() => openModal('New Invoice')}><StatLabel> Create New Invoice</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Customer')}><StatLabel> Add New Customer</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Medicine')}><StatLabel> Add New Medicine</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Supplier')}><StatLabel> Add New Supplier</StatLabel> </StatCard>
            </StatContainer>
            <div style={{ width: '100%', backgroundColor: 'red', border: '3px', maxHeight: '3px', height: '3px', margin: '10px' }} > </div>


            <SectionTitle>Requests</SectionTitle>

            <StatContainer>
                <StatCard color={randomColor} onClick={() => openModal('New Invoice')}><StatLabel>Refilling Requests</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Customer')}><StatLabel> Processed</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Medicine')}><StatLabel> In Review</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Supplier')}><StatLabel> Rejected</StatLabel> </StatCard>
            </StatContainer>
            <div style={{ width: '100%', backgroundColor: 'red', border: '3px', maxHeight: '3px', height: '3px', margin: '10px' }} > </div>

            <SectionTitle>Drug Scheduling </SectionTitle>

            <StatContainer>
                {drugSchedule.map(x => {
                    return <StatCard color={randomColor} onClick={() => {
                        schedulingId = x.id
                        openModal('Drug Scheduling')
                    }}><StatLabel><span>Name : </span>{x.name}</StatLabel><StatLabel><span>Total Drugs : </span> {x.drugsToTake}</StatLabel> </StatCard>
                })}
                {/* <StatCard color={randomColor} onClick={() => openModal('New Invoice')}><StatLabel>Refilling Requests</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Customer')}><StatLabel> Processed</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Medicine')}><StatLabel> In Review</StatLabel> </StatCard>
                <StatCard color={randomColor} onClick={() => openModal('Add New Supplier')}><StatLabel> Rejected</StatLabel> </StatCard> */}
            </StatContainer>
            <div style={{ width: '100%', backgroundColor: 'red', border: '3px', maxHeight: '3px', height: '3px', margin: '10px' }} > </div>

        </DashboardContainer>
    );
};

export default PharmacyHome;