// jshint esversion:6

import React, { Component } from "react";
import "./style.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(no, name, frequency, time, duration, quantity) {
  return { no, name, frequency, time, duration, quantity };
}

const rows = [
  createData(1, "54gddf", "MN - NT", "After - After", 20, 4),
  createData(2, "rgter", "MN - NT", "After - Before", 37, 5),
  createData(3, "dsfs", "MN - NT", "Before - Before", 24, 2),
  createData(4, "rtdrte", "MN - NT", "After - After", 67, 7),
  createData(5, "Odexa-m", "NT", "After", 49, 9),
];

export default function Scanned() {
  const classes = useStyles();
  return (
    <>
      <ul className="newul3">
        <li className="newli doc-info2">
          <h4 className="doctor-name-presc">Dr. Sudhir Shah</h4>
          <p className="doctor-number-presc">Tel: 9865885698</p>
          {/* <p className="doctor-number-presc">Email: kushall8602@gmail.com</p> */}
        </li>
        <li className="newli2">
          <h4 className="doctor-detail-presc">Reg Number</h4>
          <p className="doctor-detail-value">667667677</p>
        </li>
        <li className="newli2">
          <h4 className="doctor-detail-presc">Experience</h4>
          <p className="doctor-detail-value">6 Years</p>
        </li>
        <li className="newli2">
          <h4 className="doctor-detail-presc">Qualification</h4>
          <p className="doctor-detail-value">MBBS</p>
        </li>
      </ul>
      <div className="second-component">
        <ul className="newul">
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Patient Name</h4>
              <p className="doctor-detail-value">Tashi Singhania</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Age</h4>
              <p className="doctor-detail-value">20</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Gender</h4>
              <p className="doctor-detail-value">Female</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Height</h4>
              <p className="doctor-detail-value">5.7</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Weight</h4>
              <p className="doctor-detail-value">75Kg</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Day</h4>
              <p className="doctor-detail-value">Sep 20, 2020</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Time</h4>
              <p className="doctor-detail-value">11:30 am</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Type</h4>
              <p className="doctor-detail-value">Clinic</p>
            </div>
          </li>
        </ul>

        <div className="detailed-section">
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <div className="accordian-section">
                  <div className={classes.root}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          CHIEF COMPLAINTS
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Ut enim
                          ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore eu fugiat nulla pariatur. Excepteur
                          sint occaecat cupidatat non proident, sunt in culpa
                          qui officia deserunt mollit anim id est laborum.Lorem
                          ipsum dolor sit amet, consectetur adipiscing elit, sed
                          do eiusmod tempor incididunt ut labore et dolore magna
                          aliqua. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          RELEVANT POINTS FROM HISTORY
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Ut enim
                          ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore eu fugiat nulla pariatur. Excepteur
                          sint occaecat cupidatat non proident, sunt in culpa
                          qui officia deserunt mollit anim id est laborum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          EXAMINATION/LAB FINDINGS
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Ut enim
                          ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore eu fugiat nulla pariatur. Excepteur
                          sint occaecat cupidatat non proident, sunt in culpa
                          qui officia deserunt mollit anim id est laborum.Lorem
                          ipsum dolor sit amet, consectetur adipiscing elit, sed
                          do eiusmod tempor incididunt ut labore et dolore magna
                          aliqua. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          SUGGESTED INVESTIGATIONS
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Ut enim
                          ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat. Duis
                          aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore eu fugiat nulla pariatur. Excepteur
                          sint occaecat cupidatat non proident, sunt in culpa
                          qui officia deserunt mollit anim id est laborum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div className="diagnosis-section">
                  <div className="card diagnosed-card">
                    <p className="diagnosis-title">
                      DIAGNOSIS OR PROVISIONAL DIAGNOSIS
                    </p>
                    <hr className="diagnosis-hr" />
                    <p className="diagnosis-info">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Ut enim ad minim veniam,
                      quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu
                      fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                      non proident, sunt in culpa qui officia deserunt mollit
                      anim id est laborum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col">
              <div className="medicine-table">
                <h4>Medicines:</h4>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="centre">No</StyledTableCell>
                        <StyledTableCell align="centre">
                          Medicine
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Frequency
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Intake Time
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Duration
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Quantity
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell component="th" scope="row">
                            {row.no}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="centre">
                            {row.frequency}
                          </StyledTableCell>
                          <StyledTableCell align="centre">
                            {row.time}
                          </StyledTableCell>
                          <StyledTableCell align="centre">
                            {row.duration}
                          </StyledTableCell>
                          <StyledTableCell align="centre">
                            {row.quantity}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
