import CancelIcon from "@material-ui/icons/Cancel";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseSelect from "react-select";
import {
  default as BaseSelect2,
  default as CreatableSelect
} from "react-select/creatable";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import { useStateValue } from "../../StateProvider";
import Loader from "../Loader/Loader";
import "../Patient/style.css";
import "./doctorStyles.css";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const Select2 = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect2}
    options={props.options}
  />
);

// const specialitiesMenu = [
//   { value: "Cardiologist", label: "Cardiologist" },
//   { value: "Dentist", label: "Dentist" },
//   { value: "Gynaecologist", label: "Gynaecologist" },
//   { value: "Paediatrician", label: "Paediatrician" },
//   { value: "Psychiatrists", label: "Psychiatrists" },
// ];

const DoctorRegister = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hospitalCodeValid, setHospitalCodeValid] = useState(false);
  const [allHospitals, setAllHospitals] = useState([]);
  const [isNewHospital, setIsNewHospital] = useState(false);
  const [form_1_IsValid, setForm_1_IsValid] = useState(false);
  const [specialitiesMenu, setSpecialitiesMenu] = useState([]);
  const [agree, setagree] = useState(false);
  const [form_1_data, setForm_1_data] = useState({
    hospital_code: {
      value: "",
      error: "",
      valid: false,
      readOnly: false,
      validation: {
        minLength: 3,
        required: true,
      },
    },
    hospitalName: {
      value: "",
      error: "",
      valid: false,
      readOnly: false,
      validation: {
        minLength: 3,
        required: false,
      },
    },
    hospital_phone_number: {
      value: "",
      error: "",
      valid: false,
      readOnly: false,
      validation: {
        minLength: 8,
        maxLength: 15,
        isMobileNum: true,
        isNumeric: true,
        required: false,
      },
    },
    hospital_address: {
      value: "",
      error: "",
      valid: false,
      readOnly: false,
      validation: {
        minLength: 3,
        required: false,
      },
    },
  });
  const [form_2_IsValid, setForm_2_IsValid] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [longi, setLong] = useState("");
  const [lati, setLat] = useState("");
  const [countryCode, setCountry_code] = useState("");
  const [newSpeciality, setNewSpeciality] = useState("");
  const [newSpecialityDetails, setnewSpecialityDetails] = useState(null);
  const [{ lat, long, country_code }, dispatch] = useStateValue();
  const [qualifications, setQualifications] = useState([]);
  const [specialitiesObj, setSpecialitiesObj] = useState(null);
  const [qualificationsObj, setQualificationsObj] = useState([]);
  const [certificateLoaded, setCertificateLoaded] = useState(false);
  const [hospitalRegCerLoaded, setHospitalRegCerLoaded] = useState(false);
  const [certificateFile, setCertificateFile] = useState();
  const [hospitalRegCerFile, setHospitalRegCerFile] = useState();
  const [selected, setSelected] = useState(false);
  const [postData, setPostData] = useState();
  const [sendRequest, setSendRequest] = useState(false);
  const [addDoctor, setAddDoctor] = useState(false);
  const [addSpecialityIsValid, setAddSpecialityIsValid] = useState(false);
  const [added, setAdded] = useState(false);
  const [addSpecialities, setAddSpecialities] = useState(false);
  const [certificateLable, setCertificateLable] = useState(
    "Choose Professional Certificate"
  );
  const [hospitalRegCerLable, setHospitalRegCerLable] = useState(
    "Choose Business Registration certificate"
  );
  const [form_2_data, setForm_2_data] = useState({
    firstName: {
      value: "",
      error: "",
      validation: {
        required: true,
        minLength: 3,
        onlyAlphabets: true,
      },
      valid: false,
    },
    lastName: {
      value: "",
      error: "",
      validation: {
        required: true,
        minLength: 3,
        onlyAlphabets: true,
      },
      valid: false,
    },
    gender: {
      value: "",
      valid: true,
      error: "",
      validation: {
        required: true,
      },
    },
    regnumber: {
      value: "",
      error: "",
      validation: {
        required: true,
      },
      valid: false,
    },
    regexpirydate: {
      value: "",
      error: "",
      validation: {
        required: true,
      },
      valid: false,
    },
    experience: {
      value: "",
      error: "",
      validation: {
        minLength: 1,
        maxLength: 2,
        required: true,
        isNumeric: true,
      },
      valid: false,
    },
    email: {
      value: "",
      error: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
    },
    mobile: {
      value: "",
      error: "",
      validation: {
        minLength: 8,
        maxLength: 15,
        required: true,
        isNumeric: true,
      },
      valid: false,
    },
  });
  const [qualificationInput, setqualificationInput] = useState({
    value: "",
    isValid: true,
    error: "",
  });
  const [todayDate, settodayDate] = useState("");

  const handleQualificationKeyDown = (event) => {
    if (!qualificationInput.isValid) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setqualificationInput((old) => ({
          value: "",
          isValid: true,
          error: "",
        }));
        setQualificationsObj((old) =>
          !!old
            ? [
              ...old,
              {
                label: qualificationInput.value,
                value: qualificationInput.value,
              },
            ]
            : [
              {
                label: qualificationInput.value,
                value: qualificationInput.value,
              },
            ]
        );

        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    settodayDate(yyyy + "-" + mm + "-" + dd);
    let API = new Apilib();
    setLoading(true);
    API.getSpecialities()
      .then((result) => {
        let res = result.data

        let data = res.data;
        let modified = data.map((item) => {
          return {
            value: item.speciality,
            label: item.speciality,
            id: item.id,
          };
        });
        modified.push({ value: 0, label: "Other", id: null });
        setLoading(false);
        setSpecialitiesMenu(modified);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const onQualificationSelect = (value) => {
    setQualificationsObj(value);
  };

  // const onSpecialitySelect = (value) => {
  //   let arr = [];
  //   if (value && value.length) {
  //     value.map((val, index) => {
  //       arr.push(val.value);
  //     });
  //   }
  //   setSpecialities(arr);
  //   setSpecialitiesObj(value);
  // };

  const onSpecialitySelect = (value) => {
    console.log("onSpecialitySelect", value);
    let arr = [];
    if (value && value.length !== 0) {
      value.map((val, index) => {
        if (val.value === 0) {
          setAddSpecialities(true);
        }
        arr.push(val.value);
      });
      setSpecialities(arr);
      setSpecialitiesObj(value);
    } else {
      setAddSpecialities(false);
      setAdded(false);
      setSpecialities([]);
      setSpecialitiesObj(null);
      setNewSpeciality("");
    }
  };

  const addSpecialityHandler = (e) => {
    e.preventDefault();
    let data = {
      speciality: newSpeciality,
      toBeApproved: true,
      doctor_email: form_2_data.email.value,
    };
    setLoading(true);
    let API = new Apilib();
    API.addNewSpeciality(JSON.stringify(data))
      .then((result) => {
        let res = result.data

        console.log("addNewSpeciality_res", res);
        setnewSpecialityDetails(res.speciality);
        setAdded(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log("addNewSpeciality_err", err);
        setAdded(false);
        setLoading(false);
      });
    console.log("addSpecialityHandler", data);
  };

  useEffect(() => {
    setAddSpecialityIsValid(!!newSpeciality && form_2_data.email.valid);
  }, [newSpeciality, form_2_data.email]);

  useEffect(() => {
    setLat(lat);
    setLong(long);
    setCountry_code(country_code);
  }, [lat, long, country_code]);

  useEffect(() => {
    setLoading(true);
    if (lati && longi && countryCode) {
      setLoading(false);
    }
  }, [lati, longi, countryCode]);

  //UseEffect to add doctor
  useEffect(() => {
    if (addDoctor) {
      let API = new Apilib();
      const data = { ...postData };
      console.log(data);
      API.selfRegistrationDoctor(JSON.stringify(data))
        .then((result) => {
          let res = result.data

          console.log("response self", result, res);
          setLoading(false);
          setSendRequest(false);
          setAddDoctor(false);
          if (res.code === "auth/email-already-exists") {
            let err = {
              message:
                "The email address is already in use by another account.",
            };
            throw err;
          } else {
            props.history.push("/doctor/welcome");
            Notify({
              alert: true,
              type: "success",
              title:
                "Thank you for providing details. Our team will email you once we have verified your account.",
            });
          }
        })
        .catch((err) => {
          console.log("errrrrr", err, err.message);
          setAddDoctor(false);
          setLoading(false);
          setSendRequest(false);
          Notify({
            alert: true,
            type: "error",
            title: err.message,
          });
        });
    }
  }, [addDoctor]);

  const submitHandler = (e) => {
    e.preventDefault();
    //

    if (form_1_IsValid && form_2_IsValid) {
      const data = {};
      for (let key in form_1_data) {
        data[key] = form_1_data[key].value;
      }
      for (let key in form_2_data) {
        data[key] = form_2_data[key].value;
      }
      data["type"] = 3;
      data["specialities"] = specialities.map((spec) => {
        if (spec === 0) {
          return newSpecialityDetails.speciality;
        }
        return spec;
      });
      data["qualifications"] = qualificationsObj.map(
        (qualification) => qualification.label
      );
      data["lat"] = lati;
      data["long"] = longi;
      data["country_code"] = countryCode;
      data["new_hospital"] = isNewHospital;
      data["status"] = { label: "Active", value: "active" }
      console.log(data);
      setPostData(data);
      setSendRequest(true);
    } else {
      Notify({
        alert: true,
        type: "error",
        title: "Please fill missing fields in the form",
      });
      setSendRequest(false);
      return;
    }
  };

  //Validates Business Code
  const validateHospitalCode = () => {
    const updatedForm_1 = { ...form_1_data };
    let hospital_code = form_1_data.hospital_code.value;
    let matchedHospital = allHospitals.filter(
      (hospital, i) => hospital_code == hospital.hospital_code
    );
    //Match hospital
    console.log("MATCH HOSPITAL>>>>", matchedHospital)
    if (hospital_code && !isNewHospital) {
      if (matchedHospital.length) {
        updatedForm_1.hospital_code.readOnly = true;
        // updatedForm_1.hospitalName.value = matchedHospital[0].name;
        setForm_1_data(updatedForm_1);
        setHospitalCodeValid(true);
      } else {
        Notify({
          alert: true,
          type: "error",
          title: "Invalid Business Code",
        });
      }
    } else if (form_1_IsValid) {
      setHospitalCodeValid(true);
    } else {
      Notify({
        alert: true,
        type: "error",
        title: "Please fill missing form fields",
      });
    }
  };
  const [formType, setFormType] = useState();
  const inputChangeHandler = (event, inputName, form) => {
    if (form == 1) {
      const updatedForm_1 = { ...form_1_data };
      const updatedFormElement_1 = { ...updatedForm_1[inputName] };
      updatedFormElement_1.value = event.target.value;
      updatedForm_1[inputName] = updatedFormElement_1;
      updatedForm_1[inputName] = {
        ...updatedForm_1[inputName],
        ...checkValidity(
          updatedForm_1[inputName].value,
          updatedForm_1[inputName].validation
        ),
      };
      setForm_1_data(updatedForm_1);
      setError("");
    } else {
      const updatedForm_2 = { ...form_2_data };
      const updatedFormElement_2 = { ...updatedForm_2[inputName] };
      updatedFormElement_2.value = event.target.value;
      updatedForm_2[inputName] = updatedFormElement_2;
      updatedForm_2[inputName] = {
        ...updatedForm_2[inputName],
        ...checkValidity(
          updatedForm_2[inputName].value,
          updatedForm_2[inputName].validation
        ),
      };
      setForm_2_data(updatedForm_2);
      setError("");
    }
  };

  function checkValidity(value, rules) {
    let valid = true;
    if (rules.required) {
      valid = value.trim() !== "" && valid;
      if (!valid)
        return {
          error: "Field is required",
          valid,
        };
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      valid = pattern.test(value) && valid;
      if (!valid)
        return {
          error: "Value should be numeric",
          valid,
        };
    }

    if (rules.isEmail) {
      const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      valid = pattern.test(value) && valid;
      if (!valid)
        return {
          error: "Invalid email",
          valid,
        };
    }

    if (rules.onlyAlphabets) {
      const pattern = /^[A-Za-z/' ]+$/;
      valid = pattern.test(value) && valid;
      if (!valid)
        return {
          error: "Values should be alphabets",
          valid,
        };
    }

    if (rules.isMobileNum) {
      valid = value.length >= 8 && valid;
      if (!valid)
        return {
          error: `Invalid Mobile No.`,
          valid,
        };
    }

    if (rules.minLength) {
      valid = value.length >= rules.minLength && valid;
      if (!valid)
        return {
          error: `Invalid Mobile No`,
          valid,
        };
    }

    if (rules.maxLength) {
      valid = value.length <= rules.maxLength && valid;
      if (!valid)
        return {
          error: `Invalid Mobile No`,
          valid,
        };
    }

    return { valid, error: "" };
  }

  //Adds Validation for new hospital
  function setNewHospitalValidation() {
    const updatedForm_1 = { ...form_1_data };
    if (!isNewHospital) {
      updatedForm_1.hospitalName.validation.required = true;
      updatedForm_1.hospital_phone_number.validation.required = true;
      updatedForm_1.hospital_address.validation.required = true;
      updatedForm_1.hospital_code.validation.required = false;
      updatedForm_1.hospital_code.readOnly = true;
      updatedForm_1.hospital_code.error = "";
      updatedForm_1.hospital_code.valid = true;
      updatedForm_1.hospital_code.value = "";
    } else {
      updatedForm_1.hospitalName.validation.required = false;
      updatedForm_1.hospitalName.value = "";
      updatedForm_1.hospital_phone_number.validation.required = false;
      updatedForm_1.hospital_phone_number.value = "";
      updatedForm_1.hospital_address.validation.required = false;
      updatedForm_1.hospital_address.value = "";
      updatedForm_1.hospital_code.validation.required = true;
      updatedForm_1.hospital_code.readOnly = false;
    }
    setForm_1_data(updatedForm_1);
    setIsNewHospital(!isNewHospital);
  }

  //Reset Certificate File
  const resetCertificateFile = () => {
    setCertificateLoaded(false);
    setCertificateLable("Choose Professional Certificate");
  };

  const resetHospitalRegCerFile = (e) => {
    e.preventDefault();
    setHospitalRegCerLoaded(false);
    setHospitalRegCerLable("Choose Business Registration certificate");
  };

  //Handles Certificate Upload
  const certificateHandler = (e) => {
    e.preventDefault();
    setSelected(true);
    const file = document.getElementById("doctorRegCer").files[0];
    if (file && file.length !== 0) {
      setCertificateLable(file.name);
    }
  };
  const certificateUploadHandler = (e) => {
    e.preventDefault();
    const file = document.getElementById("doctorRegCer").files[0];
    setCertificateFile(file);
    if (file && file.length !== 0) {
      const metadata = {
        contentType: file.type,
      };
      if (metadata.contentType === "application/pdf") {
        setCertificateLoaded(true);
      } else {
        setCertificateLoaded(false);
        Notify({
          alert: true,
          type: "error",
          title: "Only PDF Files allowed!",
        });
      }
    } else {
      Notify({
        alert: true,
        type: "error",
        title: "Please select Professionals Certificate File!",
      });
    }
  };

  //Handles Registration Upload
  const hospitalRegCerHandler = (e) => {
    e.preventDefault();
    setSelected(true);
    const file = document.getElementById("hospitalRegCer").files[0];
    if (file && file.length !== 0) {
      setHospitalRegCerLable(file.name);
    }
  };
  const hospitalRegCerUploadHandler = (e) => {
    e.preventDefault();
    const file = document.getElementById("hospitalRegCer").files[0];
    setHospitalRegCerFile(file);
    if (file && file.length !== 0) {
      const metadata = {
        contentType: file.type,
      };
      if (metadata.contentType === "application/pdf") {
        setHospitalRegCerLoaded(true);
      } else {
        setHospitalRegCerLoaded(false);
        Notify({
          alert: true,
          type: "error",
          title: "Only PDF Files allowed!",
        });
      }
    } else {
      Notify({
        alert: true,
        type: "error",
        title: "Please select Business Registration Certificate File!",
      });
    }
  };

  //UseEffect for Step 1 Form
  useEffect(() => {
    let formValid = true;
    for (let key in form_1_data) {
      // console.log(
      //   key,
      //   form_1_data[key].validation.required,
      //   form_1_data[key].valid,
      //   form_1_data[key].value
      // );
      if (
        form_1_data[key].validation.required == true &&
        form_1_data[key].value == ""
      ) {
        formValid = false;
      }
      if (form_1_data[key].value !== "" && form_1_data[key].valid == false) {
        formValid = false;
      }
    }
    let valid = formValid;
    console.log(valid);
    setForm_1_IsValid(valid);
  }, [form_1_data, isNewHospital]);

  //UseEffect for Step 2 Form
  useEffect(() => {
    let formValid = true;
    for (let key in form_2_data) {
      console.log(
        key,
        form_2_data[key].validation.required,
        form_2_data[key].valid,
        form_2_data[key].value
      );
      if (
        form_2_data[key].validation.required == true &&
        form_2_data[key].value == ""
      ) {
        formValid = false;
      }
      if (form_2_data[key].value !== "" && form_2_data[key].valid == false) {
        formValid = false;
      }
    }
    let valid = formValid;
    console.log(valid);
    setForm_2_IsValid(valid);
  }, [form_2_data]);

  //UseEffect for sendRequest, fileUpload
  useEffect(() => {
    if (sendRequest) {
      setLoading(true);
      setSendRequest(false);
      let data = { ...postData };
      const promises = [];
      let hospitalRegCerPath = null;
      let certificatePath = null;
      const ref = firebase.storage().ref();

      if (certificateFile) {
        certificatePath =
          "self_registration/doctors/doc_certificates/" +
          data.email +
          "/doc_certificate";
        const certificateMetadata = {
          contentType: certificateFile.type,
        };
        const task1 = ref
          .child(certificatePath)
          .put(certificateFile, certificateMetadata);
        promises.push(task1);
      }

      if (hospitalRegCerFile) {
        hospitalRegCerPath =
          "self_registration/doctors/hospital_reg_cer/" +
          data.email +
          "/hospital_reg_cer";
        const hospitalRegCerMetadata = {
          contentType: hospitalRegCerFile.type,
        };
        const task2 = ref
          .child(hospitalRegCerPath)
          .put(hospitalRegCerFile, hospitalRegCerMetadata);
        promises.push(task2);
      }

      if (promises.length !== 0) {
        Promise.all(promises)
          .then(() => {
            data["doctor_certificate_path"] = certificatePath;
            data["hospital_reg_cer_path"] = hospitalRegCerPath;
            setPostData(data);
            setAddDoctor(true);
            setSendRequest(false);
          })
          .catch((err) => {
            setAddDoctor(false);
            console.log("errrrrr 2", err, err.message);
            setSendRequest(false);
            setForm_2_IsValid(false);
            Notify({
              alert: true,
              type: "error",
              title: err.message,
            });
          });
      } else {
        data["doctor_certificate_path"] = certificatePath;
        data["hospital_reg_cer_path"] = hospitalRegCerPath;
        setPostData(data);
        setAddDoctor(true);
        setSendRequest(false);
      }
    }
  }, [sendRequest]);

  //Get All Hospitals
  useEffect(() => {
    let API = new Apilib();
    API.getAllHospitals()
      .then((result) => {
        let res = result.data

        console.log("Response hospitals", res);
        setLoading(false);
        setAllHospitals(res.hospitals);
      })
      .catch((err) => {
        console.log("errrrrr", err, err.message);
        setLoading(false);
        Notify({
          alert: true,
          type: "error",
          title: err.message,
        });
      });
  }, []);

  //Render Patient Form
  function renderPatientForm() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              First Name <span className="text-danger">*</span>
            </label>
            <input
              name="firstName"
              value={form_2_data.firstName.value}
              onChange={(e) => inputChangeHandler(e, "firstName", 2)}
              required
              className={
                !!form_2_data.firstName.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="text"
            />
            <small className="form-text text-danger">
              {form_2_data.firstName.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Last Name <span className="text-danger">*</span>
            </label>
            <input
              name="lastName"
              value={form_2_data.lastName.value}
              onChange={(e) => inputChangeHandler(e, "lastName", 2)}
              required
              className={
                !!form_2_data.lastName.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="text"
            />
            <small className="form-text text-danger">
              {form_2_data.lastName.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Mobile No.<span className="text-danger">*</span>
            </label>
            <input
              name="mobile"
              value={form_2_data.mobile.value}
              onChange={(e) => inputChangeHandler(e, "mobile", 2)}
              required
              className={
                !!form_2_data.mobile.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="number"
            />
            <small className="form-text text-danger">
              {form_2_data.mobile.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Email <span className="text-danger">*</span>
            </label>
            <input
              name="email"
              value={form_2_data.email.value}
              onChange={(e) => inputChangeHandler(e, "email", 2)}
              required
              className={
                !!form_2_data.email.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="email"
            />
            <small className="form-text text-danger">
              {form_2_data.email.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Specialty <span className="text-danger">*</span>
            </label>
            <Select
              required
              isMulti
              className={"select"}
              onChange={(val) => onSpecialitySelect(val)}
              value={specialitiesObj}
              options={specialitiesMenu}
            />

            {addSpecialities ? (
              <div className="row mt-3">
                <div className="col-sm-8">
                  {!added ? (
                    <div className="form-group">
                      <label>
                        Add speciality <span className="text-danger">*</span>
                      </label>
                      <div style={{ display: "flex" }}>
                        <input
                          name="regnumber"
                          value={newSpeciality}
                          onChange={(e) => {
                            setNewSpeciality(e.target.value);
                          }}
                          required
                          className="form-control mr-4"
                          type="text"
                        />
                        {addSpecialityIsValid ? (
                          <button
                            onClick={(e) => addSpecialityHandler(e)}
                            type="button"
                            className="btn btn-primary"
                          >
                            Add
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <p>New Specialty: {newSpeciality}</p>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Qualifications </label>
            <CreatableSelect
              components={{
                DropdownIndicator: null,
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  // state.isFocused can display different borderColor if you need it
                  borderColor: state.isFocused
                    ? qualificationInput.isValid
                      ? "#ddd"
                      : "red"
                    : qualificationInput.isValid
                      ? "#ddd"
                      : "red",
                  // overwrittes hover style
                  boxShadow: state.isFocused
                    ? qualificationInput.isValid
                      ? base.boxShadow
                      : "red"
                    : qualificationInput.isValid,
                  "&:hover": {
                    borderColor: state.isFocused
                      ? qualificationInput.isValid
                        ? "#ddd"
                        : "red"
                      : qualificationInput.isValid
                        ? "#ddd"
                        : "red",
                  },
                }),
              }}
              inputValue={qualificationInput.value}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(val) => onQualificationSelect(val)}
              onInputChange={(value) => {
                if (!!value === false) {
                  setqualificationInput({
                    value,
                    isValid: true,
                    error: "",
                  });
                  return;
                }
                const validationRes = checkValidity(value, {
                  minLength: 3,
                  onlyAlphabets: true,
                });
                setqualificationInput({
                  value,
                  isValid: validationRes.valid,
                  error: validationRes.error,
                });
              }}
              onKeyDown={handleQualificationKeyDown}
              placeholder="Enter Qualifications"
              value={qualificationsObj}
            />
            <small className="form-text form-muted text-muted">
              (Press Enter/Tab after entering qualification)
            </small>
            <small className="form-text text-danger">
              {qualificationInput.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Registration Number <span className="text-danger">*</span>
            </label>
            <input
              name="regnumber"
              value={form_2_data.regnumber.value}
              onChange={(e) => inputChangeHandler(e, "regnumber", 2)}
              required
              className={
                !!form_2_data.regnumber.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="text"
            />
            <small className="form-text text-danger">
              {form_2_data.regnumber.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Registration Expiry Date <span className="text-danger">*</span>
            </label>
            <input
              name="regexpirydate"
              value={form_2_data.regexpirydate.value}
              onChange={(e) => inputChangeHandler(e, "regexpirydate", 2)}
              min={todayDate}
              required
              className={
                !!form_2_data.regexpirydate.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="date"
            />
            <small className="form-text text-danger">
              {form_2_data.regexpirydate.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label>
              Years of Experience <span className="text-danger">*</span>
            </label>
            <input
              min="0"
              name="experience"
              value={form_2_data.experience.value}
              onChange={(e) => inputChangeHandler(e, "experience", 2)}
              required
              className={
                !!form_2_data.experience.error === false
                  ? "form-control"
                  : "form-control error"
              }
              type="text"
            />
            <small className="form-text text-danger">
              {form_2_data.experience.error}
            </small>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group gender-select">
            <label className="gen-label">
              Gender <span className="text-danger">*</span>
            </label>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  required
                  name="gender"
                  type="radio"
                  onChange={(e) => inputChangeHandler(e, "gender", 2)}
                  checked={form_2_data.gender.value == "male"}
                  value="male"
                  className="form-check-input"
                />
                Male
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  required
                  type="radio"
                  onChange={(e) => inputChangeHandler(e, "gender", 2)}
                  name="gender"
                  checked={form_2_data.gender.value == "female"}
                  value="female"
                  className="form-check-input"
                />
                Female
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  required
                  type="radio"
                  onChange={(e) => inputChangeHandler(e, "gender", 2)}
                  name="gender"
                  checked={form_2_data.gender.value == "other"}
                  value="other"
                  className="form-check-input"
                />
                Other
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  required
                  type="radio"
                  onChange={(e) => inputChangeHandler(e, "gender", 2)}
                  name="gender"
                  checked={
                    form_2_data.gender.value ==
                    "I wish not to disclose "
                  }
                  value="I wish not to disclose "
                  className="form-check-input"
                />
                I wish not to Disclose
              </label>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="row mb-3">
            <div className="col-sm-6">
              {certificateLoaded ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: "500",
                      textAlign: "initial",
                    }}
                  >
                    <PictureAsPdfIcon
                      className="mr-2"
                      style={{
                        color: "red",
                        fontSize: "2.5rem",
                      }}
                    />
                    {certificateLable}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <a className="btn" onClick={resetCertificateFile}>
                      <CancelIcon style={{ fontSize: "1.5rem" }} />
                    </a>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <label>Choose Professional Certificate</label>

                  <div className="upload-input">
                    {/* <span class="iconify" data-icon="bx:bx-image" data-inline="false"></span> */}
                    <input
                      type="file"
                      id="doctorRegCer"
                      multiple={true}
                      onChange={certificateHandler}
                      accept="application/pdf"
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-6">
              {hospitalRegCerLoaded ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: "500",
                      textAlign: "initial",
                    }}
                  >
                    <PictureAsPdfIcon
                      className="mr-2"
                      style={{
                        color: "red",
                        fontSize: "2.5rem",
                      }}
                    />
                    {hospitalRegCerLable}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <a className="btn" onClick={resetHospitalRegCerFile}>
                      <CancelIcon style={{ fontSize: "1.5rem" }} />
                    </a>
                  </div>
                </div>
              ) : (

                <div className="form-group">
                  <label>Choose Business Registration certificate</label>

                  <div className="upload-input">
                    {/* <span class="iconify" data-icon="bx:bx-image" data-inline="false"></span> */}
                    <input
                      type="file"
                      id="hospitalRegCer"
                      multiple={true}
                      onChange={hospitalRegCerHandler}
                      accept="application/pdf"
                      className="form-control"
                    />
                  </div>
                </div>
                // <div className="custom-dropzone">

                //   <div className="inputFile">
                //     <span>{hospitalRegCerLable}</span>
                //     <input
                //       type="file"
                //       accept="application/pdf"
                //       id="hospitalRegCer"
                //       onChange={hospitalRegCerHandler}
                //     />
                //   </div>
                // </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="main-content account-content">
        <div className="content">
          <div className="container">
            <div className="account-box doctor-box">
              {loading && <Loader />}
              <form
                onSubmit={(e) => submitHandler(e)}
                method="post"
                className="form-signin"
                action="#"
              >
                <div className="account-logo">
                  <a>
                    <img src="assets/img/logo-dark.png" alt="Medvatic" />
                  </a>
                </div>
                <div className="account-title">
                  <h3>Register as Professional</h3>
                </div>
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {error}
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Business Code{" "}
                        {form_1_data.hospital_code.validation.required && (
                          <span className="text-danger">*</span>
                        )}
                      </label>
                      <input
                        name="hospital_code"
                        value={form_1_data.hospital_code.value}
                        readOnly={form_1_data.hospital_code.readOnly}
                        onChange={(e) =>
                          inputChangeHandler(e, "hospital_code", 1)
                        }
                        required
                        className={
                          !!form_1_data.hospital_code.error === false
                            ? "form-control"
                            : "form-control error"
                        }
                        type="text"
                      />
                      <small className="form-text text-danger">
                        {form_1_data.hospital_code.error}
                      </small>
                    </div>
                    {!hospitalCodeValid && (
                      <div className="form-check">
                        <input
                          onChange={() => setNewHospitalValidation()}
                          checked={isNewHospital}
                          type="checkbox"
                          className="form-check-input"
                          id="newHospital"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="newHospital"
                        >
                          Do not have code? Add New Business
                        </label>
                      </div>
                    )}
                  </div>
                  {isNewHospital ? (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Business Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="hospitalName"
                          value={form_1_data.hospitalName.value}
                          readOnly={form_1_data.hospitalName.readOnly}
                          onChange={(e) =>
                            inputChangeHandler(e, "hospitalName", 1)
                          }
                          className={
                            !!form_1_data.hospitalName.error === false
                              ? "form-control"
                              : "form-control error"
                          }
                          type="text"
                        />
                        <small className="form-text text-danger">
                          {form_1_data.hospitalName.error}
                        </small>
                      </div>
                    </div>
                  ) : null}
                </div>
                {isNewHospital ? (
                  <div className="row mt-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Business Address{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          name="hospital_address"
                          value={form_1_data.hospital_address.value}
                          readOnly={form_1_data.hospital_address.readOnly}
                          onChange={(e) =>
                            inputChangeHandler(e, "hospital_address", 1)
                          }
                          className={
                            !!form_1_data.hospital_address.error === false
                              ? "form-control"
                              : "form-control error"
                          }
                          type="text"
                        />
                        <small className="form-text text-danger">
                          {form_1_data.hospital_address.error}
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Business Phone Number{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          name="hospital_phone_number"
                          value={form_1_data.hospital_phone_number.value}
                          readOnly={form_1_data.hospital_phone_number.readOnly}
                          onChange={(e) =>
                            inputChangeHandler(e, "hospital_phone_number", 1)
                          }
                          className={
                            !!form_1_data.hospital_phone_number.error === false
                              ? "form-control"
                              : "form-control error"
                          }
                          type="number"
                        />
                        <small className="form-text text-danger">
                          {form_1_data.hospital_phone_number.error}
                        </small>
                      </div>
                    </div>
                  </div>
                ) : null}

                {hospitalCodeValid && renderPatientForm()}


                {hospitalCodeValid ? <div className="form-group">

                  <div className="form-check-inline">
                    <label className="form-check-label">
                      <input
                        required
                        type="checkbox"
                        onChange={(e) => {
                          console.log(agree, 'this is is agree')
                          setagree(agree => !agree)
                        }}
                        name="agree"
                        checked={agree}
                        value="agree"
                        className="form-check-input"
                      />
                      I agree to the terms and conditions
                    </label>
                    <span className="required">*</span>
                  </div>

                </div> : null}


                {hospitalCodeValid ? (
                  <div className="form-group text-center mt-50">
                    <button
                      type="submit"
                      disabled={!form_2_IsValid || !agree}
                      className="btn btn-primary submit-btn signup account-btn"
                    >
                      SIGNUP
                    </button>
                  </div>
                ) : (
                  <div className="form-group text-center mt-50">
                    <button
                      type="button"
                      onClick={() => validateHospitalCode()}
                      disabled={!form_1_IsValid}
                      className="btn btn-primary submit-btn signup account-btn"
                    >
                      NEXT
                    </button>
                  </div>
                )}
              </form>
              <div className="text-center register-link">
                Already Registered?
                <Link
                  to="/doctor/welcome"
                  className="login-signup-redirect-link"
                >
                  {" "}
                  Login Here{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DoctorRegister;
