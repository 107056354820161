import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo1.png'
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import Apilib from "../../api";
import { useHistory, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap';

// Dummy data for the invoice
// const invoiceData = {
//     companyIcon: 'company_icon_url',
//     invoiceNumber: 'INV-123',
//     date: '2023-07-18',
//     customer: {
//         name: 'John Doe',
//         address: '123 Main Street, City',
//     },
//     pharmacy: {
//         name: 'Example Pharmacy',
//         address: '456 Pharmacy Avenue, City',
//     },
//     items: [
//         { name: 'Item 1', description: 'Item 1 description', quantity: 2, price: 10, total: 20 },
//         { name: 'Item 2', description: 'Item 2 description', quantity: 1, price: 15, total: 15 },
//     ],
//     subTotal: 35,
//     tax: 5,
//     total: 40,
// };

const h2 = styled.h2`
  align-items: center;
  @media (max-width: 100%) {
  }
`;
const SectionHeading = styled.h3`
  color: #000;
`;
const P = styled.p`
  color: #111;
  margin-bottom:3px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const InvoiceTableWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
`;

const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: 100px;
  }

  /* Apply additional styles for smaller screens */
  @media (max-width: 768px) {
    th,
    td {
      font-size: 14px;
      white-space: nowrap;
    }
  
    tr {
      display: block;
      margin-bottom: 0.625em;
    }
  
    th,
    td::before {
      content: attr(data-label);
      display: inline-block;
      font-weight: bold;
      width: 100px;
    }
  
    td {
      display: block;
      text-align: right;
      position: relative;
      padding-left: 100px;
    }
  
    td::before {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 0;
      padding-left: 4px;
    }
  }
`;


const InvoiceHeader = () => {
  return (
    <div style={{ width: '100%', textAlign: 'end' }}>
      <h2>Orders</h2>

    </div>
  );
};


const PharmacyDetails = (pharmacyData) => {
  const pharmacy = pharmacyData.pharmacyData
  console.log(pharmacyData)
  console.log('pharmacyData')
  return (
    <div style={{ margin: '1px' }}>
      <SectionHeading>Pharmacy Details:</SectionHeading>
      <P>Pharmacy Name: {pharmacy.pharmacyName}</P>
      <P>Pharmacy Address: {pharmacy.location}</P>
    </div>
  );
};


const InvoiceOrderList = (route) => {
  console.log(route, 'this is here route')

  let [invoiceData, setinvoiceData] = useState(null)
  let [pharmacyData, setpharmacyData] = useState(null)
  let [orderData, setorderData] = useState(null)
  let [loading, setloading] = useState(false)
  let [orderView, setorderView] = useState(false)
  let [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    let API = new Apilib();

    let data = { pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1', invoiceId: 'IiSyo0bJi77pnHhREt13', type: '6' }

    console.log(data)

    API.getPharmacyInvoices({ pharmacyId: route.userData.uid })
      .then(async (res) => {
        console.log(res)
        console.log('this is response')
        console.log(res.data.invoices)
        console.log('pharmacy data')

        setinvoiceData(res.data.invoiceList)
        setpharmacyData(res.data.invoices)
      })
      .catch((error) => {
        console.log('error', error)
      });


    API.getAllPharmacyOrder({ pharmacy_id: route.userData.uid })
      .then(async (res) => {
        console.log(res)
        console.log('this is response')
        console.log(res.data)
        setorderData(res.data.data)
        console.log('pharmacy order data')

        // setinvoiceData(res.data.invoiceList)
        // setpharmacyData(res.data.invoices)
      })
      .catch((error) => {
        console.log('error order', error)
      });
    console.log('useeffect executed')
  }, []);
  const history = useHistory();

  let orderss = {
    "Email": "ram@gmail.com",
    "address": "rohili,barmer",
    "medicines": [
      {
        "Batch_Number": "45623",
        "ExpDate": "2023-08-31T12:34:00.000Z",
        "MfgDate": "2023-08-01T12:34:00.000Z",
        "Price": "300",
        "Quantity": "10",
        "Dose": "20",
        "Medicine_Name": "rama",
        "Units": "10"
      }
    ],
    "prescriptionLink": "https://www.researchgate.net/publication/345830022/figure/fig4/AS:957640029003789@1605330583881/Sample-prescription-used-as-input-to-the-GUI-developed-in-the-present-work.png",
    "city": "barmer",
    "orderId": "313131",
    "mobileNumber": "972224616",
    "pharmacyDetails": {
      "pharmacyId": "KAoOnOt37RMTwAxsr6sqi1aOler1",
      "pharmacyName": "1mg",
      "location": "Rohili, barmer, Rajasthan"
    },
    "estimatedDeliveryDate": "2023-08-31T11:32:00.000Z",
    "orderValue": "1008",
    "prescribedBy": "Ram Borawat",
    "paymentProcessed": false,
    "shippingDetails": "",
    "orderedBy": {
      "uid": "7q6GWjtjZ6bjiqjdgmaEN2boYRt1",
      "phone": "9700046173",
      "name": "ram"
    },
    "pharmacy_id": "KAoOnOt37RMTwAxsr6sqi1aOler1",
    "state": "rajasthan",
    "paymentDetails": {
      "amount": "300",
      "paymentId": "12345",
      "paymentLink": "https://commbank.com"
    },
    "orderDate": "2023-08-31T11:32:00.000Z",
    "something": "nothing",
    "delivery": {
      "deliveryAsigneeId": "7914878",
      "deliveryAssignedTo": "ram",
      "deliveryProgress": {
        "orderAcknowldgement": true,
        "orderLastLocation": [
          "barmer",
          "rohili"
        ],
        "orderReadyForDelivery": true,
        "orderReceived": true,
        "deliveryCustomerVerification": true,
        "orderPacked": true,
        "deliveryCompleted": true,
        "orderOutForDelivery": true,
        "orderAssigned": true
      }
    },
    "payment": true
  }

  return (
    <div >
      {loading && <Loader />}
      {invoiceData && <div style={{ backgroundColor: '#f1faff', padding: '5%', borderRadius: '15px' }}>
        <img style={{ width: '150px', height: '50px', position: 'absolute' }} src={logo} alt="Company Logo" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InvoiceHeader />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>

          {pharmacyData && <PharmacyDetails pharmacyData={pharmacyData} />}
        </div>

        <InvoiceTableWrapper>
          <InvoiceTable>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Customer Name</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>Customer Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>

              {orderData?.map((item, index) => (
                <tr
                  style={{ backgroundColor: '#81e2fc', borderRadius: '10px', cursor: 'pointer', overflow: 'hidden', margin: '5px' }}
                  key={index} onClick={() => {
                    console.log('clicked ', item)
                    // history.push({ pathname: `/pharmacy/InvoiceForm`, state: { id: item.invoiceUid } });
                    setorderView(item)
                    setShow(true)

                  }}>
                  <td>{item?.orderId}</td>
                  <td>{item?.orderedBy?.name}</td>
                  <td>{item?.orderValue}</td>
                  <td>{moment(item.orderDate).format("DD-MM-YYYY")}</td>
                  <td>{item?.address + ', ' + item?.state}</td>
                  <td>{item?.delivery?.deliveryProgress?.deliveryCompleted ? 'Completed' : 'Pending'}</td>

                </tr>
              ))}
            </tbody>
          </InvoiceTable>
        </InvoiceTableWrapper>

        {/* <ButtonGroup>
          <Button style={{ fontWeight: '400', fontSize: '16px' }}
            onClick={async () => {
              console.log('this is it')

              setloading(true)
              history.push('/pharmacy/InvoicePage')


              setloading(false)
            }}
          >
          </Button>

        </ButtonGroup> */}
      </div>}





      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{orderView && <div> Order Details</div>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderView && <div>


            <div style={{ fontSize: '18px', padding: '10px' }}>Ordered by: {orderView?.orderedBy?.name}</div>
            <div style={{ fontSize: '18px', padding: '10px' }}>Price: {orderView?.orderValue}</div>
            <div style={{ fontSize: '18px', padding: '10px' }}>Ordered On : {moment(orderView?.orderDate).format("DD-MM-YYYY")}</div>
            <div style={{ fontSize: '18px', padding: '10px' }}>address: {orderView?.address + ', ' + orderView?.state}</div>
            <div style={{ fontSize: '18px', padding: '10px' }}>Delivery: {orderView?.delivery?.deliveryProgress.deliveryCompleted ? 'Completed' : 'Pending'}</div>

            <div style={{ fontSize: '18px', padding: '10px' }}>Delivery Stage: {orderView?.delivery?.deliveryProgress?.deliveryCompleted ? 'Completed' : 'Pending'}
              <div>Order Acknowldgement {orderView?.delivery?.deliveryProgress?.orderAcknowldgement ? 'Completed' : 'Pending'}</div>
              <div>Order ReadyForDelivery {orderView?.delivery?.deliveryProgress?.orderReadyForDelivery ? 'Completed' : 'Pending'}</div>
              <div>Order Received {orderView?.delivery?.deliveryProgress?.orderReceived ? 'Completed' : 'Pending'}</div>
              <div>Order Packed {orderView?.delivery?.deliveryProgress?.orderPacked ? 'Completed' : 'Pending'}</div>
              <div>Order OutForDelivery {orderView?.delivery?.deliveryProgress?.orderOutForDelivery ? 'Completed' : 'Pending'}</div>
              <div>Order Assigned {orderView?.delivery?.deliveryProgress?.orderAssigned ? 'Completed' : 'Pending'}</div>
              {/* Order Acknowldgement
              Order ReadyForDelivery
              Order Received
              Order Packed
              Order OutForDelivery
              Order Assigned
              
              delivery.deliveryProgress.orderAcknowldgement
              delivery.orderReadyForDelivery
              delivery.orderReceived
              delivery.orderPacked
              delivery.orderOutForDelivery
              delivery.orderAssigned
              
               */}
            </div>

          </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default InvoiceOrderList;