import React, { useEffect, useState } from 'react';
import locationicon from '../../assets/img/locationicon.png'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import GoogleMap from "../Agora/utils/GoogleMap";
import Modal from "react-bootstrap/Modal";
import Apilib from "../../api";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import cart from '../../assets/img/cart.png'
import firebase, { auth } from '../../firebase';
import Notify from '../../Notify';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        <div style={{ alignSelf: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <i class="fa fa-arrow-left"></i>
        </div>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        <div style={{ alignSelf: 'center' }} disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <i class="fa fa-arrow-right"></i>
        </div>
    );
}

const styless = {
    inputBoxStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '10px',
        margin: '10px',
        // border-color: #eaeaea;
        fontSize: '14px',
        minHeight: '35px',
        width: '30%',
    },
}
const EditCart = (props) => {
    const [medicineList, setmedicineList] = useState(null)

    const history = useHistory();
    // getAllMedicines
    const location = useLocation();
    useEffect(() => {
        let API = new Apilib();

        API.getMedicines({})
            .then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log('this is response', res.data.medicines.medicines)
                setmedicineList(res.data.medicines.medicines)
                // medicineList
            })
            .catch((error) => {
                console.log('error', error)
            });


    }, []);

    // const cart = location.state.cart
    // console.log(location, 'this is location')
    // console.log(cart, 'this is location hi arer')
    // console.log('this is location hi arer')
    const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData')).length == 0 ? null : JSON.parse(localStorage.getItem('cartData')))
    const [cartTotal, setcartTotal] = useState(null)
    const [AddressLine, setAddressLine] = useState('')
    const [Landmark, setLandmark] = useState('')
    const [Street, setStreet] = useState('')
    const [State, setState] = useState('')
    const [prefModalOpen, setprefModalOpen] = useState(false);
    const [addType, setaddType] = useState(1);
    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    // useEffect(() => {
    //     let cart = localStorage.getItem("cartData")
    //     setcartItems(cart)
    // }, [input]);


    function itemExist(arr, arr1) {
        const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);

        return found;
    }


    const notify = () => toast.success("Item Added into cart!");
    const notifyN = () => toast.error("Item removed from cart!");
    const settingcart = (data) => {
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        data.qty = 1
        notify()

        if (itemnow == -1) pr.push(data)
        else pr[itemnow].qty += 1
        console.log(itemnow, 'itemnow herer')

        setcartItems(pr)

        localStorage.setItem('cartData', JSON.stringify(pr));

    }
    const settingcarts = (data, vari, index) => {
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        if (vari == '-') {
            notifyN()

            if (data.qty == 1) pr.splice(index, 1)
            else data.qty = data.qty - 1

        }
        else {
            notify()
            if (itemnow == -1) pr.push(data)
            else pr[itemnow].qty += 1
        }
        setcartItems(pr)

        localStorage.setItem('cartData', JSON.stringify(pr));


    }

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('cartData'));

        if (items) {
            setcartItems(items);
            console.log(items, 'this i slocal items');
        }
        let result = items.reduce(function (acc, obj) { return acc + Number(obj.Price) * Number(obj.qty); }, 0);
        setcartTotal(Number(result).toFixed(2))

        console.log(Number((cartTotal) + (cartTotal * 0.05)).toFixed(2), 'Number((cartTotal) + (20) + (cartTotal * 0.05)')

    }, []);
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('cartData'));
        let result = items.reduce(function (acc, obj) { return acc + Number(obj.Price) * Number(obj.qty); }, 0);
        setcartTotal(Number(result).toFixed(2))


    }, [cartItems]);


    return (
        <div style={{ marginTop: '0%', margin: '5%', padding: '3%' }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                newestOnTop={false}
                hideProgressBar
                closeOnClick
                rtl={false}
                theme="light"
            />
            <h5>
                Added Products
            </h5>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', }}>
                <div style={{ width: '50%', margin: '2%', justifyContent: 'center', alignItems: 'center' }}>

                    {cartItems && cartItems.map((x, index) => {
                        return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '5px' }}
                            key={Math.random()}
                        >

                            <div style={{ display: 'flex', }}>
                                <img src={x.imageLink} alt="image" style={{ width: '100px', height: '100px', overflow: 'hidden', borderRadius: '10px' }} />
                                <div style={{ alignSelf: 'center', margin: '5px' }}>

                                    <div> {x.Medicine_Name}</div>
                                    {/* <div> {x.qty || '1'}</div> */}
                                    {/* <input */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        <div style={{ width: '30px', height: '30px', borderRadius: '6px', textAlign: 'center', backgroundColor: '#007587', color: '#fff', fontSize: '18px', fontWeight: '600' }} onClick={() => {
                                            settingcarts(x, '-', index)
                                        }}>-</div>
                                        <input
                                            // className="form-control col-sm-5 row"
                                            name="qty"
                                            placeholder={'qty'}
                                            value={x.qty}
                                            onChange={e => {
                                                console.log(index, x, index);
                                                // setname(e.target.value)
                                                settingcart(x)
                                            }
                                            }
                                            style={styless.inputBoxStyle}
                                        />
                                        <div style={{ width: '30px', height: '30px', borderRadius: '6px', textAlign: 'center', backgroundColor: '#007587', color: '#fff', fontSize: '18px', fontWeight: '600' }} onClick={() => {
                                            settingcarts(x, '+')
                                        }}>+</div>

                                    </div>
                                </div>
                            </div>
                            <div> {x.Price}</div>
                        </div>
                    })}
                </div>

                <div style={{ width: '50%' }}>
                    <div style={{ width: '100%', border: 'solid 1px #f7f7f7', overflow: 'hidden' }}>
                        <div style={{ width: '100%', overflowY: 'auto', }}>
                            <h5>
                                Cart Total: {cartTotal}
                            </h5>
                            <h4>

                            </h4>


                        </div>
                    </div>
                    <div style={{ width: '100%', border: 'solid 1px #f7f7f7' }}>
                        <h5>
                            Bill Summary
                        </h5>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Total
                            </h6>
                            {cartTotal && <h6>
                                ${cartTotal}
                            </h6>}
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Delivery Charges
                            </h6>
                            <h6>
                                $20
                            </h6>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Discount
                            </h6>
                            {cartTotal && <h6>
                                ${Number(cartTotal * .05).toFixed(2)}
                            </h6>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Amount to be paid
                            </h6>
                            {cartTotal && <h6>
                                ${Number(Number(cartTotal) - Number(cartTotal * 0.05)).toFixed(2)}
                            </h6>}
                        </div>
                    </div>

                    <div style={{
                        color: '#fff', backgroundColor: '#00869a', width: '100%', borderRadius: '5px', textAlign: 'center', padding: '5px', cursor: 'pointer'
                    }} onClick={() => {
                        console.log('sending mails and processing order')
                        // auth.currentUser
                        // if()
                        console.log(cartItems, cartItems.length, 'this is lenght')
                        if (!cartItems.length) {
                            toast.error('Please add items into cart to continue')
                            return
                        }
                        auth.onAuthStateChanged(function (user) {
                            if (user) {
                                // User is signed in.
                                console.log('authorised user')
                                history.push({
                                    pathname: "/Order",
                                    state: { cart: cartItems },
                                });
                            } else {
                                console.log('authorised user not available')
                                // let provider = new firebase.auth.GoogleAuthProvider();
                                // firebase.auth().signInWithPopup(provider).catch(e => {
                                //     console.log(e)
                                // });

                                // No user is signed in.

                                // history.push({
                                //     pathname: "/Login",
                                // });
                                setprefModalOpen(true)
                                //                             auth.signInWithEmailAndPassword(email, password)
                                //   .then(res => console.log("Signin success", res))
                                //   .catch((error) => {
                                //     console.log("signin error", error)
                                //     let errorMsg = "";

                                //     switch (error.code) {
                                //       case "auth/wrong-password":
                                //         errorMsg = " The Email id or Password you entered is incorrect ";
                                //         break;
                                //       case "auth/user-not-found":
                                //         errorMsg = " The Email id or Password you entered is incorrect ";
                                //         break;
                                //       default:
                                //         errorMsg = error.message;
                                //         break;
                                //     }
                            }
                        })
                        // history.push({
                        //     pathname: "/Order",
                        //     state: { cart: cartItems },
                        // });
                    }}>
                        Continue
                    </div>
                </div>

            </div >
            <div>
                <h3>Last Minute Buy</h3>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {medicineList && medicineList.map((x) => (
                        <div
                            key={Math.random()}
                            className="card"
                            style={{
                                width: '260px',
                                justifyContent: 'space-evenly',
                                padding: '10px',
                                margin: '10px'
                            }}
                            tabIndex={0}>
                            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }}
                                onClick={() => {
                                    history.push('/ProductDetails')
                                    x.qty = 1
                                    localStorage.setItem('productDetails', JSON.stringify(x));

                                }}
                            >
                                <img src={x.imageLink ? x.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product 1" style={{ width: '230px', height: '150px', }} />
                            </div>
                            <div style={{ textAlign: 'left', }}>

                                <p style={{ width: '100%', marginBottom: '3px' }}>{x.type} </p>
                                <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{x.Medicine_Name} </p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <p style={{ width: '100%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>{x.Price} </p>
                                    <img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                                        console.log('added to cart ' + x.title)
                                        // let cart = cartItems
                                        // cart.push(x)
                                        // setcartItems(() => cart)
                                        settingcart(x)
                                        console.log(cartItems)
                                    }} />
                                </div>
                            </div>
                        </div>



                    ))}
                </ScrollMenu>
            </div>

            <Modal
                backdrop="static"
                show={prefModalOpen}
                onHide={() => {
                    setprefModalOpen(false);
                    // rejector(new Error("No preference chosen"));
                }}
                // onHide={handleClose}
                keyboard={false}
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Login to place order</Modal.Title>
                </Modal.Header>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <input
                            // className="form-control col-sm-5 row"
                            name="Registered Email"
                            placeholder={'email'}
                            value={email}
                            onChange={e => setemail(e.target.value)}
                            style={styless.inputBoxStyle}
                        />
                        <input
                            // className="form-control col-sm-5 row"
                            name="Password"
                            placeholder={'Password'}
                            value={password}
                            type='password'
                            onChange={e => setpassword(e.target.value)}
                            style={styless.inputBoxStyle}
                        />
                    </div>
                    <div style={{ justifyItems: 'center', justifyContent: 'center', alignItems: 'center' }}>

                        <button
                            className="btn btn-primary account-btn"
                            onClick={() => {
                                // resolver(2);
                                if (email == ' ' || email == '' || password == ' ' || password == '') {
                                    Notify({
                                        alert: true,
                                        type: "error",
                                        title: 'Please enter a valid email address and password to continue',
                                    });
                                    return
                                }
                                auth.signInWithEmailAndPassword(email, password)
                                    .then(res => {

                                        setprefModalOpen(false);
                                        console.log("Signin success", res)
                                        setTimeout(() => {

                                            history.push({
                                                pathname: "/Order",
                                                state: { cart: cartItems },
                                            });
                                        }, 3000);
                                    })
                                    .catch((error) => {
                                        console.log("signin error", error)
                                        let errorMsg = "";

                                        switch (error.code) {
                                            case "auth/wrong-password":
                                                errorMsg = " The Email id or Password you entered is incorrect ";
                                                break;
                                            case "auth/user-not-found":
                                                errorMsg = " The Email id or Password you entered is incorrect ";
                                                break;
                                            default:
                                                errorMsg = error.message;
                                                break;
                                        }
                                        if (errorMsg == 'The email address is badly formatted.') {
                                            Notify({
                                                alert: true,
                                                type: "error",
                                                title: 'Please enter a valid email address',
                                            });
                                            return
                                        }
                                        Notify({
                                            alert: true,
                                            type: "error",
                                            title: errorMsg,
                                        });

                                    })
                            }}
                            style={{ marginRight: "10px", marginBottom: '5px !important', borderRadius: '25px', backgroundColor: '#41d9ff', borderColor: '#41d9ff' }}
                        >
                            Login
                        </button>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px', padding: '10px' }}>
                            don't have an account? <div
                                onClick={() => {
                                    history.push({
                                        pathname: "/register",
                                    });
                                }}
                                style={{ marginRight: "10px", color: '#41d9ff', cursor: 'pointer' }}
                            >
                                SignUp
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div >
    );
}

export default EditCart;
