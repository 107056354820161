// jshint esversion:6

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import Loader from "../Loader/Loader";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";
// import "../Patient/style.css";
// import "./style.css";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userData: null,
      pp_url: "",
    };
  }

  async componentDidMount() {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ loading: true });
        API.getUserDetails(user.displayName, user.uid)
          .then((result) => {
            let res = result.data

            console.log("ressss", res.user);
            this.setState({
              loading: false,
              userData: res.user,
            });

            // this.props.updateUserData(res.user);
            if (res.user.profile_picture) {
              console.log("USE PROFILE", res.user.profile_picture);
              this.makeDownloadUrl(res.user.profile_picture);
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log(err);
          });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <div className="page-header profile-header">
          <div className="content profile-content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-3 mt-md-5 doctor-sidebar">
                  <div className="doctor-list doctor-view">
                    <div className="doctor-inner">
                      <img
                        className="img-fluid"
                        alt=""
                        src={
                          this.state.pp_url !== ""
                            ? this.state.pp_url
                            : "../assets/img/doctor-06.jpg"
                        }
                      ></img>
                      <div className="doctor-details">
                        <div className="doctor-info">
                          <h4 className="doctor-name">
                            {this.props.user && (
                              <span>
                                {this.state.userData?.firstName}{" "}
                                {this.state.userData?.lastName}
                              </span>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <div className="about-doctor">
                    <div className="d-flex justify-content-end mb-4 ">

                      <Link
                        to="/receptionist/edit-profile"
                        className="btn custom-btn btn-primary ml-2 d-block  btn-rounded">
                        Edit Profile
                      </Link>
                    </div>
                    <div className="d-flex justify-content-end mb-4 "></div>
                    <div className="row">
                      <div className="experience-widget-h col-md-4">
                        <div className="experience-box">
                          <h3 className="sub-title-h">Name</h3>
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>
                                  {this.state.userData?.firstName}{" "}
                                  {this.state.userData?.lastName}
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Email Id</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{this.state.userData?.email}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Contact details</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{this.state.userData?.mobile}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="row"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
