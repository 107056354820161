import axios from "./axios";
import { auth } from "./firebase";
// // let baseUrl = "https://australia-southeast1-medvatic-438af.cloudfunctions.net/";
// let baseUrl = "https://australia-southeast1-medvatic-dev.cloudfunctions.net/"; //Dev

export default class Api {
  constructor() { }



  createApiToken(data) {
    return axios.post("/API/createAPIKey", data)
  }

  PluginStatus(data) {
    return axios.post("/API/updateAPIKey", data)
  }


  create(data) {
    return axios.post("user/create", data);
  }
  //asdf


  // payment(data) {
  //   return axios.post("user/payment", data);
  // }

  ///
  updateHospitalVerification(data) {
    return axios.post("hospital/verifyHospital", data);
  }

  addHospitalChain(data) {
    return axios.post("hospital/addHospitalChain", data);
  }

  editHospitalChain(data) {
    return axios.post("hospital/editHospitalChain", data);
  }

  addHospital(data) {
    return axios.post("hospital/addHospital", data);
  }

  deleteHospital(data) {
    return axios.post("user/delete", data);
  }

  deleteAdmin(data) {
    return axios.post("user/deleteAdmin", data);
  }

  deleteReceptionist(data) {
    return axios.post("user/delete", data);
  }

  deletePatient(data) {
    return axios.post("user/delete", data);
  }
  deleteDoctor(data) {
    return axios.post("user/delete", data);
  }

  deleteAppointment(data) {
    return axios.post("appointment/deleteAppointment", data);
  }

  deleteMemberFamily(data) {
    return axios.post("user/deleteMemberFamily", data);
  }

  rejectRequest(data) {
    return axios.post("user/rejectRequest", data);
  }

  listFamilyMembers(uid) {
    return axios.get("user/viewFamilyMembers?patient_id=" + uid);
  }

  viewHospitalChain() {
    return axios.get("hospital/viewHospitalChain");
  }

  PluginApiKey() {
    return axios.get("/API/allAPIKey");
  }

  PluginDeleteApi(data) {
    return axios.post("/API/deleteAPIKey", data)
  }

  getAllHospitals() {
    return axios.get("hospital/viewHospital");
  }

  updateUser(data) {
    return axios.post("user/update", data);
  }
  updateAddrs(data) {
    return axios.post("user/updateAddrs", data);
  }
  ptest() {
    return axios.get("pharmacy/test");
  }
  createInvoice(data) {
    return axios.post("pharmacy/createInvoice", data);
  }
  getMedicines(data) {
    return axios.post("pharmacy/getMedicines", data);
  }
  createSupplierPharmacy(data) {
    return axios.post("pharmacy/createSupplierPharmacy", data);
  }
  createPharmacyMedicine(data) {
    return axios.post("pharmacy/createPharmacyMedicine", data);
  }
  createPharmacyMedicineReview(data) {
    return axios.post("pharmacy/createPharmacyMedicineReview", data);
  }

  createLabTest(data) {
    return axios.post("pharmacy/createLabTest", data);
  }
  getLabTests(data) {
    return axios.post("pharmacy/getLabTests", data);
  }


  updatePharmacyMedicine(data) {
    return axios.post("pharmacy/updatePharmacyMedicine", data);
  }
  getSuppliersInfo(data) {
    return axios.post("pharmacy/getSuppliersInfo", data);
  }
  getInvoiceData(data) {
    return axios.post("pharmacy/getInvoiceData", data);
  }
  getAllMedicines(data) {
    return axios.post("pharmacy/getAllMedicines", data);
  }
  getAllAvailableMedicines(data) {
    return axios.post("pharmacy/getAllAvailableMedicines", data);
  }
  getAllPharmacyOrder(data) {
    return axios.post("pharmacy/getAllPharmacyOrder", data);
  }
  getPharmacyInvoices(data) {
    return axios.post("pharmacy/getPharmacyInvoices", data);
  }
  createPharmacyOrder(data) {
    return axios.post("pharmacy/createPharmacyOrder", data);
  }
  createNewOrder(data) {
    return axios.post("pharmacy/createNewOrder", data);
  }
  createLabTest(data) {
    return axios.post("pharmacy/createLabTest", data);
  }
  updatePharmacyOrder(data) {
    return axios.post("pharmacy/updatePharmacyOrder", data);
  }
  getOrderDetails(data) {
    return axios.post("pharmacy/getOrderDetails", data);
  }
  getLabTestByTestCode(data) {
    return axios.post("pharmacy/getLabTestByTestCode", data);
  }
  updateUserPrescriber(data) {
    return axios.post("user/addPrescriber", data);
  }
  getDigitalSignature(data) {
    return axios.post("user/getDigitalSignature", data);
  }

  addSchedule(data) {
    return axios.post("appointment/addSchedule", data);
  }

  deleteSchedule(data) {
    return axios.post("appointment/deleteSchedule", data);
  }

  changePassword(data) {
    return axios.post("user/changePwd", data);
  }
  saveDataToAppointment(data) {
    console.log('calling the function', data);
    return axios.post("user/saveDataToAppointment", data);
  }
  saveNewPrescriptionData(data) {
    console.log('calling the function', data);
    return axios.post("user/saveNewPrescriptionData", {
      body: data,
    });
  }

  async getUserDetails(type, uid) {
    return axios.get("user/fetchUserDetails?type=" + type + "&uid=" + uid);
  }
  //type = 2 Business Admins fetching

  getUsers(type, hospitalId = "") {
    return axios.get("user/view?type=" + type + "&hospitalId=" + hospitalId);
  }

  //View Doctor Requests
  fetchStoredDoctors(hospital_code) {
    return axios.get(
      "content/fetchStoredDoctors?hospital_code=" + hospital_code
    );
  }

  //Update Doctor Registration
  updateDoctorRegistration(data) {
    return axios.post("content/updateDoctorRegistration", data);
  }

  forgetPassword(type, email) {
    return axios.get("user/forgotPwd?type=" + type + "&email=" + email);
  }

  addAppointment(data) {
    return axios.post("appointment/addAppointment2", data);
  }

  addLabAppointment(data) {
    return axios.post("appointment/addLabAppointment", data);
  }

  listAppointments(hospitalId) {
    return axios.get("appointment/viewAppointment?hospitalId=" + hospitalId);
  }

  listAppointmentsReceptionist(hospitalId) {
    return axios.get(
      "appointment/viewAppointment?hospitalId=" + hospitalId + "&userType=4"
    );
  }
  listAppointmentsHospital(hospitalId) {
    return axios.get(
      "appointment/viewAppointment?hospitalId=" + hospitalId + "&userType=2"
    );
  }
  listAppointmentsDoctor(hospitalId, doctorId, type) {
    return axios.get(
      "appointment/viewAppointment?hospitalId=" +
      hospitalId +
      "&doctorId=" +
      doctorId +
      "&userType=" +
      type
    );
  }

  listPatientAppointments(hospitalId, uid) {
    return axios.get(
      "appointment/viewAppointment?hospitalId=" +
      hospitalId +
      "&patientId=" +
      uid +
      "&userType=5"
    );
  }

  listSinglePatientAppointments(hospitalId, uid, patientID) {
    return axios.get(
      "appointment/viewAppointment?hospitalId=" +
      hospitalId +
      "&doctorId=" +
      uid +
      "&userType=5&patientId=" +
      patientID
    );
  }

  updateAppointment(data) {
    return axios.post("appointment/updateAppointment", data);
  }
  getPharmacies(data) {
    return axios.get("user/getPharmacies");
  }

  getSelectedPharmacy(data) {
    return axios.post("user/getSelectedPharmacy", data);
  }

  getOrdersByPharmacyId(pharmacyId) {
    return axios.get(`appointment/viewOrders?uid=${pharmacyId}`);
  }

  getOrderByUid(uid) {
    return axios.get(`appointment/viewOrder?uid=${uid}`);
  }

  changeOrderStatus(data) {
    return axios.post("appointment/changeOrderStatus", data);
  }

  updateAppointmentRecep(data) {
    return axios.post("appointment/updateAppointmentRecep", data);
  }

  addAppointmentReport(data) {
    return axios.post("appointment/addAppointmentReport", data);
  }

  deleteAppointmentReport(data) {
    return axios.post("appointment/deleteAppointmentReport", data);
  }

  addDoctorAppointmentReport(data) {
    return axios.post("appointment/addDoctorAppointmentReport", data);
  }

  deleteDoctorAppointmentReport(data) {
    return axios.post("appointment/deleteDoctorAppointmentReport", data);
  }

  // "appointment/listSchedule?doctorId="+doctorId+"&from="+from+"&to="+to
  listSchedule(doctorId, from, to) {
    return axios.get(
      "appointment/listSchedule?doctorId=" +
      doctorId +
      "&fromDate=" +
      from +
      "&toDate=" +
      to
    );
  }

  getToken() {
    return fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "api-token":
          "1R13xUH_kpgbgReWc5tK-eqwORdWtxLEcq-9upGiZ2w4kyngfje1kKPzzZhzrGq933U",
        "user-email": "murtazamzk@gmail.com",
      },
    });
  }

  getCountries() {
    return fetch("https://countriesnow.space/api/v0.1/countries/positions", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  getStates(country, token) {
    return fetch("https://countriesnow.space/api/v0.1/countries/states", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ country }),
    });
  }

  getCities(country, state) {
    return fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country,
        state,
      }),
    });
  }

  contactUs(data) {
    return axios.post("content/sendMail", data);
  }

  addPatient(data) {
    return axios.post("user/addPatient", data);
  }

  joinFamily(data) {
    return axios.post("user/joinFamily", data);
  }

  getFamilyRequests(uid) {
    return axios.get("user/fetchFamilyRequests?uid=" + uid);
  }

  addFamilyMember(data) {
    return axios.post("user/addMemberFamily", data);
  }

  isUserExist(data) {
    return axios.post("user/isUserExist", data);
  }

  getDoctorSchedule(id) {
    return axios.get("appointment/scheduleDetails?doctor_id=" + id);
  }

  async getAPIToken() {
    const token = await auth?.currentUser
      ?.getIdToken() // here we force a refresh if boolean set is true
      .then((token) => {
        console.log("Token nniksasasasaa", token);
        return token;
      })
      .catch((error) => {
        console.log("Token errorsasasa", error);
      });
    return token;
  }
  async getAppointmentDetails(id) {
    return axios.get(
      "appointment/fetchAppointmentDetails?appointment_id=" + id
    );
  }

  createRazorpayOrder(id) {
    return axios.post("appointment/razorpay?appointment_id=" + id);
  }

  selfRegistrationDoctor(data) {
    return axios.post("content/collectDoctors", data);
  }
  //search-1
  getLocation() {
    return fetch("https://ipapi.co/json", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }
  //search-2
  getSpecialities() {
    return axios.get("content/listSpeciality");
  }

  addNewSpeciality(data) {
    return axios.post("content/addSpeciality", data);
  }

  addNewMedicines(data) {
    return axios.post("content/saveMedicinesToDB", data);
  }

  updateSpeciality(data) {
    return axios.post("content/editSpeciality", data);
  }

  deleteSpeciality(data) {
    return axios.post("content/deleteSpeciality", data);
  }
  deletePharmacy(data) {
    return axios.post("content/deletePharmacy", data);
  }

  addPharmacy(data) {
    // body.email
    // body.name
    // body.orderCount
    // body.pharmacyId
    // body.pharmacyName
    return axios.post("content/addPharmacy", data);
  }
  getPatientOrders(data) {
    return axios.post("content/getPatientOrders", data);
  }

  fetchComments(id, commenttype) {
    return axios.get(
      "appointment/viewComments?appointment_id=" +
      id +
      "&commenttype=" +
      commenttype
    );
  }

  addComment(data) {
    return axios.post("appointment/addComment", data);
  }

  editComment(data) {
    return axios.post("appointment/editComment", data);
  }

  getAgoraToken(uid, channel) {
    return axios.get("appointment/apttoken?uid=" + uid + "&roomid=" + channel);
  }

}
