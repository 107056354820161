import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import csc from "country-state-city";
import firebase from "firebase";
import React, { Component } from "react";
import BaseSelect from "react-select/creatable";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import "../../Helpers/style.css";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import { customAlphabet } from "nanoid";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GoogleMap from "../Agora/utils/GoogleMap";
var QRCode = require("qrcode.react");
const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);
export default class AddPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePicture: null,
      geoLocation: "",
      countries: [],
      states: [],
      cities: [],
      modal: false,
      loading: false,
      formData: {
        firstName: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid First Name!",
          valid: true,
        },
        middleName: {
          value: "",
          validation: {
            minLength: 3,
            onlyAlphabets: true,
          },
          errMsg: "Invalid Middle Name!",
          valid: true,
        },
        lastName: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid last Name!",
          valid: true,
        },
        dateOfBirth: {
          value: new Date(),
          validation: {
            required: true,
          },
          valid: true,
        },
        email: {
          value: "",
          validation: {
            required: true,
            isEmail: true,
          },
          errMsg: "Invalid Email!",
          valid: true,
        },
        phoneno: {
          value: "",
          validation: {
            minLength: 8,
            maxLength: 15,
            required: true,
            // isPhoneNumber: true,
            // isNumeric: true,
          },
          errMsg: "Invalid Phone Number",
          valid: true,
        },
        countryCodePatient: {
          value: "",
          validation: {
            required: true,
            isCountryCode: true,
          },
          errMsg: "Invalid Country Code!",
          valid: true,
        },
        insuranceNumber: {
          value: "",
          validation: {
            isNumeric: true,
          },
          errMsg: "Invalid Insurance Number",
          valid: true,
        },
        height: {
          value: "",
          validation: {
            minLength: 2,
            required: false,
            isNumeric: true,
          },
          errMsg: "Invalid Height!",
          valid: true,
        },
        weight: {
          value: "",
          validation: {
            minLength: 2,
            required: false,
            isNumeric: true,
          },
          errMsg: "Invalid Weight!",
          valid: true,
        },
        emergencyContactNumber: {
          value: null,
          validation: {
            minLength: 10,
            maxLength: 15,
            // isPhoneNumber: true,
            isNumeric: true,
          },
          errMsg: "Invalid Emergency Number",
          valid: true,
        },
        emergencyContactName: {
          value: null,
          validation: {
            minLength: 3,
            onlyAlphabets: true,
          },
          errMsg: "Invalid Name!",
          valid: true,
        },
        bloodgroup: {
          value: "",
          validation: {
            required: false,
          },
          valid: true,
        },
        gender: {
          value: "",
          valid: true,
        },
        address: {
          value: "",
          validation: {
            required: true,
            minLength: 1,
          },
          errMsg: "Invalid address!",
          valid: true,
        },
        country: {
          value: "",
          country_id: "",
          valid: true,
        },
        city: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid City Name!",
          valid: true,
        },
        State: {
          value: "",
          state_id: "",
          valid: true,
        },
        postalcode: {
          value: "",
          validation: {
            // minLength: 5,
            required: true,
            isNumeric: true,
          },
          errMsg: "Invalid Postal Code!",
          valid: true,
        },
        allergies: {
          value: [],
          valid: true,
        },
      },

      formIsValid: false,
      disable: true,
      error: "",
      success: false,
      redirect: false,
      long: "",
      lat: "",
      country_code: "",
    };
    console.log("Props addpatient", props);
  }
  componentDidMount() {
    this.setState({ loading: true });
    // if (this.props.location.state !== undefined) {
    //   if (this.props.location.state.redirect) {
    //     this.setState({ redirect: true });
    //   }
    // }
    console.log("ADD PATIENT MOUNTED");
    let data = csc.getAllCountries();
    this.setState({ countries: data });
    let API = new Apilib();
    // Get geolocation of a user's browser.
    API.getLocation()
      .then((result) => {
        let res = result.data;

        console.log("LOCATION DETAILS:", res);
        this.setState({
          loading: false,
          lat: res.latitude,
          long: res.longitude,
          country_code: res.country_calling_code,
        });
        API.getUsers(5, this.props.userData.hospitalId).then((result) => {
          let res = result.data;

          let patientList = [];
          patientList = res.patients;
          this.setState({
            patientList: patientList,
          });
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("geoError", err);
      });
  }
  customInput = ({ onChange, placeholder, value, isSecure, onClick }) => (
    <input
      className="form-control"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      isSecure={isSecure}
      onClick={onClick}
    />
  );
  dateInputChangeHandler = (date) => {
    this.setState({
      formData: {
        ...this.state.formData,
        dateOfBirth: {
          ...this.state.formData.dateOfBirth,
          value: date,
        },
      },
    });
  };
  onChange = (e, name) => {
    if (name === "dateOfBirth") {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: {
            value: e,
            valid: true,
          },
        },
      });
      return;
    }
    let inputName = e.target.name;
    const updatedForm = { ...this.state.formData };
    const updatedFormElement = { ...updatedForm[inputName] };
    let value = "";
    switch (inputName) {
      case "country":
        console.log("country", this.state.countries);
        for (let key of this.state.countries) {
          if (key.id === e.target.value) {
            value = key.name;
            updatedFormElement.country_id = e.target.value;
          }
        }
        let stateData = csc.getStatesOfCountry(e.target.value);
        this.setState({ states: stateData });
        updatedForm.State.value = "";
        updatedForm.State.state_id = "";
        updatedForm.State.valid = false;
        // updatedForm.city.value = "";
        // updatedForm.city.city_id = "";
        // updatedForm.city.valid = false;
        break;
      case "State":
        for (let key of this.state.states) {
          if (key.id === e.target.value) {
            value = key.name;
            updatedFormElement.state_id = e.target.value;
          }
        }
        // let cityData = csc.getCitiesOfState(e.target.value);
        // this.setState({ cities: cityData });
        // updatedForm.city.value = "";
        // updatedForm.city.city_id = "";
        // updatedForm.city.valid = false;
        break;
      // case "city":
      //   for (let key of this.state.cities) {
      //     if (key.id === e.target.value) {
      //       value = key.name;
      //       updatedFormElement.city_id = e.target.value;
      //     }
      //   }
      // break;
      // no default
    }
    if (
      inputName === "country" ||
      inputName === "State"
      // inputName === "city"
    ) {
      updatedFormElement.value = value;
    } else {
      updatedFormElement.value = e.target.value;
    }
    updatedForm[inputName] = updatedFormElement;
    updatedForm[inputName].valid = this.checkValidity(
      updatedForm[inputName].value,
      updatedForm[inputName].validation
    );
    let formValid = true;
    for (let key in updatedForm) {
      formValid = updatedForm[key].valid && formValid;
    }
    console.log("form valid", formValid);
    this.setState({
      formIsValid: formValid,
      disable: !formValid,
      formData: updatedForm,
    });
  };
  onSelect = (val) => {
    console.log("value ---->", val);
    let formObj = { ...this.state.formData };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val);
    let arr = [];
    if (val && val.length) {
      val.map((value, index) => {
        arr.push(value.value);
      });
    }
    formObj["allergies"].value = arr;
    this.setState({
      formData: formObj,
      allergies: val,
    });
    // console.log("formdata", formObj, val);
  };
  checkValidity(value, rules) {
    if (rules != undefined) {
      let isValid = true;
      if (rules.required) {
        isValid = value.trim() !== "" && isValid;
      }
      if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
      }
      if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
      }
      if (rules.isPhoneNumber) {
        const pattern = /^[0-9]{8,}$/;
        isValid = pattern.test(value) && isValid;
      }
      if (rules.isCountryCode) {
        const pattern = /^[+.0-9]+$/;
        isValid = pattern.test(value) && isValid;
      }

      // if (rules.isNumeric) {
      //   const pattern = /^[0-9.]+$/;
      //   isValid = pattern.test(value) && isValid;
      // }
      if (rules.isEmail) {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        isValid = pattern.test(value) && isValid;
      }
      if (rules.onlyAlphabets) {
        const pattern = /^[A-Za-z/' ]+$/;
        isValid = pattern.test(value) && isValid;
      }
      return isValid;
    } else {
      return true;
    }
  }
  async handleProfileUpload(fileBlob, uid) {
    try {
      const file = fileBlob;
      const ref = firebase.storage().ref();
      const name = "profile_images/patients/" + uid;
      const metadata = {
        contentType: file.type,
      };
      return ref
        .child(name)
        .put(file, metadata)
        .then(() => {
          let API = new Apilib();
          return API.updateUser(
            JSON.stringify({
              uid,
              profile_picture: name,
              type: 5,
            })
          );
        });
    } catch (error) {
      console.error(error, "Error in uploading profile image");
      throw error;
    }
  }
  phoneChecking(phoneno) {
    let patientPhoneCheck = this.state.patientList;
    for (var i = 0; i < patientPhoneCheck.length; i++) {
      if (patientPhoneCheck[i].phoneno) {
        return false;
      }
    }
    return true;
  }
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValid) {
      console.log("success");
      let baseState = { ...this.state };
      this.setState({
        error: "",
        loading: true,
      });
      let API = new Apilib();
      let data = {};
      const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
      for (let inputType in this.state.formData) {
        data[inputType] = this.state.formData[inputType].value;
        data = {
          ...data,
          type: 5,
          uid: this.props.user.uid,
          hospitalId: this.props.userData.hospitalId,
          hospitalChainId: this.props.userData.hospitalChainId,
          password: nanoid(),
          created_at: Date.parse(new Date()),
        };
      }
      data.long = this.state.long;
      data.lat = this.state.lat;
      data.geoLocation = this.state.geoLocation;
      data.country_code = this.state.country_code;
      delete data.loading;
      delete data.error;
      delete data.success;
      console.log("API data::", data);
      API.create(JSON.stringify(data))
        .then((result) => {
          let res = result.data;
          this.setState({
            loading: false,
            firstName: "",
            lastName: "",
            phoneno: "",
            email: "",
            emergencyContactNumber: "",
            emergencyContactName: "",
            countryCodePatient: "",
            bloodgroup: "",
            height: "",
            weight: "",
            bmr: "",
            gender: "",
            address: "",
            country: "",
            city: "",
            State: "",
            postalcode: "",
            error: "",
            success: true,
          });
          Notify({
            type: "success",
            alert: true,
            title: "Patient added successfully",
          });
          this.props.history.push({
            pathname: "/doctor/patients",
            state: { addPatientSuccess: true },
          })
        })
        .catch((error) => {
          if (error?.response?.data?.code == "auth/email-already-exists") {
            this.setState({
              error: error.response.data.message,
              loading: false,
            })
            Notify({
              type: "error",
              alert: true,
              title: "An account with same email is already present, please use other email to create an account",
            })
          }
          else this.setState({
            error: error.message,
            loading: false,
          });
        });
    } else {
      this.setState({
        error: "Invalid Form",
        loading: false,
      });
      // setSendRequest(false);
      // setDisable(true);
      return;
    }
  };
  render() {
    let allBloodGroups = [
      {
        id: "1",
        name: "A+",
      },
      {
        id: "2",
        name: "A-",
      },
      {
        id: "3",
        name: "B+",
      },
      {
        id: "4",
        name: " B-",
      },
      {
        id: "5",
        name: "AB+",
      },
      {
        id: "6",
        name: "AB-",
      },
      {
        id: "7",
        name: "O+",
      },
      {
        id: "8",
        name: "O-",
      },
    ];
    let arr = [
      "Brazil nuts",
      "Almonds",
      "Cashews",
      "Macadamia nuts",
      "Pistachios",
      "Pine nuts",
      "Walnuts",
      "Cow’s Milk Allergies",
      "Milk",
      "Milk powder",
      "Cheese",
      "Butter",
      "Margarine",
      "Yogurt",
      "Cream",
      "Ice cream",
      "Shellfish Allergies",
      "Shrimp",
      "Prawns",
      "Crayfish",
      "Lobster",
      "Squid",
      "Scallops",
      "Peanuts Allergies",
      "Eggs Allergies",
      "Fish Allergies",
      "Soy Allergies",
      "Dog Allergies",
      "Pollen Allergies",
      "Pet Hair Allergies",
      "Dust Mites Allergies",
      "Insect bites Allergies",
      "Wheat Allergies",
      "Antibiotic Allergies",
    ];
    let allegies = arr.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    console.log("allergies", allegies);
    let bloodGroup = null;
    if (allBloodGroups.length !== 0) {
      bloodGroup = allBloodGroups.map((item) => {
        return (
          <MenuItem key={item.id} value={item.name}>
            {item.name}
          </MenuItem>
        );
      });
    }
    let countriesMenu = null;
    if (this.state.countries && this.state.countries.length !== 0) {
      countriesMenu = this.state.countries.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }
    let statesMenu = null;
    if (this.state.states && this.state.states.length !== 0) {
      statesMenu = this.state.states.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }
    let citiesMenu = null;
    if (this.state.cities && this.state.cities.length !== 0) {
      citiesMenu = this.state.cities.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={(e) => this.onSubmit(e)}>
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Patient added successfully
                </div>
              )}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      error={
                        this.state.formData.firstName.valid ? "false" : "true"
                      }
                      value={this.state.formData.firstName.value}
                      //   value={this.state.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    //   isValid={true}
                    />
                    {/* <i class="fa fa-check"></i> */}
                    {!this.state.formData["firstName"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["firstName"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Middle Name</label>
                    <input
                      name="middleName"
                      error={
                        this.state.formData.middleName.valid ? "false" : "true"
                      }
                      value={this.state.formData.middleName.value}
                      //   value={this.state.lastName}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                    {!this.state.formData["middleName"].valid ? (
                      <p className="error-messege">
                        <small>
                          {this.state.formData["middleName"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      error={
                        this.state.formData.lastName.valid ? "false" : "true"
                      }
                      value={this.state.formData.lastName.value}
                      //   value={this.state.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                    {!this.state.formData["lastName"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["lastName"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                    <label htmlFor="dob">Date of Birth</label>
                    <span className="required">*</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className={
                          this.state.formData.dateOfBirth.valid
                            ? "form-control"
                            : "form-control error"
                        }
                        inputVariant="outlined"
                        value={this.state.formData.dateOfBirth.value}
                        onChange={(e) => this.dateInputChangeHandler(e)}
                        animateYearScrolling
                        inputProps={{
                          onChange: this.dateInputChangeHandler,
                        }}
                        name="dateOfBirth"
                        TextFieldComponent={this.customInput}
                        disableFuture
                        format="dd/MM/yyyy"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Insurance Number</label>
                    <input
                      name="insuranceNumber"
                      error={
                        this.state.formData["insuranceNumber"].valid
                          ? "false"
                          : "true"
                      }
                      value={this.state.formData["insuranceNumber"].value}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                    <label>
                      Country Code <span className="text-danger">*</span>
                    </label>
                    <input
                      name="countryCodePatient"
                      prefix="+"
                      error={
                        this.state.formData["countryCodePatient"].valid
                          ? "false"
                          : "true"
                      }
                      value={this.state.formData["countryCodePatient"].value}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                    />
                    {!this.state.formData["countryCodePatient"].valid ? (
                      <p className="error-messege">
                        <small>
                          {this.state.formData["countryCodePatient"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>
                      Phone No. <span className="text-danger">*</span>
                    </label>
                    <input
                      name="phoneno"
                      error={
                        this.state.formData["phoneno"].valid ? "false" : "true"
                      }
                      value={this.state.formData["phoneno"].value}
                      //   value={this.state.phoneno}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="number"
                    />
                    {!this.state.formData["phoneno"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["phoneno"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      error={this.state.formData.email.valid ? "false" : "true"}
                      value={this.state.formData["email"].value}
                      //   value={this.state.email}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                    {!this.state.formData["email"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["email"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Name</label>
                    <input
                      name="emergencyContactName"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                      error={
                        this.state.formData["emergencyContactName"].valid
                          ? "false"
                          : "true"
                      }
                      value={this.state.formData["emergencyContactName"].value}
                    />
                    {!this.state.formData["emergencyContactName"].valid ? (
                      <p className="error-messege">
                        <small>
                          {this.state.formData["emergencyContactName"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Number</label>
                    <input
                      name="emergencyContactNumber"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="number"
                      error={
                        this.state.formData["emergencyContactNumber"].valid
                          ? "false"
                          : "true"
                      }
                      value={
                        this.state.formData["emergencyContactNumber"].value
                      }
                    />
                    {!this.state.formData["emergencyContactNumber"].valid ? (
                      <p className="error-messege">
                        <small>
                          {this.state.formData["emergencyContactNumber"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <Select2
                      className="form-control"
                      // required
                      fullWidth
                      name="bloodgroup"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.bloodgroup}
                    >
                      {bloodGroup}
                    </Select2>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start ">
                      <div>
                        <label>Height</label>
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#d4edda",
                          width: "60px",
                          height: "25px",
                          borderColor: "#c3e6cb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>In CM</span>
                      </div>
                    </div>
                    <input
                      name="height"
                      error={
                        this.state.formData["height"].valid ? "false" : "true"
                      }
                      value={this.state.formData["height"].value}
                      //   value={this.state.height}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                    {!this.state.formData["height"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["height"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start ">
                      <div>
                        <label>Weight</label>
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#d4edda",
                          width: "60px",
                          height: "25px",
                          borderColor: "#c3e6cb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>In KG</span>
                      </div>
                    </div>
                    <input
                      name="weight"
                      error={
                        this.state.formData["weight"].valid ? "false" : "true"
                      }
                      value={this.state.formData["weight"].value}
                      //   value={this.state.weight}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                    {!this.state.formData["weight"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["weight"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group gender-select">
                    <label className="gen-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          name="gender"
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          checked={this.state.formData.gender.value == "male"}
                          value="male"
                          className="form-check-input"
                        />
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.formData.gender.value == "female"}
                          value="female"
                          className="form-check-input"
                        />
                        Female
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.formData.gender.value == "other"}
                          value="other"
                          className="form-check-input"
                        />
                        Other
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={
                            this.state.formData.gender.value ==
                            "I wish not to disclose "
                          }
                          value="I wish not to disclose "
                          className="form-check-input"
                        />
                        I wish not to Disclose
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text-area"
                      name="address"
                      error={
                        this.state.formData["address"].valid ? "false" : "true"
                      }
                      value={this.state.formData.address.value}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                    {!this.state.formData["address"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["address"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Allergies</label>
                    <Select
                      placeholder="Enter Allergies"
                      onChange={(item) => this.onSelect(item)}
                      // value={this.state.selectedDaysObj}
                      options={allegies}
                      noOptionsMessage={() => "Enter allergy and press enter"}
                      isMulti={true}
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      City/State/Country <span className="text-danger">*</span>
                    </label>

                    <GoogleMap
                      style={{ width: 100 }}
                      value={this.state.geoLocation}
                      onChange={(e) => console.log("aaa")}
                      getAddress={(address) => {
                        this.setState(
                          {
                            geoLocation: address,
                          },
                          () => {
                            console.log("MApAddress", this.state.geoLocation);
                          }
                        );
                        console.log("GoogleMapsAddress", address);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Postal Code <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="pincode"
                      name="postalcode"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      error={
                        this.state.formData["postalcode"].valid
                          ? "false"
                          : "true"
                      }
                      value={this.state.formData["postalcode"].value}
                    />
                    {!this.state.formData["postalcode"].valid ? (
                      <p className="error-messege">
                        <small>
                          {this.state.formData["postalcode"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="m-t-20 text-center">
                <button
                  type="submit"
                  //   disabled={this.state.disable}
                  className="btn btn-primary submit-btn"
                >
                  Add Patient
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
