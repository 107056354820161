// jshint esversion:6

import React, { Component } from "react";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";

export default class Treatment extends Component {
  render() {
    return (
      <>
        <section className="section services-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-header text-center">
                  <h3
                    className="header-title treatment-header"
                    // style="margin-top: 30px; color: #fff"
                  >
                    Treatment Plan Management Tool
                  </h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-header text-center">
                  <h3 className="header-title"></h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 about-desc">
                <h3 className="header-h3">
                  Medvatic is a treatment plan management tool built
                  specifically to improve the ongoing health monitoring and
                  communication process between patients and practitioners.
                </h3>
                <br />

                <p>
                  Built as a mobile app, Medvatic allows practitioners to build
                  tailored treatment plans incorporating drug prescriptions,
                  non-drug rehabilitation actions and recurring measurement and
                  monitoring requests. Patients interact with their treatment
                  plan using their own mobile app login, with reminders pushed
                  to their device to follow plan actions and report back to the
                  practitioner.
                </p>
              </div>
            </div>
            <div className="about-content">
              <div className="text-center">
                <img
                  src="assets/img/solution.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <div className="row ">
          <div className="col-12">
            <div className="see-all m-t-0  product-bottom">
              <p className="product-p"> WANT TO KNOW MORE? </p>
              <a
                type="button"
                href="#/Contact"
                className="btn btn-primary see-all-btn practitioner-button"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
