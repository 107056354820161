import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Notify from "../../Notify";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={index}
      aria-labelledby={index}
      {...other}
    >
      {children}
    </div>
  );
}

export default class AppointmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointments: [],
      upcomming: [],
      today: [],
      past: [],
      tab: "one",
      modalOpen: false,
      readOnly: false,
      modalLoading: false,
      currentAppointment: {},
      success: false,
      currentAppId: "",
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.fetchAppointments(true);
  }

  fetchAppointments(flag) {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listAppointmentsReceptionist(this.props.userData.hospitalId)
      .then((result) => {
        let res = result.data

        console.log("All the appointments", res);
        if (this._isMounted) {
          let dataSorted = res.appointments.sort((a, b) => {
            return b.created_at - a.created_at;
          });
          console.log("datasorted", dataSorted);

          let date = new Date();
          date.setHours(0, 0, 0, 0);
          let startDate = date.getTime();
          date.setHours(23, 59, 59, 0);
          let endDate = date.getTime();

          let upcommingArr = [];
          let todayArr = [];
          let pastArr = [];

          dataSorted.forEach((doc) => {
            if (doc.from >= startDate && doc.from <= endDate) {
              todayArr.push(doc);
            } else if (doc.from < startDate) {
              pastArr.push(doc);
            } else if (doc.from > endDate) {
              upcommingArr.push(doc);
            }
          });
          this.setState(
            {
              loading: false,
              appointments: dataSorted,
              upcomming: upcommingArr,
              today: todayArr,
              past: pastArr,
            },
            () => {
              console.log("Up->", upcommingArr);
              if (flag) {
                if ($(".datatable").length > 0) {
                  $(".datatable").DataTable({
                    scrollX: true,
                    aoColumnDefs: [
                      {
                        bSortable: false,
                        aTargets: [-1] /* 1st one, start by the right */,
                      },
                    ],
                  });
                }
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  deleteAppointment(currentAppointment) {
    const API = new Apilib();
    let data = {
      id: currentAppointment.appointmentId,
    };
    console.log("DeleteAppoint", data)
    this.setState({
      loading: true,
    });
    API.deleteAppointment(JSON.stringify(data))
      .then((result) => {
        let res = result.data

        this.fetchAppointments();
        this.setState({
          show: false,
          currentAppointment: {},
        });
        Notify({
          alert: true,
          type: "success",
          title: "Deleted successfully",
        });
      })
      .catch((err) => {
        this.setState({
          show: false,
          loading: false,
          currentAppointment: {},
        });
        Notify({
          alert: true,
          type: "error",
          title: err.message,
        });
      });
  }

  handleOpen(e, appointment) {
    // e.preventDefault();
    this.setState({
      modalOpen: true,
      currentAppointment: appointment,
      error: null,
      success: false,
    });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      currentAppointment: {},
    });
  }

  handleDeleteOpen(e, appointment) {
    e.preventDefault();
    this.setState({
      currentAppointment: appointment,
      error: null,
      success: false,
    });
  }



  openVideoCall(e, appointment) {
    e.preventDefault();
    window.open(
      window.location.origin +
      "/#/videocall?meetingid=" +
      appointment.meetingid +
      "&appointmentid=" +
      appointment.appointmentId +
      "&uid=" +
      this.props.userData.uid +
      "&token=" +
      appointment.token,
      "_blank"
    );
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleTabChange(event, newValue) {
    this.setState({ tab: newValue });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Appointments</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link
                to="/receptionist/add-appointment"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Appointment
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Appointment updated successfully
                </div>
              )}
              <Tabs
                variant="fullWidth"
                centered
                className="mb-3"
                value={this.state.tab}
                onChange={(e, v) => this.handleTabChange(e, v)}
                aria-label="wrapped label tabs example"
              >
                <Tab value="one" label="Upcoming" wrapped />
                <Tab value="two" label="Today" wrapped />
                <Tab value="three" label="Past" wrapped />
              </Tabs>
              <TabPanel value={this.state.tab} index="one">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>User Details</th>
                        <th>Doctor Name</th>
                        <th>Appointment Type</th>
                        <th>Date / Time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.upcomming &&
                        this.state.upcomming.map((appointment, i) => (
                          <tr key={appointment.appointmentId}>
                            <td>
                              {appointment.appointment_status === "booked" ? (
                                <Link
                                  to={{
                                    pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                    state: {
                                      name: this.state.appointments[i],
                                    },
                                  }}
                                >
                                  {appointment.name}
                                </Link>
                              ) : (
                                <Link
                                  to={{
                                    pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                    state: {
                                      name: this.state.appointments[i],
                                    },
                                  }}
                                >
                                  {appointment.name}
                                </Link>
                              )}
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/receptionist/doctor-appointment/" +
                                    appointment.doctorId,
                                  state: {
                                    doctorId: appointment.doctorId,
                                    hospitalId: appointment.hospitalId,
                                  },
                                }}
                              >
                                {appointment.doctorName}
                              </Link>
                            </td>
                            <td>
                              {appointment.appointmentType === "1"
                                ? "Physical"
                                : "Video"}
                            </td>
                            <td>
                              {appointment.slot_date}
                              <br />
                              {appointment.slot_time}
                            </td>
                            <td>
                              <span className="text-capitalize">
                                {appointment.appointment_status}
                              </span>
                            </td>
                            <td className="text-center mr-6">
                              <div className="dropdown dropdown-action">
                                <a
                                  href="#!"
                                  className="action-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    to={{
                                      pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                      state: {
                                        name: this.state.appointments[i],
                                      },
                                    }}
                                    className="dropdown-item"
                                  >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    View Only
                                  </Link>
                                  {appointment.appointment_status ===
                                    "booked" ? (
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname:
                                          "/receptionist/edit-appoinment",

                                        state: {
                                          name: appointment,
                                        },
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </Link>
                                  ) : (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => e.preventDefault()}
                                      to={{
                                        pathname:
                                          "/receptionist/edit-appoinment",

                                        state: {
                                          name: appointment,
                                        },
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </Link>
                                  )}

                                  <button
                                    className="dropdown-item"
                                    href="#!"
                                    disabled={
                                      appointment.appointment_status ===
                                        "cancelled" ||
                                        appointment.appointment_status ===
                                        "completed"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        readOnly: false,
                                        show: true,
                                      });
                                      this.handleDeleteOpen(e, appointment);
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tab} index="two">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>User Details</th>
                        <th>Doctor Name</th>
                        <th>Appointment Type</th>
                        <th>Date / Time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.today &&
                        this.state.today.map((appointment, i) => (
                          <tr key={appointment.appointmentId}>
                            <td>
                              {appointment.appointment_status === "booked" ? (
                                <Link
                                  to={{
                                    pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                    state: {
                                      name: this.state.appointments[i],
                                    },
                                  }}
                                >
                                  {appointment.name}
                                </Link>
                              ) : (
                                <Link
                                  to={{
                                    pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                    state: {
                                      name: this.state.appointments[i],
                                    },
                                  }}
                                >
                                  {appointment.name}
                                </Link>
                              )}
                            </td>
                            <td>{appointment.doctorName}</td>
                            <td>
                              {appointment.appointmentType === "1"
                                ? "Physical"
                                : "Video"}
                            </td>
                            <td>
                              {appointment.slot_date}
                              <br />
                              {appointment.slot_time}
                            </td>
                            <td>
                              <span className="text-capitalize">
                                {appointment.appointment_status}
                              </span>
                            </td>

                            <td className="text-center mr-6">
                              <div className="dropdown dropdown-action">
                                <a
                                  href="#!"
                                  className="action-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    to={{
                                      pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                      state: {
                                        name: this.state.appointments[i],
                                      },
                                    }}
                                    className="dropdown-item"
                                  >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    View Only
                                  </Link>
                                  {appointment.appointment_status ===
                                    "booked" ? (
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname: "/doctor/edit-appointment",

                                        state: {
                                          name: appointment,
                                        },
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </Link>
                                  ) : (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => e.preventDefault()}
                                      to={{
                                        pathname: "/doctor/edit-appointment",

                                        state: {
                                          name: appointment,
                                        },
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </Link>
                                  )}

                                  <button
                                    className="dropdown-item"
                                    href="#!"
                                    disabled={
                                      appointment.appointment_status ===
                                        "cancelled" ||
                                        appointment.appointment_status ===
                                        "completed"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        readOnly: false,
                                        show: true,
                                      });
                                      this.handleDeleteOpen(e, appointment);
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tab} index="three">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>User Details</th>
                        <th>Doctor Name</th>
                        <th>Appointment Type</th>
                        <th>Date / Time</th>
                        <th>Status</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.past &&
                        this.state.past.map((appointment, i) => (
                          <tr key={appointment.appointmentId}>
                            <td>
                              {appointment.appointment_status === "booked" ? (
                                <Link
                                  to={{
                                    pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                    state: {
                                      name: this.state.appointments[i],
                                    },
                                  }}
                                >
                                  {appointment.name}
                                </Link>
                              ) : (
                                <Link
                                  to={{
                                    pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                    state: {
                                      name: this.state.appointments[i],
                                    },
                                  }}
                                >
                                  {appointment.name}
                                </Link>
                              )}
                            </td>
                            <td>{appointment.doctorName}</td>
                            <td>
                              {appointment.appointmentType === "1"
                                ? "Physical"
                                : "Video"}
                            </td>
                            <td>
                              {appointment.slot_date}
                              <br />
                              {appointment.slot_time}
                            </td>
                            <td>
                              <span className="text-capitalize">
                                {appointment.appointment_status}
                              </span>
                            </td>
                            <td className="text-center mr-6">
                              <div className="dropdown dropdown-action">
                                <a
                                  href="#!"
                                  className="action-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    to={{
                                      pathname: `/receptionist/view-appointment/${appointment.appointmentId}`,
                                      state: {
                                        name: this.state.appointments[i],
                                      },
                                    }}
                                    className="dropdown-item"
                                  >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    View Only
                                  </Link>
                                  {appointment.appointment_status ===
                                    "booked" ? (
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname:
                                          "/receptionist/edit-appoinment",

                                        state: {
                                          name: appointment,
                                        },
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </Link>
                                  ) : (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => e.preventDefault()}
                                      to={{
                                        pathname:
                                          "/receptionist/edit-appoinment",

                                        state: {
                                          name: appointment,
                                        },
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </Link>
                                  )}

                                  <button
                                    className="dropdown-item"
                                    href="#!"
                                    disabled={
                                      appointment.appointment_status ===
                                        "cancelled" ||
                                        appointment.appointment_status ===
                                        "completed"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        readOnly: false,
                                        show: true,
                                      });
                                      this.handleDeleteOpen(e, appointment);
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </div>
          </div>
          <Modal
            backdrop="static"
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>Appointment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Patient Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="name"
                      value={this.state.currentAppointment.name}
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Appointment Type <span className="text-danger">*</span>
                    </label>
                    <input
                      name="appointmenttype"
                      value={
                        this.state.currentAppointment.appointmentType == "1"
                          ? "Physical"
                          : "Video"
                      }
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Time</label>
                    <div className="time-icon">
                      <input
                        name="time"
                        value={this.state.currentAppointment.slot_time}
                        readOnly
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Patient Phone Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      name="phonenumber"
                      value={this.state.currentAppointment.phoneno}
                      readOnly
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Patient Email</label>
                    <input
                      className="form-control"
                      value={this.state.currentAppointment.email}
                      type="email"
                      name="patientemail"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Chief Complaints</label>
                    <textarea
                      readOnly={this.state.readOnly}
                      name="chiefComplaints"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.chiefComplaints || ""
                      }
                    >
                      {this.state.currentAppointment.chiefComplaints || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Relevant points from history</label>
                    <textarea
                      readOnly={this.state.readOnly}
                      name="pointsFromHistory"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.pointsFromHistory || ""
                      }
                    >
                      {this.state.currentAppointment.pointsFromHistory || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Examination / Lab Findings</label>
                    <textarea
                      readOnly={this.state.readOnly}
                      name="labFindings"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={this.state.currentAppointment.labFindings || ""}
                    >
                      {this.state.currentAppointment.labFindings || ""}
                    </textarea>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Suggested Investigations</label>
                    <textarea
                      readOnly={this.state.readOnly}
                      name="suggestedInvestigations"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.suggestedInvestigations ||
                        ""
                      }
                    >
                      {this.state.currentAppointment.suggestedInvestigations ||
                        ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Preferred Pharmacy</label>
                    <textarea
                      readOnly={this.state.readOnly}
                      name="preferredPharmacy"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.preferredPharmacy
                          ? this.state.currentAppointment.preferredPharmacy.name
                          : "No Preferred Pharmacy"
                      }
                    >
                      {this.state.currentAppointment.preferredPharmacy
                        ? this.state.currentAppointment.preferredPharmacy.name
                        : "No Preferred Pharmacy"}
                    </textarea>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleClose()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.show}
            onHide={() =>
              this.setState({
                show: false,
              })
            }
            animation={false}
          >
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure, You want to cancel Patient appointment
              {this.state.currentAppointment ? (
                <strong> {this.state.currentAppointment.name}</strong>
              ) : null}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() =>
                  this.deleteAppointment(this.state.currentAppointment)
                }
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={() => this.setState({ show: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
