import React, { useEffect } from "react";
import "./Testimonial.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Doctor1 from "../../assets/img/doctor_1.jpg";
import Doctor2 from "../../assets/img/doctor_2.jpeg";
import Doctor3 from "../../assets/img/doctor_3.jpeg";
import Doctor4 from "../../assets/img/doctor_4.jpeg";

const Testimonial = () => {
  return (
    <>
      <section class="testimonial text-center">
        <div class="container">
          <div class="heading">Testimonial</div>
          <div
            id="testimonial4"
            class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            data-ride="carousel"
            data-pause="hover"
            data-interval="5000"
            data-duration="2000"
          >
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="testimonial4_slide">
                  <img src={Doctor1} class="img-circle img-responsive" />
                  <p>
                    Medvatic Doctor application is effortless and handy,
                    offering a focused approach to better patient management
                    with a video consultation facility.
                  </p>
                  <h4>
                    Dr.K.N.N. PAI, MBBS, MD Senior Consultant: Physician &
                    Cardiologist at Sagar Hospitals"
                  </h4>
                </div>
              </div>
              <div class="carousel-item">
                <div class="testimonial4_slide">
                  <img src={Doctor2} class="img-circle img-responsive" />
                  <p>
                    In this perfect world where medical technologies emerge day
                    by day, we need to give special attention to the patients,
                    for this Medvatic application is perfect. I can manage my
                    patients on the go. Update patient history; writing
                    medication is pretty straightforward.
                  </p>
                  <h4>
                    Dr Amith. R. MD (Int Med), MD (Cardiology) Consultant
                    Cardiologist Asst.Professor Jayadeva Hospital Bangalore.
                  </h4>
                </div>
              </div>
              <div class="carousel-item">
                <div class="testimonial4_slide">
                  <img src={Doctor3} class="img-circle img-responsive" />
                  <p>
                    The application is easy to use, and Scheduling appointment
                    is easy that help to manage patients better. I can prescribe
                    drugs and schedule dosage made very easy, which avoids
                    patients misunderstanding the timing of taking medication
                    and probably not missing any medication. Miss medication is
                    the real challenge that we face in our day to day practice,
                    and this application serve it better. It’s also offers video
                    consultation features that is a good one.
                  </p>
                  <h4>
                    Dr.Anusha Buchade MD.,DM Asst.Prof.Jaydeva Hospital
                    Bangalore
                  </h4>
                </div>
              </div>
              <div class="carousel-item">
                <div class="testimonial4_slide">
                  <img src={Doctor4} class="img-circle img-responsive" />
                  <p>
                    As a doctor, I serve humanity. Our main objective is to
                    serve our patients better, and we need to provide quality
                    service and undivided attention to them. Medvatic doctor
                    application is perfect for that. It helps to manage my
                    patients easily, update patient information, and schedule
                    medication more efficiently. Thanks to medvatic doctor app.
                  </p>
                  <h4>
                    Dr L. Sridhar MD (Med), DM (Cardio) FICC Professor of
                    Interventional Cardiologist Jaydeva Hospital Bangalore
                  </h4>
                </div>
              </div>
            </div>

            {/* <span class="carousel-control-prev-icon"></span> */}
            <ArrowBackIosIcon
              class="carousel-control-prev icon-size"
              href="#testimonial4"
              data-slide="prev"
            />

            {/* <span class="carousel-control-next-icon"></span> */}
            <ArrowForwardIosIcon
              class="carousel-control-next icon-size"
              href="#testimonial4"
              data-slide="next"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
