import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AutoSuggest from "./AutoSuggest";
import BottomContainer from "../Pharmacy/BottomContainer";
import QAComponent from "../Pharmacy/QAComponent";
import Apilib from "../../api";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader";
import { TrainRounded } from "@material-ui/icons";

function LabTest(props) {
  const [medicineList, setmedicineList] = useState([{}])
  const [LabTests, setLabTests] = useState(null)
  const [loading, setloading] = useState(false)
  const faqs = [

    {
      question: 'What services do you offer for online lab tests?', answer: 'We offer a comprehensive range of lab tests, including blood tests, diagnostic imaging, health screenings, and more.You can conveniently schedule and receive your tests from the comfort of your home.'
    },
    {
      question: 'How do I order a lab test online?', answer: 'Ordering a lab test is easy.Simply browse our website, select the test you need, add it to your cart, and proceed to checkout.Follow the instructions to provide necessary details, and your test kit will be shipped to your doorstep'
    },

    {
      question: 'Can I trust the accuracy of online lab results?', answer: 'Yes, our online lab tests are conducted in certified laboratories with a reputation for accuracy and reliability.We adhere to stringent quality standards to ensure the precision of your results.'

    },
    {
      question: 'How long does it take to get my lab results?', answer: 'The turnaround time for lab results varies depending on the type of test.In general, you can expect to receive your results securely online within a few business days after the lab receives your sample.'

    },
    {
      question: 'Is my personal information kept confidential?', answer: 'We prioritize the security and confidentiality of your personal information.Your data is handled with the utmost care, and we strictly adhere to privacy regulations to protect your privacy.'

    },
    {
      question: "Do I need a doctor's prescription to order a lab test online?", answer: 'In many cases, no prescription is required.However, certain tests may have specific requirements.You can check the details on our website or consult with our medical team for guidance.'

    },
    {
      question: 'Can I schedule a lab test for someone else?', answer: "Yes, you can schedule a lab test for someone else.During the ordering process, you'll have the option to provide the necessary details for the individual who will be taking the test."

    },
    {
      question: 'How are lab test samples collected at home?', answer: 'Sample collection is simple and can be done at home.Detailed instructions will be provided with your test kit.Samples, such as blood or saliva, can be collected following the provided guidelines and shipped back to the lab'
    },

    {
      question: 'What payment options are available for online lab tests?', answer: 'We accept various payment methods, including credit cards, debit cards, and other secure online payment options.Your payment information is handled with industry - standard security measures.'

    },
    {
      question: 'Can I cancel or reschedule my lab test appointment?', answer: 'Yes, you can cancel or reschedule your lab test appointment.Please log in to your account or contact our customer support for assistance with any changes to your scheduled tests.'

    },
    {
      question: 'Do you offer expedited or express testing services?', answer: 'Yes, we provide options for expedited testing services.You can choose faster processing times during the checkout process if you need your results sooner.'

    },
    {
      question: 'Are the online lab tests covered by insurance?', answer: 'While we do not directly process insurance claims, you can check with your insurance provider to inquire about reimbursement for the cost of online lab tests.We provide detailed receipts for your convenience.'

    },
    {
      question: 'What precautions are in place for COVID-19 testing?', answer: 'For COVID- 19 testing, we follow strict safety protocols to ensure the safety of our customers and staff.Test kits are designed for at - home collection, minimizing the need for in -person contact.'

    },
    {
      question: 'Can I speak with a healthcare professional about my lab results?', answer: 'Yes, our team of healthcare professionals is available to discuss your lab results and answer any questions you may have.You can schedule a consultation through our platform.'

    },
    {
      question: 'What happens if my sample is insufficient for testing?', answer: 'In the rare event that a sample is insufficient for testing, our team will reach out to provide guidance on the next steps.We may offer a replacement kit or suggest alternative testing options.'
    }
  ];
  const history = useHistory()
  // {discount: '25% OFF', checkupTitle: 'Comprehensive Full Body Checkup With Vitamin D & B12.', discountedPrice: '$39.99', price: '$45.55', checkUpSubtitle: 'Includes Fasting Blood Sugar (FBS), HbA1c (Glycosylated Hemoglobin), Vitamin B12+ 87 Tests', id: 'labId', checkUpId: 'checkupId',},
  useEffect(() => {
    let API = new Apilib();
    setloading(TrainRounded)
    API.getLabTests({})
      .then(async (res) => {
        console.log(res)
        console.log('this is response')
        console.log('this is response', res.data.labtests)
        setLabTests(res.data.labtests)
        setloading(false)
        // medicineList
      })
      .catch((error) => {
        setloading(false)
        console.log('error', error)
      });
  }, []);
  return (
    <div>
      {loading && <Loader />}
      {!loading && <Div>
        <Div25>
          <Div26>
            {medicineList && <AutoSuggest userList={medicineList} placeHolderText={'wow'} />}
            {medicineList && <div style={{ color: '#fff', padding: '8px', borderRadius: '5px', backgroundColor: '#41d9ff', width: '100px', cursor: 'pointer', textAlign: 'center', alignSelf: 'center' }}>Search</div>}
          </Div26>
          {/* <Div31>
            <Div32>
              {[{ text: 'All Tests', icons: 'flask', imageLink: 'https://cdn.builder.io/api/v1/image/assets/TEMP/846cf67b-dd20-41c1-98d0-3280ff7687e0?apiKey=04f85002d68744bd9623fe1bdb31055b&', link: 'labtest' }, { text: 'Health Package', icons: 'heart', imageLink: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4cf3954e-542d-4e21-947c-a2fa4baef8d7?apiKey=04f85002d68744bd9623fe1bdb31055b&', link: 'labtest' }, { text: 'Upload Prescription', icons: 'newspaper-o', imageLink: 'https://cdn.builder.io/api/v1/image/assets/TEMP/2b44dcba-e996-4198-91cd-6b08f587af5c?apiKey=04f85002d68744bd9623fe1bdb31055b&', link: 'labtest' }, { text: 'Book on call', icons: 'phone', imageLink: 'https://cdn.builder.io/api/v1/image/assets/TEMP/658fe1c7-ac41-46ae-99b3-e04931b75607?apiKey=04f85002d68744bd9623fe1bdb31055b&', link: 'Contact' },].map(x => <Column onClick={() => history.push(`/${x.link}`)}>
                <Div33>
                  <Div34>
                    {/* <Img13
                    loading="lazy"
                    src={x.imageLink}
                  /> *  /}
                    <i class={`fa fa-${x.icons}`} aria-hidden="true" style={{ color: '#fff' }}></i>
                    <Div35>{x.text}</Div35>
                  </Div34>
                </Div33>
              </Column>)}

            </Div32>
          </Div31> */}
          <Div44>
            <Div45>Frequently Booked Lab Tests</Div45>
            <Img19
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c8684cc-f378-4008-9672-09d1e4c050da?apiKey=04f85002d68744bd9623fe1bdb31055b&"
            />
          </Div44>
          <Div46>
            <Div47>


              {LabTests && LabTests.map(x => {
                if (x.price < 800) return null

                else return <Column6>
                  <Div57>
                    <div>
                      <Div59>{x.discount ? x.discount : '5%'}</Div59>
                      <Div60>{x.testName}</Div60>
                      <Div61>
                        {x.expectedResults}
                      </Div61>
                      <Div62>{x.price}</Div62>
                      <Div63>
                        <Div64>{x.discountedPrice ? x.discountedPrice : (x.price - x.price * .05)}</Div64>
                        <Div65 onClick={() => history.push(`/LabTestDetails/${x.testCode}`)}>{'Book Now >>'}</Div65>
                      </Div63>
                    </div>

                  </Div57>
                </Column6>
              })}

            </Div47>
          </Div46>
          <Div84>
            <Div85>Affordable Health Packages</Div85>
            <Div86>View All</Div86>
          </Div84>
          <Div87>
            <Div88>
              {LabTests && LabTests.map(x => {
                if (x.price >= 800) return null
                else return <Column6>
                  <Div57>
                    <div>
                      <Div59>{x.discount ? x.discount : '5%'}</Div59>
                      <Div60>{x.testName}</Div60>
                      <Div61>
                        {x.expectedResults}
                      </Div61>
                      <Div62>{x.price}</Div62>
                      <Div63>
                        <Div64>{x.discountedPrice ? x.discountedPrice : (x.price - x.price * .05)}</Div64>
                        <Div65 onClick={() => history.push(`/LabTestDetails/${x.testCode}`)}>{'Book Now >>'}</Div65>
                      </Div63>
                    </div>

                  </Div57>
                </Column6>
              })}
            </Div88>
          </Div87>
          <Div113>Uncompromised Quality & Reliable Results</Div113>
          <Div114>
            <Div115>
              <Column13>
                <Div116>
                  In the dynamic landscape of healthcare, where precision and reliability are paramount, our webpage emerges as a transformative force, redefining the standards of diagnostic services. With an unwavering commitment to "Uncompromised Quality & Reliable Results," we stand as a beacon of excellence, leveraging cutting-edge technology and a patient-centric approach to shape the future of healthcare diagnostics. <br />
                  <br />
                  At the core of our mission is a steadfast commitment to excellence. We set the gold standard for quality, adhering to the most rigorous industry regulations and embracing the latest advancements in diagnostic technologies. Every test conducted under our banner is a testament to our dedication to providing the highest level of accuracy and reliability in healthcare diagnostics. <br />
                  <br />
                  As healthcare evolves, so do we. Our commitment to a future-forward approach is reflected in our regular updates, incorporation of emerging technologies, and a relentless pursuit of continuous improvement. We envision a future where healthcare diagnostics are not just a service but a proactive and integral part of an individual's journey toward well-being.
                </Div116>
              </Column13>
              {/* <Column14>
              <Div117>
                <Img25
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c13fc16-11b5-4dab-8696-ea5da17bc7d5?apiKey=04f85002d68744bd9623fe1bdb31055b&"
                />
              </Div117>
            </Column14> */}
            </Div115>
          </Div114>
          <Div118>

          </Div118>
          <Div119>

            {faqs.map((faq, index) => (
              <QAComponent key={index} question={faq.question} answer={faq.answer} />
            ))}
          </Div119>
        </Div25>
        <Div306>
          <BottomContainer />
        </Div306>
      </Div>}
    </div>
  );
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Div306 = styled.div`
  background-color: #effdff;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 37px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const Div2 = styled.div`
  background-color: #00869a;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1413px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Div4 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 22px;
  overflow: hidden;
  max-width: 100%;
`;

const Div5 = styled.div`
  color: #fff;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div6 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const Img2 = styled.img`
  aspect-ratio: 1.04;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  max-width: 100%;
`;

const Div7 = styled.div`
  color: #fff;
  align-self: start;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div8 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const Div9 = styled.div`
  color: #fff;
  margin: auto 0;
  font: 500 19px/22px Roboto, sans-serif;
`;

const Img3 = styled.img`
  aspect-ratio: 3.14;
  object-fit: contain;
  object-position: center;
  width: 66px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div10 = styled.div`
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 11px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div11 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1417px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 2.99;
  object-fit: contain;
  object-position: center;
  width: 221px;
  overflow: hidden;
`;

const Div12 = styled.div`
  align-self: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Div13 = styled.div`
  color: #005562;
  align-self: center;
  margin: auto 0;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div14 = styled.div`
  color: #005562;
  align-self: center;
  margin: auto 0;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div15 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 11px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div16 = styled.div`
  color: #005562;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 44.5;
  object-fit: contain;
  object-position: center;
  width: 89px;
  stroke-width: 1.7px;
  stroke: #26d4ff;
  overflow: hidden;
  align-self: center;
  margin-top: 6px;
`;

const Div17 = styled.div`
  color: #005562;
  margin: auto 0;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div18 = styled.div`
  color: #005562;
  margin: auto 0;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div19 = styled.div`
  color: #005562;
  align-self: center;
  margin: auto 0;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div20 = styled.div`
  color: #005562;
  margin: auto 0;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 35px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 35px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Img8 = styled.img`
  aspect-ratio: 0.92;
  object-fit: contain;
  object-position: center;
  width: 35px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div21 = styled.div`
  background-color: #f5f5f5;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 31px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div22 = styled.div`
  display: flex;
  margin-left: 235px;
  width: 144px;
  max-width: 100%;
  gap: 6px;
  @media (max-width: 991px) {
    margin-left: 10px;
    justify-content: center;
  }
`;

const Div23 = styled.div`
  color: #454545;
  align-self: start;
  font: 500 18px/21px Roboto, sans-serif;
`;

const Img9 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  overflow: hidden;
  max-width: 100%;
`;

const Div24 = styled.div`
  color: #26d4ff;
  align-self: start;
  white-space: nowrap;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div25 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 50px;
  width: 100%;
  max-width: 1410px;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div26 = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
  background-color: #f6fdff;
  align-self: start;
  display: flex;
  max-width: 100%;
  padding-left: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const Div27 = styled.div`
  display: flex;
  gap: 11px;
  margin: auto 0;
`;

const Img10 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 17px;
  fill: #b3b3b3;
  overflow: hidden;
  max-width: 100%;
`;

const Div28 = styled.div`
  color: #b3b3b3;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div29 = styled.div`
  disply: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  white-space: nowrap;
  fill: #26d4ff;
  overflow: hidden;
  align-self: stretch;
  aspect-ratio: 2.1666666666666665;
  width: 104px;
  align-items: center;
  padding: 18px 20px;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img11 = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div30 = styled.div`
  position: relative;
`;

const Div31 = styled.div`
  align-self: stretch;
  margin: 50px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div32 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0px;
  cursor:pointer;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div33 = styled.div`
  border-radius: 5px;
  background-color: #00869a;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Div34 = styled.div`
  disply: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items:center;
  gap: 15px;
  padding: 46px 69px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Img12 = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img13 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  max-width: 100%;
`;

const Div35 = styled.div`
  position: relative;
  color: #fff;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 300 24px/32px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div36 = styled.div`
  border-radius: 5px;
  background-color: #00869a;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Div37 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 2.5;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 28px 67px 28px 68px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Img14 = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img15 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 45px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Div38 = styled.div`
  position: relative;
  color: #fff;
  font: 500 36px/42px Roboto, sans-serif;
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div39 = styled.div`
  border-radius: 5px;
  background-color: #00869a;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Div40 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 2.5;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 28px 39px 28px 40px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Img16 = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img17 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 45px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Div41 = styled.div`
  position: relative;
  color: #fff;
  font: 500 36px/42px Roboto, sans-serif;
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div42 = styled.div`
  border-radius: 5px;
  background-color: #00869a;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin: 0 auto;
  padding: 46px 41px 46px 42px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img18 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  overflow: hidden;
  max-width: 100%;
`;

const Div43 = styled.div`
  color: #fff;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 500 36px/42px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div44 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 143px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const Div45 = styled.div`
  color: #041b1f;
  text-transform: uppercase;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 500 24px/30px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img19 = styled.img`
  aspect-ratio: 2.3;
  object-fit: contain;
  object-position: center;
  width: 115px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div46 = styled.div`
  align-self: stretch;
  margin: 50px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div47 = styled.div`
  gap: 20px;
  display: flex;
  overflow-x: auto;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Div48 = styled.div`
  border-radius: 5px;
  background-color: #effdff;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 17px 0 17px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-left: 20px;
  }
`;

const Div49 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div50 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 19px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div51 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 23px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div52 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 21px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div53 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 9px;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
`;

const Div54 = styled.div`
  color: #26d4ff;
  margin-top: 11px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div55 = styled.div`
  disply: flex;
  flex-direction: column;
  fill: #00869a;
  overflow: hidden;
  align-self: stretch;
  position: relative;
  display: flex;
  aspect-ratio: 3.3513513513513513;
  width: 124px;
  justify-content: space-between;
  gap: 11px;
  padding: 12px 13px;
`;

const Img20 = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div56 = styled.div`
  position: relative;
  color: #fff;
  font: 500 18px/21px Roboto, sans-serif;
`;

const Img21 = styled.img`
  aspect-ratio: 0.58;
  object-fit: contain;
  object-position: center;
  width: 7px;
  fill: #fff;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div57 = styled.div`
  border-radius: 5px;
  background-color: #fff8ee;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
  margin: 0 auto;
  padding: 19px 13px 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-left: 20px;
  }
`;

const Div59 = styled.div`
  color: #fff;
  width:100%;
  text-align:center;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div60 = styled.div`
  color: #4e4e4e;
  margin-top: 19px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div61 = styled.div`
  color: #5d5d5d;
  margin-top: 23px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div62 = styled.div`
  color: #737373;
  text-decoration: line-through;
  margin-top: 49px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div63 = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  gap: 20px;
`;

const Div64 = styled.div`
  color: #26d4ff;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div65 = styled.div`
  color: #00869a;
  align-self: start;
  white-space: nowrap;
  cursor:pointer;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img22 = styled.img`
  aspect-ratio: 0.58;
  object-fit: contain;
  object-position: center;
  width: 7px;
  fill: #00869a;
  align-self: end;
  margin-top: 260px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div66 = styled.div`
  border-radius: 5px;
  background-color: #effdff;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 19px 13px 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-left: 20px;
  }
`;

const Div67 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div68 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 19px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div69 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 24px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div70 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 36px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div71 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

const Div72 = styled.div`
  color: #26d4ff;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div73 = styled.div`
  align-self: start;
  display: flex;
  gap: 11px;
`;

const Div74 = styled.div`
  color: #00869a;
  font: 500 18px/21px Roboto, sans-serif;
`;

const Img23 = styled.img`
  aspect-ratio: 0.58;
  object-fit: contain;
  object-position: center;
  width: 7px;
  fill: #00869a;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div75 = styled.div`
  border-radius: 5px;
  background-color: #fff8ee;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 2px;
  width: 100%;
  margin: 0 auto;
  padding: 19px 13px 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-left: 20px;
  }
`;

const Div77 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div78 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 19px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div79 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 19px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div80 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 36px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div81 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  gap: 20px;
`;

const Div82 = styled.div`
  color: #26d4ff;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div83 = styled.div`
  color: #00869a;
  align-self: start;
  white-space: nowrap;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img24 = styled.img`
  aspect-ratio: 0.58;
  object-fit: contain;
  object-position: center;
  width: 7px;
  fill: #00869a;
  overflow: hidden;
  align-self: end;
  margin-top: 260px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div84 = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 150px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const Div85 = styled.div`
  color: #041b1f;
  text-transform: uppercase;
  flex-grow: 1;
  flex-basis: auto;
  font: 500 36px/42px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div86 = styled.div`
  color: #26d4ff;
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div87 = styled.div`
  align-self: stretch;
  margin: 59px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div88 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Div89 = styled.div`
  border-radius: 5px;
  background-color: #effdff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div90 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div91 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 19px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div92 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 23px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div93 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 21px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div94 = styled.div`
  color: #26d4ff;
  align-self: stretch;
  margin-top: 20px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column10 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div95 = styled.div`
  border-radius: 5px;
  background-color: #fff8ee;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 19px 55px 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div96 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div97 = styled.div`
  color: #4e4e4e;
  margin-top: 19px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div98 = styled.div`
  color: #5d5d5d;
  margin-top: 23px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div99 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  margin-top: 49px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div100 = styled.div`
  color: #26d4ff;
  margin-top: 20px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column11 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div101 = styled.div`
  border-radius: 5px;
  background-color: #effdff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div102 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div103 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 19px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div104 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 24px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div105 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 36px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div106 = styled.div`
  color: #26d4ff;
  align-self: stretch;
  margin-top: 20px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column12 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div107 = styled.div`
  border-radius: 5px;
  background-color: #fff8ee;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 19px 22px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div108 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  padding: 10px 0;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div109 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 19px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div110 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 19px;
  font: 400 16px/19px Roboto, sans-serif;
`;

const Div111 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 36px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div112 = styled.div`
  color: #26d4ff;
  align-self: stretch;
  margin-top: 20px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div113 = styled.div`
  color: #041b1f;
  text-transform: capitalize;
  align-self: stretch;
  white-space: nowrap;
  margin: 106px -20px 0 0;
  font: 500 36px/42px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div114 = styled.div`
  align-self: stretch;
  margin: 33px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div115 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column13 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 96%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div116 = styled.div`
  color: #5d5d5d;
  margin-top: 4px;
  font: 400 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 34px;
  }
`;

const Column14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 24%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div117 = styled.div`
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Img25 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 110px;
  overflow: hidden;
  max-width: 100%;
  margin: 74px 0 49px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const Div118 = styled.div`
  color: #041b1f;
  align-self: stretch;
  white-space: nowrap;
  margin: 99px -20px 0 0;
  font: 700 36px/34px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div119 = styled.div`
  border-radius: 6px;
  box-shadow: 5px 8px 16px 0px rgba(0, 0, 0, 0.08),
    -4px -5px 14px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  align-self: start;
  margin-top: 45px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const Div120 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Img26 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #2b2540;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div121 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: start;
  margin-top: 10px;
  width: 916px;
  max-width: 100%;
  font: 400 16px/27px Roboto, sans-serif;
`;

const Div122 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 35px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div123 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img27 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div124 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div125 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img28 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div126 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div127 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img29 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div128 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div129 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Img30 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div130 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div131 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img31 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div132 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div133 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img32 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div134 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div135 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Img33 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div136 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div137 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img34 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div138 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div139 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img35 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div140 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div141 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Img36 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div142 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div143 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Img37 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div144 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div145 = styled.div`
  color: #2b2540;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Img38 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div146 = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div147 = styled.div`
  color: #2b2540;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img39 = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 14px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div148 = styled.div`
  background-color: #effdff;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 37px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div149 = styled.div`
  width: 100%;
  max-width: 1410px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div150 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Div151 = styled.div`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  align-items: start;
  justify-content: space-between;
  gap: 17px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 48px 40px 21px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img40 = styled.img`
  aspect-ratio: 1.24;
  object-fit: contain;
  object-position: center;
  width: 42px;
  overflow: hidden;
  margin-top: 4px;
  max-width: 100%;
`;

const Div152 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div153 = styled.div`
  color: #0a0d14;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div154 = styled.div`
  color: #656565;
  margin-top: 9px;
  white-space: nowrap;
  font: 400 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column16 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div155 = styled.div`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin: 0 auto;
  padding: 39px 59px 39px 37px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img41 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  max-width: 100%;
`;

const Div156 = styled.div`
  align-self: start;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div157 = styled.div`
  color: #0a0d14;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div158 = styled.div`
  color: #656565;
  margin-top: 11px;
  white-space: nowrap;
  font: 400 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column17 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div159 = styled.div`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin: 0 auto;
  padding: 39px 50px 39px 19px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-right: 20px;
  }
`;

const Img42 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  max-width: 100%;
`;

const Div160 = styled.div`
  align-self: start;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div161 = styled.div`
  color: #0a0d14;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div162 = styled.div`
  color: #656565;
  margin-top: 11px;
  white-space: nowrap;
  font: 400 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column18 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div163 = styled.div`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  align-items: start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
  padding: 39px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img43 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  overflow: hidden;
  max-width: 100%;
`;

const Div164 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div165 = styled.div`
  color: #0a0d14;
  white-space: nowrap;
  font: 500 20px/30px Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div166 = styled.div`
  color: #656565;
  margin-top: 12px;
  white-space: nowrap;
  font: 400 14px/21px Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div167 = styled.div`
  background-color: #f6f6f6;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div168 = styled.div`
  width: 100%;
  max-width: 1410px;
  margin: 10px 0 14px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div169 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column19 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 45%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div170 = styled.div`
  margin-top: 20px;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div171 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column20 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 46%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div173 = styled.div`
  color: #000;
  font: 500 35px/41px Roboto, sans-serif;
`;

const Div174 = styled.div`
  color: #000;
  margin-top: 23px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div175 = styled.div`
  color: #000;
  margin-top: 24px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div176 = styled.div`
  color: #000;
  margin-top: 25px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div177 = styled.div`
  color: #000;
  margin-top: 25px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Column21 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 54%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div178 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div179 = styled.div`
  color: #000;
  font: 500 35px/41px Roboto, sans-serif;
`;

const Div180 = styled.div`
  color: #000;
  margin-top: 23px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div181 = styled.div`
  color: #000;
  margin-top: 20px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div182 = styled.div`
  color: #000;
  margin-top: 20px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div183 = styled.div`
  color: #000;
  margin-top: 21px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div184 = styled.div`
  color: #000;
  margin-top: 24px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Column22 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 32%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div185 = styled.div`
  display: flex;
  margin-top: 19px;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div186 = styled.div`
  color: #000;
  font: 500 35px/41px Roboto, sans-serif;
`;

const Div187 = styled.div`
  color: #000;
  margin-top: 30px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div188 = styled.div`
  color: #000;
  margin-top: 20px;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Column23 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 23%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img44 = styled.img`
  aspect-ratio: 1.76;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div189 = styled.div`
  color: #fff;
  max-width: 401px;
  background-color: #006878;
  align-self: stretch;
  width: 100%;
  align-items: center;
  padding: 25px 20px;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;



export default LabTest;