import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import { storage } from "../../firebase";
import "../Patient/FamilyAppointment.css";

export default function FamilyAppointment(props) {
  const [loading, setLoding] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    console.log("timetable details", props);

    if (props.location.state === undefined || null) {
      props.history.push("/");
    } else {
      console.log("Family details", props.location.state);
      setUserDetails(props.location.state.user);
    }
  }, []);

  useEffect(() => {
    if (userDetails) {

      let { userData } = props;
      let API = new Apilib();
      API.listSinglePatientAppointments(
        userData.hospitalId,
        userData.uid,
        props.location.state.user.uid
      )
        .then((result) => {
          let res = result.data

          console.log("Appointment1234", res.appointments);

          setAppointments([...res.appointments]);
        })
        .catch((error) => { });
    }
  }, [userDetails]);

  return (
    <>
      <div className="all-members">


        <div className="row">
          <div className="col-sm-12">
            {appointments.length ? (
              <h3 className="page-title"> <b>Appointments </b></h3>
            ) : (
              <h3 className="page-title"> <b> No Appointments Found </b></h3>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 patient-cards">
            {appointments.map((appointment, i) => (
              <Link
                // to={{
                //   pathname:
                //     appointment.appointment_status === "completed"
                //       ? `/view-appointment/${appointment.appointmentId}`
                //       : "/edit-appointment",
                //   state: {
                //     name: appointments[i],
                //   },
                // }}
                className="card mb-3"
              >
                <div className="row">

                  <div className="date col-md-1">

                    <p>{appointment.slot_date}</p>
                  </div>
                  <div className="col-md-10">
                    <div className="card-body">
                      <div className="d-md-flex justify-content-between">
                        <h5 className="card-title mb-3">
                          <h3> {appointment.doctorName}</h3>
                        </h5>
                        <h5 className="appointment_status">
                          {appointment.appointment_status}
                        </h5>
                      </div>

                      <p className="appointment-time">
                        <small className="text-muted">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          {appointment.slot_time}
                        </small>{" "}
                        <br />
                      </p>
                      <p className="card-text">
                        <small>
                          <i
                            className="fa-blue fa fa-envelope"
                            aria-hidden="true"
                          ></i>
                          {appointment.doctor_email}
                        </small>{" "}
                        <br />
                      </p>
                      <div className="card-text"></div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
