import React from 'react';

const Appointment = () => {
    return (
        <div>
            We are currently working on this.
        </div>
    );
}

export default Appointment;
