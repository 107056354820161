import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Modal } from "react-bootstrap";
import RequiredSelect from "../../Helpers/requiredSelect";
import BaseSelect from "react-select/creatable";
import Apilib from "../../api";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Notify from "../../Notify";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const Pharmacy = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preferredPharmacyId, setPreferredPharmacyId] = useState("");
  const [preferredPharmacies, setPreferredPharmacies] = useState([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [statePharmacies, setStatePharmacies] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState("");
  const [pharmacy, setPharmacy] = useState("");

  useEffect(() => {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(true);
        API.getUserDetails(user.displayName, user.uid)
          .then((result) => {
            let res = result.data

            console.log("ressss", res.user);
            setLoading(false);
            setUserDetails(res.user);
            setPharmacy(res?.user?.preferredPharmacy.name);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });

        API.getPharmacies()
          .then((result) => {
            let res = result.data

            console.log("Pharm data", res.data);
            setStatePharmacies(res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        // history.push("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.preferredPharmacies) {
        setPreferredPharmacies(userDetails?.preferredPharmacies);

        let tempArr = userDetails?.preferredPharmacies;
        if (tempArr[0] === undefined) {
          tempArr[0] = { label: '', value: '' }
          return
        }
        tempArr[0].label = tempArr[0]?.name;
        tempArr[0].value = tempArr[0]?.name;

        setSelectedPharmacies(tempArr);
      }

    }
  }, [userDetails]);

  // useEffect(() => {
  //   if (userDetails?.preferredPharmacies.length > 0) {
  //     for (let key of preferredPharmacies) {
  //       console.log("Keys", key);
  //       if (
  //         userDetails?.preferredPharmacy?.name &&
  //         key.name === userDetails?.preferredPharmacy?.name
  //       ) {
  //         setPreferredPharmacyId(parseInt(key.pharmacyId));
  //       }
  //     }
  //     console.log(preferredPharmacyId);
  //   }
  // }, [preferredPharmacies]);

  const onSelectPharmacy = (val) => {
    let formObj = { ...userDetails };
    let preferredPharmaciesMenu = [];

    if (val && val.length) {
      val.map((pharm, i) => {
        preferredPharmaciesMenu.push(pharm);
      });
    }

    setSelectedPharmacies(preferredPharmaciesMenu);
    setPreferredPharmacies(preferredPharmaciesMenu);
    formObj["preferredPharmacies"] = preferredPharmaciesMenu;
    setUserDetails(formObj);
  };

  const onChange = (e) => {
    let inputName = e.target.name;
    let data = { ...userDetails };
    let value = "";

    if (inputName === "preferredPharmacy") {
      if (preferredPharmacies.length > 0) {
        console.log("target", e.target.value);
        for (let key of preferredPharmacies) {
          if (parseInt(key.pharmacyId) === e.target.value) {
            value = key;
            setPreferredPharmacyId(e.target.value);
          }
        }
      }
    }

    if (inputName === "preferredPharmacy") {
      data[inputName] = value;
      setUserDetails(data);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    let API = new Apilib();
    let data = {
      ...userDetails,
      type: 5,
      uid: userDetails.uid,
    };
    // delete data.loading;
    // delete data.error;
    // delete data.success;
    API.updateUser(JSON.stringify(data))
      .then((res) => {
        setLoading(false);
        console.log(res)
        console.log(typeof res, 'this is res and', res,)
        if (res.status == 200) {
          setLoading(false);
          setUserDetails(null);
          setError("");
          Notify({
            alert: true,
            type: "success",
            title: "Pharmacy updated successfully.",
          });
          auth.onAuthStateChanged((user) => {
            if (user) {
              setLoading(true);
              API.getUserDetails(user.displayName, user.uid)
                .then((result) => {
                  let res = result.data

                  setLoading(false);
                  setUserDetails(res.user);
                  setPharmacy(res?.user?.preferredPharmacy.name);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
            }
          });
        } else {
          try {
            res.text().then((data) => {
              console.log(typeof res, 'this is res and', res,)
              setError('Could not update');
              setError(JSON.parse(data).message);
            });
          } catch (e) {
            console.log('error in updating', e)
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  let pharmaciesMenu = [];
  if (statePharmacies?.length > 0) {
    if (userDetails?.preferredPharmacy) {
      statePharmacies.filter((pharm) => {
        if (userDetails?.preferredPharmacy.pharmacyId === pharm.pharmacyId) {
          return false;
        } else {
          return true;
        }
      });
    }
    pharmaciesMenu = statePharmacies.map((pharm) => {
      return {
        ...pharm,
        value: pharm.name,
        label: pharm.name + (pharm.pharmacyAddrs ? ` - ${pharm.pharmacyAddrs}` : ""),
      };
    });
  }

  let preferredPharmacyMenu = null;
  if (preferredPharmacies?.length > 0) {
    preferredPharmacyMenu = preferredPharmacies.map((item, index) => {
      return (
        <MenuItem key={index + 1} value={parseInt(item.pharmacyId)}>
          {item.name}
          {item.pharmacyAddrs ? ` - ${item.pharmacyAddrs}` : ""}
        </MenuItem>
      );
    });
  }

  return (
    <div className="container" style={{ height: "80vh", width: '80vw' }}>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Pharmacy in Preference List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            required
            placeholder="Enter Pharmacies"
            onChange={(item) => onSelectPharmacy(item)}
            value={selectedPharmacies}
            options={pharmaciesMenu}
            noOptionsMessage={() => "Enter pharmacy and press enter"}
            isMulti={true}
            closeMenuOnSelect={false}
          />
        </Modal.Body>
      </Modal>
      {loading && <Loader />}
      {error && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Close</span>
          </button>
          {error}
        </div>
      )}
      <div className="mb-5 mt-4 row d-flex justify-content-center">
        <h3 className="text-center">My Pharmacy</h3>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <form
            onSubmit={(e) => {
              if (
                document.activeElement.classList.contains(
                  "eXEoGG26iwSi6UiZD8FI6"
                )
              ) {
                e.preventDefault();
                return;
              }
              onSubmit(e);
            }}
          >
            <div className="form-group">
              <label className="d-flex justify-content-between mb-3">
                <span style={{ fontSize: "20px" }}>
                  Pharmacy : <span>{pharmacy || "No Pharmacy selected"}</span>
                </span>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                  className="plus-icon"
                >
                  <i className="fa fa-plus"></i> Pharmacy
                </button>
              </label>
              <InputLabel id="select-label">
                Choose Preferred Pharmacy
              </InputLabel>
              <Select2
                labelId="select-label"
                className="border rounded"
                fullWidth
                required
                name="preferredPharmacy"
                onChange={(e) => onChange(e)}
                value={preferredPharmacyId || ""}
              >
                <MenuItem disabled={true} key="0" value={0}>
                  Please Select Pharmacy
                </MenuItem>
                {preferredPharmacyMenu}
              </Select2>
            </div>
            <div className="mt-5 mb-10 text-center ">
              <Link to="/my-profile">
                <button className="btn custom-btn btn-secondary mr-3">
                  Close
                </button>
              </Link>
              <button className="btn custom-btn btn-primary" type="submit">
                Update
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default Pharmacy;
