import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import Notify from "../../Notify";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { IconButton } from "@material-ui/core";
export default class PatientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      patients: [],
      deleteModal: false,
      currentPatient: {},
      familyData: {},
      currentIndex: "",
      modalOpen: false,
      familyPatients: [],
      familyPatient: {},
      familyPatientsData: [],
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listFamilyMembers(this.props.userData.uid)
           .then((result) => {
        let res = result.data

        if (this._isMounted) {
          let familyData = [];
          let data = res.family_members;
          data.map((member) => {
            API.getUserDetails(5, member.uid)
                    .then((result) => {
        let res = result.data

                familyData.push(res.user);
                console.log("Family whole data -> ", familyData);
              });
          });

          this.setState(
            {
              loading: false,
              familyPatients: [...data],
            },
            () => {
              console.log("Family data -> ", this.state.familyPatients);
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  order: [],
                  aoColumnDefs: [
                    {
                      bSortable: false,
                      aTargets: [-1] /* 1st one, start by the right */,
                    },
                  ],
                });
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  getFamilyMember(user) {
    let API = new Apilib();

    API.getUserDetails(5, user.uid)
           .then((result) => {
        let res = result.data

        console.log("ressss", res.user);
        this.setState({
          loading: false,
          familyData: res.user,
        });
      });
  }

  deleteMemberFamily(value) {
    console.log("Family", value, this.props);
    let data = {
      member_id: this.state.familyPatient.uid,
      uid: this.props.userData.uid,
    };
    console.log("Edited recep:::", data);
    let API = new Apilib();
    this.setState({
      loading: true,
    });
    API.deleteMemberFamily(JSON.stringify(data)).then((res) => {
      this.setState({
        show: false,
        familyPatients: [],
      });
      // if (res.ok) {
        Notify({
          title: "Family deleted successfully",
          alert: true,
          type: "success",
        });
        this.setState({
          loading: false,
          familyPatient: [],
          deleteModal: false,
          editedRecep: [],
        });
        this.componentDidMount();
      // } else {
      //   Notify({
      //     alert: true,
      //     type: "error",
      //     title: "Error occured",
      //   });
      //   this.setState({
      //     loading: false,
      //   });
      // }
    });
  }

  handleDeleteOpen(e, value) {
    e.preventDefault();
    console.log("Family Value", value);
    this.setState({
      // currentPatient: this.state.patients[index],
      familyPatient: value,
      // error: null,
      // success: false,
    });
  }

  handleOpen(e, userDetail) {
    e.preventDefault();
    this.setState(
      {
        modalOpen: true,
        error: null,
        success: false,
      },
      () => {
        this.getFamilyMember(userDetail);
      }
    );
  }
  handleClose() {
    this.setState({
      modalOpen: false,
      currentPatient: {},
      currentIndex: "",
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="all-members">
            <div className="row ">
              <div className="col-sm-4 col-3 head-family-acc">
                <h4 className="page-title">Family Members</h4>
                <Link to="/requests" className="dropdown-req req-alternate">
                  Requests
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email Id</th>
                        <th>Relation</th>
                        <th>Blood Group</th>
                        <th>Appointments</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.familyPatients &&
                        this.state.familyPatients.map((familyPatient) => (
                          <>
                            <tr>
                              <td>
                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: true,
                                    });
                                    this.handleOpen(e, familyPatient);
                                  }}
                                >
                                  {familyPatient.name}
                                </a>
                              </td>
                              <td>{familyPatient.email}</td>
                              <td>{familyPatient.relation}</td>
                              <td>
                                {familyPatient.bloodGroup == ""
                                  ? "N/A"
                                  : familyPatient.bloodGroup}
                              </td>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/familyAppointment`,
                                    state: {
                                      user: familyPatient,
                                    },
                                  }}
                                >
                                  <button className="btn custom-btn btn-primary">
                                    View Appointments
                                  </button>
                                </Link>
                              </td>

                              <td>
                                <button className="btn btn-small btn-secondary mr-2">
                                  <span
                                    class="iconify"
                                    data-icon="akar-icons:edit"
                                    data-inline="false"
                                    style={{
                                      marginRight: ".5em",
                                      marginTop: "-.1em",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  Edit
                                </button>
                                <button
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: false,
                                      show: true,
                                    });
                                    this.handleDeleteOpen(e, familyPatient);
                                  }}
                                  className="btn btn-danger btn-small mr-2"
                                >
                                  <span
                                    class="iconify"
                                    data-icon="ant-design:delete-twotone"
                                    data-inline="false"
                                    style={{
                                      marginRight: ".5em",
                                      marginTop: "-.1em",
                                      display: "inline-block",
                                    }}
                                  ></span>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Modal
            backdrop="static"
            show={this.state.show}
            onHide={() =>
              this.setState({
                show: false,
              })
            }
            animation={false}
          >
            <Modal.Header>
              <Modal.Title>Delete Family Member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure, You want to delete Family Member
              {this.familyPatient ? (
                <>
                  <strong>{" " + this.familyPatient.name + " "}</strong>
                </>
              ) : null}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => this.deleteMemberFamily(this.familyPatient)}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={() => this.setState({ show: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>View Patient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="firstName"
                      value={this.state.familyData?.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="lastName"
                      value={this.state.familyData?.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Phone No. <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="phoneno"
                      value={this.state.familyData?.phoneno}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="email"
                      value={this.state.familyData?.email}
                      
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Details</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="emergencyContactDetails"
                      value={this.state.familyData?.emergencyContactDetails}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="phone"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="bloodgroup"
                      value={this.state.familyData?.bloodgroup}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Height</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="height"
                      value={this.state.familyData?.height}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Weight</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="weight"
                      value={this.state.familyData?.weight}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label>Postal Code</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="pincode"
                      name="postalcode"
                      value={this.state.familyData?.postalcode}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>BMR</label>
                    <input
                    readOnly={this.state.readOnly}
                      name="bmr"
                      value={this.state.currentPatient.bmr}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div> */}

                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text-area"
                      name="gender"
                      value={this.state.familyData?.gender}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text-area"
                      name="address"
                      value={this.state.familyData?.address}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      name="country"
                      value={this.state.familyData?.country}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      name="city"
                      value={this.state.familyData?.city}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>State/Province</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      name="state"
                      value={this.state.familyData?.State}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleClose()}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={(e) => this.updateData(e)}>
                Save Changes
              </Button> */}
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
