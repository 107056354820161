import React from 'react';
import support from '../../assets/img/support.png'
import discount from '../../assets/img/discount.png'
import money from '../../assets/img/money.png'
import shipping from '../../assets/img/shipping.png'
import cart from '../../assets/img/cart.png'
import styled from "styled-components";

const BottomContainer = () => {
  let freeshipping = [
    {
      imglink: shipping,
      text: 'Free Shipping & Return',
      subText: 'On all orders over $200'
    },
    {
      imglink: support,
      text: 'Online Support 24/7',
      subText: 'Fast and Clear Answers'
    },
    {
      imglink: money,
      text: 'Money Back Guarantee',
      subText: 'Fast and Clear Answers'
    },
    {
      imglink: discount,
      text: 'Best Discount for All',
      subText: 'Worry Free Shopping'
    },
  ]

  return (
    <Div307>
      <Div308>

        {freeshipping.map(x => <Column18 key={Math.random()}>
          <Div317>
            <Img78
              loading="lazy"
              src={x.imglink}
            />
            <Div318>
              <Div319>{x.text}</Div319>
              <Div320>{x.subText}</Div320>
            </Div318>
          </Div317>
        </Column18>)
        }
      </Div308>
    </Div307>
  );
}


const Div306 = styled.div`
  background-color: #effdff;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 37px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div307 = styled.div`
  width: 100%;
  max-width: 1410px;
  @media (max-width: 991px) {
    max-width: 96%;
  }
`;

const Div308 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column18 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;


const Div317 = styled.div`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin: 0 auto;
  padding: 39px 50px 39px 19px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-right: 20px;
  }
`;

const Img78 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  height: 41px;
  max-width: 100%;
`;

const Div318 = styled.div`
  align-self: start;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div319 = styled.div`
  color: #0a0d14;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div320 = styled.div`
  color: #656565;
  margin-top: 11px;
  white-space: nowrap;
  font: 400 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;


export default BottomContainer;
