import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
// import { getApptData } from './data';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Apilib from "../../api";
import GoogleMap from '../Agora/utils/GoogleMap';
import { useLocation, useHistory } from 'react-router-dom';

const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  margin: 10px 10px;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 5px;
  margin-left:5px;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 95%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
width:50%;
  display: inline-grid;
  align-items: center;
`;

const Div1 = styled.div`
  width: 95%;
`;

const Div2 = styled.div`
  width: 95%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content:flex-start;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const EditMedicine = (route) => {
    const history = useHistory();

    const [loading, setloading] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);

    const [inputBoxes, setInputBoxes] = useState([route.location.state.Medicine_Name]);


    // indexValue
    // updatePharmacyMedicine
    useEffect(() => {
        let newDate = inputBoxes
        setInputBoxes(newDate)
    }, [inputBoxes])

    useEffect(() => {
        console.log(route, 'this is route')
    }, [])

    const reusableDivState = (label) => {
        return <Div1>
            <Label>{label}</Label>
        </Div1>
    }

    const handleInputChange = (index, event, field) => {
        console.log(inputBoxes)
        const values = [...inputBoxes];
        if (field == 'MfgDate' || field == 'ExpDate') {
            values[index][field] = event;
            return
        }
        values[index][field] = event.target.value;
        setInputBoxes(values);
    };





    return (
        <Container>
            {loading && <Loader />}
            <div style={{ display: 'flex' }}>

                <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
                    <Content>

                        <div style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '95%', backgroundColor: '#fff' }} >


                            <Heading>Add Medicine</Heading>
                            <div style={{ border: 'solid #000 .05px' }}></div>

                            <div>

                                {inputBoxes.map((inputState, index) => (
                                    <div key={index} style={{ backgroundColor: '#EAFCFF', padding: '10px', margin: '5px', borderRadius: '11px' }}>

                                        <Containers>
                                            {reusableDivState('Medicine Name')}
                                            <Div2>
                                                <Input type="text" value={inputState.Medicine_Name} onChange={(event) => handleInputChange(index, event, 'Medicine_Name')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Batch Number')}
                                            <Div2>
                                                <Input type="text" value={inputState.Batch_Number} onChange={(event) => handleInputChange(index, event, 'Batch_Number')} />
                                            </Div2>
                                        </Containers>


                                        <Containers>
                                            <Div1>
                                                <Label>MFG Date :</Label>
                                            </Div1>
                                            <Div2>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        inputVariant="outlined"
                                                        value={inputState.MfgDate}
                                                        onChange={(event) => handleInputChange(index, event, 'MfgDate')}
                                                        animateYearScrolling
                                                        name="dateOfBirth"
                                                        disableFuture
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>

                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            <Div1>
                                                <Label>Expiry Date :</Label>
                                            </Div1>
                                            <Div2>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        inputVariant="outlined"
                                                        value={inputState.ExpDate}
                                                        onChange={(event) => handleInputChange(index, event, 'ExpDate')}
                                                        animateYearScrolling
                                                        name="dateOfBirth"
                                                        disablePast
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>

                                            </Div2>
                                        </Containers>

                                        <Containers>
                                            {reusableDivState('Quantity')}
                                            <Div2>
                                                <Input type="text" value={inputState.Quantity} onChange={(event) => handleInputChange(index, event, 'Quantity')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Price')}
                                            <Div2>
                                                <Input type="text" value={inputState.Price} onChange={(event) => handleInputChange(index, event, 'Price')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Units')}
                                            <Div2>
                                                <Input type="text" value={inputState.Units} onChange={(event) => handleInputChange(index, event, 'Units')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Dose')}
                                            <Div2>
                                                <Input type="text" value={inputState.Dose} onChange={(event) => handleInputChange(index, event, 'Dose')} />
                                            </Div2>
                                        </Containers>


                                    </div>))}

                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={agreeTerms}
                                        onChange={() => {
                                            console.log(agreeTerms)
                                            setAgreeTerms(!agreeTerms)
                                        }} />
                                    Agree with the terms and conditions
                                </CheckboxLabel>

                            </div>
                            <ButtonGroup>
                                <Button style={{ fontWeight: '400', fontSize: '16px' }}
                                    onClick={async () => {
                                        if (!agreeTerms) {
                                            alert('Please agree to the terms and conditions')
                                            return
                                        }


                                        let inputbox = inputBoxes.map((x) => {
                                            if (x.Medicine_Name) return x

                                        })

                                        let API = new Apilib();

                                        let data = {
                                            medicines: inputbox,
                                            pharmacyId: route.userData.uid,
                                            indexValue: route.location.state.index
                                            // updatePharmacyMedicine
                                        }


                                        console.log(data)

                                        API.updatePharmacyMedicine(data)
                                            .then(async (res) => {
                                                console.log(res)
                                                console.log('this is response')
                                                history.goBack()
                                            })
                                            .catch((error) => {
                                                console.log('error', error)
                                            });

                                    }}
                                > Save Medicine</Button>
                                <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }} onClick={() => history.goBack()}>
                                    Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </Content>
                </div>
            </div>
        </Container >
    );
};

export default EditMedicine;
