import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Avatar, Grid } from "@material-ui/core";
import moment from "moment";
import { firestore } from "../../firebase";
import { storage } from "../../firebase";

const ExternalChat = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [comments, setComments] = useState([]);
  const [appointment, setAppointment] = useState();
  const [userData, setUserData] = useState();
  const [chatUserPP, setChatUserPP] = useState();
  const dummy = useRef();

  useEffect(() => {
    if (props.appointment) {
      setAppointment(props.appointment);
    }
    if (props.userData) {
      setUserData(props.userData);
    }
  }, [props]);

  useEffect(() => {
    if (appointment) {
      console.log({ appointment, props });
      firestore
        .collection("appointments")
        .doc(appointment.appointmentId)
        .collection("comments")
        .where("commenttype", "==", 2)
        .orderBy("createdAt")
        .onSnapshot((snapshot) => {
          let comments = [];
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            comments.push(data);
          });
          setComments(comments);
          console.log("External chat ", { comments }, props.pp_url);
        });
    }
  }, [appointment]);

  const chatHandler = (e) => {
    let message = e.target.value;
    setMessage(message);
  };
  const chatSubmitHandler = async (e, type) => {
    e.preventDefault();
    const commentData = {
      appointment_id: appointment?.appointmentId,
      userType: localStorage.getItem("@displayName"),
      userId: userData?.uid,
      userName: `${userData?.firstName} ${userData?.lastName}`,
      comment: message,
      commenttype: type,
      createdAt: Date.parse(new Date()),
    };
    setMessage("");

    await firestore
      .collection("appointments")
      .doc(appointment.appointmentId)
      .collection("comments")
      .add(commentData);

    console.log("comments", commentData);
  };

  useEffect(() => {
    if (dummy.current) {
      dummy.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments, props.externally]);

  function makeDownloadUrl(id, type) {
    console.log("chat url working");
    let userType;
    if (type == 5) {
      userType = "patients";
    } else if (type == 3) {
      userType = "doctors";
    } else if (type == 4) {
      userType = "receptionist"
    }
    const path = `profile_images/${userType}/${id}`;
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    gsReference
      .getDownloadURL()
      .then((url) => {
        console.log("chat user img", url);
        setChatUserPP(url);
      })
      .catch((err) => {
        console.log("chat user err", err);
      });
  }

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        centered
        show={props.externally}
        onHide={props.externalChat}
      >
        <Modal.Header closeButton>
          <Modal.Title>Chat With Doctor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="chat-window-appointment">
            {comments.length !== 0
              ? comments.map((chat) => {
                console.log(
                  "chat id",
                  userData?.uid,
                  chat.userId === userData?.uid
                );
                if (chat.userId === userData?.uid) {
                } else {
                  makeDownloadUrl(chat.userId, chat.userType);
                }
                return (
                  <Grid
                    justify={
                      chat.userId === userData?.uid
                        ? "flex-end"
                        : "flex-start"
                    }
                    container
                    wrap="nowrap"
                    spacing={2}
                  >
                    <Grid item>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          chat.userId === userData?.uid
                            ? props.pp_url
                            : chatUserPP
                        }
                      />
                    </Grid>
                    <Grid item>
                      <h5 style={{ margin: 0, textAlign: "left" }}>
                        {chat.userId === userData?.uid
                          ? "You"
                          : chat.userName}
                      </h5>
                      <p
                        className="chat-content"
                        style={{ textAlign: "left" }}
                      >
                        {chat.comment}
                      </p>
                      <p
                        className="chat-date"
                        style={{ textAlign: "left", color: "gray" }}
                      >
                        posted {moment(chat.createdAt).fromNow()}
                      </p>
                    </Grid>
                  </Grid>
                );
              })
              : null}
            <span ref={dummy}></span>
          </div>

          <div>
            <input
              className="comment-area doctor-comment"
              style={{ borderRadius: "none" }}
              placeholder="Enter Text Here...."
              onChange={(e) => chatHandler(e)}
              value={message}
            />
            <button
              onClick={(e) => chatSubmitHandler(e, 2)}
              className="send-button"
              disabled={!message}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.externalChat}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ExternalChat;