import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Notify from "../../Notify";
export default class PatientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      readOnly: false,
      show: false,
      patients: [],
      currentPatient: {},
      currentIndex: "",
      modalOpen: false,
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(5, this.props.userData.hospitalId)
           .then((result) => {
        let res = result.data

        console.log(res);
        if (this._isMounted) {
          this.setState(
            {
              loading: false,
              patients: res.patients,
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({});
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = (e) => {
    let formObj = { ...this.state.currentPatient };
    formObj[e.target.name] = e.target.value;
    this.setState({
      currentPatient: formObj,
    });
  };

  updateData = (e) => {
    e.preventDefault();
    let data = { ...this.state.currentPatient };
    data["email"] = this.state.patients[this.state.currentIndex]["email"];
    let patients = [...this.state.patients];
    patients[this.state.currentIndex] = data;
    this.setState({
      modalLoading: true,
      patients,
    });
    // delete data.email;
    let API = new Apilib();
    API.updateUser(JSON.stringify(data))
      .then((res) => {
        this.setState({
          modalLoading: false,
        });
        if (res.ok) {
          this.setState({
            modalLoading: false,
            currentPatient: {},
            modalOpen: false,
            success: true,
          });
        } else {
          res.text().then((data) => {
            this.setState({
              modalOpen: false,
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  deletePatient(currentPatient) {
    let currentUid = currentPatient.uid;
    let API = new Apilib();
    let data = {
      type: 5,
      uid: currentUid,
    };
    this.setState({
      loading: true,
    });
    API.deletePatient(JSON.stringify(data))
           .then((result) => {
        let res = result.data

        this.setState({
          show: false,
          currentPatient: {},
        });

        let API = new Apilib();
        API.getUsers(5, this.props.userData.hospitalId)
                .then((result) => {
        let res = result.data

            console.log(res);
            this.setState(
              {
                loading: false,
                patients: res.patients,
              },
              () => {
                if ($(".datatable").length > 0) {
                  $(".datatable").DataTable({
                    order: [],
                  });
                }
              }
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
          });
        Notify({
          alert: true,
          type: "success",
          title: "Deleted successfully",
        });
      })
      .catch((err) => {
        this.setState({
          show: false,
          currentPatient: {},
        });
        Notify({
          alert: true,
          type: "error",
          title: err.message,
        });
      });
  }

  handleDeleteOpen(e, index) {
    e.preventDefault();
    this.setState({
      currentPatient: this.state.patients[index],
      currentIndex: index,
      error: null,
      success: false,
    });
  }

  handleOpen(e, index) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
      currentPatient: this.state.patients[index],
      currentIndex: index,
      error: null,
      success: false,
    });
  }
  handleClose() {
    this.setState({
      modalOpen: false,
      currentPatient: {},
      currentIndex: "",
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Users</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link
                to="/hospital/add-patient"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Patient
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Patient updated successfully
                </div>
              )}
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone No.</th>
                      <th>Email</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.patients &&
                      this.state.patients.map((patient, index) => (
                        <tr key={index}>
                          <td>{patient.firstName}</td>
                          <td>{patient.lastName}</td>
                          <td>{patient.phoneno}</td>
                          <td>{patient.email}</td>
                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: true,
                                    });
                                    this.handleOpen(e, index);
                                  }}
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                                  View Only
                                </a>
                                {/* <Link
                                  className="dropdown-item"
                                  //   onClick={(e) => this.handleOpen(e, index)}
                                  to={{
                                    pathname: "/receptionist/edit-patient",
                                    // search: "?sort=name",
                                    // hash: "#the-hash",
                                    state: {
                                      patient: this.state.patients[index],
                                      index: index,
                                    },
                                  }}
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </Link> */}
                                {/* <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: false
                                    })
                                    this.handleOpen(e, index)
                                  }}
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </a> */}
                                {/* <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: false,
                                      show: true,
                                    });
                                    this.handleDeleteOpen(e, index);
                                  }}
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Delete
                                </a> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Modal
            backdrop="static"
            show={this.state.show}
            onHide={() =>
              this.setState({
                show: false,
              })
            }
            animation={false}
          >
            <Modal.Header>
              <Modal.Title>Delete patient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure, You want to delete Patient
              {this.state.currentPatient ? (
                <>
                  <strong>
                    {" " +
                      this.state.currentPatient.firstName +
                      " " +
                      this.state.currentPatient.lastName}
                  </strong>
                </>
              ) : null}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => this.deletePatient(this.state.currentPatient)}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={() => this.setState({ show: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
           backdrop="static"
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>View Patient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="firstName"
                      value={this.state.currentPatient.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="lastName"
                      value={this.state.currentPatient.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Phone No. <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="phoneno"
                      value={this.state.currentPatient.phoneno}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      readOnly={this.state.readOnly}
                      name="email"
                      value={this.state.currentPatient.email}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Details</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="emergencyContactDetails"
                      value={this.state.currentPatient.emergencyContactDetails}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="phone"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="bloodgroup"
                      value={this.state.currentPatient.bloodgroup}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Height</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="height"
                      value={this.state.currentPatient.height}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Weight</label>
                    <input
                      readOnly={this.state.readOnly}
                      name="weight"
                      value={this.state.currentPatient.weight}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label>Postal Code</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="pincode"
                      name="postalcode"
                      value={this.state.currentPatient.postalcode}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>BMR</label>
                    <input
                    readOnly={this.state.readOnly}
                      name="bmr"
                      value={this.state.currentPatient.bmr}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div> */}
                <div className="col-sm-6">
                  <div className="form-group gender-select">
                    <label className="gen-label">Gender:</label>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          readOnly={this.state.readOnly}
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.currentPatient.gender == "male"}
                          value="male"
                          className="form-check-input"
                        />
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          readOnly={this.state.readOnly}
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.currentPatient.gender == "female"}
                          value="female"
                          className="form-check-input"
                        />
                        Female
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text-area"
                      name="address"
                      value={this.state.currentPatient.address}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      name="country"
                      value={this.state.currentPatient.country}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div> */}
                {/* <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      name="city"
                      value={this.state.currentPatient.city}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div> */}
                {/* <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>State/Province</label>
                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      name="state"
                      value={this.state.currentPatient.State}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleClose()}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={(e) => this.updateData(e)}>
                Save Changes
              </Button> */}
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
