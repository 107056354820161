import { Formik } from "formik";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import BaseSelect from "react-select";
import * as Yup from "yup";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";
import { firestore } from "../../firebase";
// import { useLocation }
import { useLocation } from "react-router-dom";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const specialities = [
  { value: "Cardiologist", label: "Cardiologist" },
  { value: "Dentist", label: "Dentist" },
  { value: "Gynaecologist", label: "Gynaecologist" },
  { value: "Paediatrician", label: "Paediatrician" },
  { value: "Psychiatrists", label: "Psychiatrists" },
];

const options = [
  {
    label: '<Fontawesome class="fas fa-briefcase-medical"></i>',
    value: '<i class="fas fa-briefcase-medical"></i>',
  },
  {
    label: "Icon2",
    value: "Icon2",
  },
  {
    label: "Icon3",
    value: "Icon3",
  },
  {
    label: "Icon4",
    value: "Icon4",
  },
];

export default class EditPharmacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // neww: idfrom,
      // location: useLocation(),
      listOfNewMedicine: [],
      counter: 0,
      loading: false,
      error: "",
      success: false,
      chain_name: "",
      createdSpecialities: [],

      // update test
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
      pharmacyName: '',
      pharmacyAddrs: '',
      email: '',
      name: '',
      pharmacyId: '',

      // models state
      modalOpen: false,
      modelLoading: false,

      confirmModelLoading: false,
      confirmModalOpen: false,

      inputList: [{ name: "", medicinePrice: "", medicineId: '', medicineIngredients: '', medicineExpiry: '', discount: '', delivery: '', stock: '', misc: '' }]
    };
  }
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
  };
  handleRemoveClick = (index) => {
    const list = [...this.state.inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    // list.splice(index, 1);
    this.setState({ inputList: remove });
  };
  handleAddClick = () => {
    let inputListTemp = [...this.state.inputList]
    inputListTemp.push({ name: "", medicinePrice: "", medicineId: '', medicineIngredients: '', medicineExpiry: '', discount: '', delivery: '', stock: '', misc: '' })
    this.setState({ inputList: inputListTemp });
  };
  componentDidMount() {
    this.getSpeciality();
    console.log('this.state.location.state.idfrom')
    // console.log(this.state.location.state.idfrom)

  }

  handleClose() {
    this.setState({
      modalOpen: false,
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
    });
  }

  handleComfirmModalClose = () => {
    this.setState({
      confirmModalOpen: false,
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
    });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e, { resetForm }) => {
    console.log('clicked this in submit ', this.state.pharmacyName)
    console.log('clicked this in submit ', this.state.pharmacyAddrs, this.state.name, this.state.pharmacyId, this.state.email)
    let callApi = {
      email: this.state.email,
      name: this.state.name,
      orderCount: 0,
      pharmacyId: this.state.pharmacyId,
      pharmacyName: this.state.pharmacyName
    }
    try {
      this.setState({
        error: "",
        loading: true,
      });
      let API = new Apilib();
      API.addPharmacy(callApi).then((result) => {
        let res = result.data;
        // if (!res.ok) {
        //   let msg = await res.json();
        //   throw new Error(msg.message);
        // }
        Notify({
          alert: true,
          type: "success",
          title: "Pharmacy added",
        });
        resetForm();
        this.setState({
          selectedSpeciality: {
            speciality: "",
            id: "",
          },
          pharmacyAddrs: '',
          pharmacyId: '',
          pharmacyName: '',
          name: '',
          email: ''
        });
        this.getSpeciality();
      });
    } catch (error) {
      console.log(error);
      this.setState(
        {
          loading: false,
        },
        () =>
          Notify({
            alert: true,
            type: "error",
            title: error.message,
          })
      );
    }
  };

  onSpecialitySelect(value) {
    let arr = [];
    value.map((val, index) => {
      arr.push(val.value);
    });
    this.setState({
      specialities: arr,
      specialitiesObj: value,
    });
  }

  getSpeciality = async () => {
    try {
      this.setState({
        loading: true,
      });
      const API = new Apilib();
      API.getPharmacies()
        .then((result) => {
          let res = result.data

          console.log("Pharm data", res.data);
          // setStatePharmacies(res.data);
          this.setState({
            createdSpecialities: res.data,
            loading: false,
          });
        })
        .catch((err) => {
          // setLoading(false);
          console.log(err);
        });

      //   API.getSpecialities().then((result) => {
      //     let res = result.data;
      //     console.log(res.data, 'this is speccilaties format')
      //     return this.setState({
      //       createdSpecialities: res.data,
      //       loading: false,
      //     });
      //     // return this.setState({
      //     //   loading: false,
      //     //   error: "Unable to fetch specialities",
      //     //   success: false,
      //     // });
      //   });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message,
        success: false,
      });
    }
  };

  handleSpecialityUpdate = async () => {
    try {
      const API = new Apilib();
      await API.updateSpeciality(this.state.selectedSpeciality)
        .then((result) => {
          let res = result.data
          this.setState(
            {
              modelLoading: false,
              modalOpen: false,
            },
            () => {
              Notify({
                alert: true,
                type: "success",
                title: "Speciality Updated Successfully",
              });
              this.setState({
                selectedSpeciality: {
                  speciality: "",
                  id: "",
                },
              });
              this.getSpeciality();
            }
          );
          // if (!res.ok) {
          //   let msg = await res.json();
          //   throw new Error(msg.message);
          // }
        });

    } catch (error) {
      console.log(error);
      this.setState(
        {
          modelLoading: false,
        },
        () => {
          Notify({
            alert: true,
            type: "error",
            title: error.message,
          });
        }
      );
    }
  };

  handleSpecialityDelete = async () => {
    try {
      const API = new Apilib();
      await API.deletePharmacy(
        JSON.stringify({ id: this.state.selectedSpeciality.id })
      );
      this.setState(
        {
          confirmModelLoading: false,
          confirmModalOpen: false,
          selectedSpeciality: {
            speciality: "",
            id: "",
          },
        },
        () => {
          Notify({
            alert: true,
            type: "success",
            title: "Pharmacy Deleted Successfully",
          });
          this.getSpeciality();
        }
      );
    } catch (error) {
      console.log(error);
      this.setState(
        {
          confirmModelLoading: false,
          confirmModalOpen: false,
        },
        () => {
          Notify({
            alert: true,
            type: "error",
            title: "Failed to delete",
          });
        }
      );
    }
  };

  updateMedicine = async (index) => {

    if (this.state.counter == 1) return
    this.setState({
      loading: true,
    })
    let kl = this.state.counter
    this.setState({ counter: kl + 1 })
    let listOfMedicine = []
    let gotData = null
    try {
      gotData = await firestore.collection("pharmacy").doc(this.props.location.idfrom).collection("medicines").doc("medicines").get();
      console.log(gotData.data(), 'gotData.data()gotData.data()gotData.data()')
      listOfMedicine = gotData.data().medicines

    } catch (error) {
      console.log('error', error)
    }

    this.setState({
      listOfNewMedicine: listOfMedicine,
      loading: false,
    });
  }


  render() {

    console.log(this.props, 'this.props.location.idfromthis.props.location.idfromthis.props.location.idfrom')
    if (this.props.location.idfrom) this.updateMedicine(this.props.location.idfrom)

    async function call() {
      async function getData() {
        let listOfMedicine = []
        let gotData = null
        try {
          gotData = await firestore.collection("pharmacy").doc(this.props.location.idfrom).get();
          listOfMedicine = gotData.data().pharmacy[0].medicines

        } catch (error) {
          console.log('error', error)
        }

        this.setState({
          listOfNewMedicine: listOfMedicine,
          loading: false,
        });
      }
      await getData()
    }

    // call()


    const styless = {
      inputBoxStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '0.469rem 0.75rem',
        // border-color: #eaeaea;
        fontSize: '14px',
        minHeight: '40px',
        minWidth: '50%'
      },
      inputContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flex: 1,
        alignContent: 'space-evenly',
        // alignItems:''
      }
    }

    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">{this.props.location.pharmacyName} </h4>
          </div>
        </div>
        {this.state.error && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            {this.state.error}
          </div>
        )}
        {this.state.success && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            Pharmacy added successfully
          </div>
        )}

        {!this.state.loading && (
          <Table
            tableOptions={{
              columnDefs: [{ orderable: false, targets: 1, width: "180px" }],
            }}
            columns={["Medicine", "Med Id", "Price", "Edit"]}
            rows={this.state.listOfNewMedicine.map((speciality) => (
              <tr>
                {console.log(speciality)}
                <td>{speciality.Medicine_Name}</td>
                <td>{speciality.Batch_Number}</td>
                <td>{speciality.Price}</td>
                {/* <td><button
                  onClick={() => {
                    this.setState({
                      modalOpen: true,
                      selectedSpeciality: speciality,
                    })
                    console.log(speciality)
                  }
                  }
                  className="btn btn-small btn-secondary mr-2"
                >
                  <span
                    class="iconify"
                    data-icon="akar-icons:edit"
                    data-inline="false"
                    style={{
                      marginRight: ".5em",
                      marginTop: "-.1em",
                      display: "inline-block",
                    }}
                  ></span>
                  View Pharmacy
                </button></td> */}

                <td>
                  <button
                    onClick={() => {
                      this.setState({
                        modalOpen: true,
                        selectedSpeciality: speciality,
                      })
                      console.log(speciality, 'this is speciality')
                    }
                    }
                    className="btn btn-small btn-secondary mr-2"
                  >
                    <span
                      class="iconify"
                      data-icon="akar-icons:edit"
                      data-inline="false"
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                    ></span>
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        confirmModalOpen: true,
                        selectedSpeciality: speciality,
                        inputList: speciality
                      });
                    }}
                    className="btn btn-small btn-danger mr-2"
                  >
                    <span
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                      class="iconify"
                      data-icon="ant-design:delete-twotone"
                      data-inline="false"
                    ></span>
                    Delete
                  </button>
                </td>
              </tr>
            ))
            }
          />
        )}
        <Modal
          backdrop="static"
          show={this.state.modalOpen}
          onHide={() => this.handleClose()}
          animation={false}
          size="lg"
          dialogClassName="modal-100w"

          scrollable={true}
        // fullscreen={true}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Edit medicines</Modal.Title>
          </Modal.Header>
          <Formik
            validationSchema={Yup.object().shape({
              speciality: Yup.string()
                .required()
                .matches(
                  /^[aA0-zZ9\s]+$/,
                  "Only alphabets are allowed for speciality name"
                )
                .min(3, "Pharmacy name should be of minimum 3 characters")
                .label("Pharmacy name")
                .trim(),

            })}
            onSubmit={this.handleSpecialityUpdate}
            initialValues={{
              speciality: this.state.selectedSpeciality.speciality,
            }}
          >
            {({
              handleSubmit,
              submitForm,
              values,
              errors,
              handleChange,
              setErrors,
              setFieldValue,
            }) => (
              <>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Pharmacy Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter the pharmacy"
                          type="text"
                          onChange={(e) => {
                            console.log(values)
                            setFieldValue("pharmacy", e.target.value);
                          }}
                          onBlur={() => {
                            if (!!values.speciality === false)
                              setErrors({ speciality: "" });
                          }}
                          value={this.state.selectedSpeciality.pharmacyName}
                        />
                        {/* <span className="form-text text-danger">
                          {errors.speciality}
                        </span> */}
                      </div>
                    </div>
                    <div className="col-lg-12">


                      {/* <div className="form-group">
                        <label>Icon</label>

                        <select className="form-control" type="text">
                          <option>Select...</option>
                          <option
                            data-icon="glyphicon glyphicon-eye-open"
                            data-subtext="petrification"
                          >
                            Doctor
                          </option>
                        </select>
                        <span className="form-text text-danger">
                          {errors.speciality}
                        </span>
                      </div> */}

                      {this.state.inputList.map((x, i) => {
                        // { name: "", medicinePrice: "", medicineId: '', medicineIngredients: '', medicineExpiry: '', discount: '', delivery: '', stock: '', misc: '' }
                        return (
                          <div className="box" key={Math.random()}>
                            <div className="form-group"
                              style={styless.inputContainer}
                            >
                              <input
                                // className="form-control col-sm-5 row"
                                name="name"
                                placeholder={x.Medicine_Name}
                                value={x.Medicine_Name}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                              <input
                                // className="form-control col-sm-5 row"
                                style={styless.inputBoxStyle}
                                name="medicinePrice"
                                placeholder={x.Price}
                                value={x.Price}
                                onChange={(e) => this.handleInputChange(e, i)}
                              />

                            </div>
                            <div className="form-group"
                              style={styless.inputContainer}
                            >
                              <input
                                // className="form-control col-sm-5"
                                name="medicineId"
                                placeholder="Enter Medicine Id"
                                value={x.medicineId}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />

                              <input
                                // className="form-control"
                                name="medicineIngredients"
                                placeholder="Enter Medicine Ingredients"
                                value={x.medicineIngredients}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                            </div>
                            <div className="form-group"
                              style={styless.inputContainer}
                            >

                              <input
                                // className="form-control"
                                name="medicineExpiry"
                                placeholder="Enter Medicine Expiry(dd-mm-yyyy)"
                                value={x.medicineExpiry}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />


                              <input
                                // className="form-control"
                                name="discount"
                                placeholder="Enter Discount if any (in %)"
                                value={x.discount}
                                type={'number'}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                            </div>

                            <div className="form-group"
                              style={styless.inputContainer}>
                              <input
                                // className="form-control"
                                name="delivery"
                                placeholder="Enter Medicine delivery conditions (if any)"
                                value={x.delivery}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />

                              <input
                                // className="form-control"
                                name="stock"
                                placeholder="Enter Medicine stock available"
                                value={x.stock}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                            </div>

                            <input
                              className="form-control"
                              name="misc"
                              placeholder="Enter misc info"
                              value={x.misc}
                              onChange={(e) => this.handleInputChange(e, i)}

                            />


                            <div className="btn-box">
                              {this.state.inputList.length !== 1 && (
                                <Button
                                  variant="secondary" className="mr10" onClick={() => this.handleRemoveClick(i)}>
                                  Remove
                                </Button>
                              )}
                              {this.state.inputList.length - 1 === i && (
                                <Button onClick={this.handleAddClick} variant="primary">Add more medicines</Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ marginTop: 20 }}>{JSON.stringify(this.state.inputList)}</div>

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.handleClose();
                      console.log(this.state.inputList)
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={this.state.modelLoading}
                    variant="primary"
                    onClick={async () => {
                      // this.setState({
                      //   selectedSpeciality: {
                      //     speciality: values.speciality.trim(),
                      //     id: this.state.selectedSpeciality.id,
                      //   },
                      // });
                      // submitForm();

                      console.log(this.state.inputList)
                      let newDetails = []
                      this.state.inputList.map((x) => {
                        console.log('mapping htis')
                        console.log(x.name)
                        if (x.medicineId == '' || x.medicineId == undefined) console.log('this cant be added')
                        else newDetails.push(x)
                      })
                      console.log('mapping htis')
                      console.log(this.state.selectedSpeciality)
                      // addNewMedicines
                      let API = new Apilib();

                      // let pharmacyDocRef = firebaseAdmin.firestore().collection('pharmacy').doc(id);



                      // let gotData = await firestore
                      //   .collection("pharmacy")
                      //   .doc(this.state.selectedSpeciality.id).get();

                      // let newArray = gotData.data().pharmacy[0].medicines

                      // this.state.inputList.map((x) => {
                      //   newArray.push(x)
                      // })

                      // console.log('newArray')
                      // console.log(newArray)


                      // console.log('gotData')
                      // console.log(gotData)
                      // console.log('gotData')
                      // console.log(gotData.data().pharmacy[0].medicines)

                      this.setState({ loading: true })
                      API.addNewMedicines({ id: this.state.selectedSpeciality.id, data: newDetails }).then((result) => {
                        let res = result.data;
                        // if (!res.ok) {
                        //   let msg = await res.json();
                        //   throw new Error(msg.message);
                        // }


                        this.setState({ loading: false })
                        console.log('this is response')
                        console.log(res)
                        Notify({
                          alert: true,
                          type: "success",
                          title: "Medicines Added successfully",
                        });

                      }).catch((e) => {
                        this.setState({ loading: false })
                        console.log('some error', e)
                        Notify({
                          alert: true,
                          type: "error",
                          title: "Some error while adding medicines",
                        });
                      });


                    }}
                  >
                    Add medicines
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
        <Modal
          backdrop="static"
          show={this.state.confirmModalOpen}
          onHide={() => this.handleConfirmModalClose()}
          animation={false}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Delete Pharmacy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete{" "}
              <b>{this.state.selectedSpeciality.speciality}</b>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleComfirmModalClose();
              }}
            >
              Close
            </Button>
            <Button
              disabled={this.state.confirmModelLoading}
              variant="danger"
              onClick={this.handleSpecialityDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}
