import React from "react";

const TextInput = ({ onSubmit }) => {
    const [reason, setReason] = React.useState("");

    return (
        <div className="form-inline">
            <input
                type="text"
                className="form-control flex-grow-1 mr-2"
                placeholder="Enter here"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
            />
            <button
                className="btn btn-primary"
                type="button"
                disabled={!reason}
                onClick={() => {
                    onSubmit(reason);
                    setReason("");
                }}
            >
                Add
            </button>
        </div>
    );
};

const ArrayInput = ({ label, stateArr = [], stateSetter }) => {
    const removeEle = (index) => {
        const newArr = [...stateArr];
        newArr.splice(index, 1);
        stateSetter(newArr);
    };

    const addEle = (reason) => {
        const newArr = [...stateArr];
        newArr.push(reason);
        stateSetter(newArr);
    };

    return (
        <div className="form-group">
            <label>{label}</label>
            {stateArr.map((reason, index) => (
                <div className="d-flex my-3 align-items-stretch">
                    <div className="flex-grow-1 d-flex px-2 border align-items-center mr-3 overflow-hidden">
                        <span>{reason}</span>
                    </div>
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => removeEle(index)}
                    >
                        Delete
                    </button>
                </div>
            ))}

            <TextInput onSubmit={addEle} />
        </div>
    );
};

export const DisplayArr = ({ stateArr = [], label }) => {
    return (
        <div className="form-group">
            <label>{label}</label>
            {stateArr.map((reason, index) => (
                <div className="d-flex mb-2 align-items-stretch">
                    <div className="flex-grow-1 d-flex px-2 border align-items-center overflow-wrap">
                        <span>{index + 1}. {reason}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ArrayInput;
