import React, { useState } from 'react';
import styled from 'styled-components';
import medvaticLogo from '../../../src/assets/logo-dark.png';

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;

const LoginScreen = styled.form`
  width: 400px;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const LogoImage = styled.img`
  width: 120px;
  height: 120px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #50d8fa;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #32c2e8;
  }
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const AdditionalInfo = styled.p`
  font-size: 14px;
  color: #777;
`;

const SignupLink = styled.a`
  color: #50d8fa;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform authentication logic here
    };

    return (
        <LoginFormContainer>
            <LoginScreen onSubmit={handleSubmit}>
                <LogoContainer>
                    <LogoImage src={medvaticLogo} alt="Medvatic Logo" />
                </LogoContainer>
                <InputField
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <SubmitButton type="submit">Login</SubmitButton>
                <AdditionalInfoContainer>
                    <AdditionalInfo>
                        Forgot your password? <SignupLink onClick={() => {
                            console.log('hi thrtr')
                        }}>Reset it here</SignupLink>.
                    </AdditionalInfo>
                </AdditionalInfoContainer>
            </LoginScreen>
        </LoginFormContainer>
    );
};

export default LoginForm;