import React, { useState, useEffect } from 'react';
import Progress from '../Loader/Progress';
import prescription1Img from '../../../src/assets/img/prescription1.png';
import newcustomer from '../../../src/assets/img/new-customer.png';
import newsupplier from '../../../src/assets/img/new-supplier.png';
import newmedicine from '../../../src/assets/img/new-medicine.png';
import newinvoice from '../../../src/assets/img/new-invoice.png';
import genicon from '../../../src/assets/img/genicon.png';
import styled from 'styled-components';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LineChart } from 'recharts';
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import Apilib from "../../api";

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius:45px;
  cursor: pointer;
  width:100%;
  margin:10px;
`;
const ButtonOrange = styled.button`
  background-color: #FC700A;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius:45px;
  cursor: pointer;
  width:100%;
  margin:10px;
`;
const ButtonNavigate = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width:100%;
  margin:10px;
  text-align-last:start;
`;

const medicationManagement = [
    { Prescription: 'Cap. DDR 30mg ', Last_Refilled: "21 June 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' },
    { Prescription: 'Cap. Uromax D ', Last_Refilled: "29 May 2023", Next_Refill: '21 August 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' },
    { Prescription: 'Ticamet 250 ', Last_Refilled: "29 May 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' },
    { Prescription: 'Trilock 10 ', Last_Refilled: "21 June 2023", Next_Refill: '21 August 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' },

];
const orderManagement = [
    { Prescription: 'Cap. DDR 30mg ', Last_Refilled: "21 June 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Refillable' },
    { Prescription: 'Cap. Uromax D ', Last_Refilled: "29 May 2023", Next_Refill: '21 August 2023', Prescriber: 'John Smith', Order_Status: 'No refill available' },
    { Prescription: 'Ticamet 250 ', Last_Refilled: "29 May 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Refillable' },
    { Prescription: 'Trilock 10 ', Last_Refilled: "21 June 2023", Next_Refill: '21 August 2023', Prescriber: 'John Smith', Order_Status: 'Refillable' },
    { Prescription: 'Trilock 10 ', Last_Refilled: "19 June 2023", Next_Refill: '24 August 2023', Prescriber: 'John Smith', Order_Status: 'No refill available' },

];



const DrugRefill = (route) => {
    const history = useHistory();


    const [patientList, setPatientList] = useState([{
        uid: '1245w2',
        firstName: 'Example'
    }])

    const [selectedPatient, setSelectedPatient] = useState(null);
    const [pharmacyInvoices, setPharmacyInvoices] = useState(null);

    useEffect(() => {
        let API = new Apilib();
        console.log(route, 'this is route')
        API.getUsers(5, route.userData.hospitalId)
            .then((result) => {
                let res = result.data
                console.log(res);

                let sorted = res.patients;
                sorted.sort((a, b) => {
                    return b.created_at - a.created_at;
                })
                setPatientList(sorted)


            })
            .catch((error) => {
                // this.setState({
                //     loading: false,
                // });
            });

        API.getPharmacyInvoices({ pharmacyId: route.userData.uid })
            .then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log(res.data.invoiceList)
                console.log('pharmacy data')

                setPharmacyInvoices(res.data.invoiceList)
                // setpharmacyData(res.data.invoices)
                // { Prescription: 'Cap. DDR 30mg ', Last_Refilled: "21 June 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' }
                // setmedicationManagement



            })
            .catch((error) => {
                console.log('error', error)
            });
    }, [])


    const handleObjectChange = (event) => {
        const objectId = event.target.value
        console.log(objectId)
        const selected = patientList.find((obj) => obj.uid === objectId);
        console.log(selected)
        setSelectedPatient(selected);
        // address, Patient_Medicare_No

        let data = {
            pharmacy_id: 'KAoOnOt37RMTwAxsr6sqi1aOler1',
            orderId: '313131',

            orderedBy: {
                name: 'ram',
                uid: '7q6GWjtjZ6bjiqjdgmaEN2boYRt1',
                phone: '9700046173',
            },
            pharmacyDetails: {
                pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1',
                pharmacyName: "1mg",
                location: "Rohili, barmer, Rajasthan",
            },
            orderDate: '2023-08-31T11:32:00.000Z',
            mobileNumber: '972224616',
            Email: 'ram@gmail.com',
            address: 'rohili,barmer',
            city: 'barmer',
            state: 'rajasthan',
            prescriptionLink: 'https://www.researchgate.net/publication/345830022/figure/fig4/AS:957640029003789@1605330583881/Sample-prescription-used-as-input-to-the-GUI-developed-in-the-present-work.png',
            paymentDetails: {
                amount: '300',
                paymentId: '12345',
                paymentLink: 'https://commbank.com',

            },
            payment: true,
            paymentProcessed: false,
            estimatedDeliveryDate: '2023-08-31T11:32:00.000Z',
            orderValue: '1008',
            prescribedBy: 'Ram Borawat',
            shippingDetails: '',
            delivery: {
                deliveryAssignedTo: 'ram',
                deliveryAsigneeId: '7914878',
                deliveryProgress: {
                    orderReceived: true,
                    orderAcknowldgement: true,
                    orderPacked: true,
                    orderReadyForDelivery: true,
                    orderAssigned: true,
                    orderOutForDelivery: true,
                    orderLastLocation: ['barmer', 'rohili'],
                    deliveryCompleted: true,
                    deliveryCustomerVerification: true,
                },
            }
        }

        let API = new Apilib();

        console.log('calling api')

        API.getOrderDetails(data)
            .then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log(res.data.invoiceList)
                console.log('pharmacy data')

                setPharmacyInvoices(res.data.invoiceList)
                // setpharmacyData(res.data.invoices)
                // { Prescription: 'Cap. DDR 30mg ', Last_Refilled: "21 June 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' }
                // setmedicationManagement



            })
            .catch((error) => {
                console.log('error', error)
            });

    };

    return (
        <div style={{ backgroundColor: '#EAFCFF' }}>

            <div style={{ width: '99%', backgroundColor: '#fff', border: 'solid #bdeaf1 1px', alignSelf: 'center', }}>
                <div style={{ color: '#000', fontSize: '21px', padding: '12px' }}>Drug Refill</div>
            </div>


            <div>
                <div style={{ color: '#000', fontSize: '21px', padding: '12px' }}>Viewing Patient</div>
                <div style={{ color: '#000', }}> {patientList && <div >
                    <select style={{ padding: '11px', margin: '5px', borderRadius: '5px', width: '30%' }} value={selectedPatient?.uid} onChange={handleObjectChange} required>
                        <option value="">Select a patient to view orders</option>
                        {patientList.map((obj) => (
                            <option key={obj.uid} value={obj.uid}>
                                {obj.firstName}
                            </option>
                        ))}
                    </select>

                </div>}

                </div>


                <div style={{ color: '#000', padding: '10px' }}> Recently Ordered Refills | 4 Refills </div>

                <div style={{ borderRadius: '10px', border: 'solid #f6f6f6 .5px', justifyContent: 'space-between', display: 'flex', alignItems: 'center', backgroundColor: '#00ccff', padding: '10px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} key={Math.random()}>

                    <div style={{ width: '30%', textAlign: 'center' }}>
                        <div style={{ color: '#000' }}>Prescription</div>
                    </div>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                        <div style={{ color: '#000' }}>Last Refilled</div>
                    </div>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                        <div style={{ color: '#000' }}>Next Refill</div>
                    </div>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                        <div style={{ color: '#000' }}>Prescriber</div>
                    </div>
                    <div style={{ width: '15%' }}>
                        <div style={{ color: '#000', textAlign: 'center' }}>Order Status</div>
                    </div>
                    <hr />
                </div>

                {medicationManagement.map(x => <div style={{ borderRadius: '1px', border: 'solid #f6f6f6 .5px', justifyContent: 'space-between', display: 'flex', margin: '1px', alignItems: 'center', backgroundColor: '#fff', padding: '10px' }} key={Math.random()}>
                    <div style={{ width: '30%' }}>
                        <div>{x.Prescription}</div>
                    </div>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                        <div style={{ color: '#000', textAlign: 'center' }}>{x.Last_Refilled}</div>
                    </div>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                        <div>{x.Next_Refill}</div>
                    </div>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                        <div>{x.Prescriber}</div>
                    </div>
                    <div style={{ width: '15%' }}>
                        <div style={{ border: 'solid #ccc .5px', borderRadius: '3px', cursor: 'pointer', textAlign: 'center' }} onClick={() => {
                            console.log(x.Order_Status, x.Prescription)
                        }}>{x.Order_Status}</div>
                    </div>
                </div>)}


                <div style={{ color: '#000', padding: '10px' }}>Add Refills to your Pharmacy Cart | 4 Refills </div>

                <div style={{ borderRadius: '10px', border: 'solid #f6f6f6 .5px', justifyContent: 'space-between', display: 'flex', alignItems: 'center', backgroundColor: '#00ccff', padding: '10px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} key={Math.random()}>

                    <div style={{ width: '30%', textAlign: 'center' }}>
                        <div style={{ color: '#000' }}>Prescription</div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ color: '#000' }}>Last Refilled</div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ color: '#000' }}>Next Refill</div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ color: '#000' }}>Prescriber</div>
                    </div>
                    <div style={{ width: '15%' }}>
                        <div style={{ color: '#000', textAlign: 'center' }}>Order Status</div>
                    </div>
                    <div style={{ width: '15%' }}>
                        <div style={{ color: '#000', textAlign: 'center' }}></div>
                    </div>

                    <hr />
                </div>

                {orderManagement.map(x => <div style={{ borderRadius: '1px', border: 'solid #f6f6f6 .5px', justifyContent: 'space-between', display: 'flex', margin: '1px', alignItems: 'center', backgroundColor: '#fff', padding: '10px' }} key={Math.random()}>

                    <div style={{ width: '30%' }}>
                        <div>{x.Prescription}</div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ color: '#000', textAlign: 'center' }}>{x.Last_Refilled}</div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div>{x.Next_Refill}</div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div>{x.Prescriber}</div>
                    </div>
                    <div style={{ width: '15%' }}>
                        <div style={{ border: 'solid #ccc .5px', borderRadius: '3px', cursor: 'pointer', textAlign: 'center' }} onClick={() => {
                            console.log(x.Order_Status, x.Prescription)
                        }}>
                            <i className="fa fa-life-ring" style={{ fontWeight: '100', fontSize: '10px', color: '#000' }}></i>
                            {x.Order_Status}
                        </div>

                    </div>
                    <div style={{ width: '15%' }}>
                        <div style={{ color: '#fff', textAlign: 'center', backgroundColor: '#00ccff', margin: '10px', borderRadius: '15px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                            console.log('this is', x.Prescription)
                        }}>Add to cart</div>
                    </div>
                </div>)}
            </div>


        </div>
    );
}

export default DrugRefill;
