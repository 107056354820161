import React, { useState, useEffect } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
// import 'react-horizontal-scrolling-menu/dist/styles.css';
import Testimonials from './Testimonials';
import styled from "styled-components";
// import support from '../../../src/assets/img/ChatSupport/support'
import support from '../../assets/img/support.png'
import discount from '../../assets/img/discount.png'
import money from '../../assets/img/money.png'
import shipping from '../../assets/img/shipping.png'
import tabs from '../../assets/img/category (4).png'
import health from '../../assets/img/category (5).png'
import vitamins from '../../assets/img/category (1).png'
import devices from '../../assets/img/category (2).png'
import labtest from '../../assets/img/category (3).png'
import personal from '../../assets/img/category (6).png'
import cart from '../../assets/img/cart.png'
import Apilib from "../../api";
import '../../Components/Patient/ChatSupport.scss'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
// import Autosuggest from 'react-autosuggest';
import AutoSuggest from './AutoSuggest';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BottomContainer from '../Pharmacy/BottomContainer';

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        <div style={{ alignSelf: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <i class="fa fa-arrow-left"></i>
        </div>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        <div style={{ alignSelf: 'center' }} disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <i class="fa fa-arrow-right"></i>
        </div>
    );
}

function LeftArrows() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        <div style={{ alignSelf: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <i class="fa fa-arrow-left"></i>
        </div>
    );
}

function RightArrows() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);


    return (
        <div style={{ alignSelf: 'center' }} disabled={isLastItemVisible} onClick={() => {
            console.log('its rigt')
            scrollNext()
        }}>
            <i class="fa fa-arrow-right"></i>
        </div>
    );
}

function Card({ onClick, selected, title, itemId, type, rating, amount, imageLink }) {
    const visibility = React.useContext(VisibilityContext);

    return (
        <div
            className="card"
            onClick={() => onClick(visibility)}
            style={{
                width: '260px',
                justifyContent: 'space-evenly',
                padding: '10px',
                margin: '10px'
            }}
            tabIndex={0}
        >
            {/* <div className="card"> */}
            {/* <div>{title}</div>
                <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
                <div>selected: {JSON.stringify(!!selected)}</div> */}

            {/* <div className="product" style={{
                    margin: '10px', padding: '10px', alignContent: 'center', backgroundColor: '#fcfcfc', width: '100%'
                }}> */}
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }}>
                <img src={imageLink} alt="Product 1" style={{ width: '230px', height: '150px', }} />
            </div>
            <div style={{ textAlign: 'left', }}>

                <p style={{ width: '100%', marginBottom: '3px' }}>{type} </p>
                <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{title} </p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p style={{ width: '100%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>{amount} </p>
                    <img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                        alert('added to cart ' + title)
                    }} />
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
        </div>
    );
}

function CategoryCard({ onClick, title, imageLink, id }) {
    // const visibility = React.useContext(VisibilityContext);

    return (
        <div
            className="card"
            onClick={() => onClick(title)}
            style={{
                width: '180px',
                justifyContent: 'space-evenly',
                padding: '10px',
                margin: '10px'
            }}
            tabIndex={5}
        >
            {/* <div className="product" style={{margin: '10px', padding: '10px', alignContent: 'center', backgroundColor: '#fcfcfc', width: '60%'}}> */}
            {/* https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imageLink} alt="category" style={{ width: '90px', height: '100px', }} />
            </div>
            <p style={{ width: '100%', textAlign: 'center', height: '50px' }}>{title} </p>
            <p></p>
        </div>
        // </div>
    );
}


// function NewCard({ onClick, selected, title, itemId, type, rating, amount, imageLink }) {
//     // const visibility = React.useContext(VisibilityContext);

//     return (
//         <div
//             className="card"
//             // onClick={() => onClick(visibility)}
//             style={{
//                 width: '180px',
//                 justifyContent: 'space-evenly',
//                 padding: '10px',
//                 margin: '10px'
//             }}
//             tabIndex={5}
//         >
//             {/* <div className="product" style={{margin: '10px', padding: '10px', alignContent: 'center', backgroundColor: '#fcfcfc', width: '60%'}}> */}
//             {/* https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg */}
//             <div style={{ display: 'flex', justifyContent: 'center' }}>
//                 <img src={imageLink} alt="category" style={{ width: '90px', height: '100px', }} />
//             </div>
//             <p style={{ width: '100%', textAlign: 'center', height: '50px' }}>{title} </p>
//             <p></p>
//         </div>
//     );
// }


function NewCard({ onClick, title, imageLink, id, type, amount }) {
    // const visibility = React.useContext(VisibilityContext);

    return (
        <div
            className="card"
            // onClick={() => onClick(visibility)}
            style={{
                width: '260px',
                justifyContent: 'space-evenly',
                padding: '10px',
                margin: '10px'
            }}
            tabIndex={0}>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }}>
                <img src={imageLink ? imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product 1" style={{ width: '230px', height: '150px', }} />
            </div>
            <div style={{ textAlign: 'left', }}>

                <p style={{ width: '100%', marginBottom: '3px' }}>{type} </p>
                <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{title} </p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p style={{ width: '100%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>{amount} </p>
                    <img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                        console.log('added to cart ' + title)
                    }} />
                </div>
            </div>
        </div>
        // </div>
    );
}



const Medicine = () => {

    const [medicineList, setmedicineList] = useState(null)
    const history = useHistory();
    // getAllMedicines
    const location = useLocation();
    console.log(location, 'this is location')

    useEffect(() => {
        let API = new Apilib();

        API.getMedicines({})
            .then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log('this is response', res.data.medicines.medicines)
                setmedicineList(res.data.medicines.medicines)
                // medicineList
            })
            .catch((error) => {
                console.log('error', error)
            });


    }, []);



    let l = { id: 'a', type: 'Capsule', title: 'l', rating: '5', amount: '43', imageLink: 'https://store.nvsglassworks.com/wp-content/uploads/2019/08/Lazarus_Naturals_10_capsules_25mg.png' }

    const [items, setItems] = React.useState([{ id: 'a', type: 'Capsule', title: 'Lazarus Naturals CBD', rating: '5', amount: '43.00', imageLink: 'https://store.nvsglassworks.com/wp-content/uploads/2019/08/Lazarus_Naturals_10_capsules_25mg.png' },
    { id: 'a', type: 'Device', title: 'Digital Thermometer', rating: '4', amount: '33.99', imageLink: 'https://cdn.britannica.com/77/80577-050-8E18C22A/thermometer.jpg' },
    { id: 'a', type: 'Device', title: 'Blood Glucose Meters', rating: '5', amount: '89.99', imageLink: 'https://i0.wp.com/post.healthline.com/wp-content/uploads/2021/03/928244-7-Great-Glucometers-1296x728-Header-c0dcdf.jpg?w=1155&h=1528' },
    { id: 'a', type: 'Device', title: 'Digital Thermometer', rating: '4', amount: '33.99', imageLink: 'https://cdn.britannica.com/77/80577-050-8E18C22A/thermometer.jpg' },
    { id: 'a', type: 'Supplement', title: 'Food Supplement', rating: '5', amount: '59.69', imageLink: 'https://scitecnutrition.com/images/product_images/2670_cd5b7970fae0.webp' },
    { id: 'a', type: 'Device', title: 'Digital Thermometer', rating: '4', amount: '33.99', imageLink: 'https://cdn.britannica.com/77/80577-050-8E18C22A/thermometer.jpg' },
    { id: 'a', type: 'Device', title: 'Digital Thermometer', rating: '4', amount: '33.99', imageLink: 'https://cdn.britannica.com/77/80577-050-8E18C22A/thermometer.jpg' }]);




    const [category, setCategory] = useState([
        {
            id: 'All Medicines', categoryName: 'All Medicines', imageLink: tabs
        },
        { id: 'Health Resource Center', categoryName: 'Health Resource Center', imageLink: health },
        { id: 'Healthcare Devices', categoryName: 'Healthcare Devices', imageLink: devices },
        { id: 'Vitamins & Nutritions', categoryName: 'Vitamins & Nutritions', imageLink: vitamins },
        { id: 'Personal Care', categoryName: 'Personal Care', imageLink: personal },
        { id: 'Lab Tests', categoryName: 'Lab Tests', imageLink: labtest }
    ])
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData'))?.length > 0 ? JSON.parse(localStorage.getItem('cartData')) : [])
    const [cartItemsLength, setcartItemsLength] = useState(0)
    const [value, setvalue] = useState('')
    const [suggestions, setsuggestions] = useState([{ value: 'ram' }])


    useEffect(() => {
        console.log('useeffect executed')
        setcartItemsLength(cartItems.length)
    }, [cartItems]);

    const isItemSelected = (id) => items.find((el) => el.id === id);
    const isCategorySelected = (id) => category.find((el) => el.id === id);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
                console.log('getItemById clicked ', itemSelected)
            };


    function itemExist(arr, arr1) {
        let index = 0
        const { length } = arr;
        const id = length + 1;
        const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);
        console.log(found, 'this is found')
        // if (!found) arr.push(arr1);
        return found;
    }

    const notify = () => toast.success("Item Added into cart!");
    const settingcart = (data) => {

        // Batch_Number: "51263"
        // Dose: "NA"
        // ExpDate: "2023-10-31T06:38:00.000Z"
        // Medicine_Name: "Digital thermometer"
        // MfgDate: "2023-10-01T06:38:00.000Z"
        // Price: "150"
        // Quantity: "10"
        // Units: "5"
        // imageLink: "https://5.imimg.com/data5/SELLER/Default/2021/10/JA/WR/YY/47022212/clinical-digital-thermometer.jpg"
        // type: "Device"
        notify()
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        data.qty = 1
        if (itemnow == -1) pr.push(data)
        else pr[itemnow].qty += 1
        console.log(itemnow, 'itemnow herer')

        setcartItems(pr)
        localStorage.setItem('cartData', JSON.stringify(cartItems));
    }

    const handleClickCategory =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                if (id == 'All Medicines') {
                    console.log('showing all medicines')
                    history.push('/AllMedicines')
                }
                if (id == 'Healthcare Devices') {
                    console.log('showing all medicines')
                    history.push('/HealthcareDevices')
                }
                if (id == 'Health Resource Center') {
                    console.log('showing all medicines')
                    history.push('/HealthResourceCenter')
                }
                if (id == 'Vitamins & Nutritions') {
                    console.log('showing all medicines')
                    history.push('/VitaminsNutritions')
                }
                if (id == 'Personal Care') {
                    console.log('showing all medicines')
                    history.push('/PersonalCare')
                }
                if (id == 'Lab Tests') {
                    console.log('showing all medicines')
                    history.push('/LabTest')
                }
                // const itemSelected = isCategorySelected(id);

                // setSelected((currentSelected) =>
                //     itemSelected
                //         ? currentSelected.filter((el) => el !== id)
                //         : currentSelected.concat(id)
                // );
                console.log('getItemById clicked ', id)
            };


    const renderSuggestion = suggestion => (
        <div>
            {suggestion.Medicine_Name}
        </div>
    );

    const onChange = (event, { newValue }) => { setvalue(newValue); };
    const inputProps = {
        placeholder: 'Type a programming language',
        value,
        onChange: onChange
    };

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : medicineList.filter(lang =>
            lang.Medicine_Name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    const getSuggestionValue = suggestion => suggestion.Medicine_Name;

    const onSuggestionsFetchRequested = (value) => {
        setsuggestions(getSuggestions(value));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setsuggestions([]);
    };

    return (
        <div className="homepage">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                newestOnTop={false}
                hideProgressBar
                closeOnClick
                rtl={false}
                theme="light"
            />
            <div className="search-section" style={{
                backgroundImage: "url(" + "https://images.unsplash.com/photo-1638202993928-7267aad84c31?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '320px',
                width: '100%',
                backgroundColor: '#2594a4'
            }}>
                <h2 style={{
                    height: '80px',
                    width: '60%',
                    marginLeft: '50px',
                    marginTop: '6%',
                    fontSize: '18px'
                }}>Medvatic your number one patient centered digital services partner</h2>
                <p>Get Quality Health Care Services Online with Medvatic</p>
                <p>What are you looking for</p>
                {/* <img src="https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg" alt="Medicine" /> */}
                {/* <div style={{ display: 'flex' }}>

                    <input type="text" placeholder="Search for medicines..." style={{ borderRadius: '15px', backgroundColor: '#fff', width: '50%', height: '33px' }} />

                    <div style={{ color: '#fff', padding: '8px', borderRadius: '5px', backgroundColor: '#41d9ff', width: '80px', cursor: 'pointer' }}>Search</div>
                </div> */}
                <div style={{ display: 'flex' }}>

                    {/* {medicineList && <Autosuggest
                        // suggestions={suggestions}
                        // onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        // onSuggestionsClearRequested={onSuggestionsClearRequested}
                        // getSuggestionValue={getSuggestionValue}
                        // renderSuggestion={renderSuggestion}
                        inputProps={medicineList}
                    />} */}
                    {medicineList && <AutoSuggest userList={medicineList} placeHolderText={'wow'} />}

                    {medicineList && <div style={{ color: '#fff', padding: '8px', borderRadius: '5px', backgroundColor: '#41d9ff', width: '100px', cursor: 'pointer', textAlign: 'center', alignSelf: 'center' }}>Search</div>}
                </div>
            </div>

            <div className="categories">
                <h2>Popular Categories</h2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Div306>
                        <Div307>
                            <Div308>
                                {/* <div className="category" style={{ width: '40%', overflow: 'hidden' }}>

                        <div className="search-section" style={{
                            backgroundImage: "url(" + "https://png.pngtree.com/thumb_back/fh260/background/20201101/pngtree-scene-with-geometrical-forms-the-poster-model-minimal-background-render-image_452981.jpg" + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            width: '100%', height: '150px', margin: '10px', overflow: 'hidden', justifyContent: 'center', textTransform: 'capitalize', textAlign: '-webkit-center'
                        }}>
                            <p>order medicine</p>
                            <h3>Get Flat  13% off on first 2 medicine orders</h3>
                            <div style={{ width: '150px', height: '30px', backgroundColor: '#26d4ff', borderRadius: '3px', cursor: 'pointer', margin: '8px' }}
                                onClick={() => {
                                    console.log('clicked button')
                                }}>
                                SHOP NOW
                            </div>
                        </div>
                    </div> */}

                                <div className="category" style={{ overflow: 'hidden', display: 'flex', backgroundColor: '#effdff', margin: '5px', alignItems: 'center' }}>

                                    <div className="search-section" style={{ width: '100%', margin: '10px', overflow: 'hidden' }}>
                                        <p>ORDER MEDICINE</p>
                                        <h4 style={{ fontSize: '23px' }}>Get Flat  13% off on first 2 medicine orders</h4>
                                        <div style={{ width: '150px', height: '30px', backgroundColor: '#26d4ff', borderRadius: '3px', cursor: 'pointer', margin: '8px', textAlign: 'center', blockSize: 'auto' }}
                                            onClick={() => {
                                                console.log('clicked button')
                                                history.push('/AllMedicines')
                                            }}>
                                            SHOP NOW
                                        </div>

                                    </div>


                                    <img src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvMjI4YS1leWUtMDA1OC5qcGc.jpg" alt="Category 2"
                                        style={{ width: '60%', height: '150px', overflow: 'hidden' }}
                                    />
                                </div>


                                <div className="category" style={{ overflow: 'hidden', display: 'flex', backgroundColor: 'antiquewhite', margin: '5px', alignItems: 'center' }}>

                                    <div className="search-section" style={{
                                        // backgroundImage: "url(" + "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg" + ")",
                                        // backgroundPosition: 'center',
                                        // backgroundSize: 'cover',
                                        // backgroundRepeat: 'no-repeat',
                                        width: '100%', margin: '10px', overflow: 'hidden'
                                    }}>
                                        <p>LAB TEST</p>
                                        <h4 style={{ fontSize: '23px' }}>20% discounts for all lab test</h4>
                                        <div style={{ width: '150px', height: '30px', backgroundColor: '#00869a', borderRadius: '3px', cursor: 'pointer', margin: '8px', textAlign: 'center', blockSize: 'auto' }}
                                            onClick={() => {
                                                console.log('clicked button')
                                                history.push('/LabTest')
                                            }}>

                                            SHOP NOW
                                        </div>

                                    </div>


                                    <img src="https://plus.unsplash.com/premium_photo-1661308301418-481aedc518b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" alt="Category 2"
                                        style={{ width: '60%', height: '150px', overflow: 'hidden' }}
                                    />
                                </div>
                            </Div308>
                        </Div307>
                    </Div306>
                </div>

            </div>

            <div style={{ width: '100%', }}>
                <div style={{ width: '80%', marginLeft: '10%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <h6>Explore</h6>
                            <h3>Popular Categories</h3>
                        </div>
                        <div>
                            <div style={{ cursor: 'pointer', color: '#26d4ff' }}>View All</div>
                        </div>
                    </div>

                    <div className="product-list" >
                        {/* <h2>Featured Products</h2>
                        <h3>Our Featured Products</h3> */}
                        {/* <ul style={{ display: 'flex', scrollSnapAlign: 'center', scrollbarColor: "transparent", overflowX: "auto" }}>

                            {['All Medicines', 'Health Resource Center', 'Healthcare Devices', 'Vitamins & Nutritions', 'Personal Care', 'Lab Tests'].map((x) => <div className="product" style={{
                                margin: '10px', padding: '10px', alignContent: 'center', backgroundColor: '#fcfcfc', width: '60%'
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src="https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg" alt="Product 1" style={{ width: '50px', height: '100px', }} />
                                </div>
                                <p style={{ width: '100%', textAlign: 'center' }}>{x} </p>
                                <p></p>
                            </div>)}
                        </ul> */}

                        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                            {category.map(({ categoryName, imageLink, id }) => (
                                // type: 'Capsule', title: 'l', rating: '5', amount: '43', imageLink
                                <CategoryCard
                                    itemId={categoryName} // NOTE: itemId is required for track items
                                    // title={id}
                                    key={id}
                                    title={categoryName}
                                    imageLink={imageLink}
                                    onClick={handleClickCategory(id)}
                                    selected={isItemSelected(id)}

                                />
                            ))}
                        </ScrollMenu>


                    </div>
                </div>
            </div>






            <div className="category" style={{ width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%' }}>

                <div className="search-section" style={{
                    // backgroundImage: "url(" + "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg" + ")",
                    // backgroundPosition: 'center',
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat',
                    width: '80%', height: '250px', backgroundColor: '#e7faff', overflow: 'hidden', display: 'flex', alignItems: 'center',
                }}>
                    <div className="search-section" style={{
                        // backgroundImage: "url(" + "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg" + ")",
                        // backgroundPosition: 'center',
                        // backgroundSize: 'cover',
                        // backgroundRepeat: 'no-repeat',
                        width: '70%', height: '250px', margin: '10px', backgroundColor: '#e7faff', overflow: 'hidden', marginTop: '30px'
                    }}>
                        <p>CHECKUP</p>
                        <h4 style={{ fontSize: '23px' }}>Full Body Health Checkup Up to 20% OFF</h4>
                        <p style={{ fontSize: '18px' }}>Full body health checkup will be done in accordance with the latest machines available, Result may vary based on test selected</p>
                        <div style={{ width: '150px', height: '30px', backgroundColor: '#26d4ff', borderRadius: '3px', cursor: 'pointer', margin: '8px', textAlign: 'center', blockSize: 'auto', color: '#fff' }}
                            onClick={() => {
                                console.log('clicked button')
                                history.push('/LabTest')
                            }}>
                            BOOK NOW
                        </div>

                    </div>


                    <img src="https://img.freepik.com/free-photo/healthcare-workers-prevent-virus-insurance-medicine-concept-smiling-pretty-nurse-doctor-blue-scrubs-pointing-fingers-left-looking-baner-with-pleased-grin-grey-background_1258-57273.jpg?w=740&t=st=1699443789~exp=1699444389~hmac=bee0b7f671a5b8b1a0ff9ee6a0d3abe91de914c2576654a70ee39e02d7642b8c" alt="Category 2"
                        style={{ width: '30%', height: '230px', overflow: 'hidden', margin: '10px' }}
                    />
                </div>
            </div>

            <div className="product-list" >

                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {medicineList && medicineList.map((x) => (
                        // type: 'Capsule', title: 'l', rating: '5', amount: '43', imageLink


                        // <NewCard
                        //     itemId={title} // NOTE: itemId is required for track items
                        //     type={type}
                        //     amount={amount}
                        //     key={title}
                        //     title={title}
                        //     imageLink={imageLink}
                        //     onClick={handleClickCategory(title)}
                        //     selected={isItemSelected(title)}

                        // />

                        // id: 'a', type: 'Device', title: 'Digital Thermometer', rating: '4', amount: '33.99', imageLink: 'https://cdn.britannica.com/77/80577-050-8E18C22A/thermometer.jpg' 
                        <div
                            className="card"
                            style={{
                                width: '260px',
                                justifyContent: 'space-evenly',
                                padding: '10px',
                                margin: '10px'
                            }}
                            tabIndex={0}>
                            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }}
                                onClick={() => {
                                    history.push('/ProductDetails')
                                    x.qty = 1
                                    localStorage.setItem('productDetails', JSON.stringify(x));

                                }}
                            >
                                <img src={x.imageLink ? x.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product 1" style={{ width: '230px', height: '150px', }} />
                            </div>
                            <div style={{ textAlign: 'left', }}>

                                <p style={{ width: '100%', marginBottom: '3px' }}>{x.type} </p>
                                <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{x.Medicine_Name} </p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <p style={{ width: '100%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>{x.Price} </p>
                                    <img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                                        console.log('added to cart ' + x.title)
                                        // let cart = cartItems
                                        // cart.push(x)
                                        // setcartItems(() => cart)
                                        settingcart(x)
                                        console.log(cartItems)
                                    }} />
                                </div>
                            </div>
                        </div>



                    ))}
                </ScrollMenu>


            </div>


            {/* <div style={{ justifyContent: 'center', textAlign: 'center', backgroundColor: '#f9f9f9', marginTop: '15px' }}>
                <div >
                    <p style={{ color: "#000" }}>Testimonials</p>
                    <h2>What Our Clients Say</h2>

                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>

                            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                {items.map(({ id, type, title, rating, amount, imageLink }) => (
                                    // type: 'Capsule', title: 'l', rating: '5', amount: '43', imageLink

                                    <div style={{ width: '100%' }}>
                                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&w=1000&q=80" alt="Person 1" style={{ borderRadius: '50px', width: '100px', height: '100px' }} />
                                        <p style={{ width: '100%' }}>I appreciate your amazing services and professional staff for all your hard work and creative thinking. There are many variations of passages of rem Ipsum available, but the majority have alteration in some form.</p>
                                    </div>

                                ))}
                            </ScrollMenu>
                        </div>
                    </div>

                    <div className="testimonial">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&w=1000&q=80" alt="Person 1" style={{ borderRadius: '50px', width: '100px', height: '100px' }} />
                        <p>I appreciate your amazing services and professional staff for all your hard work and creative thinking. There are many variations of passages of rem Ipsum available, but the majority have alteration in some form.</p>
                    </div>
                    <div className="testimonial">
                        <img src="person-2.jpg" alt="Person 2" />
                        <p>Testimonial text here...</p>
                    </div>

                </div>
            </div> */}

            {/* <div style={{ display: 'flex', marginTop: '50px', justifyContent: "space-evenly", }}> */}
            <Div306>
                <BottomContainer />
            </Div306>


            <Testimonials data={[{ name: 'William Johnson', text: 'I appreciate your amazing services and professional staff for all your hard work and creative thinking. There are many variations of passages of rem Ipsum available, but the majority have alteration in some form.', image: 'https://yt3.googleusercontent.com/hIL2R51j3u9v9SfFGKK4t4Ekbz84m3N_lGXoJoMaFQEMU2CR_Dk-ceg_7aG96OFAzoUpBc2KgP0=s900-c-k-c0x00ffffff-no-rj', id: 'od0', business: 'Entrepreneur' }, { name: 'Ram', text: 'I appreciate your amazing services and professional staff for all your hard work and creative thinking. There are many variations of passages of rem Ipsum available, but the majority have alteration in some form.', image: 'https://media.licdn.com/dms/image/C5103AQEZrj7AYiE5gg/profile-displayphoto-shrink_800_800/0/1576040191145?e=2147483647&v=beta&t=HNyLcC1xGC22bKVST33x9lskW5BpiDFE8wwSWkEUDco', business: 'Entrepreneur' }]} />


            {
                <div className="ChatSupport">
                    <div className="ChatSupport__container">
                        {cartItemsLength && <button className="ChatSupport__cta-btn" onClick={async () => {

                            console.log('yes the cafrt', cartItems.length)
                            // history.push({ pathName: "/Order", state: { cart: cartItems } })

                            localStorage.setItem('cartData', JSON.stringify(cartItems));
                            const items = JSON.parse(localStorage.getItem('cartData'));
                            console.log(items, 'this i s otems')
                            history.push({
                                pathname: "/EditCart",
                                state: { cart: cartItems },
                            });



                        }} style={{ position: 'fixed', top: '13%' }}>
                            {/* <WhatsAppIcon /> &nbsp; Hi! How can I help? */}
                            Checkout {cartItemsLength} items

                        </button>}
                    </div>
                </div >
            }


        </div >
    );
};


const Div306 = styled.div`
  background-color: #effdff;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 37px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div307 = styled.div`
  width: 100%;
  max-width: 1410px;
  @media (max-width: 991px) {
    max-width: 96%;
  }
`;

const Div308 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column18 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;


const Div317 = styled.div`
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin: 0 auto;
  padding: 39px 50px 39px 19px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding-right: 20px;
  }
`;

const Img78 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  max-width: 100%;
`;

const Div318 = styled.div`
  align-self: start;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div319 = styled.div`
  color: #0a0d14;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div320 = styled.div`
  color: #656565;
  margin-top: 11px;
  white-space: nowrap;
  font: 400 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default Medicine;
