import AgoraRTC from "agora-rtc-sdk-ng"
import React, { Component, useEffect, useState, useRef } from "react";
import { Input } from "antd";
import { e } from "../Agora/utils/utils";
import { Button, Modal } from "react-bootstrap";
import Apilib from "../../api";
import { auth } from "../../firebase";
import axios from "../../axios";
import './style.css'
import { useHistory, Redirect } from 'react-router-dom'
// import { setApptData } from "./data";
let temp = 0;
export default function VideoCallLive({ props }) {
    const history = useHistory();

    const [inputValues, setInputValues] = useState({ 0: '', });
    const [counter, setCounter] = useState(0);
    const formRef = useRef();
    const handleClick = () => {
        setCounter(counter => counter + 1);
        // let obj = { [counter]: '' };

        temp++;
        setInputValues({ ...inputValues, [temp]: '' })
        // formRef.current.value.reset();
        formRef.current.value = "";
        console.log(counter);
    };

    // const handleOnChange = (e, index) => {
    //     console.log(inputValues, e.target.className, index);
    //     const abc = [];
    //     abc[e.target.className] = e.target.value;
    //     setInputValues([...inputValues, e.target.className = e.target.value]);

    // };
    const handleOnChange = (e) => {
        const abc = {};
        abc[e.target.className] = e.target.value;
        setInputValues({ ...inputValues, ...abc });
    };
    // window["tokentoverify"] = ""
    let API = new Apilib()
    let options =
    {
        // Pass your App ID here.
        appId: '7b9fa21ad2ed4216a5e0edad08e119fd',
        // Set the channel name.
        channel: '9mBGUBFdch',
        // Pass your temp token here.
        token: '0067b9fa21ad2ed4216a5e0edad08e119fdIAAILZrrnUIbvWiaDHTNuHTxPA0nOGkhWA7JEOZsjt1uim4OPLIAAAAAIgDZQIoogR21YwQAAQBBSrljAgBBSrljAwBBSrljBABBSrlj',
        // Set the user ID.
        uid: 0,
    };

    let [channelParameters, setchannelParameters] = useState(
        {
            // A variable to hold a local audio track.
            localAudioTrack: null,
            // A variable to hold a local video track.
            localVideoTrack: null,
            // A variable to hold a remote audio track.
            remoteAudioTrack: null,
            // A variable to hold a remote video track.
            remoteVideoTrack: null,
            // A variable to hold the remote user id.s
            remoteUid: null,
        });
    const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    const localPlayerContainer = document.createElement("div");

    const remotePlayerContainer = document.createElement("div");



    const expandScreen = "M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z";
    const shrinkScreen = "M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z";

    async function leavecall(setupdateUI) {
        // Destroy the local audio and video tracks.
        if (appointmentData && uid != appointmentData.doctorId) {
            channelParameters.localAudioTrack.close();
            channelParameters.localVideoTrack.close();
            // Remove the containers you created for the local video and remote video.
            removeVideoDiv(remotePlayerContainer.id);
            removeVideoDiv(localPlayerContainer.id);
            // Leave the channel
            await agoraEngine.leave();
            setupdateUI(prev => !prev)
            console.log("You left the appointment");

            // Refresh the page for reuse
            window.location.reload();
            return;
        }
        try {
            let API = new Apilib()

            // setInputDatapres
            // optionss
            // setoptionss(oppt)
            // setInputDataDosage
            let presc = [], dosage = [], timetotakemedicine = [], Notes = [], presNote = [];
            let i = 0;
            let presDet = []
            let presDetFinal = []
            localDataRecord.map((currentElement) => {
                presc.push(currentElement.name)
                dosage.push(currentElement.dosage)
                // Notes.push(inputData)
                presNote.push(currentElement.prescriptionNote)
                // timesToTake.push()
                i++;
                return currentElement
            })
            newItems.map((currentElement) => {
                Notes.push(currentElement.name)
                return currentElement
            })
            optionss.map((currentElement) => {
                let temp = { morning: currentElement.morning, afternoon: currentElement.afternoon, evening: currentElement.evening, night: currentElement.night }
                timetotakemedicine.push(temp)
                return currentElement
            })


            for (let l = 0; l < presc.length; l++) {
                // { morning: '9 am', afternoon: '1 pm', evening: '6 pm' }
                // let timingDay = {}
                // let ismorning = timetotakemedicine[l].morning, isafternoon = timetotakemedicine[l].afternoon, isevening = timetotakemedicine[l].evening, isnight = timetotakemedicine[l].night;

                let timingDay = {
                    MN: !!timetotakemedicine[l].morning,
                    AF: !!timetotakemedicine[l].afternoon,
                    EV: !!timetotakemedicine[l].evening,
                    NT: !!timetotakemedicine[l].night,
                }
                let ismorning = timetotakemedicine[l].morning, isafternoon = timetotakemedicine[l].afternoon, isevening = timetotakemedicine[l].evening, isnight = timetotakemedicine[l].night;



                if (ismorning) { timingDay.MN = !!timetotakemedicine[l].morning }
                if (isafternoon) { timingDay.AF = !!timetotakemedicine[l].afternoon }
                if (isevening) { timingDay.EV = !!timetotakemedicine[l].evening }
                if (isnight) { timingDay.NT = !!timetotakemedicine[l].night }

                let numberdays = (Number(timingDay.MN) + Number(timingDay.AF) + Number(timingDay.EV) + Number(timingDay.NT)) * (durationArray[l] || 1)
                // let numberdays = (Number(timingDay.MN) + Number(timingDay.AF) + Number(timingDay.EV) + Number(timingDay.NT)) * (numberofdays[l].duration || 1)
                // TODO
                // let ambm = beforeorafter[l].meal ? 'Before' : 'After'
                let ambm = beforeorafterarray[l] ? 'Before' : 'After'


                // if (ismorning) { timingDay.morning = '9 am' }
                // if (isafternoon) { timingDay.afternoon = '1 pm' }
                // if (isevening) { timingDay.evening = '6 pm' }
                // if (isnight) { timingDay.night = '9 pm' }
                // timetotakemedicine[l]


                // let saveData = {
                //     name: presc[l],//this is medicine name
                //     intake_time: timingDay,
                //     am_bm_stat: { qd: 'pc', qd: 'pc' },
                //     num_days: 'q2h',
                //     // TODO
                //     // num_days: numberofdays[l].duration,
                //     qty: dosage[l],
                //     subtitle: presNote[l],
                //     frequency: { morning: 'false' }
                // }


                let saveData = {
                    name: presc[l],//this is medicine name
                    intake_time: timingDay,
                    am_bm_stat: { "AF": ambm, "MN": ambm, "EV": ambm, "NT": ambm },
                    num_days: numberdays,
                    // num_days: numberofdays[l].duration,
                    qty: dosage[l],
                    subtitle: presNote[l],
                }
                // let saveData = {
                //     name: presc[l],//this is medicine name
                //     intake_time: timingDay,
                //     am_bm_stat: { "AF": ambm, "MN": ambm, "EV": ambm, "NT": ambm },
                //     num_days: numberofdays[l].duration,
                //     qty: dosage[l],
                //     subtitle: inputDataNote[l].note,
                // }
                presDet.push(saveData)
            }
            // presDet = [
            //     {
            //         medicines: presDet
            //     }
            // ]
            presDetFinal = [
                {
                    medicines: presDet
                }
            ]
            let data = {
                prescription: presc, patientNote: Notes, dosage: dosage, prescriptionNote: presNote, timeToTakeMedicine: timetotakemedicine,
                // saveAppointmentData: appointmentData,
                prescriptions: presDetFinal, type: appointmentData.userType
            }

            // await API.saveAppointmentData(JSON.stringify(data))
            // console.log(JSON.stringify(data), 'this is data sent');
            // let tokenn = await API.saveAppointmentData(data)
            // alert('Appointment Completed')
            // await setApptData(data)
            let lm = JSON.stringify(data)
            history.push({
                pathname: `/doctor/GeneratePrescriptionForm`, search: "?id=5",
                hash: lm,
                state: 'lm'
            });


        } catch (error) {
            console.log(error, 'here is s the error');
        }
        channelParameters.localAudioTrack.close();
        channelParameters.localVideoTrack.close();
        // Remove the containers you created for the local video and remote video.
        removeVideoDiv(remotePlayerContainer.id);
        removeVideoDiv(localPlayerContainer.id);
        // Leave the channel
        await agoraEngine.leave();
        setupdateUI(prev => !prev)
        console.log("You left the appointment");

        // Refresh the page for reuse
        window.location.reload();
    }



    // Remove the video stream from the container.
    function removeVideoDiv(elementId) {
        console.log("Removing " + elementId + "Div");
        let Div = document.getElementById(elementId);
        if (Div) {
            Div.remove();
        }
        console.log('removed video');
    };
    function gup(name) {
        var url = window.location.href;
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        console.log("meetingid", results)
        return results == null ? "" : results[1];
    }
    async function clickJoin(setShowButtons) {
        // Join a channel.
        setShowButtons(false);
        await agoraEngine.join(options.appId, channel, temptoken, options.uid).catch((e) => console.log('something went wrong, cannot join appointment'));
        // Create a local audio track from the audio sampled by a microphone.
        channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Create a local video track from the video captured by a camera.
        channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        // Append the local video container to the page body.
        const videoBox = e("#video-agora-local");
        videoBox.append(localPlayerContainer);
        // Publish the local audio and video tracks in the channel.
        await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]).catch((e) => console.log('something went wrong, cannot join appointment'));;
        // Play the local video track.
        channelParameters.localVideoTrack.play(localPlayerContainer);
        console.log("publish success!");
    }

    const [isfullscreen, setisfullscreen] = useState(false);
    const [rotate, setrotate] = useState(0);

    console.log(props, 'this is the props');
    async function startBasicCall(setupdateUI) {

        // Create an instance of the Agora Engine

        // Dynamically create a container in the form of a DIV element to play the remote video track.
        // Dynamically create a container in the form of a DIV element to play the local video track.

        // Specify the ID of the DIV container. You can use the uid of the local user.
        localPlayerContainer.id = 0;
        localPlayerContainer.class = "video-agora-box"
        remotePlayerContainer.class = "video-agora-box"
        // Set the textContent property of the local video container to the local user id.
        // localPlayerContainer.textContent = "Local user " + 0;
        // Set the local video container size.
        console.log('inside this one');

        localPlayerContainer.style.width = isfullscreen ? "100px" : '400px';
        localPlayerContainer.style.height = isfullscreen ? "100px" : '400px';

        localPlayerContainer.style.padding = "15px 5px 5px 5px";
        // Set the remote video container size.
        remotePlayerContainer.style.width = isfullscreen ? "600px" : '400px';
        remotePlayerContainer.style.height = isfullscreen ? "600px" : '400px';
        remotePlayerContainer.style.marginTop = "5px";
        remotePlayerContainer.style.transform = `rotate(${rotate}deg)`;
        remotePlayerContainer.style.padding = "15px 5px 5px 5px";
        remotePlayerContainer.style.resizeMode = "contain"
        // Listen for the "user-published" event to retrieve a AgoraRTCRemoteUser object.
        agoraEngine.on("user-published", async (user, mediaType) => {

            console.log(user, 'this is user');
            // Subscribe to the remote user when the SDK triggers the "user-published" event.
            await agoraEngine.subscribe(user, mediaType);
            console.log("subscribe success");
            // Subscribe and play the remote video in the container If the remote user publishes a video track.
            if (mediaType == "video") {
                console.log('connected video remote');
                // Retrieve the remote video track.
                channelParameters.remoteVideoTrack = user.videoTrack;
                // Retrieve the remote audio track.
                channelParameters.remoteAudioTrack = user.audioTrack;
                // Save the remote user id for reuse.
                channelParameters.remoteUid = user.uid.toString();
                // Specify the ID of the DIV container. You can use the uid of the remote user.
                remotePlayerContainer.id = user.uid.toString();
                channelParameters.remoteUid = user.uid.toString();
                // remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
                // Append the remote container to the page body.

                const videoBox = e("#video-agora-remote");
                videoBox.append(remotePlayerContainer);
                // Play the remote video track.
                channelParameters.remoteVideoTrack.play(remotePlayerContainer);
            }
            // Subscribe and play the remote audio track If the remote user publishes the audio track only.
            if (mediaType == "audio") {
                // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
                channelParameters.remoteAudioTrack = user.audioTrack;
                // Play the remote audio track. No need to pass any DOM element.
                channelParameters.remoteAudioTrack.play();
            }
            // Listen for the "user-unpublished" event.
            agoraEngine.on("user-unpublished", user => {
                console.log(user.uid + "has left the channel");
                console.log(user);
                if (user._video_muted_) {
                    return remotePlayerContainer.remove()
                }
                if (!user._audio_added_ && !user._video_added_) {
                    return remotePlayerContainer.remove()
                }
                setupdateUI(prev => !prev)
            });
        });

        // Listen to the Join button click event.

        // Listen to the Leave button click event.


    }


    const [screen, setScreen] = useState(false);
    function fullScreen() {
        // setScreen("col - xs - 12");
        console.log('isfullscreen');
        setisfullscreen(!isfullscreen)
        setScreen(!screen);
    }


    let token = '0067b9fa21ad2ed4216a5e0edad08e119fdIAAILZrrnUIbvWiaDHTNuHTxPA0nOGkhWA7JEOZsjt1uim4OPLIAAAAAIgDZQIoogR21YwQAAQBBSrljAgBBSrljAwBBSrljBABBSrlj'
    const [appid, setAppid] = useState("7b9fa21ad2ed4216a5e0edad08e119fd");
    const [channel, setChannel] = useState(gup("meetingid"));
    const [appointmentId, setappointmentId] = useState(gup("appointmentid"));
    const [appointmentData, setappointmentData] = useState();
    const [uid, setuid] = useState(gup("uid"));
    const [temptoken, settemptoken] = useState(gup("token"));
    console.log(temptoken, 'this is temp token');
    const [showbuttons, setShowButtons] = useState(true);
    const [shareholders, setshareholders] = useState([{ name: "" }]);



    let handleNameChange = evt => {
        this.setState({ name: evt.target.value });
    };

    let handleShareholderNameChange = idx => evt => {
        const newShareholders = shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });

        setshareholders({ shareholders: newShareholders });
    };

    let handleSubmit = evt => {
        const { name, shareholders } = this.state;
        alert(`Incorporated: ${name} with ${shareholders.length} shareholders`);
    };

    let handleAddShareholder = () => {
        let shareholderss = shareholders
        //     setCounter(counter => counter + 1);
        //     let obj = { counter: '' }
        //     setInputValues({ ...inputValues, obj })
        //     console.log(counter);
        // };

        // // const handleOnChange = (e, index) => {
        // //     console.log(inputValues, e.target.className, index);
        // //     const abc = [];
        // //     abc[e.target.className] = e.target.value;
        // //     setInputValues([...inputValues, e.target.className = e.target.value]);

        // // };
        // const handleOnChange = (e) => {
        //     const abc = {};
        //     abc[e.target.className] = e.target.value;
        //     setInputValues({ ...inputValues, ...abc });
        console.log('adding shareholder');
        let obj = { name: '' }
        setshareholders([...shareholderss, obj])
        // setshareholders({
        //     shareholderss: shareholders.concat([{ name: "" }])
        // });
    };

    let handleRemoveShareholder = idx => () => {
        let newone = shareholders.filter((s, sidx) => idx !== sidx)
        setshareholders({
            shareholders: newone
        });
    };



    const handleClickJoin = () => {
        console.log({ token })
        if (!appid || !channel || !token) {
            if (!appid) {
                openNotification("appid");
            }
            if (!channel) {
                openNotification("channel");
            }
            if (!token) {
                openNotification("token");
            }
            // setStart(true);
            return;
        }
        setShowButtons(false);

        let options = {
            appId: appid,
            channel: channel,
            token: token,
            uid: uid,
        };
        startBasicCall(options);
        log("join channel success");
        setIsJoin(true);
    };

    const handleClickLeave = () => {
        leaveCall();
        console.log("client leaves channel success");
        setIsJoin(false);
        setShowButtons(true);
        // setStart(false);
    };

    const openNotification = (placement) => {
        notification.open({
            message: "Please enter complete information",
            description: `The ${placement} is empty`,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
    };

    async function getAppointmentDetails(id) {
        console.log(uid, 'this is uid');
        // console.log(gettokenfrom, token, 'this is token');
        await axios.get(
            "appointment/fetchAppointmentDetails?appointment_id=" + id
        ).then(res => {
            console.log(res, 'this is res');
            setappointmentData(res.data.appointment_details)
        }).catch(e => {
            console.log(e, 'spme error ioin request');
        });
        console.log('ending here');
    }

    useEffect(() => {
        setTimeout(() => {
            getAppointmentDetails(appointmentId)
        }, 5000);
    }, []);

    console.log('rerender');
    const [updateUI, setupdateUI] = useState(false);
    const [video, setVideo] = useState(true);
    const [micon, setmicon] = useState(true);
    const [notes, setnotes] = useState(false);
    const [prescription, setprescription] = useState(false);

    const [cam, setcam] = useState(true);

    async function setmicrophone(setupdateUI) {

        if (!micon) channelParameters.localAudioTrack.setEnabled(true);
        else channelParameters.localAudioTrack.setEnabled(false);

        setmicon(mic => !mic)
    }
    async function setrotating(setupdateUI) {

        console.log('rotating');
        if (rotate >= 360) setrotate(rot => 0)

        else setrotate(rot => rot + 90)
        setupdateUI(o => !o)

    }
    async function setnotesfun() {
        setnotes(notes => !notes)
    }
    async function setprescriptionfun() {
        setprescription(pres => !pres)
    }
    async function setcamera(setupdateUI) {

        if (!cam) channelParameters.localVideoTrack.setEnabled(true);
        else channelParameters.localVideoTrack.setEnabled(false);
        setcam(cam => !cam)
    }
    const [isjoin, setIsJoin] = useState(false);


    function videoOff() {
        setVideo(!video);
    }
    useEffect(() => {
        startBasicCall(setupdateUI);

    }, []);

    function handleNotesModalClose() {
        console.log('coloesd modal');
        let presc = [], dosage = [], timetotakemedicine = [], Notes = [], presNote = [];
        let i = 0;
        localDataRecord.map((currentElement) => {
            presc.push(currentElement.name)
            dosage.push(currentElement.dosage)
            // Notes.push(inputData)
            presNote.push(currentElement.prescriptionNote)
            // timesToTake.push()
            i++;
            return currentElement
        })
        newItems.map((currentElement) => {
            Notes.push(currentElement.name)
            return currentElement
        })
        optionss.map((currentElement) => {
            let temp = { morning: currentElement.morning, afternoon: currentElement.afternoon, evening: currentElement.evening, night: currentElement.night }
            timetotakemedicine.push(temp)
            return currentElement
        })
        let data = { prescription: presc, patientNote: Notes, dosage: dosage, prescriptionNote: presNote, timeToTakeMedicine: timetotakemedicine, saveAppointmentData: appointmentData }
        console.log(data);

        setnotes(false)
        // setprescription(false)
    }
    function handlePresModalClose() {
        console.log('coloesd modal');
        setprescription(false)
    }
    const bindInputChannel = (event) => {
        setChannel(event.target.value);
    };






    const LocalStorageData = () => {
        const list = localStorage.getItem("medvaticsTodo")
        if (list) {
            return JSON.parse(list)
        } else {
            return [];
        }
    }
    const [inputData, setInputData] = useState("")
    const [newItems, setNewItems] = useState(LocalStorageData)
    const [isEditItem, setIsEditItem] = useState("")
    const [toggleButton, setToggleButton] = useState(false)

    const [localDataRecord, setlocalDataRecord] = useState(LocalStorageData)
    const [isEditItemPres, setIsEditItemPres] = useState("")
    const [prescriptionFormControl, setprescriptionFormControl] = useState("")
    const [inputDataDosage, setInputDataDosage] = useState("")
    const [duration, setDuration] = useState("")
    const [durationArray, setDurationArray] = useState([])
    const [beforeorafter, setbeforeorafter] = useState(false)
    const [beforeorafterarray, setbeforeorafterarray] = useState([])
    const [inputDataTiming, setInputDataTiming] = useState("")
    const [prescriptionNote, setprescriptionNote] = useState("")
    // STORING DATA INTO LOCAL STORAGE
    // useEffect(() => {
    //     localStorage.setItem("medvaticsTodo", JSON.stringify(newItems))
    // }, [newItems])

    const addItem = () => {
        if (!inputData) {
            alert('Please enter something to add.')
        }
        else if (inputData && toggleButton) {
            setNewItems(
                newItems.map((currentElement) => {
                    if (currentElement.id === isEditItem) {
                        return { ...currentElement, name: inputData }
                    }
                    return currentElement
                })
            )
            setInputData('')
            setIsEditItem(null)
            setToggleButton(false)
        }
        else {
            const newInputDataBundle = {
                id: new Date().getTime().toString(),
                name: inputData
            }
            setNewItems([...newItems, newInputDataBundle])
            setInputData('')
        }
    }
    const addItemPres = () => {
        let currentIndex = 0;
        let currentIndexCounter = 0;
        let fixedIndex = 0;
        if (!prescriptionFormControl) {
            alert('Please enter something to add.')
        }
        else if (prescriptionFormControl && toggleButton) {
            // setoptionss([...optionss, optionsss[0]])


            setlocalDataRecord(
                localDataRecord.map((currentElement) => {
                    console.log(currentIndex === isEditItemPres, currentElement.id, isEditItemPres, 'in else if');
                    if (currentIndex === isEditItemPres) {
                        console.log('in optionspres trueee', fixedIndex);

                        currentIndexCounter = currentIndex
                        return {
                            ...currentElement, name: prescriptionFormControl, dosage: inputDataDosage, timing: inputDataTiming, prescriptionNote: prescriptionNote, beforeorafter: beforeorafter, duration: duration
                        }
                    }
                    console.log('in optionspres false', fixedIndex);
                    currentIndex++;
                    return currentElement
                })
            )
            setoptionss(
                optionss.map((currentElement) => {
                    if (fixedIndex === currentIndexCounter) {
                        console.log('in options trueee', fixedIndex);
                        return {
                            ...currentElement,
                            morning: optionsss[0].morning,
                            afternoon: optionsss[0].afternoon,
                            evening: optionsss[0].evening,
                            radioName: optionsss[0].radioName

                        }
                    }
                    console.log('in options', fixedIndex);
                    fixedIndex++;
                    return currentElement
                })
            )
            fixedIndex = 0;

            setprescriptionFormControl('')
            setInputDataDosage('')
            setInputDataTiming('')
            setDuration('')
            setbeforeorafter(false)
            setIsEditItemPres(null)
            setToggleButton(false)
        }
        else {
            console.log('in else else');
            setoptionss([...optionss, optionsss[0]])
            const newInputDataBundle = {
                id: new Date().getTime().toString(),
                name: prescriptionFormControl,
                dosage: inputDataDosage,
                timing: inputDataTiming,
                prescriptionNote: prescriptionNote,
                beforeorafter: beforeorafter,
                duration: duration
            }
            setbeforeorafterarray([...beforeorafterarray, beforeorafter])
            setDurationArray([...durationArray, duration])
            setlocalDataRecord([...localDataRecord, newInputDataBundle])
            setprescriptionFormControl('')
            setInputDataDosage('')
            setDuration('')
            setInputDataTiming('')
            setprescriptionNote('')
        }
    }

    const editItem = (index) => {
        const item_todo_edited = newItems.find((currentElement) => {
            return currentElement.id === index
        })
        setInputData(item_todo_edited.name)
        setIsEditItem(index)
        setToggleButton(true)
    }
    const editItemPres = (id, index) => {
        console.log(index, optionss, optionss[index], optionsss, 'this in else ');
        setoptionsss(() => [optionss[index]])
        const item_todo_edited = localDataRecord.find((currentElement) => {
            return currentElement.id === id
        })
        setprescriptionFormControl(item_todo_edited.name)
        // setInputDatapres()
        // setInputDatapres(item_todo_edited.name)
        // setoptionsss([optionss[index]])
        setInputDataDosage(item_todo_edited.dosage)
        setInputDataTiming(item_todo_edited.timing)
        setprescriptionNote(item_todo_edited.prescriptionNote)
        setbeforeorafter(item_todo_edited.beforeorafter)
        setDuration(item_todo_edited.duration)
        setIsEditItemPres(index)
        setToggleButton(true)
    }

    // DELETE TODOS
    const deleteItem = (index) => {
        const remainingTodos = newItems.filter((currentElement) => {
            return currentElement.id !== index
        })
        setNewItems(remainingTodos)
    }
    const deleteItemPres = (indexs) => {
        const remainingTodos = localDataRecord.filter((currentElement) => {
            return currentElement.id !== indexs
        })

        let m = optionss.filter((currentElement, index) => {
            return index !== indexs
        })
        setoptionss([...optionss, m])
        setlocalDataRecord(remainingTodos)
    }
    const RemoveAll = () => {
        // setNewItems([])
        setnotes(notes => false)
        setprescriptionFormControl('')
        setInputDataDosage('')
        setprescription(pres => false)

    }
    let [optionss, setoptionss] = useState([])
    let [optionsss, setoptionsss] = useState([{
        morning: false,
        afternoon: false,
        evening: false, night: false, radioName: 'mnop'
    }])


    let onInputChange = ({ target, index }) => {
        // () => {
        //     if (props.handleChecked) {
        //         lo.morning(!lo.morning)
        //     }
        // }

        console.log(target.value, target, 'this is target', index);
        // const { cards } = this.state;

        let oppt = optionss.map(opt => {
            let m1 = opt.radioName + 'morning'
            let m2 = opt.radioName + 'afternoon'
            let m3 = opt.radioName + 'evening'

            const checked = opt.radioName === target.name;
            let m = {}
            let tempmorning = opt.morning,
                tempafternoon = opt.afternoon,
                tempevening = opt.evening;
            if (m1 == target.name) {
                // target.value
                tempmorning = !opt.morning
            } else if (m2 == target.name) {
                // target.value
                tempafternoon = !opt.afternoon
            } else if (m3 == target.name) {
                // target.value
                tempevening = !opt.evening
            } else {
                console.log('in else part');
            }
            // if (checked) {
            return {
                ...opt,
                selected: checked,
                morning: tempmorning,
                afternoon: tempafternoon,
                evening: tempevening,
                night: tempnight,
            }
            // }
            // else return opt
        })
        console.log(target.value, oppt, 'this is target1');
        // setState({ cards: nexState })
        setoptionss(oppt)
        // optionss = oppt
        console.log(oppt, 'this is inside');

    }

    const onInputChangeTemp = ({ target, index }) => {
        // () => {
        //     if (props.handleChecked) {
        //         lo.morning(!lo.morning)
        //     }
        // }

        console.log(target.value, target, 'this is target', index);
        // const { cards } = this.state;

        let oppt = optionsss.map(opt => {
            let m1 = opt.radioName + 'morning'
            let m2 = opt.radioName + 'afternoon'
            let m3 = opt.radioName + 'evening'
            let m4 = opt.radioName + 'night'

            const checked = opt.radioName === target.name;
            let m = {}
            let tempmorning = opt.morning,
                tempafternoon = opt.afternoon,
                tempevening = opt.evening,
                tempnight = opt.night;
            if (m1 == target.name) {
                // target.value
                console.log('this is target if morning');
                tempmorning = !opt.morning
            } else if (m2 == target.name) {
                // target.value
                console.log('this is target if afternoon');
                tempafternoon = !opt.afternoon
            } else if (m3 == target.name) {
                console.log('this is target if afternoon');
                // target.value
                tempevening = !opt.evening
            } else if (m4 == target.name) {
                console.log('this is target if night');
                // target.value
                tempnight = !opt.night
            } else {
                console.log('in else part');
            }
            // if (checked) {
            return {
                ...opt,
                selected: checked,
                morning: tempmorning,
                afternoon: tempafternoon,
                evening: tempevening,
                night: tempnight,
                radioName: opt.radioName
            }
            // }
            // else return opt
        })

        // optionss.map((currentElement) => {
        //     if (fixedIndex === currentIndexCounter) {
        //         return {
        //             ...currentElement,
        //             morning: optionsss[0].morning,
        //             afternoon: optionsss[0].afternoon,
        //             evening: optionsss[0].evening, radioName: fixedIndex + 'mno'

        //         }
        //     }
        //     fixedIndex++;
        //     return currentElement
        // })
        console.log(target.value, oppt, 'this is targetqq1');
        // setState({ cards: nexState })
        setoptionsss(oppt)

        // optionss = oppt
        console.log(oppt, 'this is inside');

    }

    return <div class="row">

        {notes && < Modal show={notes} onHide={() => handleNotesModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <>
                    <div className="main-div">
                        <div className="child-div">
                            <div className="AddItem" style={{ flexDirection: 'row' }}>
                                <input type="text"
                                    placeholder='Add Note here...'
                                    className='form-control'
                                    value={inputData}
                                    onChange={(event) => setInputData(event.target.value)}
                                />
                                {
                                    toggleButton ?
                                        (<i className="fa fa-edit add-btn" onClick={addItem}>save</i>) :
                                        (<i className="fa fa-plus add-btn" style={{ padding: '5px', marginTop: '10px' }} onClick={addItem}>Add</i>)
                                }
                            </div>
                            <div className="showItems">
                                {
                                    newItems.map((currentElement) => {
                                        return (
                                            <div className="eachItem" key={currentElement.id}>
                                                <h3>{currentElement.name}</h3>
                                                <div className="todo-btn">

                                                    <i className="fa fa-edit add-btn" onClick={() => editItem(currentElement.id)}></i>
                                                    <i className="fa fa-trash add-btn" onClick={() => deleteItem(currentElement.id)}></i>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/* <div className="showItems">
                                <button className="btn effect04" data-sm-link-text="SAVE" onClick={RemoveAll}>
                                    <span>SAVE</span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <div className="showItems">
                    <button className="btn effect04" data-sm-link-text="SAVE" onClick={RemoveAll}>
                        <span>SAVE</span>
                    </button>
                </div>
                <Button onClick={() => handleNotesModalClose()}>Close</Button>
            </Modal.Footer>
        </Modal>}


        {prescription && < Modal show={prescription} onHide={() => handlePresModalClose()}  >
            <Modal.Header closeButton>
                <Modal.Title>Prescription</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <>
                    <div className="main-div">
                        <div className="child-div">
                            <div className="AddItem" style={{ flexDirection: 'row' }}>
                                <textarea type="text"
                                    wrap="hard"
                                    style={{ minHeight: '50px' }}
                                    placeholder='Add Prescription here...'
                                    className='form-control'
                                    value={prescriptionFormControl}
                                    onChange={(event) => setprescriptionFormControl(event.target.value)}
                                />
                                {/* <input type="text"
                                    placeholder='Add Timing here...'
                                    className='form-control'
                                    value={inputDataTiming}
                                    onChange={(event) => setInputDataTiming(event.target.value)}
                                /> */}



                                {optionsss[0] && <div style={{
                                    justifyContent: 'center!important', width: '15px', flexDirection: 'row', display: 'flex',
                                    gap: '5px',
                                    margin: 'auto', alignItems: 'center', fontWeight: '500',
                                    width: '100%',
                                    margin: '10px',
                                    padding: '10px'
                                }}>INTERVAL
                                    <input
                                        id={'morning'}
                                        type='checkbox'
                                        name={optionsss[0].radioName + 'morning'}
                                        checked={!!optionsss[0].morning}
                                        value={!!optionsss[0].morning}
                                        onClick={onInputChangeTemp}
                                        style={{
                                            display: 'block',
                                            gap: '10px',
                                            justifyContent: 'space-around',
                                            width: '20px',
                                            flexBasis: '5%',
                                            display: 'flex',
                                            alignSelf: 'center',
                                            justifyContent: 'center'

                                        }}
                                    />MORNING
                                    <input
                                        style={{
                                            display: 'flex',
                                            gap: '10px',
                                            justifyContent: 'space-around',
                                            width: '20px',
                                            flexBasis: '5%',
                                            display: 'flex',
                                            alignSelf: 'center',
                                            justifyContent: 'center'
                                        }}
                                        key={'evening'}
                                        type="checkbox"
                                        name={optionsss[0].radioName + 'afternoon'}
                                        value={!!optionsss[0].afternoon}
                                        checked={!!optionsss[0].afternoon}
                                        onClick={onInputChangeTemp}
                                    />AFTERNOON
                                    <input
                                        style={{
                                            gap: '10px',
                                            justifyContent: 'center',
                                            width: '20px',
                                            flexBasis: '5%',
                                            alignSelf: 'center',
                                            justifyContent: 'center'
                                        }}

                                        type="checkbox"
                                        name={optionsss[0].radioName + 'evening'}
                                        value={!!optionsss[0].evening}
                                        checked={!!optionsss[0].evening}
                                        onClick={onInputChangeTemp}
                                    />EVENING
                                    <input
                                        style={{
                                            gap: '10px',
                                            justifyContent: 'center',
                                            width: '20px',
                                            flexBasis: '5%',
                                            alignSelf: 'center',
                                            justifyContent: 'center'
                                        }}

                                        type="checkbox"
                                        name={optionsss[0].radioName + 'night'}
                                        value={!!optionsss[0].night}
                                        checked={!!optionsss[0].night}
                                        onClick={onInputChangeTemp}
                                    />NIGHT

                                </div>}

                                <input type="text"
                                    placeholder='Add Dosage here...'
                                    className='form-control'
                                    value={inputDataDosage}
                                    onChange={(event) => setInputDataDosage(event.target.value)}
                                />
                                <div style={{
                                    flexDirection: 'row', display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'normal', margin: 'auto',
                                }}>


                                    {/* </div> */}
                                    {/* <div> */}

                                    <input type="number"
                                        placeholder='Duration'
                                        className='form-control'
                                        value={duration}
                                        onChange={(event) => setDuration(event.target.value)}
                                    />

                                    <input type="checkbox"

                                        style={{
                                            gap: '10px',
                                            justifyContent: 'center',
                                            width: '20px',
                                            flexBasis: '5%',
                                            alignSelf: 'center',
                                            justifyContent: 'center'
                                        }}

                                        placeholder='After Food'
                                        className='form-control'
                                        value={beforeorafter}
                                        checked={beforeorafter}
                                        onChange={(event) => setbeforeorafter(!beforeorafter)}
                                    />
                                    <div style={{
                                        flexDirection: 'row', display: 'flex',
                                        gap: '10px',
                                        justifyContent: 'normal', margin: 'auto',
                                        padding: '10px'
                                    }}>
                                        Before Food
                                    </div>
                                </div>
                                <input type="text"
                                    placeholder='Add Note here...'
                                    className='form-control'
                                    value={prescriptionNote}
                                    onChange={(event) => setprescriptionNote(event.target.value)}
                                />
                                {
                                    toggleButton ?
                                        (<i className="fa fa-edit add-btn" onClick={addItemPres}>save</i>) :
                                        (<i className="fa fa-plus add-btn" style={{ padding: '5px', marginTop: '10px' }} onClick={addItemPres}>Add</i>)
                                }
                            </div>
                            <div className="showItems">
                                {
                                    !!localDataRecord.length &&
                                    <div className="eachItem" style={{ wordBreak: 'normal', backgroundColor: 'rgb(221, 238, 255)', color: '#1890ff', borderRadius: '3px', marginLeft: '0px', padding: '10px', width: '100%' }} key={'fajhfaashasla'} disabled>
                                        <h3 style={{ width: '50%', fontSize: '14px', color: '#1890ff' }}>Prescription</h3>
                                        <h3 style={{ width: '10%', fontSize: '14px', color: '#1890ff' }}>Timing</h3>
                                        <h3 style={{ width: '30%', fontSize: '14px', color: '#1890ff' }}>Dosage</h3>
                                        <div className="todo-btn">

                                            <h3 style={{ fontSize: '14px', color: '#1890ff' }}>Edit</h3>
                                            <h3 style={{ fontSize: '14px', color: '#1890ff' }}>Delete</h3>
                                        </div>
                                    </div>
                                }
                                {
                                    localDataRecord.map((currentElement, index) => {
                                        console.log(currentElement, 'this is current element ');
                                        return (
                                            <div className="eachItem" key={currentElement.id}>
                                                <h3 style={{ width: '50%', fontSize: '13px', }}>{currentElement.name}</h3>
                                                <h3 style={{ width: '10%', fontSize: '13px', }}>


                                                    <div style={{ flexDirection: 'column', display: 'inline', justifyContent: 'center!important', display: 'flex', }}>
                                                        <input
                                                            id={index + 'mimnsssd'}
                                                            type='checkbox'
                                                            name={optionss[index].radioName + 'morning'}
                                                            checked={optionss[index].morning}
                                                            // onClick={onInputChange}
                                                            style={{
                                                                display: 'flex',
                                                                gap: '10px',
                                                                justifyContent: 'space-around',
                                                                width: '30px',
                                                                flexBasis: '25%',
                                                                display: 'flex',
                                                                alignSelf: 'center',
                                                                justifyContent: 'center'

                                                            }}
                                                        />M




                                                        <input
                                                            style={{
                                                                display: 'flex',
                                                                gap: '10px',
                                                                justifyContent: 'space-around',
                                                                width: '30px',
                                                                flexBasis: '25%',
                                                                display: 'flex',
                                                                alignSelf: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            key={index + 'mimnsd'}
                                                            type="checkbox"
                                                            name={optionss[index].radioName + 'afternoon'}
                                                            value={!!optionss[index].afternoon}
                                                            checked={!!optionss[index].afternoon}
                                                        // onClick={onInputChange}
                                                        />A

                                                        <input
                                                            style={{
                                                                display: 'flex',
                                                                gap: '10px',
                                                                justifyContent: 'space-around',
                                                                width: '30px',
                                                                flexBasis: '25%',
                                                                display: 'flex',
                                                                alignSelf: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            type="checkbox"
                                                            name={optionss[index].radioName + 'evening'}
                                                            value={!!optionss[index].evening}
                                                            checked={!!optionss[index].evening}
                                                        // onClick={onInputChange}
                                                        />E

                                                        <input
                                                            style={{
                                                                display: 'flex',
                                                                gap: '10px',
                                                                justifyContent: 'space-around',
                                                                width: '30px',
                                                                flexBasis: '25%',
                                                                display: 'flex',
                                                                alignSelf: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            type="checkbox"
                                                            name={optionss[index].radioName + 'night'}
                                                            value={!!optionss[index].night}
                                                            checked={!!optionss[index].night}
                                                        // onClick={onInputChange}
                                                        />N

                                                    </div>

                                                    {/* } */}
                                                </h3>
                                                <h3 style={{ width: '30%', fontSize: '13px', }}>{currentElement.dosage}</h3>
                                                <div className="todo-btn">

                                                    <i className="fa fa-edit add-btn" onClick={() => editItemPres(currentElement.id, index)}></i>
                                                    <i className="fa fa-trash add-btn" onClick={() => deleteItemPres(currentElement.id)}></i>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/* <div className="showItems">
                                <button className="btn effect04" data-sm-link-text="SAVE" onClick={RemoveAll}>
                                    <span>SAVE</span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <div className="showItems">
                    <button className="btn effect04" data-sm-link-text="SAVE" onClick={RemoveAll}>
                        <span>SAVE</span>
                    </button>
                </div>
                <Button onClick={() => handlePresModalClose()}>Close</Button>
            </Modal.Footer>
        </Modal>}

        <div style={{ flex: 1, flexDirection: 'row' }}>
            <div className="home-box agora-wrapper">
                <div className="title-box">
                    <span id="title-agora">
                        <img src="/assets/img/logo-main.png" className="logo" />
                    </span>
                </div>
                <div className="message-box">
                    <div>
                        <span className="text-agora">AppID</span>
                        <div className="input-box">
                            {/* <Input placeholder="Enter Appid" value={appid} onChange={bindInputAppid} readOnly allowClear='true'/> */}
                            <Input placeholder="Enter Appid" value={appid} readOnly />
                        </div>
                    </div>
                    <div>
                        <span className="text-agora">Token</span>
                        <div className="input-box">
                            {/* <Input placeholder="Enter Token" value={token} onChange={bindInputToken} readOnly allowClear='true'/> */}
                            <Input placeholder="Enter Token" value={token} readOnly />
                        </div>
                    </div>
                    <div>
                        <span className="text-agora">Channel</span>
                        <div className="input-box">
                            <Input
                                placeholder="Enter Channel Name"
                                value={channel}
                                onChange={bindInputChannel}
                                readOnly
                                allowClear="true"
                            />
                            {/* <Input placeholder="Enter Channel Name" value={channel} readOnly /> */}
                        </div>
                    </div>
                    <div>
                        <span className="text-agora">UID</span>
                        <div className="input-box">
                            <Input
                                placeholder="Enter Channel Name"
                                value={channel}
                                onChange={bindInputChannel}
                                readOnly
                            />
                            {/* <Input placeholder="Enter UID" value={uid} readOnly /> */}
                        </div>
                    </div>
                </div>

                <div className={screen ? "video-agora-box stretched" : "video-agora-box"}>
                    <div id="video-agora-local"></div>
                    <div id="video-agora-remote"></div>
                </div>

                <div className="page-footer">
                    <hr className="hrbottom" />
                    <div className="page-footer-inner">
                        {token ? (
                            <div className="click-box">
                                {showbuttons ? (
                                    <div><button
                                        className="button"
                                        type="primary"
                                        onClick={async () => {
                                            clickJoin(setShowButtons)

                                            // // let API = new Apilib()
                                            // let m = await getAppointmentDetails(appointmentId)
                                            // // let tokenn = await API.getAPIToken()
                                            // console.log(m);


                                        }}
                                    >
                                        Join Now
                                    </button>

                                    </div>

                                ) : (
                                    <div className="page-footer-inner">
                                        {/* <svg
                    width="3em"
                    height="3em"
                    viewBox="0 0 16 16"
                    className="bi bi-mic-fill"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={micOff}
                  >
                    {mic ? mute : closeMute}
                  </svg>
                  <svg
                    width="3em"
                    height="3em"
                    viewBox="0 0 16 16"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleCamera}
                  >
                    <path
                      fillRule="evenodd"
                      d={video ? videoOn : videoClose}
                    />
                  </svg> */}
                                        <svg
                                            width="2.5em"
                                            height="2.5em"
                                            viewBox="0 0 16 16"
                                            className="bi bi-arrows-fullscreen"
                                            fill="black"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => fullScreen()}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d={screen ? shrinkScreen : expandScreen}
                                            />
                                        </svg>

                                        {/* <i className="fa fa-repeat fa-3x" style={{ color: micon ? "black" : 'red' }} onClick={() => setrotating(setupdateUI)}></i> */}


                                        <i className="fa fa-microphone fa-3x" style={{ color: micon ? "black" : 'red' }} onClick={() => setmicrophone(setupdateUI)}></i>

                                        {
                                            appointmentData && uid == appointmentData.doctorId &&
                                            <i className="fa fa-sticky-note-o fa-3x" style={{ color: "black" }} onClick={() => setprescriptionfun()}></i>}

                                        {/* if user and doctorid both are same then open modal */}
                                        {
                                            appointmentData && uid == appointmentData.doctorId &&
                                            <i className="fa fa-file-text-o fa-3x" style={{ color: "black" }} onClick={() => setnotesfun()}></i>}

                                        <i className="fa fa-camera fa-3x" style={{ color: cam ? "black" : 'red' }} onClick={() => setcamera(setupdateUI)}></i>

                                        <svg
                                            width="3em"
                                            height="3em"
                                            viewBox="0 0 16 16"
                                            className="bi bi-telephone-fill"
                                            fill="red"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => leavecall(setupdateUI)}
                                            disabled={!isjoin}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className="scroll-to-top">
                        <i className="icon-arrow-up"></i>
                    </div>
                </div>
            </div>
        </div>
        {/* <div style={{ flex: 1 }}>
            <button type="button" id="join" onClick={() => clickJoin()}>Join</button>
            <button type="button" id="leave" onClick={() => leavecall(setupdateUI)}>Leave</button>
        </div> */}
    </div>
}
