import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import { Formik, useFormik } from "formik";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import BaseSelect from "react-select";
import * as Yup from "yup";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Loader from "../Loader/Loader";

let allowedFiles = ".pdf,.doc,.docx,.jpg,.png,.jpeg";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

let resolver, rejector;
export default class View_Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFormDirty: false,
      appointments: [],
      prescription: [],
      pp_url: "",
      medicinesToDelete: [],
      modalLoading: false,
      modalChat: false,
      Internally: false,
      Externally: false,
      chat: [],
      messages: "",
      currentAppointment: {},
      medicine: {
        name: "",
        subtitle: "",
        intake_time: [],
        am_bm_stat: [],
        num_days: "0",
        qty: "0",
      },
      intake_time: {
        MN: 0,
        AF: 0,
        EV: 0,
        NT: 0,
      },
      am_bm_stat: {
        MN: { value: "2" },
        AF: { value: "2" },
        EV: { value: "2" },
        NT: { value: "2" },
      },
      sequence: ["MN", "AF", "EV", "NT"],
      medicineArray: [],
      medicineTable: [],
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      error: "",
      success: false,
      fetchComments: false,
      addMedicineValid: false,
      internalComments: [],
      externalComments: [],
      fees: {
        video_call: 0,
        medicine: 0,
        consultation: 0,
        misc: 0,
        base_fee: +props.userData.base_fee,
        total: +props.userData.base_fee,
      },
      preferredPharmacy: {},
      pharmacyArr: [],
      listOfPharmacies: [],
      isPharmacySelected: false,
      isPharmacyDataLoaded: false,
      isPharmacyError: false,
      isError: false,
      medError: "",
    };
    console.log("props 2", props);
  }

  async componentDidMount() {
    let API = new Apilib();
    let { appointmentId } = this.props.match.params;
    console.log("params->", appointmentId);
    let medicines = [];
    if (this.props.location.state !== undefined || null) {
      this._isMounted = true;
      this.setState({
        loading: true,
      });
      console.log("view appointment propsss", this.props.location.state);
      API.getAppointmentDetails(appointmentId)
        .then((result) => {
          let res = result.data

          let prescription_details = res.prescription_details;
          let patientId = res.appointment_details.patientId;
          if (this._isMounted) {
            console.log("get appointment details res", res);

            //call a function to initialize pharmacyArr
            this.setState({
              loading: false,
              currentAppointment: {
                id: res.appointment_details.appointmentId,
                name: res.appointment_details.name,
                appointmentType: res.appointment_details.appointmentType,
                slot_time: res.appointment_details.slot_time,
                slot_date: res.appointment_details.slot_date,
                phoneno: res.appointment_details.phoneno,
                country_code: res.appointment_details.country_code,
                email: res.appointment_details.patient_email,
                chiefComplaints: res.appointment_details.chiefComplaints,
                pointsFromHistory: res.appointment_details.pointsFromHistory,
                labFindings: res.appointment_details.labFindings,
                suggestedInvestigations:
                  res.appointment_details.suggestedInvestigations,
                diagnosis: res.appointment_details.diagnosis,
                provisionalDiagnosis:
                  res.appointment_details.provisionalDiagnosis,
                specialInstruction: res.appointment_details.specialInstruction,
                fees: res.appointment_details.fees,
              },
              preferredPharmacy: res.appointment_details.preferredPharmacy,
              prescription_details,
              fees: res.appointment_details.fees
                ? { ...this.state.fees, ...res.appointment_details.fees }
                : {
                  ...this.state.fees,
                  base_fee: res.appointment_details.base_fee,
                  total: res.appointment_details.base_fee,
                },
            });
            if (prescription_details.length !== 0) {
              for (let key of prescription_details) {
                if (key.medicines.length !== 0) {
                  let medIndex = 0;
                  for (let item of key.medicines) {
                    item.prescriptionId = key.prescriptionId;
                    item.medIndex = medIndex;
                    medicines.push(item);
                    medIndex += 1;
                  }
                }
              }
            }
            this.setState({
              medicineTable: [...medicines],
            });
          }
        })
        .catch((error) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
            });
          }
        });
    } else {
      this.props.history.push("/doctor/patients");
    }
    console.log("view appointments", this.props);

    // if ($(".datatable-medicines").length > 0) {
    //   $(".datatable-medicines").DataTable({
    //     "order": []
    //   });
    // }
  }

  modalClose(e) {
    // e.preventDefault();
    this.setState({
      modalChat: !this.state.modalChat,
    });
  }

  render() {
    const AM_BM_Stat = [
      { value: "0", label: "After Meal" },
      { value: "1", label: "Before Meal" },
    ];
    // if ($(".datatable-medicines").length > 0) {
    //   $(".datatable-medicines").DataTable({
    //     "order": []
    //   });
    // }
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        {/* <div className="row">
          <div className="col-6">
            <h4 className="page-title doc-title">Edit Appointment</h4>
          </div>
          <div className="col-6">
          
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-8">
            <h4 className="page-title">View Appointment</h4>
          </div>
          <div className="col-md-4 pl-5"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form>
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Appointment Updated Successfully
                </div>
              )}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Patient Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="name"
                      value={this.state.currentAppointment.name || ""}
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Appointment Type <span className="text-danger">*</span>
                    </label>
                    <input
                      name="appointmenttype"
                      value={
                        this.state.currentAppointment.appointmentType == 1
                          ? "Physical"
                          : "Video"
                      }
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <div className="form-group">
                        <label>Appointment Date</label>
                        <div className="time-icon">
                          <input
                            name="time"
                            disabled
                            value={this.state.currentAppointment.slot_date}
                            readOnly
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      {" "}
                      <div className="form-group">
                        <label>Time</label>
                        <div className="time-icon">
                          <input
                            name="time"
                            value={this.state.currentAppointment.slot_time}
                            disabled
                            readOnly
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Patient Phone Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      name="phonenumber"
                      value={this.state.currentAppointment.phoneno}
                      readOnly
                      disabled
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Patient Email</label>
                    <input
                      className="form-control"
                      value={this.state.currentAppointment.email}
                      type="email"
                      name="patientemail"
                      disabled
                      readOnly
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6"></div> */}
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Chief Complaints</label>
                    <textarea
                      name="chiefComplaints"
                      disabled
                      className="form-control"
                      value={
                        this.state.currentAppointment.chiefComplaints || ""
                      }
                    >
                      {this.state.currentAppointment.chiefComplaints || ""}
                    </textarea>
                  </div>
                </div> */}
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Relevant points from history</label>
                    <textarea
                      name="pointsFromHistory"
                      disabled
                      className="form-control"
                      value={
                        this.state.currentAppointment.pointsFromHistory || ""
                      }
                    >
                      {this.state.currentAppointment.pointsFromHistory || ""}
                    </textarea>
                  </div>
                </div> */}
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Examination / Lab Findings</label>
                    <textarea
                      name="labFindings"
                      disabled
                      className="form-control"
                      value={this.state.currentAppointment.labFindings || ""}
                    >
                      {this.state.currentAppointment.labFindings || ""}
                    </textarea>
                  </div>
                </div> */}
                {/* 
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Suggested Investigations</label>
                    <textarea
                      name="suggestedInvestigations"
                      disabled
                      className="form-control"
                      value={
                        this.state.currentAppointment.suggestedInvestigations ||
                        ""
                      }
                    >
                      {this.state.currentAppointment.suggestedInvestigations ||
                        ""}
                    </textarea>
                  </div>
                </div> */}
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Diagnosis</label>
                    <textarea
                      name="diagnosis"
                      disabled
                      className="form-control"
                      value={this.state.currentAppointment.diagnosis || ""}
                    >
                      {this.state.currentAppointment.diagnosis || ""}
                    </textarea>
                  </div>
                </div> */}
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Provisional Diagnosis</label>
                    <textarea
                      name="provisionalDiagnosis"
                      disabled
                      className="form-control"
                      value={
                        this.state.currentAppointment.provisionalDiagnosis || ""
                      }
                    >
                      {this.state.currentAppointment.provisionalDiagnosis || ""}
                    </textarea>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
