// jshint esversion:6

import React, { Component } from "react";

export default class Product extends Component {
  render() {
    return (
      <div>
        <section className="section product-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row" id="top">
              <div className="col-lg-9 col-md-9">
                <div className="banner-content">
                  <h1>
                    Medvatic is the easiest way to manage Post- Consultation
                    care with your Patients.
                  </h1>
                  <p>
                    Connecting their long term health outcomes with your
                    existing consultation processes and practices. Available as
                    a mobile app for both iOS and Android devices, Medvatic
                    allows practitioners to fully document each consultation,
                    prepare treatment plans and ongoing monitoring regimes, and
                    to connect with their patients in a streamlined,
                    non-obtrusive manner.
                  </p>
                  <a
                    title="Consult"
                    className="btn btn-primary consult-btn"
                    href="#/Contact"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section-features">
          <div className="container" id="scroll">
            <div className="row">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/apppicTreatment.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4">TREATMENT PLAN</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p>
                    The heart of Medvatic is the treatment plan, where
                    practitioners can document a recovery pathway tailored for
                    each patient and keep track of those patients’ journey to
                    recovery. Treatment Plans can incorporate Drug
                    Prescriptions, Non-Drug Prescriptions and Measurement
                    Requests.
                  </p>
                </div>
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pr-md-5">
                  <div className="md-9">
                    <h2 className="mb-4">DRUG PRESCRIPTIONS</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pr-md-5 mr-md-5 mb-5">
                  <p>
                    Physicians can note and record the drug prescriptions
                    incorporated into each treatment plan, with the app
                    providing time-based reminders to the patient to take doses
                    and fill repeats as prescribed. Medvatic can optionally be
                    linked to external Drug reference data to provide drug
                    information, contraindications and related details.
                  </p>
                </div>
              </div>

              <div className="col-md-6 features-img">
                <img
                  src="assets/img/apppicDrug.jpg"
                  // style="max-width: 100% "
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/apppicNonDrug.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4">NON-DRUG PRESCRIPTIONS</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p>
                    Treatment Plans are about more than just drugs – and
                    Medvatic allows practitioners to specify any non-drug
                    treatment they deem appropriate. Time-based reminders can be
                    set to prompt the patient to undertake rehabilitation
                    exercises, eat appropriately or even schedule follow-up or
                    specialist appointments at appropriate times.
                  </p>
                </div>
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pr-md-5">
                  <div className="mr-md-5">
                    <h2 className="mb-4">Video call consultation</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pr-md-5 mr-md-5 mb-5">
                  <p>
                    Medvatic provides a secure, integrated video call system
                    that allows patients to get consultation online from
                    registered medical practitioners. Practitioners can control
                    over crowding at clinics with our pre-appointment booking
                    system which offers video as well as walk-in appointment
                    options.
                  </p>
                </div>
              </div>
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/video-call-consultation.webp"
                  // style="max-width: 100%"
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/appointment_management.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4">Appointment Management</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p>
                    The need for healthcare services is growing with the
                    increase in population and the number of patients who seek
                    health care at hospitals, medical facilities and physicians
                    practice has improved significantly. These bring a new set
                    of challenges for the staff of the facility and
                    administrators. Online scheduling, a recent technological
                    advancement, has made the booking process in hospitals
                    easier for both patients and administrative staffs.
                  </p>
                </div>
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pr-md-5">
                  <div className="mr-md-5">
                    <h2 className="mb-4">Patients family account</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pr-md-5 mr-md-5 mb-5">
                  <p>
                    Family members can now book appointments on behalf of each
                    other through a single profile! To put it plainly, you can
                    book individual appointments for your whole family from your
                    account.
                  </p>
                </div>
              </div>
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/patient_family.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img "
                  alt="Feature"
                />
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/apppicMeasurement.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img "
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4">MEASUREMENT REQUESTS</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p>
                    The path to recovery needs to be tracked by quantitative
                    milestones, which Medvatic records as Measurement Requests.
                    Practitioners can set reminders for patients to record vital
                    signs – heart rate, blood pressure, weight and more – which
                    become available to the practitioner instantly when
                    recorded. Medvatic can track patient measurements against
                    limits configured by their practitioner, with the
                    practitioner immediately alerted if any measurements exceed
                    those limits.
                  </p>
                </div>
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pr-md-5">
                  <div className="mr-md-5">
                    <h2 className="mb-4">PHOTOS & IMAGES</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pr-md-5 mr-md-5 mb-5">
                  <p>
                    Patients and Practitioners can upload and share images
                    through the Medvatic app – for example to scan imaging
                    results, photograph wound healing or provide visual updates
                    to treatment plan progress.
                  </p>
                </div>
              </div>
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/apppic1.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
            </div>

            <div className="row product-row">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/apppic2.jpg"
                  // style="max-width: 100%"
                  className="img-fluid product-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4">MESSAGING</h2>
                    <div className="line product-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p>
                    Medvatic provides a secure, integrated messaging system that
                    allows patients and practitioners to continue their
                    conversation beyond the initial consultation. For
                    time-sensitive practices (for example walk-in, bulk billing
                    clinics) the Messaging component can be disabled on a
                    per-practice basis if required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="row ">
          <div className="col-12">
            <div className="see-all m-t-0  product-bottom">
              <p className="product-p"> WANT TO KNOW MORE? </p>
              <a
                type="button"
                href="#/Contact"
                className="btn btn-primary see-all-btn practitioner-button"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
