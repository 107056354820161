import React, { useState, useEffect } from 'react';
import Apilib from "../../api";
import Loader from '../Loader/Loader';
import { Link } from "react-router-dom";
import order from '../../assets/img/orderImg.png'
import moment from "moment";
import { useHistory, Redirect, useLocation, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderDetail = (route) => {
    // getPatientOrders
    const history = useHistory();
    const { uid } = useParams();


    // prescription:[
    // Batch_Number:51263"
    // Dose:NA"
    // ExpDate:2023-10-31T06:38:00.000Z"
    // Medicine_Name:Digital thermometer"
    // MfgDate:2023-10-01T06:38:00.000Z"
    // Price:150"
    // Quantity:10"
    // Units:5"
    // imageLink:https://5.imimg.com/data5/SELLER/Default/2021/10/JA/WR/YY/47022212/clinical-digital-thermometer.jpg"
    // qty:1
    // type:Device"]


    console.log(uid)
    const [orderData, setOrderData] = useState(null)
    const [medicineData, setmedicineData] = useState(null)
    const [Loading, setLoading] = useState(false)
    console.log(route.userData.uid, 'route.userData.uid')
    let API = new Apilib();
    // setloading(true)
    useEffect(() => {
        setLoading(true);
        console.log(uid, 'this is uid from deorderdetails')
        API.getOrderByUid(uid)
            .then((result) => {
                let res = result.data
                console.log('the data', res.data)

                setOrderData(res.data);
                setmedicineData(res.data.prescription);

                // if (res.data.prescription) {
                //     setmedName(res.data?.prescription.map((med) => med?.Medicine_Name))

                //     setmedQuantity(res.data?.prescription.map((med) => med?.qty))
                // }

                setLoading(false);
            }).catch(e => {
                console.log('hi there', e)
                setLoading(false);
            });
        // setLoading(false);

        // sort and create object for each 
    }, []);

    if (Loading) return <Loader />
    else return (
        <div style={{ justifyContent: 'center', textAlign: '-webkit-center' }}>
            {orderData && <div style={{ justifyContent: 'center', textAlign: '-webkit-center', fontSize: '21px', fontWeight: '600' }}>Order Details <br />#{orderData.orderId}</div>}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                newestOnTop={false}
                hideProgressBar
                closeOnClick
                rtl={false}
                theme="light"
            />
            {orderData == null && <div style={{
                display: 'flex', width: '90%', justifyContent: 'space-between', margin: '5px', padding: '5px', alignItems: 'center', fontSize: '21px'
            }}>No order details available</div>}
            {medicineData && medicineData.map(x => {
                // let result = x.prescription.reduce(function (acc, obj) { return acc + (obj.Price * obj.qty); }, 0);
                console.log(x, 'this is indivi')
                return <div style={{
                    display: 'flex', width: '90%', justifyContent: 'space-between', margin: '5px', padding: '5px', borderBottom: 'solid 1px #000', alignItems: 'center'
                }}
                    key={Math.random()}>
                    <img src={x?.imageLink ? x.imageLink : order} alt="order" style={{ width: '50px', height: '50px', }} />
                    <div style={{ width: '19%' }}>
                        {/* <div style={{ fontSize: '16px', fontWeight: '600' }}> {x.prescription.length} Items</div> */}
                        <div style={{ fontSize: '15px', }}>  {`${x?.Medicine_Name ? x?.Medicine_Name : 'NA'}`}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Order Date</div>
                        <div>{moment(orderData.date).format('DD-MMM-YYYY')}</div>
                    </div>
                    {/* <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Order ID </div>
                        <div>#{x.orderId}</div>
                    </div> */}
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Price</div>
                        <div>${x.Price}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Quantity</div>
                        <div>{x.qty}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Total</div>
                        <div>${x.qty * x.Price}</div>
                    </div>
                    {/* <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', borderRadius: '5px', margin: '5px', padding: '5px', backgroundColor: '#00869a', cursor: 'pointer' }} onClick={() => {
                            // `/pharmacy/orders/order-details/${x.orderId}`
                            console.log('history pushing');
                            history.push({
                                pathname: `/pharmacy/orders/order-details/${x.orderId}`,
                                state: { orderId: x.orderId },
                            });
                        }}>Order History </div>
                        <div style={{ fontSize: '16px', borderRadius: '5px', margin: '5px', padding: '5px', backgroundColor: '#26d4ff', cursor: 'pointer' }}>Order Track </div>
                    </div> */}
                    <br />


                </div>
            })}
            {orderData && <div style={{ margin: '10px', padding: '10px' }}>

                <div style={{ border: 'solid 1px #e3e6ea', margin: '10px', padding: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '800', fontSize: '18px', color: '#00869a', borderRadius: '5px' }}>

                        <div>Track your package</div>
                        <div>Order ID:{orderData.orderId}</div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Order Date : {moment(orderData.date).format('DD-MMM-YYYY')}</div>
                        <div style={{ cursor: 'pointer', color: '#00869a', }} onClick={() => {
                            console.log("Item Added into cart!");
                            toast.success("Order needs to be picked up from pharmacy");
                        }}>How my item will be delivered?</div>

                    </div>
                    <div style={{ textAlign: 'left', fontSize: '18px', }}>
                        <div>Order Status</div>
                        <div>{orderData.orderStatus == 'Completed' ? 'Order is accepted and can be picked from pharmacy' : orderData.orderStatus == 'Pending' ? 'Order is still pending! please wait' : 'Order has been rejected '}</div>

                    </div>
                </div>

                <div style={{ border: 'solid 1px #e3e6ea', marginTop: '10px', textAlign: 'left', margin: '10px', padding: '10px', borderRadius: '5px' }}>
                    <div style={{ fontSize: '19px', color: '#00869a', fontWeight: '800' }}>Shipment Information</div>

                    <div>Customer Name : {orderData.patientName}</div>
                    <br />
                    <div>Delivery Address : {orderData.deliveryAddrs == 'normal' ? `${orderData.orderedBy.address}` : `${orderData.orderedBy.newAddress[orderData.deliveryAddrs].street}, ${orderData.orderedBy.newAddress[orderData.deliveryAddrs].citystatecountry}`}</div>
                    <br />
                    <div>Order ID : {orderData.orderId}</div>
                    <br />
                    <div style={{ fontSize: '17px', color: '#26d4ff', cursor: 'pointer' }} onClick={() => history.push('/contact')}>
                        Need Help? Contact us
                    </div>

                </div>
                <div>Order Status</div>
                {/* <br /> */}
                <div>{orderData.orderStatus}</div>
            </div>}
        </div>
    );
}

export default OrderDetail;
