import React, { Component, useState, useEffect } from "react";
import { auth } from "../../firebase";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

let baseUrl = "https://australia-southeast1-medvatic-438af.cloudfunctions.net/";

const ResetLogin = (props) => {
  const [hide, setHide] = useState(true);
  const [loading, setLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!@%+\/'!#$^:,(){}|~_.&><="%*?&])[A-Za-z\d@$!@%+\/'!#$^:,(){}|~_.&><="%*?&]{6,}$/,
          "Password length must be 6 and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number."
        )
        .required("New Password is required"),
      confirm_password: Yup.string().required("Confirm Password is required").oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
  });

  const [formData, setFormData] = useState({
    password: {
      value: "",
      validation: {
        required: true,
        isPassword: true,
        minLength: 6,
      },
      errMsg:
        "Password length must be 6 and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number.",
      valid: true,
    },
    confirm_password: {
      value: "",
      validation: {
        required: true,
        minLength: 6,
      },
      errMsg: "Password does not match!",
      valid: true,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);

  const [sendRequest, setSendRequest] = useState(false);

  function checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.isPassword) {
      const pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  const inputChangeHandler = (event, inputName) => {
    formik.setFieldValue(inputName, event.target.value);
    formik.setFieldTouched(inputName,true)
    const updatedForm = { ...formData };
    const updatedFormElement = { ...updatedForm[inputName] };
    updatedFormElement.value = event.target.value;
    if (inputName === "password") {
      updatedForm["confirm_password"].valid = false;
    }
    updatedForm[inputName] = updatedFormElement;
    updatedForm[inputName].valid = checkValidity(
      updatedForm[inputName].value,
      updatedForm[inputName].validation
    );
    let formValid = true;
    for (let key in updatedForm) {
      formValid = updatedForm[key].valid && formValid;
    }
    if (
      updatedForm["password"].value !== updatedForm["confirm_password"].value
    ) {
      formValid = false && formValid;
      updatedForm["confirm_password"].valid = false;
    }

    setFormIsValid(formValid);
    setFormData(updatedForm);
  };

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const actionCode = getParameterByName("oobCode");
  let continueUrl = getParameterByName("continueUrl");
  if (continueUrl) {
    // continueUrl = continueUrl.slice(0, continueUrl.lastIndexOf(".app") + 4);
    continueUrl = "https://medvatic.com/#/Login";
  }
  const [emailAddress, setEmailAddress] = useState();

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      setSendRequest(true);
    } else {
      Notify({
        alert: true,
        type: "error",
        title: "Invalid Request",
      });
      setSendRequest(false);
    }
  };

  useEffect(() => {
    if (!actionCode && !continueUrl) {
      window.location.href = "https://medvatic.com/#/Login";
    }
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        setLoading(false);
        setEmailAddress(email);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        Notify({
          alert: true,
          type: "error",
          title: "The link is malformed, expired, or has already been used.",
        });
        // if(continueUrl){
        //   window.location.href = continueUrl;
        // }else {
        // window.location.href = "https://medvatic.com/#/Login"
        // }
      });
  }, []);

  useEffect(() => {
    if (sendRequest) {
      setLoading(true);
      auth
        .confirmPasswordReset(actionCode, formData.confirm_password.value)
        .then((any) => {
          // console.log("any", any);
          // setLoading(false);
          let data = {
            email: emailAddress,
            newPassword: formData.confirm_password.value,
          };
          axios
            .post(`${baseUrl}user/updateNewPwd`, data)
            .then((res) => {
              Notify({
                alert: true,
                type: "success",
                title: res.data.message,
              });
              // window.location.href = continueUrl;
              window.location.href = "https://medvatic.com/#/Login";
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log("errrr", err);
              let message = err.message;
              if (err.response) {
                message = err.response.data.message;
              }
              Notify({
                alert: true,
                type: "error",
                title: message,
              });
              // window.location.href = continueUrl;
            });
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          Notify({
            alert: true,
            type: "error",
            title: "The link is malformed, expired, or has already been used.",
          });
          // props.history.push("/Login")
        });
      setSendRequest(false);
    }
  }, [sendRequest]);

  return (
    <>
      {loading && <Loader />}
      <div className="account-box my-5">
        {loading && <Loader />}
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h4 className="page-title">Reset Password</h4>
            </div>
          </div>
          <form onSubmit={formSubmitHandler}>
            <div className="account-logo">
              <a>
                <img src="assets/img/logo-dark.png" alt="Medvatic" />
              </a>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    New Password<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type={hide ? "password" : "text"}
                    name="password"
                    placeholder="New Password"
                    required={true}
                    onChange={(e) => inputChangeHandler(e, "password")}
                  />
                  <small className="form-text text-danger">
                    {formik.touched.password && formik.errors.password}
                  </small>
                  <div>
                      <i
                        onClick={() => setHide(false)}
                        class={
                          !hide
                            ? "hide-eye-icond eye-icond"
                            : "fa fa-eye eye-icond"
                        }
                        aria-hidden="true"
                      ></i>
                      <i
                        onClick={() => setHide(true)}
                        class={
                          hide
                            ? "hide-eye-icon eye-icond"
                            : "fa fa-eye-slash eye-icond"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                </div>
                <div className="form-group">
                  <label>
                    Confirm Password<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type={hide ? "password" : "text"}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    onChange={(e) => inputChangeHandler(e, "confirm_password")}
                  />
                  <small className="form-text text-danger">
                    {formik.touched.confirm_password && formik.errors.confirm_password}
                  </small>
                  <div>
                      <i
                        onClick={() => setHide(false)}
                        class={
                          !hide
                            ? "hide-eye-icons eye-icons"
                            : "fa fa-eye eye-icons"
                        }
                        aria-hidden="true"
                      ></i>
                      <i
                        onClick={() => setHide(true)}
                        class={
                          hide
                            ? "hide-eye-icons eye-icons"
                            : "fa fa-eye-slash eye-icons"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                </div>
              </div>
            </div>
            <div className="m-t-20 text-center">
              <button
                disabled={!formIsValid}
                className="btn btn-primary submit-btn"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetLogin;
