import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo1.png'
import styled from 'styled-components';
import moment from 'moment';
import Loader, { SplashLoader } from '../Loader/Loader';
import Apilib from "../../api";
import { useHistory, Redirect, useLocation } from 'react-router-dom'

// Dummy data for the invoice
// const invoiceData = {
//     companyIcon: 'company_icon_url',
//     invoiceNumber: 'INV-123',
//     date: '2023-07-18',
//     customer: {
//         name: 'John Doe',
//         address: '123 Main Street, City',
//     },
//     pharmacy: {
//         name: 'Example Pharmacy',
//         address: '456 Pharmacy Avenue, City',
//     },
//     items: [
//         { name: 'Item 1', description: 'Item 1 description', quantity: 2, price: 10, total: 20 },
//         { name: 'Item 2', description: 'Item 2 description', quantity: 1, price: 15, total: 15 },
//     ],
//     subTotal: 35,
//     tax: 5,
//     total: 40,
// };

const h2 = styled.h2`
  align-items: center;
  @media (max-width: 100%) {
  }
`;
const SectionHeading = styled.h3`
  color: #000;
`;
const P = styled.p`
  color: #111;
  margin-bottom:3px;
`;



const InvoiceTableWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
`;

const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: 100px;
  }

  /* Apply additional styles for smaller screens */
  @media (max-width: 768px) {
    th,
    td {
      font-size: 14px;
      white-space: nowrap;
    }
  
    tr {
      display: block;
      margin-bottom: 0.625em;
    }
  
    th,
    td::before {
      content: attr(data-label);
      display: inline-block;
      font-weight: bold;
      width: 100px;
    }
  
    td {
      display: block;
      text-align: right;
      position: relative;
      padding-left: 100px;
    }
  
    td::before {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 0;
      padding-left: 4px;
    }
  }
`;
const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px;
`;

const InvoiceHeader = () => {
  return (
    <div style={{ width: '100%', textAlign: 'end' }}>
      <h2>Suppliers Info</h2>

    </div>
  );
};


const PharmacyDetails = (pharmacyData) => {
  const pharmacy = pharmacyData.pharmacyData
  console.log(pharmacyData)
  console.log('pharmacyData')
  return (
    <div style={{ marginTop: '15px' }}>
      <SectionHeading>Pharmacy Details:</SectionHeading>
      <P>Pharmacy Name: {pharmacy.pharmacyName}</P>
      <P>Pharmacy Address: {pharmacy.location}</P>
    </div>
  );
};


const SupplierInfo = (route) => {
  let [invoiceData, setinvoiceData] = useState(null)
  let [pharmacyData, setpharmacyData] = useState(null)
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [suppliers, setSuppliers] = useState(null);
  const [patientList, setPatientList] = useState(null)
  const [loading, setloading] = useState(false)
  const location = useLocation();

  let supplierId = location.state
  console.log(supplierId, 'this is id from state supplierId')

  useEffect(() => {
    let API = new Apilib();
    API.getUsers(5, route.userData.hospitalId)
      .then((result) => {
        let res = result.data
        console.log(res);
        console.log('patient list above');

        let sorted = res.patients;
        sorted.sort((a, b) => {
          return b.created_at - a.created_at;
        })
        setPatientList(sorted)


      })
      .catch((error) => {

      });

    const data = { pharmacyId: route.userData.uid }
    API.getSuppliersInfo(data)
      .then(async (res) => {
        console.log(res)
        console.log('this is response suppliers')
        setSuppliers(res.data.invoiceList)
      })
      .catch((error) => {
        console.log('error', error)
      });


  }, [])

  const history = useHistory();


  return (
    <div>
      {loading && <SplashLoader />}
      {suppliers && <div style={{ backgroundColor: '#f1faff', padding: '5%', borderRadius: '15px', }}>
        <img style={{ width: '150px', height: '50px', position: 'absolute' }} src={logo} alt="Company Logo" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InvoiceHeader />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>

          {route?.userData && <PharmacyDetails pharmacyData={route.userData} />}
        </div>

        {suppliers.map((x) => {
          return <div style={{ backgroundColor: '#00A1BA', margin: '10px', padding: '10px', borderRadius: '15px' }}>
            <P>Name : {x.supplierName}</P>
            <P>Mobile Number : {x.mobileNumber}</P>
            <P>Address : {x.address + ', ' + x.city}</P>
            <h2 style={{ margin: '10px' }}>Medicines</h2>
            {x.medicines.map((y) => {
              return <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', backgroundColor: 'red', borderRadius: '15px', margin: '10px' }}>
                <P>Name : {y.itemName}</P>
                <P>Quantity : {y.quantity}</P>
                <P>ID : {y.itemId}</P>
                <P>Description : {y.itemDescription}</P>
              </div>
            })}
          </div>
        })}
        <ButtonGroup>
          <Button style={{ fontWeight: '400', fontSize: '16px' }}
            onClick={async () => {
              console.log('this is it')

              setloading(true)

              history.push('/pharmacy/add-patient')

              setloading(false)
            }}
          >
            Add new patient</Button>
          <Button style={{ fontWeight: '400', fontSize: '16px' }}
            onClick={async () => {
              console.log('this is it')

              setloading(true)
              history.push('/pharmacy/InvoicePage')


              setloading(false)
            }}
          >
            Add new invoice</Button>
          <Button style={{ fontWeight: '400', fontSize: '16px' }}
            onClick={async () => {
              console.log('this is it')

              setloading(true)

              history.push('/pharmacy/add-supplier')

              setloading(false)
            }}
          >
            Add new supplier</Button>
          <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }}>
            Cancel</Button>
        </ButtonGroup>

      </div>}
    </div>
  );
};

export default SupplierInfo;