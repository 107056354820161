import React, { useEffect, useState } from 'react';
import locationicon from '../../assets/img/locationicon.png'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import GoogleMap from "../Agora/utils/GoogleMap";
import Modal from "react-bootstrap/Modal";
import Notify from '../../Notify';
import LoginCheck from '../../Helpers/loginCheck';
import Apilib from "../../api";
import Loader from '../Loader/Loader';
import { customAlphabet } from "nanoid";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { storage } from '../../firebase';

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

const styless = {
    inputBoxStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '0.469rem 0.75rem',
        // border-color: #eaeaea;
        fontSize: '14px',
        minHeight: '35px',
        minWidth: '50%',
        width: '48%',
    },
}
const CheckboxLabel = styled.label`
  display: flex;
  justify-content:center;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin: 15px;
`;

const Order = (props) => {
    console.log(props, 'this is props');
    const location = useLocation();
    const history = useHistory();

    // props.userData
    if (typeof props.userData == 'undefined') history.push('/login')
    //    props.userData.newaddress
    //    props.userData.newaddress
    LoginCheck()
    // const cart = location.state.cart
    // console.log(location, 'this is location')
    // console.log(cart, 'this is location hi arer')
    // console.log('this is location hi arer')
    const [cartItems, setcartItems] = useState(null)
    const [cartTotal, setcartTotal] = useState(null)
    const [AddressLine, setAddressLine] = useState('')
    const [Landmark, setLandmark] = useState('')
    const [Street, setStreet] = useState('')
    const [State, setState] = useState('')
    const [prefModalOpen, setprefModalOpen] = useState(false);
    const [loading, setloading] = useState(false);
    const [addType, setaddType] = useState(0);
    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [email, setemail] = useState('');
    const [postCode, setpostCode] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [COD, setCOD] = useState(false);
    const [isPrescriptionRequired, setisPrescriptionRequired] = useState(false);
    const [isPrescriptionRequiredVar, setisPrescriptionRequiredVar] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    async function handleUploadCert(e) {
        // console.log(e.target.files[0]);
        let files = e.target.files[0];
        // const formData = new FormData();
        let fileArr = [];
        setSelectedFile(files)

        const buffer = Buffer.from(await new Response(files).arrayBuffer());
        const dataUrl = `data:${files.type};base64,${buffer.toString("base64")}`;

        localStorage.setItem('imga', dataUrl);

        if (files) setisPrescriptionRequired(false)
        console.log(files)

        // this.setState({
        //     fileExtError_pdf: "",
        //     fileSizeError_pdf: "",
        // });
        // for (let i = 0; i < files.length; i++) {
        //     if (this.validateFileExtension_pdf(files[i])) {
        //         // if (this.validateFileSize_pdf(files[i])) {
        //         //   // fileArr.push(file);
        //         //   formData.append(`pdf[${i}]`, files[i]);
        //         // }
        //         formData.append(`pdf[${i}]`, files[i]);
        //     }
        // }
        // if (
        //     this.state.fileExtError_pdf == "" &&
        //     this.state.fileSizeError_pdf == ""
        // ) {
        //     this.setState({
        //         inputFileData_pdf: files,
        //     });
        // }
    }


    useEffect(() => {
        if (addType == 0) {
            setname(props.userData?.firstName)
            setAddressLine(props.userData?.address)
            setState(props.userData?.geoLocation)
            setpostCode('000000')
            setemail(props.userData?.email)
            setphone(props.userData?.phoneno)
        }

        if (addType == 1) if (props?.userData?.newaddress?.home) {
            setname(props?.userData?.newaddress?.home.name)
            setAddressLine(props?.userData?.newaddress?.home.street)
            setState(props?.userData?.newaddress?.home.citystatecountry)
            setpostCode(props?.userData?.newaddress?.home.postCode)
            setemail(props?.userData?.newaddress?.home.email)
            setphone(props?.userData?.newaddress?.home.phone)
            console.log('hime')
        } else {
            setname('')
            setAddressLine('')
            setState('')
            setpostCode('')
            setemail('')
            setphone('')
        }
        if (addType == 2) if (props?.userData?.newaddress?.office) {
            setAddressLine(props?.userData?.newaddress?.office.street)
            setname(props?.userData?.newaddress?.office.name)
            setState(props?.userData?.newaddress?.office.citystatecountry)
            setpostCode(props?.userData?.newaddress?.office.postCode)
            setemail(props?.userData?.newaddress?.office.email)
            setphone(props?.userData?.newaddress?.office.phone)
            console.log('offcie')
        } else {
            setname('')
            setAddressLine('')
            setState('')
            setpostCode('')
            setemail('')
            setphone('')
        }
        if (addType == 3) if (props?.userData?.newaddress?.other) {
            setAddressLine(props?.userData?.newaddress?.other.street)
            setname(props?.userData?.newaddress?.other.name)
            setState(props?.userData?.newaddress?.other.citystatecountry)
            setpostCode(props?.userData?.newaddress?.other.postCode)
            setemail(props?.userData?.newaddress?.other.email)
            setphone(props?.userData?.newaddress?.other.phone)
            console.log('other')
        } else {
            setname('')
            setAddressLine('')
            setState('')
            setpostCode('')
            setemail('')
            setphone('')
        }
    }, [addType])

    // useEffect(() => {
    //     let cart = localStorage.getItem("cartData")
    //     setcartItems(cart)
    // }, [input]);

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('cartData'));

        if (items) {
            setcartItems(items);
            console.log(items, 'this i slocal items');
            items.map((x) => {
                if (x.isPrescription == 'yes') {
                    setisPrescriptionRequired(true)
                    setisPrescriptionRequiredVar(true)
                }
            })
        }
        let result = items.reduce(function (acc, obj) { return acc + Number(obj.Price) * Number(obj.qty); }, 0);
        setcartTotal(Number(result).toFixed(2))


    }, []);

    // const certificateHandler = (e) => {
    //     e.preventDefault();
    //     setSelected(true);
    //     const file = document.getElementById("doctorRegCer").files[0];
    //     if (file && file.length !== 0) {
    //         setCertificateLable(file.name);
    //     }
    // };

    // const certificateUploadHandler = (e) => {
    //     e.preventDefault();
    //     const file = document.getElementById("doctorRegCer").files[0];
    // }



    return (
        <div style={{ marginTop: '0%', margin: '5%', padding: '3%' }}>
            {loading && <Loader />}
            <h5>
                Select or add an address
            </h5>
            {/* <div style={{ display: 'flex', justifyContent: 'space-evenly', }}> */}
            <Div4>
                {/* <div style={{ display: 'flex', width: '50%', margin: '2%', justifyContent: 'center', alignItems: 'center' }}> */}
                <Div3>

                    <div style={{ width: '30%', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}
                    >
                        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}
                            onClick={() => {
                                console.log('here open new address')
                                setprefModalOpen(true);
                            }}
                        >
                            <img src={locationicon} alt="location"
                                style={{ width: '95%', height: '120px', overflow: 'hidden' }}
                            />
                            <label>
                                Add/Select Address
                            </label>
                        </div>

                    </div>
                    <div style={{ width: '70%', backgroundColor: '#e7fcff', margin: '2%', maxHeight: '320px' }}>

                        <div>{'Name : ' + (name ? name : 'NA')}</div>
                        <div>{'Street : ' + (AddressLine ? AddressLine : 'NA')}</div>
                        <div>{'City/State/Country: ' + (State ? State : 'NA')}</div>
                        <div>{'Phone : ' + (phone ? phone : 'NA')}</div>

                    </div>
                    {/* </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ fontSize: '13px', backgroundColor: addType == 1 ? '#00869a' : '#f4f4f4', userSelect: 'none', color: addType == 1 ? '#fff' : '#0f8d9f', borderRadius: '3px', border: 'solid .5px #999', cursor: 'pointer', padding: '5px', margin: '5px' }} onClick={() => setaddType(1)}>Home</div>
                        <div style={{ fontSize: '13px', backgroundColor: addType == 2 ? '#00869a' : '#f4f4f4', userSelect: 'none', color: addType == 2 ? '#fff' : '#0f8d9f', borderRadius: '3px', border: 'solid .5px #999', cursor: 'pointer', padding: '5px', margin: '5px' }} onClick={() => setaddType(2)}>Office</div>
                        <div style={{ fontSize: '13px', backgroundColor: addType == 3 ? '#00869a' : '#f4f4f4', userSelect: 'none', color: addType == 3 ? '#fff' : '#0f8d9f', borderRadius: '3px', border: 'solid .5px #999', cursor: 'pointer', padding: '5px', margin: '5px' }}
                            onClick={() => setaddType(3)}>Other</div>
                    </div>
                </Div3>
                {/* <div style={{ width: '50%', height: '100%' }}> */}
                <Div3>

                    <div style={{ width: '100%', border: 'solid 1px #f7f7f7', overflow: 'hidden' }}>
                        <div style={{ width: '100%', height: '100%', overflowY: 'auto', }}>
                            <h5>
                                Cart Products
                            </h5>

                            {cartItems && cartItems.map(x => {
                                return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '5px' }}
                                    key={Math.random()}
                                >

                                    <div style={{ display: 'flex', }}>
                                        <img src={x.imageLink} alt="image" style={{ width: '50px', height: '50px', overflow: 'hidden' }} />
                                        <div >

                                            <div> {x.Medicine_Name}</div>
                                            <div> {x.qty || '1'}</div>
                                        </div>
                                    </div>
                                    <div> {x.Price}</div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div style={{ width: '100%', border: 'solid 1px #f7f7f7', height: '50%', }}>
                        <h5>
                            Bill Summary
                        </h5>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Total
                            </h6>
                            {cartTotal && <h6>
                                ${cartTotal}
                            </h6>}
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Delivery Charges
                            </h6>
                            <h6>
                                $20
                            </h6>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Discount
                            </h6>
                            {cartTotal && <h6>
                                ${Number(cartTotal * .05).toFixed(2)}
                            </h6>}
                        </div>
                        {/* 
                        <button
                            onClick={certificateUploadHandler}
                            // disabled={editable}
                            className="btn btn-primary"
                        >
                            Upload
                        </button> */}
                        {/* <div className="inputFile">
                            <span>{certificateLable}</span>
                            <input
                                required
                                // disabled={editable}
                                type="file"
                                accept="application/pdf"
                                id="doctorRegCer"
                                onChange={certificateHandler}
                            />
                        </div> */}

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h6>
                                Amount to be paid
                            </h6>
                            {cartTotal && <h6>
                                ${Number(Number(cartTotal) - Number(cartTotal * 0.05)).toFixed(2)}
                            </h6>}
                        </div>

                    </div>

                    <CheckboxLabel>
                        <Checkbox type="checkbox" checked={agreeTerms}
                            onChange={() => {
                                console.log(agreeTerms)
                                setAgreeTerms(!agreeTerms)
                            }} />
                        <Link to="/WebsiteTerms" target="_blank">
                            {/* Website terms and condition of use */}
                            Agree with the terms and conditions
                        </Link>
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <Checkbox type="checkbox" checked={!COD}
                            onChange={() => {
                                setCOD(!COD)
                            }} /> Cash On delivery
                        <Checkbox type="checkbox" checked={COD}
                            onChange={() => {
                                setCOD(!COD)
                            }} /> Pay Online
                    </CheckboxLabel>


                    {/* 
                    <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/png, image/jpeg"
                        name="file"
                    />

                    <div style={{
                        color: '#fff', backgroundColor: '#00869a', width: '100%', borderRadius: '5px', textAlign: 'center', padding: '5px', cursor: 'pointer'
                    }}
                        type="file"

                        onChange={handleImageChange}


                    // onClick={async () => {

                    //     setloading(false)

                    //     Notify({
                    //         alert: true,
                    //         type: "success",
                    //         title: "Your order have been successfully,Please collect medicines at the pharmacy",
                    //     });


                    // }}
                    >
                        Upload prescription
                        <input
                            type="file"
                            onChange={handleImageChange}
                        // accept="image/png, image/jpeg"
                        />
                    </div> */}
                    {isPrescriptionRequired && <div>
                        {/* <div  >
                        Upload Prescription
                    </div> */}
                        <label style={{
                            color: '#fff', backgroundColor: '#00869a', width: '100%', borderRadius: '5px', textAlign: 'center', padding: '5px', cursor: 'pointer',
                        }}>
                            <input
                                type="file"
                                id="certInput"
                                // multiple={false}
                                onChange={(e) => handleUploadCert(e)}
                                accept=".pdf, .jpg, .png"
                            />
                            Upload Prescription
                        </label>
                        <div style={{ fontSize: '11px', marginBottom: '10px' }} >
                            You have added medicines which requires prescription
                        </div>
                    </div>
                    }
                    <div>
                        {/* <label class="custom-file-upload">
                            <input
                                type="file"
                                id="certInput"
                                // multiple={false}
                                onChange={(e) => handleUploadCert(e)}
                                accept='*'
                            />
                            Upload Certificate
                        </label> */}
                    </div>


                    <div style={{
                        color: '#fff', backgroundColor: '#00869a', width: '100%', borderRadius: '5px', textAlign: 'center', padding: '5px', cursor: 'pointer'
                    }} onClick={async () => {
                        console.log('sending mails and processing orderss')
                        // alert("Your order have been successfully sent to pharmacy")
                        // let isPrescriptionRequired = false
                        if (!agreeTerms) {
                            alert('Please agree to the terms and conditions')
                            return
                        }

                        let API = new Apilib();
                        let medicines = []
                        cartItems.map((x) => medicines.push(x))
                        if (isPrescriptionRequired) {
                            alert('You have added prescription medicines in cart Please upload prescription to continue')
                            return
                        }

                        let addrs = {}
                        setloading(true)

                        if (props?.userData?.newaddress?.home) addrs.home = props?.userData?.newaddress?.home

                        if (props?.userData?.newaddress?.office) addrs.office = props?.userData?.newaddress?.office

                        if (props?.userData?.newaddress?.other) addrs.other = props?.userData?.newaddress?.other

                        if (addType == 1) addrs.home = { name: name, street: AddressLine, citystatecountry: State, phone: phone, postCode: postCode, email: email }
                        if (addType == 2) addrs.office = { name: name, street: AddressLine, citystatecountry: State, phone: phone, postCode: postCode, email: email }
                        if (addType == 3) addrs.other = { name: name, street: AddressLine, citystatecountry: State, phone: phone, postCode: postCode, email: email }

                        let deliveryadd = addType == 0 ? 'normal' : addType == 1 ? addrs.home : addType == 2 ? addrs.office : addrs.other
                        let arr = [{ createdAt: '', medicines: [], prescriptionId: '' }]
                        let newid = nanoid()
                        console.log(newid, 'this is nano id')

                        let n = {
                            orderId: newid, pharmacy_id: cartItems[0].pharmacyId, orderedBy: props.userData, email: email, prescription: medicines, appointmentId: "NA", cost: cartTotal, date: Date.now(), doctorId: "NA", doctorName: "NA", hospitalAddress: "NA", hospitalId: "NA", hospitalName: "NA", orderNumber: 'NA', orderStatus: "Pending", patientId: props.userData.uid, patientName: name, patientPhone: phone, patient_email: email, pharmacyId: cartItems[0].pharmacyId, uid: props.userData.uid, deliveryAddrs: deliveryadd, prescriptionImage: JSON.stringify(selectedFile), isPrescriptionRequiredVar: isPrescriptionRequiredVar
                        }

                        if (COD) {

                            localStorage.setItem('orderData', JSON.stringify(n));
                            history.push('/PaymentOrder')
                            return
                        }



                        // storage
                        //     .ref(`/prescriptions/${docid}/${body.prescriptionImage}`)
                        //     .put(body.prescriptionImage).then(res => {
                        //         console.log(res)
                        //     }).then(x => console.log(x)).catch(e => console.log(e, 'this is error'))


                        console.log(n)
                        setloading(true)
                        API.createNewOrder(n).then(async res => {
                            console.log(res, 'this is res')

                            if (isPrescriptionRequiredVar) await storage
                                .ref(`/prescriptions/${res.data.prescriptionId}/prescription.${selectedFile.name.split('.').pop().toLowerCase()}`)
                                .put(selectedFile).then(res => {
                                    console.log(res)
                                }).then(x => console.log(x)).catch(e => console.log(e, 'this is error'))



                            setloading(false)

                            Notify({
                                alert: true,
                                type: "success",
                                title: "Your order have been successfully,Please collect medicines at the pharmacy",
                            });
                        }).catch(e => {
                            console.log(e, 'this is error');
                            setloading(false)
                            Notify({
                                alert: true,
                                type: "error",
                                title: "Your order could not be placed please try again",
                            });
                        })

                        // cartItems
                        setloading(false)



                    }}>
                        Place order
                    </div>
                    <div style={{ fontSize: '11px', }} >
                        Note : Orders placed should be picked from pharmacy and payment needs to be done at the pharmacy
                    </div>
                    {/* </div> */}
                </Div3>
            </Div4>
            {/* </div > */}

            <Modal
                backdrop="static"
                show={prefModalOpen}
                onHide={() => {
                    setprefModalOpen(false);
                    // rejector(new Error("No preference chosen"));
                }}
                // onHide={handleClose}
                keyboard={false}
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Add shipping address</Modal.Title>
                </Modal.Header>
                <div className="preference-buttons">
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <div style={{ fontSize: '13px', backgroundColor: addType == 1 ? '#00869a' : '#f4f4f4', userSelect: 'none', color: addType == 1 ? '#fff' : '#0f8d9f', borderRadius: '3px', border: 'solid .5px #999', cursor: 'pointer', padding: '5px', margin: '5px' }} onClick={() => setaddType(1)}>Home</div>
                        <div style={{ fontSize: '13px', backgroundColor: addType == 2 ? '#00869a' : '#f4f4f4', userSelect: 'none', color: addType == 2 ? '#fff' : '#0f8d9f', borderRadius: '3px', border: 'solid .5px #999', cursor: 'pointer', padding: '5px', margin: '5px' }} onClick={() => setaddType(2)}>Office</div>
                        <div style={{ fontSize: '13px', backgroundColor: addType == 3 ? '#00869a' : '#f4f4f4', userSelect: 'none', color: addType == 3 ? '#fff' : '#0f8d9f', borderRadius: '3px', border: 'solid .5px #999', cursor: 'pointer', padding: '5px', margin: '5px' }}
                            onClick={() => setaddType(3)}>Other</div>
                    </div>
                    {/* <button
                        className="btn btn-primary preference-btn account-btn"
                        onClick={() => {
                            // resolver(2);
                            setprefModalOpen(false);
                        }}
                        style={{ marginRight: "10px" }}
                    >
                        Business Admin
                    </button>
                    <button
                        className="btn btn-primary preference-btn account-btn"
                        onClick={() => {
                            // resolver(3);
                            setprefModalOpen(false);
                        }}
                        style={{ marginLeft: "10px" }}
                    >
                        Professional
                    </button> */}
                    <div className="form-group" style={{ display: 'flex' }}>
                        {/* <label>
                            Address Line <span className="text-danger">*</span>
                        </label> */}
                        <input
                            // className="form-control col-sm-5 row"
                            name="Name"
                            placeholder={'Name'}
                            value={name}
                            onChange={e => setname(e.target.value)}
                            style={styless.inputBoxStyle}
                        />
                        <input
                            // className="form-control col-sm-5 row"
                            name="Phone Number"
                            placeholder={'Phone Number'}
                            value={phone}
                            onChange={e => setphone(e.target.value)}
                            style={styless.inputBoxStyle}
                        />
                    </div>
                    <div className="form-group" style={{ display: 'flex' }}>
                        {/* <label>
                            Address Line <span className="text-danger">*</span>
                        </label> */}
                        <input
                            // className="form-control col-sm-5 row"
                            name="Email"
                            placeholder={'Email'}
                            value={email}
                            onChange={e => setemail(e.target.value)}
                            style={styless.inputBoxStyle}
                        />
                        <input
                            // className="form-control col-sm-5 row"
                            name="Address"
                            placeholder={'Address'}
                            value={AddressLine}
                            onChange={e => setAddressLine(e.target.value)}
                            style={styless.inputBoxStyle}
                        />
                    </div>
                    <div className="form-group" style={{ display: 'flex' }}>
                        {/* <label>
                            Address Line <span className="text-danger">*</span>
                        </label> */}
                        <input
                            // className="form-control col-sm-5 row"
                            name="Postcode"
                            placeholder={'Postcode'}
                            value={postCode}
                            onChange={e => setpostCode(e.target.value)}
                            style={styless.inputBoxStyle}
                        />

                        <GoogleMap
                            placeholder={'City/State/Country'}
                            style={{ width: 100 }}
                            value={State}
                            getAddress={(address) => {
                                console.log("GoogleMapsAddress", address)
                                setState(address)
                            }}

                        />
                    </div>

                    <button
                        className="btn btn-primary preference-btn account-btn"
                        onClick={async () => {
                            // resolver(2);

                            let API = new Apilib();
                            let addrs = {}
                            setloading(true)

                            if (props.userData.newaddress.home) addrs.home = props.userData.newaddress.home

                            if (props.userData.newaddress.office) addrs.office = props.userData.newaddress.office

                            if (props.userData.newaddress.other) addrs.other = props.userData.newaddress.other


                            // name
                            // phone
                            // email
                            // AddressLine
                            // postCode
                            // State

                            if (addType == 1) addrs.home = { name: name, street: AddressLine, citystatecountry: State, phone: phone, postCode: postCode, email: email }
                            if (addType == 2) addrs.office = { name: name, street: AddressLine, citystatecountry: State, phone: phone, postCode: postCode, email: email }
                            if (addType == 3) addrs.other = { name: name, street: AddressLine, citystatecountry: State, phone: phone, postCode: postCode, email: email }

                            await API.updateAddrs({ type: '5', uid: props.userData.uid, newaddress: addrs })
                                .then(async (res) => {
                                    console.log(res)
                                    console.log('this is response')
                                    // console.log('this is response', res.data.medicines.medicines)
                                    // setmedicineList(res.data.medicines.medicines)
                                    // medicineList
                                })
                                .catch((error) => {
                                    console.log('error', error)
                                });

                            setprefModalOpen(false);
                            setloading(false)
                            // updateAddrs
                        }}
                        style={{ marginRight: "10px" }}
                    >
                        Save address
                    </button>
                </div>
            </Modal >

        </div >
    );
}

const Div3 = styled.div`
  width: 100%;
  max-width: 1113px;
  justify-content: space-between;
  gap: 20px;
  height:100%;
  text-align: -webkit-center;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    text-align: -webkit-center;
  }
`;
const Div4 = styled.div`
  display:flex;
  width: 100%;
  max-width: 1113px;
  justify-content: space-between;
  gap: 20px;
  height:100%;
  text-align: -webkit-center;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    text-align: -webkit-center;
  }
`;
export default Order;
