import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import Apilib from "../../api";
import cart from '../../assets/img/cart.png'
import './ProductList.css';
import { useHistory, Redirect, useLocation } from 'react-router-dom'


const LabTests = () => {
    const history = useHistory();

    const [products, setproducts] = useState([])
    const [loading, setloading] = useState(false)
    const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData')) ? JSON.parse(localStorage.getItem('cartData')) : [])
    const [cartItemsLength, setcartItemsLength] = useState(0)

    useEffect(() => {
        let API = new Apilib();
        setloading(true)
        API.getMedicines({})
            .then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log('this is response', res.data.medicines.medicines)
                let sorted = []

                res.data.medicines.medicines.map(x => {
                    if (x.type == 'LabTests') sorted.push(x)
                })
                setproducts(sorted)
                // medicineList
            })
            .catch((error) => {
                console.log('error', error)
            });

        setloading(false)

    }, []);

    useEffect(() => {
        console.log('useeffect executed')
        const items = JSON.parse(localStorage.getItem('cartData'));
        if (items) setcartItemsLength(items.length)
    }, [cartItems]);

    function itemExist(arr, arr1) {
        let index = 0
        const { length } = arr;
        const id = length + 1;
        const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);
        console.log(found, 'this is found')
        // if (!found) arr.push(arr1);
        return found;
    }

    const settingcart = (data) => {
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        data.qty = 1
        if (itemnow == -1) pr.push(data)
        else pr[itemnow].qty += 1
        console.log(itemnow, 'itemnow herer')

        localStorage.setItem('cartData', JSON.stringify(pr));

        setcartItems(pr)

    }


    if (loading) return <Loader />
    else return (
        <div style={{ width: '100%', alignItems: 'center', textAlign: 'center' }}>
            <div style={{ fontSize: '30px' }}>Lab Tests</div>
            {products.length == 0 && <div style={{ justifyContent: 'center', alignItems: 'center', margin: '50px', height: '150px', padding: '30px' }}><div>Currently we are not supporting lab tests</div>
                <div>Please check back in a few time </div></div>}
            <div className="product-grid" style={{ justifyContent: 'center', alignItems: 'center' }}>
                {products.map((product) => {
                    return <div
                        id={Math.random()}
                        className="card"
                        style={{
                            width: '260px',
                            justifyContent: 'space-evenly',
                            padding: '10px',
                            margin: '10px'
                        }}
                        tabIndex={0}>
                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f4faff' }} onClick={() => {
                            history.push('/ProductDetails')
                            product.qty = 1
                            localStorage.setItem('productDetails', JSON.stringify(product));

                        }}>
                            <img src={product.imageLink ? product.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product 1" style={{ width: '230px', height: '150px', }} />
                        </div>
                        <div style={{ textAlign: 'left', }}>

                            <p style={{ width: '100%', marginBottom: '3px' }}>{product.type} </p>
                            <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{product.Medicine_Name} </p>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <p style={{ width: '50%', marginBottom: '3px', color: '#000', fontSize: '15px', display: 'flex', alignItems: 'center' }}>MRP :  <p style={{ width: '50%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>  {product.Price} </p></p>
                                <img src={cart} alt="Product 1" style={{ width: '30px', height: '30px', }} onClick={() => {
                                    console.log('added to cart ' + product.title)
                                    // let cart = cartItems
                                    // cart.push(x)
                                    // setcartItems(() => cart)
                                    settingcart(product)
                                    console.log(product)
                                }} />
                            </div>
                        </div>
                    </div>
                })}
            </div>

            {<div className="ChatSupport">
                <div className="ChatSupport__container">
                    {cartItemsLength && <button className="ChatSupport__cta-btn" onClick={async () => {

                        console.log('yes the cafrt', cartItems.length)
                        // history.push({ pathName: "/Order", state: { cart: cartItems } })

                        localStorage.setItem('cartData', JSON.stringify(cartItems));
                        const items = JSON.parse(localStorage.getItem('cartData'));
                        console.log(items, 'this i s otems')
                        history.push({
                            pathname: "/EditCart",
                            state: { cart: items },
                        });
                    }} style={{ position: 'fixed', top: '13%' }}>
                        {/* <WhatsAppIcon /> &nbsp; Hi! How can I help? */}
                        Checkout {cartItemsLength} items

                    </button>}
                </div>
            </div >}

        </div>
    );
}

export default LabTests;
