// jshint esversion:6

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Route, useHistory, useLocation } from "react-router-dom";
import Apilib from "../api";
import ChatSupport from "../Components/Patient/ChatSupport";
import Footer from "../Components/Patient/Footer";
import Header from "../Components/Patient/Header";
import { auth, storage } from "../firebase";
import { getDefaultPageUrl, isFooterLink } from "../Helpers/constants";
import { useStateValue } from "../StateProvider";
import Loader from "./../Components/Loader/Loader";
// import './public.css';

export const PatientLayout = (
  { children, ...rest },
  props
) => {
  return (
    <>
      <div className="main-wrapper" style={{ position: "relative" }}>
        {/* <ChatSupport phoneNumber={"8828103808"} /> */}
        {/* <Header /> */}
        {children}
        {/* <Footer /> */}
      </div>
    </>
  );
};

let resolver, rejector;

const OpenLayoutRoute = ({
  component: Component,
  title,
  path,
  ...rest
}) => {
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState();
  const [userData, setUserData] = useState("loading");
  const [userDisplayName, setUserDisplayName] = useState(true);
  const [loading, setLoading] = useState(false);
  const [{ userPP }, dispatch] = useStateValue();
  const [prefModalOpen, setprefModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();


  useEffect(() => {
    let API = new Apilib();
    let isSubscribed = true;

    API.getLocation()
           .then((result) => {
        let res = result.data

        dispatch({
          type: "SET_LOCATION",
          lat: res.latitude,
          long: res.longitude,
          country_code: res.country_code,
        });
      })
      .catch((err) => {
        console.log("geoError", err);
      });

    // https://ipapi.co/json

    return () => (isSubscribed = false);
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <PatientLayout>
            <Component
              type={userDisplayName}
              userData={userData}
              updateUserData={setUserData}
              user={user}
              title={title}
              {...matchProps}
            />
            <Modal 
              backdrop="static"
              show={prefModalOpen}
              onHide={() => {
                setprefModalOpen(false);
                rejector(new Error("No preference chosen"));
              }}
              // onHide={handleClose}
              // backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Choose Your Preference</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="account-title">
                  <h3 className="account-head">Login As</h3>
                </div>
              </Modal.Body>
              <div className="preference-buttons">
                <button
                  className="btn btn-primary preference-btn account-btn"
                  onClick={() => {
                    resolver(2);
                    setprefModalOpen(false);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Business Admin
                </button>
                <button
                  className="btn btn-primary preference-btn account-btn"
                  onClick={() => {
                    resolver(3);
                    setprefModalOpen(false);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Professional
                </button>
              </div>
            </Modal>
          </PatientLayout>
        )}
      />
    );
  }
};

export default OpenLayoutRoute;
