import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo1.png'
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import Apilib from "../../api";
import { useLocation, useHistory } from 'react-router-dom';

// Dummy data for the invoice
// const invoiceData = {
//     companyIcon: 'company_icon_url',
//     invoiceNumber: 'INV-123',
//     date: '2023-07-18',
//     customer: {
//         name: 'John Doe',
//         address: '123 Main Street, City',
//     },
//     pharmacy: {
//         name: 'Example Pharmacy',
//         address: '456 Pharmacy Avenue, City',
//     },
//     items: [
//         { name: 'Item 1', description: 'Item 1 description', quantity: 2, price: 10, total: 20 },
//         { name: 'Item 2', description: 'Item 2 description', quantity: 1, price: 15, total: 15 },
//     ],
//     subTotal: 35,
//     tax: 5,
//     total: 40,
// };

const h2 = styled.h2`
  align-items: center;
  @media (max-width: 100%) {
  }
`;
const SectionHeading = styled.h3`
  color: #000;
`;
const P = styled.p`
  color: #111;
  margin-bottom:3px;
`;



const InvoiceTableWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
`;

const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: 100px;
  }

  /* Apply additional styles for smaller screens */
  @media (max-width: 768px) {
    th,
    td {
      font-size: 14px;
      white-space: nowrap;
    }
  
    tr {
      display: block;
      margin-bottom: 0.625em;
    }
  
    th,
    td::before {
      content: attr(data-label);
      display: inline-block;
      font-weight: bold;
      width: 100px;
    }
  
    td {
      display: block;
      text-align: right;
      position: relative;
      padding-left: 100px;
    }
  
    td::before {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 0;
      padding-left: 4px;
    }
  }
`;
const CompanyIcon = () => {
    return (
        <div>
            {/* Replace with company logo or image */}
            <img style={{ width: '150px', height: '50px' }} src={logo} alt="Company Logo" />
        </div>
    );
};

const InvoiceHeader = ({ invoiceData }) => {
    return (
        <div style={{ width: '100%', textAlign: 'end' }}>
            <h1>Invoice</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ border: 'solid #39D5FF 4px', height: '3px', width: '50%', borderRadius: '3px' }}></div>
                <P>Invoice Number: {invoiceData.invoiceNumber}</P>
                <P>Date: {invoiceData.date}</P>
            </div>
        </div>
    );
};

const CustomerDetails = ({ invoiceData }) => {
    const { customer } = invoiceData;
    return (
        <div style={{ textAlign: 'end' }}>
            <SectionHeading>Invoice To:</SectionHeading>
            <P>Patient name: {customer.name}</P>
            <P>Addr. :{customer.address}</P>
        </div>
    );
};

const PharmacyDetails = ({ invoiceData }) => {
    const { pharmacy } = invoiceData;
    return (
        <div style={{ margin: '1px' }}>
            <SectionHeading>Invoice From:</SectionHeading>
            <P>Pharmacy Name: {pharmacy.name}</P>
            <P>Pharmacy Address: {pharmacy.address}</P>
        </div>
    );
};

// const InvoiceTable = () => {
//     return (
//         <table style={{ textAlign: 'center' }}>
//             <thead >
//                 <tr style={{ borderRadius: '5px', backgroundColor: '#81e2fc' }}>
//                     <th >Item</th>
//                     <th>Item Description</th>
//                     <th>Qty</th>
//                     <th>Price</th>
//                     <th>Total</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {invoiceData.items.map((item, index) => (
//                     <tr key={index} style={{ borderBottom: 'solid #aeb3b5 .5px' }}>
//                         <td>{item.name}</td>
//                         <td>{item.description}</td>
//                         <td>{item.quantity}</td>
//                         <td>{item.price}</td>
//                         <td>{item.total}</td>
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     );
// };

const InvoiceTotals = ({ invoiceData }) => {
    // const { subTotal, tax, total } = invoiceData;
    console.log(invoiceData)
    let subTotal = invoiceData.items.reduce(function (acc, obj) { return acc + (obj.Price * obj.quantity) }, 0);
    let tax = subTotal * .18
    let total = subTotal + tax
    // Price
    //    quantity

    return (
        <div style={{ textAlign: 'end' }}>
            <P>Sub Total: {subTotal}</P>
            <P>Tax: {tax}</P>
            <hr />
            <SectionHeading>Total: {total}</SectionHeading>
        </div>
    );
};

const InvoiceForm = (route) => {
    const history = useHistory();

    console.log(route, 'this is here route')
    const location = useLocation();

    let invoiceId = location.state
    console.log(invoiceId, 'this is id from state')


    let [invoiceData, setinvoiceData] = useState(null)
    useEffect(() => {
        let API = new Apilib();

        if (!invoiceId?.id) {
            history.push(`/pharmacy/orders`);
            return
        }
        let data = {
            "pharmacyId": route.userData.uid,
            "invoiceId": invoiceId?.id,
        }

        console.log(data)

        API.getInvoiceData(data)
            .then(async (res) => {
                console.log(res)
                console.log('this is response')

                let calculateSubTotal = res.data.data.prescription.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.price * currentValue.quantity)
                }, 0)

                let invoiceDataDb = {
                    invoiceNumber: res.data.data.invoiceNumber,
                    date: moment(res.data.data.date).format('MM/DD/YYYY'),
                    customer: {
                        name: res.data.data.invoiceFor.firstName + " " + res.data.data.invoiceFor.lastName,
                        address: res.data.data.address + " " + res.data.data.city,
                    },
                    pharmacy: {
                        name: res.data.msg.pharmacyName,
                        address: res.data.msg.location,
                    },
                    items: res.data.data.prescription,

                    // { name: 'Item 1', description: 'Item 1 description', quantity: 2, price: 10, total: 20 },
                    // "itemName": "ara",
                    // "quantity": "34",
                    // "price": "32",
                    // "itemDescription"
                    subTotal: calculateSubTotal,
                    tax: calculateSubTotal * .18,
                    total: calculateSubTotal + (calculateSubTotal * .18),
                }

                setinvoiceData(invoiceDataDb)
            })
            .catch((error) => {
                console.log('error', error)
            });
        console.log('useeffect executed')
    }, []);


    return (
        <div >
            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute' }} onClick={() => history.goBack()}>
                <i className="fa fa-arrow-left"></i>Back
            </div>
            {invoiceData && <div style={{ backgroundColor: '#f1faff', padding: '5%', borderRadius: '15px' }}>
                <img style={{ width: '150px', height: '50px', position: 'absolute' }} src={logo} alt="Company Logo" />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <InvoiceHeader invoiceData={invoiceData} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>

                    <PharmacyDetails invoiceData={invoiceData} />
                    <CustomerDetails invoiceData={invoiceData} />
                </div>
                {/* <InvoiceTable />
             */}

                <InvoiceTableWrapper>
                    <InvoiceTable>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Item Description</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceData.items.map((item, index) => {
                                console.log(item);
                                return <tr key={index}>
                                    <td>{item.itemName}</td>
                                    <td>{item.itemDescription}</td>
                                    <td>{Number(item.quantity)}</td>
                                    <td>{Number(item.Price)}</td>
                                    <td>{Number(item.Price) * Number(item.quantity)}</td>
                                </tr>
                            }
                            )}
                        </tbody>
                    </InvoiceTable>
                </InvoiceTableWrapper>
                {invoiceData && <InvoiceTotals invoiceData={invoiceData} />}
            </div>}
        </div>
    );
};

export default InvoiceForm;