import React, { Component, useState } from "react";
import { useHistory } from "react-router-dom";
import BaseSelect from "react-select";
import RequiredSelect from "../../Helpers/requiredSelect";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import Notify from "../../Notify";

const options = [
  { value: "Sales Executive", label: "Sales Executive" },
  { value: "Admin", label: "Admin" },
  { value: "Super Admin", label: "Super Admin" },
];



const countryCheckPhone = [
  {
    cc: 91,
    digitLimit: 10,
  },
  {
    cc: 61,
    digitLimit: 9,
  },
];

const limitPh = (value) => {
  // console.log("value: ", value);
  for (let i = 0; i < countryCheckPhone.length; i++) {
    if (countryCheckPhone[i].cc === +value) {
      return countryCheckPhone[i].digitLimit;
    }
  }
  return 9;
};

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      loading: false,
      error: "",
      success: false,
      name: "",
      email: "",
      phone: "",
      accessLevel: null,
      password: "ks24nskd",
      formData: {
        name: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid Name!",
          valid: true,
        },
        email: {
          value: "",
          validation: {
            required: true,
            isEmail: true,
          },
          errMsg: "Invalid Email!",
          valid: true,
        },
        countryCode: {
          value: "61",
          validation: {
            required: true,
            isNumeric: true,
            minLength: 2,
            maxLength: 2,
          },
          errMsg: "Invalid Country code",
          valid: true,
        },
        mobile: {
          value: "",
          validation: {
            required: true,
            isNumeric: true,
            minLength: 9,
            maxLength: 9,
          },
          errMsg: "Invalid Contact Number!",
          valid: true,
        },
      },
      formIsValid: false,
    };
  }

  checkValidity(value, rules) {
    // console.log("checkValidity", value, rules);
    let isValid = true;
    if (value !== null && value !== undefined) {
      if (rules.required) {
        isValid = value !== "" && value !== null && isValid;
      }

      if (rules.minLength) {
        isValid = value.trim().length >= rules.minLength && isValid;
      }

      if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
      }

      if (rules.isContactNo) {
        var pattern = /^\d{10}$/;
        isValid = pattern.test(value) && isValid;
      }

      if (rules.range) {
        isValid = value >= rules.range.start;
      }

      if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
        // console.log("isnumber", value, typeof value);
      }

      if (rules.isEmail) {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        isValid = pattern.test(value) && isValid;
      }

      if (rules.onlyAlphabets) {
        const pattern = /^[A-Za-z ]+$/;
        isValid = pattern.test(value) && isValid;
      }

      if (rules.AlphaNumeric) {
        const pattern = /^[a-zA-Z0-9]+$/;
        isValid = pattern.test(value) && isValid;
      }

      // if (rules.noPastDate) {
      //   isValid =
      //     Date.parse(moment(Date.now()).subtract(1, "day")._d) <=
      //       Date.parse(value) && isValid;
      // }
      // if (rules.noFutureDate) {
      //   isValid =
      //     Date.parse(moment(Date.now()).subtract(1, "day")._d) >=
      //       Date.parse(value) && isValid;

      //   console.log("date validation", moment("25/03/1998"));
      // }
    } else {
      isValid = false;
    }
    return isValid;
  }

  inputChangeHandler = (event, inputName) => {
    // console.log("Event", event.target.value);
    let updatedForm = { ...this.state.formData };
    
    if(inputName === "countryCode"){
      updatedForm.mobile.validation.maxLength = limitPh(event.target.value)
      updatedForm.mobile.validation.minLength = limitPh(event.target.value)
    }
    // else if(inputName==="mobile"){
    //   updatedForm.mobile.validation.maxLength = limitPh(updatedForm.countryCode.value)
    //   updatedForm.mobile.validation.minLength = limitPh(updatedForm.countryCode.value)
    // }
    const updatedFormElement = { ...updatedForm[inputName] };

    if (0) {
      updatedFormElement.value = event;
    } else {
      updatedFormElement.value = event.target.value.trimStart();
    }

    updatedFormElement.value = event.target.value;

    updatedForm[inputName] = updatedFormElement;
    updatedForm[inputName].valid = this.checkValidity(
      updatedForm[inputName].value,
      updatedForm[inputName].validation
    );
    let formValid = true;
    for (let key in updatedForm) {
      formValid =
        updatedForm[key].valid && formValid && updatedForm[key].value !== "";
    }
    // console.log("form Valid", formValid);
    this.setState({ formIsValid: formValid });
    this.setState({ formData: updatedForm });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSelect = (val) => {
    console.log("val acess>>>>>>",val)
    this.setState({
      accessLevel: val,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let baseState = { ...this.state };
    this.setState({
      error: "",
      loading: true,
    });
    let API = new Apilib();
    const data = {};
    // let password = generatePassword(6);

    for (let inputType in this.state.formData) {
      data[inputType] = this.state.formData[inputType].value;
      if (1) {
        var postData = {
          ...data,
          type: 1,
          "access-level": this.state.accessLevel.value,
          "uid": this.props.user.uid
        };
        // setPostData({});
      }
    }
    // let data = {
    //   ...this.state,
    //   type: 1,
    //   uid: this.props.user.uid,
    //   phone: this.state.phone,
    //   "access-level": this.state.accessLevel.value,
    //   password: "pwd@123",
    // };
    delete data.loading;
    delete data.error;
    delete data.success;
    API.create(JSON.stringify(postData))
      .then((result) => {
        let res =result.data
        console.log("UserDataa",res)
        this.setState({
          loading: false,
        });
        // if (res.ok) {
          this.setState({
            success: true,
            name: "",
            email: "",
            accessLevel: null,
            phone: "",
          });
          Notify({
            alert: true,
            type: "success",
            title: "User added successfully",
          });
        // } else {
        //   res.text().then((data) => {
        //     this.setState({
        //       error: JSON.parse(data).message,
        //     });
        //   });
        //   Notify({
        //     alert: true,
        //     type: "error",
        //     title: "Error occured",
        //   });
        // }
        this.props.history.push("/admin/users");
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
        Notify({
          alert: true,
          type: "error",
          title: "Error occured",
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Add User</h4>
          </div>
        </div>
        {this.state.error && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            {this.state.error}
          </div>
        )}
        {this.state.success && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            User added successfully
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={(e) => this.onSubmit(e)}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      //   value={this.state.name}
                      //   onChange={(e) => this.onChange(e)}
                      required
                      type="text"
                      placeholder="Enter Name"
                      value={this.state.formData.name.value}
                      error={!this.state.formData["name"].valid}
                      onChange={(e) => this.inputChangeHandler(e, "name")}
                    />
                    {!this.state.formData["name"].valid ? (
                      <p className="error-messege">
                        <small style={{ color: "red" }}>
                          {this.state.formData["name"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="email"
                      //   value={this.state.email}
                      required
                      //   onChange={(e) => this.onChange(e)}
                      type="email"
                      placeholder="Enter Email"
                      value={this.state.formData.email.value}
                      error={!this.state.formData["email"].valid}
                      onChange={(e) => this.inputChangeHandler(e, "email")}
                    />
                    {!this.state.formData["email"].valid ? (
                      <p className="error-messege">
                        <small style={{ color: "red" }}>
                          {this.state.formData["email"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Phone number<span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-2">
                        <input
                          className="form-control"
                          name="countryCode"
                          
                          //   value={this.state.phone}
                          required
                          //   onChange={(e) => this.onChange(e)}
                          type="text"
                          placeholder="61"
                          value={this.state.formData.countryCode.value}
                          // error={!this.state.formData["countryCode"].valid}
                          onChange={(e) => {
                            this.inputChangeHandler(e, "countryCode");
                          }}
                        />
                      </div>
                      <div className="col-10">
                        <input
                          className="form-control"
                          name="mobile"
                          //   value={this.state.phone}
                          required
                          //   onChange={(e) => this.onChange(e)}
                          type="text"
                          placeholder="eg 5647891254"
                          value={this.state.formData.mobile.value}
                          error={!this.state.formData["mobile"].valid}
                          onChange={(e) => {
                            // console.log("e :",e.target.value)
                            this.inputChangeHandler(e, "mobile")
                          }}
                        />
                      </div>
                    </div>
                    {!this.state.formData["countryCode"].valid ? (
                      <p className="error-messege">
                        <small style={{ color: "red" }}>
                          {this.state.formData["countryCode"].errMsg}
                        </small>
                      </p>
                    ) : null}
                    {!this.state.formData["mobile"].valid ? (
                      <p className="error-messege">
                        <small style={{ color: "red" }}>
                          {this.state.formData["mobile"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Access Level<span className="text-danger">*</span>
                    </label>
                    <Select
                      required
                      value={this.state.accessLevel}
                      onChange={(item) => this.onSelect(item)}
                      options={options}
                    />
                  </div>
                </div>
              </div>
              <div className="m-t-20 text-center">
                <button
                  disabled={!this.state.formIsValid}
                  className="btn btn-primary submit-btn"
                >
                  Create User
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
