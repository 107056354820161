import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Link, withRouter } from 'react-router-dom';
import Apilib from '../../api'
import SearchResults from './SearchResults'


// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters


class SearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      fetchSpecialities:[],
      searchValue:""
    };    
  }

  getAllSpeciality = ( )=>{
    let API = new Apilib();
    API.getSpecialities().then((res) =>{
        return res.json();
    }).then((data)=>{
      
      this.setState({
        fetchSpecialities: data.data
      })
      console.log("Data speciality: ",this.state.fetchSpecialities)
    }).catch(err => console.log("getSpecialities err", err))  
  }

  componentDidMount =()=>{
    this.getAllSpeciality()
  }

   escapeRegexCharacters =(str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  
 getSuggestionValue = (suggestion) =>{
    return suggestion.speciality;
  }
  
  renderSuggestion=(suggestion) =>{
    return (
    //  <div className="d-flex justify-content-start">
    //     <ul className="search-cont">
    //       <li className="search-list">
    //         {/* <i class="fas fa-stethoscope"></i> */}
    //         {suggestion.speciality}
    //       </li>
    //     </ul>
    //   </div>
    <span>

      <i style={{marginRight:"5%",color:"#67D3EE"}} class="fa fa-user-md" aria-hidden="true"/>
      <Link className="nav-link-search" to={`/searched?search=${suggestion.speciality}`}>{suggestion.speciality}</Link>
      
    </span>
    );
  }

  getSuggestions =(value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');

    const spResult=this.state.fetchSpecialities.filter(language => regex.test(language.speciality));

    if(spResult.length===0 && !!value){
      return [{speciality:value}]
    }
    
    if (escapedValue === '') {
      return this.state.fetchSpecialities;
    }
    //if(!!value===false) return this.state.fetchSpecialities;
  
    return this.state.fetchSpecialities.filter(language => regex.test(language.speciality));
  }


  onChange = (event, { newValue, method }) => {
    //console.log("Search events>>>>>>>>>",event.target.value)
    if(newValue===""){
      this.setState({
        value: event.target.value
      })
      //console.log("Search value1>>>",this.state.value)
    }
    this.setState({
      value: newValue
    });
    // console.log("Search value2>>>",this.state.value)
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSearchValue =(value)=>{
    //console.log("Search value:",value)
    if(value){
      
    }

  }

  onKeyUp = (event) =>{
    if(event.keyCode===13){
      !!this.state.value &&
      this.props.history.push(`/searched?search=${this.state.value}`)
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search by professional or business name",
      value,
      onKeyUp:this.onKeyUp,
      onChange: this.onChange,
      onFocus : () => this.setState({
        suggestions : this.state.fetchSpecialities
      })
    };

    return (
      <Autosuggest 
        onSuggestionSelected={(e,{ suggestion, suggestionValue, suggestionIndex, sectionIndex, method })=>this.onSearchValue(suggestionValue)}
        alwaysRenderSuggestions={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps} />
    );
  }
}

export default withRouter(SearchBar) ;
