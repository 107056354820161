import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
var isMobile = true



const EPrescriptionForm2 = React.forwardRef((props, ref) => {
  let appointmentDet = {
    "doctor_email": "aimoolaram@gmail.com",
    "patientId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
    "created_at": 1676155568000,
    "appointment_payment": {
      "label": "Cash",
      "value": "1"
    },
    "slot_duration": "30:00",
    "doctorName": "Moola Ram",
    "doctorId": "7q6GWjtjZ6bjiqjdgmaEN2boYRt1",
    "hospitalId": "71ZNVAMJgBHhgAFb1mrP",
    "from": 1676266200000,
    "slot_time": "11:00 AM",
    "email": "ridmal2009@gmail.com",
    "hospital_address": "",
    "hospital_name": "Clinic",
    "doctor_specialities": [
      "General Physician",
      "Endocrologist"
    ],
    "appointmentType": "2",
    "slot_date": "13/02/2023",
    "doctor_experience": "3",
    "for_family_member": false,
    "userId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
    "phoneno": "919672699969",
    "base_fee": "300",
    "country_code": "",
    "appointmentpayment": "1",
    "patient_email": "ridmal2009@gmail.com",
    "name": "moola borawat",
    "to": 1676268000000,
    "userType": "5",
    "join_url": "http://53digital.com/demos/medvatic/#/call?meetingid=ys18pw8i2D&appointmentid=9u1MxB1OwBxHsh2U3PGE",
    "appointmentId": "9u1MxB1OwBxHsh2U3PGE",
    "meetingid": "ys18pw8i2D",
    "token": "0067b9fa21ad2ed4216a5e0edad08e119fdIADYq02B9tXxhg5DKnIzOQNNoIMODtdnwjd2OMEYbJEF/uVBm1wAAAAAIgBVzBLbMGzpYwQAAQDgNepjAgDgNepjAwDgNepjBADgNepj",
    "appointment_status": "completed",
    "patient_docs": [
      {
        "uploadedOn": "2023-02-28T11:59:45.263Z",
        "name": "AwesomeFileName.pdf",
        "id": "DSLoNQe6pA",
        "path": "/undefined/undefined/appointments/9u1MxB1OwBxHsh2U3PGE/reports/4b0b60d0-7e7d-4c6c-af96-d242ce8af6b1-AwesomeFileName.pdf",
        "publicUrl": "https://firebasestorage.googleapis.com/v0/b/medvatic-438af.appspot.com/o/undefined%2Fundefined%2Fappointments%2F9u1MxB1OwBxHsh2U3PGE%2Freports%2F4b0b60d0-7e7d-4c6c-af96-d242ce8af6b1-AwesomeFileName.pdf?alt=media&token=7c0a9b2a-0004-4e30-ad37-be985993d94c"
      },
      {
        "publicUrl": "https://firebasestorage.googleapis.com/v0/b/medvatic-438af.appspot.com/o/undefined%2Fundefined%2Fappointments%2F9u1MxB1OwBxHsh2U3PGE%2Freports%2Fa87c6e98-8cff-48a1-b555-96d1f5478308-AwesomeFileName.pdf?alt=media&token=de158b4c-8470-49d5-ad5c-6c8bc1f79aa1",
        "id": "yLMaRYFQEG",
        "uploadedOn": "2023-02-28T12:00:32.747Z",
        "name": "AwesomeFileName.pdf",
        "path": "/undefined/undefined/appointments/9u1MxB1OwBxHsh2U3PGE/reports/a87c6e98-8cff-48a1-b555-96d1f5478308-AwesomeFileName.pdf"
      }
    ]
  }
  let medicines = [
    {
      "medicines": [
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": true,
            "AF": false,
            "NT": true
          },
          "subtitle": "warm",
          "qty": 3,
          "name": "Paracetamol",
          "am_bm_stat": {
            "MN": "After",
            "EV": "After",
            "AF": "After",
            "NT": "After"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": false,
            "NT": true
          },
          "qty": 2,
          "subtitle": "cold water",
          "name": "Dolo",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "10",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": true,
            "NT": false
          },
          "subtitle": "nothing",
          "qty": 20,
          "name": "Er",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": true,
            "AF": false,
            "NT": true
          },
          "subtitle": "warm",
          "qty": 3,
          "name": "Paracetamol",
          "am_bm_stat": {
            "MN": "After",
            "EV": "After",
            "AF": "After",
            "NT": "After"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": false,
            "NT": true
          },
          "qty": 2,
          "subtitle": "cold water",
          "name": "Dolo",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "10",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": true,
            "NT": false
          },
          "subtitle": "nothing",
          "qty": 20,
          "name": "Er",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": true,
            "AF": false,
            "NT": true
          },
          "subtitle": "warm",
          "qty": 3,
          "name": "Paracetamol",
          "am_bm_stat": {
            "MN": "After",
            "EV": "After",
            "AF": "After",
            "NT": "After"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": false,
            "NT": true
          },
          "qty": 2,
          "subtitle": "cold water",
          "name": "Dolo",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "10",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": true,
            "NT": false
          },
          "subtitle": "nothing",
          "qty": 20,
          "name": "Er",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },


      ],
      "prescriptionId": "5wHdqNAYcPySkHscbpgY"
    }
  ]
  const [medicareNumber, setMedicareNumber] = useState('');
  const [PBS, setPBS] = useState('');

  const [width, setWidth] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth <= 768);
    isMobile = window.innerWidth <= 768;
    console.log(isMobile, window.innerWidth)
    console.log(width)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [width]);



  const handlemedicareNumber = (e) => setMedicareNumber(e.target.value)
  const handlePBS = (e) => setPBS(e.target.value)




















  const themeColor = '#C6C6C6';

  const Container = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin: 6%;
  padding: 20px;
  border: 1px solid ${themeColor};
  background-color: #EAFCFF;
  border-radius:21px;
`;

  const Section = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  background-color: ${themeColor};
`;

  const TopSection = styled(Section)`
  height: 50px;
`;

  const BottomSection = styled(Section)`
  height: 50px;
`;

  const MainBody = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  position: relative;
  border: 1px solid ${themeColor};
  background-color: #fff;
  margin:-20px;
  padding:20px;
`;



  // const Container = styled.div`
  // font-size:${width ? '11px' : '21px'};
  //   margin: 20px;
  //   padding: 20px;
  //   border: 1px solid black;
  // `;

  const Header = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  justify-content: space-between;
  padding:15px;
`;

  const DoctorInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  flex-direction: column;
`;

  const DoctorName = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  font-weight: 800;
  color:#00a1ba;
  font-size:${!isMobile ? '10px' : '21px'};
  margin:10px;
`;


  const Address = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  white-space: pre-line;
  margin:10px;
`;

  const PrescriberInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  flex-direction: column;
  text-align: right;
`;

  const PrescriberNo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  white-space: pre-line;
  margin:10px;
`;

  const PhoneNumber = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  
;`;

  // const MainBody = styled.div`
  // font-size:${width ? '11px' : '21px'};
  //   margin-top: 20px;
  // `;

  const PatientInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

  const Label = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 0 0 50%;
  
`;

  const Input = styled.input`
  width: 75%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

  const CheckboxRow = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  margin-bottom: 10px;
`;

  const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

  const Checkbox = styled.input`
  margin-right: 5px;
`;

  const SmallInput = styled.input`
  width: 80px;
`;

  const PrescriptionDesigned = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  margin-top: 20px;
`;

  const VerticalLogo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 0 0 100px;
`;

  const PrescriptionDetails = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 1;
`;

  const DrugInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-bottom: 10px;
`;

  const DrugName = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  font-weight: bold;
`;

  const DrugTime = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const DrugPurpose = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const TotalDrugs = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 20px;
`;

  const ScriptNo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  font-weight: bold;
`;

  const DrugsPrescribed = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
`;

  const ItemsPrinted = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
`;

  const DoctorNameAndDesignation = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  font-weight: bold;
`;

  const TurnOverNote = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 20px;
  text-align: right;
  font-weight: bold;
`;

  const Declaration = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 10px;
`;

  const DeclarationText = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const Footer = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

  const PatientSignature = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 1;
`;

  const DateOfSupply = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const AddressAndDoctorSignature = styled.div`
  font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  white-space: pre-line;
`;




















  return (
    <div id='report' style={{ width: '100%', }} ref={ref}>
      <Container>
        <Header>
          <DoctorInfo>
            <DoctorName>Dr. {appointmentDet.doctorName}</DoctorName>
            <Address>123 Main Street, City, State</Address>
            <PrescriberNo>Prescriber No. {appointmentDet.appointmentId}</PrescriberNo>
          </DoctorInfo>
          <PrescriberInfo>
            <PhoneNumber>Phone: {appointmentDet.phoneno}</PhoneNumber>

            <PhoneNumber>Hospital: {appointmentDet.hospital_name}</PhoneNumber>
            <PhoneNumber>Hospital ID: {appointmentDet.hospitalId}</PhoneNumber>
            <PhoneNumber>Prescription ID: {appointmentDet.appointmentId}</PhoneNumber>
          </PrescriberInfo>
        </Header>
        <MainBody>
          <PatientInfo>
            <Label>Patient's Medicare No: </Label>
            {/* <Label>Patient's Medicare No: {appointmentDet.patientId}</Label> */}
            {/* <Input /> */}
            <Input type="text" value={medicareNumber} onChange={handlemedicareNumber} />
          </PatientInfo>
          <PatientInfo>
            <Label>Pharmaceutical Benefits Entitlement No: </Label>
            <Input type="text" />
            {/* <Input /> */}
          </PatientInfo>
          <CheckboxRow>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              Safety Net Entitlement Cardholder
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              Concessional or Dependent RPBS Beneficiary or Safety Concession Cardholder
            </CheckboxLabel>
          </CheckboxRow>
          <PatientInfo>
            <Label>Patient's Name: {appointmentDet.name}</Label>
            {/* <Input /> */}
          </PatientInfo>
          <PatientInfo>
            <Label>Address: { }</Label>
            {/* <Input /> */}
          </PatientInfo>
          <PatientInfo>
            <Label>Date: {appointmentDet.slot_date}</Label>
            {/* <Input /> */}
          </PatientInfo>
          <CheckboxRow>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              PBS
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              RPBS
            </CheckboxLabel>
          </CheckboxRow>
          <PatientInfo>
            {/* <Label>Brand Substitution Not Permitted:</Label>
          <SmallInput /> */}
            <CheckboxRow>
              <CheckboxLabel>
                <Checkbox type="checkbox" />
                Brand Substitution Not Permitted:
              </CheckboxLabel>
            </CheckboxRow>
          </PatientInfo>
          <PrescriptionDesigned>
            {/* <CrossLines />
          <CrossLines />
          <CrossLines />
          <CrossLines />
          <CrossLines /> */}
            <VerticalLogo>
              {/* <img
                src={logo}
                alt="new"
                style={{ width: '110%', height: '45px', transform: 'rotate(90deg)', top: '30%', position: 'relative' }}
              /> */}
            </VerticalLogo>
            <PrescriptionDetails>
              {medicines[0].medicines.map(x => {
                return <DrugInfo>
                  <DrugName>{x.name}</DrugName>
                  {x.intake_time.MN ? <DrugTime>Take at 8 AM  {x.am_bm_stat.MN} food</DrugTime> : null}
                  {x.intake_time.AF ? <DrugTime>Take at 1 PM {x.am_bm_stat.AF} food</DrugTime> : null}
                  {x.intake_time.EV ? <DrugTime>Take at 5 PM {x.am_bm_stat.EV} food</DrugTime> : null}
                  {x.intake_time.NT ? <DrugTime>Take at 8 PM {x.am_bm_stat.NT} food</DrugTime> : null}
                  <DrugPurpose>Purpose: {x.subtitle}</DrugPurpose>
                </DrugInfo>
              })}
              {/* <DrugInfo>
              <DrugName>Drug A</DrugName>
              <DrugTime>Take at 8 AM</DrugTime>
              <DrugPurpose>Purpose: ...</DrugPurpose>
            </DrugInfo>
            <DrugInfo>
              <DrugName>Drug B</DrugName>
              <DrugTime>Take at 12 PM</DrugTime>
              <DrugPurpose>Purpose: ...</DrugPurpose>
            </DrugInfo> */}
              {/* Add more DrugInfo components as needed */}
            </PrescriptionDetails>
          </PrescriptionDesigned>
          <TotalDrugs>
            <ScriptNo>Script No: 123456</ScriptNo>
            <DrugsPrescribed>Total: {medicines[0].medicines.reduce((accumulator, currentValue) => {
              // console.log(currentValue)
              return accumulator + currentValue.qty
            },
              0)} drugs</DrugsPrescribed>
            <ItemsPrinted>{medicines[0].medicines.length} Items Printed</ItemsPrinted>
            <DoctorNameAndDesignation>{appointmentDet.doctorName}<ItemsPrinted> {appointmentDet.doctor_specialities.map(x => x + ', ')}</ItemsPrinted></DoctorNameAndDesignation>
          </TotalDrugs>
          <TurnOverNote>Turn over for privacy note</TurnOverNote>
          <Declaration>
            <DeclarationText>
              I declare that I have received these medicines and the information relating to any entitlement to a pharmaceutical benefit is correct.
            </DeclarationText>
          </Declaration>
        </MainBody>
        <Footer>
          <PatientSignature>Signature: _______________</PatientSignature>
          <DateOfSupply>Date of Supply: {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}</DateOfSupply>
          <AddressAndDoctorSignature>
            Address: ___________________ <br /><br /><br />
            Doctor's Signature: _______________
          </AddressAndDoctorSignature>
        </Footer>
      </Container>
    </div>
  );



});

const EPrescriptionForm = () => {
  const componentRef = useRef();
  const handlePrint = async () => {

    const pdf = new jsPDF();
    const handleDownloadPDF = () => {
      const input = document.getElementById('report');

      html2canvas(input)
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png', 1.0);
          pdf.addImage(imgData, 'PNG', 0, 0, 207, 297, { format: 'PNG', ImageCompression: 'SLOW' });
          // pdf.save('exported1.pdf');
        });
    };
    handleDownloadPDF()



    async function signPDF(pdfFilePath, signatureDataUrl, outputFilePath) {
      try {

        // const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
        const existingPdfBytes = await pdf.output('arraybuffer')
        // pdf.path()
        console.log(pdfFilePath, 'this is path233')


        // const pdfDoc = await PDFDocument.load(arrayBuffer);
        // const existingPdfBytes = await fs.promises.readFile(pdfFilePath).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        // Read the signature image from the data URL
        const signatureImage = await pdfDoc.embedPng(signatureDataUrl);
        // Get the first page of the PDF document
        const firstPage = pdfDoc.getPages()[0];

        // Get the size of the first page
        const { width, height } = firstPage.getSize();

        // Set the position and size of the signature on the page
        const signatureWidth = 60; // Set the width of the signature
        const signatureHeight = 15;
        const signatureX = width - 150; // Set the X position of the signature
        const signatureY = height - height + (height * .02); // Set the Y position of the signature

        // Draw the signature on the first page
        firstPage.drawImage(signatureImage, {
          x: signatureX,
          y: signatureY,
          width: signatureWidth,
          height: signatureHeight,
        });

        // Save the modified PDF to a new file
        const modifiedPdfBytes = await pdfDoc.save();

        download(modifiedPdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
        console.log('PDF has been signed and saved successfully!');
        console.log(modifiedPdfBytes)
      } catch (error) {
        console.error('Error signing PDF:', error);
        throw error;
      }
    }

    const pdfFilePath = './exported.pdf'; // Replace with the path to your PDF
    const signatureDataUrl = await fetch('http://127.0.0.1:2000/products').then(x => {
      return x.json()
    }).then(x => {
      console.log('this is res')
      console.log(x)
      return x.msg
    }); // Replace with the signature data URL
    const outputFilePath = './thisissigned.pdf'; // Replace with the path to the signed PDF
    console.log('signatureDataUrlthis is what kdnanm')
    // console.log(signatureDataUrl)

    await signPDF(pdfFilePath, signatureDataUrl, outputFilePath)
      .catch((error) => {
        console.error('Error:', error);
      });



  }
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });



  document.addEventListener(
    "wheel",
    function touchHandler(e) {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, { passive: false });


  window.addEventListener('keydown', function (e) {
    if ((e.ctrlKey || e.metaKey) && (e.which === 61 || e.which === 107 || e.which === 173 || e.which === 109 || e.which === 187 || e.which === 189)) {
      e.preventDefault();
    }
  }, false);
  let appointmentDet = {
    "doctor_email": "aimoolaram@gmail.com",
    "patientId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
    "created_at": 1676155568000,
    "appointment_payment": {
      "label": "Cash",
      "value": "1"
    },
    "slot_duration": "30:00",
    "doctorName": "Moola Ram",
    "doctorId": "7q6GWjtjZ6bjiqjdgmaEN2boYRt1",
    "hospitalId": "71ZNVAMJgBHhgAFb1mrP",
    "from": 1676266200000,
    "slot_time": "11:00 AM",
    "email": "ridmal2009@gmail.com",
    "hospital_address": "",
    "hospital_name": "Clinic",
    "doctor_specialities": [
      "General Physician",
      "Endocrologist"
    ],
    "appointmentType": "2",
    "slot_date": "13/02/2023",
    "doctor_experience": "3",
    "for_family_member": false,
    "userId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
    "phoneno": "919672699969",
    "base_fee": "300",
    "country_code": "",
    "appointmentpayment": "1",
    "patient_email": "ridmal2009@gmail.com",
    "name": "moola borawat",
    "to": 1676268000000,
    "userType": "5",
    "join_url": "http://53digital.com/demos/medvatic/#/call?meetingid=ys18pw8i2D&appointmentid=9u1MxB1OwBxHsh2U3PGE",
    "appointmentId": "9u1MxB1OwBxHsh2U3PGE",
    "meetingid": "ys18pw8i2D",
    "token": "0067b9fa21ad2ed4216a5e0edad08e119fdIADYq02B9tXxhg5DKnIzOQNNoIMODtdnwjd2OMEYbJEF/uVBm1wAAAAAIgBVzBLbMGzpYwQAAQDgNepjAgDgNepjAwDgNepjBADgNepj",
    "appointment_status": "completed",
    "patient_docs": [
      {
        "uploadedOn": "2023-02-28T11:59:45.263Z",
        "name": "AwesomeFileName.pdf",
        "id": "DSLoNQe6pA",
        "path": "/undefined/undefined/appointments/9u1MxB1OwBxHsh2U3PGE/reports/4b0b60d0-7e7d-4c6c-af96-d242ce8af6b1-AwesomeFileName.pdf",
        "publicUrl": "https://firebasestorage.googleapis.com/v0/b/medvatic-438af.appspot.com/o/undefined%2Fundefined%2Fappointments%2F9u1MxB1OwBxHsh2U3PGE%2Freports%2F4b0b60d0-7e7d-4c6c-af96-d242ce8af6b1-AwesomeFileName.pdf?alt=media&token=7c0a9b2a-0004-4e30-ad37-be985993d94c"
      },
      {
        "publicUrl": "https://firebasestorage.googleapis.com/v0/b/medvatic-438af.appspot.com/o/undefined%2Fundefined%2Fappointments%2F9u1MxB1OwBxHsh2U3PGE%2Freports%2Fa87c6e98-8cff-48a1-b555-96d1f5478308-AwesomeFileName.pdf?alt=media&token=de158b4c-8470-49d5-ad5c-6c8bc1f79aa1",
        "id": "yLMaRYFQEG",
        "uploadedOn": "2023-02-28T12:00:32.747Z",
        "name": "AwesomeFileName.pdf",
        "path": "/undefined/undefined/appointments/9u1MxB1OwBxHsh2U3PGE/reports/a87c6e98-8cff-48a1-b555-96d1f5478308-AwesomeFileName.pdf"
      }
    ]
  }
  let medicines = [
    {
      "medicines": [
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": true,
            "AF": false,
            "NT": true
          },
          "subtitle": "warm",
          "qty": 3,
          "name": "Paracetamol",
          "am_bm_stat": {
            "MN": "After",
            "EV": "After",
            "AF": "After",
            "NT": "After"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": false,
            "NT": true
          },
          "qty": 2,
          "subtitle": "cold water",
          "name": "Dolo",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "10",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": true,
            "NT": false
          },
          "subtitle": "nothing",
          "qty": 20,
          "name": "Er",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": true,
            "AF": false,
            "NT": true
          },
          "subtitle": "warm",
          "qty": 3,
          "name": "Paracetamol",
          "am_bm_stat": {
            "MN": "After",
            "EV": "After",
            "AF": "After",
            "NT": "After"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": false,
            "NT": true
          },
          "qty": 2,
          "subtitle": "cold water",
          "name": "Dolo",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "10",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": true,
            "NT": false
          },
          "subtitle": "nothing",
          "qty": 20,
          "name": "Er",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": true,
            "AF": false,
            "NT": true
          },
          "subtitle": "warm",
          "qty": 3,
          "name": "Paracetamol",
          "am_bm_stat": {
            "MN": "After",
            "EV": "After",
            "AF": "After",
            "NT": "After"
          }
        },
        {
          "num_days": "1",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": false,
            "NT": true
          },
          "qty": 2,
          "subtitle": "cold water",
          "name": "Dolo",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },
        {
          "num_days": "10",
          "intake_time": {
            "MN": true,
            "EV": false,
            "AF": true,
            "NT": false
          },
          "subtitle": "nothing",
          "qty": 20,
          "name": "Er",
          "am_bm_stat": {
            "EV": "Before",
            "MN": "Before",
            "AF": "Before",
            "NT": "Before"
          }
        },


      ],
      "prescriptionId": "5wHdqNAYcPySkHscbpgY"
    }
  ]
  const [medicareNumber, setMedicareNumber] = useState('');
  const [PBS, setPBS] = useState('');

  const [width, setWidth] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth <= 768);
    isMobile = window.innerWidth <= 768;
    console.log(isMobile, window.innerWidth)
    console.log(width)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [width]);



  const handlemedicareNumber = (e) => setMedicareNumber(e.target.value)
  const handlePBS = (e) => setPBS(e.target.value)




















  const themeColor = '#C6C6C6';

  const Container = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin: 6%;
  padding: 20px;
  border: 1px solid ${themeColor};
  background-color: #EAFCFF;
  border-radius:21px;
`;

  const Section = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  background-color: ${themeColor};
`;

  const TopSection = styled(Section)`
  height: 50px;
`;

  const BottomSection = styled(Section)`
  height: 50px;
`;

  const MainBody = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  position: relative;
  border: 1px solid ${themeColor};
  background-color: #fff;
  margin:-20px;
  padding:20px;
`;



  // const Container = styled.div`
  // font-size:${width ? '11px' : '21px'};
  //   margin: 20px;
  //   padding: 20px;
  //   border: 1px solid black;
  // `;

  const Header = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  justify-content: space-between;
  padding:15px;
`;

  const DoctorInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  flex-direction: column;
`;

  const DoctorName = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  font-weight: 800;
  color:#00a1ba;
  font-size:${!isMobile ? '10px' : '21px'};
  margin:10px;
`;


  const Address = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  white-space: pre-line;
  margin:10px;
`;

  const PrescriberInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  flex-direction: column;
  text-align: right;
`;

  const PrescriberNo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  white-space: pre-line;
  margin:10px;
`;

  const PhoneNumber = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  
;`;

  // const MainBody = styled.div`
  // font-size:${width ? '11px' : '21px'};
  //   margin-top: 20px;
  // `;

  const PatientInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

  const Label = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 0 0 50%;
  
`;

  const Input = styled.input`
  width: 75%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

  const CheckboxRow = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  margin-bottom: 10px;
`;

  const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

  const Checkbox = styled.input`
  margin-right: 5px;
`;

  const SmallInput = styled.input`
  width: 80px;
`;

  const PrescriptionDesigned = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  display: flex;
  margin-top: 20px;
`;

  const VerticalLogo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 0 0 100px;
`;

  const PrescriptionDetails = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 1;
`;

  const DrugInfo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-bottom: 10px;
`;

  const DrugName = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  font-weight: bold;
`;

  const DrugTime = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const DrugPurpose = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const TotalDrugs = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 20px;
`;

  const ScriptNo = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  font-weight: bold;
`;

  const DrugsPrescribed = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
`;

  const ItemsPrinted = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
`;

  const DoctorNameAndDesignation = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  font-weight: bold;
`;

  const TurnOverNote = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 20px;
  text-align: right;
  font-weight: bold;
`;

  const Declaration = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 10px;
`;

  const DeclarationText = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const Footer = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

  const PatientSignature = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};
  flex: 1;
`;

  const DateOfSupply = styled.div`
    font-size:${!isMobile ? '8px' : '21px'};`;

  const AddressAndDoctorSignature = styled.div`
  font-size:${!isMobile ? '8px' : '21px'};
  text-align: right;
  white-space: pre-line;
`;




















  return (
    <div id='report' style={{ width: '100%', }}>
      <Container>
        <Header>
          <DoctorInfo>
            <DoctorName>Dr. {appointmentDet.doctorName}</DoctorName>
            <Address>123 Main Street, City, State</Address>
            <PrescriberNo>Prescriber No. {appointmentDet.appointmentId}</PrescriberNo>
          </DoctorInfo>
          <PrescriberInfo>
            <PhoneNumber>Phone: {appointmentDet.phoneno}</PhoneNumber>

            <PhoneNumber>Hospital: {appointmentDet.hospital_name}</PhoneNumber>
            <PhoneNumber>Hospital ID: {appointmentDet.hospitalId}</PhoneNumber>
            <PhoneNumber>Prescription ID: {appointmentDet.appointmentId}</PhoneNumber>
          </PrescriberInfo>
        </Header>
        <MainBody>
          <PatientInfo>
            <Label>Patient's Medicare No: </Label>
            {/* <Label>Patient's Medicare No: {appointmentDet.patientId}</Label> */}
            {/* <Input /> */}
            <Input type="text" value={medicareNumber} onChange={handlemedicareNumber} />
          </PatientInfo>
          <PatientInfo>
            <Label>Pharmaceutical Benefits Entitlement No: </Label>
            <Input type="text" />
            {/* <Input /> */}
          </PatientInfo>
          <CheckboxRow>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              Safety Net Entitlement Cardholder
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              Concessional or Dependent RPBS Beneficiary or Safety Concession Cardholder
            </CheckboxLabel>
          </CheckboxRow>
          <PatientInfo>
            <Label>Patient's Name: {appointmentDet.name}</Label>
            {/* <Input /> */}
          </PatientInfo>
          <PatientInfo>
            <Label>Address: { }</Label>
            {/* <Input /> */}
          </PatientInfo>
          <PatientInfo>
            <Label>Date: {appointmentDet.slot_date}</Label>
            {/* <Input /> */}
          </PatientInfo>
          <CheckboxRow>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              PBS
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              RPBS
            </CheckboxLabel>
          </CheckboxRow>
          <PatientInfo>
            {/* <Label>Brand Substitution Not Permitted:</Label>
          <SmallInput /> */}
            <CheckboxRow>
              <CheckboxLabel>
                <Checkbox type="checkbox" />
                Brand Substitution Not Permitted:
              </CheckboxLabel>
            </CheckboxRow>
          </PatientInfo>
          <PrescriptionDesigned>
            {/* <CrossLines />
          <CrossLines />
          <CrossLines />
          <CrossLines />
          <CrossLines /> */}
            <VerticalLogo>
              {/* <img
                src={logo}
                alt="new"
                style={{ width: '110%', height: '45px', transform: 'rotate(90deg)', top: '30%', position: 'relative' }}
              /> */}
            </VerticalLogo>
            <PrescriptionDetails>
              {medicines[0].medicines.map(x => {
                return <DrugInfo>
                  <DrugName>{x.name}</DrugName>
                  {x.intake_time.MN ? <DrugTime>Take at 8 AM  {x.am_bm_stat.MN} food</DrugTime> : null}
                  {x.intake_time.AF ? <DrugTime>Take at 1 PM {x.am_bm_stat.AF} food</DrugTime> : null}
                  {x.intake_time.EV ? <DrugTime>Take at 5 PM {x.am_bm_stat.EV} food</DrugTime> : null}
                  {x.intake_time.NT ? <DrugTime>Take at 8 PM {x.am_bm_stat.NT} food</DrugTime> : null}
                  <DrugPurpose>Purpose: {x.subtitle}</DrugPurpose>
                </DrugInfo>
              })}
              {/* <DrugInfo>
              <DrugName>Drug A</DrugName>
              <DrugTime>Take at 8 AM</DrugTime>
              <DrugPurpose>Purpose: ...</DrugPurpose>
            </DrugInfo>
            <DrugInfo>
              <DrugName>Drug B</DrugName>
              <DrugTime>Take at 12 PM</DrugTime>
              <DrugPurpose>Purpose: ...</DrugPurpose>
            </DrugInfo> */}
              {/* Add more DrugInfo components as needed */}
            </PrescriptionDetails>
          </PrescriptionDesigned>
          <TotalDrugs>
            <ScriptNo>Script No: 123456</ScriptNo>
            <DrugsPrescribed>Total: {medicines[0].medicines.reduce((accumulator, currentValue) => {
              // console.log(currentValue)
              return accumulator + currentValue.qty
            },
              0)} drugs</DrugsPrescribed>
            <ItemsPrinted>{medicines[0].medicines.length} Items Printed</ItemsPrinted>
            <DoctorNameAndDesignation>{appointmentDet.doctorName}<ItemsPrinted> {appointmentDet.doctor_specialities.map(x => x + ', ')}</ItemsPrinted></DoctorNameAndDesignation>
          </TotalDrugs>
          <TurnOverNote>Turn over for privacy note</TurnOverNote>
          <Declaration>
            <DeclarationText>
              I declare that I have received these medicines and the information relating to any entitlement to a pharmaceutical benefit is correct.
            </DeclarationText>
          </Declaration>
        </MainBody>
        <Footer>
          <PatientSignature>Signature: _______________</PatientSignature>
          <DateOfSupply>Date of Supply: {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}</DateOfSupply>
          <AddressAndDoctorSignature>
            Address: ___________________ <br /><br /><br />
            Doctor's Signature: _______________
          </AddressAndDoctorSignature>
        </Footer>
      </Container>
    </div>
  );
}

export default EPrescriptionForm;
