// import React from 'react';
import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import { Formik } from "formik";
import "jquery/dist/jquery.min.js";
import React, { Component, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { default as Api, default as Apilib } from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";
import { Link } from "react-router-dom";
import { data } from "jquery";

function Plugin() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [pluginApiKey, setPluginApiKey] = useState([]);
  const [deletePlugin, setDeletePlugin] = useState(null);
  const [selectedKey, setSelectedKey] = useState();
  const [active, setActive] = useState();

  useEffect(() => {
    PluginApi();
  }, []);

  function PluginApi() {
    setLoading(true);
    let API = new Apilib();
    API.PluginApiKey()
      .then((result) => {
        let res = result.data;
        console.log("PluginApiKey", res);
        setPluginApiKey(res.data);

        setSelectedKey(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }

  function PluginStat(domain) {
    console.log("First", domain);
    setLoading(true);
    let data = {
      status: !domain?.api_key_activated,
      key: domain?.id,
    };
    console.log("data1349", data);
    let API = new Apilib();
    API.PluginStatus(data)
      .then((result) => {
        let res = result.data;
        console.log("PluginStatus", res);
        PluginApi();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function deleteApiKey() {
    setLoading(true);
    let data = {
      api_key: deletePlugin.api_key,
    };

    console.log("data12345", deletePlugin);
    let API = new Apilib();
    API.PluginDeleteApi(data)
      .then((result) => {
        let res = result.data;
        console.log("deleteApi", res);
        Notify({
          type: "success",
          alert: true,
          title: "Busissness Plugin deleted successfully",
        });
        PluginApi();

      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setDeletePlugin();
  }

  return (
    <React.Fragment>
        {loading && <Loader />}
      <div className="row">
        <div className="col-sm-4 col-3">
          <h4 className="page-title">Plugin Managment</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {error && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
              {this.state.error}
            </div>
          )}

          <div className="table-responsive">
            <table className="table table-border table-striped custom-table datatable m-b-0">
              <thead>
                <tr>
                  <th>Domain Name</th>
                  {/* <th>Email</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody style={{ overflowY: "scroll" }}>
                {pluginApiKey &&
                  pluginApiKey.map((domain) => {
                    if (domain.api_key) {
                      return (
                        <tr>
                          <td className="UserName">{domain?.domain_Name}</td>
                          {/* <td></td> */}
                          <td>
                            <button
                              onClick={() => {
                                PluginStat(domain);
                              }}
                              type="button"
                                  class="btn btn-secondary"
                            >
                              {domain?.api_key_activated
                                ? "Active"
                                : "Inactive"}
                            </button>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <div></div>
                              <div style={{ marginLeft: "2%" }}>
                                <button
                                  onClick={() => {
                                    setDeletePlugin(domain);
                                  }}
                                  type="button"
                                  class="btn btn-danger"
                                >
                                  <span
                                    style={{
                                      marginRight: ".5em",
                                      marginTop: "-.1em",
                                      display: "inline-block",
                                    }}
                                    class="iconify"
                                    data-icon="ant-design:delete-twotone"
                                    data-inline="false"
                                  ></span>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={deletePlugin}
        onHide={() => setDeletePlugin()}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Delete user</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, You want to delete Plugin</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              deleteApiKey();
            }}
          >
            Yes
          </Button>
          <Button variant="danger" onClick={() => setDeletePlugin()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        show={active}
        onHide={() => setActive()}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Activate user</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, You want to Active Plugin</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              PluginStat();
            }}
          >
            Yes
          </Button>
          <Button variant="danger" onClick={() => setActive()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Plugin;
