import { customAlphabet } from "nanoid";
import React, { Component } from "react";
import BaseSelect from "react-select";
import BaseSelect2 from "react-select/creatable";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import GoogleMap from "../Agora/utils/GoogleMap";
import ArrayInput from "../ArrayInput";
import Loader from "../Loader/Loader";
let allowedCert = ".pdf";
const Select = (props) => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

const Select2 = (props) => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect2}
        options={props.options}
    />
);

const status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
];

const specialities = [
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "Dentist", label: "Dentist" },
    { value: "Gynaecologist", label: "Gynaecologist" },
    { value: "Paediatrician", label: "Paediatrician" },
    { value: "Psychiatrists", label: "Psychiatrists" },
    { value: 0, label: "Other" },
];

export default class AddDoctor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            firstName: "",
            geoLocation: "",
            lastName: "",
            qualifications: [],
            regnumber: "",
            regexpirydate: "",
            experience: "",
            gender: "",
            email: "",
            mobile: "",
            landline: "",
            error: "",
            success: false,
            long: "",
            lat: "",
            country_code: "",
            newSpecialityDetails: null,
            specialitiesObj: null,
            qualificationsObj: null,
            addSpecialities: false,
            allSpecialities: [],
            base_fee: 0,
            status: null,
            addSpecialityIsValid: false,
            description: "",
            consultation_reasons: [],
            area_of_interest: [],
            language_spoken: [],
            added: false,
            formData: {
                firstName: {
                    value: "",
                    validation: {
                        minLength: 3,
                        required: true,
                        onlyAlphabets: true,
                    },
                    errMsg: "Invalid First Name!",
                    valid: true,
                },
                lastName: {
                    value: "",
                    validation: {
                        minLength: 3,
                        required: true,
                        onlyAlphabets: true,
                    },
                    errMsg: "Invalid Last Name!",
                    valid: true,
                },
                email: {
                    value: "",
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    errMsg: "Invalid Email!",
                    valid: true,
                },
                mobile: {
                    value: "",
                    validation: {
                        required: true,
                        isNumeric: true,
                        minLength: 8,
                        maxLength: 15,
                        isPhoneNumber: true,
                    },
                    errMsg: "Max 15 digits are allowed!",
                    valid: true,
                },
            },
            formIsValid: false,
            todaysDate: "",
        };
    }
    validateFileSize_pdf(file) {
        let fileSize = file.size / 1024 / 1024;
        if (fileSize > 5) {
            this.setState(
                {
                    fileSizeError_pdf: "Sorry, Max filesize allowed is 5MB",
                },
                () => {
                    return false;
                }
            );
        } else {
            return true;
        }
    }
    validateFileExtension_pdf(file) {
        var _validFileExtensions = allowedCert.split(",");
        var sFileName = file.name;
        if (sFileName.length > 0) {
            var blnValid = false;
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j];
                if (
                    sFileName
                        .substr(
                            sFileName.length - sCurExtension.length,
                            sCurExtension.length
                        )
                        .toLowerCase() == sCurExtension.toLowerCase()
                ) {
                    blnValid = true;
                    break;
                }
            }

            if (!blnValid) {
                this.setState(
                    {
                        fileExtError_pdf:
                            sFileName +
                            " is not supported; acceptable formats to upload certificate is " +
                            _validFileExtensions.join(", "),
                    },
                    () => {
                        return false;
                    }
                );
            } else {
                return true;
            }
        }
    }
    handleUploadCert(e) {
        // console.log(e.target.files[0]);
        let files = e.target.files;
        const formData = new FormData();
        let fileArr = [];
        this.setState({
            fileExtError_pdf: "",
            fileSizeError_pdf: "",
        });
        for (let i = 0; i < files.length; i++) {
            if (this.validateFileExtension_pdf(files[i])) {
                if (this.validateFileSize_pdf(files[i])) {
                    // fileArr.push(file);
                    formData.append(`pdf[${i}]`, files[i]);
                }
            }
        }
        if (
            this.state.fileExtError_pdf == "" &&
            this.state.fileSizeError_pdf == ""
        ) {
            this.setState({
                inputFileData_pdf: formData,
            });
        }
    }
    checkValidity(value, rules) {
        // console.log("rules: ", rules);

        // console.log("checkValidity", value, rules);
        let isValid = true;
        if (value !== null && value !== undefined) {
            if (rules.required) {
                isValid = value !== "" && value !== null && isValid;
            }

            if (rules.minLength) {
                isValid = value.trim().length >= rules.minLength && isValid;
            }

            if (rules.maxLength) {
                isValid = value.length <= rules.maxLength && isValid;
            }
            if (rules.isPhoneNumber) {
                const phoneRegExp =
                    /^((\\+[1-9]{8,15}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
                isValid = phoneRegExp.test(value) && isValid;
            }

            if (rules.isContactNo) {
                var pattern = /^\d{10}$/;
                isValid = pattern.test(value) && isValid;
            }

            if (rules.range) {
                isValid = value >= rules.range.start;
            }

            if (rules.isNumeric) {
                const pattern = /^\d+$/;
                isValid = pattern.test(value) && isValid;
                // console.log("isnumber", value, typeof value);
            }

            if (rules.isEmail) {
                const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                isValid = pattern.test(value) && isValid;
                // isValid = true
            }

            if (rules.onlyAlphabets) {
                const pattern = /^[A-Za-z/' ]+$/;
                isValid = pattern.test(value) && isValid;
            }

            if (rules.AlphaNumeric) {
                const pattern = /^[a-zA-Z0-9]+$/;
                isValid = pattern.test(value) && isValid;
            }
        } else {
            isValid = false;
        }
        return isValid;
    }

    componentDidMount() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }

        this.setState({
            loading: true,
            todaysDate: yyyy + "-" + mm + "-" + dd,
        });
        let API = new Apilib();
        API.getLocation()
            .then((result) => {
                let res = result.data;

                this.setState({
                    loading: false,
                    lat: res.latitude,
                    long: res.longitude,
                    country_code: res.country_code,
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
                // console.log("geoError", err);
            });

        this.setState({
            loading: true,
        });
        API.getSpecialities()
            .then((result) => {
                let res = result.data;

                let data = res.data;
                let modified = data.map((item) => {
                    return {
                        value: item.speciality,
                        label: item.speciality,
                        id: item.id,
                    };
                });
                modified.push({ value: 0, label: "Other", id: null });
                this.setState({
                    loading: false,
                    allSpecialities: modified,
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
        if (this.props.userData.hospital_name) {
            // console.log("hospital_name", this.props.userData.hospital_name);
        }
    }

    onChange = (e) => {
        // e.preventDefault();
        if (e.target.name === "experience" && e.target.value < 0) {
            return this.setState({
                [e.target.name]: "",
            });
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log("state onchange:", this.state);
    };

    inputChangeHandler = (event, inputName) => {
        const updatedForm = { ...this.state.formData };
        const updatedFormElement = { ...updatedForm[inputName] };

        if (inputName === "mobile") {
            updatedFormElement.value = event;
        } else {
            updatedFormElement.value = event.target.value.trimStart();
        }

        updatedFormElement.value = event.target.value;

        updatedForm[inputName] = updatedFormElement;
        // console.log("updateFormElement>>>>>>>>>", updatedForm);
        updatedForm[inputName].valid = this.checkValidity(
            updatedForm[inputName].value,
            updatedForm[inputName].validation
        );
        let formValid = true;
        for (let key in updatedForm) {
            formValid =
                updatedForm[key].valid &&
                formValid &&
                updatedForm[key].value !== "";
        }
        this.setState({ formIsValid: formValid });

        this.setState({ formData: updatedForm });
        // console.log("formdata:  ", this.state.formData);
    };

    onSubmit = (e) => {
        // console.log("Formdata : " + this.state.formData.email.valid);
        e.preventDefault();
        if (!!this.state.newSpeciality && !this.state.added) {
            Notify({
                alert: true,
                type: "error",
                title: "Please click on Add button to add new speciality",
            });
            return;
        }
        this.setState({
            error: "",
            loading: true,
        });
        let API = new Apilib();

        const all_state = { ...this.state };

        let specialitiesData = all_state.specialitiesObj.map((spec) => {
            if (spec.label === "Other" && this.state.added) {
                return {
                    id: this.state.newSpecialityDetails.id,
                    label: this.state.newSpecialityDetails.speciality,
                    value: this.state.newSpecialityDetails.speciality,
                    isApproved: false,
                };
            }
            return { ...spec, isApproved: true };
        });

        if (this.state.added) {
            all_state.specialities.push(this.state.newSpeciality);
        }

        // console.log("....state??????????", this.state);
        const qualificationsObj = [];

        if (all_state.qualificationsObj) {
            for (let qualification of all_state.qualificationsObj) {
                if (qualification.__isNew__) {
                    delete qualification["__isNew__"];
                }
                qualificationsObj.push(qualification);
            }
            delete all_state["qualificationsObj"];
        }

        const nanoid = customAlphabet(
            "1234567890abcdefghijklmnopqrstuvwxyz",
            10
        );

        let data = {
            ...all_state,
            specialitiesObj: specialitiesData,
            qualificationsObj,
            type: 3,
            uid: this.props.userData.uid,
            hospitalId: this.props.userData.hospitalId,
            hospitalName: this.props.userData.hospital_name,
            hospitalChainId: this.props.userData.hospitalChainId,
            password: nanoid(),
        };
        console.log("Data>>>>>>>>>>>>", data);
        data.email = data.formData.email.value;
        data.geoLocation = this.state.geoLocation;
        data.firstName = data.formData.firstName.value;
        data.lastName = data.formData.lastName.value;
        data.mobile = data.formData.mobile.value;

        delete data.formData;
        delete data.formIsValid;
        delete data.allSpecialities;
        delete data.added;
        delete data.addSpecialityIsValid;
        delete data.addSpecialities;
        delete data.loading;
        delete data.error;
        delete data.success;

        // console.log("API data Doctor::", data);
        API.create(JSON.stringify(data))
            .then((result) => {
                let res = result.data;
                console.log("DoctorAddedORNot", res);
                this.setState({
                    loading: false,
                });

                this.setState({
                    loading: false,
                    firstName: "",
                    lastName: "",
                    speciality: "",
                    experience: "",
                    qualifications: [],
                    qualificationsObj: null,
                    regnumber: "",
                    regexpirydate: "",
                    gender: "",
                    email: "",
                    mobile: "",
                    error: "",
                    success: true,
                });
                Notify({
                    alert: true,
                    type: "success",
                    title: "Doctor Added Successfully",
                });
                this.props.history.push("/hospital/doctors");
            })
            .catch((error) => {
                // Notify({
                //   alert: true,
                //   type: "error",
                //   title: "Failed to add doctor",
                // });
                console.log("error catch 2", this.state.error);
                this.setState({
                    error: error.message,
                    success: false,
                    loading: false,
                });
            });
    };

    onQualificationSelect(value) {
        let arr = [];
        if (value) {
            if (value && value.length) {
                value.map((val, index) => {
                    arr.push(val.value);
                });
            }
            this.setState({
                qualifications: arr,
                qualificationsObj: value,
            });
        }
    }
    onSpecialitySelect(value) {
        // console.log("onSpecialitySelect", value);
        let arr = [];
        if (value && value.length !== 0) {
            value.map((val, index) => {
                if (val.value === 0) {
                    this.setState({
                        addSpecialities: true,
                    });
                    // !!this.state.newSpeciality && arr.push(this.state.newSpeciality);
                } else {
                    arr.push(val.value);
                }
            });
            this.setState({
                specialities: arr,
                specialitiesObj: value,
            });
        } else {
            this.setState({
                addSpecialities: false,
                added: false,
                specialities: [],
                specialitiesObj: null,
                newSpeciality: "",
            });
        }
    }

    addSpecialityHandler(e) {
        e.preventDefault();
        // let speciality = this.state.newSpeciality;
        let data = {
            speciality: this.state.newSpeciality,
            toBeApproved: true,
            hospital_name: this.props.userData.hospital_name,
            admin_email: this.props.userData.email,
            doctor_email: this.state.email,
        };
        this.setState({ loading: true });
        let API = new Apilib();
        API.addNewSpeciality(JSON.stringify(data))
            .then((result) => {
                let res = result.data;

                console.log("addNewSpeciality_res", res);
                this.setState({
                    loading: false,
                    added: true,
                    newSpecialityDetails: res.speciality,
                });
            })
            .catch((err) => {
                console.log("addNewSpeciality_err", err);
                this.setState({ loading: false, added: false });
            });
        // console.log("addSpecialityHandler", data);
    }

    // componentDidUpdate(prevProp, prevState) {
    //   if (
    //     prevState.newSpeciality !== this.state.newSpeciality ||
    //     prevState.email !== this.state.email
    //   ) {
    //     let valid = !!this.state.newSpeciality && !!this.state.email
    //     this.setState({
    //       addSpecialityIsValid: valid,
    //     });
    //   }
    // }

    onSelectStatus = (val) => {
        // let formObj = { ...this.state.currentDoctor };
        // formObj["status"] = val;
        this.setState({
            status: val,
        });
    };

    addLangSpoken = (e) => {
        const langs = this.state.language_spoken;
        langs.push(e);
        this.setState({
            language_spoken: langs,
        });
    };
    removeLangSpoken = (index) => {
        const langs = this.state.language_spoken;
        langs.splice(index, 1);
        this.setState({
            language_spoken: langs,
        });
    };

    addConsultationReason = (text) => {
        const consultations = [...this.state.consultation_reasons, text];
        this.setState({
            consultation_reasons: consultations,
        });
    };

    removeConsultationReason = (index) => {
        const consultations = [...this.state.consultation_reasons];
        consultations.splice(index, 1);
        this.setState({
            consultation_reasons: consultations,
        });
    };

    addAreaOfInterest = (text) => {
        const consultations = [...this.state.area_of_interest, text];
        this.setState({
            area_of_interest: consultations,
        });
    };

    removeAreaOfInterest = (index) => {
        const consultations = [...this.state.area_of_interest];
        consultations.splice(index, 1);
        this.setState({
            area_of_interest: consultations,
        });
    };

    render() {
        // console.log(this.props);
        return (
            <React.Fragment>
                {this.state.loading && <Loader />}
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="page-title">Add Professional</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={(e) => this.onSubmit(e)}>
                            {this.state.error && (
                                <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                    {this.state.error}
                                </div>
                            )}
                            {this.state.success && (
                                <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                    Doctor added successfully
                                </div>
                            )}

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            First Name{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="firstName"
                                            value={
                                                this.state.formData.firstName
                                                    .value
                                            }
                                            error={
                                                !this.state.formData[
                                                    "firstName"
                                                ].valid
                                            }
                                            onChange={(e) =>
                                                this.inputChangeHandler(
                                                    e,
                                                    "firstName"
                                                )
                                            }
                                            required
                                            className="form-control"
                                            type="text"
                                        />
                                        {!this.state.formData["firstName"]
                                            .valid ? (
                                            <p className="error-messege">
                                                <small style={{ color: "red" }}>
                                                    {
                                                        this.state.formData[
                                                            "firstName"
                                                        ].errMsg
                                                    }
                                                </small>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Last Name{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="lastName"
                                            value={
                                                this.state.formData.lastName
                                                    .value
                                            }
                                            error={
                                                !this.state.formData["lastName"]
                                                    .valid
                                            }
                                            onChange={(e) =>
                                                this.inputChangeHandler(
                                                    e,
                                                    "lastName"
                                                )
                                            }
                                            required
                                            className="form-control"
                                            type="text"
                                        />
                                        {!this.state.formData["lastName"]
                                            .valid ? (
                                            <p className="error-messege">
                                                <small style={{ color: "red" }}>
                                                    {
                                                        this.state.formData[
                                                            "lastName"
                                                        ].errMsg
                                                    }
                                                </small>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Email{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="email"
                                            type="email"
                                            value={
                                                this.state.formData.email.value
                                            }
                                            error={
                                                !this.state.formData["email"]
                                                    .valid
                                            }
                                            onChange={(e) =>
                                                this.inputChangeHandler(
                                                    e,
                                                    "email"
                                                )
                                            }
                                            required
                                            className="form-control"
                                        />
                                        {!this.state.formData["email"].valid ? (
                                            <p className="error-messege">
                                                <small style={{ color: "red" }}>
                                                    {
                                                        this.state.formData[
                                                            "email"
                                                        ].errMsg
                                                    }
                                                </small>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Mobile No.
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="mobile"
                                            value={
                                                this.state.formData.mobile.value
                                            }
                                            error={
                                                !this.state.formData["mobile"]
                                                    .valid
                                            }
                                            onChange={(e) =>
                                                this.inputChangeHandler(
                                                    e,
                                                    "mobile"
                                                )
                                            }
                                            required
                                            className="form-control"
                                            type="number"
                                        />
                                        {!this.state.formData["mobile"]
                                            .valid ? (
                                            <p className="error-messege">
                                                <small style={{ color: "red" }}>
                                                    {
                                                        this.state.formData[
                                                            "mobile"
                                                        ].errMsg
                                                    }
                                                </small>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Specialty{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Select
                                            required
                                            isMulti
                                            onChange={(val) =>
                                                this.onSpecialitySelect(val)
                                            }
                                            value={this.state.specialitiesObj}
                                            options={this.state.allSpecialities}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Qualifications</label>
                                        <Select2
                                            isMulti
                                            onChange={(val) =>
                                                this.onQualificationSelect(val)
                                            }
                                            placeholder="Enter Qualifications"
                                            noOptionsMessage={() =>
                                                "Enter qualification"
                                            }
                                        />
                                        <small className="form-text form-muted text-muted">
                                            (Press Enter/Tab after entering
                                            qualification)
                                        </small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Registration Number{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="regnumber"
                                            value={this.state.regnumber}
                                            onChange={(e) => this.onChange(e)}
                                            required
                                            className="form-control"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Registration Expiry Date{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="regexpirydate"
                                            value={this.state.regexpirydate}
                                            onChange={(e) => this.onChange(e)}
                                            required
                                            className="form-control"
                                            type="date"
                                            min={this.state.todaysDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>
                                            Years of Experience{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            name="experience"
                                            min={0}
                                            value={this.state.experience}
                                            onChange={(e) => this.onChange(e)}
                                            required
                                            className="form-control"
                                            type="number"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>
                                            Status{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Select
                                            required
                                            //value={this.state.currentDoctor.status}
                                            onChange={(item) =>
                                                this.onSelectStatus(item)
                                            }
                                            options={status}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Landline No.</label>
                                        <input
                                            name="landline"
                                            value={this.state.landline}
                                            onChange={(e) => this.onChange(e)}
                                            className="form-control"
                                            type="phone"
                                            placeholder="7346593201"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group gender-select">
                                        <label className="gen-label">
                                            Gender{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                            :
                                        </label>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    required
                                                    type="radio"
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    name="gender"
                                                    checked={
                                                        this.state.gender ==
                                                        "male"
                                                    }
                                                    value="male"
                                                    className="form-check-input"
                                                />
                                                Male
                                            </label>
                                        </div>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    required
                                                    type="radio"
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    name="gender"
                                                    checked={
                                                        this.state.gender ==
                                                        "female"
                                                    }
                                                    value="female"
                                                    className="form-check-input"
                                                />
                                                Female
                                            </label>
                                        </div>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    required
                                                    type="radio"
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    name="gender"
                                                    checked={
                                                        this.state.gender ===
                                                        "other"
                                                    }
                                                    value="other"
                                                    className="form-check-input"
                                                />
                                                Other
                                            </label>
                                        </div>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    required
                                                    type="radio"
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    name="gender"
                                                    checked={
                                                        this.state.gender ==
                                                        "not_disclose"
                                                    }
                                                    value="not_disclose"
                                                    className="form-check-input"
                                                />
                                                I wish not to disclose
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Base fee (Per appointment basis)
                                        </label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span
                                                    class="input-group-text"
                                                    id="inputGroupPrepend3"
                                                >
                                                    $
                                                </span>
                                            </div>
                                            <input
                                                name="base_fee"
                                                value={this.state.base_fee}
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                className="form-control"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>City/State/Country</label>
                                        <span className="text-danger">*</span>

                                        <GoogleMap
                                            style={{ width: 100 }}
                                            // setGeoLocation={(e) => {
                                            //   this.setState({
                                            //     geoLocation: { lat: e.lat, lng: e.lng },
                                            //   });
                                            // }}
                                            // geoLocation={this.state.geoLocation}
                                            value={this.state.geoLocation}
                                            onChange={(e) =>
                                                // this.setState({
                                                //   geoLocation: this.state.address,
                                                // })
                                                console.log("aaa")
                                            }
                                            getAddress={(address) => {
                                                this.setState(
                                                    {
                                                        geoLocation: address,
                                                    },
                                                    () => {
                                                        console.log(
                                                            "MApAddress",
                                                            this.state
                                                                .geoLocation
                                                        );
                                                    }
                                                );
                                                console.log(
                                                    "GoogleMapsAddress",
                                                    address
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <ArrayInput
                                        label={"Language Spoken"}
                                        stateArr={this.state.language_spoken}
                                        stateSetter={(e) =>
                                            this.setState({
                                                language_spoken: e,
                                            })
                                        }
                                    />
                                </div>

                                {/* PROFESSIONAL DESCRIPTION */}
                                {/* DISPLAYED ON PLUGIN */}
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Profession Description</label>
                                        <div class="input-group">
                                            <textarea
                                                name="description"
                                                value={this.state?.description}
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* PROFESSIONAL CONSULTATION REASONS */}
                                {/* DISPLAYED ON PLUGIN */}
                                <div className="col-sm-6">
                                    <ArrayInput
                                        label={"Consultation Reasons"}
                                        stateArr={
                                            this.state.consultation_reasons
                                        }
                                        stateSetter={(e) =>
                                            this.setState({
                                                consultation_reasons: e,
                                            })
                                        }
                                    />
                                </div>

                                {/* PROFESSIONAL AREA OF INTERESTS */}
                                {/* DISPLAYED ON PLUGIN */}
                                <div className="col-sm-6">
                                    <ArrayInput
                                        label={"Area Of Interests"}
                                        stateArr={this.state.area_of_interest}
                                        stateSetter={(e) =>
                                            this.setState({
                                              area_of_interest: e,
                                            })
                                        }
                                    />
                                </div>

                                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Choose Professional's Certificate</label>
                    <div className="upload-input">
                      <input
                        type="file"
                        id="certInput"
                        onChange={(e) => this.handleUploadCert(e)}
                        accept={allowedCert}
                        className="form-control"
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "#d4edda",
                        width: "320px",
                        borderColor: "#c3e6cb",
                      }}
                    >
                      <span>Supported format is .pdf</span>
                    </div>
                    {this.state.fileExtError_pdf && (
                      <div
                        className="alert alert-danger mt-2 fade show"
                        role="alert"
                      >
                        {this.state.fileExtError_pdf}
                      </div>
                    )}
                    {this.state.fileSizeError_pdf && (
                      <div
                        className="alert alert-danger mt-2 fade show"
                        role="alert"
                      >
                        {this.state.fileSizeError_pdf}
                      </div>
                    )}
                  </div>
                </div> */}
                            </div>

                            <div className="m-t-20 text-center">
                                <button className="btn btn-primary submit-btn">
                                    Add Professional
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
