import React, { Component } from "react";
import RequiredSelect from "../../Helpers/requiredSelect";
import BaseSelect from "react-select/creatable";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Apilib from "../../api";
import { storage } from "../../firebase";
import $, { timers } from "jquery";
import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import csc from "country-state-city";
import * as Datetime from "react-datetime";
import Notify from "../../Notify";
import GoogleMap from "../Agora/utils/GoogleMap";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

let allowedFiles = ".pdf,.doc,.docx,.jpg,.png,.jpeg";

export default class EditPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      geoLocation: {},
      countries: [],
      states: [],
      cities: [],
      patients: [],
      currentPatient: {},
      country: {
        country_id: "",
      },
      city: {
        value: "",
        validation: {
          minLength: 3,
          required: true,
          onlyAlphabets: true,
        },
        errMsg: "Invalid City Name!",
        valid: true,
      },
      State: {
        state_id: "",
      },
      currentIndex: "",
      modalOpen: false,
    };
    console.log("props editpatient", props);
  }

  

  componentDidMount() {
    let API = new Apilib();
    API.getUsers(5, this.props.userData.hospitalId)
           .then((result) => {
        let res = result.data

        let patientList = [];
        patientList = res.patients;
        this.setState({
          patientList: patientList,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("geoError", err);
      });

    if (this.props.location.state === undefined || null) {
      this.props.history.push("/doctor/patients");
    } else {
      let data = csc.getAllCountries();
      this.setState(
        {
          currentPatient: { ...this.props.location.state.patient },
          countries: data,
          geoLocation: this.props.location.state.patient.geoLocation,
        },
        () => {
          for (let key of this.state.countries) {
            if (
              this.state.currentPatient.country &&
              key.name === this.state.currentPatient.country
            ) {
              this.setState({
                country: {
                  country_id: key.id,
                },
              });
              let stateData = csc.getStatesOfCountry(key.id);
              this.setState({ states: stateData }, () => {
                for (let key of this.state.states) {
                  if (
                    this.state.currentPatient.State &&
                    key.name === this.state.currentPatient.State
                  ) {
                    this.setState({
                      State: {
                        state_id: key.id,
                      },
                    });
                    // let cityData = csc.getCitiesOfState(key.id);
                    // this.setState({ cities: cityData }, () => {
                    //   for (let key of this.state.cities) {
                    //     if (
                    //       this.state.currentPatient.city &&
                    //       key.name === this.state.currentPatient.city
                    //     ) {
                    //       this.setState({
                    //         city: {
                    //           city_id: key.id,
                    //         },
                    //       });
                    //     }
                    //   }
                    // });
                  }
                }
              });
            }
          }
        }
      );
      
    }
  }

  phoneChecking(phoneno) {
    // this.setState({
    //   phoneCheckingError : false
    // })
    let patientPhoneCheck = this.state.patientList;
    for (var i = 0; i < patientPhoneCheck.length; i++) {
      if (
        patientPhoneCheck[i].phoneno == phoneno &&
        patientPhoneCheck[i].uid !== this.state.currentPatient.uid
      ) {
        console.log("HIII BYEE");
        return false;
      }
    }
    return true;
  }

  updateData = (e) => {
    e.preventDefault();

    let data = { ...this.state.currentPatient, geoLocation: this.state.geoLocation };
    console.log("Addd",data)
    let phCheck = this.phoneChecking(data.phoneno);
    if (!phCheck) {
      this.setState({
        loading: false,
      });
      Notify({
        type: "error",
        alert: true,
        title: "Phone no is already used",
      });
      return;
    }
    console.log("send data::", data);
    let API = new Apilib();
    this.setState({
      loading: true,
    });

    API.updateUser(JSON.stringify(data))
      .then((result) => {
        let res = result.data
        this.setState({
          loading: false,
        });
        // if (res.ok) {
          this.setState({
            success: true,
          });
          Notify({
            alert: true,
            type: "success",
            title: "Patient edited successfully",
          });

          this.props.history.goBack();
        // } else {
        //   res.text().then((data) => {
        //     this.setState({
        //       error: JSON.parse(data).message,
        //     });
        //     Notify({
        //       alert: true,
        //       type: "error",
        //       title: "Error occured",
        //     });
        //   });
        // }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  onChange = (e) => {

    let inputName = e.target.name;
    let formObj = { ...this.state.currentPatient };
    console.log("formObj", formObj);
    let value = "";
    switch (inputName) {
      case "country":
        console.log("country", this.state.countries);
        for (let key of this.state.countries) {
          if (key.id === e.target.value) {
            value = key.name;
            this.setState({
              country: {
                country_id: e.target.value,
              },
            });
          }
        }
        let stateData = csc.getStatesOfCountry(e.target.value);
        console.log("stateData", stateData, e.target.value);
        this.setState({ states: stateData });
        this.setState({
          // city: {
          //   city_id: "",
          // },
          State: {
            state_id: "",
          },
        });
        break;

      case "State":
        for (let key of this.state.states) {
          if (key.id === e.target.value) {
            value = key.name;
            this.setState({
              State: {
                state_id: e.target.value,
              },
            });
          }
        }
        // let cityData = csc.getCitiesOfState(e.target.value);
        // this.setState({ cities: cityData });
        // this.setState({
        //   city: {
        //     city_id: "",
        //   },
        // });
        break;

        // case "city":
        //   for (let key of this.state.cities) {
        //     if (key.id === e.target.value) {
        //       value = key.name;
        //       this.setState({
        //         city: {
        //           city_id: e.target.value,
        //         },
        //       });
        //     }
        //   }
        break;

      // no default
    }
    if (
      inputName === "country" ||
      inputName === "State"
      // inputName === "city"
    ) {
      formObj[inputName] = value;
    } else {
      formObj[inputName] = e.target.value;
    }
    this.setState({
      currentPatient: formObj,
    });
  };

  onSelect = (val) => {
    let formObj = { ...this.state.currentPatient };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val);
    let arr = [];
    if (val && val.length) {
      val.map((value, index) => {
        arr.push(value.value);
      });
    }
    formObj["allergies"] = arr;
    this.setState({
      currentPatient: formObj,
      allergies: val,
    });
    // console.log("formdata", formObj, val);
  };

  render() {
    console.log("Geolocation",this.state)
    let allBloodGroups = [
      {
        id: "1",
        name: "A+",
      },
      {
        id: "2",
        name: "A-",
      },
      {
        id: "3",
        name: "B+",
      },
      {
        id: "4",
        name: " B-",
      },
      {
        id: "5",
        name: "AB+",
      },
      {
        id: "6",
        name: "AB-",
      },
      {
        id: "7",
        name: "O+",
      },
      {
        id: "8",
        name: "O-",
      },
    ];
    let arr = [
      "Brazil nuts",
      "Almonds",
      "Cashews",
      "Macadamia nuts",
      "Pistachios",
      "Pine nuts",
      "Walnuts",

      "Cow’s Milk Allergies",

      "Milk",
      "Milk powder",
      "Cheese",
      "Butter",
      "Margarine",
      "Yogurt",
      "Cream",
      "Ice cream",

      "Shellfish Allergies",

      "Shrimp",
      "Prawns",
      "Crayfish",
      "Lobster",
      "Squid",
      "Scallops",

      "Peanuts Allergies",

      "Eggs Allergies",

      "Fish Allergies",

      "Soy Allergies",

      "Dog Allergies",

      "Pollen Allergies",

      "Pet Hair Allergies",

      "Dust Mites Allergies",

      "Insect bites Allergies",

      "Wheat Allergies",

      "Antibiotic Allergies",
    ];
    let allegies = arr.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    let bloodGroup = null;
    if (allBloodGroups.length !== 0) {
      bloodGroup = allBloodGroups.map((item) => {
        return (
          <MenuItem key={item.id} value={item.name}>
            {item.name}
          </MenuItem>
        );
      });
    }

    let countriesMenu = null;
    if (this.state.countries && this.state.countries.length !== 0) {
      countriesMenu = this.state.countries.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }

    let statesMenu = null;
    if (this.state.states && this.state.states.length !== 0) {
      statesMenu = this.state.states.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }

    let citiesMenu = null;
    if (this.state.cities && this.state.cities.length !== 0) {
      citiesMenu = this.state.cities.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }

    let allergies = [];
    console.log(this.state.currentPatient);
    if (this.state.currentPatient) {
      if (
        this.state.currentPatient.allergies &&
        this.state.currentPatient.allergies.length
      ) {
        this.state.currentPatient.allergies.map((allergy, i) => {
          allergies.push({
            value: allergy,
            label: allergy,
          });
        });
      }
    }
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 com-3">
            <h4 className="page-title">Edit Patient</h4>
          </div>
          <div className="col-sm-8 col-9 text-right m-b-20">
            {/* <Link
              to={{
                pathname: `/AppointmentsList`,
                state: {
                  user: this.state.currentPatient,
                },
              }}
              // + this.state.currentPatient.uid
              className="btn btn-primary float-right btn-rounded"
            >
              <i className="fa fa-list"></i> View Appointments
            </Link> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={(e) => this.updateData(e)}>
              {/* {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Patient Updated Successfully
                </div>
              )} */}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      pattern="^[A-Za-z/']{3,}"
                      type="text"
                      name="firstName"
                      value={this.state.currentPatient.firstName || ""}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Please enter valid first name"
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      pattern="^[A-Za-z/' ]{3,}"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Please enter valid last name"
                          );
                        }
                      }}
                      name="lastName"
                      value={this.state.currentPatient.lastName || ""}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Phone No. <span className="text-danger">*</span>
                    </label>
                    <input
                      pattern="^[0-9]{8,15}$"
                      name="phoneno"
                      value={this.state.currentPatient.phoneno || ""}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Phone no should be greater or equal to 8 digits"
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      value={this.state.currentPatient.email || ""}
                      readOnly
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Name</label>
                    <input
                      pattern="^[A-Za-z/']{3,}$"
                      name="emergencyContactName"
                      value={
                        this.state.currentPatient.emergencyContactName || ""
                      }
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity("Name is invalid");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Number</label>
                    <input
                      pattern="^[0-9]{8,15}$"
                      name="emergencyContactNumber"
                      value={
                        this.state.currentPatient.emergencyContactNumber || ""
                      }
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Emergency Contact should be greater or equal to 8 digits"
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <Select2
                      className="form-control"
                      fullWidth
                      name="bloodgroup"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.currentPatient.bloodgroup || ""}
                    >
                      {bloodGroup}
                    </Select2>
                    {/* <input
                      name="bloodgroup"
                      value={this.state.currentPatient.bloodgroup || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    /> */}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start ">
                      <div>
                        <label>Height</label>
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#d4edda",
                          width: "60px",
                          height: "25px",
                          borderColor: "#c3e6cb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>In CM</span>
                      </div>
                    </div>

                    <input
                      name="height"
                      min={0}
                      value={this.state.currentPatient.height || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="number"
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start ">
                      <div>
                        <label>Weight</label>
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#d4edda",
                          width: "60px",
                          height: "25px",
                          borderColor: "#c3e6cb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>In KG</span>
                      </div>
                    </div>
                    <input
                      name="weight"
                      min={0}
                      value={this.state.currentPatient.weight || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="number"
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group gender-select">
                    <label className="gen-label">Gender:</label>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.currentPatient.gender == "male"}
                          value="male"
                          className="form-check-input"
                        />
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.currentPatient.gender == "female"}
                          value="female"
                          className="form-check-input"
                        />
                        Female
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.currentPatient.gender == "other"}
                          value="other"
                          className="form-check-input"
                        />
                        Other
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={
                            this.state.currentPatient.gender ==
                            "I wish not to disclose "
                          }
                          value="I wish not to disclose "
                          className="form-check-input"
                        />
                        I wish not to Disclose
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text-area"
                      name="address"
                      value={this.state.currentPatient.address || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Allergies</label>
                    <Select
                      isMulti
                      placeholder="Enter Allergies"
                      value={this.state.allergies || allergies}
                      options={allegies}
                      onChange={(item) => this.onSelect(item)}
                      noOptionsMessage={() => "Enter allergy and press enter"}
                    />
                    <small className="form-text form-muted text-muted">
                      (Press Enter/Tab after entering allergies)
                    </small>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                     City/State/Country <span className="text-danger">*</span>
                    </label>
                    {/* <Select2
                      required
                      className="form-control"
                      fullWidth
                      name="country"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.country.country_id || ""}
                    >
                      {countriesMenu}
                    </Select2> */}
                      <GoogleMap
                      style={{ width: 100 }}
                      // setGeoLocation={(e) => {
                      //   this.setState({
                      //     geoLocation: { lat: e.lat, lng: e.lng },
                      //   });
                      // }}
                      // geoLocation={this.state.geoLocation}
                      value = { this.state.geoLocation}
                      onChange={(e) =>  
                        // this.setState({
                        //   geoLocation: this.state.address,
                        // })
                        console.log("aaa")
                      }
                     getAddress={(address) => {
                      this.setState({
                        geoLocation: address,
                      },()=>{
                        console.log("MApAddress",this.state.geoLocation)
                      })
                       console.log("GoogleMapsAddress",address)
                     }}
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      State/Province <span className="text-danger">*</span>
                    </label>
                    <Select2
                      required
                      className="form-control"
                      fullWidth
                      name="State"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.State.state_id || ""}
                    >
                      <MenuItem disabled={true} value="">
                        Please Select Country
                      </MenuItem>
                      {statesMenu}
                    </Select2>
                  </div>
                </div> */}
                {/* <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      pattern="^[A-Za-z/' ]{3,}"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Please enter valid last name"
                          );
                        }
                      }}
                      name="city"
                      value={this.state.currentPatient.city || ""}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div> */}
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Postal Code <span className="text-danger">*</span>
                    </label>
                    <input
                      pattern="^[0-9.]+$"
                      onInvalid={function (e) {
                        e.target.setCustomValidity("");
                        if (!e.target.validity.valid) {
                          e.target.setCustomValidity(
                            "Please enter valid postal code"
                          );
                        }
                      }}
                      required
                      type="pincode"
                      name="postalcode"
                      value={this.state.currentPatient.postalcode || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>BMR</label>
                    <input
                      name="bmr"
                      value={this.state.currentPatient.bmr || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />
                  </div> */}
                </div>

                {/* <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>Postal Code <span className="text-danger">*</span></label>
                    <input
                      required
                      type="pincode"
                      name="postalcode"
                      value={this.state.currentPatient.postalcode || ""}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div> */}
              </div>
              <div className="m-t-20 text-center">
                <button className="btn btn-secondary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
