import React, { useEffect, useState } from 'react';
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import BaseSelect from "react-select";
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 5px;
  margin-left:5px;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 95%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Div1 = styled.div`
  width: 35%;
`;

const Div2 = styled.div`
  width: 65%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content:flex-start;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: #50d8fa;
`;

function GenerateAuthority_script_number(n) {
    return (n - 1) % 9 + 1;
}

const reusableInputState = (label, valueVar, onChaneVar) => {
    return <Containers>
        <Div1>
            <Label>{label}</Label>
        </Div1>
        <Div2>
            <Input type="text" value={valueVar} onChange={onChaneVar} />
        </Div2>
    </Containers>
}

const reusableDivState = (label) => {
    return <Div1>
        <Label>{label}</Label>
    </Div1>
}



const NewPrescription = (route) => {
    console.log(route)
    console.log('route.userData.hospitalIdroute.userData.hospitalId', route.userData.hospitalId)

    const [patientList, setPatientList] = useState(null)

    useEffect(() => {
        let API = new Apilib();
        API.getUsers(5, route.userData.hospitalId)
            .then((result) => {
                let res = result.data
                console.log(res);

                let sorted = res.patients;
                sorted.sort((a, b) => {
                    return b.created_at - a.created_at;
                })
                setPatientList(sorted)


            })
            .catch((error) => {
                // this.setState({
                //     loading: false,
                // });
            });
    }, [])


    const [selectedPatient, setSelectedPatient] = useState(null);
    const [formData, setFormData] = useState({ firstName: '' });
    const [fullName, setFullName] = useState('');
    const [includeDr, setIncludeDr] = useState(false);
    const [is18, setis18] = useState(false);
    const [loading, setloading] = useState(false);
    const [abbreviatedQualifications, setAbbreviatedQualifications] = useState('');
    const [practiceName, setPracticeName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [Dates, setDates] = useState(moment().format('DD MMM, YYYY'));
    const [prescriberNumber, setPrescriberNumber] = useState('');
    const [Patient_Medicare_No, setPatient_Medicare_No] = useState('');
    // TODO: fetch Authority_script_number from database and provide to this
    const [Authority_script_number, setAuthority_script_number] = useState('1178451');



    useEffect(() => {
        let theNum = GenerateAuthority_script_number(Authority_script_number);
        setAuthority_script_number('' + Authority_script_number + theNum)

    }, []);


    const handleObjectChange = (event) => {
        const objectId = event.target.value
        console.log(objectId)
        const selected = patientList.find((obj) => obj.uid === objectId);
        console.log(selected)
        setSelectedPatient(selected);
        setFullName(selected.firstName + ' ' + selected.lastName)
        // address, Patient_Medicare_No
        setPatient_Medicare_No(selected.Patient_Medicare_No)
        setStreetAddress(selected.address)
        setFormData({
            firstName: selected.firstName,
            age: selected.age,
            email: selected.email,
        });
    };


    const handleInputChange = (index, event, field) => {
        if (field == 'PBS' || field == 'includeDrAuth' || field == 'medAuth') {
            const values = [...inputBoxes];
            values[index][field] = !values[index][field];
            setInputBoxes(values);
            return
        }
        console.log(inputBoxes)
        const values = [...inputBoxes];
        values[index][field] = event.target.value;
        setInputBoxes(values);
    };

    const handleInputChangeCheck = (index, event, field) => {
        const values = [...inputBoxes];
        values[index][field] = !values[index][field];
        setInputBoxes(values);
    };
    const handleAge = (event) => {
        console.log(is18)
        setis18(!is18);
    };
    const handlePatient_Medicare_No = (event) => {
        setPatient_Medicare_No(event.target.value);
    };

    const handleAddBox = () => {
        const values = [...inputBoxes];
        values.push({
            "drugName": "",
            "Dosage_directions": "",
            "Quantity": "",
            "Repeats": "",
            "Authority_code": "",
            "Clinical_justification": "",
            "PBS": false,
            "includeDrAuth": false,
            "medAuth": false,
            'morning': false,
            'afternoon': false,
            'evening': false,
            'night': false,
            'Additional_Instructions': '',
            'AfterFood': false,
            'duration': 1
        });
        setInputBoxes(values);
    };

    const handleRemoveBox = (index) => {
        const values = [...inputBoxes];
        values.splice(index, 1);
        setInputBoxes(values);
    };


    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
    };

    const handleIncludeDrChange = (e) => {
        setIncludeDr(e.target.checked);
    };

    const handleAbbreviatedQualificationsChange = (e) => {
        setAbbreviatedQualifications(e.target.value);
    };

    const handlePracticeNameChange = (e) => {
        setPracticeName(e.target.value);
    };

    const handleStreetAddressChange = (e) => {
        setStreetAddress(e.target.value);
    };

    const handlePrescriberNumberChange = (e) => {
        setPrescriberNumber(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to handle form submission
        // ...
    };

    const [inputBoxes, setInputBoxes] = useState([{
        "drugName": "",
        "Dosage_directions": "",
        "Quantity": "",
        "Repeats": "",
        "Authority_code": "",
        "Clinical_justification": "",
        "PBS": false,
        "includeDrAuth": false,
        "medAuth": false,
        'morning': false,
        'afternoon': false,
        'evening': false,
        'night': false,
        'AfterFood': false,
        'Additional_Instructions': '',
        'duration': 1
    }]);




    return (
        <div>

            {patientList && <div style={{ justifyContent: 'center', display: 'grid' }}>


                <h3>Create new prescription</h3><br />

                <select style={{ padding: '5px', margin: '5px' }} value={selectedPatient?.uid} onChange={handleObjectChange} required>
                    <option value="">Select a patient to create  prescription</option>
                    {patientList.map((obj) => (
                        <option key={obj.uid} value={obj.uid}>
                            {obj.firstName}
                        </option>
                    ))}
                </select>

            </div>}

            <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
                <Content>


                    <Form style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '100%', backgroundColor: '#fff' }} >
                        <Heading>New Prescription</Heading>
                        <SubNote>Complete all sections required for your prescription</SubNote>
                        <SubNote style={{ textAlign: 'right' }}>Script number: {' ' + Authority_script_number}</SubNote>

                        <div style={{ border: 'solid #000 .05px' }}></div>
                        <div>
                            <Heading>Patient Details</Heading>

                            {reusableInputState('Full Name:', fullName, handleFullNameChange)}
                            {reusableInputState('Street Address:', streetAddress, handleStreetAddressChange)}

                            {reusableInputState('Medicare Number:', Patient_Medicare_No, handlePatient_Medicare_No)}
                            {reusableInputState('IRN:', practiceName, handlePracticeNameChange)}




                            <Heading>Medication Details</Heading>

                            <div>
                                {inputBoxes.map((inputState, index) => (
                                    <div key={index} style={{ backgroundColor: '#e6fdf9', padding: '10px', margin: '5px', borderRadius: '11px' }}>

                                        <Containers>
                                            {reusableDivState('Drug Name')}
                                            <Div2>
                                                <Input type="text" value={inputState.drugName} onChange={(event) => handleInputChange(index, event, 'drugName')} />
                                            </Div2>
                                        </Containers>

                                        <Containers>
                                            {reusableDivState('Dosage directions')}
                                            <Div2>
                                                <Input type="text" value={inputState.Dosage_directions} onChange={(event) => handleInputChange(index, event, 'Dosage_directions')} />
                                            </Div2>
                                        </Containers>


                                        <Containers>
                                            {reusableDivState('Repeats')}
                                            <Div2>
                                                <Input type="text" value={inputState.Repeats} onChange={(event) => handleInputChange(index, event, 'Repeats')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Duration (in days)')}
                                            <Div2>
                                                <Input type="number" value={inputState.duration} onChange={(event) => handleInputChange(index, event, 'duration')} />
                                            </Div2>
                                        </Containers>

                                        <div style={{ borderWidth: '3px', borderColor: '#000' }}></div>





                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'center'
                                            }}
                                        >
                                            INTERVAL
                                            <CheckboxLabel>

                                                <Checkbox type="checkbox" checked={inputState.morning}
                                                    onChange={(event) => handleInputChangeCheck(index, event, 'morning')}

                                                />MORNING
                                            </CheckboxLabel>
                                            <CheckboxLabel>

                                                <Checkbox type="checkbox" checked={inputState.afternoon}
                                                    onChange={(event) => handleInputChangeCheck(index, event, 'afternoon')}
                                                />AFTERNOON
                                            </CheckboxLabel>


                                            <CheckboxLabel>

                                                <Checkbox type="checkbox" checked={inputState.evening}
                                                    onChange={(event) => handleInputChangeCheck(index, event, 'evening')}
                                                />EVENING
                                            </CheckboxLabel>
                                            <CheckboxLabel>

                                                <Checkbox type="checkbox" checked={inputState.night}
                                                    onChange={(event) => handleInputChangeCheck(index, event, 'night')}
                                                />NIGHT
                                            </CheckboxLabel>
                                            <CheckboxLabel>

                                                <Checkbox type="checkbox" checked={inputState.AfterFood}
                                                    onChange={(event) => handleInputChangeCheck(index, event, 'AfterFood')}
                                                />After Food
                                            </CheckboxLabel>

                                        </div>






                                        <Containers>
                                            {reusableDivState('Authority code')}
                                            <Div2>
                                                <Input type="text" value={inputState.Authority_code} onChange={(event) => handleInputChange(index, event, 'Authority_code')} />
                                            </Div2>
                                        </Containers>

                                        <Containers>
                                            {reusableDivState('Clinical justification')}
                                            <Div2>
                                                <Input type="text" value={inputState.Clinical_justification} onChange={(event) => handleInputChange(index, event, 'Clinical_justification')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Additional Instructions')}
                                            <Div2>
                                                <Input type="text" value={inputState.Additional_Instructions} onChange={(event) => handleInputChange(index, event, 'Additional_Instructions')} />
                                            </Div2>
                                        </Containers>


                                        <CheckboxLabel>
                                            <Checkbox type="checkbox" checked={inputState.PBS} onChange={(event) => handleInputChange(index, event, 'PBS')} />
                                            PBS Prescription
                                            <SubNote>Select a medication from the dropdown list for PBS information</SubNote>
                                        </CheckboxLabel>


                                        <CheckboxLabel>
                                            <Checkbox type="checkbox" checked={inputState.includeDrAuth} onChange={(event) => handleInputChange(index, event, 'includeDrAuth')} />
                                            Authority required
                                            <SubNote> Select a medication from the dropdown list for authority information</SubNote>
                                        </CheckboxLabel>




                                        <CheckboxLabel>
                                            <Checkbox type="checkbox" checked={inputState.medAuth} onChange={(event) => handleInputChange(index, event, 'medAuth')} />
                                            Patient has received authority for this medicine Before
                                        </CheckboxLabel>
                                        <br />

                                        <CheckboxLabel style={{ justifyContent: 'space-between', display: 'flex' }}>
                                            <SubNote></SubNote>
                                            <Button style={{ backgroundColor: 'red', justifyContent: 'flex-end' }} onClick={() => handleRemoveBox(index)}>Delete Medicine</Button>
                                        </CheckboxLabel>

                                    </div>
                                ))}
                                <Button onClick={handleAddBox}>Add new medicine</Button>
                            </div>



                            < CheckboxLabel style={{ marginTop: '10px' }}>

                                <Checkbox type="checkbox" checked={is18} onChange={handleAge} />
                                Patient's age if under 18
                            </CheckboxLabel>
                            <CheckboxLabel style={{ marginTop: '10px' }}>

                                {reusableDivState('Date')}
                                {reusableDivState(Dates)}
                            </CheckboxLabel>

                        </div>

                        <ButtonGroup>
                            <Button
                                style={{ fontWeight: '400', fontSize: '16px' }}
                                onClick={async () => {
                                    console.log('this is it')
                                    console.log(selectedPatient)
                                    if (selectedPatient == null) {
                                        alert('Please select a patient to add a prescription')
                                        return
                                    }
                                    setloading(true)
                                    let presDet = []
                                    let presDetFinal = []
                                    let additionalDetails = []

                                    try {
                                        let API = new Apilib()
                                        let presc = [], dosage = [], timetotakemedicine = [], Notes = [], presNote = [];
                                        let i = 0;
                                        let durationArray = []
                                        let beforeorafterarray = []


                                        inputBoxes.map(drugs => {
                                            presc.push(drugs.drugName)
                                            dosage.push(drugs.Dosage_directions)
                                            presNote.push(drugs.Additional_Instructions)
                                            let temp = { morning: drugs.morning, afternoon: drugs.afternoon, evening: drugs.evening, night: drugs.night }
                                            timetotakemedicine.push(temp)
                                            beforeorafterarray.push(drugs.AfterFood)
                                            durationArray.push(drugs.duration)

                                            additionalDetails.push({
                                                Quantity: drugs.Quantity,
                                                Repeats: drugs.Repeats,
                                                Authority_code: drugs.Authority_code,
                                                Clinical_justification: drugs.Clinical_justification,
                                                PBS: drugs.PBS,
                                                includeDrAuth: drugs.includeDrAuth,
                                                medAuth: drugs.medAuth,
                                                duration: drugs.duration,
                                            })
                                        })



                                        for (let l = 0; l < presc.length; l++) {
                                            let timingDay = {
                                                MN: !!timetotakemedicine[l].morning,
                                                AF: !!timetotakemedicine[l].afternoon,
                                                EV: !!timetotakemedicine[l].evening,
                                                NT: !!timetotakemedicine[l].night,
                                            }
                                            let ismorning = timetotakemedicine[l].morning, isafternoon = timetotakemedicine[l].afternoon, isevening = timetotakemedicine[l].evening, isnight = timetotakemedicine[l].night;



                                            if (ismorning) { timingDay.MN = !!timetotakemedicine[l].morning }
                                            if (isafternoon) { timingDay.AF = !!timetotakemedicine[l].afternoon }
                                            if (isevening) { timingDay.EV = !!timetotakemedicine[l].evening }
                                            if (isnight) { timingDay.NT = !!timetotakemedicine[l].night }

                                            let numberdays = (Number(timingDay.MN) + Number(timingDay.AF) + Number(timingDay.EV) + Number(timingDay.NT)) * (durationArray[l] || 1)

                                            let ambm = beforeorafterarray[l] ? 'Before' : 'After'



                                            let saveData = {
                                                name: presc[l],//this is medicine name
                                                intake_time: timingDay,
                                                am_bm_stat: { "AF": ambm, "MN": ambm, "EV": ambm, "NT": ambm },
                                                num_days: numberdays,
                                                qty: dosage[l],
                                                subtitle: presNote[l],
                                                additionalDetails: additionalDetails[l]
                                            }
                                            console.log('saveData')
                                            console.log(saveData)
                                            presDet.push(saveData)
                                            console.log(presDet)
                                            console.log('presDet')

                                        }

                                        presDetFinal = [
                                            {
                                                medicines: presDet
                                            }
                                        ]
                                        let data = {
                                            prescription: presc, patientNote: Notes, dosage: dosage, prescriptionNote: presNote, timeToTakeMedicine: timetotakemedicine,
                                            prescriptions: presDetFinal,
                                            additionalDetails: additionalDetails,
                                            doctorData: route.userData,
                                            patientData: selectedPatient
                                        }


                                        await API.saveNewPrescriptionData(JSON.stringify(data))
                                        // console.log(JSON.stringify(data), 'this is data sent');
                                        // let tokenn = await API.saveAppointmentData(data)
                                        alert('Prescription saved successfully')

                                        console.log(JSON.stringify(data), 'this is data sent');

                                        history.push(`/doctor/patients`);


                                    } catch (error) {
                                        console.log(error, 'here is s the error');
                                    }
                                    setloading(false)
                                }}
                            >
                                Generate Prescription</Button>
                            <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }}
                                onClick={() => history.push(`/doctor/patients`)}
                            >
                                Cancel</Button>
                        </ButtonGroup>
                    </Form>
                </Content></div>

        </div >
    );
}

export default NewPrescription;
