import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { customAlphabet } from "nanoid";
import Notify from "../../Notify";
import { storage } from "../../firebase";
import { useHistory, Redirect, useLocation } from 'react-router-dom'

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);


function PaymentOrder(props) {
  const history = useHistory();

  const [show, setshow] = useState(false)

  const [ip, setIP] = useState('')
  const [creditCardNumber, setcreditCardNumber] = useState('')
  const [Cardholdername, setCardholdername] = useState('')
  const [expiryMonth, setExpiryMonth] = useState('')
  const [expiryYear, setExpiryYear] = useState('')
  // const [amount, setAmount] = useState('')
  const [CVC, setCVC] = useState('')
  const [Indicator, setIndicator] = useState(false)
  const [ifs, setifs] = useState('')
  const [cardType, setcardType] = useState('')
  const [cardExpirationDate, setcardExpirationDate] = useState('')

  const [addType, setaddType] = useState(0);

  const [cartItems, setcartItems] = useState(null)
  const [cartTotal, setcartTotal] = useState(null)
  const [AddressLine, setAddressLine] = useState('')
  const [Landmark, setLandmark] = useState('')
  const [Street, setStreet] = useState('')
  const [State, setState] = useState('')
  const [prefModalOpen, setprefModalOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [name, setname] = useState('');
  const [phone, setphone] = useState('');
  const [email, setemail] = useState('');
  const [postCode, setpostCode] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [COD, setCOD] = useState(false);
  const [isPrescriptionRequired, setisPrescriptionRequired] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [orderData, setorderData] = useState(null);



  useEffect(() => {
    async function pp() {
      let nk = await JSON.parse(localStorage.getItem('orderData'));
      setorderData(nk)
      console.log(nk)
    }
    pp()
    if (addType == 0) {
      setname(props.userData?.firstName)
      setAddressLine(props.userData?.address)
      setState(props.userData?.geoLocation)
      setpostCode('000000')
      setemail(props.userData?.email)
      setphone(props.userData?.phoneno)
    }

    if (addType == 1) if (props?.userData?.newaddress?.home) {
      setname(props?.userData?.newaddress?.home.name)
      setAddressLine(props?.userData?.newaddress?.home.street)
      setState(props?.userData?.newaddress?.home.citystatecountry)
      setpostCode(props?.userData?.newaddress?.home.postCode)
      setemail(props?.userData?.newaddress?.home.email)
      setphone(props?.userData?.newaddress?.home.phone)
      console.log('hime')
    } else {
      setname('')
      setAddressLine('')
      setState('')
      setpostCode('')
      setemail('')
      setphone('')
    }
    if (addType == 2) if (props?.userData?.newaddress?.office) {
      setAddressLine(props?.userData?.newaddress?.office.street)
      setname(props?.userData?.newaddress?.office.name)
      setState(props?.userData?.newaddress?.office.citystatecountry)
      setpostCode(props?.userData?.newaddress?.office.postCode)
      setemail(props?.userData?.newaddress?.office.email)
      setphone(props?.userData?.newaddress?.office.phone)
      console.log('offcie')
    } else {
      setname('')
      setAddressLine('')
      setState('')
      setpostCode('')
      setemail('')
      setphone('')
    }
    if (addType == 3) if (props?.userData?.newaddress?.other) {
      setAddressLine(props?.userData?.newaddress?.other.street)
      setname(props?.userData?.newaddress?.other.name)
      setState(props?.userData?.newaddress?.other.citystatecountry)
      setpostCode(props?.userData?.newaddress?.other.postCode)
      setemail(props?.userData?.newaddress?.other.email)
      setphone(props?.userData?.newaddress?.other.phone)
      console.log('other')
    } else {
      setname('')
      setAddressLine('')
      setState('')
      setpostCode('')
      setemail('')
      setphone('')
    }
  }, [addType])


  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('cartData'));

    if (items) {
      setcartItems(items);
      console.log(items, 'this i slocal items');
      items.map((x) => {
        if (x.isPrescription == 'yes') setisPrescriptionRequired(true)
      })
    }
    let result = items.reduce(function (acc, obj) { return acc + Number(obj.Price) * Number(obj.qty); }, 0);
    setcartTotal(Number(result).toFixed(2))


  }, []);



  const checkSubstring = (length, match) => {
    return creditCardNumber.substring(0, length) === match;
  };

  useEffect(() => {
    let splitted = cardExpirationDate.split('-')
    var year = splitted[0].slice(-2);
    setExpiryMonth(splitted[1])
    setExpiryYear(year)
  }, [cardExpirationDate]);

  const getIP = async () => {
    await fetch('https://geolocation-db.com/json/').then((response) => response.json())
      .then((data) => {
        setIP(data.IPv4)
      }).catch(e => {
        getIP()
      })
  }

  useEffect(() => {
    getIP()
  }, []);

  let paymentId = 'uiuoioi'

  function createMarkup(str) {
    return { __html: str };
  }


  useEffect(() => {
    const cardNumber = creditCardNumber
    if (cardNumber[0] === "4") {
      setcardType("Visa");
    } else if (checkSubstring(4, "6011")) {
      setcardType("Discover");
    } else if (checkSubstring(2, "51")) {
      setcardType("MasterCard");
    } else if (checkSubstring(2, "34")) {
      setcardType("American Express");
    } else if (checkSubstring(3, "300")) {
      setcardType("Diners Club");
    } else if (checkSubstring(2, "35")) {
      setcardType("JCB");
    } else {
      setcardType("💳");
    }
  }, [creditCardNumber]);

  async function processPayment() {
    if (creditCardNumber.length != 16) {
      alert('Please enter a valid Credit Card Number')
      return
    }
    if (CVC.length != 3) {
      alert('Please enter a valid CVC')
      return
    }
    if (!expiryMonth && !expiryYear) {
      alert('Please choose month and year')
      return
    }
    setIndicator(true)
    var payload = {
      number: creditCardNumber,
      cvc: CVC,
      expMonth: expiryMonth,
      expYear: expiryYear,
      amount: Number(Number(cartTotal) - Number(cartTotal * 0.05)).toFixed(2) * 100,
      paymentId: orderData.orderId,
      uid: orderData.uid
      // number: creditCardNumber,
      // cvc: CVC,
      // expMonth: expiryMonth,
      // expYear: expiryYear,
      // amount: 1,
      // paymentId: paymentId,
      // uid: uid
    };
    console.log(payload, 'this is payload')
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/pharmacy/payEmvCreate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(res => res.json())
      .then(resp => {
        setIndicator(false)
        if (resp.error === 'Error') {
          alert(resp.errorMessage)
          window.ReactNativeWebView.postMessage(resp.errorMessage)
          window.location.reload(true);
          return
        }
        var token = resp.id;
        var currency = "AUD";
        var browser = navigator.userAgent;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var updatePayload = {
          ipAddress: ip,
          currency: currency,
          token: token,
          browser: browser,
          timezone: timezone,
          paymentId: paymentId,
          paymentId: orderData.orderId,
          uid: orderData.uid
        };
        setIndicator(true)

        fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/pharmacy/payEmvUpdate', {
          method: 'POST',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatePayload)
        }).then(res => {
          return res.json()
        })
          .then(resp => {
            setIndicator(false)

            if (resp.error === 'Error') {
              alert(resp.errorMessage)
              window.ReactNativeWebView.postMessage(resp.errorMessage)

              window.location.reload(true);
              return
            }
            setifs(resp.authentication.redirectHtml)
            setshow(true)
            var iframeNode = document.querySelector('#challengeFrame');
            var token = resp.id;
            var currency = "AUD";

            if (JSON.stringify(resp).includes('Error')) {
              alert('Something went wrong Please try again')
              return;
            }

            var process3dSecureCallback = function (threedsResponse) {
              // window.removeEventListener('message', process3dSecureCallback);
              var simplifyDomain = 'https://www.simplify.com';
              console.log('eorr herer saas1', threedsResponse)

              if (threedsResponse.origin === simplifyDomain
                && !JSON.parse(threedsResponse.data)['secure3d']['authenticated']) {
                alert('Payment was cancelled, please try again')
                history.push({
                  pathname: "/Order",
                  state: { cart: cartItems },
                });
                return
              }
              if (threedsResponse.origin === simplifyDomain
                && JSON.parse(threedsResponse.data)['secure3d']['authenticated']) {

                var completePayload = {
                  amount: Number(Number(cartTotal) - Number(cartTotal * 0.05)).toFixed(2) * 100,
                  currency: currency,
                  description: 'description',
                  token: token,
                  paymentId: orderData.orderId,
                  uid: orderData.uid
                };
                setIndicator(true)
                console.log('eorr herer saassaasssas1')

                fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/pharmacy/complete', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(completePayload)
                }).then(res => {
                  return res.json()
                })
                  .then(async resp => {
                    setIndicator(false)
                    console.log('eorr herer saassaasssasdsdasdsdas1')

                    if (resp.error === 'Error') {
                      window["my_special_setting"] = "failed"
                      console.log('eorr herer 1')
                      alert(resp.errorMessage)
                      window.ReactNativeWebView.postMessage(resp.errorMessage)
                      window.location.reload(true);
                      return
                    }

                    if (resp.success) {
                      window["my_special_setting"] = true
                      console.log('eorr herer 12')
                      alert('Payment to medvatic was successful, Thank you')
                      window.ReactNativeWebView.postMessage('paymenttrue')



                      let API = new Apilib();
                      setloading(true)
                      let nk = await JSON.parse(localStorage.getItem('orderData'));
                      console.log(nk)
                      API.createNewOrder(nk).then(async res => {

                        console.log(res, 'this is res')

                        let imgg = await localStorage.getItem('imga');

                        console.log(imgg, 'this is res')
                        // image/png;
                        let types = null
                        if (nk.isPrescriptionRequiredVar) types = imgg.split(';')[0].split('/')[1];
                        if (nk.isPrescriptionRequiredVar) await storage
                          .ref(`/prescriptions/${res.data.prescriptionId}/prescription.${types}`)
                          .putString(imgg.split(',')[1], 'base64')
                          .then(res => {
                            console.log(res)
                          }).then(x => console.log(x)).catch(e => console.log(e, 'this is error'))





                        setloading(false)

                        Notify({
                          alert: true,
                          type: "success",
                          title: "Your order have been successfully,Please collect medicines at the pharmacy",
                        });
                        history.push({
                          pathname: "/Medicine",
                        });
                      }).catch(e => {
                        console.log(e, 'this is error');
                        setloading(false)
                        Notify({
                          alert: true,
                          type: "error",
                          title: "Your order could not be placed please try again",
                        });
                      })

                      // cartItems
                      setloading(false)


                      window.location.reload(true);
                    }
                    else if (resp.paymentStatus === "DECLINED") {
                      window["my_special_setting"] = "failed"
                      console.log('eorr herer 123')
                      alert('Payment failed, bank declined payment')
                      window.ReactNativeWebView.postMessage('paymentbankfalse')

                      // window.location.reload(true);
                      history.push({
                        pathname: "/Order",
                      });
                    }
                    else {
                      window["my_special_setting"] = "failed"
                      console.log('eorr herer 12345')
                      alert('Payment failed, Hold on for few minutes and try again')
                      window.ReactNativeWebView.postMessage('paymentfalse')
                      window.location.reload(true);
                    }


                  })
                  .catch(e => {
                    setIndicator(false)
                    window["my_special_setting"] = "failed"
                    console.log('eorr herer 1234567')
                    alert('Network error, please try again after a few minutes')

                    window.location.reload(true);
                  })
              }
            };

            var iframe = document.getElementById('challengeFrame');
            iframe.addEventListener('load', function () {
              window.addEventListener('message', process3dSecureCallback);
            });
            console.log('eorr herer 1234567saa')
            document.getElementById('threedsChallengeRedirectForm').submit();
            console.log('eorr herer 1234567saadsaaadddddddd')
          })
          .catch(e => {
            setIndicator(false)
            console.log('eorr herer 1234567saadsaaaasasasasa')
            alert('Network error, please try again after a few minutes')
          })

      })
      .catch(e => {
        setIndicator(false)
        console.log('eorr herer 1234567saadsaaa')
        alert('Network error, please try again after a few minutes')
        setloading(false)
        Notify({
          alert: true,
          type: "error",
          title: "Your order could not be placed please try again",
        });

      })

  }
  const handleSubmit = async (e) => {
    processPayment()
    // window.ReactNativeWebView.postMessage("Hello!")
    e.preventDefault();
  }
  return (
    <Div>
      {Indicator && <Loader />}
      <div style={{ alignContent: 'center', alignItems: 'center', }}>

        {!show &&
          <>
            <Div24>
              <Div25>
                <Column>
                  <Div26>
                    {/* <Div27>
                      <Div28>
                        <Div29>VAT number</Div29>
                        <Div30>
                          <Div31>(optional)</Div31>
                          <Div32>PO number</Div32>
                        </Div30>
                        <Div33>(optional)</Div33>
                      </Div28>
                      <Div34>
                        <Div35>158795456</Div35>
                        <Div36>988795456</Div36>
                      </Div34>
                    </Div27> */}
                    <Div37>
                      <Div38>Payment Method</Div38>
                      <Div39>
                        <Img11>
                          {cardType}
                        </Img11>
                      </Div39>
                      <Div40>Card holder name</Div40>
                      <Div41
                        options={{
                          numeral: "true"
                        }}

                        placeholder="Card holder name"
                        maxLength="30"
                        value={Cardholdername}
                        className="text-inputss"
                        onChange={e => setCardholdername(e.target.value.toUpperCase())}
                      />
                      <Div47>
                        <Div43>Card number</Div43>
                        {/* <Div44> */}
                        <Div45> Expiry date</Div45>
                        <Div46>CVC</Div46>
                        {/* </Div44> */}
                      </Div47>
                      <Div47>
                        <Div48 placeholder="Enter your credit card number"
                          options={{ creditCard: true }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          id="number-input"
                          name="number-input"
                          className="text-inputss"
                          maxLength="16"
                          value={creditCardNumber}
                          onChange={e => setcreditCardNumber(e.target.value)}></Div48>

                        <Div49 type="month"
                          placeholder="YYYY-MM"
                          className="text-inputss"
                          onChange={e => {
                            console.log(e.target.value);
                            return setcardExpirationDate(e.target.value)
                          }}

                        ></Div49>
                        <Div50 options={{
                          numeral: "true"
                        }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Enter CVC"
                          maxLength="3"
                          value={CVC}
                          className="text-inputss"
                          onChange={e => setCVC(e.target.value)}></Div50>
                      </Div47>
                      <Div51>
                        <Div53>
                          Credit card payments may take up to 24h to be processed
                        </Div53>
                      </Div51>
                      {/* <Div54>
                        <Img14
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/82b0c353-9457-4b99-af43-ecf5d3122428?apiKey=04f85002d68744bd9623fe1bdb31055b&"
                        />
                        <Div55>Save my payment details for future purchase</Div55>
                      </Div54> */}
                    </Div37>
                  </Div26>
                </Column>
                {/* <Column2>
                  <Div56>
                    <Div57>
                      <Div58>Total</Div58>
                      <Div59>$21.00</Div59>
                    </Div57>
                    <Div60 />
                    <Div61>
                      <Div62>
                        <Div63>Total Item</Div63>
                        <Div64>Discount</Div64>
                        <Div65>Shipping Cost</Div65>
                      </Div62>
                      <Div66>
                        <Div67>04</Div67>
                        <Div68>-$3.50</Div68>
                        <Div69>$2.00</Div69>
                      </Div66>
                    </Div61>
                    <Div70 />
                    <Div71 onClick={(e) => {
                      console.log(e, 'this si action')
                      handleSubmit(e)
                    }}>Pay $21.00</Div71>
                    {/* <form action="" method="POST" onSubmit={e => { console.log(e) }}></form> 
                  </Div56>
                </Column2> */}



                <div style={{ width: '100%', border: 'solid 1px #f7f7f7', height: '50%', }}>
                  <h5>
                    Bill Summary
                  </h5>

                  <div style={{ width: '100%', border: 'solid 1px #f7f7f7', overflow: 'hidden' }}>
                    <div style={{ width: '100%', height: '100%', overflowY: 'auto', }}>
                      <h5>
                        Cart Products
                      </h5>

                      {cartItems && cartItems.map(x => {
                        return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '5px' }}
                          key={Math.random()}
                        >

                          <div style={{ display: 'flex', }}>
                            <img src={x.imageLink} alt="image" style={{ width: '50px', height: '50px', }} />
                            <div >

                              <div> {x.Medicine_Name}</div>
                              <div> {x.qty || '1'}</div>
                            </div>
                          </div>
                          <div> {x.Price}</div>
                        </div>
                      })}
                    </div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h6>
                      Total
                    </h6>
                    {cartTotal && <h6>
                      ${cartTotal}
                    </h6>}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h6>
                      Discount
                    </h6>
                    {cartTotal && <h6 style={{ color: 'red' }}>
                      ${Number(cartTotal * .05).toFixed(2)}
                    </h6>}
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h6>
                      Amount to be paid
                    </h6>
                    {cartTotal && <h6>
                      ${Number(Number(cartTotal) - Number(cartTotal * 0.05)).toFixed(2)}
                    </h6>}
                  </div>



                  <div style={{
                    color: '#fff', backgroundColor: '#00869a', width: '100%', borderRadius: '5px', textAlign: 'center', padding: '5px', cursor: 'pointer'
                  }} onClick={async (e) => {
                    console.log('sending mails and processing orderss')
                    // alert("Your order have been successfully sent to pharmacy")
                    // let isPrescriptionRequired = false

                    await handleSubmit(e)


                  }}>
                    Make Payment & Place order
                  </div>
                </div>

              </Div25>
            </Div24>
          </>}
      </div>

      {show && <div dangerouslySetInnerHTML={createMarkup(ifs)} />}
      <div name="challengeFrames" id="challengeFrames" hidden></div>
      <form id="paymentForm" name="paymentForm" hidden></form>
      <form id="initiate3dsForm" name="initiate3dsForm" hidden></form>
      <form id="threedsChallengeRedirectForm" name="threedsChallengeRedirectForm" hidden></form>
      <script id="initiate-authentication-script"></script>
    </Div>
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;


const Div24 = styled.div`
  align-self: center;
  margin-top: 65px;
  width: 100%;
  max-width: 1411px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div25 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 59%;
  margin: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div26 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 10px;
  }
`;

const Div27 = styled.div`
  border-radius: 10px;
  border: 1px solid #dadde3;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 34px 35px 34px 29px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div28 = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Div29 = styled.div`
  color: #000;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Div30 = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Div31 = styled.div`
  color: #5f5f5f;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div32 = styled.div`
  color: #000;
  align-self: start;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div33 = styled.div`
  color: #5f5f5f;
  align-self: stretch;
  white-space: nowrap;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div34 = styled.div`
  display: flex;
  margin-top: 23px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div35 = styled.div`
  color: #5f5f5f;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #dadde3;
  background-color: #fff;
  flex-grow: 1;
  align-items: start;
  padding: 16px 12px;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div36 = styled.div`
  color: #5f5f5f;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #dadde3;
  background-color: #fff;
  flex-grow: 1;
  padding: 16px 0;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div37 = styled.div`
  border-radius: 10px;
  border: 1px solid #dadde3;
  background-color: #fff;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  padding: 32px 31px 32px 29px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div38 = styled.div`
  color: #000;
  align-self: stretch;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div39 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  justify-content: space-between;
  gap: 13px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
`;

const Img11 = styled.div`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  align-self: start;
  max-width: 100%;
  font-weight:800;
  font: 800 29px/28px Roboto, sans-serif;
`;

const Div40 = styled.div`
  color: #737373;
  align-self: stretch;
  margin-top: 53px;
  white-space: nowrap;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div41 = styled.input`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  height: 54px;
  font-weight:bold;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div42 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 33px;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div43 = styled.div`
  color: #737373;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div44 = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Div45 = styled.div`
  color: #737373;
  font: 400 20px/23px Roboto, sans-serif;
`;

const Div46 = styled.div`
  color: #737373;
  align-self: start;
  white-space: nowrap;
  font: 400 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div47 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  gap: 15px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Div48 = styled.input`
  color: #041b1f;
  type:number;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  flex-grow: 1;
  align-items: start;
  padding: 21px 13px;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div49 = styled.input`
  color: #041b1f;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  flex-grow: 1;
  align-items: start;
  padding: 20px 12px;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div50 = styled.input`
  color: #041b1f;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  flex-grow: 1;
  align-items: start;
  padding: 21px 13px;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div51 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 482px;
  max-width: 100%;
  gap: 10px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div52 = styled.div`
  color: #00869a;
  white-space: nowrap;
  border-radius: 50%;
  aspect-ratio: 1;
  padding: 5px 0;
  font: 400 15px/15px SeoulHangang CBL, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div53 = styled.div`
  color: #000;
  align-self: start;
  margin-top: 4px;
  flex-grow: 1;
  white-space: nowrap;
  font: 300 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div54 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 386px;
  max-width: 100%;
  gap: 11px;
`;

const Img14 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const Div55 = styled.div`
  color: #000;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;



export default PaymentOrder;