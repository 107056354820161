import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import Apilib from "../../api";
import { SplashLoader } from "../Loader/Loader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={index}
      aria-labelledby={index}
      {...other}
    >
      {children}
    </div>
  );
}

const Orders = (route) => {
  // let orderPendingDummyData = [
  //   {
  //     orderId: "1",
  //     patientName: "Jimmy Vardy",
  //     doctorName: "Mark Henry",
  //     date: new Date().toISOString(),
  //     cost: "$12",
  //     status: "Pending",
  //   },
  // ];
  let [ordersPending, setOrdersPending] = useState([]);
  let [ordersDone, setOrdersDone] = useState([]);
  let [ordersRejected, setOrdersRejected] = useState([]);
  let [allOrders, setAllOrders] = useState([]);
  let [loading, setLoading] = useState(false);
  let [selectedTab, setSelectedTab] = useState("pending");

  useEffect(() => {
    //get all orders by pharmacy id: use api.getordersbypharmacyID

    let getAllOrders = async () => {
      let API = new Apilib();

      setLoading(true);
      // let res = await API.getOrdersByPharmacyId(props.userData.pharmacyId);
      // res = await res.json();
      // console.log("RES IS:", res.data);
      // let allOrdersTemp = res.data;
      let allOrdersTemp = []
      // setAllOrders(allOrdersTemp);

      let temp = []
      API.getAllPharmacyOrder({ pharmacy_id: route.userData.uid })
        .then(async (res) => {
          console.log(res)
          console.log('this is response')
          console.log(res.data)
          // setorderData(res.data.data)
          res?.data?.data.map(x => {
            console.log('individual')
            console.log(x)

            let pro = {
              orderId: x.orderId,
              patientName: x.patientName,
              doctorName: x?.prescribedBy ? x.prescribedBy : "NA",
              date: x.date,
              cost: x.cost,
              orderStatus: x.orderStatus,
            }
            temp.push(pro)
            allOrdersTemp = temp

            let ordersPendingTemp = allOrdersTemp.filter(
              (order) => order.orderStatus === "Pending"
            );
            setOrdersPending(ordersPendingTemp);

            let ordersDoneTemp = allOrdersTemp.filter(
              (order) => order.orderStatus === "Completed"
            );
            let ordersRejectedTemp = allOrdersTemp.filter(
              (order) => order.orderStatus === "Rejected"
            );
            console.log('ordersPendingTemp')
            console.log(ordersPendingTemp)
            console.log('ordersRejectedTemp')
            console.log(ordersRejectedTemp)
            console.log('ordersDoneTemp')
            console.log(ordersDoneTemp)
            setOrdersRejected(ordersRejectedTemp);
            setOrdersDone(ordersDoneTemp);


            console.log(x)
          })
          setLoading(false);
          setAllOrders(temp);
          console.log('pharmacy order data')

          // setinvoiceData(res.data.invoiceList)
          // setpharmacyData(res.data.invoices)
        })
        .catch((error) => {
          console.log('error order', error)
        });

      //filter allOrdersTemp by status into ordersPending and ordersDone

      // if ($(".datatable").length > 0) {
      //   $(".datatable").DataTable({
      //     columnDefs: [
      //       {
      //         targets: [0, 5],
      //         orderable: false,
      //       },
      //     ],
      //     order: [],
      //   });
      // }
    };
    getAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <>
        <SplashLoader />
      </>
    );
  } else {
    return (
      <div>
        <Tabs
          variant="fullWidth"
          centered
          className="mb-3"
          value={selectedTab}
          onChange={(e, v) => {
            setSelectedTab(v);
          }}
          aria-label="wrapped label tabs example"
        >
          <Tab value="pending" label="Pending" wrapped />
          <Tab value="done" label="Done" wrapped />
          <Tab value="rejected" label="Rejected" wrapped />
        </Tabs>
        <TabPanel index="pending" value={selectedTab}>
          <div className="table-responsive">
            <table className="table table-border table-striped custom-table datatable m-b-0">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Patient Name</th>
                  <th>Doctor Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {ordersPending.length > 0 ? (
                <tbody>
                  {ordersPending.map((order) => (
                    <tr key={order.orderId}>
                      <td>MED{order.orderId}</td>
                      <td>{order.patientName}</td>
                      <td>{order.doctorName}</td>
                      <td>{moment(order.date).format('DD-MMM-YYYY')}</td>
                      <td>{order.orderStatus}</td>
                      <td>
                        <Link
                          to={`/pharmacy/orders/order-details/${order.orderId}/${order.orderId}`}
                        >
                          <i className="fa fa-info-circle"></i> Order Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr className="text-center">
                    <td colSpan={12}>No Pending Orders</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </TabPanel>
        <TabPanel index="done" value={selectedTab}>
          <div className="table-responsive">
            <table className="table table-border table-striped custom-table datatable m-b-0">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Patient Name</th>
                  <th>Doctor Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {ordersDone.length > 0 ? (
                <tbody>
                  {ordersDone.map((order) => (
                    <tr key={order.orderId}>
                      <td>MED{order.orderId}</td>
                      <td>{order.patientName}</td>
                      <td>{order.doctorName}</td>
                      <td>{moment(order.date).format('DD-MMM-YYYY')}</td>
                      <td>{order.orderStatus}</td>
                      <td>
                        <Link
                          to={`/pharmacy/orders/order-details/${order.orderId}/${order.orderId}`}
                        >
                          <i className="fa fa-info-circle"></i> Order Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr className="text-center">
                    <td colSpan={12}>No Completed Orders</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </TabPanel>
        <TabPanel index="rejected" value={selectedTab}>
          <div className="table-responsive">
            <table className="table table-border table-striped custom-table datatable m-b-0">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Patient Name</th>
                  <th>Doctor Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {ordersRejected.length > 0 ? (
                <tbody>
                  {ordersRejected.map((order) => (
                    <tr key={order.orderId}>
                      <td>MED{order.orderId}</td>
                      <td>{order.patientName}</td>
                      <td>{order.doctorName}</td>
                      <td>{moment(order.date).format('DD-MMM-YYYY')}</td>
                      <td>{order.orderStatus}</td>
                      <td>
                        <Link
                          to={`/pharmacy/orders/order-details/${order.orderId}/${order.orderId}`}
                        >
                          <i className="fa fa-info-circle"></i> Order Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr className="text-center">
                    <td colSpan={12}>No Pending Orders</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </TabPanel>
      </div>
    );
  }
};

export default Orders;
