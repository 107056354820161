import React, { Component } from "react";
import BaseSelect from "react-select";
import BaseSelect2 from "react-select/creatable";
import RequiredSelect from "../../Helpers/requiredSelect";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import Notify from "../../Notify";
import GoogleMap from "../Agora/utils/GoogleMap";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ArrayInput, { DisplayArr } from "../ArrayInput";

const Select = (props) => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

const Select2 = (props) => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect2}
        options={props.options}
    />
);

const status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
];

const specialities = [
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "Dentist", label: "Dentist" },
    { value: "Gynaecologist", label: "Gynaecologist" },
    { value: "Paediatrician", label: "Paediatrician" },
    { value: "Psychiatrists", label: "Psychiatrists" },
];

const qualifications = [
    { value: "X", label: "X" },
    { value: "Y", label: "Y" },
    { value: "Z", label: "Z" },
];
export default class DoctorsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            doctors: [],
            geoLocation: {},
            currentDoctor: {
                specialitiesObj: [],
                specialities: [],
            },
            qualificationsObj: null,
            specialitiesObj: [],
            specialities: [],
            deleteModal: false,
            currentDoctor: [],
            date: Date().toLocaleString(),
            currentIndex: "",
            added: false,
            modalOpen: false,
            openModel: false,
            allSpecialities: [],
            addSpecialities: false,
            newSpeciality: "",
            addSpecialityIsValid: false,
            modalLoading: false,
            success: false,
        };

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        console.log("propsssss", this.props);
        this.setState({
            loading: true,
        });
        let API = new Apilib();
        API.getUsers(3, this.props.userData.hospitalId)
            .then((result) => {
                let res = result.data;

                if (this._isMounted) {
                    this.setState(
                        {
                            loading: false,
                            doctors: res.doctors,
                        },
                        () => {
                            if ($(".datatable").length > 0) {
                                $(".datatable").DataTable({});
                            }
                        }
                    );
                }
            })
            .catch((error) => {
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                    });
                }
            });

        this.setState({
            loading: true,
        });
        API.getSpecialities()
            .then((result) => {
                let res = result.data;

                let data = res.data;
                let modified = data.map((item) => {
                    return {
                        value: item.speciality,
                        label: item.speciality,
                        id: item.id,
                    };
                });
                modified.push({ value: 0, label: "Other", id: null });
                console.log("modified data>>>>>>>", modified);
                this.setState({
                    loading: false,
                    allSpecialities: modified,
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange = (e) => {
        let formObj = { ...this.state.currentDoctor };
        formObj[e.target.name] = e.target.value.trim();
        this.setState({
            currentDoctor: formObj,
        });
    };
    // updateData = (e) => {
    //   e.preventDefault();
    //   let data = { ...this.state.currentHospital };
    //   let hospitals = [...this.state.hospitals];
    //   hospitals[this.state.currentIndex] = data;
    //   this.setState({
    //     modalLoading: true,
    //     hospitals,
    //   });
    editedDoctors(value) {
        console.log("edited doc", value);
        if (
            !(
                value.firstName &&
                value.lastName &&
                value.specialities != 0 &&
                value.experience &&
                value.regnumber &&
                value.regexpirydate &&
                value.email &&
                value.mobile &&
                value.gender
            )
        ) {
            console.log("invalid form");
            Notify({
                alert: true,
                type: "error",
                title: "Fill all mandatory fields",
            });
            return;
        }
        let data = {
            ...value,
        };
        delete data.formIsValid;
        delete data.formData;
        console.log("Edited doc:::", data);
        let API = new Apilib();
        API.updateUser(JSON.stringify(data))
            .then((result) => {
                let res = result.data;
                this.componentDidMount();
                this.setState({
                    loading: false,
                    doctors: [],
                    modalOpen: false,
                    openModel: false,
                    currentDoctor: [],
                });
                Notify({
                    alert: true,
                    type: "success",
                    title: "Professional Updated Successfully",
                });
            })
            .catch((err) => console.log("updateuser err", err));
    }

    onQualificationSelect(value) {
        console.log("value--->", value);
        let formObj = { ...this.state.currentDoctor };
        let arr = [];
        if (value) {
            value.map((val, index) => {
                arr.push(val.value);
            });
            formObj.qualifications = [...arr];
            formObj.qualificationsObj = [...value];
            this.setState({
                currentDoctor: formObj,
            });
        } else {
            this.setState({
                currentDoctor: {
                    ...this.state.currentDoctor,
                    qualificationsObj: [],
                    qualifications: [],
                },
            });
        }
    }

    onSelectStatus = (val) => {
        let formObj = { ...this.state.currentDoctor };
        formObj["status"] = val;
        this.setState({
            currentDoctor: formObj,
        });
    };

    onSucess = () => {
        this.setState({
            currentDoctor: {
                specialitiesObj: [],
                specialities: [],
            },
            modalLoading: false,
            modalOpen: false,
            openModel: false,
        });
    };

    // updateData = (e) => {
    //   e.preventDefault();
    //   if (!!this.state.newSpeciality && this.state.added) {
    //     Notify({
    //       alert: true,
    //       type: "error",
    //       title: "Please click on Add button to add new speciality",
    //     });
    //     return;
    //   }
    //   const all_state = { ...this.state };
    //   let data = { ...this.state.currentDoctor, geoLocation: this.state.geoLocation };

    //   data.specialities = data.specialities.map((el) => {
    //     if (el == 0) return this.state.newSpeciality;
    //     return el;
    //   });

    //   data["email"] = this.state.doctors[this.state.currentIndex]["email"];
    //   let doctors = [...this.state.doctors];
    //   doctors[this.state.currentIndex] = data;
    //   this.setState({
    //     modalLoading: true,
    //     doctors,
    //   });
    //   // delete data.email;
    //   let API = new Apilib();
    //   API.updateUser(data).then((result) => {
    //       let res = result.data
    //       console.log("update doctor", data, res);
    //       // this.onSucess();
    //       Notify({
    //         alert: true,
    //         type: "success",
    //         title: "Professional Updated successfully!",
    //       });
    //       this.setState({
    //         loading: false,
    //         currentDoctor: {
    //           specialitiesObj: [],
    //           specialities: [],
    //         },
    //         qualificationsObj: null,
    //         specialitiesObj: [],
    //         specialities: [],
    //         currentIndex: "",
    //         added: false,
    //         modalOpen: false,
    //         openModel: false,
    //         allSpecialities: [],
    //         addSpecialities: false,
    //         newSpeciality: "",
    //         addSpecialityIsValid: false,
    //         modalLoading: false,
    //         success: false,
    //       });

    //     })
    //     .catch((error) => {
    //       this.setState({
    //         modalLoading: false,
    //         modalOpen: false,
    //         openModel: false,
    //       });
    //       Notify({
    //         alert: true,
    //         type: "error",
    //         title: error.message,
    //       });
    //     });
    // };

    deleteDoctors(value) {
        let data = {
            type: 3,
            uid: value.uid,
        };
        console.log("Edited doct:::", data);
        let API = new Apilib();
        this.setState({
            loading: true,
        });
        API.deleteDoctor(data).then((result) => {
            let res = result.data;
            console.log("RES_>", res);
            // if (res.ok) {
            Notify({
                title: "Doctor deleted successfully",
                alert: true,
                type: "success",
            });
            this.setState({
                loading: false,
                doctors: [],
                deleteModal: false,
                currentDoctor: [],
            });
            this.componentDidMount();
            // } else {
            //   Notify({
            //     alert: true,
            //     type: "error",
            //     title: "Error Occured",
            //   });
            //   this.setState({
            //     loading: false,
            //   });
            // }
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleOpen(e, index) {
        e.preventDefault();
        console.log("selected doctor", this.state.doctors[index]);
        this.setState({
            modalOpen: true,
            currentDoctor: this.state.doctors[index],
            //  geoLocation: this.props.state.geoLocation,
            currentIndex: index,
            error: null,
            success: false,
        });
    }

    handleOpens(e, index) {
        e.preventDefault();
        console.log("selected doctor", this.state.doctors[index]);
        this.setState({
            openModel: true,
            currentDoctor: this.state.doctors[index],
            currentIndex: index,
            error: null,
            success: false,
        });
    }

    handleClose() {
        this.setState({
            modalOpen: false,

            currentDoctor: {},
            currentIndex: "",
        });
    }
    handleCloses() {
        this.setState({
            openModel: false,
            currentDoctor: {},
            currentIndex: "",
        });
    }

    onSpecialitySelect(value) {
        console.log("onSpecialitySelect", value);
        let arr = [];
        if (value && value.length !== 0) {
            value.map((val, index) => {
                if (val.value === 0) {
                    this.setState({
                        addSpecialities: true,
                    });
                }
                arr.push(val.value);
            });
            this.setState({
                specialities: arr,
                added: true,
                specialitiesObj: value,
                currentDoctor: {
                    ...this.state.currentDoctor,
                    specialitiesObj: value,
                    specialities: arr,
                },
            });
        } else {
            this.setState({
                currentDoctor: {
                    ...this.state.currentDoctor,
                    specialitiesObj: [],
                    specialities: [],
                },
                addSpecialities: false,
                added: false,
                specialities: [],
                specialitiesObj: [],
                newSpeciality: "",
            });
        }
    }

    addSpecialityHandler(e) {
        e.preventDefault();
        // let speciality = this.state.newSpeciality;
        let data = {
            speciality: this.state.newSpeciality,
            toBeApproved: true,
            hospital_name: this.props.userData.hospital_name,
            admin_email: this.props.userData.email,
            doctor_email: this.state.email,
        };
        this.setState({ loading: true });
        let API = new Apilib();
        API.addNewSpeciality(JSON.stringify(data))
            .then((result) => {
                let res = result.data;

                console.log("addNewSpeciality_res", res);
                this.setState({
                    loading: false,
                    added: false,
                    newSpecialityDetails: res.speciality,
                });
            })
            .catch((err) => {
                console.log("addNewSpeciality_err", err);
                this.setState({ loading: false, added: false });
            });
        console.log("addSpecialityHandler", data);
    }

    componentDidUpdate(prevProp, prevState) {
        if (
            prevState.newSpeciality !== this.state.newSpeciality ||
            prevState.email !== this.state.email
        ) {
            let valid = true;
            valid =
                this.state.newSpeciality !== "" &&
                this.state.email !== "" &&
                valid;
            this.setState({
                addSpecialityIsValid: valid,
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />;
        } else {
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title">Professionals</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link
                                to="/hospital/add-doctor"
                                className="btn btn-primary float-right btn-rounded"
                            >
                                <i className="fa fa-plus"></i> Add Professional
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                {this.state.error && (
                                    <div
                                        className="alert alert-danger alert-dismissible fade show"
                                        role="alert"
                                    >
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="alert"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">
                                                &times;
                                            </span>
                                            <span className="sr-only">
                                                Close
                                            </span>
                                        </button>
                                        {this.state.error}
                                    </div>
                                )}
                                <table className="table table-border table-striped custom-table datatable m-b-0">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Speciality</th>
                                            <th>Qualifications</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.doctors &&
                                            this.state.doctors.map(
                                                (doctor, index) => (
                                                    <tr key={index}>
                                                        <td className="full_name">
                                                            <a
                                                                href="#"
                                                                onClick={(e) =>
                                                                    this.handleOpens(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    doctor.firstName
                                                                }{" "}
                                                                { }{" "}
                                                                {
                                                                    doctor.lastName
                                                                }
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {doctor?.specialities ==
                                                                ""
                                                                ? "N/A"
                                                                : doctor?.specialities &&
                                                                doctor?.specialities.join(
                                                                    ", "
                                                                )}
                                                        </td>
                                                        <td>
                                                            {doctor.qualifications ==
                                                                ""
                                                                ? "N/A"
                                                                : doctor.qualifications &&
                                                                doctor.qualifications.join(
                                                                    ", "
                                                                )}
                                                        </td>
                                                        <td>
                                                            {doctor?.status
                                                                ?.label == ""
                                                                ? "N/A"
                                                                : doctor?.status
                                                                    ?.label}
                                                        </td>
                                                        <td>
                                                            {doctor.todaysDate ==
                                                                ""
                                                                ? "N/A"
                                                                : doctor.todaysDate}
                                                        </td>

                                                        <td>
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.handleOpen(
                                                                        e
                                                                    );
                                                                    this.setState(
                                                                        {
                                                                            currentDoctor:
                                                                                doctor,
                                                                        }
                                                                    );
                                                                }}
                                                                className="btn btn-small btn-secondary mr-2"
                                                            >
                                                                <span
                                                                    class="iconify"
                                                                    data-icon="akar-icons:edit"
                                                                    data-inline="false"
                                                                    style={{
                                                                        marginRight:
                                                                            ".5em",
                                                                        marginTop:
                                                                            "-.1em",
                                                                        display:
                                                                            "inline-block",
                                                                    }}
                                                                ></span>
                                                                Edit
                                                            </button>
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.setState(
                                                                        {
                                                                            deleteModal: true,
                                                                            currentDoctor:
                                                                                doctor,
                                                                        }
                                                                    );
                                                                }}
                                                                className="btn btn-danger btn-small mr-2"
                                                            >
                                                                <span
                                                                    class="iconify"
                                                                    data-icon="ant-design:delete-twotone"
                                                                    data-inline="false"
                                                                    style={{
                                                                        marginRight:
                                                                            ".5em",
                                                                        marginTop:
                                                                            "-.1em",
                                                                        display:
                                                                            "inline-block",
                                                                    }}
                                                                ></span>
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Modal
                        backdrop="static"
                        show={this.state.openModel}
                        onHide={() => this.handleCloses()}
                        animation={true}
                        size="l"
                    >
                        {this.state.modalLoading && <Loader />}
                        <Modal.Header closeButton>
                            <Modal.Title>View Doctor</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={(e) => this.updateData(e)}>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                First Name{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="firstName"
                                                value={
                                                    this.state.currentDoctor
                                                        .firstName
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                readOnly
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Last Name{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="lastName"
                                                value={
                                                    this.state.currentDoctor
                                                        .lastName
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                readOnly
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Specialty{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="form-control"
                                                required
                                                readOnly
                                                onChange={(val) =>
                                                    this.onSpecialitySelect(val)
                                                }
                                                value={
                                                    this.state.currentDoctor
                                                        .specialities
                                                }
                                                options={
                                                    this.state.allSpecialities
                                                }
                                            />
                                            {/* 
                      {this.state.addSpecialities ? (
                        <div className="row mt-3">
                          <div className="col-sm-8">
                            {this.state.added ? (
                              <div className="form-group">
                                <label>
                                  Add speciality{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div style={{ display: "flex" }}>
                                  <input
                                    name="regnumber"
                                    value={this.state.newSpeciality}
                                    onChange={(e) =>
                                      this.setState({
                                        newSpeciality: e.target.value,
                                      })
                                    }
                                    required
                                    readOnly
                                    className="form-control mr-4"
                                    type="text"
                                  />
                                </div>
                              </div>
                            ) : (
                              <p>New Specialty: {this.state.newSpeciality}</p>
                            )}
                          </div>
                        </div>
                      ) : null} */}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Years of Experience{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="experience"
                                                value={
                                                    this.state.currentDoctor
                                                        .experience
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                readOnly
                                                min="0"
                                                className="form-control"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Qualifications</label>
                                            <input
                                                className="form-control"
                                                readOnly
                                                placeholder="Enter Qualifications"
                                                value={
                                                    this.state.currentDoctor
                                                        .qualifications
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Registration Number{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="regnumber"
                                                value={
                                                    this.state.currentDoctor
                                                        .regnumber
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                readOnly
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Registration Expiry Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="regexpirydate"
                                                value={
                                                    this.state.currentDoctor
                                                        .regexpirydate
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                readOnly
                                                className="form-control"
                                                type="date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Email{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="email"
                                                value={
                                                    this.state.currentDoctor
                                                        .email
                                                }
                                                readOnly
                                                className="form-control"
                                                type="email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Mobile No.
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="mobile"
                                                value={
                                                    this.state.currentDoctor
                                                        .mobile
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                readOnly
                                                className="form-control"
                                                type="phone"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Gender{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="gender"
                                                value={
                                                    this.state.currentDoctor
                                                        .gender
                                                }
                                                readOnly
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Base Fee(Per appointment basis)
                                            </label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text"
                                                        id="inputGroupPrepend3"
                                                    >
                                                        $
                                                    </span>
                                                </div>
                                                <input
                                                    name="base_fee"
                                                    min="0"
                                                    readOnly
                                                    value={
                                                        this.state.currentDoctor
                                                            .base_fee
                                                    }
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    className="form-control"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <DisplayArr
                                            label={"Language Spoken"}
                                            stateArr={
                                                this.state.currentDoctor
                                                    ?.language_spoken
                                                    ? this.state.currentDoctor
                                                        ?.language_spoken
                                                    : []
                                            }
                                            stateSetter={(arr) => {
                                                this.setState({
                                                    currentDoctor: {
                                                        ...this.state
                                                            .currentDoctor,
                                                        language_spoken: arr,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Profession Description
                                            </label>
                                            <div class="input-group">
                                                <textarea
                                                    name="description"
                                                    value={
                                                        this.state
                                                            ?.currentDoctor
                                                            ?.description
                                                    }
                                                    readOnly={true}
                                                    rows="10"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <DisplayArr
                                            label={"Consultation Reasons"}
                                            stateArr={
                                                this.state.currentDoctor
                                                    ?.consultation_reasons
                                                    ? this.state.currentDoctor
                                                        ?.consultation_reasons
                                                    : []
                                            }
                                            stateSetter={(arr) => {
                                                this.setState({
                                                    currentDoctor: {
                                                        ...this.state
                                                            .currentDoctor,
                                                        consultation_reasons:
                                                            arr,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <DisplayArr
                                            label={"Area Of Interests"}
                                            stateArr={
                                                this.state.currentDoctor
                                                    ?.area_of_interest
                                                    ? this.state.currentDoctor
                                                        ?.area_of_interest
                                                    : []
                                            }
                                            stateSetter={(arr) => {
                                                this.setState({
                                                    currentDoctor: {
                                                        ...this.state
                                                            .currentDoctor,
                                                        area_of_interest: arr,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                        </form>
                    </Modal>
                    <Modal
                        backdrop="static"
                        show={this.state.modalOpen}
                        onHide={() => this.handleClose()}
                        animation={true}
                        size="xl"
                    >
                        {this.state.modalLoading && <Loader />}
                        <Modal.Header closeButton>
                            <Modal.Title>Professional Update</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={(e) => this.updateData(e)}>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                First Name{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="firstName"
                                                value={
                                                    this.state.currentDoctor
                                                        .firstName
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Last Name{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="lastName"
                                                value={
                                                    this.state.currentDoctor
                                                        .lastName
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Specialty{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <Select
                                                isMulti
                                                required
                                                onChange={(val) =>
                                                    this.onSpecialitySelect(val)
                                                }
                                                value={
                                                    this.state.currentDoctor
                                                        .specialities
                                                        ? this.state.currentDoctor.specialities.map(
                                                            (spec) => {
                                                                return {
                                                                    ...spec,
                                                                    value: spec,
                                                                    label: spec,
                                                                };
                                                            }
                                                        )
                                                        : null
                                                }
                                                options={
                                                    this.state.allSpecialities
                                                }
                                            />
                                            {/* 
                      {this.state.addSpecialities ? (
                        <div className="row mt-3">
                          <div className="col-sm-8">
                            {this.state.added ? (
                              <div className="form-group">
                                <label>
                                  Add speciality{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div style={{ display: "flex" }}>
                                  <input
                                    name="regnumber"
                                    value={this.state.newSpeciality}
                                    onChange={(e) =>
                                      this.setState({
                                        newSpeciality: e.target.value,
                                      })
                                    }
                                    required
                                    className="form-control mr-4"
                                    type="text"
                                  />
                                  <button
                                    disabled={!this.state.addSpecialityIsValid}
                                    onClick={(e) =>
                                      this.addSpecialityHandler(e)
                                    }
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <p>New Specialty: {this.state.newSpeciality}</p>
                            )}
                          </div>
                        </div>
                      ) : null} */}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Years of Experience{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="experience"
                                                value={
                                                    this.state.currentDoctor
                                                        .experience
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                min="0"
                                                className="form-control"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Qualifications</label>
                                            <Select2
                                                isMulti
                                                onChange={(val) =>
                                                    this.onQualificationSelect(
                                                        val
                                                    )
                                                }
                                                placeholder="Enter Qualifications"
                                                noOptionsMessage={() =>
                                                    "Enter qualification"
                                                }
                                                options={
                                                    this.state.currentDoctor
                                                        .qualifications
                                                        ? this.state.currentDoctor.qualifications.map(
                                                            (qua) => {
                                                                return {
                                                                    value: qua,
                                                                    label: qua,
                                                                };
                                                            }
                                                        )
                                                        : null
                                                }
                                                value={
                                                    this.state.currentDoctor
                                                        .qualifications
                                                        ? this.state.currentDoctor.qualifications.map(
                                                            (qua) => {
                                                                return {
                                                                    value: qua,
                                                                    label: qua,
                                                                };
                                                            }
                                                        )
                                                        : null
                                                }
                                            />
                                            <small className="form-text form-muted text-muted">
                                                (Press Enter/Tab after entering
                                                qualification)
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Registration Number{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="regnumber"
                                                value={
                                                    this.state.currentDoctor
                                                        .regnumber
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                className="form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Registration Expiry Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="regexpirydate"
                                                value={
                                                    this.state.currentDoctor
                                                        .regexpirydate
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                className="form-control"
                                                type="date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Email{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="email"
                                                value={
                                                    this.state.currentDoctor
                                                        .email
                                                }
                                                readOnly
                                                className="form-control"
                                                type="email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Mobile No.
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                name="mobile"
                                                value={
                                                    this.state.currentDoctor
                                                        .mobile
                                                }
                                                onChange={(e) =>
                                                    this.onChange(e)
                                                }
                                                required
                                                className="form-control"
                                                type="phone"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                       Location<span className="text-danger">*</span>
                      </label>
                      <GoogleMap
                      style={{ width: 100 }}
                      // setGeoLocation={(e) => {
                      //   this.setState({
                      //     geoLocation: { lat: e.lat, lng: e.lng },
                      //   });
                      // }}
                      // geoLocation={this.state.geoLocation}
                      value = { this.state.geoLocation}
                      onChange={(e) =>  
                        // this.setState({
                        //   geoLocation: this.state.address,
                        // })
                        console.log("aaa")
                      }
                     getAddress={(address) => {
                      this.setState({
                        geoLocation: address,
                      },()=>{
                        console.log("MApAddress",this.state.geoLocation)
                      })
                       console.log("GoogleMapsAddress",address)
                     }}
                    />
                    </div>
                  </div> */}
                                    <div className="col-sm-6">
                                        <div className="form-group gender-select">
                                            <label className="gen-label">
                                                Gender{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>{" "}
                                                :
                                            </label>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input
                                                        required
                                                        type="radio"
                                                        onChange={(e) =>
                                                            this.onChange(e)
                                                        }
                                                        name="gender"
                                                        checked={
                                                            this.state
                                                                .currentDoctor
                                                                .gender ==
                                                            "male"
                                                        }
                                                        value="male"
                                                        className="form-check-input"
                                                    />
                                                    Male
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input
                                                        required
                                                        type="radio"
                                                        onChange={(e) =>
                                                            this.onChange(e)
                                                        }
                                                        name="gender"
                                                        checked={
                                                            this.state
                                                                .currentDoctor
                                                                .gender ==
                                                            "female"
                                                        }
                                                        value="female"
                                                        className="form-check-input"
                                                    />
                                                    Female
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input
                                                        required
                                                        type="radio"
                                                        onChange={(e) =>
                                                            this.onChange(e)
                                                        }
                                                        name="gender"
                                                        checked={
                                                            this.state
                                                                .currentDoctor
                                                                .gender ==
                                                            "other"
                                                        }
                                                        value="other"
                                                        className="form-check-input"
                                                    />
                                                    Other
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input
                                                        required
                                                        type="radio"
                                                        onChange={(e) =>
                                                            this.onChange(e)
                                                        }
                                                        name="gender"
                                                        checked={
                                                            this.state
                                                                .currentDoctor
                                                                .gender ==
                                                            "not_disclose"
                                                        }
                                                        value="not_disclose"
                                                        className="form-check-input"
                                                    />
                                                    I wish not to disclose
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Status{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <Select
                                                required
                                                value={
                                                    this.state.currentDoctor
                                                        .status
                                                }
                                                onChange={(item) =>
                                                    this.onSelectStatus(item)
                                                }
                                                options={status}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Base Fee(Per appointment basis)
                                            </label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text"
                                                        id="inputGroupPrepend3"
                                                    >
                                                        $
                                                    </span>
                                                </div>
                                                <input
                                                    name="base_fee"
                                                    min="0"
                                                    value={
                                                        this.state.currentDoctor
                                                            .base_fee
                                                    }
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    className="form-control"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <ArrayInput
                                            label={"Language Spoken"}
                                            stateArr={
                                                this.state.currentDoctor
                                                    ?.language_spoken
                                                    ? this.state.currentDoctor
                                                        ?.language_spoken
                                                    : []
                                            }
                                            stateSetter={(arr) => {
                                                this.setState({
                                                    currentDoctor: {
                                                        ...this.state
                                                            .currentDoctor,
                                                        language_spoken: arr,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Profession Description
                                            </label>
                                            <div class="input-group">
                                                <textarea
                                                    name="description"
                                                    rows="10"
                                                    value={
                                                        this.state
                                                            ?.currentDoctor
                                                            ?.description
                                                    }
                                                    onChange={(e) =>
                                                        this.onChange(e)
                                                    }
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <ArrayInput
                                            label={"Consultation Reasons"}
                                            stateArr={
                                                this.state.currentDoctor
                                                    ?.consultation_reasons
                                                    ? this.state.currentDoctor
                                                        ?.consultation_reasons
                                                    : []
                                            }
                                            stateSetter={(arr) => {
                                                this.setState({
                                                    currentDoctor: {
                                                        ...this.state
                                                            .currentDoctor,
                                                        consultation_reasons:
                                                            arr,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <ArrayInput
                                            label={"Area Of Interests"}
                                            stateArr={
                                                this.state.currentDoctor
                                                    ?.area_of_interest
                                                    ? this.state.currentDoctor
                                                        ?.area_of_interest
                                                    : []
                                            }
                                            stateSetter={(arr) => {
                                                this.setState({
                                                    currentDoctor: {
                                                        ...this.state
                                                            .currentDoctor,
                                                        area_of_interest: arr,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => this.handleClose()}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        this.editedDoctors(
                                            this.state.currentDoctor
                                        )
                                    }
                                >
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                    {/* Delete Modal */}
                    <Modal
                        backdrop="static"
                        show={this.state.deleteModal}
                        onHide={() =>
                            this.setState({
                                deleteModal: false,
                            })
                        }
                        animation={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Doctor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="p-2">
                                Are you sure you want delete doctor{" "}
                                <b>{`${this.state.currentDoctor.firstName} ${this.state.currentDoctor.lastName}`}</b>
                                ?
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="danger"
                                onClick={() =>
                                    this.setState({
                                        deleteModal: false,
                                    })
                                }
                            >
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() =>
                                    this.deleteDoctors(this.state.currentDoctor)
                                }
                            >
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            );
        }
    }
}
