import { seedValue } from "faker";
import moment from "moment";
import React, { Component } from "react";
import * as Datetime from "react-datetime";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";

const options = [
  { value: 1, label: "1 - One" },
  { value: 2, label: "2 - Two" },
  { value: 3, label: "3 - Three" },
];

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class AddAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainLoading: false,
      loading: false,
      formData: {
        hospitalId: this.props.userData.hospitalId,
        slot_date: moment().format("DD/MM/YYYY"),
        appointmentType: 1,
      },
      patientId: null,
      appointmentType: null,
      slot_date: moment().format("DD/MM/YYYY"),
      slot_time: "",
      error: "",
      success: false,
      patients: [],
      doctors: [],
      doctorId: null,
      doctorName: null,
      slots: {},
      selectedSlots: [],
    };
  }

  onChange = (e) => {
    let formObj = { ...this.state.formData };
    formObj[e.target.name] = e.target.value;
    this.setState({
      formData: formObj,
    });
  };

  onSelect = (val, name) => {
    console.log("val-->", val);
    let formObj = { ...this.state.formData };
    formObj[name] = val.value;
    if (name == "patientId") {
      formObj["name"] = val.label || "";
      formObj["phoneno"] = val.phoneno || "";
      formObj["country_code"] = val.country_code || "";
      formObj["email"] = val.email || "";
      formObj["patient_email"] = val.email || "";
    }
    if (name == "doctorId") {
      formObj["doctor_email"] = val.email;
      formObj["doctorName"] = val.label;
      formObj["base_fee"] = val.base_fee;

      this.fetchSchedule(val.value);
    }
    this.setState({
      formData: formObj,
      [name]: val,
    });
  };

  fetchDoctors() {
    let API = new Apilib();
    API.getUsers(3, this.props.userData.hospitalId)
      .then((result) => {
        let res = result.data;

        if (this._isMounted) {
          this.setState({
            doctors: res.doctors,
            mainLoading: false,
          });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({});
        }
      });
  }

  fetchSchedule(value) {
    console.log("value", value);
    let aestTimeTo = new Date().toLocaleString("en-US", {
      timeZone: "Australia/Brisbane",
    });
    let from = moment(aestTimeTo, "M/D/YYYY, H:mm:ss a").valueOf();
    from = from / 1000;
    let to = "";
    let API = new Apilib();
    API.listSchedule(value, from, to)
      .then((result) => {
        let res = result.data;

        if (this._isMounted) {
          if (res.code == 200) {
            let slotsArr = [];
            let slots = [];
            let daysTxt = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ];
            let daysNum = [];
            console.log("response", res.data);
            if (res.data) {
              res.data.map((value, index) => {
                daysNum.push(daysTxt.indexOf(value.day));
              });
              slotsArr = res.data.filter((value, map) => {
                return (
                  moment(value.date, "DD/MM/YYYY").day() ===
                  moment(this.state.slot_date, "DD/MM/YYYY").day()
                );
              });

              if (slotsArr.length > 0) {
                slotsArr[0].slots
                  .filter((slot) => {
                    return (
                      !slot.booked &&
                      moment(new Date()).isSameOrBefore(
                        moment(slot.time, "hh:mm a")
                      )
                    );
                  })
                  .map((value, index) => {
                    slots.push({ value: value.time, label: value.time });
                    //   console.log("Slots ::", value.label, value.time);
                  });
              }
              // console.log("Slots state ::", this.state.slots);
              // console.log("Slots ::", slots);
            }

            // console.log("state.formdate::", this.state.formData);

            this.setState({
              daysNum: daysNum,
              selectedSlots: slots,
              slots: res,
              mainLoading: false,
            });
          } else if (res.code == 202) {
            this.setState({
              error: "No schedule added, please add schedule first",
              mainLoading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({
            mainLoading: false,
          });
        }
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      mainLoading: true,
    });
    let API = new Apilib();
    API.getUsers(5, this.props.userData.hospitalId)
      .then((result) => {
        let res = result.data;

        if (this._isMounted) {
          this.setState(
            {
              patients: res.patients,
            },
            () => {
              this.fetchDoctors();
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({});
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = (e) => {
    e.preventDefault();
    console.log("slot", this.state.slots.slot_time.split(":")[0] * 60000);

    let baseState = { ...this.state.formData };
    this.setState({
      error: "",
      loading: true,
    });
    let API = new Apilib();
    let data = {
      ...baseState,
      created_at: Date.parse(new Date()),
      from: moment(
        baseState.slot_date + " " + baseState.slot_time,
        "DD/MM/YYYY h:mm a"
      ).valueOf(),
      to:
        moment(
          baseState.slot_date + " " + baseState.slot_time,
          "DD/MM/YYYY h:mm a"
        ).valueOf() +
        this.state.slots.slot_time.split(":")[0] * 60000,
      for_family_member: false,
      userId: this.props.userData.uid,
      userType: this.props.userData.type.toString(),
    };

    API.addAppointment(JSON.stringify(data))
      .then((result) => {
        let res = result;
        this.setState({
          loading: false,
        });

        this.setState({
          loading: false,
          formData: {},
          patientId: null,
          appointmentType: null,
          slot_date: moment().format("DD/MM/YYYY"),
          slot_time: "",
          error: "",
          success: true,
        });
        Notify({
          alert: true,
          type: "success",
          title: "Appointment Created Successfully",
        });
        this.props.history.push("/receptionist/appointments");
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });

    // if (res.ok) {
    //   res.text().then((data) => {
    //     console.log("add apointment res--->", data);
    //     if (JSON.parse(data).code == 204) {
    //       Notify({
    //         alert: true,
    //         type: "error",
    //         title: JSON.parse(data).message,
    //       });
    //     } else {

    //     }
    //   });
    // } else {
    //   res.text().then((data) => {
    //     this.setState({
    //       error: JSON.parse(data).message,
    //     });
    //   });
    // }
  };

  onDateSelect(value) {
    let formObj = { ...this.state.formData };
    formObj["slot_date"] = value.format("DD/MM/YYYY");
    formObj["slot_time"] = null;

    if (Object.keys(this.state.slots).length > 0) {
      let filteredSlots = this.state.slots.data.filter((slot) => {
        return (
          slot.date === value.format("DD/MM/YYYY") ||
          moment(value.format("DD/MM/YYYY"), "DD/MM/YYYY").day() ===
            moment(slot.date, "DD/MM/YYYY").day()
        );
      });
      if (filteredSlots.length === 0) {
        Notify({
          alert: true,
          type: "error",
          title: "No Slots available",
        });
        this.setState({
          slot_date: value.format("DD/MM/YYYY"),
          slot_time: null,
          formData: formObj,
          selectedSlots: [],
        });
      } else {
        this.setState({
          slot_date: value.format("DD/MM/YYYY"),
          slot_time: null,
          formData: formObj,
          selectedSlots: filteredSlots[0].slots
            .filter((slot) => {
              if (
                moment(new Date()).format("DD/MM/YYYY") ===
                value.format("DD/MM/YYYY")
              ) {
                return (
                  !slot.booked &&
                  moment(new Date()).isSameOrBefore(
                    moment(slot.time, "hh:mm a")
                  )
                );
              }
              return !slot.booked;
            })
            .map((slot) => ({
              value: slot.time,
              label: slot.time,
            })),
        });
      }
    }
  }

  render() {
    let patients = [];
    let doctors = [];

    const options = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];

    const appointment_types = [
      { value: "1", label: "Physical" },
      { value: "2", label: "Video Conference" },
    ];

    if (this.state.patients.length) {
      this.state.patients.map((item, i) => {
        patients.push({
          value: item.uid,
          label: item.firstName + " " + item.lastName,
          phoneno: item.phoneno,
          country_code: item.country_code,
          email: item.email,
        });
      });
    }

    if (this.state.doctors.length) {
      this.state.doctors.map((item, i) => {
        doctors.push({
          value: item.uid,
          label: item.firstName + " " + item.lastName,
          email: item.email,
          base_fee: item.base_fee,
        });
      });
    }

    if (this.state.mainLoading) {
      return <Loader />;
    } else {
      let slotsArr = [];
      let slots = [];
      let daysTxt = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let daysNum = [];
      if (this.state.slots.data) {
        this.state.slots.data.map((value, index) => {
          daysNum.push(daysTxt.indexOf(value.day));
        });
        slotsArr = this.state.slots.data.filter(
          (value, map) => value.date == this.state.slot_date
        );
        if (slotsArr.length) {
          slotsArr[0].slots
            .filter((slot) => {
              return (
                !slot.booked &&
                moment(new Date()).isSameOrBefore(moment(slot.time, "hh:mm a"))
              );
            })
            .map((value, index) => {
              slots.push({ value: value.time, label: value.time });
            });
        }
      }
      let yesterday = Datetime.moment().subtract(1, "day");
      let afterMonth = Datetime.moment().add(30, "day");
      let valid = function (current) {
        return (
          current.isAfter(yesterday) &&
          current.isBefore(afterMonth) &&
          daysNum.includes(current.day())
        );
      };
      return (
        <React.Fragment>
          {this.state.loading && <Loader />}
          <div className="row">
            <div className="col-md-12">
              <h4 className="page-title">Add Appointment</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={(e) => this.onSubmit(e)}>
                {this.state.error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {this.state.error}
                  </div>
                )}
                {this.state.success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    Appointment added successfully
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Patient Name <span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={this.state.patientId}
                        onChange={(item) => this.onSelect(item, "patientId")}
                        options={patients}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Doctor <span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={this.state.doctorId}
                        onChange={(item) => this.onSelect(item, "doctorId")}
                        options={doctors}
                      />
                    </div>
                  </div>
                  {this.state.doctorId ? (
                    <>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Date <span className="text-danger">*</span>
                          </label>
                          {/* <input name="date" min={moment().format('YYYY-MM-DD')} value={this.state.date} onChange={(e) => this.onChange(e)} required className="form-control" type="date" /> */}
                          <Datetime
                            isValidDate={this.valid}
                            closeOnSelect={true}
                            dateFormat="DD/MM/YYYY"
                            onChange={(val) => {
                              if (typeof val === "object") {
                                this.onDateSelect(val);
                              }
                            }}
                            value={this.state.slot_date}
                            inputProps={{ required: true }}
                            input={true}
                            timeFormat={false}
                            viewMode="days"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Available Slots{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            required
                            value={this.state.slot_time}
                            onChange={(item) =>
                              this.onSelect(item, "slot_time")
                            }
                            options={this.state.selectedSlots}
                            placeholder={
                              this.state.selectedSlots.length
                                ? "Select a slot"
                                : "No slots available"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Appointment Type{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            required
                            value={this.state.appointmentType}
                            onChange={(val) =>
                              this.onSelect(val, "appointmentType")
                            }
                            isSearchable={false}
                            options={appointment_types}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Patient Email</label>
                          <input
                            className="form-control"
                            value={this.state.formData.email || ""}
                            type="email"
                            name="patientemail"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Patient Phone Number</label>
                          <input
                            className="form-control"
                            value={this.state.formData.phoneno || ""}
                            type="text"
                            name="patientPhoneNumber"
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-12">
                        <div className="form-group">
                          <label>Chief Complaints</label>
                          <textarea
                            name="chiefComplaints"
                            onChange={(e) => this.onChange(e)}
                            className="form-control"
                            value={this.state.formData.chiefComplaints || ""}
                          >
                            {this.state.formData.chiefComplaints || ""}
                          </textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Relevant points from history</label>
                          <textarea
                            name="pointsFromHistory"
                            onChange={(e) => this.onChange(e)}
                            className="form-control"
                            value={this.state.formData.pointsFromHistory || ""}
                          >
                            {this.state.formData.pointsFromHistory || ""}
                          </textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Examination / Lab Findings</label>
                          <textarea
                            name="labFindings"
                            onChange={(e) => this.onChange(e)}
                            className="form-control"
                            value={this.state.formData.labFindings || ""}
                          >
                            {this.state.formData.labFindings || ""}
                          </textarea>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Suggested Investigations</label>
                          <textarea
                            name="suggestedInvestigations"
                            onChange={(e) => this.onChange(e)}
                            className="form-control"
                            value={
                              this.state.formData.suggestedInvestigations || ""
                            }
                          >
                            {this.state.formData.suggestedInvestigations || ""}
                          </textarea>
                        </div>
                      </div> */}
                    </>
                  ) : null}
                </div>
                {this.state.doctorId ? (
                  <div className="m-t-20 text-center">
                    <button className="btn btn-primary submit-btn">
                      Add Appointment
                    </button>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
