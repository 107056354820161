import React, { useEffect, useState } from 'react';
import Progress from '../Loader/Progress';
import prescription1Img from '../../../src/assets/img/prescription1.png';
import newcustomer from '../../../src/assets/img/new-customer.png';
import newsupplier from '../../../src/assets/img/new-supplier.png';
import newmedicine from '../../../src/assets/img/new-medicine.png';
import newinvoice from '../../../src/assets/img/new-invoice.png';
import genicon from '../../../src/assets/img/genicon.png';
import styled from 'styled-components';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LineChart } from 'recharts';
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import Apilib from "../../api";
import moment from 'moment';

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius:45px;
  cursor: pointer;
  width:100%;
  margin:10px;
`;
const ButtonOrange = styled.button`
  background-color: #FC700A;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius:45px;
  cursor: pointer;
  width:100%;
  margin:10px;
`;
const ButtonNavigate = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width:100%;
  margin:10px;
  text-align-last:start;
`;

const data = [
    { name: 'Sun', orders: 31, },
    { name: 'Mon', orders: 18, },
    { name: 'Tue', orders: 21, },
    { name: 'Wed', orders: 28, },
    { name: 'Thu', orders: 55, },
    { name: 'Fri', orders: 8, },
    { name: 'Sat', orders: 3, },
];
// name
// expiry
// quantity
// price
const expiredData = [
    { name: 'Uromax- D', expiryDate: '27/8/2023', quantity: 30, price: 90 },
    { name: 'Pantronix 20', expiryDate: '3/8/2023', quantity: 15, price: 100 },
    { name: 'Bextram Gold', expiryDate: '15/8/2023', quantity: 18, price: 150 },
    { name: 'Pronex 20', expiryDate: '12/8/2023', quantity: 8, price: 130 },
    { name: 'Ticamet 250', expiryDate: '13/8/2023', quantity: 3, price: 35 },
    { name: 'Trilock 10', expiryDate: '11/8/2023', quantity: 1, price: 141 },
];
const ordersData = [
    { name: 'Uromax- D', batchNumber: '1213181', quantity: 30, price: 90 },
    { name: 'Bextram Gold', batchNumber: '1213181', quantity: 18, price: 150 },
    { name: 'Trilock 10', batchNumber: '1213181', quantity: 1, price: 141 },
    { name: 'Pronex 20', batchNumber: '1213181', quantity: 20, price: 80 },
    { name: 'Pantronix 20', batchNumber: '1213181', quantity: 8, price: 130 },
    { name: 'Ticamet 250', batchNumber: '1213181', quantity: 3, price: 35 },
];
const drugStock = [
    { name: 'Sun', outOfStock: 31, expired: 28, stocked: 41, },
    { name: 'Mon', outOfStock: 18, expired: 11, stocked: 48, },
    { name: 'Tue', outOfStock: 21, expired: 11, stocked: 31, },
    { name: 'Wed', outOfStock: 28, expired: 38, stocked: 12, },
    { name: 'Thu', outOfStock: 55, expired: 25, stocked: 15, },
    { name: 'Fri', outOfStock: 8, expired: 18, stocked: 13, },
    { name: 'Sat', outOfStock: 3, expired: 13, stocked: 31, },
];
const medicationManagement = [
    {
        patientName: 'Patient 1', medicines: [{ name: 'Ram', doctorName: "ram Borawat", prescriptionDate: '28/7/2022', doseSchedule: 'in morning(with food)', numberOfDose: 3, Note: 'take after Meal' },
        { name: 'Shayam', doctorName: "George", prescriptionDate: '2/7/2022', doseSchedule: 'in morning(before food)', numberOfDose: 3, Note: 'take with warm water' },]
    },
    {
        patientName: 'Patient 2', medicines: [{ name: 'Ram', doctorName: "ram Borawat", prescriptionDate: '28/7/2022', doseSchedule: 'in morning(with food)', numberOfDose: 3, Note: 'take after Meal' },
        { name: 'Shayam', doctorName: "George", prescriptionDate: '2/7/2022', doseSchedule: 'in morning(before food)', numberOfDose: 3, Note: 'take with warm water' },]
    }

];

const NewButton = ({ name, onHit, percentage }) => <Button onClick={onHit}>
    <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div>

            <img
                src={prescription1Img}
                alt="new"
                style={{ width: '40px', height: '40px' }}
            />

        </div>
        <div style={{ fontSize: '16px', margin: '5px', color: '#fff', textAlign: 'left', width: '80%' }}>

            {name}
            <div style={{ fontSize: '10px', width: '100%' }}>
                <Progress bgcolor="#00ff47" progress={percentage} />
            </div>
            <div style={{ fontSize: '10px', marginTop: '5px', color: '#fff', textAlign: 'left' }}>
                {percentage}% increase
            </div>
        </div>


    </div>

</Button>

const NewButtonOrange = ({ name, onHit, percentage }) => <ButtonOrange onClick={onHit}>
    <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div>

            <img
                src={prescription1Img}
                alt="new"
                style={{ width: '40px', height: '40px' }}
            />

        </div>
        <div style={{ fontSize: '16px', margin: '5px', color: '#fff', textAlign: 'left', width: '80%' }}>

            {name}
            <div style={{ fontSize: '10px', width: '100%' }}>
                <Progress bgcolor="#00ff47" progress={percentage} />
            </div>
            <div style={{ fontSize: '10px', marginTop: '5px', color: '#fff', textAlign: 'left' }}>
                {percentage}% increase
            </div>
        </div>


    </div>

</ButtonOrange>

const NavigateButton = ({ title, image, clicked }) => <ButtonNavigate onClick={clicked}>
    <img
        src={image}
        alt="new"
        style={{ width: '45px', height: '45px', marginBottom: '15px' }}
    />
    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
        <div style={{ fontSize: '18px', textAlign: 'left', width: '60%' }}>{title}</div>
        <div>
            <i className="fa fa-arrow-right" style={{ fontWeight: '100', color: '#fff' }}></i>
        </div>
    </div>

</ButtonNavigate>

const OrderDetail = ({ title, subTitle, title1, subtitle1, history }) => <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', margin: '5px', cursor: 'pointer' }} onClick={() => history.push(
    `/pharmacy/orders/order-details/${subTitle}/${subTitle}`
)}>
    <img
        src={genicon}
        alt="new"
        style={{ width: '45px', height: '45px', margin: '5px' }}
    />
    <div>
        <div>{title}</div>
        <div>{subTitle}</div>
    </div>
    <div>
        <div>{title1}</div>
        <div>{subtitle1}</div>
    </div>
    <i className="fa fa-arrow-right" style={{ color: '#0fbFB8', fontSize: '21px', alignSelf: 'center' }}></i>
</div>

const iterate = [
    [
        { name: 'Total Orders' },
        { name: 'Pending Orders' },
        { name: 'Revenue' },
    ],
    [
        { name: 'Total Patients' },
        { name: 'Expired Drugs' },
        { name: 'Drug out of Stock' }
    ],
    [
        { name: 'Stocked Drugs' },
        { name: 'Total Pharmacies' },
        { name: 'Total Pharmacy Clerk' }
    ],
    [
        { name: 'Total Doctors' },
        { name: 'New Patients Admited' },
        { name: 'Total Patients Admited' }
    ],

]
const PharmacistDashboard = (route) => {
    const history = useHistory();

    const [allOrders, setAllOrders] = useState(null)
    const [medicineList, setmedicineList] = useState(null)
    const [medicineManage, setmedicineManage] = useState(null)
    const [recentManage, setrecentManage] = useState(null)

    // const expiredData = [
    //     { name: 'Uromax- D', expiryDate: '27/8/2023', quantity: 30, price: 90 },
    //     { name: 'Pantronix 20', expiryDate: '3/8/2023', quantity: 15, price: 100 },
    //     { name: 'Bextram Gold', expiryDate: '15/8/2023', quantity: 18, price: 150 },
    //     { name: 'Pronex 20', expiryDate: '12/8/2023', quantity: 8, price: 130 },
    //     { name: 'Ticamet 250', expiryDate: '13/8/2023', quantity: 3, price: 35 },
    //     { name: 'Trilock 10', expiryDate: '11/8/2023', quantity: 1, price: 141 },
    // ];

    useEffect(() => {
        let API = new Apilib();
        console.log(route, 'this is route')


        let data = { pharmacyId: route.userData.uid }
        API.getAllMedicines(data)
            .then(async (res) => {
                console.log(res.data.medicines[0].medicines)
                console.log('this is response medicines')
                let sorted = []

                res.data.medicines[0].medicines.map(x => {
                    let details = x
                    details.itemName = x.Medicine_Name
                    if (route.userData.uid == details.pharmacyId) sorted.push(details)
                })
                setmedicineList(sorted)
            })
            .catch((error) => {
                setmedicineList([])
                console.log('error', error)
            });
    }, [])

    useEffect(() => {
        //get all orders by pharmacy id: use api.getordersbypharmacyID

        let getAllOrders = async () => {
            let API = new Apilib();


            let temp = []
            let recent = []
            API.getAllPharmacyOrder({ pharmacy_id: route.userData.uid })
                .then(async (res) => {
                    let newone = []
                    let recent = []
                    console.log(res?.data?.data.length == 0)
                    if (res?.data?.data.length == 0) {
                        setrecentManage([])
                        setmedicineManage([])
                        setAllOrders([]);
                        return
                    }
                    res?.data?.data.map(x => {
                        console.log('individual')
                        console.log(x)


                        let compareDate = moment(x.date);
                        let startDate = moment().subtract(1, 'months');
                        let endDate = moment();

                        let shouldPushed = compareDate.isBetween(startDate, endDate);

                        let pro = {
                            orderId: x.orderId,
                            patientName: x.patientName,
                            doctorName: x?.prescribedBy ? x.prescribedBy : "NA",
                            date: x.date,
                            cost: x.cost,
                            orderStatus: x.orderStatus,
                        }
                        temp.push(pro)
                        // allOrdersTemp = temp

                        let theMed = []
                        x.prescription.map(y => {
                            let ob = { name: y.Medicine_Name, doctorName: x?.prescribedBy ? x.prescribedBy : "NA", prescriptionDate: moment(x.date).format('DD-MM-YYYY'), doseSchedule: 'NA', numberOfDose: y.qty, Note: 'NA' }
                            theMed.push(ob)
                            if (shouldPushed) {
                                let robj = { name: y.Medicine_Name, batchNumber: y.Batch_Number, quantity: y.qty, price: y.Price }


                                const existingItem = recent.find((item) => {
                                    return y.Medicine_Name === item.name;
                                });

                                if (existingItem) {
                                    existingItem.quantity++;
                                } else {
                                    // Push the item into the cart
                                    recent.push(robj)
                                }

                            }
                        })
                        let obj = {
                            patientName: x.patientName, medicines: theMed
                        }
                        setrecentManage(recent)
                        newone.push(obj)

                        // prescription


                    })
                    setmedicineManage(newone)
                    console.log(newone)
                    setAllOrders(temp);
                })
                .catch((error) => {

                    setrecentManage([])
                    setmedicineManage([])
                    setAllOrders([]);

                    console.log('error order', error)
                });

        };
        getAllOrders();
    }, []);

    return (
        <div style={{ backgroundColor: '#EAFCFF' }}>


            {/* {
                iterate.map(x => {
                    return <div style={{ display: 'flex' }}>
                        <NewButton name={x[0].name} onHit />
                        <NewButton name={x[1].name} onHit />
                        <NewButtonOrange name={x[2].name} onHit />
                    </div>
                })
            } */}


            <div style={{ display: 'flex' }}>
                <NewButton name={'Total Orders'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    history.push('/pharmacy/orders')
                }} />
                <NewButton name={'Pending Orders'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    history.push('/pharmacy/orders')
                }} />
                <NewButtonOrange name={'Revenue'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    history.push('/pharmacy/PharmacyInvoiceList')
                }} />
            </div>
            <div style={{ display: 'flex' }}>
                <NewButton name={'Total Patients'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    history.push('/pharmacy/Patients')
                }} />
                <NewButton name={'Expired Drugs'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    console.log('Expired Drugs')
                }} />
                <NewButtonOrange name={'Drug out of Stock'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    console.log('Drug out of Stock')
                }} />
            </div>
            <div style={{ display: 'flex' }}>
                <NewButton name={'Stocked Drugs'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    console.log('Stocked Drugs')
                    history.push('/pharmacy/Medicines')
                }} />
                <NewButton name={'Total Pharmacies'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    console.log('Total Pharmacies')
                }} />
                <NewButtonOrange name={'Total Pharmacy Clerk'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    console.log('Total Pharmacy Clerk')
                }} />
            </div>
            <div style={{ display: 'flex' }}>
                <NewButton name={'Total Doctors'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    console.log('Total Doctors')
                }} />
                <NewButton name={'New Patients Admited'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    history.push('/pharmacy/Patients')
                }} />
                <NewButtonOrange name={'Total Patients Admited'} percentage={Math.floor(Math.random() * 100) + 1} onHit={() => {
                    history.push('/pharmacy/Patients')
                }} />
            </div>


            <ResponsiveContainer width="100%" height={300}>
                <BarChart width={530} height={250} data={data} margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="orders" stackId="a" fill="#00ccff" />
                    {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
                </BarChart >
            </ResponsiveContainer>

            <div style={{ display: 'flex' }}>

                <NavigateButton title={'Create new invoice'} image={newinvoice} clicked={() => {
                    console.log('Create new invoice')
                    history.push('InvoicePage')
                }} />
                <NavigateButton title={'Create new customer'} image={newcustomer} clicked={() => {
                    console.log('Create new customer')
                    history.push('/pharmacy/add-patient')
                }} />
                <NavigateButton title={'Create new medicine'} image={newmedicine} clicked={() => {
                    console.log('Create new medicine')
                    history.push('AddMedicine')
                }} />
                <NavigateButton title={'Create new supplier'} image={newsupplier} clicked={() => {
                    console.log('Create new supplier')
                    history.push('AddSupplier')
                }} />

            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                        <div style={{ fontSize: '21px' }}>Online Orders</div>
                        <div style={{ alignSelf: 'center', color: '#00ccff', fontWeight: '800', cursor: 'pointer' }} onClick={() => {
                            console.log('clicked view orders')
                            history.push('/pharmacy/orders')
                        }}>{'View All > >'}</div>
                    </div>

                    {/* orderId: x.orderId,
                    patientName: x.patientName,
                    doctorName: x?.prescribedBy ? x.prescribedBy : "NA",
                    date: x.date,
                    cost: x.cost,
                    orderStatus: x.orderStatus, */}
                    {(allOrders != null && allOrders.length == 0) && <div>No orders available</div>}
                    {allOrders && allOrders.map(x => {
                        return <OrderDetail key={Math.random()} title={'Order Number'} subTitle={x.orderId} title1={'Order Status'} subtitle1={x.orderStatus} history={history} />

                    })}
                    {/* <OrderDetail title={'Order Number'} subTitle={'7799298'} title1={'Notes'} subtitle1={'Order from patient'} />
                    <OrderDetail title={'Order Number'} subTitle={'97000461'} title1={'Notes'} subtitle1={'Collect before 5 pm'} /> */}
                </div>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                        <div style={{ fontSize: '21px' }}>Refilling Requests</div>
                        <div style={{ alignSelf: 'center', color: '#00ccff', fontWeight: '800', cursor: 'pointer' }} onClick={() => {
                            console.log('clicked view refilling')
                        }}>{'View All > >'}</div>
                    </div>

                    <OrderDetail title={'Medicine name'} subTitle={'Uromax-D'} title1={'Notes'} subtitle1={'3 Refill'} />
                    <OrderDetail title={'Medicine name'} subTitle={'Pantronix-20'} title1={'Notes'} subtitle1={'8 Refill'} />
                </div>
            </div>


            <ResponsiveContainer width="100%" height={300}>
                <BarChart width={530} height={250} data={drugStock} margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend iconSize={10} layout="horizontal" verticalAlign="top" wrapperStyle={{
                        top: '5%',
                        right: 0,
                        transform: 'translate(0, -50%)',
                        lineHeight: '24px',
                    }} />
                    <Bar dataKey="outOfStock" fill="#27c1d9" />
                    <Bar dataKey="expired" fill="#00ccff" />
                    <Bar dataKey="stocked" fill="#0095AC" />
                    {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
                </BarChart >
            </ResponsiveContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ backgroundColor: '#fff', borderRadius: '10px', width: '50%', margin: '10px' }}>
                    <div style={{ width: '60%', fontSize: '15px', fontWeight: '600', textAlign: 'left' }}>Expiry List</div>
                    {(medicineList != null && medicineList.length == 0) ? <div>No medicines available</div> :
                        <div style={{ backgroundColor: '#fff', borderRadius: '10px', width: '100%', margin: '10px' }}>
                            <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                                <div style={{ textAlign: 'center', width: '40%', fontSize: '13px', fontWeight: '600' }}>Medicine Name</div>
                                <div style={{ textAlign: 'center', width: '20%', fontSize: '13px', fontWeight: '600' }}>Expiry</div>
                                <div style={{ textAlign: 'center', width: '20%', fontSize: '13px', fontWeight: '600' }}>Quantity</div>
                                <div style={{ textAlign: 'center', width: '20%', fontSize: '13px', fontWeight: '600' }}>Price</div>
                            </div>
                            {medicineList && medicineList.map(x => <div style={{ justifyContent: 'space-between', display: 'flex' }} key={Math.random()}>
                                <div style={{ textAlign: 'center', width: '40%' }}>{x.Medicine_Name}</div>
                                <div style={{ textAlign: 'center', width: '20%' }}>{moment(x.ExpDate).format('DD-MM-YYYY')}</div>
                                <div style={{ textAlign: 'center', width: '20%' }}>{x.Quantity}</div>
                                <div style={{ textAlign: 'center', width: '20%' }}>{x.Price}</div>
                            </div>)}
                        </div>}
                </div>
                <div style={{ backgroundColor: '#fff', borderRadius: '10px', width: '50%', margin: '10px' }}>
                    <div style={{ width: '60%', fontSize: '15px', fontWeight: '600', textAlign: 'left' }}>Recent Order's</div>
                    {(recentManage != null && recentManage.length == 0) ? <div>No Recent orders available</div> : <div style={{ backgroundColor: '#fff', borderRadius: '10px', width: '100%', margin: '10px' }}>
                        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ textAlign: 'center', width: '40%', fontSize: '13px', fontWeight: '600' }}>Medicine Name</div>
                            <div style={{ textAlign: 'center', width: '20%', fontSize: '13px', fontWeight: '600' }}>Batch Number</div>
                            <div style={{ textAlign: 'center', width: '20%', fontSize: '13px', fontWeight: '600' }}>Quantity</div>
                            <div style={{ textAlign: 'center', width: '20%', fontSize: '13px', fontWeight: '600' }}>Price</div>
                        </div>
                        {recentManage && recentManage.map(x => <div style={{ justifyContent: 'space-between', display: 'flex' }} key={Math.random()}>
                            <div style={{ textAlign: 'center', width: '40%' }}>{x.name}</div>
                            <div style={{ textAlign: 'center', width: '20%' }}>{x.batchNumber}</div>
                            <div style={{ textAlign: 'center', width: '20%' }}>{x.quantity}</div>
                            <div style={{ textAlign: 'center', width: '20%' }}>{x.price}</div>
                        </div>)}
                    </div>}
                </div>
            </div>


            {medicineManage && medicineManage.map(medicines => <div style={{ width: '100%', margin: '10px' }} key={Math.random()}>
                <div style={{ width: '60%', fontSize: '15px', fontWeight: '600', padding: '10px' }}>{medicines.patientName}</div>

                {medicines.medicines.map(x => <div style={{ justifyContent: 'space-between', display: 'flex', borderLeft: 'solid #00ccff 5px', borderLeftColor: '#00ccff', margin: '3px', alignItems: 'center', backgroundColor: '#fff' }} key={Math.random()}>
                    {/* {name: 'Ram', doctorName: "ram Borawat", prescriptionDate: '28/7/2022', doseSchedule: 'in morning(with food)', numberOfDose: 3, Note: 'take after Meal' } */}
                    <img
                        src={genicon}
                        alt="new"
                        style={{ width: '45px', height: '45px', margin: '5px' }}
                    />
                    <div style={{ width: '16.6%' }}>
                        <div style={{ color: '#000' }}>Name</div>
                        <div>{x.name}</div>
                    </div>
                    <div style={{ width: '16.6%' }}>
                        <div style={{ color: '#000' }}>Doctor</div>
                        <div>{x.doctorName}</div>
                    </div>
                    <div style={{ width: '16.6%' }}>
                        <div style={{ color: '#000' }}>Prescription</div>
                        <div>{x.prescriptionDate}</div>
                    </div>
                    <div style={{ width: '16.6%' }}>
                        <div style={{ color: '#000' }}>Dose Schedule</div>
                        <div>{x.doseSchedule}</div>
                    </div>
                    <div style={{ width: '16.6%' }}>
                        <div style={{ color: '#000' }}>No. Of Dose</div>
                        <div>{x.numberOfDose}</div>
                    </div>
                    <div style={{ width: '16.6%' }}>
                        <div style={{ color: '#000' }}>Note</div>
                        <div>{x.Note}</div>
                    </div>
                </div>)}
            </div>)}



        </div>
    );
}

export default PharmacistDashboard;
