import React, { Component } from "react";

export default class PrivacyPolicyAus extends Component {
  render() {
    return (
      <section className="section section-padding">
        <div className="section-header2">
          <h3 className="header-title text-center">AUSTRALIAN PRIVACY POLICY</h3>
          <div className="line"></div>
          <div class="policy">
            <p>
            Medvatic  Pty Ltd provides health monitoring services for patients and allows healthcare providers to manage post consultation care of their patients. We are therefore committed to safeguarding your privacy and any personal information that we collect from you. We do this by ensuring that we adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) and by taking additional measures to ensure safe and ethical handling of information when it’s in our care.
            </p>
            <ol>
              <li>
                <strong>What personal information is collected?</strong>
              </li>
            </ol>
            <ul>
              <li>
              Medvatic collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website and when we provide our services to you. 
              </li>
            </ul>

            <ul>
              <li>
              We may also receive personal information about you from third parties, such as your healthcare providers. 
              </li>
            </ul>

            <ul>
              <li>
              The kinds of personal information collected and held by us will typically include your full name and basic contact information, such as your postal address, email address and phone number. Where relevant to the services you are accessing, we may also collect your payment or credit card details, date of birth, gender and employment type. 
              </li>
            </ul>

            <ul>
              <li>
              We may also collect your health information to provide our services to you, for example, to offer digital health records, treatment plans and health monitoring services. Health information that we collect includes (but is not limited to) information such as your heart rate, blood pressure, glucose levels, fitness levels, sleep behaviour, prescriptions, treatments and diagnoses, including diagnostic imaging and test results. 
              </li>
            </ul>

            <ol start="2">
              <li>
                <strong>How we use your personal information</strong>
              </li>
            </ol>
            <ul>
              <li>
              Medvatic may use personal information collected from you to provide you with our products or services, communicate information or updates about our products or services to you and to process your enquiries, payments and orders. 
              </li>
            </ul>

            <ul>
              <li>
              We may also make you aware of new and additional products, services and opportunities available to you where you have consented to receive such communications. 
              </li>
            </ul>

            <ul>
              <li>
              We use aggregate data to understand and improve how people use our products and services.
              </li>
            </ul>

            <ol start="3">
              <li>
                <strong>Disclosure of your personal information</strong>
              </li>
            </ol>
            <ul>
              <li>Medvatic may disclose your personal information to its agents, suppliers or subcontractors to the extent necessary for the purposes set out in this Policy. This personal information is only supplied to a third party when it is required for the delivery of our services.</li>
            </ul>

            <ul>
              <li>We may from time to time also need to disclose your personal information to insurers or professional advisers, or to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request.</li>
            </ul>

            <ul>
              <li>If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer our user databases, together with any personal information and non-personal information contained in those databases. </li>
            </ul>



            <ol start="4">
              <li>
                <strong>Security of your personal information</strong>
              </li>
            </ol>
            <ul>
              <li>Medvatic is committed to ensuring that the information you provide to us is kept secure. We do not store any of your personal information on either the mobile application, or our website, <a href="https://medvatic.com/#/">medvatic.com</a>. </li>
            </ul>
            <ul>
              <li>We store your personal information with a third-party storage provider, which has suitable physical, electronic and operational access controls in place to secure information, monitor and protect it from unauthorised access, disclosure, loss, misuse, interference or modification. </li>
            </ul>
            <ul>
              <li>Whilst we have implemented operational measures to safeguard against unauthorised access to or disclosure of personal information, we cannot assure you that personal information that you transmit to us via the internet will also be secure during transit. </li>
            </ul>
            <ul>
              <li>Records containing your personal information will be retained for seven (7) years, in accordance with our Document Retention and Destruction Policy.</li>
            </ul>
            <ul>
              <li>If your personal information is no longer required by us for any purpose for which it was collected and is no longer required by law to be retained by us, then we will destroy or de-identify the information.</li>
            </ul>

            <ol start="5">
              <li>
                <strong>Access to your personal information</strong>
              </li>
            </ol>
            <ul>
              <li>Medvatic takes care to ensure that the personal information it holds about you is accurate, complete and up-to-date. </li>
            </ul>
            <ul>
              <li>If you would like a copy of the personal information that we hold about you or you believe that any information we hold about you is inaccurate, out-of-date, incomplete or irrelevant, please email us at privacy@medvatic.com.au. A small administrative fee may be payable for the provision of information.</li>
            </ul>

            <ol start="6">
              <li>
                <strong>
                Complaints about privacy
                </strong>
              </li>
            </ol>
            <ul>
              <li>
              If you have any complaints about our privacy practices, please email us at privacy@medvatic.com.au or write to us at 10/3 Bowman St Jamison Centre Macquarie Australian Capital Territory, 2614. We take complaints very seriously and will respond within 14 days
              </li>
            </ul>


            <ol start="7">
              <li>
                <strong>Changes to Privacy Policy:</strong>
              </li>
            </ol>
            <ul>
              <li>
              Please be aware that we may modify this Privacy Policy at any time, and our most up to date Privacy Policy will always be available on our website. See here: 
              </li>
              
            </ul>

            <ol start="8">
              <li>
                <strong>Cookies and non-identifiable information</strong>
              </li>
            </ol>
            <ul>
              <li>
              Medvatic uses cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. 
              </li>
            </ul>

            <ul>
              <li>
              Most web browsers automatically accept cookies, but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website.  
              </li>
            </ul>

            <ul>
              <li>
              Our website may from time to time use cookies to analyse website traffic and help us provide a better visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google Adwords. These ads may appear on this website or other websites you visit.
              </li>
            </ul>

            <ul>
              <li>
              Our website server also automatically collects non-identifiable data about your browsing of our website, including your computer’s server address and/ or top level domain name, date and time of access to the site, pages accessed and documents downloaded, the previous site visited and the type of browser software in use. We use this data to analyse how people use our website, so that we can improve it. 
              </li>
            </ul>

            <ol start="9">
              <li>
                <strong>Third parties</strong>
              </li>
            </ol>
            <ul>
              <li>
              Our website, <a href="https://medvatic.com/#/">medvatic.com</a>, may have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third party websites do not constitute sponsorship or endorsement or approval of these websites. 
              </li>
            </ul>
            <ul>
              <li>
              Similarly, our services may be used by third parties (such as healthcare providers and others) to support the provision of healthcare services and communicate with you. 
              </li>
            </ul>
            <ul>
              <li>
              Medvatic is not responsible for the privacy practices of third parties to whom we link or others who adopt our products or services in the provision of healthcare. 
              </li>
            </ul>
            <ul>
              <li>
              We encourage our users to be aware and to read the privacy policies of the websites and third parties that collect and handle your personal information.
              </li>
            </ul>
            <br></br>
            <ul style={{listStyle:"none"}} start="10">
              <li>
                <strong>Date:20 January 2020</strong>
              </li>
            </ul>

          </div>
        </div>
      </section>
    );
  }
}