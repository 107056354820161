// jshint esversion:6

import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { Link, Route, useParams } from "react-router-dom";
import { storage } from "../../firebase";
import "./style.css";
import Axios from "axios";
import { useStateValue } from "../../StateProvider";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import SearchBar from "./SearchBar";
import LocationBar from "./LocationBar";
import GoogleMap from "../Agora/utils/GoogleMap";

const SearchResults = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [{ lat, long }, dispatch] = useStateValue();
  const [noResults, setNoResults] = useState(false);
  const [pp_url, setpp_url] = useState("");
  const [geoLocation, setGeoLocation] = useState();

  if (!props.location.search) {
    props.history.push("/");
  }

  useEffect(() => {

    if (lat && long) {
      const urlParams = new URLSearchParams(props.location.search);
      const search = urlParams.get("search");
      setSearchQuery(search);
    }
  }, [lat, long, props.location.search]);

  useEffect(() => {
    if (searchQuery) {

      setNoResults(false);
      setLoading(true);
      Axios.get(
        "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/searchForAppointment?name=" + searchQuery + "&lat=" + lat + "&longitude=" + long

        // "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/searchDoctorsInRegion?name=" +
        //   searchQuery +
        //   "&lat=" +
        //   lat +
        //   "&longitude=" +
        //   long
      )
        .then((res) => {

          let dataAPI = [];
          dataAPI = res.data.result;
          setLoading(false);
          if (dataAPI.length === 0) {
            setSearchResult([]);
            setNoResults(true);

          } else {
            if (noResults) {
              setSearchResult([]);
            }
            setSearchResult(dataAPI);
          }
        })
        .catch((err) => {
          setLoading(false);

          setSearchResult([]);
          setNoResults(false);
        });
    }
  }, [searchQuery]);

  // async function makeDownloadUrl(id, type) {
  //   let imageUrl = null;
  //   
  //   let userType;
  //   if (type == 5) {
  //     userType = "patients";
  //   } else if (type == 3) {
  //     userType = "doctors";
  //   } else if (type == 4) {
  //     userType = "receptionist";
  //   }
  //   const path = `profile_images/Fdoctors/${id}`;
  //   let gsReference = storage.refFromURL(
  //     "gs://medvatic-438af.appspot.com/" + path
  //   );
  //   await gsReference
  //     .getDownloadURL()
  //     .then((url) => {
  //       console.log("chat user img", url);
  //       imageUrl = url
  //       setpp_url(url);
  //     })
  //     .catch((err) => {
  //       console.log("chat user err", err);
  //     });
  //   if (imageUrl === null) return null
  //   return imageUrl
  // }

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    <>
      <div className="container">
        {/* <div className="search-bar">
          <>
            <div className="location-bar">
              <GoogleMap
                style={{ width: 100 }}
                value={geoLocation}
                onChange={(e) => {}}
                getAddress={(address) => {
                  setGeoLocation(address);
                }}
              />
            </div>
            <div>
              <SearchBar />
            </div>
          </>
        </div> */}
      </div>
      <div className="main-content account-content">
        <script src="https://use.fontawesome.com/cee0220b65.js"></script>
        {loading ? <Loader /> : null}
        <div className="content">
          <div className="container">
            <div className="content">
              <div className="row">
                <div className="col-md-12 appointment-cards">
                  {noResults && <h3>No search result found!</h3>}
                  {searchResult.length !== 0 ? (
                    searchResult.map((item) => {
                      let imageUrl = item.profile_picture

                      // let imageUrl = await makeDownloadUrl(item.uid, item.type);
                      return (
                        <div className="card mb-3">
                          <div className="row">
                            <div className="date col-md-2">
                              {/* <p>Doctor</p>
                              <img src="../../../public/assets/img/Dr_drake_ramoray-.jpg" /> */}
                              <img
                                className="img-fluid"
                                alt=""
                                src={
                                  imageUrl !== null && imageUrl !== undefined
                                    ? imageUrl
                                    : "../assets/img/doctor-06.jpg"
                                }
                              ></img>
                            </div>
                            <div className="col-md-10">
                              <div className="card-body">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="head-card"
                                >
                                  <h5 className="card-title2 m-b-10">
                                    {item.firstName + " " + item.lastName}
                                  </h5>

                                  <div className="appointment-available mt-50">
                                    <a>
                                      <p className="available">
                                        {item.days
                                          ? item.days.includes(
                                            new Date(Date.now()).getDay()
                                          )
                                            ? "Available Today!"
                                            : "Available on " +
                                            item.days.map((day, i) => {
                                              if (i === 0) {
                                                return days[day];
                                              } else {
                                                return " " + days[day];
                                              }
                                            })
                                          : "No schedule available"}
                                      </p>
                                      <Link
                                        to={{
                                          pathname: "/add-appointment",
                                          state: { item },
                                        }}
                                      >
                                        <button
                                          className="btn btn-primary appointment2-btn account-btn"
                                          disabled={!item.days}
                                        >
                                          Book Appointment
                                        </button>
                                      </Link>
                                    </a>
                                  </div>
                                </div>
                                <p className="speciality">
                                  {item.specialities ? (
                                    item.specialities.map((spe, i) => {
                                      if (i === 0) {
                                        return spe;
                                      } else {
                                        return ", " + spe;
                                      }
                                    })
                                  ) : (
                                    <h3>No search result found!</h3>
                                  )}
                                </p>
                                <p className="speciality">
                                  {item.experience} Years of Overall Experience
                                </p>
                                <p className="card-text location">
                                  {/* experience
                                  <br /> */}
                                  {item.hospitalName ? (
                                    item.hospitalName
                                  ) : (
                                    <h3>No search result found!</h3>
                                  )}
                                </p>
                                {/* <p className="card-text speciality">
                                  ₹1800 Consultation fee at clinic
                                </p> */}
                                {/* <hr />
                                <div className="card-text ">
                                  <p className="card-end">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit longer.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : noResults ? null : (
                    <h3>No search result found!</h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
