import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// Prod
const firebaseConfig = {
  apiKey: "AIzaSyAk76hJ6S9JxhWizT4KKI3wGNIZtpDNMak",
  authDomain: "medvatic-438af.firebaseapp.com",
  databaseURL: "https://medvatic-438af.firebaseio.com",
  projectId: "medvatic-438af",
  storageBucket: "medvatic-438af.appspot.com",
  messagingSenderId: "85545573563",
  appId: "1:85545573563:web:6e1445df1d5e0f58ff9d9e",
  measurementId: "G-MN3JS3XR4W"
};

// Dev
// const firebaseConfig = {
//   apiKey: "AIzaSyCKVeT3U4SNnaXqjmovByhrqoK6DkbkXpg",
//   authDomain: "medvatic-dev.firebaseapp.com",
//   projectId: "medvatic-dev",
//   storageBucket: "medvatic-dev.appspot.com",
//   messagingSenderId: "582385254630",
//   appId: "1:582385254630:web:eca34060bec2d7ac04bb27",
//   measurementId: "G-MX16WXXLYG"
// };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export default firebase;