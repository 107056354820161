// jshint esversion:6

import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import Loader from "../Loader/Loader";
import "./style.css";

let allowedFiles = ".pdf,.doc,.docx,.jpg,.png,.jpeg";

export default class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointments: [],
      modalOpen: false,
      fileModalOpen: false,
      modalLoading: false,
      currentAppointment: {},
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      currentIndex: "",
      currentAppId: "",
      success: false,
    };
    this._isMounted = false;
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
      } else {
        this.props.history.push("/login");
      }
    });
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    console.log("propps", this.props);
    let API = new Apilib();
    API.listPatientAppointments(
      this.props.userData.hospitalId,
      this.props.userData.uid
    )
      .then((result) => {
        let res = result.data
        console.log("listPatientAppointments", res, this._isMounted);
        if (this._isMounted) {
          let sorted = res.appointments;
          sorted.sort((a, b) => moment(b.from).diff(moment(a.from)));
          this.setState(
            {
              loading: false,
              appointments: sorted,
            },
            () => {
              console.log(this.state.appointments.slot_date);
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({});
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log("listPatientAppointments err", error);
          this.setState({
            loading: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openVideoCall(e, appointment) {
    e.preventDefault();
    window.open(
      window.location.origin +
      "/#/videocall?meetingid=" +
      appointment.meetingid +
      "&appointmentid=" +
      appointment.appointmentId +
      "&uid=" +
      this.props.userData.uid +
      "&token=" +
      appointment.token,
      "_blank"
    );
  }

  viewFile(e, ref) {
    e.preventDefault();
    storage
      .ref(ref)
      .getDownloadURL()
      .then((path) => {
        window.open(path, "_blank");
      });
  }

  render() {
    console.log("all apointments", this.state.appointments);
    return (
      <>
        <div className="main-content account-content">
          <script src="https://use.fontawesome.com/cee0220b65.js"></script>
          {this.state.loading ? <Loader /> : null}
          <div className="content" style={{ backgroundColor: "transparent" }}>
            <div className="container">
              <div className="content">

                <h5 className="mb-5">My Appointments</h5>
                <div className="row">
                  <div className="col-md-12 appointment-cards">
                    {this.state.appointments.length ? (
                      <>
                        {this.state.appointments &&
                          this.state.appointments.map((appointment, index) => {
                            return (
                              <Link
                                key={index}
                                to={
                                  "my-appointments/" + appointment.appointmentId
                                }
                              >
                                <div
                                  className="card py-3 px-4"
                                  style={{
                                    borderRadius: 20,
                                    overflow: "hidden",
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="d-flex h-100 justify-content-center align-items-center flex-column">
                                        <h5>
                                          {moment(
                                            appointment.slot_date,
                                            "DD/MM/YYYY"
                                          ).format("LL")}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <h5> {appointment.doctorName}</h5>
                                      <div className="d-flex flex-column">
                                        {!!appointment.phoneno && (
                                          <span className="d-flex align-items-center mb-1">
                                            <span
                                              className="p-2 mr-1 d-flex"
                                              style={{
                                                backgroundColor:
                                                  "rgb(249 249 249)",
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "rgb(150 150 150)",
                                                width: "fit-content",
                                              }}
                                            >
                                              <i
                                                class="fa fa-phone"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <span
                                              className="my-1 d-flex"
                                              style={{
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "rgb(129 129 129)",
                                                width: "fit-content",
                                              }}
                                            >
                                              {appointment.phoneno}
                                            </span>
                                          </span>
                                        )}
                                        {!!appointment.doctor_email && (
                                          <span className="d-flex align-items-center mb-1">
                                            <span
                                              className="p-2 mr-1 d-flex"
                                              style={{
                                                backgroundColor:
                                                  "rgb(249 249 249)",
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "rgb(150 150 150)",
                                                width: "fit-content",
                                              }}
                                            >
                                              <i
                                                class="fa fa-envelope"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <span
                                              className="my-1 d-flex"
                                              style={{
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "rgb(129 129 129)",
                                                width: "fit-content",
                                              }}
                                            >
                                              {appointment.doctor_email}
                                            </span>
                                          </span>
                                        )}
                                        {!!appointment.hospital_name && (
                                          <span className="d-flex align-items-center mb-1">
                                            <span
                                              className="p-2 mr-1 d-flex"
                                              style={{
                                                backgroundColor:
                                                  "rgb(249 249 249)",
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "rgb(150 150 150)",
                                                width: "fit-content",
                                              }}
                                            >
                                              <i
                                                class="fa fa-medkit"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <span
                                              className="my-1 d-flex"
                                              style={{
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "rgb(129 129 129)",
                                                width: "fit-content",
                                              }}
                                            >
                                              {appointment.hospital_name}
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex justify-content-start align-items-end h-100 flex-md-column">
                                        {appointment.appointment_status ===
                                          "booked" && (
                                            <span
                                              className="px-2 py-1"
                                              style={{
                                                backgroundColor: "#ffeecc",
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "#cc8800",
                                              }}
                                            >
                                              Booked
                                            </span>
                                          )}
                                        {appointment.appointment_status ===
                                          "completed" && (
                                            <span
                                              className="px-2 py-1"
                                              style={{
                                                backgroundColor: "#cef3fd",
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "#0788ab",
                                              }}
                                            >
                                              Completed
                                            </span>
                                          )}
                                        {appointment.appointment_status ===
                                          "cancelled" && (
                                            <span
                                              className="px-2 py-1"
                                              style={{
                                                backgroundColor: "#f5bfbf",
                                                boxSizing: "border-box",
                                                borderRadius: 5,
                                                fontWeight: "600",
                                                fontSize: "1rem",
                                                color: "#f03232",
                                              }}
                                            >
                                              Cancelled
                                            </span>
                                          )}
                                        <span
                                          className="px-2 py-1 mt-2"
                                          style={{
                                            backgroundColor: "rgb(251 251 251)",
                                            boxSizing: "border-box",
                                            borderRadius: 5,
                                            fontWeight: "600",
                                            fontSize: "1rem",
                                            color: "rgb(115 115 115)",
                                          }}
                                        >
                                          {moment(appointment.from).format(
                                            "LT"
                                          )}{" "}
                                          -{" "}
                                          {moment(appointment.to).format("LT")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {this.state.loading ? null : (
                          <p>No Appointments Available</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
