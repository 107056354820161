import React, { useEffect, useState } from 'react';
import cart from '../../assets/img/cart.png'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRating from './StarRating';
import Apilib from "../../api";

const styless = {
    inputBoxStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '10px',
        margin: '10px',
        // border-color: #eaeaea;
        fontSize: '14px',
        minHeight: '35px',
        width: '30%',
    },
}
const ProductDetails = (route) => {
    const history = useHistory();
    const notify = (text) => toast.success(text);
    const notifys = (text) => toast.error(text);
    const [cartItems, setcartItems] = useState(JSON.parse(localStorage.getItem('cartData'))?.length > 0 ? JSON.parse(localStorage.getItem('cartData')) : [])
    const [items, setitems] = useState(JSON.parse(localStorage.getItem('productDetails')) ? JSON.parse(localStorage.getItem('productDetails')) : null)
    const [rating, setrating] = useState(0)
    const [productInfo, setproductInfo] = useState(0)
    const [cartItemsLength, setcartItemsLength] = useState(0)
    const [isProductBought, setisProductBought] = useState(false)
    useEffect(() => {
        console.log('useeffect executed', cartItems)
        setcartItemsLength(cartItems.length)
        // cartItems.rating.reduce()
        let totalrating = items?.rating?.reduce(function (acc, obj) { return acc + Number(obj.rating) }, 0);
        let countedStars = 0
        console.log(totalrating, items?.rating?.length, 'thiss i s calculation')
        if (items?.rating?.length) countedStars = totalrating / items?.rating?.length
        setrating(countedStars)

    }, [cartItems]);

    function itemExist(arr, arr1) {
        let index = 0
        const { length } = arr;
        const id = length + 1;
        const found = arr.findIndex(el => el.Medicine_Name === arr1.Medicine_Name);
        console.log(found, 'this is found')
        // if (!found) arr.push(arr1);
        return found;
    }


    useEffect(() => {
        let API = new Apilib();

        API.getPatientOrders({ uid: route.userData.uid })
            .then(async (res) => {
                console.log(res)
                let ml = []
                if (!res.data.orders.length) return
                res.data.orders.map(x => {
                    let obj = x.prescription.find(o => {
                        if (o.Medicine_Name === items.Medicine_Name && o.Batch_Number === items.Batch_Number) return o
                    });

                    if (obj) setisProductBought(true)
                })
            })
            .catch((error) => {
                console.log('error', error)
            });

        // sort and create object for each 
    }, []);

    const settingcart = (data) => {

        // Batch_Number: "51263"
        // Dose: "NA"
        // ExpDate: "2023-10-31T06:38:00.000Z"
        // Medicine_Name: "Digital thermometer"
        // MfgDate: "2023-10-01T06:38:00.000Z"
        // Price: "150"
        // Quantity: "10"
        // Units: "5"
        // imageLink: "https://5.imimg.com/data5/SELLER/Default/2021/10/JA/WR/YY/47022212/clinical-digital-thermometer.jpg"
        // type: "Device"
        if (data.qty <= 0) {
            notifys("Please add atleast one quantity!!")
            return
        }
        notify("Item Added into cart!")
        let pr = [...cartItems]
        let itemnow = itemExist(pr, data)
        // data.qty = 1
        if (itemnow == -1) pr.push(data)
        else pr[itemnow].qty = pr[itemnow].qty + data.qty
        console.log(itemnow, 'itemnow herer')

        setcartItems(pr)
        // localStorage.setItem('productDetails', JSON.stringify(items));
        localStorage.setItem('cartData', JSON.stringify(pr));

    }

    const settingcarts = (data, vari) => {
        let pr = [...cartItems]
        let localUpdate = items
        console.log(data, vari, pr)
        let itemnow = itemExist(pr, data)
        if (vari == '-') {
            // notifyN()
            if (localUpdate.qty == 0) localUpdate.qty = 1
            localUpdate.qty = localUpdate.qty - 1
            setitems(prev => ({ ...prev, qty: localUpdate.qty }))
            // if (itemnow == -1) return
            // if (pr[itemnow].qty == 1) pr.splice(itemnow, 1)
            // else pr[itemnow].qty -= 1

        }
        else {
            // notify()
            localUpdate.qty = localUpdate.qty + 1
            setitems(prev => ({ ...prev, qty: localUpdate.qty }))
            // if (itemnow == -1) pr.push(data)
            // else pr[itemnow].qty += 1
        }
        console.log(data, vari, pr)
        // setcartItems(pr)
        // setitems(localUpdate)

        localStorage.setItem('productDetails', JSON.stringify(localUpdate));
        // localStorage.setItem('cartData', JSON.stringify(pr));


    }

    // const items = JSON.parse(localStorage.getItem('productDetails'));
    console.log('the item', items)
    // Batch_Number:"45121253"
    // Dose:"50mg"
    // ExpDate:"2023-12-20T10:38:00.000Z"
    // Medicine_Name:"Lazarus Naturals CBD"
    // MfgDate:"2023-10-01T10:38:00.000Z"
    // Price:"50"
    // Quantity:"10"
    // Units:"10"
    // imageLink:"https://store.nvsglassworks.com/wp-content/uploads/2019/08/Lazarus_Naturals_10_capsules_25mg.png"
    // pharmacyId:"KAoOnOt37RMTwAxsr6sqi1aOler1"
    // type:"Tablet"
    return (
        <div className="homepage">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                newestOnTop={false}
                hideProgressBar
                closeOnClick
                rtl={false}
                theme="light"
            />
            {items && <div style={{ textAlign: '-webkit-center' }}>
                {/* 
                <div
                    className="card"
                    style={{
                        width: '60%',
                        justifyContent: 'space-evenly',
                        padding: '10px',
                        margin: '10px',
                    }}
                    tabIndex={0}> */}
                <div style={{ display: 'flex', justifyContent: 'center', width: '60%', marginTop: '3%' }}>
                    <img src={items.imageLink ? items.imageLink : 'https://w7.pngwing.com/pngs/929/130/png-transparent-herbalism-alternative-health-services-medicine-health-care-health-angle-traditional-medicine-logo.png'} alt="Product image" style={{ width: '200px', height: '200px', }} />
                    {/* </div> */}
                    <div style={{ textAlign: 'left', width: '70%', }}>

                        <p style={{ width: '100%', marginBottom: '3px' }}>{items.type} </p>
                        <p style={{ width: '100%', marginBottom: '3px', color: '#000', fontSize: '13px', }}>Name </p>
                        <p style={{ width: '100%', marginBottom: '3px', color: '#007587', fontSize: '18px', fontWeight: '600' }}>{items.Medicine_Name} </p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            {rating || isProductBought ? <StarRating stars={items?.rating ? rating : '1'} hover={isProductBought} uid={route.userData.uid} name={route.userData.firstName} /> : 'No reviews available'}
                            {items?.rating?.length && `${items?.rating?.length ? items?.rating.length : 'No '} reviews`}
                        </div>
                        <div style={{}}>
                            {/* <p style={{ width: '100%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>Price </p> */}
                            <p style={{ width: '100%', marginBottom: '3px', color: '#41d9ff', fontSize: '19px', fontWeight: '600' }}>${items.Price} In-Stock</p>
                        </div>
                        <div>{items.Description}</div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <div style={{ width: '30px', height: '30px', borderRadius: '6px', textAlign: 'center', backgroundColor: '#007587', color: '#fff', fontSize: '18px', fontWeight: '600' }} onClick={() => {
                                settingcarts(items, '-')
                            }}>-</div>
                            <input
                                // className="form-control col-sm-5 row"
                                name="qty"
                                disabled
                                placeholder={'1'}
                                value={items.qty}
                                // onChange={e => {
                                //     // console.log(index, items, index);
                                //     // setname(e.target.value)
                                //     // settingcart(items)
                                // }}
                                style={styless.inputBoxStyle}
                            />
                            <div style={{ width: '30px', height: '30px', borderRadius: '6px', textAlign: 'center', backgroundColor: '#007587', color: '#fff', fontSize: '18px', fontWeight: '600' }} onClick={() => {
                                settingcarts(items, '+')
                            }}>+</div>

                        </div>

                        <div onClick={() => {
                            console.log('added to cart ' + items)
                            // let cart = cartItems
                            // cart.push(x)
                            // setcartItems(() => cart)
                            settingcart(items)
                            console.log(cartItems)
                        }}>
                            <img src={cart} alt="Product image" style={{ width: '30px', height: '30px', }} />Add to cart
                        </div>
                        {isProductBought && <div style={{ padding: "10px" }}>
                            You can add a review to this product
                        </div>}
                    </div>
                </div>
                <div style={{ display: 'flex', width: '60%', }}>
                    <div style={{ color: productInfo == 0 ? '#41d9ff' : '#000', borderBottom: productInfo == 0 ? 'solid #41d9ff 2px' : null, fontWeight: '800', paddingLeft: '15px' }} onClick={() => {
                        console.log('clicked ', productInfo);
                        setproductInfo(0)
                    }}>More details</div>
                    <div style={{ color: productInfo == 1 ? '#41d9ff' : '#000', borderBottom: productInfo == 1 ? 'solid #41d9ff 2px' : null, fontWeight: '800', paddingLeft: '15px' }} onClick={() => {
                        console.log('clicked ', productInfo);
                        setproductInfo(1)
                    }}>Information</div>
                    <div style={{ color: productInfo == 2 ? '#41d9ff' : '#000', borderBottom: productInfo == 2 ? 'solid #41d9ff 2px' : null, fontWeight: '800', paddingLeft: '15px' }} onClick={() => {
                        console.log('clicked ', productInfo);
                        setproductInfo(2)
                    }}>Reviews</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%', minHeight: '200px' }}>
                    {productInfo == 0 && (items.Description ? <div>{items.Description}</div> : <div>No details available</div>)}
                    {productInfo == 1 && (items.Contents ? <div style={{}}>{items.Contents}</div> : <div>No Information available</div>)}
                    {productInfo == 2 && (rating ? <div style={{ width: '100%' }}>
                        {items?.rating.map(x => <div style={{ textAlign: 'left', borderBottom: 'solid #ccc 1px', margin: '5px', width: '100%' }}>
                            <div style={{ fontSize: '16px' }}> {x.name}</div>
                            <StarRating stars={x.rating ? x.rating : 'No reviews available'} hover={0} />
                            <div>{x.comment}</div>
                        </div>)}

                    </div> : <div>
                        No reviews available
                    </div>)
                    }
                </div>
                {/* </div> */}
            </div>}
            {cartItemsLength && <button className="ChatSupport__cta-btn" onClick={async () => {
                // history.push({ pathName: "/Order", state: { cart: cartItems } })

                // localStorage.setItem('cartData', JSON.stringify(cartItems));
                const items = JSON.parse(localStorage.getItem('cartData'));
                console.log(items, 'this i s otems')
                history.push({
                    pathname: "/EditCart",
                    state: { cart: cartItems },
                });



            }} style={{ position: 'fixed', top: '13%', right: '5px' }}>
                {/* <WhatsAppIcon /> &nbsp; Hi! How can I help? */}
                Checkout {cartItemsLength} items

            </button>}
        </div>
    );
}

export default ProductDetails;
