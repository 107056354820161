import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QAComponent from "../Pharmacy/QAComponent";
import Apilib from "../../api";
import { useHistory, Redirect, useLocation, useParams } from 'react-router-dom'
import invoice from '../../assets/img/new-invoice.png'
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Datetime from "react-datetime";
import RequiredSelect from '../../Helpers/requiredSelect';
import moment from "moment";
import BaseSelect from "react-select";
import Loader from "../Loader/Loader";
function shuffle(a, b) {
  return Math.random() > 0.5 ? -1 : 1;
}
let yesterday = Datetime.moment().subtract(1, "day");
let afterMonth = Datetime.moment().add(30, "years");


function LabTestDetails(route) {

  const Select = (props) => (
    <RequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );

  const faqs = [

    {
      question: 'What services do you offer for online lab tests?', answer: 'We offer a comprehensive range of lab tests, including blood tests, diagnostic imaging, health screenings, and more.You can conveniently schedule and receive your tests from the comfort of your home.'
    },
    {
      question: 'How do I order a lab test online?', answer: 'Ordering a lab test is easy.Simply browse our website, select the test you need, add it to your cart, and proceed to checkout.Follow the instructions to provide necessary details, and your test kit will be shipped to your doorstep'
    },

    {
      question: 'Can I trust the accuracy of online lab results?', answer: 'Yes, our online lab tests are conducted in certified laboratories with a reputation for accuracy and reliability.We adhere to stringent quality standards to ensure the precision of your results.'

    },
    {
      question: 'How long does it take to get my lab results?', answer: 'The turnaround time for lab results varies depending on the type of test.In general, you can expect to receive your results securely online within a few business days after the lab receives your sample.'

    },
    {
      question: 'Is my personal information kept confidential?', answer: 'We prioritize the security and confidentiality of your personal information.Your data is handled with the utmost care, and we strictly adhere to privacy regulations to protect your privacy.'

    },
    {
      question: "Do I need a doctor's prescription to order a lab test online?", answer: 'In many cases, no prescription is required.However, certain tests may have specific requirements.You can check the details on our website or consult with our medical team for guidance.'

    },
    {
      question: 'Can I schedule a lab test for someone else?', answer: "Yes, you can schedule a lab test for someone else.During the ordering process, you'll have the option to provide the necessary details for the individual who will be taking the test."

    },
    {
      question: 'How are lab test samples collected at home?', answer: 'Sample collection is simple and can be done at home.Detailed instructions will be provided with your test kit.Samples, such as blood or saliva, can be collected following the provided guidelines and shipped back to the lab'
    },

    {
      question: 'What payment options are available for online lab tests?', answer: 'We accept various payment methods, including credit cards, debit cards, and other secure online payment options.Your payment information is handled with industry - standard security measures.'

    },
    {
      question: 'Can I cancel or reschedule my lab test appointment?', answer: 'Yes, you can cancel or reschedule your lab test appointment.Please log in to your account or contact our customer support for assistance with any changes to your scheduled tests.'

    },
    {
      question: 'Do you offer expedited or express testing services?', answer: 'Yes, we provide options for expedited testing services.You can choose faster processing times during the checkout process if you need your results sooner.'

    },
    {
      question: 'Are the online lab tests covered by insurance?', answer: 'While we do not directly process insurance claims, you can check with your insurance provider to inquire about reimbursement for the cost of online lab tests.We provide detailed receipts for your convenience.'

    },
    {
      question: 'What precautions are in place for COVID-19 testing?', answer: 'For COVID- 19 testing, we follow strict safety protocols to ensure the safety of our customers and staff.Test kits are designed for at - home collection, minimizing the need for in -person contact.'

    },
    {
      question: 'Can I speak with a healthcare professional about my lab results?', answer: 'Yes, our team of healthcare professionals is available to discuss your lab results and answer any questions you may have.You can schedule a consultation through our platform.'

    },
    {
      question: 'What happens if my sample is insufficient for testing?', answer: 'In the rare event that a sample is insufficient for testing, our team will reach out to provide guidance on the next steps.We may offer a replacement kit or suggest alternative testing options.'
    }
  ];
  const [labTests, setLabTests] = useState(null)
  const [labTestData, setLabTestData] = useState(null)
  const [loading, setloading] = useState(false)
  const [ModalOpen, setModalOpen] = useState(false)
  const [selectedDate, setselectedDate] = useState('')
  const [slotsToShow, setslotsToShow] = useState([])
  const [selectedSlot, setselectedSlot] = useState(null)
  const { testcode } = useParams();

  const onDateSelect = (value) => {
    console.log("onDateselect", Date.parse(value._d));
    // formObj["slot_date"] = value.format("DD/MM/YYYY");
    // formObj["slot_time"] = null;
    // setFormData(formObj);
    // setSlot_time(null);
    setselectedDate(value.format("DD/MM/YYYY"));

    let startFrom = labTestData.from.split(':')[0]
    let startFromMinute = labTestData.from.split(':')[1].split(' ')[0]

    let endTo = labTestData.to.split(':')[0]
    let endToMinute = labTestData.to.split(':')[1].split(' ')[0]

    let startFromAMPM = labTestData.from.split(':')[1].split(' ')[1]
    let endToAMPM = labTestData.to.split(':')[1].split(' ')[1]

    if (startFromAMPM == 'pm') startFrom = Number(startFrom) + 12
    else if (startFromAMPM == 'am' && endToAMPM == 'am' && Number(startFrom) >= Number(endTo)) endTo = Number(endTo) + 24

    if (endToAMPM == 'pm') endTo = Number(labTestData.to.split(':')[0]) + Number(12)
    else if (startFromAMPM == 'pm' && endToAMPM == 'am') endTo = Number(labTestData.to.split(':')[0]) + Number(24)
    if (startFromAMPM == 'pm' && endToAMPM == 'pm' && Number(startFrom) >= Number(endTo)) endTo = Number(endTo) + 24

    function getTimes(startFromTime, endToTime) {
      let x = labTestData.timeSlot; //minutes interval
      let times = []; // time array
      let tt = startFromTime * 60; // start time
      let ap = ['AM', 'PM']; // AM-PM
      endToTime = endToTime * 60
      if (startFromMinute != '00') {
        tt = Number(tt) + Number(startFromMinute)
      }
      if (endToMinute != '00') {
        endToTime = Number(endToTime) + Number(endToMinute)
      }
      //loop to increment the time and push results in array
      for (let i = 0; tt < endToTime; i++) {
        let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        let mm = (tt % 60); // getting minutes of the hour in 0-55 format
        times[i] = {
          label: ("0" + ((hh % 12) == 0 ? 12 : (hh % 12))).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[(Math.floor(hh / 12) % 2)],
          value: ("0" + ((hh % 12) == 0 ? 12 : (hh % 12))).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[(Math.floor(hh / 12) % 2)]
        }; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
      }
      return times
    }
    setslotsToShow(getTimes(startFrom, endTo))

  };
  let valid = function (current) {
    return (
      current.isAfter(yesterday) &&
      current.isBefore(afterMonth)
      &&
      labTestData.daysAvailable.includes(current.day())
    );
  };

  // useEffect(() => {
  //   testcode = useParams();
  // }, [testcode]);

  const history = useHistory()
  useEffect(() => {
    let API = new Apilib();

    API.getLabTests({})
      .then(async (res) => {
        console.log(res)
        console.log('this is response')
        console.log('this is response', res.data.labtests)
        setLabTests(res.data.labtests)
        // medicineList
      })
      .catch((error) => {
        console.log('error', error)
      });
    setloading(true)
    API.getLabTestByTestCode({ testCode: testcode })
      .then((result) => {
        let res = result.data
        console.log('the data', res.data)
        setLabTestData(res.data.testData[0])
        setloading(false)
        // setOrderData(res.data);
        // setmedicineData(res.data.prescription);

        // if (res.data.prescription) {
        //     setmedName(res.data?.prescription.map((med) => med?.Medicine_Name))

        //     setmedQuantity(res.data?.prescription.map((med) => med?.qty))
        // }

        // setLoading(false);
      }).catch(e => {
        setloading(false)
        console.log('hi there', e)
        // setLoading(false);
      });

    console.log('error', testcode)

  }, [testcode]);


  const onSlotSelect = (slot) => {
    console.log("Slot:", slot);
    setselectedSlot(slot)
  }

  console.log('labTestDatalabTestDataerror', labTestData)
  return (
    <Div>
      {loading && <Loader />}
      <Div24>
        <Div25>
          <Div26>
            {labTestData && <Column>
              <Div27>
                <Div28>
                  <Div29>
                    POPULAR CHOICE
                  </Div29>
                  <Div31>
                    <Div32>
                      <Img10
                        loading="lazy"
                        src={invoice}
                      />
                      <Div33>{labTestData.testName}</Div33>
                    </Div32>
                    <Div34>
                      {labTestData.description}
                    </Div34>
                    <Div35>
                      <Div36>${labTestData.price * .95}</Div36>
                      <Div37>${labTestData.price} </Div37>
                      <Div38>5% off</Div38>
                    </Div35>
                  </Div31>
                  <Div39 />
                  <Div40>
                    <Div41>
                      <Img11
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcd39d6f-6e08-4fa6-a15a-8e090e9b78dd?"
                      />
                      <Div42>Slots Available</Div42>
                    </Div41>
                    <Div43>
                      <Img12
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b75f945c-be93-49fd-bd96-a85497a91714?"
                      />
                      <Div44>Report in 24 hours</Div44>
                    </Div43>
                    <Div45 onClick={() => {

                      // addLabAppointment
                      // let API = new Apilib();

                      // API.addLabAppointment({
                      //   pharmacyId: labTestData.pharmacyId,
                      //   slot_date: 'Thu Dec 14 2023 010:00:47 GMT+0530 (India Standard Time)',
                      //   slot_time: 'Thu Dec 14 2023 010:00:47 GMT+0530 (India Standard Time)',
                      //   pharmacy_email: 'moola@medvatic.com',
                      //   patient_email: 'moola@medvatic.com',
                      //   appointmentType: '1',
                      //   patientId: 'AIzaSyAk76hJ6S9JxhWizT4KKI3wGNIZtpDNMak',
                      //   pharmacy_name: '1mg',
                      //   name: 'Ram',
                      //   from: 'Thu Dec 14 2023 010:00:47 GMT+0530 (India Standard Time)',
                      //   to: 'Thu Dec 14 2023 09:00:47 GMT+0530 (India Standard Time)',
                      //   testId: labTestData.testId

                      // }).then(async (res) => {
                      //   console.log(res)
                      //   console.log('this is response')
                      //   console.log('this is response', res.data)
                      //   // setLabTests(res.data)
                      //   // medicineList
                      // })
                      //   .catch((error) => {
                      //     console.log('error', error)
                      //   });

                      // setselectedDate
                      // setslotsToShow


                      setModalOpen(true)
                      // setselectedDate
                    }}>Book Test</Div45>
                  </Div40>
                </Div28>
                <Div46>
                  <Div47>
                    <Img13
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5f42c9fb-433e-4a8d-b117-ae3af687abd5?"
                    />
                    <Div48>Sample Type</Div48>
                    <Div49>{labTestData.testCategory}</Div49>
                    <Img14
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4352700-2f57-410f-aef8-b66c092a64a2?"
                    />
                    <Div50>{labTestData.preparationInstructions}</Div50>
                  </Div47>
                  <Div52>
                    <Img15
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e227f7d6-295b-42fa-b46b-74820869b53a?"
                    />
                    <Div53>Package Details</Div53>
                  </Div52>
                  <Div54>
                    <Div55 />
                    <Div56>
                      <span>
                        {labTestData.Miscellaneous}
                      </span>
                    </Div56>
                  </Div54>
                </Div46>
              </Div27>
            </Column>}
            <Column2>
              <Div57>
                <Div58>Other Tests:</Div58>

                {labTests && labTests.sort(shuffle).slice(0, 2).map((item) => {
                  return <Div59>
                    <Div60>5% OFF</Div60>
                    <Div61>{item.testName}</Div61>
                    <Div62>
                      {item.description}
                    </Div62>
                    <Div63>${item.price}</Div63>
                    <Div64>
                      <Div65>${item.price * .95}</Div65>
                      {/* <Img16
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fac61921-5a43-4334-b906-d91a1adae315?"
                      /> */}
                      <Div45 onClick={() => {
                        history.push(`/LabTestDetails/${item.testCode}`)
                      }}>Book Test</Div45>
                    </Div64>
                  </Div59>
                })
                }
                {/* <Div59>
                  <Div60>25% OFF</Div60>
                  <Div61>Diabetes Care</Div61>
                  <Div62>
                    Lorem ipsum dolor sit amet adipiscing. consectetur. In
                    aliquam in posuere vitae nunc. Tristique odio sed neque
                    sagittis integer egestas est suspendisse. Aliquet.
                  </Div62>
                  <Div63>$890.00</Div63>
                  <Div64>
                    <Div65>$750.00</Div65>
                    <Img16
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fac61921-5a43-4334-b906-d91a1adae315?"
                    />
                  </Div64>
                </Div59>
                <Div66>
                  <Div67>25% OFF</Div67>
                  <Div68>Basic Health Checkup</Div68>
                  <Div69>
                    Lorem ipsum dolor sit amet adipiscing. consectetur. In
                    aliquam in posuere vitae nunc. Tristique odio sed neque
                    sagittis integer egestas est suspendisse. Aliquet.
                  </Div69>
                  <Div70>$890.00</Div70>
                  <Div71>
                    <Div72>$750.00</Div72>
                    <Img17
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9cc8e60-6938-4acb-b25d-51d6363fc07e?"
                    />
                  </Div71>
                </Div66> */}
                <Div73 onClick={() => history.push('/LabTest')}>View All</Div73>
              </Div57>
            </Column2>
          </Div26>
        </Div25>
        <Div119>

          <Div79>
            <span style={{ fontFamily: 'Roboto sans-serif', fontWeight: "700" }}>
              FAQ
            </span>
            <span style={{ fontFamily: 'Roboto sans-serif', fontWeight: "700" }}>
              {" "}
            </span>
            <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: "700" }}>
              (Frequently Asked Questions)
            </span>
          </Div79>
          {faqs.map((faq, index) => (
            <QAComponent key={index} question={faq.question} answer={faq.answer} />
          ))}
        </Div119>
      </Div24>
      <Modal
        backdrop="static"
        show={ModalOpen}
        onHide={() =>
          setModalOpen(false)
        }
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Book Lab test</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="form-group">
            <label>
              Date <span className="text-danger">*</span>
            </label>
            {/* <input name="date" min={moment().format('YYYY-MM-DD')} value={this.state.date} onChange={(e) => this.onChange(e)} required className="form-control" type="date" /> */}
            <Datetime
              isValidDate={valid}
              closeOnSelect={true}
              dateFormat="DD/MM/YYYY"
              onChange={(val) => onDateSelect(val)}
              value={selectedDate}
              inputProps={{ required: true }}
              input={true}
              timeFormat={false}
              viewMode="days"
            />
          </div>

          <Divselectday>
            <label>
              Slot Time
              <span className="required">*</span>
            </label>

            <Select
              onChange={(val) => onSlotSelect(val)}
              required
              value={selectedSlot}
              options={slotsToShow}
              noOptionsMessage={() => "Slots unavailable"}
              isMulti={false}
              closeMenuOnSelect={true}
            />
          </Divselectday>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              // console.log('clicked', selectedDate, selectedSlot.value)

              if (selectedSlot == null) {
                console.log(route.userData, 'props?.route?.userData');
                alert('Please select a slot and then continue')
                return
              }
              if (!route?.userData?.email) {
                history.push('/login')
                return
              }
              setloading(true)
              // var momentObj = moment(selectedDate, 'DD-MM-YYYY').valueOf();
              // console.log('clicked', momentObj, selectedSlot.value)
              let selectedSlotInMillis = moment(selectedDate + selectedSlot.value, 'DD-MM-YYYY HH:mm').valueOf();
              let selectedSlotEndInMillis = moment(selectedDate + selectedSlot.value, 'DD-MM-YYYY HH:mm').add(labTestData.timeSlot, 'minutes').valueOf();

              console.log('clicked', moment(selectedDate + selectedSlot.value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm:ss'), selectedSlotInMillis, selectedSlotEndInMillis)

              let data = {
                pharmacyId: labTestData.pharmacyId,
                slot_date: selectedDate,
                slot_time: selectedSlot.value,
                pharmacy_email: labTestData.email,
                patient_email: route?.userData?.email,
                appointmentType: '1',
                patientId: route?.userData?.uid,
                pharmacy_name: labTestData.pharmacyName,
                name: route?.userData?.firstName + ' ' + route?.userData?.lastName,
                from: selectedSlotInMillis,
                to: selectedSlotEndInMillis,
                testId: labTestData.testId,
                testName: labTestData.testName,
                phone: route?.userData?.phoneno
              }
              let API = new Apilib();

              API.addLabAppointment(data).then(async (res) => {
                console.log(res)
                console.log('this is response')
                console.log('this is response', res.data)
                // setLabTests(res.data)
                // medicineList
                setloading(false)
                setModalOpen(false)
              })
                .catch((error) => {
                  console.log('error', error)
                  setloading(false)

                });


              // this.handleDeleteAttachment(this.state.currentAppId)
            }}
          >
            Confirm booking
          </Button>


          <Button
            variant="danger"
            onClick={() =>
              setModalOpen(false)
            }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Div>
  );
}


const Div119 = styled.div`
  border-radius: 6px;
  box-shadow: 5px 8px 16px 0px rgba(0, 0, 0, 0.08),
    -4px -5px 14px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  align-self: start;
  margin-top: 45px;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 18px 19px 18px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;


const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Div24 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 99px;
  width: 100%;
  max-width: 1410px;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div25 = styled.div`
  align-self: stretch;
  margin-right: -20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div26 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 67%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div27 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 31px;
  }
`;

const Div28 = styled.div`
  border-radius: 10px;
  border: 1px solid #dadde3;
  background-color: #fffaf4;
  display: flex;
  padding-bottom: 25px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div29 = styled.div`
  disply: flex;
  flex-direction: column;
  color: #ec8b00;
  position: relative;
  white-space: nowrap;
  fill: #ffe8c8;
  overflow: hidden;
  align-self: start;
  aspect-ratio: 6.176470588235294;
  width: 210px;
  max-width: 100%;
  align-items: center;
  padding: 12px 20px;
  font: 500 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div31 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 53px;
  flex-direction: column;
  padding: 0 67px 0 27px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Divselectday = styled.div`
            display: flex;
            flex-grow: 1;
            flex-basis: 0%;
            flex-direction: column;
            @media (max-width: 991px) {
                max - width: 100%;
  }
            `;

const Div32 = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img10 = styled.img`
  aspect-ratio: 0.97;
  object-fit: contain;
  object-position: center;
  width: 58px;
  fill: #0a0d14;
  overflow: hidden;
  max-width: 100%;
`;

const Div33 = styled.div`
  color: #0a0d14;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  font: 500 36px/42px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div34 = styled.div`
  color: #5d5d5d;
  align-self: end;
  margin-top: 12px;
  width: 772px;
  max-width: 100%;
  font: 400 14px/36px Roboto, sans-serif;
`;

const Div35 = styled.div`
  align-self: start;
  display: flex;
  width: 286px;
  max-width: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 17px;
  margin: 29px 0 0 44px;
  @media (max-width: 991px) {
    margin-left: 10px;
    justify-content: center;
  }
`;

const Div36 = styled.div`
  color: #26d4ff;
  font: 500 36px/42px Roboto, sans-serif;
`;

const Div37 = styled.div`
  color: #737373;
  text-decoration-line: line-through;
  margin-top: 18px;
  font: 500 20px/23px Roboto, sans-serif;
`;

const Div38 = styled.div`
  color: #f47779;
  margin-top: 19px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div39 = styled.div`
  background-color: #d2d2d2;
  align-self: stretch;
  margin-top: 34px;
  height: 2px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div40 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 40px;
  width: 100%;
  padding-right: 13px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Div41 = styled.div`
  align-self: start;
  display: flex;
  gap: 16px;
`;

const Img11 = styled.img`
  aspect-ratio: 0.89;
  object-fit: contain;
  object-position: center;
  width: 16px;
  fill: #4e4e4e;
  overflow: hidden;
  max-width: 100%;
`;

const Div42 = styled.div`
  color: #5d5d5d;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 18px/36px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div43 = styled.div`
  align-self: start;
  display: flex;
  gap: 16px;
`;

const Img12 = styled.img`
  aspect-ratio: 0.81;
  object-fit: contain;
  object-position: center;
  width: 13px;
  overflow: hidden;
  max-width: 100%;
`;

const Div44 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px/36px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div45 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #00869a;
  align-items: center;
  padding: 19px 20px;
  cursor:pointer;
  font: 500 16px/19px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div46 = styled.div`
  border-radius: 10px;
  border: 1px solid #dadde3;
  background-color: #f8f8f8;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  padding: 45px 80px 45px 23px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div47 = styled.div`
  align-self: start;
  display: flex;
  width: 552px;
  max-width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Img13 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  stroke-width: 2px;
  stroke: #0a0d14;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div48 = styled.div`
  color: #0a0d14;
  margin-top: 4px;
  font: 500 18px/21px Roboto, sans-serif;
`;

const Div49 = styled.div`
  color: #00869a;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #d3f9ff;
  align-self: stretch;
  flex-grow: 1;
  padding: 5px 0;
  font: 500 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img14 = styled.img`
  aspect-ratio: 0.65;
  object-fit: contain;
  object-position: center;
  width: 11px;
  fill: #000;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div50 = styled.div`
  color: #0a0d14;
  margin-top: 4px;
  font: 500 18px/21px Roboto, sans-serif;
`;

const Div51 = styled.div`
  color: #00869a;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #d3f9ff;
  align-self: stretch;
  flex-grow: 1;
  padding: 5px 0;
  font: 500 14px/16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div52 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 14px;
  max-width: 100%;
  gap: 16px;
`;

const Img15 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  fill: #000;
  overflow: hidden;
  max-width: 100%;
`;

const Div53 = styled.div`
  color: #0a0d14;
  align-self: start;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div54 = styled.div`
  display: flex;
  margin-top: 19px;
  align-items: start;
  justify-content: space-between;
  gap: 6px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 9px;
    flex-wrap: wrap;
  }
`;

const Div55 = styled.div`
  border-radius: 50%;
  display: flex;
  margin-top: 4px;
  width: 6px;
  height: 6px;
  flex-direction: column;
`;

const Div56 = styled.div`
  color: #00869a;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/20px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div57 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Div58 = styled.div`
  color: #000;
  white-space: nowrap;
  font: 400 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div59 = styled.div`
  border-radius: 10px;
  background-color: #effdff;
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  padding: 24px 0 24px 22px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const Div60 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  align-items: start;
  padding: 10px 6px;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div61 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 20px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div62 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 25px;
  font: 400 16px/19px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div63 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 35px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div64 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div65 = styled.div`
  color: #26d4ff;
  margin-top: 17px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Img16 = styled.img`
  aspect-ratio: 3.35;
  object-fit: contain;
  object-position: center;
  width: 124px;
  overflow: hidden;
  max-width: 100%;
`;

const Div66 = styled.div`
  border-radius: 10px;
  background-color: #effdff;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  padding: 24px 0 24px 22px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const Div67 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #f47779;
  align-self: start;
  width: 83px;
  max-width: 100%;
  align-items: start;
  padding: 10px 6px;
  font: 500 18px/21px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div68 = styled.div`
  color: #4e4e4e;
  align-self: stretch;
  margin-top: 20px;
  white-space: nowrap;
  font: 500 24px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div69 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 20px;
  font: 400 16px/19px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div70 = styled.div`
  color: #737373;
  text-decoration-line: strikethrough;
  align-self: stretch;
  margin-top: 35px;
  white-space: nowrap;
  font: 500 20px/23px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div71 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div72 = styled.div`
  color: #26d4ff;
  margin-top: 17px;
  font: 500 24px/28px Roboto, sans-serif;
`;

const Img17 = styled.img`
  aspect-ratio: 3.35;
  object-fit: contain;
  object-position: center;
  width: 124px;
  overflow: hidden;
  max-width: 100%;
`;

const Div73 = styled.div`
  color: #00869a;
  text-decoration-line: underline;
  margin-top: 33px;
  white-space: nowrap;
  font: 500 16px/19px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div79 = styled.div`
  color: #041b1f;
  align-self: stretch;
  white-space: nowrap;
  margin: 25px -20px 20px 0;
  font: 700 36px/34px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 10px;
    white-space: initial;
  }
`;



export default LabTestDetails;