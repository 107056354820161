import React from "react";
import ReactDOM from "react-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

// import { LeftArrow, RightArrow } from "./arrows";
// import { Card } from "./card";
// import { Footer } from "./footer";
// import { Header } from "./header";
// import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";

// NOTE: embrace power of CSS flexbox!
import "./hideScrollbar.css";
// import "./firstItemMargin.css";

const Card = ({
    itemId,
    width,
    margin,
    title,
    name,
    text,
    image, business
}) => {
    const visibility = React.useContext(VisibilityContext);

    const visible = visibility.isItemVisible(itemId);

    return (
        <div
            // role="button"
            style={{
                // border: "1px solid",
                // display: "inline-block",
                // margin: "0 10px",
                userSelect: "none",
                width,
                marginLeft: margin,
                marginRight: margin
            }}
            tabIndex={0}
            className="card">
            <div style={{ textAlign: 'center' }}>
                <div style={{ backgroundColor: "transparent" }}>
                    {text}
                </div>
                <img src={image} alt="Person 1" style={{ borderRadius: '50px', width: '100px', height: '100px' }} />

                <div style={{ fontWeight: '600', fontSize: '21px' }}>{name}</div>
                <div>{business}</div>

            </div>
        </div>
    );
}
// const LeftArrow = <div style={{ alignSelf: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//     <i class="fa fa-arrow-left"></i>
// </div>
// const RightArrow = <div style={{ alignSelf: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}><i class="fa fa-arrow-right"></i></div>

const elemPrefix = "test";
const getId = (index) => `${elemPrefix}${index}`;

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: getId(ind) }));

function Testimonials({ data }) {
    const mk = data
    const [items, setItems] = React.useState(getItems);
    const { disableScroll, enableScroll } = usePreventBodyScroll();
    // useEffect(() => {
    //     effect
    //     return () => {
    //         cleanup
    //     };
    // }, [input]);

    const containerRef = React.useRef();
    const [containerWidth, setContainerWidth] = React.useState(0);
    // const containerWidth =
    //   menuRef?.current?.scrollContainer?.current?.clientWidth || 0;
    const itemWidth = containerWidth / 2 || 160;
    const itemMargin = `${containerWidth / 4}px` || "50px";

    React.useEffect(() => {
        if (containerRef.current && !containerWidth) {
            // 96 is width of both arrows
            setContainerWidth(containerRef.current.clientWidth - 96);
        }
    }, [containerRef, containerWidth]);


    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev } =
            React.useContext(VisibilityContext);

        return (
            <div style={{ alignSelf: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
                <i className="fa fa-arrow-left"></i>
            </div>
        );
    }

    function RightArrow() {
        const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

        return (
            <div style={{ alignSelf: 'center' }} disabled={isLastItemVisible} onClick={() => scrollNext()}>
                <i className="fa fa-arrow-right"></i>
            </div>
        );
    }

    return (
        <div ref={containerRef}>
            {/* <Header /> */}
            <div className="example" style={{ paddingTop: "100px" }}>
                <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
                    <ScrollMenu
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        onWheel={onWheel}
                    >
                        {mk?.map(({ id, name, text, image, business }) => (
                            <Card
                                title={id}
                                name={name}
                                business={business}
                                text={text}
                                image={image}
                                itemId={id} // NOTE: itemId is required for track items
                                key={id}
                                width={itemWidth + "px"}
                                margin={itemMargin}
                            />
                        ))}
                    </ScrollMenu>
                </div>
                {/* <Footer /> */}
            </div>
        </div>
    );
}
export default Testimonials;

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

ReactDOM.render(<Testimonials />, document.getElementById("root"));
