import React, { useEffect, useState } from 'react';
import Axios from "axios";
import Loader from '../Loader/Loader';
import { Link, Route, useParams } from "react-router-dom";

const AllDoctors = () => {
    // getAllDoctors
    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const [searchResult, setSearchResult] = useState([])
    const [noResults, setNoResults] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {

        setLoading(true);
        Axios.get("https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/getAllDoctors")
            .then((res) => {

                let dataAPI = [];
                dataAPI = res.data.result;
                console.log(res, 'this is data')
                setLoading(false);
                if (dataAPI.length === 0) {
                    setSearchResult([]);
                    setNoResults(true);

                } else {
                    if (noResults) {
                        setSearchResult([]);
                    }
                    setSearchResult(dataAPI);
                    console.log(dataAPI, 'this is data')
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('this error is data')

                setSearchResult([]);
            });

    }, [])
    return (
        <>
            <div className="container">
                {/* <div className="search-bar">
          <>
            <div className="location-bar">
              <GoogleMap
                style={{ width: 100 }}
                value={geoLocation}
                onChange={(e) => {}}
                getAddress={(address) => {
                  setGeoLocation(address);
                }}
              />
            </div>
            <div>
              <SearchBar />
            </div>
          </>
        </div> */}
            </div>
            <div className="main-content account-content">
                <script src="https://use.fontawesome.com/cee0220b65.js"></script>
                {loading ? <Loader /> : null}
                <div className="content">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-12 appointment-cards">
                                    {noResults && <h3>Getting list of available doctors</h3>}
                                    {searchResult.length !== 0 ? (
                                        searchResult.map((item) => {
                                            let imageUrl = item.profile_picture

                                            // let imageUrl = await makeDownloadUrl(item.uid, item.type);
                                            return (
                                                <div className="card mb-3">
                                                    <div className="row">
                                                        <div className="date col-md-2">
                                                            {/* <p>Doctor</p>
                              <img src="../../../public/assets/img/Dr_drake_ramoray-.jpg" /> */}
                                                            <img
                                                                className="img-fluid"
                                                                alt=""
                                                                src={
                                                                    imageUrl !== null && imageUrl !== undefined
                                                                        ? imageUrl
                                                                        : "../assets/img/doctor-06.jpg"
                                                                }
                                                            ></img>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card-body">
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                    className="head-card"
                                                                >
                                                                    <h5 className="card-title2 m-b-10">
                                                                        {item.firstName + " " + item.lastName}
                                                                    </h5>

                                                                    <div className="appointment-available mt-50">
                                                                        <a>
                                                                            <p className="available">
                                                                                {item.days
                                                                                    ? item.days.includes(
                                                                                        new Date(Date.now()).getDay()
                                                                                    )
                                                                                        ? "Available Today!"
                                                                                        : "Available on " +
                                                                                        item.days.map((day, i) => {
                                                                                            if (i === 0) {
                                                                                                return days[day];
                                                                                            } else {
                                                                                                return " " + days[day];
                                                                                            }
                                                                                        })
                                                                                    : "No schedule available"}
                                                                            </p>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/add-appointment",
                                                                                    state: { item },
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-primary appointment2-btn account-btn"
                                                                                    disabled={!item.days}
                                                                                >
                                                                                    Book Appointment
                                                                                </button>
                                                                            </Link>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <p className="speciality">
                                                                    {item.specialities ? (
                                                                        item.specialities.map((spe, i) => {
                                                                            if (i === 0) {
                                                                                return spe;
                                                                            } else {
                                                                                return ", " + spe;
                                                                            }
                                                                        })
                                                                    ) : (
                                                                        <h3>No search result found!</h3>
                                                                    )}
                                                                </p>
                                                                <p className="speciality">
                                                                    {item.experience} Years of Overall Experience
                                                                </p>
                                                                <p className="card-text location">
                                                                    {/* experience
                                  <br /> */}
                                                                    {item.hospitalName ? (
                                                                        item.hospitalName
                                                                    ) : (
                                                                        <h3>No search result found!</h3>
                                                                    )}
                                                                </p>
                                                                {/* <p className="card-text speciality">
                                  ₹1800 Consultation fee at clinic
                                </p> */}
                                                                {/* <hr />
                                <div className="card-text ">
                                  <p className="card-end">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit longer.
                                  </p>
                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : noResults ? null : (
                                        <h3>Getting list of available doctors</h3>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllDoctors;
