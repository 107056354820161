import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo1.png'
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import Apilib from "../../api";
import { useHistory, Redirect } from 'react-router-dom'

// Dummy data for the invoice
// const invoiceData = {
//     companyIcon: 'company_icon_url',
//     invoiceNumber: 'INV-123',
//     date: '2023-07-18',
//     customer: {
//         name: 'John Doe',
//         address: '123 Main Street, City',
//     },
//     pharmacy: {
//         name: 'Example Pharmacy',
//         address: '456 Pharmacy Avenue, City',
//     },
//     items: [
//         { name: 'Item 1', description: 'Item 1 description', quantity: 2, price: 10, total: 20 },
//         { name: 'Item 2', description: 'Item 2 description', quantity: 1, price: 15, total: 15 },
//     ],
//     subTotal: 35,
//     tax: 5,
//     total: 40,
// };

const h2 = styled.h2`
  align-items: center;
  @media (max-width: 100%) {
  }
`;
const SectionHeading = styled.h3`
  color: #000;
`;
const P = styled.p`
  color: #111;
  margin-bottom:3px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const InvoiceTableWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
`;

const InvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: 100px;
  }

  /* Apply additional styles for smaller screens */
  @media (max-width: 768px) {
    th,
    td {
      font-size: 14px;
      white-space: nowrap;
    }
  
    tr {
      display: block;
      margin-bottom: 0.625em;
    }
  
    th,
    td::before {
      content: attr(data-label);
      display: inline-block;
      font-weight: bold;
      width: 100px;
    }
  
    td {
      display: block;
      text-align: right;
      position: relative;
      padding-left: 100px;
    }
  
    td::before {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 0;
      padding-left: 4px;
    }
  }
`;


const InvoiceHeader = () => {
  return (
    <div style={{ width: '100%', textAlign: 'end' }}>
      <h2>Generated Invoices</h2>

    </div>
  );
};


const PharmacyDetails = (pharmacyData) => {
  const pharmacy = pharmacyData.pharmacyData
  console.log(pharmacyData)
  console.log('pharmacyData')
  return (
    <div style={{ margin: '1px' }}>
      <SectionHeading>Pharmacy Details:</SectionHeading>
      <P>Pharmacy Name: {pharmacy.pharmacyName}</P>
      <P>Pharmacy Address: {pharmacy.location}</P>
    </div>
  );
};


const PharmacyInvoiceList = (route) => {
  console.log(route, 'this is here route')

  let [invoiceData, setinvoiceData] = useState(null)
  let [pharmacyData, setpharmacyData] = useState(null)
  let [loading, setloading] = useState(false)

  useEffect(() => {
    let API = new Apilib();

    let data = { pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1', invoiceId: 'IiSyo0bJi77pnHhREt13', type: '6' }
    setloading(true)
    // console.log(data)

    API.getPharmacyInvoices({ pharmacyId: route.userData.uid })
      .then(async (res) => {
        console.log(res)
        console.log('this is response')
        console.log(res.data.invoices)
        console.log('pharmacy data')

        setinvoiceData(res.data.invoiceList)
        setpharmacyData(res.data.invoices)
        setloading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setloading(false)
      });
    console.log('useeffect executed')
  }, []);
  const history = useHistory();


  return (
    <div >
      {loading && <Loader />}
      {invoiceData && <div style={{ backgroundColor: '#f1faff', padding: '5%', borderRadius: '15px' }}>
        <img style={{ width: '150px', height: '50px', position: 'absolute' }} src={logo} alt="Company Logo" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InvoiceHeader />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>

          {pharmacyData && <PharmacyDetails pharmacyData={pharmacyData} />}
        </div>

        <InvoiceTableWrapper>
          <InvoiceTable>
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Patient Name</th>
                <th>Item Quantity</th>
                <th>Patient Address</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.map((item, index) => (
                <tr
                  style={{ backgroundColor: '#81e2fc', borderRadius: '10px', cursor: 'pointer', overflow: 'hidden', margin: '5px' }}
                  key={index} onClick={() => {
                    console.log('clicked ', item.invoiceNumber, item.invoiceUid)
                    history.push({ pathname: `/pharmacy/InvoiceForm`, state: { id: item.invoiceUid } });

                  }}>
                  {console.log(item, 'itemitemitemitem')}
                  <td>{item.invoiceNumber}</td>
                  <td>{item.invoiceFor.firstName + ' ' + item.invoiceFor.lastName}</td>
                  <td>{item.prescription.length}</td>
                  <td>{item.address}</td>

                </tr>
              ))}
            </tbody>
          </InvoiceTable>
        </InvoiceTableWrapper>

        <ButtonGroup>
          <Button style={{ fontWeight: '400', fontSize: '16px', margin: '15px' }}
            onClick={async () => {
              console.log('this is it')

              setloading(true)
              history.push('/pharmacy/InvoicePage')


              setloading(false)
            }}
          >
            Add new invoice</Button>

        </ButtonGroup>
      </div>}
    </div>
  );
};

export default PharmacyInvoiceList;