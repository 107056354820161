import * as React from "react";
import styled from "styled-components";
import certified from '../../assets/img/certified.png';
import healthcare from '../../assets/img/healthcare.png';
import money from '../../assets/img/money.png';
import onlinedel from '../../assets/img/onlinedel.png';
import shipping from '../../assets/img/shipping.png';
import signup from '../../assets/img/signup.png';
import support from '../../assets/img/support.png';
import Register from "../Patient/Register";
function HowMedvaticWorks(props) {
  return (
    <Div>
      <Div24>
        <Div25>How it works</Div25>
        <Div26>
          <Div27 />
          <Div29 />
          <Div31 />
        </Div26>
        <Div32>
          <Div33>
            {[{ img: signup, title: 'Join Medvatic for Personalized Healthcare', subtext: 'Embark on your health journey with Medvatic! Sign up today to access a world of personalized healthcare services. Our platform connects you with experienced professionals, innovative resources, and tailored solutions to empower you on your path to well-being. Your health, your way – sign up with Medvatic and take control of your wellness today.' },
            { img: healthcare, title: 'Comprehensive Healthcare Services at Medvatic', subtext: "Discover a range of top-notch healthcare services tailored to your needs at Medvatic. From virtual consultations with experienced professionals to personalized health plans and a wealth of informative resources, we're committed to providing holistic care. At Medvatic, your health is our priority. Explore our services and take a proactive step towards a healthier and happier you." },
            { img: onlinedel, title: 'Seamless Medication Access with Medvatic', subtext: "Experience the convenience of online medicine orders through Medvatic. With a few clicks, you can have your prescribed medications delivered to your doorstep. Our user-friendly platform ensures a hassle-free ordering process, making healthcare accessible and efficient. Trust Medvatic for a seamless and secure way to receive the medications you need. Order online and prioritize your health with ease." },
            ].map(x => <Column>
              <Div34>
                <Img9
                  loading="lazy"
                  src={x.img}
                />
                <Div35>{x.title}</Div35>
                <Div36>
                  {x.subtext}
                </Div36>
              </Div34>
            </Column>)
            }
            {/* <Column>
                            <Div34>
                                <Img9
                                    loading="lazy"
                                    src={signup}
                                />
                                <Div35>Join Medvatic for Personalized Healthcare</Div35>
                                <Div36>
                                    Embark on your health journey with Medvatic! Sign up today to access a world of personalized healthcare services. Our platform connects you with experienced professionals, innovative resources, and tailored solutions to empower you on your path to well-being. Your health, your way – sign up with Medvatic and take control of your wellness today.
                                </Div36>
                            </Div34>
                        </Column>
                        <Column2>
                            <Div37>
                                <Img10
                                    loading="lazy"
                                    src={healthcare}
                                />
                                <Div38>Comprehensive Healthcare Services at Medvatic</Div38>
                                <Div39>
                                    Discover a range of top-notch healthcare services tailored to your needs at Medvatic. From virtual consultations with experienced professionals to personalized health plans and a wealth of informative resources, we're committed to providing holistic care. At Medvatic, your health is our priority. Explore our services and take a proactive step towards a healthier and happier you.
                                </Div39>
                            </Div37>
                        </Column2>
                        <Column3>
                            <Div40>
                                <Img11
                                    loading="lazy"
                                    src={onlinedel}
                                />
                                <Div41>Seamless Medication Access with Medvatic</Div41>
                                <Div42>
                                    Experience the convenience of online medicine orders through Medvatic. With a few clicks, you can have your prescribed medications delivered to your doorstep. Our user-friendly platform ensures a hassle-free ordering process, making healthcare accessible and efficient. Trust Medvatic for a seamless and secure way to receive the medications you need. Order online and prioritize your health with ease.
                                </Div42>
                            </Div40>
                        </Column3> */}
          </Div33>
        </Div32>
        <Div43>
          <Div44>
            {/* <Img12
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5ab07e603bdc88ff24d1a801bda044e83d5d7dbb0df940ecbb6ba97f957a1d65?"
                        />
                         */}
            <iframe
              id="ytplayer"
              type="text/html"
              width="100%"
              height="360"
              src={`https://www.youtube.com/embed/BAYCswHo2V4?autoplay=0&origin=http://example.com&controls=0&rel=1`}
              frameBorder="0"
            ></iframe>
            <Div45>Explore Medvatic</Div45>
            <Div46>Lets Watch How It’s Work</Div46>
          </Div44>
        </Div43>
        <Div47>
          <Div48>
            {[
              { text: 'Experience unparalleled service standards at Medvatic. Our commitment to your well-being ensures the best in healthcare services, providing a seamless and personalized experience.', img: certified, title: 'Best services' },
              { text: "Experience peace of mind with Medvatic's secure payment options, ensuring your financial transactions are safeguarded for a trustworthy healthcare experience.", img: money, title: 'Trusted payment' },
              { text: 'Immerse yourself in top-tier healthcare at Medvatic, where cutting-edge technology meets compassionate care for an unparalleled medical experience.', img: support, title: 'Top facility' },
              { text: 'Order your medications online with Medvatic for swift and hassle-free doorstep delivery, ensuring your health is just a click away.', img: shipping, title: 'Medicine Delivery' },
            ].map(x => <Column4>
              <Div49>
                <Img13
                  loading="lazy"
                  src={x.img}
                />

                <Div50>{x.title}</Div50>
                <Div51>
                  {x.text}
                </Div51>
              </Div49>
            </Column4>)
            }
            {/*                        
                        <Column5>
                            <Div52>
                                <Img14
                                    loading="lazy"
                                    src={money}
                                />
                                <Div53>Trusted payment</Div53>
                                <Div54>
                                    Experience peace of mind with Medvatic's secure payment options, ensuring your financial transactions are safeguarded for a trustworthy healthcare experience.
                                </Div54>
                            </Div52>
                        </Column5>
                        <Column6>
                            <Div55>
                                <Img15
                                    loading="lazy"
                                    src={support}
                                />
                                <Div56>Top facility</Div56>
                                <Div57>
                                    Immerse yourself in top-tier healthcare at Medvatic, where cutting-edge technology meets compassionate care for an unparalleled medical experience.
                                </Div57>
                            </Div55>
                        </Column6>
                        <Column7>
                            <Div58>
                                <Img16
                                    loading="lazy"
                                    src={shipping}
                                />
                                <Div59>Medicine Delivery</Div59>
                                <Div60>
                                    Order your medications online with Medvatic for swift and hassle-free doorstep delivery, ensuring your health is just a click away.
                                </Div60>
                            </Div58>
                        </Column7> */}
          </Div48>
        </Div47>
        <Div61>Become a Customer</Div61>
        <Div62>
          Embark on your healthcare journey by becoming a valued customer at Medvatic. Experience personalized care, cutting-edge services, and a commitment to your well-being.
        </Div62>
        <Div63>
          <Div64>

            <Register />

          </Div64>
        </Div63>

      </Div24>
      {/* <Div85>
                <Div86>
                    <Img22
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b806aeb8f51210d39f38405a12672f85f8d8db270bdb467f6bfd56ec3a1e7e68?"
                    />
                    <Div87>
                        <Div88>Get the latest news and offers</Div88>
                        <Div89>Subscribe to our newsletter</Div89>
                    </Div87>
                    <Div90>
                        <Div91>Enter your mail address</Div91>
                        <Div92>Subscribe</Div92>
                    </Div90>
                </Div86>
            </Div85> */}
    </Div>
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Div24 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 70px;
  width: 100%;
  max-width: 1410px;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div25 = styled.div`
  color: #313239;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div26 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 34px;
  align-items: center;
  gap: 0px;
`;

const Div27 = styled.div`
  background-color: #cacbcd;
  width: 239px;
  height: 1px;
  margin: auto 0;
`;

const Div28 = styled.div`
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 10px;
  height: 10px;
  flex-direction: cŌolumn;
`;

const Div29 = styled.div`
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 10px;
  height: 10px;
  flex-direction: column;
`;

const Div31 = styled.div`
  background-color: #cacbcd;
  width: 239px;
  height: 1px;
  margin: auto 0;
`;

const Div32 = styled.div`
  align-self: stretch;
  margin: 50px 12px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 10px 0 0;
  }
`;

const Div33 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div34 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Img9 = styled.img`
  aspect-ratio: 1.09;
  object-fit: contain;
  object-position: center;
  width: 90%;
  overflow: hidden;
  align-self: stretch;
`;

const Div35 = styled.div`
  color: #2b2540;
  text-align: center;
  margin-top: 41px;
  font: 500 24px/100% Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div36 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  text-align: center;
  align-self: stretch;
  margin-top: 11px;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Div43 = styled.div`
  border-radius: 12px;
  background-color: #1e1b2b;
  align-self: stretch;
  display: flex;
  margin-top: 101px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div44 = styled.div`
  display: flex;
  width: 541px;
  max-width: 100%;
  flex-direction: column;
  margin: 63px 0 50px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;


const Div45 = styled.div`
  color: #fff;
  text-align: center;
  align-self: center;
  margin-top: 28px;
  white-space: nowrap;
  font: 500 24px/100% Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div46 = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 31px;
  font: 500 48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const Div47 = styled.div`
  align-self: stretch;
  margin-top: 100px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div48 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div49 = styled.div`
  border-radius: 12px;
  box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 16px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 49px 50px 49px 36px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Img13 = styled.img`
  aspect-ratio: 1.29;
  object-fit: contain;
  object-position: center;
  width: 45px;
  fill: #0c373e;
  overflow: hidden;
  max-width: 100%;
`;

const Div50 = styled.div`
  color: #00869a;
  align-self: stretch;
  margin-top: 21px;
  font: 500 20px Roboto, sans-serif;
`;

const Div51 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: stretch;
  margin-top: 26px;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Div61 = styled.div`
  color: #2b2540;
  align-self: stretch;
  margin-top: 64px;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div62 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: stretch;
  margin-top: 29px;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div63 = styled.div`
  align-self: stretch;
  margin-top: 35px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div64 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;




export default HowMedvaticWorks;
