import * as React from "react";
import styled from "styled-components";
import QAComponent from "../Pharmacy/QAComponent";
import docs from '../../assets/img/docs.png'
import rx from '../../assets/img/rx.png'
import message from '../../assets/img/message.png'
import alarm from '../../assets/img/alarm.png'
import certified from '../../assets/img/certified.png'
import dollars from '../../assets/img/dollars.png'
import privacy from '../../assets/img/privacy.png'
import support from '../../assets/img/supportblue.png'
import aredoctor from '../../assets/img/aredoctor.png'
import { useHistory } from "react-router-dom";
import Testimonials from "../PharmacyOrder/Testimonials";

function ConsultDoctor(props) {
  const faqs = [

    {
      question: 'What services does Medvatic provide?', answer: 'Medvatic is your one-stop solution for all your healthcare needs. We offer a seamless online platform for ordering prescription medications, over-the-counter products, and various health and wellness essentials. Our services include secure online payments and the option for offline payments upon delivery.'
    },
    {
      question: 'How can I place an order on Medvatic?', answer: 'To place an order on Medvatic, simply browse our website, add your desired medications or products to your cart, and proceed to checkout. During checkout, you can choose to pay online through our secure payment gateway or select the option for offline payment to pay in cash upon delivery.'
    },
    {
      question: 'Is Medvatic a licensed online pharmacy?', answer: 'Yes, Medvatic is a licensed and accredited online pharmacy. Our commitment to your well-being includes adhering to all regulatory standards to provide you with safe and reliable healthcare services.'
    },
    {
      question: 'What payment methods does Medvatic accept?', answer: 'Medvatic accepts a variety of payment methods. You can pay securely online using credit cards, debit cards, or other online payment options. Additionally, we offer the convenience of offline payments, allowing you to pay in cash at the time of delivery.'
    },
    {
      question: 'How does prescription delivery work?', answer: 'Once you place your order, our team processes it promptly. You can choose between standard and expedited shipping options during checkout. We partner with reliable courier services to ensure timely and secure delivery to your doorstep. If you choose offline payment, you can pay in cash when receiving your order.'
    },
    {
      question: 'Can I track my order on Medvatic?', answer: "Absolutely. Once your order is dispatched, you'll receive a tracking number via email. This enables you to monitor the status of your order until it reaches you. For offline payments, our delivery personnel will provide you with details at the time of delivery."
    },
    {
      question: 'What if I have questions about my medications?', answer: 'Our dedicated pharmacists at Medvatic are here to assist you. If you have any questions about your medications, potential interactions, or side effects, feel free to reach out to our pharmacy team through our customer support channels. Your well-being is our priority.'
    },
    {
      question: 'Is my personal information secure with Medvatic?', answer: 'Absolutely. Medvatic prioritizes the security and privacy of your personal information. We use industry-standard encryption to safeguard your data and adhere to strict privacy policies. Your information is used solely for order processing and communication related to your healthcare.'
    },
    {
      question: 'What should I do if I encounter issues with my order?', answer: 'If you encounter any issues with your order, delivery, or products, please contact our customer support team immediately. We are committed to resolving any concerns promptly and ensuring a smooth and satisfactory experience'
    },

  ];
  const aboutDoc = [
    { title: 'Certified Doctor', subtitle: 'At Medvatic, your health is in the hands of certified and experienced doctors. Our team comprises medical professionals who are not only highly qualified but also committed to providing you with the best possible care. Rest assured, when you choose Medvatic, you are consulting with experts who bring a wealth of experience and expertise to address your health concerns.', img: certified },
    { title: 'Cost Effective', subtitle: "Quality healthcare shouldn't come at an exorbitant price. At Medvatic, we prioritize affordability without compromising on the standard of care. Our commitment to being cost-effective means you can access top-notch medical consultations, prescriptions, and healthcare services without breaking the bank. Experience the convenience of online healthcare without the financial burden.", img: dollars },
    { title: '24/7 Patient Support', subtitle: "Your well-being is our top priority, and that's why we offer 24/7 patient support. Our dedicated support team is ready to assist you at any time, day or night. Whether you have questions about your medication, need assistance with the online consultation process, or require any other support, we're just a message or a call away. Trust Medvatic to be there for you whenever you need us.", img: support },
    { title: 'Secure and Confidential', subtitle: 'Your privacy matters, and at Medvatic, we take it seriously. Our platform employs state-of-the-art security measures to ensure that your personal and medical information remains secure and confidential. From encrypted communications to secure storage of your data, we go the extra mile to provide you with a safe and trustworthy online healthcare experience. Rest easy knowing that your information is in trusted hands.', img: privacy },
  ]
  const history = useHistory()
  return (
    <Div>
      <Div25>
        <Div26>
          <Column>
            <Div27>
              <Div28>Online Qualified Doctors Consultation</Div28>
              <Div29>
                <Div30>Starting At $30</Div30>
              </Div29>
              <Div32>
                <Img10
                  loading="lazy"
                  src={alarm}
                />
                <Div33>Talk Within 30 min</Div33>
                <Img11
                  loading="lazy"
                  src={message}
                />
                <Div34>Free Follow up for 3 days</Div34>
                <Img12
                  loading="lazy"
                  src={rx}
                />
                <Div35>Get a valid Prescription</Div35>
              </Div32>
              <Div36>
                Discover the unparalleled convenience of accessing top-tier healthcare from the comfort of your home through Medvatic's Online Qualified Doctors Consultation. Our platform redefines the traditional doctor-patient interaction, offering a seamless and secure virtual experience.
              </Div36>
              <Div37 onClick={() => {
                history.push('/AllDoctors')
              }} >Consult Now</Div37>
            </Div27>
          </Column>
          <Column2>
            <Img13
              loading="lazy"
              srcSet={docs}
            />
          </Column2>
        </Div26>
      </Div25>
      <Div38>
        <Div39>
          {[
            { title: '1K+', subTitle: 'Total Consultation' },
            { title: '100+', subTitle: 'Daily Consultation' },
            { title: '15+', subTitle: 'Total Qualified Doctors' },
          ].map(x => <Column3>
            <Div40>
              <Div41>
                <Div42>{x.title}</Div42>
                <Div43>{x.subTitle}</Div43>
              </Div41>
            </Div40>
          </Column3>
          )
          }

        </Div39>
      </Div38>
      <Div51>
        <Div52>
          <Div53>
            {aboutDoc.map(x => <Column6>
              <Div54>
                <Img14
                  loading="lazy"
                  src={x.img}
                />
                <Div55>{x.title}</Div55>
                <Div56>{x.subtitle}</Div56>
              </Div54>
            </Column6>)}
          </Div53>
        </Div52>
      </Div51>
      {/* <Div66>What Our Patient's Say</Div66>
      <Testimonials data={[{ name: 'William Johnson', text: 'I appreciate your amazing services and professional staff for all your hard work and creative thinking. There are many variations of passages of rem Ipsum available, but the majority have alteration in some form.', image: 'https://yt3.googleusercontent.com/hIL2R51j3u9v9SfFGKK4t4Ekbz84m3N_lGXoJoMaFQEMU2CR_Dk-ceg_7aG96OFAzoUpBc2KgP0=s900-c-k-c0x00ffffff-no-rj', id: 'od0', business: 'Entrepreneur' }, { name: 'Ram', text: 'I appreciate your amazing services and professional staff for all your hard work and creative thinking. There are many variations of passages of rem Ipsum available, but the majority have alteration in some form.', image: 'https://media.licdn.com/dms/image/C5103AQEZrj7AYiE5gg/profile-displayphoto-shrink_800_800/0/1576040191145?e=2147483647&v=beta&t=HNyLcC1xGC22bKVST33x9lskW5BpiDFE8wwSWkEUDco', business: 'Entrepreneur' }]} /> */}
      <Div73>
        <span >
          FAQ
        </span>
        <span >
          {" "}
        </span>
        <span >
          (Frequently Asked Questions)
        </span>
      </Div73>

      {faqs.map((faq, index) => (
        <QAComponent key={index} question={faq.question} answer={faq.answer} />
      ))}



      <Div89>
        <Div90>
          <Div91>
            <Column10>
              <Img29
                loading="lazy"
                srcSet={aredoctor}
              />
            </Column10>
            <Column11>
              <Div92>
                <Div93>Are you a Doctor?</Div93>
                <Div36>
                  If Yes, Medvatic welcomes qualified and compassionate healthcare professionals. If you're a licensed doctor, nurse practitioner, or other certified healthcare provider, we invite you to join our platform. Medvatic is committed to connecting medical experts with patients in need of virtual healthcare services. Expand your practice, set flexible hours, and provide top-notch care through our secure and user-friendly online platform. Join us in revolutionizing the future of healthcare.
                </Div36>
                <Div37
                  // /doctor/register
                  onClick={() => {
                    history.push('/doctor/register')
                  }}
                >Join Us</Div37>
              </Div92>
            </Column11>
          </Div91>
        </Div90>
      </Div89>

    </Div>
  );
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Div25 = styled.div`
  border-radius: 10px;
  border: 1px solid #dadde3;
  background-color: #f8f8f8;
  align-self: center;
  margin-top: 100px;
  width: 100%;
  max-width: 1410px;
  padding: 48px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div26 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 57%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div27 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div28 = styled.div`
  color: #0a0d14;
  align-self: stretch;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div29 = styled.div`
  display: flex;
  margin-top: 27px;
  gap: 19px;
`;

const Div30 = styled.div`
  color: #5d5d5d;
  white-space: nowrap;
  font: 500 24px/36px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div32 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 36px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 11px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Img10 = styled.img`
  aspect-ratio: 0.96;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: #26d4ff;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div33 = styled.div`
  color: #5d5d5d;
  margin: auto 0;
  font: 500 18px Roboto, sans-serif;
`;

const Img11 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 26px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div34 = styled.div`
  color: #5d5d5d;
  margin: auto 0;
  font: 500 18px Roboto, sans-serif;
`;

const Img12 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 27px;
  stroke-width: 2.3px;
  stroke: #26d4ff;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div35 = styled.div`
  color: #5d5d5d;
  align-self: start;
  margin-top: 7px;
  white-space: nowrap;
  font: 500 18px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div36 = styled.div`
  color: #5d5d5d;
  align-self: stretch;
  margin-top: 21px;
  font: 400 16px/24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div37 = styled.div`
  color: #fff;
  cursor:pointer;
  white-space: nowrap;
  border-radius: 3px;
  background-color: #00869a;
  margin-top: 39px;
  width: 235px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px 60px;
  font: 500 16px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 43%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img13 = styled.img`
  aspect-ratio: 1.62;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div38 = styled.div`
  align-self: center;
  margin-top: 100px;
  width: 100%;
  max-width: 1409px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div39 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div40 = styled.div`
  border-radius: 5px;
  border: 1px solid #dadde3;
  background-color: #f1fdff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 31px;
    padding: 0 20px;
  }
`;

const Div41 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0 15px;
`;

const Div42 = styled.div`
  color: #00869a;
  white-space: nowrap;
  font: 800 80px Roboto, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
    white-space: initial;
  }
`;

const Div43 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 40px;
  white-space: nowrap;
  font: 500 28px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div51 = styled.div`
  background-color: #f8f8f8;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div52 = styled.div`
  width: 100%;
  max-width: 1430px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div53 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 26%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div54 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Img14 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 90px;
  fill: #00869a;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
`;

const Div55 = styled.div`
  color: #000;
  align-self: center;
  margin-top: 36px;
  white-space: nowrap;
  font: 500 24px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div56 = styled.div`
  color: #5d5d5d;
  text-align: center;
  margin-top: 25px;
  font: 400 14px Roboto, sans-serif;
`;

const Div66 = styled.div`
  color: #041b1f;
  align-self: center;
  margin-top: 106px;
  white-space: nowrap;
  font: 500 36px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div73 = styled.div`
  color: #041b1f;
  align-self: center;
  margin-top: 108px;
  white-space: nowrap;
  font: 700 36px/34px Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div89 = styled.div`
  background-color: #f8f8f8;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div90 = styled.div`
  width: 1086px;
  max-width: 100%;
`;

const Div91 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column10 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img29 = styled.img`
  aspect-ratio: 1.01;
  object-fit: contain;
  object-position: center;
  width: 270px;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column11 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 73%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div92 = styled.div`
  display: flex;
  margin-top: 5px;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div93 = styled.div`
  color: #000;
  align-self: stretch;
  white-space: nowrap;
  font: 500 24px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;


export default ConsultDoctor