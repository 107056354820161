import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";

class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
    };
    this.dropRef = React.createRef();
  }

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files }, () =>
      this.props.setProfilePicture(files[0])
    );
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  render(props) {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/* <CardTitle className="mb-3">Upload profile</CardTitle> */}
            <Form>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  this.handleAcceptedFiles(acceptedFiles)
                }
                accept={"image/jpeg, image/png"}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps, inputRef }) => (
                  <>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {this.state.selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-12">
                                  <img
                                    data-dz-thumbnail=""
                                    height="150"
                                    className="avatar-sm rounded bg-light uploaded-image"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col className="uploaded-file-info center">
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      inputRef.current.click();
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    color={"danger"}
                                    className={"ml-1"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState(
                                        {
                                          selectedFiles: [],
                                        },
                                        () => this.props.setProfilePicture(null)
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>

                    <div className="file-drop" ref={this.dropRef}>
                      <div {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          accept=".png, .jpg, .jpeg"
                        />
                        <div className="dz-message needsclick file-drop-data">
                          <div>
                            <i className="display-4 text-muted bx bxs-cloud-upload upload-icon" />
                          </div>
                          <div className="d-flex flex-row justify-content-start align-item-start">
                            <div
                              style={{
                                marginRight: "10px",
                              }}
                            >
                              <i
                                style={{
                                  color: "#50D8FA",
                                }}
                                class="fa fa-plus-square"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div>
                              <h4 style={{ color: "#50D8FA" }}>
                                Upload profile picture
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Dropzone>
            </Form>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default UploadFiles;
