import $ from "jquery";
import React, { Component, useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { auth } from "../../firebase";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import { Button, Modal } from "react-bootstrap";
import { useStateValue } from "../../StateProvider";

let arrayOfLinks = [
  {
    location: "/admin/add-hospital-chain",
    label: "Add Hospital Chain",
  },
  {
    location: "/admin/add-speciality",
    label: "Add Speciality",
  },
  {
    location: "/admin/hospitals",
    label: "All Businesses",
  },
  {
    location: "/admin/users",
    label: "All Users",
  },
  {
    location: "/admin/plugin",
    label: "Plugin",
  },
  {
    location: "/admin/change-password",
    label: "Change Password",
  },
  //hospitals
  {
    location: "/hospital/doctors",
    label: "All Professionals",
  },
  {
    location: "/hospital/add-doctor",
    label: "Add Professional",
  },
  {
    location: "/hospital/receptionists",
    label: "Receptionists",
  },
  {
    location: "/hospital/patients-list",
    label: "All Users",
  },
  {
    location: "/hospital/add-receptionist",
    label: "Add Receptionist",
  },
  {
    location: "/hospital/view-requests",
    label: "View Requests",
  },
  {
    location: "/hospital/appointment-list",
    label: "All Appointments",
  },
  {
    location: "/hospital/view-patients",
    label: "View Users",
  },
  {
    location: "/hospital/change-password",
    label: "Change Password",
  },
  {
    location: "/hospital/profile",
    label: "View Hospital",
  },
  //receptionist
  {
    location: "/receptionist/patients",
    label: "All Users",
  },
  {
    location: "/receptionist/profile",
    label: "My Profile",
  },
  {
    location: "/receptionist/add-patient",
    label: "Add patient",
  },
  {
    location: "/receptionist/appointments",
    label: "All Appointments",
  },
  {
    location: "/receptionist/add-appointment",
    label: "Add Appointment",
  },
  {
    location: "/receptionist/add-schedule",
    label: "Add Schedule",
  },
  {
    location: "/receptionist/change-password",
    label: "Change Password",
  },
  //doctors
  {
    location: "/doctor/patients",
    label: "All Users",
  },
  {
    location: "/doctor/my-profile",
    label: "My Profile",
  },
  {
    location: "/doctor/edit-profile",
    label: "Edit Profile",
  },
  {
    location: "/doctor/add-patient",
    label: "Add Patient",
  },
  {
    location: "/doctor/AddDoctorDetailsPrescriber",
    label: "Add Prescriber",
  },
  {
    location: "/doctor/NewPrescription",
    label: "New Prescription",
  },
  {
    location: "/doctor/appointments",
    label: "Appointments",
  },
  {
    location: "/doctor/add-appointment",
    label: "Add Appointments",
  },
  {
    location: "/doctor/add-schedule",
    label: "Add Schedule",
  },
  {
    location: "/doctor/change-password",
    label: "Change Password",
  },
];

export const Pagetitle = ({ options }) => {
  const [title, setTitle] = useState();

  const location = useLocation();

  useEffect(() => {
    let values = options.filter(
      (option) => option.location === location.pathname
    );
    if (values.length == 0) {
      return;
    }
    setTitle(values[0].label);
  }, [location]);

  // console.log(title);
  // console.log("location",location.pathname)

  // console.log("values of arry",values)
  return <div>{title}</div>;
};

const Header = (props) => {
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [logoDestinationLink, setLogoDestinationLink] = useState("/");
  const [loading, setLoading] = useState(false);
  const [{ userPP, lat, long, country_code }, dispatch] = useStateValue();
  const [profile_pic, setProfile_pic] = useState(props.userData.profile_picture);

  useEffect(() => {
    if (userPP) {
      // setProfile_pic(userPP);
      setProfile_pic(props.userData.profile_picture);
    }
  }, [userPP]);

  useEffect(() => {
    const userType = props.user.displayName;
    switch (userType) {
      case "1":
        setLogoDestinationLink("/admin/users");

        break;
      case "2":
        setLogoDestinationLink("/hospital/doctors");

        break;
      case "3":
        setLogoDestinationLink("/doctor/patients");

        break;
      case "4":
        setLogoDestinationLink("/receptionist/patients");

        break;

      default:
        break;
    }
  }, []);

  function logoutUser(e) {
    e.preventDefault();
    let Usertype = props.type;
    setLoading(true);
    auth
      .signOut()
      .then(() => {
        let type = localStorage.getItem("@displayName");
        localStorage.removeItem("@userData");
        localStorage.removeItem("@displayName");
        localStorage.removeItem("@prefLogin");
        setLoading(false);
        setRedirect("/");
        // this.setState({
        //   redirect: "/",
        //   loading: false,
        // });
        Notify({
          alert: true,
          type: "success",
          title: "Logged out successfully.",
        });
      })
      .catch(
        () => setLoading(false)
        // this.setState({
        //   loading: false,
        // })
      );
  }

  function renderName() {
    switch (Number(localStorage.getItem("@displayName"))) {
      case 1:
        return props.userData.name;
      case 2:
        return props.userData.hospital_name
          ? props.userData.hospital_name
          : props.userData.hospitalName;
      case 3:
        return props.userData.firstName;
      case 4:
        return props.userData.firstName;
      case 5:
        return props.userData.firstName;
      case 6:
        return props.userData.name;
    }
  }

  function toggleMobileMenu(e, target) {
    e.preventDefault();
    var $sidebarOverlay = $(".sidebar-overlay");
    var $target = $(target);
    if ($target.length) {
      $target.toggleClass("opened");
      $sidebarOverlay.toggleClass("opened");
      $("html").toggleClass("menu-opened");
      $sidebarOverlay.attr("data-reff", target);
      var $wrapper = $(".main-wrapper");
      $wrapper.toggleClass("slide-nav");
    }
    $sidebarOverlay.on("click", function (e) {
      var $target = $($(this).attr("data-reff"));
      if ($target.length) {
        $target.removeClass("opened");
        $("html").removeClass("menu-opened");
        $(this).removeClass("opened");
        $(".main-wrapper").removeClass("slide-nav");
      }
      e.preventDefault();
    });
  }
  // console.log("DisplayName", props?.user?.displayName);

  function RedirectURL(url) {
    const url_1 = props?.userData?.type == 3
      ? "/doctor/change-password"
      : props?.userData?.type == 2
        ? "/hospital/change-password"
        : props?.userData?.type == 1
          ? "/admin/change-password"
          : "/receptionist/change-password"

    const url_2 = props?.userData?.type == 3
      ? "/doctor/my-profile"
      : props?.userData?.type == 2
        ? "/hospital/profile"
        : props?.userData?.type == 6 ? "/hospital/profile" : "/receptionist/profile"
    return url == 1 ? url_1 : url_2
  }
  // render() {
  //   if (this.state.loading) {
  //     return <Loader />;
  //   }
  //   const userType = this.props.user.displayName;
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="header navbar-head ">
        {redirect ? <Redirect to={redirect} /> : null}
        <div className="header-left">
          <Link
            to={logoDestinationLink}
            className="logo"
            style={{ height: "40px" }}
          >
            <img
              src="assets/img/logo1.png"
              height="30"
              alt="Medvatic"
              style={{ height: "100%", width: "auto" }}
            />
          </Link>
        </div>
        <div
          style={{
            display: "-ms-flexbox",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "14%",
          }}
        >
          <span style={{ fontSize: "1.25rem" }}>
            <Pagetitle options={arrayOfLinks} />
          </span>
        </div>
        <a
          onClick={(e) => toggleMobileMenu(e, "#sidebar")}
          className="mobile_btn float-left text-white"
        >
          <i className="fa fa-bars"></i>
        </a>

        <ul className="nav user-menu float-right">
          <li className="nav-item dropdown has-arrow">
            <a
              href="#"
              className="dropdown-toggle nav-link user-link"
              data-toggle="dropdown"
            >
              <span className="user-img mr-2">
                <img
                  className="rounded-circle"
                  src={profile_pic ? profile_pic : "assets/img/user.jpg"}
                  width="40"
                  alt="Admin"
                />
              </span>
              {props.user && <span>{renderName()}</span>}
            </a>
            <div className="dropdown-menu">
              {/* <h4 className="dropdown-head">MY ACCOUNT</h4> */}
              {props?.user?.displayName == 2 ? (
                <a className="dropdown-item">
                  Hospital Code: {props?.userData?.hospital_code}
                </a>
              ) : null}

              <Link
                to={
                  RedirectURL(1)
                }
                className="dropdown-item"
              >
                Change Password
              </Link>
              <Link
                // onClick={()=>this.setState({show:true})}
                to={
                  RedirectURL(2)
                }
                className="dropdown-item"
              >
                Profile
              </Link>
              <a onClick={() => setShow(true)} className="dropdown-item">
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div></div>

      <Modal
        backdrop="static"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setShow(false);
              logoutUser(e);
            }}
            style={{ backgroundColor: "#50D8FA" }}
            variant="primary"
          >
            Yes
          </Button>
          <Button onClick={() => setShow(false)} variant="danger">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
