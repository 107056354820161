import React, { useState } from 'react';
import styled from 'styled-components';
import prescriptionImg from '../../../src/assets/img/medical-prescription1.png';
import scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img from '../../../src/assets/img/prescription1.png';



const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 25px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 20px;
  text-align: left;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 95%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Div1 = styled.div`
  width: 35%;
`;

const Div2 = styled.div`
  width: 65%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: #333;
  margin-bottom: 10px;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: #50d8fa;
`;


const EPrescription2 = () => {
  const [fullName, setFullName] = useState('');
  const [includeDr, setIncludeDr] = useState(false);
  const [abbreviatedQualifications, setAbbreviatedQualifications] = useState('');
  const [practiceName, setPracticeName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [prescriberNumber, setPrescriberNumber] = useState('');

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleIncludeDrChange = (e) => {
    setIncludeDr(e.target.checked);
  };

  const handleAbbreviatedQualificationsChange = (e) => {
    setAbbreviatedQualifications(e.target.value);
  };

  const handlePracticeNameChange = (e) => {
    setPracticeName(e.target.value);
  };

  const handleStreetAddressChange = (e) => {
    setStreetAddress(e.target.value);
  };

  const handlePrescriberNumberChange = (e) => {
    setPrescriberNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission
    // ...
  };

  const reusableInputState = (label, valueVar, onChaneVar) => {
    console.log(valueVar)
    return <Containers>
      <Div1>
        <Label>{label}</Label>
      </Div1>
      <Div2>
        <Input type="text" value={valueVar} onChange={onChaneVar} />
      </Div2>
    </Containers>
  }
  const reusableMenuState = (label, onClickHandleMethod, icon) => {
    return <SidebarOption onClick={() => onClickHandleMethod()}>
      <i className={icon}></i><SidebarOptionText>{label}</SidebarOptionText>
    </SidebarOption>
  }
  const onClickHandle = () => {
    console.log('hi there')
  }
  return (
    <Container>

      <div style={{ display: 'flex' }}>
        <div style={{ width: '20%' }}>

          <Sidebar>
            <SidebarHeading>Medvatic</SidebarHeading>

            {reusableMenuState('Admin Dashboard', onClickHandle, "fa fa-address-card")}
            {reusableMenuState('Doctor Dashboard', onClickHandle, "fa fa-user-plus")}
            {reusableMenuState('Patient Dashboard', onClickHandle, "fa fa-home")}
            {reusableMenuState('Pharmacist Dashboard', onClickHandle, "fa fa-home")}
            {reusableMenuState('Analysis', onClickHandle, "fa fa-line-chart")}
            {reusableMenuState('Appointment', onClickHandle, "fa fa-stethoscope")}
            {reusableMenuState('Doctors', onClickHandle, "fa fa-user-md")}
            {reusableMenuState('Patients', onClickHandle, "fa fa-male")}
            {reusableMenuState('Prescription', onClickHandle, "fa fa-clipboard")}
            {reusableMenuState('Service', onClickHandle, "fa fa-newspaper-o")}
            {reusableMenuState('Department', onClickHandle, "fa fa-outdent")}
            {reusableMenuState('Pharmacy', onClickHandle, "fa fa-medkit")}
            {reusableMenuState('Finance', onClickHandle, "fa fa-money")}
            {reusableMenuState('Support', onClickHandle, "fa fa-phone-square")}
            {reusableMenuState('Chat', onClickHandle, "fa fa-comments-o")}
            {reusableMenuState('Settings', onClickHandle, "fa fa-cog")}
            {reusableMenuState('Logout', onClickHandle, "fa fa-sign-out")}

          </Sidebar>
        </div>
        <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
          <Content>

            <ButtonGroup>
              {/* scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img */}
              <Button style={{ fontWeight: '400', fontSize: '21px' }}>
                <img
                  src={prescription1Img}
                  alt="new"
                  style={{ width: '40px', height: '40px' }}
                />
                New Rx</Button>
              <Button style={{ fontWeight: '400', fontSize: '21px' }}>

                <img
                  src={scriptImg}
                  alt="new"
                  style={{ width: '40px', height: '40px' }}
                />
                Scripts</Button>
              <Button style={{ backgroundColor: '#FF8E3C', fontWeight: '400', fontSize: '21px' }}>
                <img
                  src={prescriptionImg}
                  alt="new"
                  style={{ width: '40px', height: '40px' }}
                />
                Prescribers</Button>
            </ButtonGroup>
            <ButtonGroup2>
              <Label1>Prescriber Details</Label1>
              <ButtonNormal onClick={() => console.log('adding new prescriber')}>Add New prescriber</ButtonNormal>
            </ButtonGroup2>

            <Form style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '100%', backgroundColor: '#fff' }} onSubmit={handleSubmit}>
              <Heading>New Prescription</Heading>
              <SubNote>Complete all sections required for your prescription</SubNote>

              <div>
                <Heading>Patient Details</Heading>
                {/* <Label>
                  Full Name:
                  <Input type="text" value={fullName} onChange={handleFullNameChange} />
                </Label>
                <Containers>
                  <Div1>
                    <Label>
                      Full Name:
                    </Label>
                  </Div1>
                  <Div2>
                    <Input type="text" value={fullName} onChange={handleFullNameChange} />
                  </Div2>
                </Containers> */}
                {reusableInputState('Full Name:', fullName, handleFullNameChange)}
                {reusableInputState('Street Address:', prescriberNumber, handlePrescriberNumberChange)}

                {reusableInputState('Medicare Number:', abbreviatedQualifications, handleAbbreviatedQualificationsChange)}
                {reusableInputState('IRN:', practiceName, handlePracticeNameChange)}
                <Heading>Medication Details</Heading>
                {reusableInputState('Active ingredient:', streetAddress, handleStreetAddressChange)}
                {reusableInputState('Dosage directions:', streetAddress, handleStreetAddressChange)}
                <CheckboxLabel>
                  <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                  PBS Prescription <br />
                </CheckboxLabel>
                <SubNote>Select a medication from the dropdown list for PBS information</SubNote>
                {reusableInputState('Quantity:', streetAddress, handleStreetAddressChange)}
                {reusableInputState('Repeats:', streetAddress, handleStreetAddressChange)}
                <div style={{ borderWidth: '3px', borderColor: '#000' }}></div>

                {/* <Heading>Medication details</Heading> */}
                <CheckboxLabel>
                  <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                  Authority required
                </CheckboxLabel>
                <SubNote>Select a medication from the dropdown list for authority information</SubNote>
                <SubNote>Authority script number: 0000555</SubNote>
                {reusableInputState('Authority code (where applicable):', streetAddress, handleStreetAddressChange)}
                {reusableInputState('Clinical justification for use of item:', streetAddress, handleStreetAddressChange)}
                {reusableInputState("Patient's age if under 18:", streetAddress, handleStreetAddressChange)}

                <CheckboxLabel>
                  <Checkbox type="checkbox" checked={includeDr} onChange={handleIncludeDrChange} />
                  Patient has received authority for this medicine before
                </CheckboxLabel>
                <SubNote>Complete all sections required for your prescription</SubNote>

                {reusableInputState("Date", streetAddress, handleStreetAddressChange)}

                {/* <Label>
                  Prescriber Number:
                  <Input type="text" value={prescriberNumber} onChange={handlePrescriberNumberChange} />
                </Label> */}
              </div>

              {/* <SubmitButton type="submit">Generate Prescription</SubmitButton>
              <SubmitButton type="submit">Cancel</SubmitButton> */}
              <ButtonGroup>
                {/* scriptImg from '../../../src/assets/img/script1.png';
import prescription1Img */}
                <Button style={{ fontWeight: '400', fontSize: '16px' }}>
                  Generate Prescription</Button>
                <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }}>
                  Cancel</Button>
              </ButtonGroup>
            </Form>
          </Content></div>
      </div>
    </Container>
  );
};

export default EPrescription2;
