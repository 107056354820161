import React, { Component } from "react";

export default class MobileTerms extends Component {
  render() {
    return (
      <section class="section-padding">
        <div class="section-header2">
          <h3 className="header-title text-center">TERMS OF USE</h3>
          <div className="line"></div>
          <div className="policy">
            <p style={{ textAlign: "center" }}>
              <strong>
                <em>Welcome to MEDVATIC</em>
              </strong>
            </p>
            <p>
              PLEASE READ THESE TERMS OF USE CAREFULLY AND THE PRIVACY POLICY.
              BY CONTINUING TO ACCESS AND USE THIS WEBSITE/APPLICATION YOU
              CONFIRM THAT YOU ACCEPT OUR TERMS AND CONDITIONS DESCRIBED HEREIN.
              THESE TERMS &amp; PRIVACY POLICY TOGETHER CONSTITUTE A LEGAL
              AGREEMENT BETWEEN YOU AND MEDVATIC IN CONNECTION WITH YOUR VISIT
              TO THE WEBSITE/APPLICATION AND USE OF OUR SERVICES. IF YOU DO NOT
              AGREE THE TERMS OF USE, DO NOT USE THIS APPLICATION. WHERE ANY
              PART OF THE AGREEMENT IS MODIFIED IN ACCORDANCE WITH THE TERMS OF
              THE AGREEMENT, THE AGREEMENT SHALL BE ENFORCEABLE IN ITS MODIFIED
              FORM.
            </p>
            <p>
              <strong>
                The Agreement supersedes all previous oral and written terms and
                conditions (if any) communicated to you.
              </strong>
            </p>
            <p>
              The terms &ldquo;You&rdquo; or &ldquo;Your&rdquo; refer to you as
              the user (irrespective of whether You are a Registered User
              (defined hereunder), HSP Registered User (defined hereunder) or a
              Non-Registered User (defined hereunder)) and the terms
              &ldquo;MEDVATIC&rdquo;, &ldquo;We&rdquo;, &ldquo;Us&rdquo;,
              &ldquo;Company&rdquo;, &ldquo;Our&rdquo; refer to MEDVATIC LLP. In
              the event the Application is being used by a person on your
              behalf, the expression &ldquo;You&rdquo; or &ldquo;Your&rdquo; or
              the &ldquo;User&rdquo; shall apply to such person as well.
            </p>
            <ol>
              <li>
                <strong>DEFINITIONS:</strong>
              </li>
            </ol>
            <p>
              <strong>APPLICATION</strong>
            </p>
            <p>
              MEDVATIC is the author and publisher of the Application, which
              shall mean the mobile application, website, blog contained
              therein, queries answered by Our team over email, phone or any
              other medium and all variants, tools, editions, add-ons, and
              ancillary products or services of the Application (including all
              files and images contained therein or generated by the software,
              and accompanying data, herein referred to individually or
              collectively as &ldquo;Application&rdquo;). The Application may be
              used by Users (defined below) to avail Services and/or to find,
              access, manage and organize information including but not limited
              to personal or non-personal information, doctor/consultant
              information, appointments, prescriptions, laboratory and
              diagnostic tests, drug delivery, electronic medical records,
              billing, inventory, accounting details and other relevant
              information.
            </p>
            <p>
              The Application is for Post-consultative patient care management,
              measurement request recording, medication remainder, appointment
              scheduling, Care team management, exercise activities, treatment
              plan creation and management, Lab reports, Incidents report etc.
              (the 'Services').
            </p>
            <p>
              <strong>USERS</strong>
            </p>
            <p>&ldquo;Registered Users&rdquo; are users:</p>
            <ul>
              <li>
                who have successfully registered themselves with MEDVATIC
                through the Website/ Application by providing information that
                is true and accurate, and who can log on to their account on the
                Application by providing their username and password
                (&ldquo;Primary User&rdquo;); or
              </li>
              <li>
                whose profile has successfully been created on the Application
                under the account of a Primary User, by providing information
                that is true and accurate (&ldquo;Secondary User&rdquo;)
              </li>
            </ul>
            <p>
              &ldquo;Healthcare Service Providers&rdquo; or &ldquo;HSPs&rdquo;
              are third parties including but not limited to clinic or hospital
              listed on the Application, also, includes their duly qualified
              doctors, nurses, paramedical staff, physiotherapists, consultants,
              medical equipment suppliers, laboratory service providers,
              phlebotomist, pharmacist other incidental and ancillary healthcare
              service providers listed on the Application and who interacts with
              Users as part of the Services.
            </p>
            <p>
              &ldquo;HSP Registered Users&rdquo; are Registered Users whose
              profile has a unique identification attached to it including but
              not limited to UHID (unique health identification). HSP Registered
              Users may not be permitted to access to certain areas of the
              Application.
            </p>
            <p>
              &ldquo;Non-Registered Users&rdquo; are users who have not
              registered themselves on the Application. Non-Registered Users may
              not be provided access to certain areas of the Application.
            </p>
            <p>
              Registered Users, HSP Registered Users and Non-Registered Users
              shall individually or collectively be referred to as
              &ldquo;Users&rdquo;.
            </p>
            <ol start="2">
              <li>
                <strong>ACCEPTANCE OF TERMS</strong>
              </li>
            </ol>
            <p>
              You accept the Terms by using or browsing the Application. You may
              also accept the Terms by clicking to accept or agree to the Terms
              where this option is made available to you by MEDVATIC in the user
              interface.
            </p>
            <p>
              MEDVATIC reserves the right to review and change any of the Terms
              by updating this page at its sole discretion. When MEDVATIC
              updates the Terms, it will use reasonable endeavors to provide you
              with notice of updates to the Terms. Any changes to the Terms take
              immediate effect from the date of their publication. Before you
              continue, we recommend you keep a copy of the Terms for your
              records. If you have any questions about any part of the
              Agreement, feel free to contact us at{" "}
              <strong>info@medvatic.com.</strong>
            </p>
            <p>
              By downloading or accessing the Application to use the Services,
              you irrevocably accept all the conditions stipulated in this
              Agreement, the Subscription Terms of Service and Privacy Policy,
              as available on the Website, and agree to abide by them. Your
              access to use of the Website and the Services will be solely at
              the discretion of MEDVATIC.
            </p>
            <p>
              The Agreement is published in compliance of, and is governed by
              the provisions of Indian law, including but not limited to:
            </p>
            <ol>
              <li>the Indian Contract Act, 1872,</li>
              <li>the (Indian) Information Technology Act, 2000, and</li>
            </ol>
            <ul>
              <li>
                the rules, regulations, guidelines and clarifications framed
                there under, including the (Indian) Information Technology
                (Reasonable Security Practices and Procedures and Sensitive
                Personal Information) Rules, 2011 (the&nbsp;
                <strong>&ldquo;SPI Rules&rdquo;</strong>), and the (Indian)
                Information Technology (Intermediaries Guidelines) Rules, 2011
                (the&nbsp;<strong>&ldquo;IG Rules&rdquo;</strong>).
              </li>
            </ul>
            <ol start="3">
              <li>
                <strong>CONDITIONS &amp; GENERAL TERMS OF USE</strong>
              </li>
            </ol>
            <p>
              You must be 18 years of age or older to register, use the
              Services, or visit or use the Website/Application in any manner.
              By registering, visiting and using the Website/Application or
              accepting this Agreement, you represent and warrant to MEDVATIC
              that you are 18 years of age or older, and that you have the
              right, authority and capacity to use the Application and the
              Services available through the Application, and agree to and abide
              by this Agreement. In case the Application or Services are to be
              accessed or used by a minor (i.e. a person less than 18 years of
              age), then only the legal guardian/representative of such person
              shall register himself/herself as a Primary User and create/add
              the profile of the minor as a Secondary User.&nbsp;
            </p>
            <p>
              All Services will be requested for, and provided to, the Primary
              User only. A Secondary User may avail the Services rendered to the
              Primary User only as a beneficiary. The Primary User will be
              entirely responsible and accountable for the Secondary
              User&rsquo;s activity on the Application, as if the Application
              and Services were being accessed and used by the Primary User.
              However, this shall not discharge the Secondary User who is adult
              and of sound mind from liability towards MEDVATIC or its
              contractors or agents and MEDVATIC shall have the right to proceed
              against Secondary User and Primary User, either jointly or
              severally, for acts and omissions of Secondary User that violate
              the Agreement.
            </p>
            <p>
              You will use the Application and the features provided on the
              Application only in relation to and in compliance with all
              applicable Indian laws. You will not use this Application, or any
              feature provided on the Application for any purposes not intended
              under this Agreement.
            </p>
            <p>
              You should limit the use of this Application to India only. We are
              not responsible if You avail any Service from outside India. You
              must have the advice and/or prescription given by such HSP
              validated by a local registered medical practitioner.
            </p>
            <p>
              Certain Services are location specific. Depending on Your location
              certain Services may not be available to You.
            </p>
            <p>
              You will not deliberately use the Application in any way that is
              unlawful or harms MEDVATIC, its directors, employees, affiliates,
              distributors, partners, service providers and/or any User and/or
              data or content on the Application.
            </p>
            <p>
              You will not use the Application for any illegal, immoral,
              offensive purpose or for any other purpose that may infringe or
              violate any applicable laws and regulations.
            </p>
            <p>
              You will not interfere, disrupt or hamper with the proper
              functioning of the Application.
            </p>
            <p>
              As a User, You may have access to business sensitive information.
              You shall not share such information with MEDVATIC&rsquo;s direct
              competitors or use it for competitive purposes, except with
              MEDVATIC&rsquo;s prior written consent.
            </p>
            <p>
              You understand that as part of Your registration process as well
              as in course of Your use of the Application, You may receive
              communication from MEDVATIC on Your registered contact
              information. These communications will relate to Your
              registration, Services provided by MEDVATIC, transactions that You
              carry out through the Application and any such information found
              suitable of Your attention by MEDVATIC. Please note that MEDVATIC
              will send these communications only to the contact details that
              You or HSP provide on the Application. It is Your responsibility
              to ensure that You provide the correct contact details for the
              transaction You wish to enter. Further, MEDVATIC may also send
              notifications and reminders to You for the features that You may
              be using on the Application. By accepting these Terms of Use You
              provide Us Your consent to collect Your medical records and/or
              reports from HSP. MEDVATIC may also share the dynamic password
              with You in order to facilitate the online delivery of your
              medical report. You hereby consent to receive such communications
              from MEDVATIC.
            </p>
            <p>
              You will not share Your log-in details with anyone. You are
              responsible for maintaining the confidentiality of Your account
              access information and password. You shall be responsible for all
              usage of Your account and password, whether or not authorized by
              You. You shall immediately notify MEDVATIC of any actual or
              suspected unauthorized use of the Your account or password.
              MEDVATIC will not be liable for Your losses caused by any
              unauthorized use of Your account. You may be liable for the losses
              of MEDVATIC or others due to such unauthorized use.
            </p>
            <ol start="4">
              <li>
                <strong>SPECIFIC TERMS FOR USERS (EXCLUDING HSP)</strong>
              </li>
            </ol>
            <p>
              The terms &ldquo;personal information&rdquo; and &ldquo;sensitive
              personal data or information&rdquo; are defined under the SPI
              Rules, and are reproduced in the Privacy Policy. MEDVATIC may by
              its Services, collect information relating to the devices through
              which you access the Website, and anonymous data of your usage.
              The collected information will be used only for improving the
              quality of MEDVATIC&rsquo;s services and to build new services.
            </p>
            <p>
              The Website allows MEDVATIC to have access to registered
              Users&rsquo; personal email or phone number, for communication
              purpose so as to provide you a better way of booking appointments
              and for obtaining feedback in relation to the Practitioners and
              their practice.
            </p>
            <p>
              The Privacy Policy sets out,&nbsp;<em>inter-alia</em>:
            </p>
            <ul>
              <li>
                The type of information collected from Users, including
                sensitive personal data or information;
              </li>
              <li>
                The purpose, means and modes of usage of such information;
              </li>
              <li>
                How and to whom MEDVATIC will disclose such information; and,
              </li>
              <li>Other information mandated by the SPI Rules.</li>
            </ul>
            <p>
              The User is expected to read and understand the Privacy Policy, so
              as to ensure that he or she has the knowledge of,&nbsp;
              <em>inter-alia</em>:
            </p>
            <ul>
              <li>the fact that certain information is being collected;</li>
              <li>the purpose for which the information is being collected;</li>
              <li>the intended recipients of the information;</li>
              <li>
                the nature of collection and retention of the information; and
              </li>
              <li>
                the name and address of the agency that is collecting the
                information and the agency that will retain the information; and
              </li>
              <li>
                the various rights available to such Users in respect of such
                information.
              </li>
            </ul>
            <p>
              MEDVATIC shall not be responsible in any manner for the
              authenticity of the personal information or sensitive personal
              data or information supplied by the User to MEDVATIC or to any
              other person acting on behalf of MEDVATIC.
            </p>
            <p>
              If a User provides any information that is untrue, inaccurate, not
              current or incomplete (or becomes untrue, inaccurate, not current
              or incomplete), or MEDVATIC has reasonable grounds to suspect that
              such information is untrue, inaccurate, not current or incomplete,
              MEDVATIC has the right to discontinue the Services to the User at
              its sole discretion.
            </p>
            <p>
              MEDVATIC may use such information collected from the Users from
              time to time for the purposes of debugging customer support
              related issues.
            </p>
            <ol start="5">
              <li>
                <strong>ACCESS AND USAGE RIGHTS OF USERS</strong>
              </li>
            </ol>
            <p>
              Non - Registered User: A Non - Registered User is not permitted to
              access or make use of the Application for any purpose other than
              the following permitted use:
            </p>
            <ul>
              <li>To browse the Application without accessing any Services;</li>
              <li>To use Self-Assessment Tool;</li>
              <li>To share the Application via social media applications.</li>
            </ul>
            <p>
              HSP Registered Users: A HSP Registered User is only permitted to
              access and use the Services provided through a particular HSP only
              or any other limitations and restrictions as are prescribed under
              the Agreement or may be otherwise communicated to HSP Registered
              User from time to time through any contact information shared by
              HSP or HSP Registered User at the time of registration.
            </p>
            <p>
              Registered User: A Registered User is specifically permitted to:
            </p>
            <ul>
              <li>
                Enjoy all the access and usage rights of a Non-Registered User;
              </li>
              <li>
                Access and use the Services subject to such limitations and
                restrictions as are prescribed under the Agreement or may be
                otherwise communicated to Registered User from time to time
                through any contact information shared by such user at the time
                of registration.
              </li>
            </ul>
            <ol start="6">
              <li>
                <strong>CONTENT</strong>
              </li>
            </ol>
            <p>
              The data and information available on the Application may be
              categorized as follows:
            </p>
            <ol>
              <li>
                Data and information generated or provided by Unregistered
                Users, HSP Registered Users and Registered Users on the
                Application (&ldquo;User Content&rdquo;).
              </li>
              <li>
                Data and information which MEDVATIC (through itself or its
                contractors) has generated or procured for the Application and
                includes data and information generated or provided by HSP
                (&ldquo;MEDVATIC Content&rdquo;).
              </li>
            </ol>
            <ol start="7">
              <li>
                <strong>SERVICES</strong>
              </li>
            </ol>
            <p>MEDVATIC a transaction facilitator/Aggregator</p>
            <p>
              Through the Application, MEDVATIC facilitates the purchase of
              Services offered for sale by HSP. You understand and agree that
              MEDVATIC merely provide hosting services to You. All Services
              offered for sale on the Application are owned by HSP. MEDVATIC has
              no control over HSP Services and does not originate or initiate
              the transmission or select the sender/recipient of the
              transmission. The authenticity and genuineness of the Services
              made available by HSP through the Application shall be the sole
              responsibility of HSP. You understand and agree that Application
              shall have no liability with respect to the authenticity of the
              Services being facilitated through the Application.
            </p>
            <p>
              <strong>Use of Services</strong>
            </p>
            <p>
              The Application may be used to access a variety of healthcare and
              related services. Depending on the status of Your registration and
              subject to the limitations and restrictions imposed by the
              Agreement or by MEDVATIC, You may avail following services through
              the Application:
            </p>
            <ol>
              <li>
                Online Consultations (through Medvatic care team doctors and/or
                HSP);
              </li>
              <li>Home examination;</li>
              <li>Lab/sample collections;</li>
              <li>Booking radiology services;</li>
              <li>
                Delivery of pharmaceutical goods and services offered for sale
                by the HSP;
              </li>
              <li>Doctor prescribed medicines/drugs delivery;</li>
              <li>OTC drugs delivery;</li>
              <li>
                Home services including but not limited to vaccination non
                stress test, physiotherapy or any other therapy;
              </li>
              <li>Long term care programs for chronic diseases;</li>
              <li>Follow up service;</li>
              <li>Post-surgery care;</li>
              <li>Second opinion service;</li>
              <li>Patient data storage service;</li>
              <li>
                Built-in app features such as health trackers, medicine
                reminders, storage and display of Your medical records,
                information and history, including general medical information,
                outpatient records, discharge summaries, prescriptions, lab
                investigations and radiology reports along with information
                regarding Your family members;
              </li>
              <li>
                Such other services as may be intimated from time to time.
              </li>
            </ol>
            <p>
              The aforesaid list of services is jointly referred to as
              &ldquo;Services.&rdquo;
            </p>
            <p>
              The Services are non-transferable and only cover the Registered
              User.
            </p>
            <p>
              You agree and acknowledge that the property and title in the drugs
              ordered by You shall stand immediately transferred to You upon the
              dispensation of drugs and the raising of the invoice at the
              concerned HSP. Accordingly, the sale of drugs is concluded at the
              concerned HSP itself. Post Your acceptance of the drugs, the
              related Services would not be eligible for any exchange or refund.
            </p>
            <p>
              The minimum age requirement of 18 years does not apply to any
              Service that is open to children and adolescents from Age 1-18.
            </p>
            <p>
              <strong>Online Consultations</strong>
            </p>
            <p>
              You are advised not to use the Medvatic online consultation
              services in case of any medical emergencies like accidents,
              bleeding injuries, burns, sexual abuse/assault, medico legal cases
              or if You are in a critical condition.
            </p>
            <p>
              If You are a Registered User but do not satisfy the above
              pre-conditions, please do not avail the online consultation
              services. MEDVATIC will not be responsible or liable for any harm
              or loss that You may suffer if You elect to access those Services.
            </p>
            <p>
              All Users, who are patients, and who opt for online consultation
              services, may be required to undergo an initial medical
              examination to ascertain and record medical history, medication
              history and medical status before video-consulting support can be
              provided.
            </p>
            <p>
              Online consultation services are provided at the express consent
              by the User and the same shall not be construed as a replacement
              for physical consultation and the Services are meant for general
              consultation only. If after online consultation, if it is
              recommended to undergo any diagnostic tests or if You are issued
              with a Prescription, the same are provided based on the
              information and preliminary examination, hence the same shall not
              be treated as accurate, final and conclusive. HSP reserves their
              rights to modify the prescription or recommended diagnostic tests
              if the User provides any additional information in future
              consultation.
            </p>
            <p>
              Online consultation services may be provided via the following
              communication channels Call Center Helplines / Telephones /
              Mobiles / IM Chats / Online Chats / SMS &amp; Text Chats with and
              without camera and video facilities at the sole discretion of
              MEDVATIC. Reasonable efforts will be made to protect Your privacy
              and confidentiality across Health Consultation Services.
            </p>
            <p>
              <strong>
                MEDVATIC&rsquo; RIGHTS TO THE APPLICATION AND MEDVATIC CONTENT
              </strong>
            </p>
            <p>
              MEDVATIC respects the intellectual property rights of others, and
              We expect the same from You. The Application, User Content and the
              MEDVATIC Content are protected by applicable intellectual property
              laws.
            </p>
            <p>
              MEDVATIC&rsquo;s Rights to the Application and MEDVATIC Content:
            </p>
            <p>
              All intellectual property in and to the Application and all
              software, techniques and processes used in connection with the
              aforesaid, belongs exclusively to MEDVATIC or its licensors. As a
              User, You are granted a limited, non-exclusive, non-transferable
              license to access and use the Application as per the terms of the
              Agreement. Through Your use of the Application, by no means are
              any intellectual property rights impliedly or expressly granted by
              MEDVATIC to You in respect of such works.
            </p>
            <p>
              MEDVATIC and/or its licensors assert all proprietary rights in and
              to all names and trademarks contained in the Application.
              Notwithstanding the generality of the foregoing, the name and logo
              of &ldquo;Medvatic&rdquo; is/are the trademarks and copyright
              (inclusive of any other applicable intellectual property right) of
              MEDVATIC and/or its licensors. Any use of such name or logo,
              unless otherwise authorized in a written agreement, will
              constitute an infringement upon the trademark and copyright (or
              any other such intellectual property right) of MEDVATIC and/or its
              licensors and may be actionable under applicable laws.
            </p>
            <p>
              Except as stated otherwise in this Agreement, all MEDVATIC Content
              (including all intellectual property rights to such content) is
              owned exclusively by MEDVATIC and/or its licensors.
            </p>
            <p>
              When You use the Application in accordance with this Agreement, a
              limited royalty-free right is granted to You by MEDVATIC to use
              MEDVATIC Content only for personal and non-commercial use in India
              for the term of the Agreement.
            </p>
            <p>
              Unless explicitly stated herein, nothing in this Agreement shall
              be construed as conferring on You any license to intellectual
              property rights, whether by estoppel, implication, or otherwise.
            </p>
            <p>
              Unless expressly authorized by MEDVATIC, You agree not to
              reproduce, modify, sell, distribute, mirror, frame, republish,
              download, transmit, or create derivative works of the MEDVATIC
              Content, in whole or in part, by any means. You must not remove or
              modify any copyright or trademark notice, or other notice of
              ownership.
            </p>
            <p>Rights in User Content:</p>
            <p>
              You agree that You are the sole owner of all rights (including all
              intellectual property rights) in the User Content that You post on
              the Application or provide to MEDVATIC.
            </p>
            <p>
              To the extent permissible by law, You hereby grant MEDVATIC a
              perpetual, non-revocable, worldwide, royalty-free license to make
              use of such User Content including the right to copy, distribute,
              display, reproduce, modify, adapt, the User Content, and create
              derivate works of the User Content.
            </p>
            <p>
              <strong>USER CONSENT &amp; PRIVACY</strong>
            </p>
            <p>
              You have the option of uploading Your identifiable health
              information including physical, physiological and mental health
              condition, and medical records and history on the Application
              which may be accessed by MEDVATIC and/or HSPs using the
              Application, during the course of providing Services to You. If
              You are a Secondary User, Your information may be provided to Us
              by the Primary User under whose account you are using and
              accessing the Application and Services. If you have not lawfully
              authorized the Primary User to do so, kindly reach out to us
              immediately at info@medvatic.com. By continuing to access and use
              the Application and Service, You confirm that You as a Secondary
              User have authorized Primary User to provide Your information to
              us and on the basis of information provided by the Primary User
              Medvatic care team doctors and/or HSP would generate the
              prescription in the Secondary User&rsquo;s name.
            </p>
            <p>
              We may collect various personal information from You when You use
              the Application and for the features provided by the Application.
            </p>
            <p>
              Our Privacy Policy sets out terms with respect to the collection,
              storage, use, processing, transfer and disclosure of any such
              personal information.
            </p>
            <p>
              When You click on the &lsquo;I accept&rsquo; tab at the time of
              registering or access the Application or otherwise provide Your
              personal information, You specifically consent to Our Privacy
              Policy &amp; Terms of Use.
            </p>
            <p>
              MEDVATIC may disclose/transfer Your Personal Information in
              accordance with applicable law and for the purposes as set out in
              the Privacy Policy.
            </p>
            <p>
              MEDVATIC is required by law to maintain the privacy and
              confidentiality of Your medical information. We assure to take
              Your privacy seriously and to maintain privacy and confidentiality
              of the information provided by You to Us. We, however, do not
              represent, warrant or guarantee that Our safeguards to prevent
              unauthorized access to Your personal information is fool-proof.
              Further, MEDVATIC may itself have to access Your medical
              information (medical records, consultation history, transcripts
              etc.) in order to investigate any deficiencies, complaints or
              grievances that You bring to the notice of MEDVATIC. You hereby
              specifically authorize MEDVATIC to access Your medical information
              in such cases.
            </p>
            <p>
              <strong>OTHER WEBSITES/MOBILE APPLICATIONS</strong>
            </p>
            <p>
              You may be provided with links on the Application that direct You
              to third party websites / applications / content or service
              providers (collectively &ldquo;Third Party Content&rdquo;).
            </p>
            <p>
              Links to such third party applications are provided for Your
              convenience only. Please exercise Your independent judgment and
              prudence when visiting / using any Third Party Content via a link
              available on the Application. Should You decide to click on the
              links to visit such Third Party Content, You do so on Your own
              volition. Your usage of such Third Party Content is subject to the
              terms of use of the respective Third Party Content and MEDVATIC is
              not responsible for Your use of any Third Party Content.
            </p>
            <p>
              We do not extend the Agreement to any other websites/applications
              except for this Application. We do not make any warranty or give
              any security to the personal information disclosed by You to the
              other websites/applications, even if such websites/applications
              are linked to our Application or they are using our Application
              link.
            </p>
            <p>
              MEDVATIC does not endorse any Third Party Content that You may be
              directed to from the Application.
            </p>
            <p>
              <strong>MEDVATIC RESPONSIBILITIES</strong>
            </p>
            <p>HSPs</p>
            <p>
              MEDVATIC will make available qualified HSPs to render Services
              after carrying out appropriate background and reference
              checks.&nbsp;
            </p>
            <p>
              It is hereby clarified MEDVATIC&rsquo; obligation to carry out
              appropriate background and reference checks and to validate
              qualification of HSPs does not go beyond ensuring that the HSPs
              were registered with relevant state professional councils or
              central professional council at the time of engagement and had a
              certificate of good standing from the relevant professional
              council at the time engagement.
            </p>
            <p>Service Levels</p>
            <p>
              Subject to applicable law, MEDVATIC will use commercially
              reasonable efforts to make the Services available to Registered
              Users 24 hours a day, 7 days a week, except for:
            </p>
            <ol>
              <li>
                planned downtime that will be communicated in advance to
                Registered Users;
              </li>
              <li>
                any unavailability caused by circumstances beyond
                MEDVATIC&rsquo;s reasonable control, including without
                limitation, cyber-attacks, acts of God, acts of government or
                regulatory authority, any mandate under applicable law, flood,
                fire, earthquakes, civil unrest, acts of terror, strikes or
                other labor problems, or internet service provider or/and web
                hosts failures or delays, denial of service attack, and/or Your
                acts or omissions, equipment, software or any other technology
                of Your, or any third party acting on Your behalf
              </li>
              <li>
                When such Service is rendered under a time-schedule, in which
                case, the obligation of MEDVATIC under this clause shall be
                limited to making available such Service during the
                time-schedule. This obligation will also be subject to (2) and
                (3) above;
              </li>
              <li>
                When the Registered User is outside the operational coverage of
                Service.
              </li>
            </ol>
            <p>
              MEDVATIC has no responsibility towards Non-registered User for
              providing Services.
            </p>
            <p>
              For the avoidance of doubt, it is hereby clarified that
              MEDVATIC&rsquo; responsibility towards Registered User will be
              limited to the provision of the Services as per the terms and
              conditions of the Agreement.
            </p>
            <p>
              If MEDVATIC suspects any illegal, wrongful or fraudulent activity
              on the Application by any User, notwithstanding any other rights
              MEDVATIC may have against such User, MEDVATIC reserves the right
              to inform the relevant government or law enforcement authorities.
              MEDVATIC will cooperate in all investigative procedures or
              requests for access / information initiated by any such government
              or law enforcement authorities.
            </p>
            <p>
              MEDVATIC reserves the right to add new functionality, remove
              existing functionality, and modify existing functionality to its
              Services as and when it deems fit, and make any such changes
              available in newer versions of Application at its discretion. All
              Users of its Services will be duly notified upon release of such
              newer versions and MEDVATIC reserves the right to automatically
              upgrade all Users to the latest version of its Services as and
              when it deems fit.
            </p>
            <p>
              The Application and Services may be subject to certain
              limitations, such as, limits on storage space, limit on bandwidth,
              unexpected downtime, unavailability of HSPs due to unforeseen
              circumstances, etc.
            </p>
            <p>
              MEDVATIC reserves the right to schedule/reschedule/cancel
              appointments and swap HSPs at any time without prior intimation.
            </p>
            <p>
              MEDVATIC reserves the right to refuse Service at any time without
              providing any reasons. The terms applicable to refund and
              cancellation are specified below.
            </p>
            <p>Legal Compliances</p>
            <p>
              MEDVATIC will strictly adhere to and adopt all reasonable measures
              to ensure compliance to all central and state government
              regulations and directives as applicable from time-to-time.
            </p>
            <p>Data Recording, Storage, Encryption &amp; Usage</p>
            <p>
              The information stored on the Application may be entered by You or
              by a HSP in the course of services provided by MEDVATIC. The
              information collected as part of the Services may be stored on
              Your mobile device as well as on a third-party cloud platform
              (&ldquo;Cloud Service Provider&rdquo;) or server (&ldquo;Storage
              Service Provider&rdquo;).
            </p>
            <p>
              All video-consultations / IM chat consultations / text messages /
              audios / videos / transcripts / prescriptions / email and hard
              copy correspondences between Users and HSP Acquired Users who are
              patients, and HSPs engaged by MEDVATIC shall be recorded, saved
              and stored for record purposes and in the event such records are
              required to be produced as evidence on the direction of a court of
              law. MEDVATIC may review this data from time to time for quality
              evaluation purposes. However, under normal circumstances, MEDVATIC
              will not access any information that identifies You and shall
              either omit, mask or anonymize Your personal information at the
              time of quality evaluation.
            </p>
            <p>
              It is hereby clarified that MEDVATIC does not have the obligation
              to pre-screen or monitor User Content at any time. However,
              MEDVATIC may elect to monitor any User Content and may modify or
              remove any User Content from the Application if MEDVATIC
              determines in its sole discretion that such User Content is in
              violation of this Agreement or any applicable law and best
              practices. Where MEDVATIC removes any User Content from the
              Application, MEDVATIC will make reasonable efforts to inform the
              Registered User who had posted such User Content.
            </p>
            <p>
              All User Content shall be recorded, saved and stored
              electronically on a secure cloud database/server managed by the
              Cloud Service Provider / Storage Service Provider. MEDVATIC shall
              take best efforts to ensure that the Cloud Service Provider/
              Storage Service Provider uses appropriate levels of encryptions to
              protect data and takes all necessary precautions. MEDVATIC will
              however not be responsible for any cyber data theft from its
              Application or the Cloud Service Provider&rsquo;s/ Storage Service
              Provider&rsquo;s databases.
            </p>
            <p>
              MEDVATIC shall maintain a detailed transaction &amp; purchase
              history of Users online.
            </p>
            <p>
              MEDVATIC reserves the right to permanently delete User Content in
              case the User does not avail a Service for a period of two years.
            </p>
            <p>
              <strong>USER RESPONSIBILITIES</strong>
            </p>
            <p>
              Your use of this Application shall be subjected to the following
              terms and conditions:
            </p>
            <ol>
              <li>
                You will observe and comply with the terms and conditions of the
                Agreement in letter and spirit;
              </li>
              <li>
                You will provide full and accurate information about Your
                health, medical history and personal care needs;
              </li>
              <li>
                You will strictly adhere to the advice and instructions given by
                HSPs from time to time;
              </li>
              <li>
                You will not delete or modify any content of the Application
                including but not limited to, legal notices, disclaimers or
                proprietary notices such as copyright or trademark symbols,
                logos, that You do not own or have express permission to modify;
              </li>
              <li>
                You will not decipher, decompile, reverse engineer, or
                disassemble the MEDVATIC content;
              </li>
              <li>
                You will not use any engine, software, tool, agent or other
                device or mechanism (such as spiders, robots, avatars or
                intelligent agents) to navigate or search the Application;
              </li>
              <li>
                You will not remove any copyright, trademark registration, or
                other proprietary notices from the Application. You further
                agree not to access or use this Application in any manner that
                may be harmful to the operation of this Application or its
                content;
              </li>
              <li>
                You will not frame or hotlink or deeplink any MEDVATIC Content;
              </li>
              <li>
                You will not use the Application and / or Services in any way
                that is unlawful, or harms MEDVATIC or any other person or
                entity, as determined in the MEDVATIC&rsquo;s sole discretion.
              </li>
              <li>
                You will not engage in any form of antisocial, disrupting, or
                destructive acts, including &ldquo;flaming&rdquo;,
                &ldquo;spamming&rdquo;, &ldquo;flooding&rdquo;,
                &ldquo;trolling&rdquo;, &ldquo;phishing&rdquo; and
                &ldquo;griefing&rdquo; as those terms are commonly understood
                and used on the Internet;
              </li>
              <li>
                You will not host, display, upload, modify, publish, transmit,
                update or share any information that:
              </li>
            </ol>
            <ol>
              <li>
                belongs to another person and over which You does not have
                sufficient legal rights;
              </li>
              <li>
                is grossly harmful, harassing, blasphemous defamatory, obscene,
                pornographic, paedophilic, libellous, invasive of
                another&rsquo;s privacy, hateful, or racially, ethnically
                objectionable, disparaging, relating or encouraging money
                laundering or gambling, or otherwise unlawful in any manner
                whatever;
              </li>
              <li>harm minors in any way;</li>
              <li>
                infringes any patent, trademark, copyright or other proprietary
                rights(se), violates any law for the time being in force;
              </li>
              <li>
                deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature;
              </li>
              <li>impersonate another person;</li>
              <li>
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource;
              </li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognizable
                offence or prevents investigation of any offence or is insulting
                any other nation.
              </li>
            </ol>
            <p>
              Without prejudice to the rights that MEDVATIC may have under other
              provisions of the Agreement, where MEDVATIC has reasons to believe
              that a User has violated a term or condition of the Agreement,
              then MEDVATIC shall, in its sole discretion:
            </p>
            <ol>
              <li>Suspend provision of Services; and/or</li>
              <li>
                Suspend the account of the User until there is enough
                information available with MEDVATIC to determine whether the
                User had violated a term or condition of this Agreement; and/or
              </li>
              <li>
                Suspend or terminate the account of the User where MEDVATIC has
                determined that the User had violated a term or condition of
                this Agreement; and/or
              </li>
              <li>
                Approach User to make good that loss or damage caused to
                MEDVATIC as a pre-condition to revoke the suspension; and/or
              </li>
              <li>
                Take any such action against the User as it permissible under
                law; and/or
              </li>
              <li>Raise invoice of Services availed by the User; and/or</li>
              <li>
                Disable and/or delete such User Content that is in contravention
                of the Agreement while preserving such information and
                associated records for the purpose of production to governmental
                authorities for investigation purposes.
              </li>
            </ol>
            <p>
              <strong>PAYMENT, FEES AND TAXES</strong>
            </p>
            <p>
              You agree to pay all Service fees, consulting fees, reasonable out
              of pocket expenses and other fees applicable to Your use of the
              Services. While MEDVATIC will strive to give You a fair estimate
              of fees in advance, the actual fee may be dependent on the type of
              Services that You purchase and may vary based on the number or
              duration of the Services subscribed to.
            </p>
            <p>
              You are solely responsible for payment of all taxes, legal
              compliances, statutory registrations and reporting. MEDVATIC is in
              no way responsible for any of the taxes except for its own income
              tax.
            </p>
            <p>
              The fees could be paid online through the facility made on the
              Application. Third parties support and services are required to
              process online fee payment. MEDVATIC is not responsible for any
              loss or damage caused to User during this process as these third
              parties are beyond the control of MEDVATIC.
            </p>
            <p>
              All fees are exclusive of taxes. The payment process would be
              complete only on receipt of the amount to MEDVATIC&rsquo;s
              designated bank account.
            </p>
            <p>
              An invoice for fees against Services received by You will be
              generated periodically and will be intimated to You on the
              Application and/or on Your contact details. You may be requested
              to make the payment immediately or on a deferred basis, at the
              sole discretion of MEDVATIC. In case the fees against Services are
              not received within the requested timeframe, MEDVATIC reserves the
              right to stop provision of Services on immediate basis,
              permanently delete Your account and all information stored against
              your account and, suspend or terminate the Agreement. You will be
              solely responsible for the consequences of non-payment for the
              fees within the requested timeframe and have no right of recovery
              in any form against MEDVATIC.
            </p>
            <p>
              In order to process the payments, MEDVATIC might require details
              of Your bank account, credit card number etc. Please check our
              privacy policy AVAILBALE HERE on how MEDVATIC uses the
              confidential information provided by You.
            </p>
            <p>
              MEDVATIC shall send an intimation of fee receipt through an email
              and/or your contact details within seven (7) working days of fee
              receipt from You.
            </p>
            <p>
              <strong>OFFERS AND PROMOTIONS</strong>
            </p>
            <p>
              MEDVATIC and HSP may occasionally promote their Services on the
              Application available for you to purchase. We try to encourage HSP
              to offer good quality Services and materials at competitive prices
              but we have no control over them, we do not endorse the Services
              they offer, or give you any assurance that they will be suitable
              for your needs. It is your responsibility to satisfy yourself in
              this regard and we have no liability in connection with the same.
              All promotions are for limited period and subject to special terms
              and conditions, which are in addition and not to the terms and
              condition stated herein.
            </p>
            <p>
              For the avoidance of any doubt, it is hereby clarified that any
              reference of the term &lsquo;offer/offered for sale by the
              HSP&rsquo;, as appearing in the Agreement, shall be construed
              solely as an &lsquo;invitation to offer for sale&rsquo; by any
              such HSP.
            </p>
            <p>
              <strong>REFUNDS &amp; CANCELLATIONS</strong>
            </p>
            <p>Refund &amp; Cancellation Policy</p>
            <p>
              For internet-based subscription Services, You are free to cancel
              the Service at any time. MEDVATIC shall refund any unexpended
              advance fees paid by You at the time of cancellation of the
              Service. In case You wish to seek a refund of the advance paid by
              You because You do not agree with a modification to the Agreement,
              please write to <strong>info@medvatic.com</strong>. We will
              process refund of the unexpended amount.
            </p>
            <p>
              Subjected to terms under clause &lsquo;Use of Services&rsquo; of
              this Agreement for all home-based Services, MEDVATIC shall provide
              refund of any unexpended advance fees paid by You if:
            </p>
            <ol>
              <li>
                MEDVATIC is not able to deliver the Services and/or Products for
                any reason. In case You wish to seek a refund of the advance
                paid by You because You believe;
              </li>
              <li>
                MEDVATIC Service is not as per the specification provided on the
                Application , please write to <strong>info@medvatic.com</strong>
                . MEDVATIC will process refund the unexpended amount provided
                internal investigation confirms the claim made by you.
              </li>
            </ol>
            <p>
              <strong>PATIENT EMERGENCIES</strong>
            </p>
            <p>
              The Services are not a replacement for emergency services offered
              at hospitals and should not be accessed if the patient is in a
              critical condition. In Medical Emergency (defined hereunder),
              please contact emergency services and/or rush to the nearest
              hospital.
            </p>
            <p>
              &ldquo;Medical Emergency&rdquo; will include a serious and
              unexpected situation that may involve illness or injury and
              requiring immediate medical attention in the absence of which,
              possess an imminent threat/risk to life and can potentially lead
              to death in the absence of the medical attention. In such
              situation patient (adult and minor) may undergo through any or
              multiple of the following conditions:
            </p>
            <ol>
              <li>development of chest pain;</li>
              <li>sudden breathing difficulty;</li>
              <li>inability to speak;</li>
              <li>inability to move any of the body parts;</li>
              <li>loss of consciousness;</li>
              <li>sudden loss of vision and/or hearing;</li>
              <li>not oriented to surrounding;</li>
              <li>sudden abnormal behavior;</li>
              <li>sudden irritability;</li>
              <li>extreme lethargy;</li>
              <li>extreme discomfort;</li>
              <li>
                sudden worsening of any pre-existing medical conditions;&nbsp;
              </li>
              <li>sudden development of severe pain.</li>
            </ol>
            <p>
              <strong>REPRESENTATIONS AND WARRANTIES</strong>
            </p>
            <p>
              By Using This Application, You hereby represent and warrant that:
            </p>
            <ol>
              <li>
                You are 18 years of age or older and that Your use of the
                Application shall not violate any applicable law or regulation;
              </li>
              <li>
                Where a minor may access or use the Application or Service, You
                will ensure that such access or use happens through Your account
                and under Your personal supervision. Further, You accept to be
                accountable and liable for the activity of the minor on the
                Application, including in respect of the Services availed on the
                Application;
              </li>
              <li>
                All registration information You submit is truthful and accurate
                and that You agree to maintain the accuracy of such information;
              </li>
              <li>
                You as a Registered User will use the Application solely for
                Your personal and non-commercial use. Any use of this
                Application or its content other than for personal purposes is
                strictly prohibited.
              </li>
            </ol>
            <p>MEDVATIC represents and warrants that:</p>
            <ol>
              <li>
                it is an LLP duly organized under the [LLP Act, 2008] and has
                the power and authority to provide the Services and the
                Application;
              </li>
              <li>
                it has all permits, approvals and licenses necessary to carry
                out the Services and in fulfilling its obligations hereunder;
              </li>
              <li>
                by entering into this Agreement, MEDVATIC does not violate any
                of the previous arrangements entered into with other entities to
                which it is a party;
              </li>
            </ol>
            <p>
              <strong>DISCLAIMERS</strong>
            </p>
            <p>
              You understand that there are inherent risks involved in receiving
              medical services over a mobile/computer platform, which include:
            </p>
            <p>
              Information transmitted may not be sufficient (e.g., poor
              resolution of images) to allow for appropriate medical decision
              making by a HSP;
            </p>
            <ol>
              <li>
                Your HSP may neither be able to provide medical treatment to You
                nor provide for or arrange for care that You may require in the
                case of an emergency;
              </li>
              <li>
                Delays in medical evaluation and treatment could occur due to
                deficiencies or failures of the Application;
              </li>
              <li>
                Security protocols could fail, causing a breach of privacy of
                Your confidential medical information.
              </li>
              <li>
                A lack of access to complete medical records may result in
                errors in medical judgment.
              </li>
            </ol>
            <p>
              User may expect the anticipated benefits from the Services
              provided by MEDVATIC, HSPs and its authorized representatives, but
              no results can be guaranteed. User&rsquo;s condition may not be
              cured or improved, and in some cases, may get worse.
            </p>
            <p>
              MEDVATIC hereby disclaims any responsibility towards Users in
              terms of development, maintenance and updating of the Application.
            </p>
            <p>
              Any modification, development, maintenance or updating of the
              Application and any modification, transfer, license or assignment
              of the rights in the Application shall be done by MEDVATIC as per
              its sole judgment and in its sole discretion.
            </p>
            <p>
              The Application and Services provided by MEDVATIC is provided
              &ldquo;as-is,&rdquo; as available, and without any warranties or
              conditions (express or implied, including the implied warranties
              of merchantability, accuracy, fitness for a particular purpose,
              title and non-infringement, arising by statute or otherwise in law
              or from a course of dealing or usage or trade). To the fullest
              extent permitted by law, MEDVATIC disclaims all liability arising
              out of the User&rsquo;s use or reliance upon the Application, the
              Services, the MEDVATIC Content, representations and warranties
              made by MEDVATIC or any opinion or suggestion given or expressed
              by MEDVATIC or its contractors and agents (including HSPs).
            </p>
            <p>
              Specifically, MEDVATIC disclaims any liability arising out of:
            </p>
            <ol>
              <li>Any pre-existing medical condition; or</li>
              <li>
                Any adverse drug reaction (due to any act or omission based on
                information found on the Application, or otherwise); or
              </li>
              <li>
                Sudden escalation of a prior medical condition or medical
                situations that occur on account of omission of critical and
                material health information by a User.
              </li>
            </ol>
            <p>
              MEDVATIC assumes no responsibility and shall not be liable for
              ways in which Your personal data is used. It is the responsibility
              of You alone to ensure that Your personal data is used in
              compliance to applicable privacy laws.
            </p>
            <p>
              MEDVATIC assumes no responsibility, and shall not be liable for,
              any damages to, or viruses that may infect User&rsquo;s equipment
              on account of User&rsquo;s access to, use of, or browsing the
              Application or the downloading of any material, data, text,
              images, video content, or audio content from the Application. If a
              User is dissatisfied with the Application, User&rsquo;s sole
              remedy is to discontinue using the Application.
            </p>
            <p>
              Neither MEDVATIC nor its contractors and agents (including HSPs)
              shall be liable to a User, monetarily or otherwise, for an
              occurrence wherein no medical or procedural negligence of MEDVATIC
              or its contractors and agents is proven.
            </p>
            <p>
              Use of the Application does not include the provision of a
              computer or mobile device or other necessary equipment to access
              it. To use the Application You will require internet connectivity
              and appropriate telecommunication links. MEDVATIC shall not have
              any responsibility or liability for costs incurred in procuring
              any equipment or telephone or other costs you may incur in
              connection with the use of the Application.
            </p>
            <p>Information regarding HSP and Services</p>
            <p>
              Information regarding HSP and MEDVATIC Services is intended for
              general reference purposes only. Such information often changes
              frequently and may become out of date or inaccurate. You are
              encouraged to independently verify any such information You see on
              the Application with respect to a HSP that You seek to engage
              through the Application.
            </p>
            <p>
              MEDVATIC DISCLAIMS ANY REPRESENTATION AND/OR WARRANTY FOR THE
              SECURITY, RELIABILITY, QUALITY, TIMELINESS, AND PERFORMANCE OF (I)
              THE APPLICATION AND ITS FEATURES; (II) HSP INFORMATION ON THE
              APPLICATION; (III) ANY SERVICE INFORMATION, CONTENT OR ADVICE
              AVAILABLE ON OR RECEIVED THROUGH THE APPLICATION (IV) ACCESS TO OR
              ALTERATION OF USER CONTENT OR MEDVATIC CONTENT (V) TRANSMISSIONS
              OR DATA AND (VI) ANY OTHER MATTER RELATING TO THE APPLICATION AND
              / OR SERVICES.
            </p>
            <p>
              MEDVATIC MAKES NO WARRANTY OR REPRESENTATION THAT ANY ERRORS IN
              THE APPLICATION WILL BE CORRECTED EXCEPT AS REQUIRED BY LAW.
            </p>
            <p>
              MEDVATIC ASSUMES NO RESPONSIBILITY AND/OR LIABILITY WITH RESPECT
              TO ANY INFORMATION WHICH YOU OR A HSP MAY POST ON THE APPLICATION
              AND YOU ARE SOLELY RESPONSIBLE FOR SUCH USER CONTENT POSTED ON THE
              SITE.
            </p>
            <p>
              YOU AGREE THAT THE OPEN AND REAL-TIME NATURE OF THE PLATFORM MAKE
              IT IMPOSSIBLE FOR MEDVATIC TO VOUCH FOR THE VALIDITY, AUTHETICITY
              AND HONESTY OF USER CONTENT. MEDVATIC IS NOT RESPONSIBLE FOR ANY
              USER CONTENT ON THE APPLICATION, OR FOR THE CONSEQUENCES OF YOU
              READING OR RELYING ON SUCH CONTENT.
            </p>
            <p>
              MEDVATIC DOES NOT PROVIDE ANY GUARANTEE AND SHALL NOT BE HELD
              LIABLE OR RESPONSIBLE FOR THE FAILURE TO SEND ANY COMMUNICATION,
              NOTIFICATION OR REMINDER TO YOU WHETHER AS A FEATURE OF THE
              APPLICATION OR NOT.
            </p>
            <p>
              MEDVATIC SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY BREACH OR LOSS
              OF DATA INCLUDING PERSONAL INFORMATION CAUSED DUE TO EVENTS BEYOND
              THE CONTROL OF MEDVATIC DUE TO TECHNICAL REASONS OR THIRD PARTY
              ACTIONS.
            </p>
            <p>
              <strong>LIMITATION OF LIABILITY</strong>
            </p>
            <p>
              Medvatic's total liability arising out of or in connection with
              the Services or these Terms, however arising, including under
              contract, tort (including negligence), in equity, under statute or
              otherwise, will not exceed the resupply of the Services to you.
            </p>
            <p>
              You expressly understand and agree that Medvatic, its affiliates,
              employees, agents, contributors and licensors shall not be liable
              to you for any direct, indirect, incidental, special consequential
              or exemplary damages which may be incurred by you, however caused
              and under any theory of liability. This shall include, but is not
              limited to, any loss of profit (whether incurred directly or
              indirectly), any loss of goodwill or business reputation and any
              other intangible loss.
            </p>
            <p>
              <strong>INDEMNIFICATION</strong>
            </p>
            <p>
              You hereby agree to indemnify, defend, and hold MEDVATIC,
              MEDVATIC&rsquo;s contractors, agents, representatives and other
              authorized users (Registered and Non-Registered Users, Patients,
              HSPs), and each of the foregoing entities&rsquo; respective
              contractors, agents, representatives, service providers and
              suppliers, and all of the foregoing entities&rsquo; respective
              officers, directors, owners, employees, agents, representatives,
              harmless from and against any and all losses, damages, liabilities
              and costs arising from (i) Your use of the Application or Service
              (ii) Your breach of terms, conditions or provisions of this
              Agreement, or violation of any law, rules or regulations by the
              User, or due to such other actions, omissions or commissions of
              the User that gave rise to the claim., (iii) any negligent or
              intentional wrongdoing on Your part, (vi) misrepresentations or
              fraudulent feedback that has adversely affected MEDVATIC or its
              Users, (v) User&rsquo;s actions resulting from the User&rsquo;s
              viewing of Content on MEDVATIC Application, (vi) any legal or
              third party intellectual property right claim that may arise from
              the User Content and (vii) any such claim or liability arising out
              of unauthorized use of Application and content within the
              Application
            </p>
            <p>
              <strong>USER ACCOUNT SUSPENSION</strong>
            </p>
            <p>
              In the event where a User has breached the Agreement in
              MEDVATIC&rsquo;s sole judgment or when MEDVATIC is unable to
              verify or authenticate any information provide by User to MEDVATIC
              or when or User fails to provide the consents necessary or
              desirable for MEDVATIC to provide the Services (or after providing
              such consent, later revokes), MEDVATIC reserves the right to
              suspend and / or terminate the User&rsquo;s account and/or access
              to the Application by blocking Your IP address or email id with or
              without notice to the User. Any suspected illegal, fraudulent or
              abusive activity may be grounds for suspending and / or
              terminating User account and/or User access to the Application
              and/or Services. Upon suspension or termination, Your right to use
              the features on the Application including Services shall
              immediately cease and MEDVATIC may permanently remove or delete
              Your information that is available with MEDVATIC, including but
              not limited to login and account information.
            </p>
            <p>
              MEDVATIC shall not be liable to User or any third party for any
              claims or damages arising out of any termination or suspension of
              User&rsquo;s account or any other actions taken by MEDVATIC in
              connection with such account termination or suspension.
            </p>
            <p>
              Once User&rsquo;s account has been temporarily or indefinitely
              suspended or terminated, User may not continue to use the
              Application under the same account, a different account or
              re-register under a new account.
            </p>
            <p>
              <strong>TERMINATION</strong>
            </p>
            <p>
              This Agreement will remain in full force and effect while You use
              the Application in any manner or capacity.
            </p>
            <p>
              You can terminate Your relationship with MEDVATIC at any time by
              providing ninety (90) days&rsquo; prior written notice at{" "}
              <strong>info@medvatic.com</strong>. We need this period to
              inactivate User account, only after verifying if there are any
              ongoing or pending Services. MEDVATIC may require User to continue
              until the completion of an on-going Service should the situation
              warrant.
            </p>
            <p>
              MEDVATIC may terminate its relationship with You after providing
              30 (thirty) days&rsquo; prior written notice to Your registered
              email address. However, it does not prejudice MEDVATIC&rsquo;
              right to refuse Service to You without providing any reason.
            </p>
            <p>
              MEDVATIC may terminate the Agreement without notice and with
              immediate effect where, in the sole opinion of MEDVATIC:
            </p>
            <ol>
              <li>
                Any material relating to personal and medical information
                furnished by a User appears to be untrue; or
              </li>
              <li>A User is critical or unstable or dead; or</li>
              <li>Provision of Services poses a risk to life of HSPs; or</li>
              <li>
                Financial dues have not been paid by a User within requested
                timeframe; or
              </li>
              <li>User fails to co-operate with HSPs or MEDVATIC; or</li>
              <li>
                HSPs and/or other MEDVATIC resources are no longer adequate,
                available and/or suitable to accommodate User&rsquo;s health
                requirements.
              </li>
            </ol>
            <p>
              MEDVATIC reserves the right to suspended or withdrawn any non-paid
              version of any variant, tool, edition, add-on, and ancillary
              product or service of the Application from you personally or all
              users temporarily or permanently at any time and without notice.
              MEDVATIC may also impose restrictions on the length and manner of
              usage of any non-paid version of any variant, tool, edition,
              add-on, and ancillary product or service of the Application for
              any reason. MEDVATIC may change or update the non-paid version of
              any variant, tool, edition, add-on, and ancillary product or
              service of the Application without notice to you. If the need
              arises, we may suspend access to such part of the Application or
              close it indefinitely.
            </p>
            <p>
              <strong>
                APPLICABLE LAW, JURISDICTION OF COURTS AND DISPUTE RESOLUTION
              </strong>
            </p>
            <p>
              The information provided under this Application and the terms and
              conditions therein are governed by and to be interpreted in
              accordance with laws of India.
            </p>
            <p>
              The parties shall be bound to submit all disputes and differences
              howsoever arising out of or in connection with this Agreement to
              arbitration by one (1) arbitrator, mutually appointed by the
              parties. The arbitration shall be governed by the Arbitration and
              Conciliation Act, 1996 as amended from time to time. The seat and
              venue of arbitration shall be Mumbai. Subject to the aforesaid,
              the competent courts of Mumbai shall have jurisdiction to grant
              urgent interim reliefs to the parties.
            </p>
            <p>
              The parties shall have the right to apply to a court of competent
              jurisdiction to obtain interim injunctive relief in respect of any
              dispute, pending resolution of such dispute in accordance with the
              Agreement.
            </p>
            <p>
              <strong>MISCELLANEOUS</strong>
            </p>
            <p>
              Severability: If any provision of this Agreement is held by a
              court of competent jurisdiction or arbitral tribunal to be
              unenforceable under applicable law, then such provision shall be
              excluded from the Agreement and the remainder of the Agreement
              shall be interpreted as if such provision were so excluded and
              shall be enforceable in accordance with its terms; provided
              however that, in such event the Agreement shall be interpreted so
              as to give effect, to the greatest extent consistent with and
              permitted by applicable law, to the meaning and intention of the
              excluded provision as determined by such court of competent
              jurisdiction or arbitral tribunal.
            </p>
            <p>
              Notices: All notices and other communications required or
              permitted hereunder to be given to a party shall be in writing, in
              the English language, and shall be sent e-mail, or mailed by
              prepaid internationally-recognized courier or otherwise delivered
              by hand or by messenger, addressed to such party&rsquo;s address
              as set forth below or at such other address as the party shall
              have furnished to the other party in writing in accordance with
              this provision:
            </p>
            <ul>
              <li>
                If to MEDVATIC: GRB103, WeWork Chromium, JVLR, Andheri East,
                Mumbai - 400076
              </li>
              <li>
                If to You: at the email address provided by You to us when You
                registered as a User. If You are a Secondary User, You agree to
                receive communications and be notified at the email address of
                the Primary User.
              </li>
            </ul>
            <p>
              No term of the Agreement shall be deemed waived and no breach
              excused, unless such waiver or consent shall be in writing and
              signed by the party claimed to have waived or consented. Any
              consent by any party to, or waiver of a breach by the other,
              whether expressed or implied, shall not constitute consent to,
              waiver of, or excuse for any other different or subsequent breach.
            </p>
            <p>
              Complete Understanding: The Agreement contain the entire
              understanding of the parties, and there are no other written or
              oral understandings or promises between the parties with respect
              to the subject matter of the Agreement other than those contained
              or referenced in the Agreement.
            </p>
            <p>
              Force Majeure: MEDVATIC shall not be liable for any downtime or
              delay or unavailability of the Application caused by circumstances
              beyond MEDVATIC&rsquo;s reasonable control, including without
              limitation, cyber-attacks, acts of God, acts of government,
              floods, fires, earthquakes, civil unrest, acts of terror, strikes
              or other labour problems, internet service provider failures or
              delays, or denial of service attacks.
            </p>
            <p>
              Advertisement: MEDVATIC shall have the right to place
              advertisements and publicity materials of its choice, including
              that pertaining to parties other than itself for commercial use
              through electronic means on the Application.
            </p>
            <p>
              Assignment: You may not assign or sub-license, without the prior
              written consent of MEDVATIC, the rights, duties or obligations
              under this Agreement, in whole or in part, to any person or
              entity.
            </p>
            <p>
              Conflict: In the event there is any conflict between the terms of
              the Agreement, the following order of prevalence shall apply: (i)
              any additional Service-specific terms and conditions that may be
              agreed by You (ii) Privacy Policy (iii) the Terms of Use and (iv)
              any other policies applicable to specific pages of the
              Application.
            </p>
            <p>
              Survival: Even after termination, the provisions of the Agreement
              with respect to Platform as-is, Payment terms, Limitation of
              Liability, Indemnity, Intellectual Property, Dispute Resolution
              will continue and survive termination of this Agreement.
            </p>
            <p>
              Modifications: MEDVATIC reserves the right to modify any part of
              the Agreement at any time without giving You prior notice. No part
              of the Agreement shall stand modified unless consented to in
              writing by MEDVATIC.
            </p>
            <p>
              When MEDVATIC updates the Agreement, We will notify you through an
              email. Alternatively, MEDVATIC may cause Your account to be
              logged-off in and make Your subsequent account log-in conditional
              on acceptance of the Agreement.
            </p>
            <p>
              If You do not agree to the changes, please do not access or use
              the Application and the Services any further.
            </p>
          </div>
          {/* <h3 class="header-title text-center">
            WEBSITE TERMS AND CONDITIONS OF USE
          </h3>
          <div class="line"></div>
          <div class="policy">
            <ol>
              <li class="policy_heading">About the Website</li>
              <ol>
                <li class="policy_heading">
                  Welcome to www.Medvatic.com ('Website '). The Website include
                  Tradem arks and name use of those trade marks and names is
                  with the permission of the owners and does not imply ownership
                  or endorsement by Medvatic.
                </li>
                <li class="policy_heading">
                  The Website is operated by Medvatic PTY. LTD. (ABN
                  18621062486). Access to and use of the Website, or any of its
                  associated Products or Services, is provided by Medvatic.
                  Please read these terms and conditions ('Terms') carefully. By
                  using, browsing and/or reading the Website, this signifies
                  that you have read, understood and agree to be bound by the
                  Terms. If you do not agree with the Terms, you must cease
                  usage of the Website, or any of Services, immediately.
                </li>
                <li class="policy_heading">
                  Medvatic reserves the right to review and change any of the
                  Terms by updating this page at its sole discretion. When
                  Medvatic updates the Terms, it will use reasonable endeavors
                  to provide you with notice of updates to the Terms. Any
                  changes to the Terms take immediate effect from the date of
                  their publication. Before you continue, we recommend you keep
                  a copy of the Terms for your records.
                </li>
              </ol>
              <li class="policy_heading">Acceptance of the Terms</li>
              <ol style={{ listStyle: "none" }}>
                <li class="policy_heading">
                  You accept the Terms by remaining on the Website. You may also
                  accept the Terms by clicking to accept or agree to the Terms
                  where this option is made available to you by Medvatic in the
                  user interface.
                </li>
              </ol>
              <li class="policy_heading">
                Copyright and Intellectual Property
              </li>
              <ol>
                <li class="policy_heading">
                  The Website, the content and all of the related products of
                  Medvatic are subject to copyright. The material on the Website
                  is protected by copyright under the laws of Australia and
                  through international treaties. Unless otherwise indicated,
                  all rights (including copyright) in the content and
                  compilation of the Website (including but not limited to text,
                  graphics, logos, button icons, video images, audio clips,
                  Website, code, scripts, design elements and interactive
                  features) or the content are owned or controlled for these
                  purposes, and are reserved by Medvatic or its contributors.
                </li>
                <li class="policy_heading">
                  All trademarks, service marks and trade names are owned,
                  registered and/or licensed by Medvatic, who grants to you a
                  worldwide, non-exclusive, royaltyfree, revocable license
                  whilst you are a Member to:
                  <ol style={{ listStyle: "lower-alpha" }}>
                    <li class="policy_heading">
                      use the Website pursuant to the Terms;
                    </li>
                    <li class="policy_heading">
                      copy and store the Website and the material contained in
                      the Website in your device's cache memory; and
                    </li>
                    <li class="policy_heading">
                      print pages from the Website for your own personal and
                      non-commercial use.
                    </li>
                  </ol>
                  Medvatic does not grant you any other rights whatsoever in
                  relation to the Website or the content. All other rights are
                  expressly reserved by Medvatic
                </li>
                <li class="policy_heading">
                  Medvatic retains all rights, title and interest in and to the
                  Website and all related content. Nothing you do on or in
                  relation to the Website will transfer any:
                  <ol style={{ listStyle: "lower-alpha" }}>
                    <li class="policy_heading">
                      business name, trading name, domain name, trade mark,
                      industrial design, patent, registered design or copyright,
                      or
                    </li>
                    <li class="policy_heading">
                      a right to use or exploit a business name, trading name,
                      domain name, trade mark or industrial design, or
                    </li>
                    <li class="policy_heading">
                      a thing, system or process that is the subject of a
                      patent, registered design or copyright (or an adaptation
                      or modification of such a thing, system or process),
                    </li>
                  </ol>
                  to you.
                </li>
                <li class="policy_heading">
                  You may not, without the prior written permission of Medvatic
                  and the permission of any other relevant rights owners:
                  broadcast, republish, up-load to a third party, transmit,
                  post, distribute, show or play in public, adapt or change in
                  any way the content or third party content for any purpose,
                  unless otherwise provided by these Terms. This prohibition
                  does not extend to materials on the Website, which are freely
                  available for re-use or are in the public domain.
                </li>
              </ol>
              <li class="policy_heading">Privacy</li>
              <ol style={{ listStyle: "none" }}>
                <li class="policy_heading">
                  Medvatic takes your privacy seriously and any information
                  provided through your use of the Website and/or content are
                  subject to Medvatic Privacy Policy, which is available on the
                  Website.
                </li>
              </ol>
              <li class="policy_heading">General Disclaimer</li>
              <ol>
                <li class="policy_heading">
                  Nothing in the Terms limits or excludes any guarantees,
                  warranties, representations or conditions implied or imposed
                  by law, including the Australian Consumer Law (or any
                  liability under them) which by law may not be limited or
                  excluded.
                </li>
                <li class="policy_heading">
                  Subject to this clause 5, and to the extent permitted by law:
                  <ol style={{ listStyle: "lower-alpha" }}>
                    <li class="policy_heading">
                      all terms, guarantees, warranties, representations or
                      conditions which are not expressly stated in the Terms are
                      excluded; and
                    </li>
                    <li class="policy_heading">
                      Medvatic will not be liable for any special, indirect or
                      consequential loss or damage (unless such loss or damage
                      is reasonably foreseeable resulting from our failure to
                      meet an applicable Consumer Guarantee), loss of profit or
                      opportunity, or damage to goodwill arising out of or in
                      connection with the content or these Terms (including as a
                      result of not being able to use the content or the late
                      supply of the content), whether at common law, under
                      contract, tort (including negligence), in equity, pursuant
                      to statute or otherwise.
                    </li>
                  </ol>
                </li>
                <li class="policy_heading">
                  Use of the Website and the content is at your own risk.
                  Everything on the Website and the content is provided to you
                  "as is" and "as available" without warranty or condition of
                  any kind. None of the affiliates, directors, officers,
                  employees, agents, contributors and licensors of Medvatic make
                  any express or implied representation or warranty about the
                  content or any products or content (including the products or
                  content of Medvatic) referred to on the Website. This includes
                  (but is not restricted to) loss or damage you might suffer as
                  a result of any of the following:
                  <ol style={{ listStyle: "lower-alpha" }}>
                    <li class="policy_heading">
                      failure of performance, error, omission, interruption,
                      deletion, defect, failure to correct defects, delay in
                      operation or transmission, computer virus or other harmful
                      component, loss of data, communication line failure,
                      unlawful third-party conduct, or theft, destruction,
                      alteration or unauthorized access to records;
                    </li>
                    <li class="policy_heading">
                      the accuracy, suitability or currency of any information
                      on the Website, the content, or any of its content related
                      products (including third party material and
                      advertisements on the Website);
                    </li>
                    <li class="policy_heading">
                      costs incurred as a result of you using the Website, the
                      content or any of the products of Medvatic; and
                    </li>
                    <li class="policy_heading">
                      the content or operation in respect to links which are
                      provided for your convenience.
                    </li>
                  </ol>
                </li>
              </ol>

              <li class="policy_heading">Food Information Disclaimer</li>
              <ol>
                <li class="policy_heading">
                  The information contained in this website is for general
                  information purposes only and is not meant to substitute
                  professional dietary advice or treatment. If you have or
                  suspect you may have allergies or medical issues which may be
                  affected by certain foods, or, after taking any of our
                  products, find you may have or be experiencing side effects,
                  you should promptly contact your health care provider. Any
                  statements regarding dietary supplements are to be used at
                  your discretion and are not intended to diagnose, treat, cure
                  or prevent any disease.
                </li>
              </ol>
              <li class="policy_heading">Limitation of liability</li>
              <ol>
                <li class="policy_heading">
                  Medvatic total liability arising out of or in connection with
                  the content or these Terms, however arising, including under
                  contract, tort (including negligence), in equity, under
                  statute or otherwise, will not exceed the resupply of the
                  content to you.
                </li>
                <li class="policy_heading">
                  You expressly understand and agree that Medvatic, its
                  affiliates, employees, agents, contributors and licensors
                  shall not be liable to you for any direct, indirect,
                  incidental, special consequential or exemplary damages which
                  may be incurred by you, however caused and under any theory of
                  liability. This shall include, but is not limited to, any loss
                  of profit (whether incurred directly or indirectly), any loss
                  of goodwill or business reputation and any other intangible
                  loss.
                </li>

                <li class="policy_heading">
                  You acknowledge and agree that Medvatic holds no liability for
                  any direct, indirect, incidental, special consequential or
                  exemplary damages which may be incurred by you as a result of
                  providing your content to the Website.
                </li>
              </ol>
              <li class="policy_heading">Termination of Contract</li>
              <ol>
                <li class="policy_heading">
                  If you want to terminate the Terms, you may do so by providing
                  Medvatic with 14 days' notice of your intention to terminate
                  by sending notice of your intention to terminate to Medvatic
                  via the 'Contact Us' link on our homepage.
                </li>
                <li class="policy_heading">
                  Medvatic may at any time, terminate the Terms with you if:
                  <ol style={{ listStyle: "lower-alpha" }}>
                    <li class="policy_heading">
                      you have breached any provision of the Terms or intend to
                      breach any provision;
                    </li>
                    <li class="policy_heading">
                      Medvatic is required to do so by law;
                    </li>
                    <li class="policy_heading">
                      Medvatic is transitioning to no longer providing the
                      Services to Members in the country in which you are
                      resident or from which you use the service; or
                    </li>
                    <li class="policy_heading">
                      the provision of the Services to you by Medvatic, is in
                      the opinion of Medvatic, no longer commercially viable.
                    </li>
                  </ol>
                </li>
                <li class="policy_heading">
                  Subject to local applicable laws, Medvatic reserves the right
                  to discontinue or cancel your access at any time and may
                  suspend or deny, in its sole discretion, your access to all or
                  any portion of the Website or the Services without notice if
                  you breach any provision of the Terms or any applicable law or
                  if your conduct impacts Medvatic name or reputation or
                  violates the rights of those of another party.
                </li>
                <li class="policy_heading">
                  When the Terms come to an end, all of the legal rights,
                  obligations and liabilities that you and Medvatic have
                  benefitted from, been subject to (or which have accrued over
                  time whilst the Terms have been in force) or which are
                  expressed to continue indefinitely, shall be unaffected by
                  this cessation, and the provisions of this clause shall
                  continue to apply to such rights, obligations and liabilities
                  indefinitely.
                </li>
              </ol>

              <li class="policy_heading">Indemnity</li>
              <ol>
                <li class="policy_heading">
                  You agree to indemnify Medvatic, its affiliates, employees,
                  agents, contributors, third party content providers and
                  licensors from and against:
                  <ol style={{ listStyle: "lower-alpha" }}>
                    <li class="policy_heading">
                      all actions, suits, claims, demands, liabilities, costs,
                      expenses, loss and damage (including legal fees on a full
                      indemnity basis) incurred, suffered or arising out of or
                      in connection with your content;
                    </li>
                    <li class="policy_heading">
                      any direct or indirect consequences of you accessing,
                      using or transacting on the Website or attempts to do so;
                      and/or
                    </li>
                    <li class="policy_heading">any breach of the Terms.</li>
                  </ol>
                </li>
              </ol>
              <li class="policy_heading">
                {" "}
                Dispute Resolution
                <ol>
                  <li class="policy_heading">
                    Compulsory:
                    <ol style={{ listStyle: "none" }}>
                      <li class="policy_heading">
                        If a dispute arises out of or relates to the Terms,
                        either party may not commence any Tribunal or Court
                        proceedings in relation to the dispute, unless the
                        following clauses have been complied with (except where
                        urgent interlocutory relief is sought).
                      </li>
                    </ol>
                  </li>
                  <li class="policy_heading">
                    Notice:
                    <ol style={{ listStyle: "none" }}>
                      <li class="policy_heading">
                        A party to the Terms claiming a dispute ('Dispute ') has
                        arisen under the Terms, must give written notice to the
                        other party detailing the nature of the dispute, the
                        desired outcome and the action required to settle the
                        Dispute.
                      </li>
                    </ol>
                  </li>
                  <li class="policy_heading">
                    Resolution:
                    <ol style={{ listStyle: "none" }}>
                      <li class="policy_heading">
                        On receipt of that notice ('Notice ') by that other
                        party, the parties to the Terms ('Parties') must:
                        <ol style={{ listStyle: "lower-alpha" }}>
                          <li class="policy_heading">
                            Within 28 days of the Notice endeavor in good faith
                            to resolve the Dispute expeditiously by negotiation
                            or such other means upon which they may mutually
                            agree;
                          </li>
                          <li class="policy_heading">
                            If for any reason whatsoever, 14 days after the date
                            of the Notice, the Dispute has not been resolved,
                            the Parties must either agree upon selection of a
                            mediator or request that an appropriate mediator be
                            appointed by the President of the Australia health
                            practitioner’s association or his or her nominee;
                          </li>
                          <li class="policy_heading">
                            The Parties are equally liable for the fees and
                            reasonable expenses of a mediator and the cost of
                            the venue of the mediation and without limiting the
                            foregoing undertake to pay any amounts requested by
                            the mediator as a pre-condition to the mediation
                            commencing. The Parties must each pay their own
                            costs associated with the mediation;
                          </li>
                          <li class="policy_heading">
                            The mediation will be held in ACT, Australia.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li class="policy_heading">
                    Confidential:
                    <ol style={{ listStyle: "none" }}>
                      <li class="policy_heading">
                        All communications concerning negotiations made by the
                        Parties arising out of and in connection with this
                        dispute resolution clause are confidential and to the
                        extent possible, must be treated as "without prejudice"
                        negotiations for the purpose of applicable laws of
                        evidence.
                      </li>
                    </ol>
                  </li>
                  <li class="policy_heading">
                    Termination of Mediation:
                    <ol style={{ listStyle: "none" }}>
                      <li class="policy_heading">
                        If 3 months have elapsed after the start of a mediation
                        of the Dispute and the Dispute has not been resolved,
                        either Party may ask the mediator to terminate the
                        mediation and the mediator must do so.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li class="policy_heading">
                Venue and Jurisdiction
                <ol style={{ listStyle: "none" }}>
                  <li>
                    The Services offered by Medvatic is intended to be viewed by
                    residents of Australia. In the event of any dispute arising
                    out of or in relation to the Website, you agree that the
                    exclusive venue for resolving any dispute shall be in the
                    courts of Australian Capital Territory, Australia.
                  </li>
                </ol>
              </li>
              <li class="policy_heading">
                Governing Law
                <ol style={{ listStyle: "none" }}>
                  <li>
                    The Terms are governed by the laws of Australian Capital
                    Territory, Australia. Any dispute, controversy, proceeding
                    or claim of whatever nature arising out of or in any way
                    relating to the Terms and the rights created hereby shall be
                    governed, interpreted and construed by, under and pursuant
                    to the laws of Australian Capital Territory, Australia,
                    without reference to conflict of law principles,
                    notwithstanding mandatory rules. The validity of this
                    governing law clause is not contested. The Terms shall be
                    binding to the benefit of the parties hereto and their
                    successors and assigns.
                  </li>
                </ol>
              </li>
              <li class="policy_heading">
                Independent Legal Advice
                <ol style={{ listStyle: "none" }}>
                  <li>
                    Both parties confirm and declare that the provisions of the
                    Terms are fair and reasonable and both parties having taken
                    the opportunity to obtain independent legal advice and
                    declare the Terms are not against public policy on the
                    grounds of inequality or bargaining power or general grounds
                    of restraint of trade.
                  </li>
                </ol>
              </li>
              <li class="policy_heading">
                Severance
                <ol style={{ listStyle: "none" }}>
                  <li>
                    If any part of these Terms is found to be void or
                    unenforceable by a Court of competent jurisdiction, that
                    part shall be severed and the rest of the Terms shall remain
                    in force.
                  </li>
                </ol>
              </li>
            </ol>
          </div> */}
        </div>
      </section>
    );
  }
}
