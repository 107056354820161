/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import csc from "country-state-city";
import { useFormik } from "formik";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseSelect from "react-select/creatable";
import * as Yup from "yup";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import { useStateValue } from "../../StateProvider";
import Loader from "../Loader/Loader";
import "./style.css";
import { Modal } from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GoogleMap from "../Agora/utils/GoogleMap";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const AddPatient = (props) => {
  const profilePictureRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [change_pp, setChange_pp] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [geoLocation, setGeoLocation] = useState();
  const [geoLatLng, setGeoLatLng] = useState();
  const [cities, setCities] = useState([]);
  const [allPharmacies, setAllPharmacies] = useState([]);
  const [preferredPharmacies, setPreferredPharmacies] = useState([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [pp_url, setpp_url] = useState("");
  const [country_id, setCountry_id] = useState("");
  const [state_id, setState_id] = useState("");
  const [city_id, setCity_id] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [preferredPharmacyId, setPreferredPharmacyId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [statePharmacies, setStatePharmacies] = useState([]);
  const [{ userPP }, dispatch] = useStateValue();

  const formik = useFormik({
    initialValues: {
      firstName: null,
      middleName: null,
      lastName: null,
      emergencyContactDetails: null,
      geoLocation: null,
      emergencyContactName: null,
      height: null,
      weight: null,
      address: null,
      postalcode: null,
      dateOfBirth: null,
      insuranceNumber: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "First Name should contain alphabets and may contain '"
        )
        .label("First Name"),
      middleName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Middle Name should contain alphabets and may contain '"
        )
        .label("Middle Name"),
      lastName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Last Name should contain alphabets and may contain '"
        )
        .label("Last Name"),
      dateOfBirth: Yup.date(),
      emergencyContactName: Yup.string()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Name should contain alphabets and may contain '"
        )
        .label("Emergency Name"),
      emergencyContactDetails: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(8)
        .label("Emergency Contact no"),
      height: Yup.number(),
      weight: Yup.number(),
      address: Yup.string().required().min(3).label("Address"),
      postalcode: Yup.string()
        .required()
        .min(4)
        .matches(/^[0-9]+$/, "Must be only digits")
        .label("Postal Code"),
      insuranceNumber: Yup.number(),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const makeDownloadUrl = async (path) => {
    console.log('starting downloadurl');
    setLoading(true);
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    let url_1 = null;
    // if(path )
    try {
      url_1 = await gsReference.getDownloadURL().catch(e => console.log(e, 'this is url error'));
      console.log(url_1);
      setpp_url(url_1);
      dispatch({
        type: "SET_USER_PP",
        url: url_1,
      });
      setChange_pp(false);
      setLoading(false);
    } catch (err) {
      setChange_pp(true);
      setLoading(false);
      Notify({
        alert: true,
        type: "error",
        title: err.message,
      });
    }
    if (url_1) return url_1
  };

  useEffect(() => {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(true);
        API.getUserDetails(user.displayName, user.uid)
          .then((result) => {
            let res = result.data

            console.log("ressss", res.user);
            setLoading(false);
            setUserDetails(res.user);
            setGeoLocation(res.user.geoLocation);
            props.updateUserData(res.user);
            if (res.user.profile_picture) {

              setpp_url(res.user.profile_picture);
              dispatch({
                type: "SET_USER_PP",
                url: res.user.profile_picture,
              });
              // makeDownloadUrl(res.user.profile_picture);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });

        API.getPharmacies()
          .then((result) => {
            let res = result.data

            console.log("Pharm data", res.data);
            setStatePharmacies(res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });;
      } else {
        props.history.push("/login");
      }
    });
    let data = csc.getAllCountries();
    setCountries(data);
    setAllPharmacies();
  }, []);

  useEffect(() => {
    if (userDetails)
      formik.setValues({
        firstName: userDetails.firstName,
        middleName: userDetails.middleName || "",
        lastName: userDetails.lastName,
        emergencyContactDetails: userDetails.emergencyContactDetails,
        emergencyContactName: userDetails.emergencyContactName,
        geoLocation: userDetails.geoLocation,
        height: !!userDetails.height ? userDetails.height.split(" ")[0] : "",
        weight: !!userDetails.weight ? userDetails.weight.split(" ")[0] : "",
        address: userDetails.address,
        postalcode: userDetails.postalcode,
        dateOfBirth: userDetails.dateOfBirth || "",
        insuranceNumber: userDetails.insuranceNumber || "",
      });
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      for (let key of countries) {
        if (userDetails.country && key.name === userDetails.country) {
          setCountry_id(key.id);
          let stateData = csc.getStatesOfCountry(key.id);
          setStates(stateData);
        }
      }

      if (userDetails?.preferredPharmacies) {
        setPreferredPharmacies(userDetails?.preferredPharmacies);

        let tempArr = userDetails?.preferredPharmacies;
        tempArr[0].label = tempArr[0].name;
        tempArr[0].value = tempArr[0].name;

        setSelectedPharmacies(tempArr);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      for (let key of states) {
        if (userDetails.State && key.name === userDetails.State) {
          setState_id(key.id);
          let cityData = csc.getCitiesOfState(key.id);
          setCities(cityData);
        }
      }
    }
  }, [states]);

  useEffect(() => {
    if (userDetails) {
      for (let key of cities) {
        if (userDetails.city && key.name === userDetails.city) {
          setCity_id(key.id);
        }
      }
    }
  }, [cities]);

  useEffect(() => {
    for (let key of preferredPharmacies) {
      console.log("Keys", key);
      if (key.name === userDetails?.preferredPharmacy.name) {
        setPreferredPharmacyId(key.id);
      }
    }
    return () => { };
  }, []);

  const handleUpload = (e) => {
    e.preventDefault();
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl(1);
    if (PP.state.status == "EMPTY") {
      return
    }
    console.log("file", PP);

    const ref = storage.ref();
    if (imageAsDataURL) {
      const name = userDetails.uid;
      const path = "profile_images/patients/" + name;
      setpp_url(imageAsDataURL);
      const task = ref.child(path).putString(imageAsDataURL, "data_url");
      task
        .then(async (reso) => {
          console.log('calling this  details')
          let picDownloadLink = await makeDownloadUrl(path);
          console.log('calling this  details')
          setLoading(true);
          setError("");
          let API = new Apilib();
          let data = {
            ...userDetails,
            type: 5,
            uid: props.user.uid,
            geoLocation: geoLocation,
            profile_picture: picDownloadLink,
            ...geoLatLng
          };

          API.updateUser(data)
            .then(async (res) => {
              setLoading(false);

              if (res.status == 200) {
                setLoading(false);
                setChange_pp(false);

                Notify({
                  alert: true,
                  type: "success",
                  title: "Profile picture uploaded successfully!",
                });
              } else {
                res.text().then((data) => {
                  setError(JSON.parse(data).message);
                  setChange_pp(false);
                  Notify({
                    alert: true,
                    type: "error",
                    title: JSON.parse(data).message,
                  });
                });
              }
            })
            .catch((error) => {
              setError(error.message);
              setChange_pp(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
          setChange_pp(false);
          Notify({
            alert: true,
            type: "error",
            title: err.message,
          });
        });
    }
  };

  const onChange = (e) => {
    let data = { ...userDetails };
    // for date, e will be directly the date value, thus e.target will be undefined. PS. sorry for bad code.
    if (e.target === undefined) {
      data["dateOfBirth"] = e;
      setUserDetails(data);
      return;
    }
    let inputName = e.target.name;
    let value = "";

    switch (inputName) {
      case "country":
        console.log("country", countries);
        for (let key of countries) {
          if (key.id === e.target.value) {
            value = key.name;
            setCountry_id(e.target.value);
          }
        }
        let stateData = csc.getStatesOfCountry(e.target.value);
        setStates(stateData);
        setState_id("");
        setCity_id("");
        break;

      case "State":
        for (let key of states) {
          if (key.id === e.target.value) {
            value = key.name;
            setState_id(e.target.value);
          }
        }
        let cityData = csc.getCitiesOfState(e.target.value);
        setCities(cityData);
        setCity_id("");
        break;

      case "city":
        for (let key of cities) {
          if (key.id === e.target.value) {
            value = key.name;
            setCity_id(e.target.value);
          }
        }
        break;

      case "preferredPharmacy":
        if (preferredPharmacies.length > 0) {
          for (let key of preferredPharmacies) {
            if (parseInt(key.pharmacyId) === e.target.value) {
              value = key;
              setPreferredPharmacyId(e.target.value);
            }
          }
        }
        break;

      // no default
    }
    if (
      inputName === "country" ||
      inputName === "State" ||
      inputName === "city" ||
      inputName === "preferredPharmacy"
    ) {
      data[inputName] = value;
    } else if (inputName === "weight") {
      data[inputName] = e.target.value + " kg";
    } else if (inputName === "height") {
      data[inputName] = e.target.value + " cm";
    } else {
      data[inputName] = e.target.value;
    }
    setUserDetails(data);
  };

  const onSelect = (val) => {
    let formObj = { ...userDetails };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val);
    let arr = [];
    if (val && val.length) {
      val.map((value, index) => {
        arr.push(value.value);
      });
    }
    formObj["allergies"] = arr;
    setUserDetails(formObj);
    // console.log("formdata", formObj, val);
  };

  const onSelectPharmacy = (val) => {
    let formObj = { ...userDetails };
    let preferredPharmaciesMenu = [];

    if (val && val.length) {
      val.map((pharm, i) => {
        preferredPharmaciesMenu.push(pharm);
      });
    }

    setSelectedPharmacies(preferredPharmaciesMenu);
    setPreferredPharmacies(preferredPharmaciesMenu);
    formObj["preferredPharmacies"] = preferredPharmaciesMenu;
    setUserDetails(formObj);
  };
  const customInput = (props) => {
    return (
      <input
        type="text"
        className="form-control"
        onChange={props.inputProps.onChange}
        placeholder={props.placeholder}
        value={props.value}
        onClick={props.onClick}
      />
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!formik.isValid) return;
    setError("");
    setLoading(true);

    let API = new Apilib();
    let data = {
      ...userDetails,
      type: 5,
      uid: props.user.uid,
      geoLocation: geoLocation,
      ...geoLatLng
    };

    console.log("dataaa", data)
    delete data.loading;
    delete data.error;
    delete data.success;
    API.updateUser(data)
      .then((result) => {
        let res = result.data
        setLoading(false);
        // if (res.ok) {
        setLoading(false);
        setUserDetails(null);
        setError("");
        setSuccess(true);
        Notify({
          alert: true,
          type: "success",
          title: "User Updated Successfully",
        });
        props.history.push("/my-profile");
        // } else {
        //   res.text().then((data) => {
        //     setError(JSON.parse(data).message);
        //   });
        // }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  let allBloodGroups = [
    {
      id: "1",
      name: "A+",
    },
    {
      id: "2",
      name: "A-",
    },
    {
      id: "3",
      name: "B+",
    },
    {
      id: "4",
      name: " B-",
    },
    {
      id: "5",
      name: "AB+",
    },
    {
      id: "6",
      name: "AB-",
    },
    {
      id: "7",
      name: "O+",
    },
    {
      id: "8",
      name: "O-",
    },
  ];
  let arr = [
    "Brazil nuts",
    "Almonds",
    "Cashews",
    "Macadamia nuts",
    "Pistachios",
    "Pine nuts",
    "Walnuts",

    "Cow’s Milk Allergies",

    "Milk",
    "Milk powder",
    "Cheese",
    "Butter",
    "Margarine",
    "Yogurt",
    "Cream",
    "Ice cream",

    "Shellfish Allergies",

    "Shrimp",
    "Prawns",
    "Crayfish",
    "Lobster",
    "Squid",
    "Scallops",

    "Peanuts Allergies",

    "Eggs Allergies",

    "Fish Allergies",

    "Soy Allergies",

    "Dog Allergies",

    "Pollen Allergies",

    "Pet Hair Allergies",

    "Dust Mites Allergies",

    "Insect bites Allergies",

    "Wheat Allergies",

    "  Antibiotic Allergies",
  ];
  let allegies = arr.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  let bloodGroup = null;
  if (allBloodGroups.length !== 0) {
    bloodGroup = allBloodGroups.map((item) => {
      return (
        <MenuItem key={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      );
    });
  }

  let allergies = [];
  if (userDetails) {
    if (userDetails.allergies && userDetails.allergies.length) {
      userDetails.allergies.map((allergy, i) => {
        allergies.push({
          value: allergy,
          label: allergy,
        });
      });
    }
  }
  let button = change_pp ? (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleUpload(e);
      }}
      className="btn custom-btn text-white"
      style={{ backgroundColor: "#31d4eb" }}
    >
      Save Image
    </button>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();
        setChange_pp(true);
      }}
      className="btn custom-btn btn-primary"
      style={{ backgroundColor: "#31d4eb" }}
    >
      Change Profile Pic
    </button>
  );

  let countriesMenu = null;
  if (countries && countries?.length !== 0) {
    countriesMenu = countries.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let statesMenu = null;
  if (states && states?.length !== 0) {
    statesMenu = states.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let citiesMenu = null;
  if (cities && cities?.length !== 0) {
    citiesMenu = cities.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let pharmaciesMenu = [];
  if (statePharmacies?.length > 0) {
    if (userDetails?.preferredPharmacy) {
      statePharmacies.filter((pharm) => {
        if (userDetails?.preferredPharmacy.pharmacyId === pharm.pharmacyId) {
          return false;
        } else {
          return true;
        }
      });
    }
    pharmaciesMenu = statePharmacies.map((pharm) => {
      return {
        ...pharm,
        value: pharm.name,
        label: pharm.name + (pharm.location ? ` - ${pharm.location}` : ""),
      };
    });
  }

  let preferredPharmacyMenu = null;
  if (preferredPharmacies?.length > 0) {
    preferredPharmacyMenu = preferredPharmacies.map((item, index) => {
      return (
        <MenuItem key={index} value={parseInt(item.pharmacyId)}>
          {item.name}
          {item.location ? ` - ${item.location}` : ""}
        </MenuItem>
      );
    });
  }

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Pharmacy in Preference List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            required
            placeholder="Enter Pharmacies"
            onChange={(item) => onSelectPharmacy(item)}
            value={selectedPharmacies}
            options={pharmaciesMenu}
            noOptionsMessage={() => "Enter pharmacy and press enter"}
            isMulti={true}
            closeMenuOnSelect={false}
          />
        </Modal.Body>
      </Modal>

      {loading && <Loader />}
      <div className="page-header profile-header">
        <div className="content profile-content edit-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="page-title about-doctor">Edit Profile</h4>
              </div>
            </div>
            {userDetails ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    onSubmit={(e) => {
                      if (
                        document.activeElement.classList.contains(
                          "eXEoGG26iwSi6UiZD8FI6"
                        )
                      ) {
                        e.preventDefault();
                        return;
                      }
                      onSubmit(e);
                    }}
                  >
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        {error}
                      </div>
                    )}
                    {success && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        Profile Updated successfully
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12 row p-0 m-0">
                        <div className="col-sm-3">
                          <div
                            className="form-group"
                            style={{ textAlign: "center" }}
                          >
                            {change_pp ? (
                              <ProfilePicture
                                minImageSize={0}
                                ref={profilePictureRef}
                                useHelper={true}
                                frameFormat="rounded-square"
                                image={pp_url !== "" ? pp_url : ""}
                              />
                            ) : (
                              <img className="Profile_pic" src={pp_url} />
                            )}
                            {button}
                          </div>
                        </div>
                        <div className="col-sm-9 row pr-0 m-0">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="firstName"
                                value={userDetails.firstName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "firstName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.firstName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Middle Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="middleName"
                                value={userDetails.middleName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "middleName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.middleName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <input
                                name="lastName"
                                value={userDetails.lastName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "lastName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.lastName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Phone No.</label>
                              <input
                                name="phoneno"
                                readOnly
                                value={userDetails.phoneno}
                                onChange={(e) => onChange(e)}
                                disabled
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                name="email"
                                readOnly
                                value={userDetails.email}
                                onChange={(e) => onChange(e)}
                                disabled
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Date of Birth</label>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  className="form-control"
                                  inputVariant="outlined"
                                  value={userDetails.dateOfBirth}
                                  onChange={(e) => onChange(e)}
                                  animateYearScrolling
                                  inputProps={{
                                    onChange: onChange,
                                  }}
                                  name="dateOfBirth"
                                  TextFieldComponent={customInput}
                                  disableFuture
                                  format="dd/MM/yyyy"
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Emergency Contact Name</label>
                              <input
                                name="emergencyContactName"
                                value={userDetails.emergencyContactName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "emergencyContactName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.emergencyContactName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Emergency Contact Number</label>
                              <input
                                name="emergencyContactDetails"
                                value={userDetails.emergencyContactDetails}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "emergencyContactDetails",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                className="form-control"
                                type="phone"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.emergencyContactDetails}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Blood Group{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Select2
                                fullWidth
                                name="bloodgroup"
                                onChange={(e) => onChange(e)}
                                value={userDetails.bloodgroup}
                              >
                                {bloodGroup}
                              </Select2>
                            </div>
                          </div>



                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Patient Medicare Number</label>
                              <input
                                name="Patient_Medicare_Number"
                                value={userDetails.Patient_Medicare_Number}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "Patient_Medicare_Number",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                className="form-control"
                                type="number"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.Patient_Medicare_Number}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-sm-12 row">
                                                        
                                                    </div> */}


                      <div className="col-sm-3">
                        <div className="form-group">
                          <div className="d-flex flex-row justify-content-start ">
                            <div>
                              <label>Weight</label>
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#d4edda",
                                width: "60px",
                                height: "25px",
                                borderColor: "#c3e6cb",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>In KG</span>
                            </div>
                          </div>
                          <input
                            name="weight"
                            value={formik.values.weight}
                            onChange={(e) => {
                              console.log(formik.values);
                              formik.setFieldValue("weight", e.target.value);
                              onChange(e);
                            }}
                            step="any"
                            className="form-control"
                            type="number"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.weight}
                          </small>
                        </div>
                      </div>




                      <div className="col-sm-3">
                        <div className="form-group">
                          <div className="d-flex flex-row justify-content-start ">
                            <div>
                              <label>Height</label>
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#d4edda",
                                width: "60px",
                                height: "25px",
                                borderColor: "#c3e6cb",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>In CM</span>
                            </div>
                          </div>
                          <input
                            name="height"
                            value={formik.values.height}
                            onChange={(e) => {
                              console.log(formik.values);
                              formik.setFieldValue("height", e.target.value);
                              onChange(e);
                            }}
                            step="any"
                            className="form-control"
                            type="number"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.height}
                          </small>
                        </div>
                      </div>





                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Insurance Number</label>
                          {/* <input name="bmr" value={state.userDetails.bmr} onChange={(e) => onChange(e)} className="form-control"  type="text"/> */}
                          <input
                            name="insuranceNumber"
                            value={userDetails.insuranceNumber}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "insuranceNumber",
                                e.target.value
                              );
                              onChange(e);
                            }}
                            className="form-control"
                            type="text"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.insuranceNumber}
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Allergies</label>
                          {/* <input name="bmr" value={state.userDetails.bmr} onChange={(e) => onChange(e)} className="form-control"  type="text"/> */}
                          <Select
                            required
                            placeholder="Enter Allergies"
                            onChange={(item) => onSelect(item)}
                            value={allergies}
                            // value={this.state.selectedDaysObj}
                            options={allegies}
                            noOptionsMessage={() =>
                              "Enter allergy and press enter"
                            }
                            isMulti={true}
                            closeMenuOnSelect={false}
                          />
                          <small className="form-text form-muted text-muted">
                            (Press Enter/Tab after entering allergy)
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group gender-select">
                          <label className="gen-label">
                            Gender: <span className="text-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                required
                                type="radio"
                                onChange={(e) => onChange(e)}
                                name="gender"
                                checked={userDetails.gender == "male"}
                                value="male"
                                className="form-check-input"
                              />
                              Male
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                required
                                type="radio"
                                onChange={(e) => onChange(e)}
                                name="gender"
                                checked={userDetails.gender === "female"}
                                value="female"
                                className="form-check-input"
                              />
                              Female
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                required
                                type="radio"
                                onChange={(e) => this.onChange(e)}
                                name="gender"
                                checked={userDetails.gender == "other"}
                                value="other"
                                className="form-check-input"
                              />
                              Other
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                required
                                type="radio"
                                onChange={(e) => this.onChange(e)}
                                name="gender"
                                checked={
                                  userDetails.gender ==
                                  "I wish not to disclose "
                                }
                                value="I wish not to disclose "
                                className="form-check-input"
                              />
                              I wish not to Disclose
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text-area"
                            name="address"
                            value={userDetails.address}
                            onChange={(e) => {
                              formik.setFieldValue("address", e.target.value);
                              onChange(e);
                            }}
                            className="form-control"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.address}
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label>
                            City/State/Country
                          </label>
                          <GoogleMap
                            style={{ width: 100 }}

                            value={geoLocation}
                            onChange={(e) => { }}
                            //   onChange={(e) =>  
                            //     // this.setState({
                            //     //   geoLocation: this.state.address,
                            //     // })
                            //     console.log("aaa")
                            //   }
                            setGeoLocation={(LatLng) => {
                              console.log("Place API", LatLng)
                              setGeoLatLng(LatLng)
                            }}
                            getAddress={(address) => {
                              // formik.setFieldValue(
                              //   "geoLocation",
                              //   address
                              // );
                              setGeoLocation(address)
                            }}
                          />

                        </div>
                      </div>


                      <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label>
                            Postal Code
                          </label>
                          <input
                            type="pincode"
                            name="postalcode"
                            value={userDetails.postalcode}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "postalcode",
                                e.target.value
                              );
                              onChange(e);
                            }}
                            className="form-control"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.postalcode}
                          </small>
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="d-flex justify-content-between">
                            <span>Preferred Pharmacy List</span>
                            <button
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="plus-icon"
                            >
                              <i className="fa fa-plus"></i> Add Pharmacy
                            </button>
                          </label>

                          <Select2
                            fullWidth
                            required
                            name="preferredPharmacy"
                            onChange={(e) => onChange(e)}
                            value={preferredPharmacyId || ""}
                          >
                            <MenuItem disabled={true} value="">
                              Please Select Pharmacy
                            </MenuItem>
                            {preferredPharmacyMenu}
                          </Select2>
                        </div>
                      </div> */}
                      {/* <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label>All Pharmacies</label>
                          <Select2
                            fullWidth
                            required
                            name="allPharmacy"
                            onChange={(e) => onChange(e)}
                            value={pharmacyId}
                          >
                            <MenuItem disabled={true} value="">
                              Please Select Pharmacy
                            </MenuItem>
                            {pharmacyMenu}
                          </Select2>
                        </div>
                      </div> */}
                    </div>
                    <div className="m-t-20 text-center">
                      <Link to="/my-profile">
                        <button className="btn custom-btn btn-secondary mr-3">
                          Close
                        </button>
                      </Link>
                      <button
                        className="btn custom-btn btn-primary"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddPatient;
