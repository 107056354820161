export const initialState = {
  userInfo: null,
  userPP: null,
  lat: "",
  long: "",
  country_code: ""
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      console.log("action", action.user);
      return {
        ...state,
        userInfo: action.user,
      };

    case "SET_USER_PP":
      console.log("action", action.url);
      let data = {...state.userInfo}
      return {
        ...state,
        userPP: action.url,
        userInfo: {...data, profile_pic:action.url}
      };
    case "SET_LOCATION":
      console.log("location", action.lat, action.long);
      return {
        ...state,
        lat: action.lat,
        long: action.long,
        country_code: action.country_code
      };
    default:
      break;
  }
};

export default reducer;


