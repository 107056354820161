import React, { useState, useEffect, useRef } from 'react';
import Loader from '../Loader/Loader';
import { useLocation } from "react-router-dom";

const Payment = () => {
    function randomString(len, charSet) {
        charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var randomString = '';
        for (var i = 0; i < len; i++) {
            var randomPoz = Math.floor(Math.random() * charSet.length);
            randomString += charSet.substring(randomPoz, randomPoz + 1);
        }
        return randomString;
    }
    const search = useLocation().search;
    let paymentId = new URLSearchParams(search).get("id");
    let amount = new URLSearchParams(search).get("amount");
    let uid = new URLSearchParams(search).get("uid");
    if (paymentId === null) {
        paymentId = randomString(12);
    } else if (amount === null) {
        amount = 1;

    } else if (uid === null) {
        uid = 123456
    }
    console.log(paymentId, 'amount ', amount, ' uid ', uid);
    const [ip, setIP] = useState('')
    const [creditCardNumber, setcreditCardNumber] = useState('')
    const [expiryMonth, setExpiryMonth] = useState('')
    const [expiryYear, setExpiryYear] = useState('')
    // const [amount, setAmount] = useState('')
    const [CVC, setCVC] = useState('')
    const [show, setshow] = useState(false)
    const [Indicator, setIndicator] = useState(false)
    const [ifs, setifs] = useState('')
    const [cardType, setcardType] = useState('')
    const [cardExpirationDate, setcardExpirationDate] = useState('')
    window["my_special_setting"] = ""
    const getIP = async () => {
        await fetch('https://geolocation-db.com/json/').then((response) => response.json())
            .then((data) => {
                setIP(data.IPv4)
            }).catch(e => {
                getIP()
            })
    }

    useEffect(() => {
        getIP()
    }, []);
    useEffect(() => {
        let splitted = cardExpirationDate.split('-')
        var year = splitted[0].slice(-2);
        setExpiryMonth(splitted[1])
        setExpiryYear(year)
    }, [cardExpirationDate]);


    function handleSelectChangeMonth(event) {
        setExpiryMonth(event.target.value);
    }
    function handleSelectChangeYear(event) {
        setExpiryYear(event.target.value);
    }


    function createMarkup(str) {
        return { __html: str };
    }

    const checkSubstring = (length, match) => {
        return creditCardNumber.substring(0, length) === match;
    };

    const setDate = e => {
        let data = (e.target.value).split("");
        console.log(data)
        let cardExpirationDate = (data.map((x) => {
            return x === "-" ? "/" : x
        })).join("");
        console.log(cardExpirationDate)
        this.setState({ cardExpirationDate });
    };

    useEffect(() => {
        const cardNumber = creditCardNumber
        if (cardNumber[0] === "4") {
            setcardType("Visa");
        } else if (checkSubstring(4, "6011")) {
            setcardType("Discover");
        } else if (checkSubstring(2, "51")) {
            setcardType("MasterCard");
        } else if (checkSubstring(2, "34")) {
            setcardType("American Express");
        } else if (checkSubstring(3, "300")) {
            setcardType("Diners Club");
        } else if (checkSubstring(2, "35")) {
            setcardType("JCB");
        } else {
            setcardType("💳");
        }
    }, [creditCardNumber]);
    const setNumber = e => {
        console.log('setnumber');
        const creditCardNumber = e.target.value;
        setcreditCardNumber(creditCardNumber, () => {
            const cardNumber = creditCardNumber
            if (cardNumber[0] === "4") {
                setcardType("Visa");
            } else if (checkSubstring(4, "6011")) {
                setcardType("Discover");
            } else if (checkSubstring(2, "51")) {
                setcardType("MasterCard");
            } else if (checkSubstring(2, "34")) {
                setcardType("American Express");
            } else if (checkSubstring(3, "300")) {
                setcardType("Diners Club");
            } else if (checkSubstring(2, "35")) {
                setcardType("JCB");
            } else {
                setcardType("💳");
            }
        });
    }
    async function processPayment() {
        setIndicator(true)
        var payload = {
            number: creditCardNumber,
            cvc: CVC,
            expMonth: expiryMonth,
            expYear: expiryYear,
            amount: amount,
            paymentId: paymentId,
            uid: uid
        };
        console.log(payload, 'this is payload')
        fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/user/payEmvCreate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then(res => res.json())
            .then(resp => {
                setIndicator(false)
                if (resp.error === 'Error') {
                    alert(resp.errorMessage)
                    window.ReactNativeWebView.postMessage(resp.errorMessage)
                    window.location.reload(true);
                    return
                }
                var token = resp.id;
                var currency = "AUD";
                var browser = navigator.userAgent;
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                var updatePayload = {
                    ipAddress: ip,
                    currency: currency,
                    token: token,
                    browser: browser,
                    timezone: timezone,
                    paymentId: paymentId,
                    uid: uid
                };
                setIndicator(true)

                fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/user/payEmvUpdate', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatePayload)
                }).then(res => {
                    return res.json()
                })
                    .then(resp => {
                        setIndicator(false)

                        if (resp.error === 'Error') {
                            alert(resp.errorMessage)
                            window.ReactNativeWebView.postMessage(resp.errorMessage)

                            window.location.reload(true);
                            return
                        }
                        setifs(resp.authentication.redirectHtml)
                        setshow(true)
                        var iframeNode = document.querySelector('#challengeFrame');
                        var token = resp.id;
                        var currency = "AUD";

                        if (JSON.stringify(resp).includes('Error')) {
                            alert('Something went wrong Please try again')
                            return;
                        }

                        var process3dSecureCallback = function (threedsResponse) {
                            window.removeEventListener('message', process3dSecureCallback);
                            var simplifyDomain = 'https://www.simplify.com';

                            if (threedsResponse.origin === simplifyDomain
                                && JSON.parse(threedsResponse.data)['secure3d']['authenticated']) {

                                var completePayload = {
                                    amount: amount,
                                    currency: currency,
                                    description: 'description',
                                    token: token,
                                    paymentId: paymentId,
                                    uid: uid
                                };
                                setIndicator(true)

                                fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/user/complete', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(completePayload)
                                }).then(res => {
                                    return res.json()
                                })
                                    .then(resp => {
                                        setIndicator(false)

                                        if (resp.error === 'Error') {
                                            window["my_special_setting"] = "failed"
                                            alert(resp.errorMessage)
                                            window.ReactNativeWebView.postMessage(resp.errorMessage)
                                            window.location.reload(true);
                                            return
                                        }

                                        if (resp.success) {
                                            window["my_special_setting"] = true
                                            alert('Payment to medvatic was successful, Thank you')
                                            window.ReactNativeWebView.postMessage('paymenttrue')

                                            window.location.reload(true);
                                        }
                                        else if (resp.paymentStatus === "DECLINED") {
                                            window["my_special_setting"] = "failed"
                                            alert('Payment failed, bank declined payment')
                                            window.ReactNativeWebView.postMessage('paymentbankfalse')

                                            window.location.reload(true);
                                        }
                                        else {
                                            window["my_special_setting"] = "failed"
                                            alert('Payment failed, Hold on for few minutes and try again')
                                            window.ReactNativeWebView.postMessage('paymentfalse')
                                            window.location.reload(true);
                                        }
                                    })
                                    .catch(e => {
                                        setIndicator(false)
                                        window["my_special_setting"] = "failed"
                                        alert('Network error, please try again after a few minutes')
                                        window.location.reload(true);
                                    })
                            }
                        };

                        var iframe = document.getElementById('challengeFrame');
                        iframe.addEventListener('load', function () {
                            window.addEventListener('message', process3dSecureCallback);
                        });
                        document.getElementById('threedsChallengeRedirectForm').submit();
                    })
                    .catch(e => {
                        setIndicator(false)
                        alert('Network error, please try again after a few minutes')
                    })

            })
            .catch(e => {
                setIndicator(false)
                alert('Network error, please try again after a few minutes')
            })

    }


    const handleSubmit = (e) => {
        processPayment()
        // window.ReactNativeWebView.postMessage("Hello!")
        e.preventDefault();
    }

    return (
        <div className="containers">
            {Indicator && <Loader />}
            {!show &&
                <>
                    <div className="credit-card">
                        <div className="credit-card-inner">
                            <div className="credit-card-front">

                                <div id="card-Medvatic">Medvatic</div>
                                <div id="card-details-right">Type</div>
                                <div id="card-type">{cardType}</div>

                                <div id="card-details">Number</div>
                                <div id="card-number">{creditCardNumber}</div>

                                <div id="card-expiration">
                                    {<div id="validthru">Valid Thru</div>}
                                    {cardExpirationDate}
                                </div>

                                <div id="card-details">CVC</div>
                                <div id="card-holder-name">{CVC}</div>
                            </div>
                        </div>
                    </div>
                    <div className="input-label">We accept
                        <img
                            src="https://e7.pngegg.com/pngimages/117/675/png-clipart-visa-and-mastercard-ads-mastercard-credit-card-american-express-visa-debit-card-mastercard-text-payment.png"
                            alt="VISA MASTERCARD"
                            style={{
                                width: '130px',
                                height: '35px',
                            }}
                        />
                        <img
                            src="https://seeklogo.com/images/A/american-express-logo-F365CA38BB-seeklogo.com.png"
                            alt="AE"
                            style={{
                                width: '35px',
                                height: '35px',
                            }}
                        />
                    </div>
                    <form className="card-form" id="simplify-payment-forms" action="" method="POST" onSubmit={e => { handleSubmit(e) }}>
                        <label className="input-label">Credit Card Number</label>
                        <input
                            placeholder="Enter your credit card number"
                            options={{ creditCard: true }}
                            id="number-input"
                            name="number-input"
                            className="text-inputss"
                            maxLength="16"
                            value={creditCardNumber}
                            onChange={e => setcreditCardNumber(e.target.value)}
                        />
                        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                            <label className="input-label">CVC Security Code</label>
                            <input
                                options={{
                                    numeral: "true"
                                }}
                                placeholder="Enter CVC"
                                maxLength="3"
                                value={CVC}
                                className="text-inputss"
                                onChange={e => setCVC(e.target.value)}
                            />
                        </div>

                        {/* <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <label className="input-label">Amount</label>
                            <input
                                options={{
                                    numeral: "true"
                                }}
                                placeholder="Enter Amount"
                                maxLength="9"
                                value={amount}
                                className="text-inputss"
                                onChange={e => setAmount(e.target.value)}
                            />
                        </div> */}
                        <div className="date-and-csv" style={{ display: "flex" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                                <label className="input-label">
                                    Expiration Date(YYYY-MM)
                                </label>
                                <input
                                    type="month"
                                    placeholder="YYYY-MM"
                                    className="text-inputss"
                                    onChange={e => {
                                        console.log(e.target.value);
                                        return setcardExpirationDate(e.target.value)
                                    }}
                                    style={{ height: "23px", fontSize: "16px", fontWeight: "100" }}
                                />
                            </div>
                        </div>
                        {/* <div id="card-details-note">The amount is in Currency AUD with 2 decimal points</div>
                        <div id="card-details-note">ex: 1 = 0.01 AUD</div> */}

                        <button className="card-button" type="submit">Process Payment</button>
                    </form>
                </>}

            {show && <div dangerouslySetInnerHTML={createMarkup(ifs)} />}
            <div name="challengeFrames" id="challengeFrames" hidden></div>
            <form id="paymentForm" name="paymentForm" hidden></form>
            <form id="initiate3dsForm" name="initiate3dsForm" hidden></form>
            <form id="threedsChallengeRedirectForm" name="threedsChallengeRedirectForm" hidden></form>
            <script id="initiate-authentication-script"></script>
        </div>
    );
}

export default Payment;
