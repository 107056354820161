import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={index}
      aria-labelledby={index}
      {...other}
    >
      {children}
    </div>
  );
}

export default class AppointmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointments: [],
      upcomming: [],
      today: [],
      past: [],
      tab: 'one',
      modalOpen: false,
      modalLoading: false,
      currentAppointment: {},
      success: false,
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.fetchAppointments(true);
  }

  fetchAppointments(flag) {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listAppointmentsReceptionist(this.props.userData.hospitalId)
           .then((result) => {
        let res = result.data

        console.log(res);
        if (this._isMounted) {
          let dataSorted = res.appointments.sort((a, b) => {
            return b.created_at - a.created_at
          })
          console.log("datasorted" , dataSorted)

          let date = new Date();
          date.setHours(0,0,0,0);
          let startDate = date.getTime()
          date.setHours(23,59,59,0);
          let endDate = date.getTime();

          let upcommingArr = [];
          let todayArr = [];
          let pastArr = [];

          dataSorted.forEach((doc) => {
            if(doc.from >= startDate && doc.from <= endDate){
              todayArr.push(doc);
            } else if(doc.from < startDate){
              pastArr.push(doc)
            } else if(doc.from > endDate){
              upcommingArr.push(doc);
            }
          })
          
          this.setState(
            {
              loading: false,
              appointments: dataSorted,
              upcomming: upcommingArr,
              today: todayArr,
              past: pastArr,
            },
            () => {
              if(flag){
                if ($(".datatable").length > 0) {
                  $(".datatable").DataTable({
                    scrollX: true,
                  });
                }
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = (e) => {
    let formObj = { ...this.state.currentAppointment };
    formObj[e.target.name] = e.target.value;
    this.setState({
      currentAppointment: formObj,
    });
  };

  handleTabChange (event, newValue) {
    this.setState({tab: newValue});
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Appointments</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              {/* <Link
                to="/receptionist/add-appointment"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Appointment
              </Link> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Appointment updated successfully
                </div>
              )}
              <Tabs variant="fullWidth" centered className="mb-3" value={this.state.tab} onChange={(e,v) => this.handleTabChange(e,v)} aria-label="wrapped label tabs example">
                <Tab
                  value="one"
                  label="Upcoming"
                  wrapped
                />
                <Tab value="two" label="Today" wrapped/>
                <Tab value="three" label="Past" wrapped/>
              </Tabs>
              <TabPanel value={this.state.tab} index="one">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>User Details</th>
                        <th>Appointment Type</th>
                        <th>Date / Time</th>
                        {/* <th>Chief complaints</th>
                        <th>Relevant points from history</th>
                        <th>Examination / Lab Findings</th>
                        <th>Suggested Investigations</th> */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.upcomming &&
                        this.state.upcomming.map((appointment, index) => (
                          <tr key={index}>
                            <td>
                              <Link to={{
                                pathname: "/hospital/edit-appoinment",
                                // search: "?sort=name",
                                // hash: "#the-hash",
                                state: {
                                  name: this.state.upcomming[index],
                                },
                              }}>
                              {appointment.name}
                              </Link>
                              <br />
                              {appointment.phoneno}
                              <br />
                              {appointment.email}
                            </td>
                            <td>
                              {appointment.appointmentType == 1
                                ? "Physical"
                                : "Video"}
                            </td>
                            <td>
                              {appointment.slot_date}
                              <br />
                              {appointment.slot_time}
                            </td>
                            {/* <td>{appointment.chiefComplaints}</td>
                            <td>{appointment.pointsFromHistory}</td>
                            <td>{appointment.labFindings}</td>
                            <td>{appointment.suggestedInvestigations}</td> */}
                            <td>
                              <span className="text-capitalize">
                                {appointment.appointment_status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tab} index="two">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>User Details</th>
                        <th>Appointment Type</th>
                        <th>Date / Time</th>
                        {/* <th>Chief complaints</th>
                        <th>Relevant points from history</th>
                        <th>Examination / Lab Findings</th>
                        <th>Suggested Investigations</th> */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.today &&
                        this.state.today.map((appointment, index) => (
                          <tr key={index}>
                            <td>
                              <Link to={{
                                pathname: "/hospital/edit-appoinment",
                                // search: "?sort=name",
                                // hash: "#the-hash",
                                state: {
                                  name: this.state.today[index],
                                },
                              }}>
                              {appointment.name}
                              </Link>
                              <br />
                              {appointment.phoneno}
                              <br />
                              {appointment.email}
                            </td>
                            <td>
                              {appointment.appointmentType == 1
                                ? "Physical"
                                : "Video"}
                            </td>
                            <td>
                              {appointment.slot_date}
                              <br />
                              {appointment.slot_time}
                            </td>
                            {/* <td>{appointment.chiefComplaints}</td>
                            <td>{appointment.pointsFromHistory}</td>
                            <td>{appointment.labFindings}</td>
                            <td>{appointment.suggestedInvestigations}</td> */}
                            <td>
                              <span className="text-capitalize">
                                {appointment.appointment_status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tab} index="three">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>User Details</th>
                        <th>Appointment Type</th>
                        <th>Date / Time</th>
                        {/* <th>Chief complaints</th>
                        <th>Relevant points from history</th>
                        <th>Examination / Lab Findings</th>
                        <th>Suggested Investigations</th> */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.past &&
                        this.state.past.map((appointment, index) => (
                          <tr key={index}>
                            <td>
                              <Link to={{
                                pathname: "/hospital/edit-appoinment",
                                // search: "?sort=name",
                                // hash: "#the-hash",
                                state: {
                                  name: this.state.past[index],
                                },
                              }}>
                              {appointment.name}
                              </Link>
                              <br />
                              {appointment.phoneno}
                              <br />
                              {appointment.email}
                            </td>
                            <td>
                              {appointment.appointmentType == 1
                                ? "Physical"
                                : "Video"}
                            </td>
                            <td>
                              {appointment.slot_date}
                              <br />
                              {appointment.slot_time}
                            </td>
                            {/* <td>{appointment.chiefComplaints}</td>
                            <td>{appointment.pointsFromHistory}</td>
                            <td>{appointment.labFindings}</td>
                            <td>{appointment.suggestedInvestigations}</td> */}
                            <td>
                              <span className="text-capitalize">
                                {appointment.appointment_status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
