// jshint esversion:6

import { UploadOutlined } from "@ant-design/icons";
import { Avatar, Grid, Paper } from "@material-ui/core";
import { Button as AntButton, Upload } from "antd";
import medImage from "../../assets/logo-dark.png";
//Datatable Modules
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";
import moment from "moment";
import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { uuid } from "uuidv4";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import "./style.css";
import ExternalChat from "../Doctor/ExternalChat";
let allowedFiles = ".pdf,.doc,.docx,.jpg,.png,.jpeg";

// const MyDoc = () => (
//   <Document>
//     <Page>
//         <View>
//           <Text>
//               Hallo das ist ein Twst
//           </Text>
//       </View>
//     </Page>
//   </Document>
// )

const imgLink =
  "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

export default class Appointment_details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointments: {},
      razorpayOrder: {},
      prescription: [],
      pp_url: "",
      doctorDetails: {},
      modalOpen: false,
      fileModalOpen: false,
      modalLoading: false,
      currentAppointment: {},
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      currentIndex: "",
      currentAppId: "",
      success: false,
      userData: null,
      chat: [],
      message: "",
      comments: [],
      fetchComments: false,
      isJoinEnabled: false,
      Externally: false,
      fileList: [],
      show: false,
      razorpayModal: false,
      rzpPaymentID: "",
      rzpOrderID: "",
      modalshow: false,
      paymentId: ''

    };
    this._isMounted = false;
    this.chatSubmitHandler = this.chatSubmitHandler.bind(this);
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
      } else {
        this.props.history.push("/login");
      }
    });
    this._isMounted = true;
    this.fetchAppointmentDetails(true);
    this.ml()
  }

  ml = async () => {
    console.log('ml called');
    let isIt = 'failed'
    let API = new Apilib();
    if (isIt === true) {
      console.log('yes its working');
      alert('payment successful')
      // write databse manipulaion command
    } else if (isIt == 'failed') {
      console.log('yes no its working');
      await API.getAppointmentDetails(this.props.match.params.id)
        .then((result) => {
          let res = result.data
          console.log(res, 'tresure');
          console.log(result, 'result');
        }).catch((e) => {
          console.log('error in get appointment details', e)
        })
      // write databse manipulaion command
      // alert('payment was not successful')
    }
  }

  showModal = async (e) => {
    async function randomString(len, charSet) {
      charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var randomString = '';
      for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      return randomString;
    }
    let tempid = await randomString(12);
    let API = new Apilib();
    console.log('calling function to save payment id');
    // })
    try {

      await API.saveDataToAppointment(JSON.stringify({
        paymentId: tempid, appointmentId: this.state.appointments.appointmentId,
      })
      )
    } catch (e) {
      console.log(e, 'this is error');
    }

    e.preventDefault();
    // window.open

    var newWindow = window.open(
      window.location.origin +
      "/#/payment?id=" +
      tempid +
      "&amount=" +
      this.state.appointments.base_fee +
      "&uid=" +
      this.props.userData.uid,
      "_blank"
    );
    newWindow.my_special_setting = false;
    setInterval(async () => {
      let isIt = newWindow.my_special_setting
      let API = new Apilib();
      if (isIt === true) {
        console.log('yes its working');
        alert('payment successful')
        // write databse manipulaion command
      } else if (isIt == 'failed') {
        console.log('yes no its working');
        await API.getAppointmentDetails(this.props.match.params.id)
          .then((result) => {
            let res = result.data
            console.log(res, 'tresure');
            console.log(result, 'result');
          }).catch((e) => {
            console.log(e, 'errro in appointment d');
          })
        // write databse manipulaion command
        // alert('payment was not successful')
      }
      else {
        console.log('nope some error in this');
      }
    }, 5000);
  };

  hideModal = () => {
    this.setState({ modalshow: false });
  };


  fetchAppointmentDetails(flag) {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getAppointmentDetails(this.props.match.params.id)
      .then((result) => {
        let res = result.data
        if (this._isMounted) {
          this.makeDownloadUrl(this.props.userData.profile_picture);
          console.log("res", res.prescription_details);
          // API.createRazorpayOrder(res.appointment_details.appointmentId)
          //   .then((res) =>
          //     this.setState({
          //       razorpayOrder: res.data,
          //     })
          //   );
          console.log(res.appointment_details, 'res.appointment_detailsres.appointment_details')
          this.setState(
            {
              appointments: res.appointment_details,
              fileList: res.appointment_details.patient_docs
                ? res.appointment_details.patient_docs.map((doc) => ({
                  uid: doc.id,
                  status: "done",
                  url: doc.publicUrl,
                  name: doc.name,
                }))
                : [],
              prescription: res.prescription_details,
              isJoinEnabled:
                moment(new Date()).isSameOrAfter(
                  moment(res.appointment_details.from).subtract("15", "minutes")
                ) &&
                moment(new Date()).isSameOrBefore(
                  moment(res.appointment_details.to)
                ),
            },
            () => {
              console.log("Appt details->", this.state.appointments);
              if (flag) {
                if (this.state.appointments.doctorId) {
                  API.getUserDetails(3, this.state.appointments.doctorId)
                    .then((result) => {
                      let res = result.data

                      console.log("res doctor", res.user);
                      let doctor = res.user;
                      this.setState({
                        doctorDetails: doctor,
                        loading: false,
                      });
                    })
                    .catch((error) => {
                      console.log("error doctor", error);
                    });
                }
              }
            }
          );
          this.joinButtonInterval = setInterval(() => {
            this.setState({
              isJoinEnabled:
                moment(new Date()).isSameOrAfter(
                  moment(res.appointment_details.from).subtract("15", "minutes")
                ) &&
                moment(new Date()).isSameOrBefore(
                  moment(res.appointment_details.to)
                ),
            });
          }, 60000);
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.joinButtonInterval);
  }

  makeDownloadUrl = (path) => {
    this.setState({ loading: true });
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    gsReference
      .getDownloadURL()
      .then((url) => {
        console.log(url);
        this.setState({
          pp_url: url,
          loading: false,
        });

        console.log("PPurl>>.", this.state.pp_url);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  async openVideoCall(e, appointment) {
    e.preventDefault();

    let videoCallWindow = window.open(
      window.location.origin +
      "/#/videocall?meetingid=" +
      appointment.meetingid +
      "&appointmentid=" +
      appointment.appointmentId +
      "&uid=" +
      this.props.userData.uid +
      "&token=" +
      appointment.token,
      "_blank"
    );
    let API = new Apilib();
    let newToken = await API.getAPIToken();
    console.log('newtoken', newToken);
    videoCallWindow.tokentoverify = newToken;
  }

  externalChat(e) {
    // e.preventDefault();
    this.setState({
      Externally: !this.state.Externally,
    });
  }

  viewFile(e, ref) {
    e.preventDefault();
    storage
      .ref(ref)
      .getDownloadURL()
      .then((path) => {
        window.open(path, "_blank");
      });
  }

  validateFileSize(file) {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      Notify({
        alert: true,
        type: "error",
        title: "Sorry, Max filesize allowed is 5MB",
      });
      return false;
    } else {
      return true;
    }
  }

  validateFileExtension(file) {
    var _validFileExtensions = allowedFiles.split(",");
    var sFileName = file.name;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        this.setState(
          {
            fileExtError:
              "Sorry, " +
              sFileName +
              " is invalid, allowed extensions are: " +
              _validFileExtensions.join(", "),
          },
          () => {
            return false;
          }
        );
      } else {
        return true;
      }
    }
  }

  handleFileChange(e) {
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    this.setState({
      fileExtError: "",
      fileSizeError: "",
    });
    if (this.validateFileExtension(file)) {
      if (this.validateFileSize(file)) {
        this.setState({
          inputFileData: file,
        });
      }
    }
  }

  handleDeleteOpen(e) {
    e.preventDefault();
    this.setState({
      show: !this.state.show,
    });
  }

  uploadFile() {
    if (
      this.state.fileExtError == "" &&
      this.state.fileSizeError == "" &&
      this.state.inputFileData !== ""
    ) {
      ///chainid/hospitalid/appointments/appointmentid/reports

      let chainId = this.props.userData.hospitalChainId;
      let hospitalId = this.props.userData.hospitalId;
      let appId = this.state.currentAppId;

      let file = this.state.inputFileData;
      this.setState(
        {
          modalLoading: true,
        },
        () => {
          const uploadTask = storage
            .ref(
              `/${chainId}/${hospitalId}/appointments/${appId}/reports/${file.name}`
            )
            .put(file);
          uploadTask.on(
            "state_changed",
            (snapShot) => {
              console.log(
                (snapShot.bytesTransferred / snapShot.totalBytes) * 100
              );
            },
            (err) => {
              console.log(err);
            },
            () => {
              let data = {};
              data["id"] = appId;
              data[
                "report_path"
              ] = `/${chainId}/${hospitalId}/appointments/${appId}/reports/${file.name}`;
              let API = new Apilib();
              API.addAppointmentReport(JSON.stringify(data))
                .then((res) => {
                  this.setState({
                    modalLoading: false,
                  });
                  if (res.ok) {
                    let dataApp = {
                      ...this.state.appointments,
                    };
                    dataApp[
                      "report_path"
                    ] = `/${chainId}/${hospitalId}/appointments/${appId}/reports/${file.name}`;

                    this.setState({
                      fileModalOpen: false,
                      fileUploaded: true,
                      currentAppId: "",
                      appointments: dataApp,
                    });
                  }
                })
                .catch((error) => {
                  Notify({
                    alert: true,
                    type: "error",
                    title: "Failed to upload file!",
                  });
                  console.log(error, 'this is error');
                });
            }
          );
        }
      );
    }
  }

  fileUploadProps = {
    onChange: (info) => {
      console.log(info, "on change");
      let fileList = [...info.fileList];
      fileList = fileList
        .filter((file) => Object.keys(file).includes("status"))
        .map((file) => {
          if (file.response) {
            file.url = file.response.data.publicUrl;
            file.uid = file.response.data.id;
          }
          return file;
        });

      this.setState({ fileList });
    },
    accept: allowedFiles,
    beforeUpload: (file, fileList) => {
      console.log(fileList);
      return this.validateFileSize(file);
    },
    onRemove: (file) => {
      try {
        this.setState({
          loading: true,
        });
        let API = new Apilib();
        let res = API.deleteAppointmentReport(
          JSON.stringify({
            id: this.state.appointments.appointmentId,
            fileId: file.uid,
          })
        );
        console.log(res, 'after uload this is it')
        if (!res.statusText == "OK") {
          throw new Error("Failed to delete file " + file.name);
        }
        Notify({
          alert: true,
          type: "success",
          title: "File deleted successfully",
        });
        this.setState({
          loading: false,
        });
        return true;
      } catch (error) {
        Notify({
          alert: true,
          type: "error",
          title: error.message,
        });
        this.setState({
          loading: false,
        });
        return false;
      }
    },
    customRequest: async ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) => {
      try {
        let chainId = this.props.userData.hospitalChainId;
        let hospitalId = this.props.userData.hospitalId;
        let appId = this.state.appointments.appointmentId;
        const newFileName = uuid() + "-" + file.name;
        const filePath = `/${chainId}/${hospitalId}/appointments/${appId}/reports/${newFileName}`;
        const uploadTask = storage.ref(filePath).put(file, {
          contentDisposition: `inline; filename="${file.name}"`,
        });
        uploadTask.on(
          "state_changed",
          (snapShot) => {
            onProgress(
              {
                percent:
                  (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
              },
              file
            );
          },
          (err) => {
            onError(err);
            console.log(err);
          },
          async () => {
            try {
              let data = {};
              data["id"] = appId;
              data["filePath"] = filePath;
              data["fileName"] = file.name;
              let API = new Apilib();
              const publicUrl = await storage
                .refFromURL("gs://medvatic-438af.appspot.com" + filePath)
                .getDownloadURL();
              data["publicUrl"] = publicUrl;
              await API.addAppointmentReport(JSON.stringify(data))
                .then((res) => {
                  console.log(res, 'this is res you re quirw', typeof res)
                  if (res.statusText == "OK") {
                    return res
                  }
                  console.log(res, 'this is res you re quirw', typeof res)
                  throw new Error("d to upload image");
                })
                .then((res) => {
                  onSuccess(res, file);
                })
                .catch((error) => {
                  Notify({
                    alert: true,
                    type: "error",
                    title: "Failed to upload file!",
                  });
                  onError(error);
                  console.log(error, 'this is error1');
                });
            } catch (error) {
              Notify({
                alert: true,
                type: "error",
                title: "Failed to upload file!",
              });
              onError(error);
              console.log(error, 'this is error2');
            }
          }
        );
      } catch (error) {
        Notify({
          alert: true,
          type: "error",
          title: "Failed to upload file!",
        });
        onError(error);
        console.log(error, 'this is error3');
      }
    },
  };

  handleFileModalOpen(e, appointmentId, index) {
    e.preventDefault();
    this.setState({
      fileModalOpen: true,
      currentAppId: appointmentId,
      currentIndex: index,
    });
  }

  handleFileModalClose() {
    this.setState({
      fileModalOpen: false,
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      currentAppId: "",
      currentIndex: "",
    });
  }

  chatHandler(e) {
    let message = e.target.value;
    this.setState({ message: message }, () => {
      console.log("message", this.state.message);
    });
  }

  chatSubmitHandler(e) {
    e.preventDefault();
    let messages = this.state.message;

    if (messages == "") {
      return;
    }

    const commentData = {
      appointment_id: this.props.match.params.id,
      userType: localStorage.getItem("@displayName"),
      userId: this.props.userData.uid,
      userName: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      comment: messages,
      commenttype: 2,
    };

    console.log("comments", commentData);
    this.setState({ loading: true });
    const API = new Apilib();
    API.addComment(JSON.stringify(commentData))
      .then((result) => {
        let res = result.data

        console.log("add_comment res", res);
        this.setState({ loading: false, fetchComments: true, message: "" });
      })
      .catch((err) => {
        this.setState({ loading: false, fetchComments: false });
        console.log("add_comment err", err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.fetchComments !== prevState.fetchComments &&
      this.state.fetchComments
    ) {
      const API = new Apilib();
      API.fetchComments(this.props.match.params.id, 2)
        .then((result) => {
          let res = result.data

          let data = res.data;
          let sorted = data.sort((a, b) => {
            return (
              a.createdAt._seconds * 1000 +
              a.createdAt._nanoseconds / 1000000 -
              (b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000)
            );
          });
          this.setState({
            comments: sorted,
            loading: false,
            fetchComments: false,
          });
        })
        .catch((err) => {
          if (this._isMounted) {
            this.setState({ loading: false, fetchComments: false });
          }
          console.log("comments res", err);
        });
    }
  }

  deleteAppointment(currentAppointment) {
    const API = new Apilib();
    let data = {
      id: currentAppointment.appointmentId,
    };
    this.setState({
      loading: true,
    });
    API.deleteAppointment(JSON.stringify(data))
      .then((result) => {
        let res = result.data
        if (res.code == 209) {
          this.setState({
            show: false,
            loading: false,
          });
          Notify({
            alert: true,
            type: "error",
            title: res.message,
          });
          return;
        }
        this.setState({
          show: false,
          loading: false,
        });
        Notify({
          alert: true,
          type: "success",
          title: "Your appointment has been cancelled successfully",
        });
        this.props.history.push("/my-appointments");
      })
      .catch((err) => {
        console.log('this is catch', err);
        this.setState({
          show: false,
          loading: false,
        });
        Notify({
          alert: true,
          type: "error",
          title: err.message,
        });
      });
  }

  loadScript(src) {
    console.log("In load");
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        console.log("In load true");
        resolve(true);
      };
      script.onerror = () => {
        console.log("In load false");
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async displayRazorpay(e) {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Failed loading razorpay!!");
      return;
    }
    console.log("Razorpay-> ", this.state.razorpayOrder);
    const options = {
      key: "rzp_live_oHw3R4bpaIlAbP",
      amount:
        this.state.razorpayOrder?.amount.toString() ||
        this.state.appointments?.fees?.total,
      currency: this.state.razorpayOrder?.currency,
      name: "Medvatic Pty Ltd",
      description: "Commonwealth Bank",
      image: medImage,
      order_id: this.state.razorpayOrder?.id,
      handler: (response) => this.razorpayPaymentHandler(response),
      prefill: {
        name: this.state.appointments?.name,
        email: this.state.appointments?.patient_email,
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  }


  async doPayment(data) {
    // payment ? id = ${ props.route.params.paymentId }& amount=${ props.route.params.baseFee }& uid=${ props.route.params.userId }
    // route to this
    // open modal and render payment page and maintain state



  }

  razorpayPaymentHandler(response) {
    console.log("Rzp response-> ", response);
    Notify({
      alert: true,
      type: "success",
      title: "Payment successful",
    });

    this.setState({
      razorpayModal: true,
      rzpOrderID: response.razorpay_order_id,
      rzpPaymentID: response.razorpay_payment_id,
    });

    // Razorpay Signature : ${response.razorpay_signature}`
  }

  handleRazorpayModalClose() {
    this.setState({
      razorpayModal: false,
      modalshow: false
    });
  }

  render() {
    return (
      <>
        <div className="main-content account-content">
          {this.state.loading ? <Loader /> : null}
          {/* {this.state.modalshow ? modal : null} */}

          {/* comm bank payment */}
          <Modal
            style={{ width: '100%', height: '100%', backgroundColor: 'white', }}
            backdrop="static"
            centered={true}
            show={this.state.modalshow}
            onHide={() => this.handleRazorpayModalClose()}
            animation={false}>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.modalLoading && <Loader />}


              {/* <Modal.Header closeButton>
              <Modal.Title>Payment Details</Modal.Title>
            </Modal.Header> */}
              {/* <Modal.Body style={{ width: '80%', height: '50%', backgroundColor: 'red' }}>
              <div>

                <div className="container">Razorpay Payment ID - {this.state.rzpPaymentID}</div>
                <div>Razorpay Order ID - {this.state.rzpOrderID}</div>
              </div>
            </Modal.Body> */}

              {/* <Modal.Footer> */}
              <iframe src={`https://medvatic.com/#/payment?id=${'14256'}&amount=${1}&uid=${'12545421251'}`} width='100%' height='100%' />
              <Button
                variant="secondary"
                onClick={() => this.handleRazorpayModalClose()}>
                Close
              </Button>
              {/* </Modal.Footer> */}
            </div>

          </Modal>
          {/*           
          <Modal
            backdrop="static"
            centered={true}
            show={this.state.razorpayModal}
            onHide={() => this.handleRazorpayModalClose()}
            animation={false}>
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>Payment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>Razorpay Payment ID - {this.state.rzpPaymentID}</div>
              <div>Razorpay Order ID - {this.state.rzpOrderID}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.handleRazorpayModalClose()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
          <div className="content">
            <div className="container">
              <div className="content">
                <div className="row">
                  <div className="col-sm-4 col-3"></div>
                </div>
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <>
                      {this.state.appointments ? (
                        <>
                          <div className="card patient-card">
                            {this.state.fileUploaded && (
                              <div
                                className="alert alert-success alert-dismissible fade show"
                                role="alert">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="alert"
                                  aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                  <span className="sr-only">Close</span>
                                </button>
                                File uploaded successfully
                              </div>
                            )}
                            <div className="card-header px-5">
                              <h2 className="patient-name">
                                {this.state.appointments.doctorName}
                                <p>
                                  {this.state.appointments.slot_date} <br />{" "}
                                  <span className="text-muted mt-4">
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"></i>{" "}
                                    {moment(
                                      this.state.appointments.from
                                    ).format("LT")}{" "}
                                    -{" "}
                                    {moment(this.state.appointments.to).format(
                                      "LT"
                                    )}
                                  </span>
                                </p>
                              </h2>
                              <p className="">
                                <div className="row justify-content-between ">
                                  <span className="d-flex col-9 align-items-center mb-1">
                                    <span
                                      className="p-2 mr-1 d-flex"
                                      style={{
                                        backgroundColor: "rgb(249 249 249)",
                                        boxSizing: "border-box",
                                        borderRadius: 5,
                                        fontWeight: 500,
                                        fontSize: "1rem",
                                        color: "rgb(150 150 150)",
                                        width: "fit-content",
                                      }}>
                                      <i
                                        class="fa fa-envelope"
                                        aria-hidden="true"></i>
                                    </span>
                                    <span
                                      className="my-1 d-flex"
                                      style={{
                                        boxSizing: "border-box",
                                        borderRadius: 5,
                                        fontWeight: 500,
                                        fontSize: "1rem",
                                        color: "rgb(129 129 129)",
                                        width: "fit-content",
                                      }}>
                                      {this.state.appointments?.doctor_email ||
                                        "No Email Specified"}
                                    </span>
                                  </span>

                                  <div className="col-3">
                                    {this.state.appointments
                                      .appointment_status === "booked" && (
                                        <span
                                          className="px-2 py-1"
                                          style={{
                                            backgroundColor: "#ffeecc",
                                            boxSizing: "border-box",
                                            borderRadius: 5,
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                            color: "#cc8800",
                                          }}>
                                          Booked
                                        </span>
                                      )}
                                    {this.state.appointments
                                      .appointment_status === "completed" && (
                                        <span
                                          className="px-2 py-1"
                                          style={{
                                            backgroundColor: "#cef3fd",
                                            boxSizing: "border-box",
                                            borderRadius: 5,
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                            color: "#0788ab",
                                          }}>
                                          Completed
                                        </span>
                                      )}
                                    {this.state.appointments
                                      .appointment_status === "cancelled" && (
                                        <span
                                          className="px-2 py-1"
                                          style={{
                                            backgroundColor: "#f5bfbf",
                                            boxSizing: "border-box",
                                            borderRadius: 5,
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                            color: "#f03232",
                                          }}>
                                          Cancelled
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="row justify-content-between w-100">
                                  {!!this.state.appointments.phoneno && (
                                    <span className="d-flex col-9 align-items-center mb-1">
                                      <span
                                        className="p-2 mr-1 d-flex"
                                        style={{
                                          backgroundColor: "rgb(249 249 249)",
                                          boxSizing: "border-box",
                                          borderRadius: 5,
                                          fontWeight: 500,
                                          fontSize: "1rem",
                                          color: "rgb(150 150 150)",
                                          width: "fit-content",
                                        }}>
                                        <i
                                          class="fa fa-phone"
                                          aria-hidden="true"></i>
                                      </span>
                                      <span
                                        className="my-1 d-flex"
                                        style={{
                                          boxSizing: "border-box",
                                          borderRadius: 5,
                                          fontWeight: 500,
                                          fontSize: "1rem",
                                          color: "rgb(129 129 129)",
                                          width: "fit-content",
                                        }}>
                                        {this.state.appointments.phoneno}
                                      </span>
                                    </span>
                                  )}
                                  {this.state.appointments
                                    .appointment_status === "booked" ? (
                                    <div className="col-3">
                                      <button
                                        onClick={(e) =>
                                          this.handleDeleteOpen(e)
                                        }
                                        className="btn btn-primary px-3 btn-rounded pay-btn cancel-apt-btn">
                                        Cancel Appointment
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                                {/* <div className="App">
                                    <button data-tip data-for="registerTip">
                                      Register
                                    </button>

                                    <ReactTooltip
                                      id="registerTip"
                                      place="right"
                                      effect="solid"
                                    >
                                      Base fees={" "}
                                      <i
                                        class="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>
                                      500 <br /> Variable fees={" "}
                                      <i
                                        class="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>
                                      600
                                    </ReactTooltip>
                                  </div> */}
                              </p>
                            </div>
                            <hr />
                            <div className="card-body">
                              <div className="col-sm-11 mx-auto mb-4">
                                <div className="row">
                                  {this.state.appointments.chiefComplaints ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Chief Complaints:
                                      </h5>
                                      <p className="card-text text">
                                        {
                                          this.state.appointments
                                            .chiefComplaints
                                        }
                                      </p>
                                    </div>
                                  ) : null}
                                  {this.state.appointments.pointsFromHistory ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Relevant points from history:
                                      </h5>
                                      <p className="card-text text">
                                        {
                                          this.state.appointments
                                            .pointsFromHistory
                                        }
                                      </p>
                                    </div>
                                  ) : null}
                                  {this.state.appointments.labFindings ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Examination / Lab Findings:
                                      </h5>
                                      <p className="card-text text">
                                        {this.state.appointments.labFindings}
                                      </p>
                                    </div>
                                  ) : null}
                                  {this.state.appointments
                                    .suggestedInvestigations ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Suggested Investigations:
                                      </h5>
                                      <p className="card-text text">
                                        {
                                          this.state.appointments
                                            .suggestedInvestigations
                                        }
                                      </p>
                                    </div>
                                  ) : null}
                                  {this.state.appointments
                                    .provisionalDiagnosis ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Provisional Diagnosis:
                                      </h5>
                                      <p className="card-text text">
                                        {
                                          this.state.appointments
                                            .provisionalDiagnosis
                                        }
                                      </p>
                                    </div>
                                  ) : null}
                                  {this.state.appointments.diagnosis ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Diagnosis:
                                      </h5>
                                      <p className="card-text text">
                                        {this.state.appointments.diagnosis}
                                      </p>
                                    </div>
                                  ) : null}
                                  {this.state.appointments
                                    .specialInstruction ? (
                                    <div className="col-sm-12 col-md-6">
                                      <h5 className="card-title title">
                                        Special Instruction:
                                      </h5>
                                      <p className="card-text text">
                                        {
                                          this.state.appointments
                                            .specialInstruction
                                        }
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row mb-2 px-5">
                                <div className="col-12 col-md-6">
                                  <div className=" reports">
                                    <h5 className="card-title title">
                                      Prescription:
                                    </h5>
                                    <Link
                                      // to={{
                                      //   pathname: `/my-appointments/${this.state.appointments.appointmentId}/prescriptions`
                                      //   userData: {}
                                      // }}
                                      to={{
                                        pathname: `/my-appointments/${this.state.appointments.appointmentId}/Eprescription`,
                                        userData: this.props.userData
                                      }}
                                      // to={{
                                      //   pathname: `/my-appointments/${this.state.appointments.appointmentId}/prescriptions`,
                                      //   userData: this.props.userData
                                      // }}


                                      //     to= {
                                      //   "/prescriptions" + appointment.appointmentId
                                      // }
                                      className={
                                        this.state.appointments
                                          .appointment_status === "completed"
                                          ? "btn btn-primary text-white mt-2 mb-3 px-3 btn-rounded"
                                          : "btn btn-primary text-white mt-2 mb-3 px-3 btn-rounded disabled"
                                      }>
                                      View Prescription
                                    </Link>
                                    {/* <div className="row">
                                    <div className="col-md-3 report">
                                      {this.state.prescription ? (
                                        <PDFDownloadLink
                                          className="btn btn-primary text-white px-3 btn-rounded"
                                          document={
                                            <PdfReport
                                              appointment={
                                                this.state.appointments
                                              }
                                              prescription={
                                                this.state.prescription
                                              }
                                              doctorDetails={
                                                this.state.doctorDetails
                                              }
                                              patientDetails={
                                                this.props.userData
                                              }
                                            />
                                          }
                                          fileName={
                                            "prescription-" +
                                            this.state.appointments.name +
                                            ".pdf"
                                          }
                                        >
                                          {({ blob, url, loading, error }) =>
                                            loading
                                              ? "Loading document..."
                                              : "Download!"
                                          }
                                        </PDFDownloadLink>
                                      ) : (
                                        <p>No Prescription Found</p>
                                      )}
                                    </div>
                                  </div> */}
                                  </div>
                                  <div className="mt-3 mx-auto">
                                    <h5 className="card-title title mb-3">
                                      Patient Documents
                                    </h5>

                                    <div className="report">
                                      <Upload
                                        {...this.fileUploadProps}
                                        listType="picture"
                                        fileList={this.state.fileList}
                                        disabled={
                                          this.state.appointments
                                            .appointment_status === "cancelled"
                                            ? true
                                            : false
                                        }>
                                        <AntButton icon={<UploadOutlined />}>
                                          Upload
                                        </AntButton>
                                      </Upload>
                                    </div>
                                  </div>
                                  <div className="mt-3 mx-auto">
                                    <h5 className="card-title title">
                                      Doctor Reports
                                    </h5>
                                    {!this.state.appointments.doctor_docs ||
                                      this.state.appointments.doctor_docs
                                        .length === 0 ? (
                                      <p className="mt-2">
                                        No reports available
                                      </p>
                                    ) : null}
                                    <Upload
                                      listType="picture"
                                      showUploadList={{
                                        showRemoveIcon: false,
                                      }}
                                      defaultFileList={
                                        this.state.appointments.doctor_docs
                                          ? this.state.appointments.doctor_docs.map(
                                            (doc) => ({
                                              uid: doc.id,
                                              name: doc.name,
                                              url: doc.publicUrl,
                                              status: "done",
                                            })
                                          )
                                          : []
                                      }
                                    />
                                  </div>
                                  {!!this.state.appointments
                                    .prescription_path && (
                                      <div className="mt-3 mx-auto">
                                        <h5 className="card-title title">
                                          Doctor Report
                                        </h5>
                                        <div className="row">
                                          <div className="report">
                                            <button
                                              onClick={(e) =>
                                                this.viewFile(
                                                  e,
                                                  this.state.appointments
                                                    .prescription_path
                                                )
                                              }
                                              className="btn btn-primary text-white mt-2 mb-3 px-3 btn-rounded">
                                              View Report
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                  {this.state.appointments.fees && (
                                    <div
                                      className="border p-3"
                                      style={{
                                        borderColor: "#bcbcbc!important",
                                      }}>
                                      <h5 className="text-muted mb-2">
                                        Your Appointment Bill
                                      </h5>
                                      <div className="d-flex justify-content-between w-100">
                                        <span>Base Fee</span>
                                        <span>
                                          {!!this.state.appointments.fees
                                            .base_fee
                                            ? "$" +
                                            this.state.appointments.fees
                                              .base_fee
                                            : "$" + 0}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between w-100">
                                        <span>Consultation Fee</span>
                                        <span>
                                          {!!this.state.appointments.fees
                                            .consultation
                                            ? "$" +
                                            this.state.appointments.fees
                                              .consultation
                                            : "$" + 0}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between w-100">
                                        <span>Medicine Fee</span>
                                        <span>
                                          {!!this.state.appointments.fees
                                            .medicine
                                            ? "$" +
                                            this.state.appointments.fees
                                              .medicine
                                            : "$" + 0}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between w-100">
                                        <span>Misc. Fee</span>
                                        <span>
                                          {!!this.state.appointments.fees.misc
                                            ? "$" +
                                            this.state.appointments.fees.misc
                                            : "$" + 0}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between w-100 mb-1">
                                        <span>Video Call Fee</span>
                                        <span>
                                          {!!this.state.appointments.fees
                                            .video_call
                                            ? "$" +
                                            this.state.appointments.fees
                                              .video_call
                                            : "$" + 0}
                                        </span>
                                      </div>
                                      <hr />
                                      <div className="d-flex justify-content-between w-100 mt-1">
                                        <span>Total Fee</span>
                                        <span>
                                          {!!this.state.appointments.fees.total
                                            ? "$" +
                                            this.state.appointments.fees.total
                                            : "$" + 0}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <hr />
                              <div className="col-md-11 mt-3 mx-auto">
                                {/* <h5 className="card-title title mb-3">
                                    Download Doctor Report
                                  </h5> */}
                                {/* <PDFDownloadLink className="btn btn-primary text-white px-3 btn-rounded" document={<PdfReport appointment={this.state.appointments} prescription={this.state.prescription} />} fileName={"report-"+this.state.appointments.name+".pdf"}>
                                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download!')}
                                          </PDFDownloadLink> */}
                              </div>

                              <div className="main-btns">
                                <div className="card-text appointment-type d-flex align-items-center justify-content-center">
                                  {/* <p>Appointment Type: {appointment.appointmentType === "1" ? "Physical  " : "Video  "} </p> */}
                                  {/* <div className="apt-btn"> */}
                                  {+this.state.appointments.appointmentType ===
                                    2 && (
                                      <button
                                        onClick={(e) =>
                                          this.openVideoCall(
                                            e,
                                            this.state.appointments
                                          )
                                        }
                                        // href={this.state.appointments.join_url}
                                        // disabled={!this.state.isJoinEnabled}
                                        disabled={
                                          this.state.appointments
                                            .appointment_status === "cancelled"
                                            ? true
                                            : false
                                        }
                                        className="btn btn-primary px-3 mr-2 btn-rounded">
                                        Join Now{" "}
                                        <svg
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 16 16"
                                          className="bi bi-camera-video"
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            fillRule="evenodd"
                                            d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175l3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                  <button
                                    disabled={
                                      this.state.appointments
                                        .appointment_status === "booked"
                                        ? false
                                        : true
                                    }
                                    onClick={(e) => {
                                      // this.displayRazorpay(e)
                                      console.log('clicked on payment');
                                      this.showModal(e)
                                    }}
                                    // edit herer
                                    className="btn btn-primary px-3 btn-rounded">
                                    Pay Now{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-credit-card-2-back"
                                      viewBox="0 0 16 16">
                                      <path
                                        fill-rule="evenodd"
                                        d="M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"
                                      />
                                      <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1zM1 9h14v2H1V9z" />
                                    </svg>
                                  </button>
                                  <button
                                    disabled={
                                      this.state.appointments
                                        .appointment_status === "cancelled"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => this.externalChat(e)}
                                    className="btn btn-primary px-3 btn-rounded pay-btn">
                                    Chat With Doctor
                                  </button>
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>No Appointments Available</p>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this?.state?.appointments?.appointmentId ? (
          <ExternalChat
            externally={this.state.Externally}
            externalChat={() => this.externalChat()}
            pp_url={this.state.pp_url}
            appointment={this?.state?.appointments}
            userData={this.props.userData}
          />
        ) : null}

        {/* <div className="main-content account-content">
          <div className="content comment-container">
            <div className="container">
              <div className="row">
                <div className="col-sm-4 col-3"></div>
              </div>
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="card patient-card">
                    <Paper style={{ padding: "40px 20px" }}>
                      {this.state.comments.length !== 0
                        ? this.state.comments.map((chat) => {
                          return (
                            <Grid
                              justify={
                                chat.userId === this.props.userData.uid
                                  ? "flex-end"
                                  : "flex-start"
                              }
                              container
                              wrap="nowrap"
                              spacing={2}
                            >
                              <Grid item>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={this.state.pp_url}
                                />
                              </Grid>
                              <Grid item>
                                <h5 style={{ margin: 0, textAlign: "left" }}>
                                  {chat.userId === this.props.userData.uid
                                    ? "You"
                                    : chat.userName}
                                </h5>
                                <p
                                  className="chat-content"
                                  style={{ textAlign: "left" }}
                                >
                                  {chat.comment}
                                </p>
                                <p
                                  className="chat-date"
                                  style={{ textAlign: "left", color: "gray" }}
                                >
                                  posted{" "}
                                  {moment(
                                    chat.createdAt._seconds * 1000 +
                                    chat.createdAt._nanoseconds / 1000000
                                  ).fromNow()}
                                </p>
                              </Grid>
                            </Grid>
                          );
                        })
                        : null}
                      <div>
                        <button className="chat-buttons">
                          <i
                            class="fa fa-microphone chat-icons"
                            aria-hidden="true"
                          ></i>
                        </button>
                       
                        <button className="chat-buttons">
                          <i
                            type="file"
                            class="fa fa-paperclip chat-icons"
                            aria-hidden="true"
                           
                          ></i>
                        </button>
                        <input
                          className="comment-area"
                          placeholder="Enter Text Here...."
                          onChange={(e) => this.chatHandler(e)}
                          value={this.state.message}
                        />
                        <button
                          onClick={this.chatSubmitHandler}
                          className="send-button"
                        >
                          Send
                        </button>
                      </div>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <Modal
          backdrop="static"
          centered={true}
          show={this.state.fileModalOpen}
          onHide={() => this.handleFileModalClose()}
          animation={false}>
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Upload Attachments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="profile-upload">
                    <div className="upload-input">
                      <input
                        type="file"
                        onChange={(e) => this.handleFileChange(e)}
                        accept={allowedFiles}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {this.state.fileExtError && (
                  <div className="alert alert-danger fade show" role="alert">
                    {this.state.fileExtError}
                  </div>
                )}
                {this.state.fileSizeError && (
                  <div className="alert alert-danger fade show" role="alert">
                    {this.state.fileSizeError}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.handleFileModalClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.uploadFile()}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.show}
          onHide={(e) => this.handleDeleteOpen(e)}
          animation={false}>
          <Modal.Header>
            <Modal.Title>Cancel appointment with doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure, You want to cancel this appointment with
            {this.state.currentAppointment ? (
              <strong>
                {" "}
                {`${this.state.doctorDetails.firstName} ${this.state.doctorDetails.lastName}`}
              </strong>
            ) : null}
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => this.deleteAppointment(this.state.appointments)}>
              Yes
            </Button>
            <Button variant="primary" onClick={(e) => this.handleDeleteOpen(e)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
