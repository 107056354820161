import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Notify from "../../Notify";
import { Select } from "antd";

export default class AddFamilyMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formData: {},
      fullName: "",
      email: "",
      phone: "",
      relation: "",
      bloodGroup: "",
      modalLoading: false,
      familyPatients: [],
      familyPatient: {},
      currentPatient: {},
      currentIndex: "",
      modalOpen: false,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listFamilyMembers(this.props.userData.uid)
      .then((result) => {
        let res = result.data

        if (this._isMounted) {
          let data = res.family_members;
          this.setState(
            {
              loading: false,
              familyPatients: [],
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  destroy: true,
                  order: [],
                });
              }
            }
          );
          // this.setState({
          //   loading: false,
          //   familyPatients: [...data],
          // });


          // if ($(".datatable").length > 0) {
          //   $(".datatable").DataTable({
          //     destroy: true,
          //     order: [],
          //   });
          // }
        }

      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  inputChangeHandler(e) {
    e.preventDefault();
    let form_data = { ...this.state.formData };
    form_data[e.target.name] = e.target.value;
    this.setState({
      formData: form_data,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    let data = {
      uid: this.props.userData.uid,
      member_email: this.state.formData.email,
      relation: this.state.formData.relation,
      bloodGroup: this.state.formData.bloodGroup,
    };

    console.log("DATA>>>>", data);

    let API = new Apilib();
    API.addFamilyMember(JSON.stringify(data))
      .then((result) => {
        let res = result.data

        if (res.code == 200) {
          this.setState({
            formData: {
              fullName: "",
              email: "",
              phone: "",
              relation: "",
              bloodGroup: "",
            },
          });

          let API = new Apilib();
          API.listFamilyMembers(this.props.userData.uid)
            .then((result) => {
              let res = result.data

              if (this._isMounted) {
                let data = res.family_members;
                this.setState(
                  {
                    loading: false,
                    familyPatients: [...data],
                  },
                  () => {
                    if ($(".datatable").length > 0) {
                      $(".datatable").DataTable({
                        order: [],
                        bDestroy: true,
                      });
                    }
                  }
                );
              }
            })
            .catch((error) => {
              if (this._isMounted) {
                this.setState({
                  loading: false,
                });
              }
            });

          Notify({
            alert: true,
            type: "success",
            title: "Family member added successfully",
          });
        } else {
          this.setState({
            formData: {
              fullName: "",
              email: "",
              phone: "",
              relation: "",
              bloodGroup: "",
            },
          });
          Notify({
            alert: true,
            type: "error",
            title: res.message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formData: {
            fullName: "",
            email: "",
            phone: "",
            relation: "",
            bloodGroup: "",
          },
        });
        Notify({
          alert: true,
          type: "error",
          title: err.message,
        });
      });
  }
  handleDeleteOpen(e, value) {
    e.preventDefault();
    console.log("Family Value", value);
    this.setState({
      // currentPatient: this.state.patients[index],
      familyPatient: value,
      // error: null,
      // success: false,
    });
  }

  deleteMemberFamily(value) {
    console.log("Family", value, this.props);
    let data = {
      member_id: this.state.familyPatient.uid,
      uid: this.props.userData.uid,
    };
    console.log("Edited recep:::", data);
    let API = new Apilib();
    this.setState({
      loading: true,
    });
    API.deleteMemberFamily(JSON.stringify(data)).then((res) => {
      // this.setState({
      //   show:false,
      //   familyPatients : [],
      // })
      if (res.ok) {
        Notify({
          title: "Family deleted successfully",
          alert: true,
          type: "success",
        });
        this.setState({
          loading: false,
          familyPatient: [],
          deleteModal: false,
          editedRecep: [],
          show: false,
        });
        this.componentDidMount();
      } else {
        Notify({
          alert: true,
          type: "error",
          title: "Error occured",
        });
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="add-family-members">
          <div class="content">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="page-title">Add Family Members</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card-box">
                  <h4 class="card-title">Basic Information</h4>
                  <form onSubmit={(e) => this.onSubmit(e)}>
                    <div class="form-group row">
                      <label class="col-form-label col-md-2">
                        Email Id<span className="text-danger">*</span>
                      </label>
                      <div class="col-md-10">
                        <input
                          required
                          value={this.state.formData.email}
                          name="email"
                          onChange={(e) => this.inputChangeHandler(e)}
                          type="email"
                          class="form-control"
                          onInvalid={function (e) {
                            e.target.setCustomValidity("");
                            if (!e.target.validity.valid) {
                              e.target.setCustomValidity(
                                "Please enter email in proper format"
                              );
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-form-label col-md-2">
                        Relation
                        <span className="text-danger">*</span>
                      </label>
                      <div class="col-md-10">
                        <select
                          required
                          value={this.state.formData.relation}
                          name="relation"
                          onChange={(e) => this.inputChangeHandler(e)}
                          type="text"
                          class="form-control"
                          onInvalid={function (e) {
                            e.target.setCustomValidity("");
                            if (!e.target.validity.valid) {
                              e.target.setCustomValidity(
                                "Please mention a relation"
                              );
                            }
                          }}
                        >
                          <option>-- Select --</option>
                          <option>Mother</option>
                          <option>Father</option>
                          <option>Daughter</option>
                          <option>Son</option>
                          <option>Sister</option>
                          <option>Brother</option>
                          <option>Aunt</option>
                          <option>Uncle</option>
                          <option>Niece</option>
                          <option>Nephew</option>
                          <option>Cousin ( female )</option>
                          <option>Cousin ( male )</option>
                          <option>Grandmother</option>
                          <option>Grandfather</option>
                          <option>Granddaughter</option>
                          <option>Grandson</option>
                          <option>Brother-in-law</option>
                          <option>Sister-in-law</option>
                          <option>Mother-in-law</option>
                          <option>father-in-law</option>
                          <option>Son-in-law</option>
                          <option>Daughter-in-law</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-form-label col-md-2">Blood Group</label>
                      <div class="col-md-10">
                        <select
                          required
                          value={this.state.formData.bloodGroup}
                          name="bloodGroup"
                          onChange={(e) => this.inputChangeHandler(e)}
                          class="form-control"
                        >
                          <option>-- Select --</option>
                          <option>A+</option>
                          <option>A-</option>
                          <option>B+</option>
                          <option>B-</option>
                          <option>O+</option>
                          <option>O-</option>
                          <option>AB+</option>
                          <option>AB-</option>
                        </select>
                      </div>
                    </div>
                    <div className="member-submit">
                      <button
                        class="btn btn-primary member-submit-btn"
                        type="submit"
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
                <div class="card-box">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        {this.state.familyPatients.length == 0 ? (
                          <h2>No data available</h2>
                        ) : (

                          <table className="table table-border table-striped custom-table datatable m-b-0">
                            <thead>
                              <tr>
                                <th>Full Name</th>
                                <th>Email Id</th>
                                <th>Relation</th>
                                <th>Blood Group</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.familyPatients &&
                                this.state.familyPatients.map(
                                  (familyPatient) => (

                                    <tr>
                                      <td>{familyPatient.name}</td>
                                      <td>{familyPatient.email}</td>
                                      <td>{familyPatient.relation}</td>
                                      <td>
                                        {familyPatient.bloodGroup == ""
                                          ? "N/A"
                                          : familyPatient.bloodGroup}
                                      </td>

                                      <td>
                                        <button
                                          // onClick={(e) => {
                                          //   this.handleOpen(e);
                                          //   this.setState({
                                          //     currentDoctor: doctor,
                                          //   });
                                          // }}
                                          className="btn btn-small btn-secondary mr-2"
                                        >
                                          <span
                                            class="iconify"
                                            data-icon="akar-icons:edit"
                                            data-inline="false"
                                            style={{
                                              marginRight: ".5em",
                                              marginTop: "-.1em",
                                              display: "inline-block",
                                            }}
                                          ></span>
                                          Edit
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            this.setState({
                                              readOnly: false,
                                              show: true,
                                            });
                                            this.handleDeleteOpen(
                                              e,
                                              familyPatient
                                            );
                                          }}
                                          // onClick={(e) => {
                                          //   this.setState({
                                          //     readOnly: false,
                                          //     show :true
                                          //   });
                                          //   this.handleDeleteOpen(e,familyPatient)
                                          // }}
                                          className="btn btn-danger btn-small mr-2"
                                        >
                                          <span
                                            class="iconify"
                                            data-icon="ant-design:delete-twotone"
                                            data-inline="false"
                                            style={{
                                              marginRight: ".5em",
                                              marginTop: "-.1em",
                                              display: "inline-block",
                                            }}
                                          ></span>
                                          Delete
                                        </button>
                                      </td>
                                    </tr>

                                  )
                                )}
                            </tbody>
                          </table>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          backdrop="static"
          show={this.state.show}
          onHide={() =>
            this.setState({
              show: false,
            })
          }
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete Family Member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure, You want to Family Member
            {this.familyPatient ? (
              <>
                <strong>{" " + this.familyPatient.name + " "}</strong>
              </>
            ) : null}
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => this.deleteMemberFamily(this.familyPatient)}
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ show: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}