/* eslint-disable jsx-a11y/anchor-is-valid */
// jshint esversion:6

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { Component } from "react";
import { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
//assets SVG
import bloodPressure from "../../assets/blood-pressure.svg";
import collaboration from "../../assets/collaboration.svg";
import sleep from "../../assets/day.svg";
import doctApp1 from "../../assets/Group 12.svg";
import doctApp2 from "../../assets/Group 16.svg";
import doctApp3 from "../../assets/Group 17.svg";
import patientApp1 from "../../assets/Group 28.svg";
import patientApp2 from "../../assets/Group 32.svg";
import heart from "../../assets/heart.svg";
import world from "../../assets/location.svg";
import owner from "../../assets/owner.svg";
import oxyTank from "../../assets/oxygen-tank.svg";
import plant from "../../assets/plant.svg";
import privacy from "../../assets/privacy.svg";
import smartWatch from "../../assets/smart-watch.svg";
import sugarLevel from "../../assets/sugar-blood-level.svg";
import thinking from "../../assets/thinking.svg";
import brainAI from "./brainAI.svg";
import LocationBar from "./LocationBar";
import SearchBar from "./SearchBar";
import Testimonial from "./Testimonial";
import GoogleMap from "../Agora/utils/GoogleMap";
import { useStateValue } from "../../StateProvider";
import Apilib from "../../api";
import Loader from "../Loader/Loader";

// function Dispatcherfunction(props) {
//   const [{ userPP }, dispatch] = useStateValue();

//   useEffect(() => {
//     dispatch({
//       type: "SET_LOCATION",
//       lat: props.lat,
//       long: props.lng,
//       // country_code: res.country_code,
//     });
//   }, []);
//   return <div></div>;
// }

export default function Homepage(props) {
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [{ userPP }, dispatch] = useStateValue();
  const [geolocation, setGeoLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [bannerSource, setBannerSource] = useState(
    window.innerWidth > 768
      ? "assets/img/main-banner.png"
      : "assets/img/mobile-banner.jpg"
  );

  useEffect(() => {
    getLocation();
  }, []);
  function getLocation() {
    setLoading(true);
    let API = new Apilib();
    API.getLocation()
      .then((res) => res.json())
      .then((jsonData) => {
        console.log("JsonData>>>>>>>>>>", jsonData); //pune
        dispatch({
          type: "SET_LOCATION",
          lat: jsonData.latitude,
          long: jsonData.longitude,
        });
        let locationName = `${jsonData.city}, ${jsonData.region}, ${jsonData.country_name}`;
        console.log("location Name=", locationName);
        setGeoLocation(locationName);
      })
      .catch((err) => {
        console.log("Location error: ", err);
      })
      .finally(() => setLoading(false));
  }
  return (
    <>
      {loading ? <Loader /> : null}

      <div style={{ position: "relative" }}>
        <div className="container">
          <div className="search-bar">
            <>
              <div className="location-bar mr-3">
                {/* <LocationBar /> */}
                {geolocation && (
                  <GoogleMap
                    // style={{ width: 100 }}

                    value={geolocation}
                    onChange={(address) => setGeoLocation(address)}
                    setGeoLocation={(e) => {
                      console.log("GoogleMapsAddress", e);
                      dispatch({
                        type: "SET_LOCATION",
                        lat: e.lat,
                        long: e.lng,
                      });
                    }}
                    getAddress={(address) => {
                      // setGeoLocation(address);
                    }}
                  />
                )}
              </div>
              <div>
                <SearchBar />
              </div>
            </>
          </div>
        </div>

        <section>
          <a target="_blank" rel="noopener noreferrer">
            <img
              style={{ borderTop: "1px solid #d9d9d9" }}
              width="100%"
              alt="Medvatic Mobile App"
              src={bannerSource}
            />
          </a>
          <div className="AppBtns">
            <a
              class="btnG btn-google"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.medvaticdoctor.new"
              title="Google Play"
            >
              Google Play
            </a>
            <a
              class="btnA btn-Store"
              target="_blank"
              href="https://apps.apple.com/us/app/medvatic-doctor/id1567798682"
              title="Google Play"
            >
              App Store
            </a>
          </div>
        </section>

        <section
          className="section features"
          style={{ backgroundColor: "#fbfbfb" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12" data-aos="fade-top">
                <div className="section-header text-center">
                  <h3 className="header-title">OUR CORE VALUES</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
            <div className="row feature-row">
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-6" data-aos="fade-right">
                    <div className="feature-box">
                      <div className="feature-img">
                        <img
                          width="60"
                          height="60"
                          alt="Book an Appointment"
                          src={world}
                        />
                      </div>
                      <h4>One World</h4>
                    </div>
                  </div>
                  <div className="col-6" data-aos="fade-right">
                    <div className="feature-box">
                      <div className="feature-img">
                        <img
                          width="60"
                          height="60"
                          alt="Consult with a Doctor"
                          src={collaboration}
                        />
                      </div>
                      <h4>Collaboration</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-6" data-aos="fade-right">
                    <div className="feature-box">
                      <div className="feature-img">
                        <img
                          width="60"
                          height="60"
                          alt="Make a family Doctor"
                          src={privacy}
                        />
                      </div>
                      <h4>Privacy</h4>
                    </div>
                  </div>
                  <div className="col-6" data-aos="fade-right">
                    <div className="feature-box">
                      <div className="feature-img">
                        <img
                          width="60"
                          height="60"
                          alt="Make a family Doctor"
                          src={thinking}
                        />
                      </div>
                      <h4>Thinking Differently</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-6" data-aos="fade-right">
                    <div className="feature-box">
                      <div className="feature-img">
                        <img
                          width="60"
                          height="60"
                          alt="Make a family Doctor"
                          src={plant}
                        />
                      </div>
                      <h4>Simplicity</h4>
                    </div>
                  </div>
                  <div className="col-6" data-aos="fade-right">
                    <div className="feature-box">
                      <div className="feature-img">
                        <img
                          width="60"
                          height="60"
                          alt="Make a family Doctor"
                          src={owner}
                        />
                      </div>
                      <h4>Ownership</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="gallery-overlay">
          <section className="gallery-area section-padding" id="gallery_page">
            <div className="container-fluid">
              <div className="row">
                <div
                  data-aos="fade-right"
                  className=" d-flex flex-row justify-content-center alighn-items-center col-xs-12 col-sm-6 gallery-slider wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <OwlCarousel
                    autoplay={true}
                    autoplayTimeout={2200}
                    dots={false}
                    style={{ height: "50%", width: "50%" }}
                    items={1}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      // 600: {
                      //   items: 1,
                      // },
                      // 1280: {
                      //   items: 1,
                      // },
                      // 1500: {
                      //   items: 1,
                      // },
                    }}
                    className="owl-theme gallery-slide"
                    loop={true}
                  >
                    <div className="item">
                      <img src={doctApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp2} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp3} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp2} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp3} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp3} alt="" />
                    </div>

                    {/* <div className="item">
                      <img src="assets/img/screens/9.jpg" alt="" />
                    </div> */}
                  </OwlCarousel>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-lg-6 mt-5 wow "
                  data-aos="fade-left"
                >
                  <div
                    className="section-header text-center"
                    data-aos="fade-top"
                  >
                    <h3 className="header-title">Doctor App</h3>
                    <div className="line"></div>
                    {/* <p className="white-color title fadeInUp">
                  Available as a mobile app for both iOS and Android devices,
                  Medvatic allows practitioners to fully document each
                  consultation, prepare treatment plans and ongoing monitoring
                  regimes, and to connect with their patients in a streamlined,
                  non-obtrusive manner.
                </p> */}
                  </div>
                  <div className="page-title">
                    <p
                      // style="  color: #ffffff;"
                      className="white-color title fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      {/* Available as a mobile app for both iOS and Android
                      devices, Medvatic allows practitioners to fully document
                      each consultation, prepare treatment plans and ongoing
                      monitoring regimes, and to connect with their patients in
                      a streamlined, non-obtrusive manner. */}
                      <div>
                        <div className="doct-info">
                          {/* <img src={meeting} height="45px" width="45px" /> */}
                          <span
                            class="iconify iconify-app"
                            data-icon="clarity:video-camera-line"
                            data-inline="false"
                          ></span>
                          <span className="doct-text">
                            Schedule Your Meeting As Per Your preference
                          </span>
                        </div>
                        <div className="doct-info">
                          {/* <img src={time} height="45px" width="45px" /> */}
                          <span
                            class="iconify iconify-app"
                            data-icon="bi:calendar-check"
                            data-inline="false"
                          ></span>
                          <span className="doct-text">
                            Add And Schedule Your Medicines As Per Your
                            Prescription
                          </span>
                        </div>
                        <div className="doct-info">
                          {/* <img src={que} height="45px" width="45px" /> */}
                          <span
                            class="iconify iconify-app"
                            data-icon="akar-icons:question"
                            data-inline="false"
                          ></span>
                          <span className="doct-text">
                            Have A Direct Q&amp;A Session With Your Doctor
                          </span>
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="space-80" />
                </div>
              </div>
            </div>
          </section>
        </div>

        <div style={{ marginTop: "5%" }} className="gallery-overlay">
          <section
            style={{ paddingTop: "30px", paddingBottom: "60px" }}
            className="gallery-area"
            id="gallery_page"
          >
            <div className="container-fluid">
              <div className="row">
                <div
                  data-aos="fade-right"
                  className="col-xs-12 col-sm-6 order-last order-md-first mt-5 gallery-slider wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="page-title">
                    <p
                      // style="  color: #ffffff;"
                      className="white-color title fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div
                        className="section-header text-center"
                        data-aos="fade-top"
                      >
                        <h3 className="header-title">Patient App</h3>
                        <div className="line"></div>
                        {/* <p className="white-color title fadeInUp">
                      Available as a mobile app for both iOS and Android devices,
                      Medvatic allows practitioners to fully document each
                      consultation, prepare treatment plans and ongoing monitoring
                      regimes, and to connect with their patients in a streamlined,
                      non-obtrusive manner.
                    </p> */}
                      </div>
                      {/* Available as a mobile app for both iOS and Android
                      devices, Medvatic allows practitioners to fully document
                      each consultation, prepare treatment plans and ongoing
                      monitoring regimes, and to connect with their patients in
                      a streamlined, non-obtrusive manner. */}
                      <div>
                        <div className="doct-info">
                          {/* <img src={meeting} height="45px" width="45px" /> */}
                          <span
                            class="iconify iconify-app"
                            data-icon="clarity:video-camera-line"
                            data-inline="false"
                          ></span>
                          <span className="doct-text">
                            Schedule Your Meeting As Per Your preference
                          </span>
                        </div>
                        <div className="doct-info">
                          {/* <img src={time} height="45px" width="45px" /> */}
                          <span
                            class="iconify iconify-app"
                            data-icon="bi:calendar-check"
                            data-inline="false"
                          ></span>
                          <span className="doct-text">
                            Add And Schedule Your Medicines As Per Your
                            Prescription
                          </span>
                        </div>
                        <div className="doct-info">
                          {/* <img src={que} height="45px" width="45px" /> */}
                          <span
                            class="iconify iconify-app"
                            data-icon="akar-icons:question"
                            data-inline="false"
                          ></span>
                          <span className="doct-text">
                            Have A Direct Q&amp;A Session With Your Doctor
                          </span>
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="space-80" />
                </div>
                <div
                  style={{ position: "relative" }}
                  className="col-xs-12 col-sm-6 col-lg-6 mt-5 d-flex flex-row justify-content-center alighn-items-center  wow patient-img"
                  data-aos="fade-left"
                >
                  <OwlCarousel
                    autoplay={true}
                    autoplayTimeout={2200}
                    dots={false}
                    style={{ height: "50%", width: "50%" }}
                    items={1}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      // 600: {
                      //   items: 1,
                      // },
                      // 1280: {
                      //   items: 1,
                      // },
                      // 1500: {
                      //   items: 1,
                      // },
                    }}
                    className="owl-theme gallery-slide"
                    loop={true}
                  >
                    <div className="item">
                      <img src={doctApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={patientApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={patientApp2} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp2} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp3} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp1} alt="" />
                    </div>
                    <div className="item">
                      <img src={doctApp3} alt="" />
                    </div>

                    {/* <div className="item">
                      <img src="assets/img/screens/9.jpg" alt="" />
                    </div> */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="section departments">
          <div className="container">
            <div className="row">
              <div className="col-12" data-aos="fade-bottom">
                <div className="section-header text-center">
                  <h3 className="header-title">MEDVATIC MONITORING SERVICES</h3>
                  <div className="line"></div>
                  <p>Monitoring Service that Medvatic Currently Offer</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" data-aos="fade-right">
                <div className="dept-box">
                  <div className="dept-img">
                    <a>
                      <img width="67" height="80" alt="Dentists" src={heart} />
                    </a>
                  </div>
                  <h4>
                    <a>HEART RATE</a>
                  </h4>
                  <p>
                    Heart rate sensor allows your doctor to monitor and measure
                    your heart rate in real-time.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-right">
                <div className="dept-box">
                  <div className="dept-img">
                    <a>
                      <img
                        width="63"
                        height="80"
                        alt="Neurology"
                        src={bloodPressure}
                      />
                    </a>
                  </div>
                  <h4>
                    <a>BLOOD PRESSURE MONITOR</a>
                  </h4>
                  <p>
                    Monitoring your blood pressure using smart devices let your
                    doctor monitor your blood pressure in your daily activity.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-right">
                <div className="dept-box">
                  <div className="dept-img">
                    <a>
                      <img
                        width="92"
                        height="80"
                        alt="Opthalmology"
                        src={sugarLevel}
                      />
                    </a>
                  </div>
                  <h4>
                    <a>BLOOD GLUCOSE LEVELS</a>
                  </h4>
                  <p>
                    Your doctor can measure glucose levels continuously in order
                    to monitor it throughout the day and night.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="dept-box">
                  <div className="dept-img">
                    <a>
                      <img
                        width="40"
                        height="80"
                        alt="Orthopedics"
                        src={oxyTank}
                      />
                    </a>
                  </div>
                  <h4>
                    <a>OXYGEN LEVEL</a>
                  </h4>
                  <p>
                    Monitoring oxygen level closely lets your doctors keep
                    oxygen level within a specific range and plan a personalised
                    asthma action plan for people with asthma.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="dept-box">
                  <div className="dept-img">
                    <a>
                      <img
                        width="76"
                        height="80"
                        alt="Cancer Department"
                        src={sleep}
                      />
                    </a>
                  </div>
                  <h4>
                    <a>SLEEP MONITOR</a>
                  </h4>
                  <p>
                    By tracking your movements and your motion using different
                    smart devices as well as smartwatches or Fitbit, your doctor
                    can monitor your sleep behaviour.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="dept-box">
                  <div className="dept-img">
                    <a>
                      <img
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src={smartWatch}
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">FITBIT</a>
                  </h4>
                  <p>
                    It tracks your day to day activities as well as exercise,
                    food and sleep to help your doctor to keep monitoring your
                    health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section testimonials">
          <div className="container">
            <div className="row">
              <div className="col-12" data-aos="fade-top">
                <div className="section-header text-center">
                  <h3 className="header-title">AI &amp; MACHINE LEARNING</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-right"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="row"
            >
              {/* <div className="col-lg-4"></div> */}
              <div className="">
                <img style={{ height: "100%", width: "100%" }} src={brainAI} />
                <div>
                  {/* <Lottie
                    options={aiLottie}
                    height={400}
                    width={400}
                  /> */}
                </div>
              </div>
              {/* <div className="col-lg-4"></div> */}
            </div>
            <div className="row">
              <div className="col-12" data-aos="fade-left">
                <div
                  className="section-header text-center aitext"
                // style="margin-top: 50px;"
                >
                  <p>
                    Secure by design, AI &amp; machine learning platform Privacy
                    by design clouds platform
                  </p>
                  <p>
                    Remotely and conveniently access your scans in real time
                    with high quality images.
                  </p>
                </div>
              </div>
            </div>
            <Testimonial />
          </div>
        </section>
      </div>
    </>
  );
}
