import React from 'react';
import Progress from '../Loader/Progress';
import prescription1Img from '../../../src/assets/img/prescription1.png';
import newcustomer from '../../../src/assets/img/new-customer.png';
import newsupplier from '../../../src/assets/img/new-supplier.png';
import newmedicine from '../../../src/assets/img/new-medicine.png';
import newinvoice from '../../../src/assets/img/new-invoice.png';
import genicon from '../../../src/assets/img/genicon.png';
import styled from 'styled-components';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LineChart } from 'recharts';
import { useHistory, Redirect, useLocation } from 'react-router-dom'

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius:45px;
  cursor: pointer;
  width:100%;
  margin:10px;
`;
const ButtonOrange = styled.button`
  background-color: #FC700A;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius:45px;
  cursor: pointer;
  width:100%;
  margin:10px;
`;
const ButtonNavigate = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width:100%;
  margin:10px;
  text-align-last:start;
`;

const data = [
    { name: 'Sun', orders: 31, },
    { name: 'Mon', orders: 18, },
    { name: 'Tue', orders: 21, },
    { name: 'Wed', orders: 28, },
    { name: 'Thu', orders: 55, },
    { name: 'Fri', orders: 8, },
    { name: 'Sat', orders: 3, },
];
// name
// expiry
// quantity
// price
const expiredData = [
    { name: 'Uromax- D', expiryDate: '27/8/2023', quantity: 30, price: 90 },
    { name: 'Pantronix 20', expiryDate: '3/8/2023', quantity: 15, price: 100 },
    { name: 'Bextram Gold', expiryDate: '15/8/2023', quantity: 18, price: 150 },
    { name: 'Pronex 20', expiryDate: '12/8/2023', quantity: 8, price: 130 },
    { name: 'Ticamet 250', expiryDate: '13/8/2023', quantity: 3, price: 35 },
    { name: 'Trilock 10', expiryDate: '11/8/2023', quantity: 1, price: 141 },
];
const ordersData = [
    { name: 'Uromax- D', batchNumber: '1213181', quantity: 30, price: 90 },
    { name: 'Bextram Gold', batchNumber: '1213181', quantity: 18, price: 150 },
    { name: 'Trilock 10', batchNumber: '1213181', quantity: 1, price: 141 },
    { name: 'Pronex 20', batchNumber: '1213181', quantity: 20, price: 80 },
    { name: 'Pantronix 20', batchNumber: '1213181', quantity: 8, price: 130 },
    { name: 'Ticamet 250', batchNumber: '1213181', quantity: 3, price: 35 },
];
const drugStock = [
    { name: 'Sun', outOfStock: 31, expired: 28, stocked: 41, },
    { name: 'Mon', outOfStock: 18, expired: 11, stocked: 48, },
    { name: 'Tue', outOfStock: 21, expired: 11, stocked: 31, },
    { name: 'Wed', outOfStock: 28, expired: 38, stocked: 12, },
    { name: 'Thu', outOfStock: 55, expired: 25, stocked: 15, },
    { name: 'Fri', outOfStock: 8, expired: 18, stocked: 13, },
    { name: 'Sat', outOfStock: 3, expired: 13, stocked: 31, },
];
const medicationManagement = [{ name: 'Ram', doctorName: "ram Borawat", prescriptionDate: '28/7/2022', doseSchedule: 'in morning(with food)', numberOfDose: 3, Note: 'take after Meal' },
{ name: 'Shayam', doctorName: "George", prescriptionDate: '2/7/2022', doseSchedule: 'in morning(before food)', numberOfDose: 3, Note: 'take with warm water' }, { name: 'Ram', doctorName: "ram Borawat", prescriptionDate: '28/7/2022', doseSchedule: 'in morning(with food)', numberOfDose: 3, Note: 'take after Meal' },
{ name: 'Shayam', doctorName: "George", prescriptionDate: '2/7/2022', doseSchedule: 'in morning(before food)', numberOfDose: 3, Note: 'take with warm water' }
];

const NewButton = ({ name, onHit, percentage }) => <Button onClick={onHit}>
    <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div>

            <img
                src={prescription1Img}
                alt="new"
                style={{ width: '40px', height: '40px' }}
            />

        </div>
        <div style={{ fontSize: '16px', margin: '5px', color: '#fff', textAlign: 'left', width: '80%' }}>

            {name}
            <div style={{ fontSize: '10px', width: '100%' }}>
                <Progress bgcolor="#00ff47" progress={percentage} />
            </div>
            <div style={{ fontSize: '10px', marginTop: '5px', color: '#fff', textAlign: 'left' }}>
                {percentage}% increase
            </div>
        </div>


    </div>

</Button>

const NewButtonOrange = ({ name, onHit, percentage }) => <ButtonOrange onClick={onHit}>
    <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div>

            <img
                src={prescription1Img}
                alt="new"
                style={{ width: '40px', height: '40px' }}
            />

        </div>
        <div style={{ fontSize: '16px', margin: '5px', color: '#fff', textAlign: 'left', width: '80%' }}>

            {name}
            <div style={{ fontSize: '10px', width: '100%' }}>
                <Progress bgcolor="#00ff47" progress={percentage} />
            </div>
            <div style={{ fontSize: '10px', marginTop: '5px', color: '#fff', textAlign: 'left' }}>
                {percentage}% increase
            </div>
        </div>


    </div>

</ButtonOrange>

const NavigateButton = ({ title, image, clicked }) => <ButtonNavigate onClick={clicked}>
    <img
        src={image}
        alt="new"
        style={{ width: '45px', height: '45px', marginBottom: '15px' }}
    />
    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
        <div style={{ fontSize: '18px', textAlign: 'left', width: '60%' }}>{title}</div>
        <div>
            <i className="fa fa-arrow-right" style={{ fontWeight: '100', color: '#fff' }}></i>
        </div>
    </div>

</ButtonNavigate>

const OrderDetail = ({ title, subTitle, title1, subtitle1 }) => <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', margin: '5px' }}>
    <img
        src={genicon}
        alt="new"
        style={{ width: '45px', height: '45px', margin: '5px' }}
    />
    <div>
        <div>{title}</div>
        <div>{subTitle}</div>
    </div>
    <div>
        <div>{title1}</div>
        <div>{subtitle1}</div>
    </div>
    <i className="fa fa-arrow-right" style={{ color: '#0fbFB8', fontSize: '21px', alignSelf: 'center' }}></i>
</div>

const iterate = [
    [
        { name: 'Total Orders' },
        { name: 'Pending Orders' },
        { name: 'Revenue' },
    ],
    [
        { name: 'Total Patients' },
        { name: 'Expired Drugs' },
        { name: 'Drug out of Stock' }
    ],
    [
        { name: 'Stocked Drugs' },
        { name: 'Total Pharmacies' },
        { name: 'Total Pharmacy Clerk' }
    ],
    [
        { name: 'Total Doctors' },
        { name: 'New Patients Admited' },
        { name: 'Total Patients Admited' }
    ],

]
const Medication = () => {
    const history = useHistory();

    return (
        <div style={{ backgroundColor: '#eafcff' }}>

            <div style={{ width: '98%', backgroundColor: '#fff', border: 'solid #bdeaf1 1px', alignSelf: 'center', margin: '1%', marginTop: '20px' }}>
                <div style={{ color: '#000', fontSize: '21px', padding: '8px' }}>Medication</div>
            </div>

            <div style={{ width: '100%', display: 'flex' }}>

                <div style={{ width: '49%', backgroundColor: '#fff', border: 'solid #bdeaf1 1px', alignContent: 'center', margin: '5px' }}>
                    <div style={{ display: 'flex' }}>

                        <img
                            src={'https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg'}
                            alt="new"
                            style={{ width: '65px', height: '65px', margin: '5px', borderRadius: '50px' }}
                        />
                        <div style={{ alignSelf: 'center', margin: '21px' }}>

                            <div style={{ color: '#525252', fontWeight: '600' }}>Ram Borawat</div>
                            <div style={{ color: '#000', fontWeight: '400' }}>ID:124532</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>

                        <div style={{ alignSelf: 'center', width: '50%' }}>

                            <div style={{ color: '#525252' }}>Medicare Number: <div style={{ color: '#000', fontWeight: '600' }}>124521</div></div>
                            <div style={{ color: '#525252' }}>Blood Group: <div style={{ color: '#000', fontWeight: '600' }}> B+</div></div>
                            <div style={{ color: '#525252' }}>Mobile: <div style={{ color: '#000', fontWeight: '600' }}> 9700046172</div></div>
                        </div>
                        <div style={{ alignSelf: 'center', width: '50%' }}>

                            <div style={{ color: '#525252' }}>Age: <div style={{ color: '#000', fontWeight: '600' }}> 23</div></div>
                            <div style={{ color: '#525252' }}>Sex: <div style={{ color: '#000', fontWeight: '600' }}>M</div></div>
                            <div style={{ color: '#525252' }}>Mail: <div style={{ color: '#000', fontWeight: '600' }}>moola@medvatic.com</div></div>
                        </div>
                    </div>
                </div>

                <div style={{ width: '49%', backgroundColor: '#fff', border: 'solid #bdeaf1 1px', alignContent: 'center', margin: '5px' }}>
                    <div style={{ display: 'flex' }}>

                        <div style={{ alignSelf: 'center', margin: '21px' }}>

                            <div style={{ color: '#525252', fontWeight: '600' }}>Health Records</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>

                        <div style={{ alignSelf: 'center', width: '50%' }}>

                            <div style={{ color: '#525252' }}>Height: <div style={{ color: '#000', fontWeight: '600', fontSize: '18px' }}>187cm</div></div>
                            <div style={{ color: '#525252' }}>Blood Pressure: <div style={{ color: '#000', fontWeight: '600', fontSize: '18px' }}> 130/80</div></div>
                            <div style={{ color: '#525252' }}>Blood Glucose: <div style={{ color: '#000', fontWeight: '600', fontSize: '18px' }}> 6.0mmol/L</div></div>
                        </div>
                        <div style={{ alignSelf: 'center', width: '50%' }}>

                            <div style={{ color: '#525252' }}>Weight: <div style={{ color: '#000', fontWeight: '600', fontSize: '18px' }}>55</div></div>
                            <div style={{ color: '#525252' }}>Pulse: <div style={{ color: '#000', fontWeight: '600', fontSize: '18px' }}>70 BPM</div></div>
                            <div style={{ color: '#525252' }}>RBS: <div style={{ color: '#000', fontWeight: '600', fontSize: '18px' }}>5.7</div></div>
                        </div>
                    </div>
                </div>
            </div>


            {medicationManagement.map(x => <div style={{ borderRadius: '3px', border: 'solid #f6f6f6 .5px', justifyContent: 'space-between', display: 'flex', borderLeft: 'solid #00ccff 5px', borderLeftColor: '#00ccff', margin: '3px', alignItems: 'center', backgroundColor: '#fff', }} key={Math.random()}>
                {/* {name: 'Ram', doctorName: "ram Borawat", prescriptionDate: '28/7/2022', doseSchedule: 'in morning(with food)', numberOfDose: 3, Note: 'take after Meal' } */}
                <img
                    src={genicon}
                    alt="new"
                    style={{ width: '45px', height: '45px', margin: '5px' }}
                />
                <div style={{ width: '16.6%' }}>
                    <div style={{ color: '#000' }}>Medicine</div>
                    <div>{x.name}</div>
                </div>
                <div style={{ width: '16.6%' }}>
                    <div style={{ color: '#000' }}>Doctor</div>
                    <div>{x.doctorName}</div>
                </div>
                <div style={{ width: '16.6%' }}>
                    <div style={{ color: '#000' }}>Prescription</div>
                    <div>{x.prescriptionDate}</div>
                </div>
                <div style={{ width: '16.6%' }}>
                    <div style={{ color: '#000' }}>Dose Schedule</div>
                    <div>{x.doseSchedule}</div>
                </div>
                <div style={{ width: '16.6%' }}>
                    <div style={{ color: '#000' }}>No. Of Dose</div>
                    <div>{x.numberOfDose}</div>
                </div>
                <div style={{ width: '16.6%' }}>
                    <div style={{ color: '#000' }}>Note</div>
                    <div>{x.Note}</div>
                </div>
            </div>)}



        </div>
    );
}

export default Medication;
