import React, { Component } from "react";
import "./style.css";

export default class NewS extends React.Component {
  constructor(props) {
    super(props);
    this.items = [
      "david",
      "deep",
      "joe",
      "chandler",
      "ross",
      "phoebe",
      "rachel",
      "monica",
    ];
    this.state = {
      suggestions: [],
    };
  }

  onTextChanged = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.items.sort().filter((v) => regex.test(v));
    }
    this.setState(() => ({ suggestions }));
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <div>
        <input type="text" onChange={this.onTextChanged} />
        {/* <ul>
          {this.items.map((item) => (
            <li>{item}</li>
          ))}
        </ul> */}
        {this.renderSuggestions()}
      </div>
    );
  }
}
