import React from 'react'

const Progress = ({ bgcolor, progress, height }) => {

    const Parentdiv = {
        width: '100%',
        backgroundColor: '#b5e6ed',
        borderRadius: 20,
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 20,
        textAlign: 'right',
        height: '3px'
    }

    const progresstext = {
        color: 'black',
        height: '3px'
    }

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
                <span style={progresstext}></span>
            </div>
        </div>
    )
}

export default Progress;