import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import loginSVG from "./loginSVG.svg";
import Select2 from "@material-ui/core/Select";
import { useTheme } from "@material-ui/core/styles";
import "./style.css";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PhoneInput from "react-phone-input-2";
import Chip from "@material-ui/core/Chip";
import "react-phone-input-2/lib/style.css";
// import $ from "jquery";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);
const customInput = (props) => {
  return (
    <input
      type="text"
      className="form-control"
      onChange={props.inputProps.onChange}
      placeholder={props.placeholder}
      value={props.value}
      onClick={props.onClick}
    />
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Register = (props) => {
  const [hide, setHide] = useState(true);
  const [isFamilyAcc, setIsFamilyAcc] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [parentAccounts, setParentAccounts] = useState([]);
  const [allPharmacies, setAllPharmacies] = useState([]);
  const [preferredPharmacies, setPreferredPharmacies] = useState([]);
  const [pharmacyId, setPharmacyId] = useState("");
  const [agree, setagree] = useState(false);
  const [formDataPharmacy, setFormDataPharmacy] = useState({});
  const [formData, setFormData] = useState({
    firstName: {
      value: "",
      validation: {
        minLength: 3,
        required: true,
        onlyAlphabets: true,
      },
      valid: true,
    },
    middleName: {
      value: "",
      validation: {
        minLength: 3,
        required: true,
        onlyAlphabets: true,
      },
      valid: true,
    },
    lastName: {
      value: "",
      validation: {
        minLength: 3,
        required: true,
        onlyAlphabets: true,
      },
      valid: true,
    },
    dateOfBirth: {
      value: new Date(),
      validation: {
        required: true,
      },
      valid: true,
    },
    email: {
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: true,
    },
    password: {
      value: "",
      validation: {
        required: true,
        minLength: 6,
        passwordValidation: true,
      },
      valid: true,
    },
    phoneno: {
      value: "",
      validation: {
        required: true,
        isNumeric: true,
        minLength: 8,
        maxLength: 15,
      },
      valid: true,
    },
    country_code: {
      value: "",
      validation: {
        required: true,
        isNumeric: true,
        minLength: 1,
        maxLength: 3,
      },
      valid: true,
    },
    insuranceNumber: {
      value: "",
      validation: {
        isNumeric: true,
      },
      valid: true,
    },
    pharmacy: {
      value: "",
    },
    family_uid: {
      value: "null",
      validation: {
        required: false,
      },
      valid: true,
    },
    relation: {
      value: "",
      validation: {
        minLength: 3,
        required: true,
        onlyAlphabets: true,
      },
      valid: true,
    },
  });
  const [familyReqData, setFamilyReqData] = useState({
    head_email: "",
    uid: "",
    member_email: "",
    member_name: "",
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const dateInputChangeHandler = (date) => {
    setFormIsValid(true);
    setFormData({
      ...formData,
      dateOfBirth: {
        value: date,
        validation: {
          required: true,
        },
        valid: true,
      },
    });
    setError("");
  };

  const inputChangeHandler = (event, inputName) => {
    const updatedForm = { ...formData };
    const updatedFormElement = { ...updatedForm[inputName] };
    if (inputName === "phoneno") {
      updatedFormElement.value = event;
    } else {
      updatedFormElement.value = event.target.value;
    }
    updatedForm[inputName] = updatedFormElement;
    updatedForm[inputName].valid = checkValidity(
      updatedForm[inputName].value,
      updatedForm[inputName].validation
    );
    let formValid = true;
    let { pharmacy, ...newUpdatedForm } = updatedForm;
    for (let key in newUpdatedForm) {
      if (
        isFamilyAcc === true ||
        (isFamilyAcc === false && key !== "family_uid" && key !== "relation")
      ) {
        formValid =
          updatedForm[key].valid && formValid && !!updatedForm[key].value;
        // console.log(inputName, key, formValid);
      }
    }
    setFormIsValid(formValid);
    setFormData(updatedForm);
    setError("");
  };

  const setFamilyAcc = () => {
    const updatedForm = { ...formData };
    // isfamilyacc == true -> false
    if (isFamilyAcc) {
      updatedForm.family_uid.validation.required = false;
      updatedForm.family_uid.value = "null";
      updatedForm.family_uid.valid = true;
      updatedForm.relation.validation.required = false;
      updatedForm.relation.value = "";
      updatedForm.relation.valid = true;
    } else {
      // isfamilyacc == false -> true
      updatedForm.family_uid.validation.required = true;
      updatedForm.family_uid.valid = false;
      if (updatedForm.relation.valid !== true) {
        updatedForm.relation.validation.required = true;
        updatedForm.relation.valid = false;
      }
    }
    let formValid = true;
    for (let key in updatedForm) {
      if (
        isFamilyAcc === false ||
        (isFamilyAcc === true && key !== "family_uid" && key !== "relation")
      ) {
        formValid =
          updatedForm[key].valid && formValid && updatedForm[key].value !== "";
      }
    }
    setFormIsValid(formValid);
    setFormData(updatedForm);
    setIsFamilyAcc(!isFamilyAcc);
  };

  function checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isEmail) {
      const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.onlyAlphabets) {
      const pattern = /^[A-Za-z ]+$/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.passwordValidation) {
      const pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$|+\,)(_/%:~\^&{><}\*])[A-Za-z\d?=.*[!@#\$|%^{~_}|><\:/,)(+&\*]{6,}$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  const [reCaptchaVerified, setReCaptchaVerified] = useState(false);
  let preferredPharmacy = "";
  let preferredPharmaciesArr = [];

  const onChange = async (e) => {
    let inputName = e.target.name;
    console.log("target", e.target.value);
    let value = "";
    let data = { ...formData };
    let preferredPharmacyData = preferredPharmacies;
    if (inputName === "allPharmacy") {
      console.log("All pharmacies->", allPharmacies);
      for (let key of allPharmacies) {
        if (parseInt(key.pharmacyId) === e.target.value) {
          value = key;
          preferredPharmacyData.push(key);
          setPharmacyId(e.target.value);
          setPreferredPharmacies(preferredPharmacyData);
        }
      }
    }
    data["preferredPharmacy"] = value;
    data["preferredPharmacies"] = preferredPharmacyData;
    console.log("data", data);
    setFormData(data);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let API = new Apilib();
    setError("");
    console.log("Is form Valid?:", formIsValid);

    if (true) {
      const data = {};
      for (let key in formData) {
        data[key] = formData[key].value;
      }
      data["type"] = 5;
      data["preferredPharmacy"] = formData["preferredPharmacy"];
      data["preferredPharmacies"] = formData["preferredPharmacies"];
      let countryCode = formData["country_code"].value;
      let country_code = countryCode;
      data["country_code"] = country_code;

      console.log("code on submit->", country_code);
      console.log("data onsubmit->", data);

      const verifyUser = {};
      verifyUser["email"] = data.email;
      verifyUser["phone"] = "+" + data.country_code + data.phoneno;
      if (reCaptchaVerified) {
        setLoading(true);
        API.isUserExist(JSON.stringify(verifyUser))
          .then((result) => {
            console.log("isUserExist", verifyUser);
            let res = result.data;

            console.log(res);
            if (res.code === 403) {
              var phoneNumber = "+" + data.country_code + data.phoneno;
              var appVerifier = window.recaptchaVerifier;
              console.log("data", data);
              firebase
                .auth()
                .signInWithPhoneNumber(phoneNumber, appVerifier)
                .then(function (confirmationResult) {
                  console.log("confirmationResult", confirmationResult);
                  props.history.push({
                    pathname: "/otp",
                    verificationId: confirmationResult.verificationId,
                    state: { data },
                    familyAcc: isFamilyAcc,
                  });
                  window.confirmationResult = confirmationResult;
                  setLoading(false);
                })
                .catch(function (error) {
                  setLoading(false);
                  console.log("error", error);
                  setError(error.message);
                  Notify({
                    alert: true,
                    type: "error",
                    title: error.message,
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              throw 0;
            }
          })
          .catch((err) => {
            setLoading(false);
            Notify({
              alert: true,
              type: "error",
              title: "User Already Exist",
            });
            setFormIsValid(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Notify({
          alert: true,
          type: "error",
          title: "Please verify that you are not robot!",
        });
      }
    } else {
      Notify({
        alert: true,
        type: "error",
        title: "Invalid Form!",
      });
      setLoading(false);
      return;
    }
  };

  // const pageLoaded = () => {
  //   // setUpRecaptcha();
  // };

  function onParentSelect(user) {
    let updatedForm = { ...formData };
    updatedForm.family_uid.value = user;
    updatedForm.family_uid.valid = true;
    let formValid = true;
    for (let key in updatedForm) {
      formValid =
        updatedForm[key].valid && formValid && updatedForm[key].value !== "";
    }
    console.log("Inside onParentSelect", formValid);
    setFormIsValid(formValid);
    setFormData(updatedForm);
  }

  useEffect(() => {
    //Fetch all patients and save parent account in object
    setLoading(true);
    firebase
      .firestore()
      .collection("patient")
      // .where("isFamilyAdmin","==",true)
      .get()
      .then((snapshot) => {
        // console.log(snapshot.docs);
        const postData = [];
        snapshot.forEach((doc) =>
          postData.push({
            ...doc.data(),
            id: doc.id,
            value: doc.data().phoneno,
            label: doc.data().phoneno,
          })
        );
        console.log(postData);
        setParentAccounts(postData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    //fetch all pharmacies
    let api = new Apilib();
    api
      .getPharmacies()
      .then((result) => {
        let res = result.data;

        console.log("GetResults", res);
        if (res) {
          setAllPharmacies(res.data);
        }
      })
      .catch((err) => {
        console.log("GetResults err", err);
      })
      .finally(() => {
        setLoading(false);
      });

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          if (response) {
            setReCaptchaVerified(true);
            console.log("response", response);
          } else {
            setError("Please verify that you are not robot!");
            setReCaptchaVerified(false);
          }
        },
        "expired-callback": function () {
          setError("Please verify that you are not robot!");
          setReCaptchaVerified(false);
        },
      }
    );
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let pharmacyMenu = null;
  if (allPharmacies?.length > 0) {
    pharmacyMenu = allPharmacies.map((item, index) => {
      return (
        <MenuItem key={index} value={parseInt(item.pharmacyId)}>
          {item.name}
          {item.location ? ` - ${item.location}` : ""}
        </MenuItem>
      );
    });
  }

  // console.log(window.recaptchaVerifier);

  return (
    <>
      <div className="main-content account-content" style={{ paddingTop: 0 }}>
        <div className="content pb-60" style={{ paddingTop: 0 }}>
          <div className="container" style={{ width: "95%" }}>
            <div className="sub-header">
              <div>
                <Link to="/login" id="reg-login">
                  Login
                </Link>
              </div>
              <div className="sub-link">
                <Link to="#" id="registerlink">
                  Register
                </Link>
              </div>
            </div>
            <div className="row">
              {loading && <Loader />}
              <div className="d-none d-md-block col-md-6">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={loginSVG} height="100%" width="100%" />
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-12 col-md-5 login-info login-outer">
                <form
                  onSubmit={(e) => submitHandler(e)}
                  className="form-signin"
                  action="#"
                >
                  <div className="account-logo">
                    <a>
                      <img src="assets/img/logo-dark.png" alt="Medvatic" />
                    </a>
                  </div>
                  <div className="account-title">
                    <h3>Register</h3>
                  </div>
                  {error && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close</span>
                      </button>
                      {error}
                    </div>
                  )}
                  {/* <div className="row"> */}

                  {/* <div classname="col-6"> */}
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <span className="required">*</span>
                    <input
                      type="text"
                      id="firstName"
                      className={
                        formData.firstName.valid
                          ? "form-control"
                          : "form-control error"
                      }
                      required
                      value={formData.firstName.value}
                      name="firstName"
                      onChange={(e) => inputChangeHandler(e, "firstName")}
                    />
                    <small className="form-text text-danger">
                      {(!formData.firstName.valid &&
                        !!formData.firstName.value === false &&
                        "First Name is required") ||
                        (!formData.firstName.valid && "Invalid First Name")}
                    </small>
                  </div>
                  {/* </div> */}
                  <div className="form-group">
                    <label htmlFor="middleName">Middle Name</label>
                    <span className="required">*</span>
                    <input
                      type="text"
                      id="middleName"
                      className={
                        formData.middleName.valid
                          ? "form-control"
                          : "form-control error"
                      }
                      required
                      value={formData.middleName.value}
                      name="middleName"
                      onChange={(e) => inputChangeHandler(e, "middleName")}
                    />
                    <small className="form-text text-danger">
                      {(!formData.middleName.valid &&
                        !!formData.middleName.value === false &&
                        "Middle Name is required") ||
                        (!formData.middleName.valid && "Invalid Middle Name")}
                    </small>
                  </div>

                  {/* <div classname="col-6"> */}
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <span className="required">*</span>
                    <input
                      type="text"
                      id="lastName"
                      className={
                        formData.lastName.valid
                          ? "form-control"
                          : "form-control error"
                      }
                      required
                      value={formData.lastName.value}
                      name="lastName"
                      onChange={(e) => inputChangeHandler(e, "lastName")}
                    />
                    <small className="form-text text-danger">
                      {(!formData.lastName.valid &&
                        !!formData.lastName.value === false &&
                        "Last Name is required") ||
                        (!formData.lastName.valid && "Invalid Last Name")}
                    </small>
                  </div>
                  {/* </div> */}

                  {/* </div> */}
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <span className="required">*</span>
                    <input
                      type="email"
                      id="email"
                      className={
                        formData.email.valid
                          ? "form-control"
                          : "form-control error"
                      }
                      required
                      value={formData.email.value}
                      name="email"
                      onChange={(e) => inputChangeHandler(e, "email")}
                    />
                    <small className="form-text text-danger">
                      {(!formData.email.valid &&
                        !!formData.email.value === false &&
                        "Email is required") ||
                        (!formData.email.valid &&
                          "Please provide email in correct format (eg. john@domain.com)")}
                    </small>
                  </div>
                  <div className="form-group pass-input">
                    <label>Password</label>
                    <span className="required">*</span>
                    <input
                      type={hide ? "password" : "text"}
                      id="password"
                      className={
                        formData.password.valid
                          ? "form-control pass-input"
                          : "form-control error pass-input"
                      }
                      required
                      value={formData.password.value}
                      name="password"
                      onChange={(e) => inputChangeHandler(e, "password")}
                    />
                    <div>
                      <i
                        onClick={() => setHide(false)}
                        class={
                          !hide
                            ? "hide-eye-icon eye-icon"
                            : "fa fa-eye eye-icon"
                        }
                        aria-hidden="true"
                      ></i>
                      <i
                        onClick={() => setHide(true)}
                        class={
                          hide
                            ? "hide-eye-icon eye-icon"
                            : "fa fa-eye-slash eye-icon"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <small className="form-text text-danger">
                    {(!formData.password.valid &&
                      !!formData.password.value === false &&
                      "Password is required") ||
                      (!formData.password.valid &&
                        "Your password must be at least 6 characters long and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number")}
                  </small>
                  <div>
                    <label>Phone Number</label>
                    <div>
                      <span className="phone countryCode" style={{ width: 80 }}>
                        <PhoneInput
                          value={formData.phoneno.value}
                          onChange={(e) => inputChangeHandler(e, "phoneno")}
                          country="au"
                          prefix="+"
                          placeholder="Enter phone number"
                          copyNumbersOnly={true}
                        />
                        <small className="form-text text-danger">
                          {(!formData.phoneno.valid &&
                            !!formData.phoneno.value === false &&
                            "Mobile Number is required") ||
                            (!formData.phoneno.valid &&
                              "Invalid Mobile Number")}
                        </small>
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="dob">Date of Birth</label>
                    <span className="required">*</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        readOnly={false}
                        className={
                          formData.dateOfBirth.valid
                            ? "form-control"
                            : "form-control error"
                        }
                        inputVariant="outlined"
                        value={formData.dateOfBirth.value}
                        onChange={dateInputChangeHandler}
                        inputProps={{ onChange: dateInputChangeHandler }}
                        animateYearScrolling
                        disableFuture
                        TextFieldComponent={customInput}
                        format="dd/MM/yyyy"
                      />
                    </MuiPickersUtilsProvider>
                    <small className="form-text text-danger">
                      {(!formData.dateOfBirth.valid &&
                        !!formData.dateOfBirth.value === false &&
                        "Date of Birth is required") ||
                        (!formData.dateOfBirth.valid &&
                          "Invalid Date of Birth")}
                    </small>
                  </div>
                  <div className="form-group">

                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="checkbox"
                          onChange={(e) => setagree(agree => !agree)
                          }
                          name="agree"
                          checked={agree}
                          value="agree"
                          className="form-check-input"
                        />
                        I agree to the terms and conditions
                      </label>
                      <span className="required">*</span>
                    </div>

                  </div>
                  {/* Insurance number */}
                  {/* <div className="form-group">
                    <label htmlFor="insuranceNumber">Insurance Number</label>
                    <input
                      type="number"
                      id="insuranceNumber"
                      className={
                        formData.insuranceNumber.valid
                          ? "form-control"
                          : "form-control error"
                      }
                      value={formData.insuranceNumber.value}
                      name="insuranceNumber"
                      onChange={(e) => inputChangeHandler(e, "insuranceNumber")}
                    />
                    <small className="form-text text-danger">
                      {!formData.insuranceNumber.valid &&
                        "Invalid Insurance Number"}
                    </small>
                  </div> */}

                  {/* <div className="form-group">
                    <label>Choose Pharmacy</label>
                    <Select2
                      className="border rounded"
                      // MenuProps={{
                      //   anchorOrigin: {
                      //     vertical: "bottom",
                      //     horizontal: "left",
                      //   },
                      //   getContentAnchorEl: null,
                      // }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      fullWidth
                      name="allPharmacy"
                      onChange={(e) => onChange(e)}
                      value={pharmacyId || ""}
                    >
                      {allPharmacies.length === 0 && (
                        <MenuItem disabled={true} value="">
                          Please Select Pharmacy
                        </MenuItem>
                      )}
                      {pharmacyMenu}
                    </Select2>
                  </div>
                  <div className="form-group">
                    <label>Do You have a Family Account?</label>
                    <div className="main-container-check">
                      <label htmlFor="familyYes" className="cust-container">
                        Yes
                        <input
                          onChange={setFamilyAcc}
                          checked={isFamilyAcc}
                          type="radio"
                          id="familyYes"
                          name="familyacc"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        htmlFor="familyNo"
                        className="cust-container second-check"
                      >
                        No
                        <input
                          onChange={setFamilyAcc}
                          checked={!isFamilyAcc}
                          type="radio"
                          id="familyNo"
                          name="familyacc"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  {isFamilyAcc ? (
                    <>
                      <div className="form-group">
                        <Select
                          required
                          value={formData.family_uid.value}
                          placeholder={"Search by mobile number"}
                          onChange={(item) => onParentSelect(item)}
                          options={parentAccounts}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="relation">Relation</label>
                        <span className="required">*</span>
                        <select
                          id="relation"
                          className={
                            formData.relation.valid
                              ? "form-control"
                              : "form-control error"
                          }
                          required
                          value={formData.relation.value}
                          name="relation"
                          onChange={(e) => inputChangeHandler(e, "relation")}
                        >
                          <option>-- Select --</option>
                          <option>Mother</option>
                          <option>Father</option>
                          <option>Daughter</option>
                          <option>Son</option>
                          <option>Sister</option>
                          <option>Brother</option>
                          <option>Aunt</option>
                          <option>Uncle</option>
                          <option>Niece</option>
                          <option>Nephew</option>
                          <option>Cousin ( female )</option>
                          <option>Cousin ( male )</option>
                          <option>Grandmother</option>
                          <option>Grandfather</option>
                          <option>Granddaughter</option>
                          <option>Grandson</option>
                          <option>Brother-in-law</option>
                          <option>Sister-in-law</option>
                          <option>Mother-in-law</option>
                          <option>father-in-law</option>
                          <option>Son-in-law</option>
                          <option>Daughter-in-law</option>
                        </select>
                        <small className="form-text text-danger">
                          {!formData.relation.valid && "Invalid Entry"}
                        </small>
                      </div>{" "}
                    </>
                  ) : null} */}
                  <div
                    id="recaptcha-container"
                    className="my-3"
                    style={{ display: "flex", justifyContent: "center" }}
                  ></div>
                  <div className="form-group text-center mt-50">
                    <button
                      style={{
                        width: "100%",
                        padding: "2%",
                        backgroundColor: "#50D8FA",
                      }}
                      type="submit"
                      disabled={!agree}
                      className="btn btn-primary submit-btn signup account-btn"
                    >
                      SIGNUP
                    </button>
                  </div>
                </form>
                <div className="text-center register-link">
                  Already Registered?
                  {/* <Link to={Register}> Register Now </Link> */}
                  <Link to="/login" className="login-signup-redirect-link">
                    {" "}
                    Login Here{" "}
                  </Link>
                </div>
                {/* <div className="text-center register-link">
                Don&#x2019;t have an account?
                <a>Register Now</a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
