// jshint esversion:6

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import Api from "../../api";
import Notify from "../../Notify";
import { SplashLoader } from "../Loader/Loader";
import "./style.css";
import styled from 'styled-components';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
var isMobile = true
import logo from '../../../src/assets/img/logo1.png';

var QRCode = require("qrcode.react");

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    table: {
        minWidth: 700,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(no, name, frequency, time, duration, quantity, comments) {
    return { no, name, frequency, time, duration, quantity, comments };
}

export default function EPrescription({ userData }) {
    console.log('this is prescription form')


    const themeColor = '#C6C6C6';

    const Container = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  margin: 6%;
  padding: 20px;
  border: 1px solid ${themeColor};
  background-color: #EAFCFF;
  border-radius:18px;
`;

    const Section = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  background-color: ${themeColor};
`;

    const TopSection = styled(Section)`
  height: 50px;
`;

    const BottomSection = styled(Section)`
  height: 50px;
`;

    const MainBody = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  position: relative;
  border: 1px solid ${themeColor};
  background-color: #fff;
  margin:-20px;
  padding:20px;
`;



    // const Container = styled.div`
    // font-size:${width ? '11px' : '18px'};
    //   margin: 20px;
    //   padding: 20px;
    //   border: 1px solid black;
    // `;

    const Header = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  display: flex;
  justify-content: space-between;
  padding:15px;
`;

    const DoctorInfo = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  display: flex;
  flex-direction: column;
`;

    const DoctorName = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  font-weight: 600;
  color:#00a1ba;
  margin:10px;
`;


    const Address = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  white-space: pre-line;
  margin:10px;
`;

    const PrescriberInfo = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  display: flex;
  flex-direction: column;
  text-align: right;
`;

    const PrescriberNo = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  white-space: pre-line;
  margin:10px;
`;

    const PhoneNumber = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  text-align: right;
  
;`;

    // const MainBody = styled.div`
    // font-size:${width ? '11px' : '18px'};
    //   margin-top: 20px;
    // `;

    const PatientInfo = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

    const Label = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  flex: 0 0 50%;
`;
    const Label1 = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  flex: 0 0 28%;
`;

    const Input = styled.input`
  width: 75%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: flex-end;
`;

    const CheckboxRow = styled.div`
    font-size:${!isMobile ? '8px' : '14px'};
  display: flex;
  margin-bottom: 10px;
`;

    const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size:${!isMobile ? '8px' : '14px'};
`;

    const Checkbox = styled.input`
  margin-right: 5px;
`;

    const SmallInput = styled.input`
  width: 80px;
`;

    const PrescriptionDesigned = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  display: flex;
  margin-top: 20px;
`;

    const VerticalLogo = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  flex: 0 0 100px;
`;

    const PrescriptionDetails = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  flex: 1;
`;

    const DrugInfo = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  margin-bottom: 10px;
`;

    const DrugName = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  font-weight: bold;
`;

    const DrugTime = styled.div`
    font-size:${!isMobile ? '8px' : '14px'};`;

    const DrugPurpose = styled.div`
    font-size:${!isMobile ? '8px' : '15px'};`;

    const TotalDrugs = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  margin-top: 20px;
`;

    const ScriptNo = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  text-align: right;
  font-weight: bold;
`;

    const DrugsPrescribed = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  text-align: right;
`;

    const ItemsPrinted = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};
  text-align: right;
`;

    const DoctorNameAndDesignation = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  text-align: right;
  font-weight: bold;
`;

    const TurnOverNote = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  margin-top: 20px;
  text-align: right;
`;

    const Declaration = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  margin-top: 10px;
`;

    const DeclarationText = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};`;

    const Footer = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

    const PatientSignature = styled.div`
    font-size:${!isMobile ? '8px' : '18px'};
  flex: 1;
`;

    const DateOfSupply = styled.div`
    font-size:${!isMobile ? '8px' : '16px'};`;

    const AddressAndDoctorSignature = styled.div`
  font-size:${!isMobile ? '8px' : '16px'};
  text-align: right;
  white-space: pre-line;
`;




    // const classes = useStyles();
    const { id: appointmentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [appointment, setAppointment] = useState(null);
    const [Authority_script_number, setAuthority_script_number] = useState('1178451');

    useEffect(() => {
        let theNum = GenerateAuthority_script_number(1178451);
        setAuthority_script_number('' + Authority_script_number + theNum)
    }, []);
    function GenerateAuthority_script_number(n) {
        return (n - 1) % 9 + 1;
    }
    const handlePrint = async () => {

        const pdf = new jsPDF();
        const handleDownloadPDF = () => {
            const input = document.getElementById('report');

            html2canvas(input)
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    pdf.addImage(imgData, 'PNG', 0, 0, 207, 297, { format: 'PNG', ImageCompression: 'SLOW' });
                    // pdf.save('exported1.pdf');
                });
        };
        handleDownloadPDF()



        async function signPDF(pdfFilePath, signatureDataUrl, outputFilePath) {
            try {

                // const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
                const existingPdfBytes = await pdf.output('arraybuffer')
                // pdf.path()
                console.log(pdfFilePath, 'this is path233')


                // const pdfDoc = await PDFDocument.load(arrayBuffer);
                // const existingPdfBytes = await fs.promises.readFile(pdfFilePath).then(res => res.arrayBuffer());
                const pdfDoc = await PDFDocument.load(existingPdfBytes);

                // Read the signature image from the data URL
                const signatureImage = await pdfDoc.embedPng(signatureDataUrl);
                // Get the first page of the PDF document
                const firstPage = pdfDoc.getPages()[0];

                // Get the size of the first page
                const { width, height } = firstPage.getSize();

                // Set the position and size of the signature on the page
                const signatureWidth = 60; // Set the width of the signature
                const signatureHeight = 15;
                const signatureX = width - (width * .21); // Set the X position of the signature
                const signatureY = height - height + (height * .011); // Set the Y position of the signature

                // Draw the signature on the first page
                firstPage.drawImage(signatureImage, {
                    x: signatureX,
                    y: signatureY,
                    width: signatureWidth,
                    height: signatureHeight,
                });

                // Save the modified PDF to a new file
                const modifiedPdfBytes = await pdfDoc.save();

                download(modifiedPdfBytes, appointmentId, "application/pdf");
                console.log('PDF has been signed and saved successfully!');
                console.log(modifiedPdfBytes)
            } catch (error) {
                console.error('Error signing PDF:', error);
                throw error;
            }
        }

        const pdfFilePath = './exported.pdf'; // Replace with the path to your PDF




        let body = { userId: appointment.data.appointment_details.doctorId, userType: '3' }

        let API = new Api();
        // API.getDigitalSignature(body)
        //   .then(async (res) => {
        //     // setLoading(false);

        //     console.log('fine')
        //     console.log(res)
        //     console.log('fine')
        //     if (res.status == 200) {
        //       // setLoading(false);
        //       // setChange_pp(false);
        //       // await makeDownloadUrl(path);
        //     //   Notify({
        //     //     alert: true,
        //     //     type: "success",
        //     //     title: "Prescriber data saved successfully!",
        //     //   });
        //       // history.push(`/doctor/patients`);

        //       console.log('in else part if no error error')
        //         return res.msg;
        //     } else {
        //       console.log('in else part if error')
        //       res.text().then((data) => {
        //         // setError(JSON.parse(data).message);
        //         // setChange_pp(false);
        //         Notify({
        //           alert: true,
        //           type: "error",
        //           title: 'Error in signing the document',
        //         });
        //       });
        //       }
        //       return null
        //   })
        //   .catch((error) => {
        //     console.log('in else part if error catch', error)
        //     // setError(error.message);
        //     // setChange_pp(false);
        //     // setLoading(false);.
        //       Notify({
        //           alert: true,
        //           type: "error",
        //           title: 'Error in signing the document',
        //       });
        //       return null
        //   });




        // const signatureDataUrl = await fetch('http://127.0.0.1:2000/products').then(x => {
        //     return x.json()
        // }).then(x => {
        //     console.log('this is res')
        //     console.log(x)
        //     return x.msg
        // }); // Replace with the signature data URL
        const signatureDataUrl = await API.getDigitalSignature(body)
            .then(async (res) => {
                // setLoading(false);

                console.log('fine')
                console.log(res)
                console.log('fine')
                if (res.status == 200) {
                    // setLoading(false);
                    // setChange_pp(false);
                    // await makeDownloadUrl(path);
                    //   Notify({
                    //     alert: true,
                    //     type: "success",
                    //     title: "Prescriber data saved successfully!",
                    //   });
                    // history.push(`/doctor/patients`);

                    console.log('in else part if no error error')
                    console.log('this is return from siuccess')

                    return res.data.msg;
                } else {
                    console.log('in else part if error')
                    res.text().then((data) => {
                        // setError(JSON.parse(data).message);
                        // setChange_pp(false);
                        Notify({
                            alert: true,
                            type: "error",
                            title: 'Error in signing the document',
                        });
                    });
                    return null
                }
                console.log('this is return from else siuccess')
            })
            .catch((error) => {
                console.log('in else part if error catch', error)
                // setError(error.message);
                // setChange_pp(false);
                // setLoading(false);.
                Notify({
                    alert: true,
                    type: "error",
                    title: 'Error in signing the document',
                });
                console.log('this is return from catch')

                return null
            });
        const outputFilePath = './thisissigned.pdf'; // Replace with the path to the signed PDF
        console.log('signatureDataUrlthis is what kdnanm')
        // console.log(signatureDataUrl)

        await signPDF(pdfFilePath, signatureDataUrl, outputFilePath)
            .catch((error) => {
                console.error('Error:', error);
            });



    }
    // const handlePrint = useReactToPrint({
    //   content: () => componentRef.current,
    // });



    document.addEventListener(
        "wheel",
        function touchHandler(e) {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        }, { passive: false });


    window.addEventListener('keydown', function (e) {
        if ((e.ctrlKey || e.metaKey) && (e.which === 61 || e.which === 107 || e.which === 173 || e.which === 109 || e.which === 187 || e.which === 189)) {
            e.preventDefault();
        }
    }, false);
    let appointmentDet = {
        "doctor_email": "aimoolaram@gmail.com",
        "patientId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
        "created_at": 1676155568000,
        "appointment_payment": {
            "label": "Cash",
            "value": "1"
        },
        "slot_duration": "30:00",
        "doctorName": "Moola Ram",
        "doctorId": "7q6GWjtjZ6bjiqjdgmaEN2boYRt1",
        "hospitalId": "71ZNVAMJgBHhgAFb1mrP",
        "from": 1676266200000,
        "slot_time": "11:00 AM",
        "email": "ridmal2009@gmail.com",
        "hospital_address": "",
        "hospital_name": "Clinic",
        "doctor_specialities": [
            "General Physician",
            "Endocrologist"
        ],
        "appointmentType": "2",
        "slot_date": "13/02/2023",
        "doctor_experience": "3",
        "for_family_member": false,
        "userId": "vY0bv8fc7Gd1JxyOASXnTrfuGJy2",
        "phoneno": "919672699969",
        "base_fee": "300",
        "country_code": "",
        "appointmentpayment": "1",
        "patient_email": "ridmal2009@gmail.com",
        "name": "moola borawat",
        "to": 1676268000000,
        "userType": "5",
        "join_url": "http://53digital.com/demos/medvatic/#/call?meetingid=ys18pw8i2D&appointmentid=9u1MxB1OwBxHsh2U3PGE",
        "appointmentId": "9u1MxB1OwBxHsh2U3PGE",
        "meetingid": "ys18pw8i2D",
        "token": "0067b9fa21ad2ed4216a5e0edad08e119fdIADYq02B9tXxhg5DKnIzOQNNoIMODtdnwjd2OMEYbJEF/uVBm1wAAAAAIgBVzBLbMGzpYwQAAQDgNepjAgDgNepjAwDgNepjBADgNepj",
        "appointment_status": "completed",
        "patient_docs": [
            {
                "uploadedOn": "2023-02-28T11:59:45.263Z",
                "name": "AwesomeFileName.pdf",
                "id": "DSLoNQe6pA",
                "path": "/undefined/undefined/appointments/9u1MxB1OwBxHsh2U3PGE/reports/4b0b60d0-7e7d-4c6c-af96-d242ce8af6b1-AwesomeFileName.pdf",
                "publicUrl": "https://firebasestorage.googleapis.com/v0/b/medvatic-438af.appspot.com/o/undefined%2Fundefined%2Fappointments%2F9u1MxB1OwBxHsh2U3PGE%2Freports%2F4b0b60d0-7e7d-4c6c-af96-d242ce8af6b1-AwesomeFileName.pdf?alt=media&token=7c0a9b2a-0004-4e30-ad37-be985993d94c"
            },
            {
                "publicUrl": "https://firebasestorage.googleapis.com/v0/b/medvatic-438af.appspot.com/o/undefined%2Fundefined%2Fappointments%2F9u1MxB1OwBxHsh2U3PGE%2Freports%2Fa87c6e98-8cff-48a1-b555-96d1f5478308-AwesomeFileName.pdf?alt=media&token=de158b4c-8470-49d5-ad5c-6c8bc1f79aa1",
                "id": "yLMaRYFQEG",
                "uploadedOn": "2023-02-28T12:00:32.747Z",
                "name": "AwesomeFileName.pdf",
                "path": "/undefined/undefined/appointments/9u1MxB1OwBxHsh2U3PGE/reports/a87c6e98-8cff-48a1-b555-96d1f5478308-AwesomeFileName.pdf"
            }
        ]
    }
    let medicines = [
        {
            "medicines": [
                {
                    "num_days": "1",
                    "intake_time": {
                        "MN": true,
                        "EV": true,
                        "AF": false,
                        "NT": true
                    },
                    "subtitle": "warm",
                    "qty": 3,
                    "name": "Paracetamol",
                    "am_bm_stat": {
                        "MN": "After",
                        "EV": "After",
                        "AF": "After",
                        "NT": "After"
                    }
                },
                {
                    "num_days": "1",
                    "intake_time": {
                        "MN": true,
                        "EV": false,
                        "AF": false,
                        "NT": true
                    },
                    "qty": 2,
                    "subtitle": "cold water",
                    "name": "Dolo",
                    "am_bm_stat": {
                        "EV": "Before",
                        "MN": "Before",
                        "AF": "Before",
                        "NT": "Before"
                    }
                },
                {
                    "num_days": "10",
                    "intake_time": {
                        "MN": true,
                        "EV": false,
                        "AF": true,
                        "NT": false
                    },
                    "subtitle": "nothing",
                    "qty": 20,
                    "name": "Er",
                    "am_bm_stat": {
                        "EV": "Before",
                        "MN": "Before",
                        "AF": "Before",
                        "NT": "Before"
                    }
                },
                {
                    "num_days": "1",
                    "intake_time": {
                        "MN": true,
                        "EV": true,
                        "AF": false,
                        "NT": true
                    },
                    "subtitle": "warm",
                    "qty": 3,
                    "name": "Paracetamol",
                    "am_bm_stat": {
                        "MN": "After",
                        "EV": "After",
                        "AF": "After",
                        "NT": "After"
                    }
                },
                {
                    "num_days": "1",
                    "intake_time": {
                        "MN": true,
                        "EV": false,
                        "AF": false,
                        "NT": true
                    },
                    "qty": 2,
                    "subtitle": "cold water",
                    "name": "Dolo",
                    "am_bm_stat": {
                        "EV": "Before",
                        "MN": "Before",
                        "AF": "Before",
                        "NT": "Before"
                    }
                },
                {
                    "num_days": "10",
                    "intake_time": {
                        "MN": true,
                        "EV": false,
                        "AF": true,
                        "NT": false
                    },
                    "subtitle": "nothing",
                    "qty": 20,
                    "name": "Er",
                    "am_bm_stat": {
                        "EV": "Before",
                        "MN": "Before",
                        "AF": "Before",
                        "NT": "Before"
                    }
                },
                {
                    "num_days": "1",
                    "intake_time": {
                        "MN": true,
                        "EV": true,
                        "AF": false,
                        "NT": true
                    },
                    "subtitle": "warm",
                    "qty": 3,
                    "name": "Paracetamol",
                    "am_bm_stat": {
                        "MN": "After",
                        "EV": "After",
                        "AF": "After",
                        "NT": "After"
                    }
                },
                {
                    "num_days": "1",
                    "intake_time": {
                        "MN": true,
                        "EV": false,
                        "AF": false,
                        "NT": true
                    },
                    "qty": 2,
                    "subtitle": "cold water",
                    "name": "Dolo",
                    "am_bm_stat": {
                        "EV": "Before",
                        "MN": "Before",
                        "AF": "Before",
                        "NT": "Before"
                    }
                },
                {
                    "num_days": "10",
                    "intake_time": {
                        "MN": true,
                        "EV": false,
                        "AF": true,
                        "NT": false
                    },
                    "subtitle": "nothing",
                    "qty": 20,
                    "name": "Er",
                    "am_bm_stat": {
                        "EV": "Before",
                        "MN": "Before",
                        "AF": "Before",
                        "NT": "Before"
                    }
                },


            ],
            "prescriptionId": "5wHdqNAYcPySkHscbpgY"
        }
    ]
    console.log(userData, 'this is userDatauserDatauserDatauserData')
    const [medicareNumber, setMedicareNumber] = useState(userData.Patient_Medicare_Number);
    const [PBS, setPBS] = useState('');

    const [width, setWidth] = useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth <= 768);
        isMobile = window.innerWidth <= 768;
        console.log(isMobile, window.innerWidth)
        console.log(width)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [width]);



    function handleWindowSizeChange() {
        setWidth(window.innerWidth <= 768);
        isMobile = window.innerWidth <= 768;
        console.log(isMobile, window.innerWidth)
        console.log(width)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [width]);



    const handlemedicareNumber = (e) => setMedicareNumber(e.target.value)


    const fetchAppointment = async (appointmentId) => {
        try {
            let apt = null, doc = null;
            console.log(appointmentId, 'this is id');
            setIsLoading(true);
            let API = new Api();
            const appointmentails = await (
                await API.getAppointmentDetails(appointmentId).then(data => {
                    console.log(data, 'this is appt data');
                    apt = data.data
                    return data.data
                }).catch((e) => {
                    console.log(e)
                    console.log('error in fetching paoointment detail')
                })
            )
            const doctorDetails = await (
                await API.getUserDetails(
                    3,
                    appointmentails.appointment_details.doctorId
                ).then(data => {
                    doc = data.data
                    console.log(data, 'this is doctor data');
                    return data.data
                }).catch((e) => {
                    console.log(e)
                    console.log('error in fetching doctor detail')
                })
            )
            console.log(appointmentId, 'appointmentId', appointmentails, 'docyor ', doctorDetails);
            setAppointment({ data: appointmentails, doctor: doctorDetails?.user });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, 'fetch error');
            Notify({
                alert: true,
                title: "Failed to fetch prescriptions details",
                type: "error",
            });
        }
    };
    // <QRCode
    //     className="qr-code"
    //     value={`${window.location.origin + "/#/prescriptions/"
    //         }${appointmentId}`}
    // />
    // <div>
    //     <h4 className="doctor-detail-presc">Gender</h4>
    //     <p
    //         style={{ textTransform: "capitalize" }}
    //         className="doctor-detail-value"
    //     >{`${userData.gender ? userData.gender : 'N/A'}`}</p>
    // </div>
    // { `${userData.firstName} ${userData.lastName}` }
    // { `${userData.height ? userData.height : 'N/A'}` }
    // { `${userData.weight ? userData.weight : 'N/A'}` }
    useEffect(() => {
        console.log(userData, 'this comes at user data')
        fetchAppointment(appointmentId);
        return () => { };
    }, [appointmentId]);

    if (isLoading) return <SplashLoader />;

    return (
        <>

            {appointment && <div >
                <ul className="newul2 container">
                    <li className="newli vertical-height doc-info">
                        {/* <h4 className="doctor-name-presc">{`Dr. ${appointment.doctor.firstName ?
              appointment.doctor.firstName : ''
              } ${appointment.doctor.lastName
              }`}</h4> */}
                        <p className="doctor-number-presc">
                            Tel: {appointment.doctor.mobile}
                        </p>
                        <p className="doctor-number-presc">
                            Email: {appointment.doctor.email}
                        </p>
                    </li>
                    <li className="newli2">
                        <QRCode
                            className="qr-code"
                            value={`${window.location.origin + "/#/Eprescription/"
                                }${appointmentId}`}
                        />
                    </li>
                    <li className="newli2 vertical-height">
                        <h4 className="doctor-detail-presc">Reg Number</h4>
                        <p className="doctor-detail-value">
                            {appointment.doctor.regnumber}
                        </p>
                    </li>
                    <li className="newli2 vertical-height">
                        <Button onClick={handlePrint}>
                            <i className="fa fa-download"></i> Download
                        </Button>
                        {/* <ReactToPrint
                            className="ml-auto"
                            trigger={() => (
                            )}
                            content={() => componentRef.current}
                        /> */}
                    </li>
                </ul>
                <div id='report' style={{ width: '100%', }}>
                    <Container>
                        <Header>
                            <DoctorInfo>
                                <DoctorName>{appointment?.doctor?.prescriberData?.includeDr ? 'Dr. ' : ''} {appointment.data.appointment_details.doctorName}</DoctorName>
                                <Address>{appointment.data.appointment_details.hospital_address}</Address>
                                <PrescriberNo>Prescriber No. {appointment.data.appointment_details.doctorId}</PrescriberNo>
                            </DoctorInfo>
                            <PrescriberInfo>
                                <PhoneNumber>Phone: {appointment.data.appointment_details.phoneno}</PhoneNumber>

                                <PhoneNumber>Hospital: {appointment.data.appointment_details.hospital_name}</PhoneNumber>
                                <PhoneNumber>Hospital ID: {appointment.data.appointment_details.hospitalId}</PhoneNumber>
                                <PhoneNumber>Prescription ID: {appointment.data.appointment_details.appointmentId}</PhoneNumber>
                            </PrescriberInfo>
                        </Header>
                        <MainBody>
                            <PatientInfo>
                                <Label>Patient's Medicare No: </Label>
                                {/* <Label>Patient's Medicare No: {appointment.data.appointment_details.patientId}</Label> */}
                                {/* <Input /> */}
                                {/* <Input type="text" value={medicareNumber} onChange={handlemedicareNumber} /> */}
                                <Input type="text" disabled value={medicareNumber} onChange={handlemedicareNumber} />
                            </PatientInfo>
                            <PatientInfo>
                                <Label>Pharmaceutical Benefits Entitlement No: </Label>
                                <Input type="text" />
                                {/* <Input /> */}
                            </PatientInfo>
                            <CheckboxRow>
                                <CheckboxLabel>
                                    <Checkbox type="checkbox" />
                                    Safety Net Entitlement Cardholder
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <Checkbox type="checkbox" />
                                    Concessional or Dependent RPBS Beneficiary or Safety Concession Cardholder
                                </CheckboxLabel>
                            </CheckboxRow>
                            <PatientInfo>
                                <Label>Patient's Name: {appointment.data.appointment_details.name}</Label>
                                {/* <Input /> */}
                            </PatientInfo>
                            <PatientInfo>
                                <Label1>Gender:{`${userData.gender ? userData.gender : 'N/A'}`}</Label1>
                                {/* <Input /> */}
                                <Label1>Height:{`${userData.height ? userData.height : 'N/A'}`}</Label1>
                                <Label1>Weight: {`${userData.weight ? userData.weight : 'N/A'}`}</Label1>
                            </PatientInfo>

                            <PatientInfo>
                                <Label>Address: {userData.address}</Label>
                                {/* <Input /> */}
                            </PatientInfo>
                            <PatientInfo>
                                <Label>Date: {appointment.data.appointment_details.slot_date}</Label>
                                {/* <Input /> */}
                            </PatientInfo>
                            <CheckboxRow>
                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={appointment?.data?.prescription_details[0]?.medicines[0]?.additionalDetails?.PBS} />
                                    PBS
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <Checkbox type="checkbox" />
                                    RPBS
                                </CheckboxLabel>
                            </CheckboxRow>
                            <PatientInfo>
                                {/* <Label>Brand Substitution Not Permitted:</Label>
          <SmallInput /> */}
                                <CheckboxRow>
                                    <CheckboxLabel>
                                        <Checkbox type="checkbox" />
                                        Brand Substitution Not Permitted:
                                    </CheckboxLabel>
                                </CheckboxRow>
                            </PatientInfo>
                            <PrescriptionDesigned>
                                {/* <CrossLines />
          <CrossLines />
          <CrossLines />
          <CrossLines />
          <CrossLines /> */}
                                <VerticalLogo>
                                    <img
                                        src={logo}
                                        alt="new"
                                        style={{ width: '110%', height: '45px', transform: 'rotate(270deg)', top: '30%', position: 'relative' }}
                                    />
                                </VerticalLogo>
                                <PrescriptionDetails>
                                    {appointment.data.prescription_details[0].medicines.map(x => {
                                        let countTotal = 0
                                        if (x.intake_time.MN) countTotal += 1
                                        if (x.intake_time.EV) countTotal += 1
                                        if (x.intake_time.AF) countTotal += 1
                                        if (x.intake_time.NT) countTotal += 1
                                        return <DrugInfo>
                                            <DrugName>{x.name} </DrugName>
                                            <DrugTime>Total {countTotal} per day for {x.num_days} days</DrugTime>
                                            {x.intake_time.MN ? <DrugTime>Take at 8 AM  {x.am_bm_stat.MN} food</DrugTime> : null}
                                            {x.intake_time.AF ? <DrugTime>Take at 1 PM {x.am_bm_stat.AF} food</DrugTime> : null}
                                            {x.intake_time.EV ? <DrugTime>Take at 5 PM {x.am_bm_stat.EV} food</DrugTime> : null}
                                            {x.intake_time.NT ? <DrugTime>Take at 8 PM {x.am_bm_stat.NT} food</DrugTime> : null}
                                            {x.subtitle && <DrugPurpose>Instruction: {x.subtitle}</DrugPurpose>}
                                            {x?.additionalDetails?.Clinical_justification && <DrugPurpose>Purpose: {x?.additionalDetails?.Clinical_justification}</DrugPurpose>}
                                        </DrugInfo>
                                    })}
                                    {/* <DrugInfo>
              <DrugName>Drug A</DrugName>
              <DrugTime>Take at 8 AM</DrugTime>
              <DrugPurpose>Purpose: ...</DrugPurpose>
            </DrugInfo>
            <DrugInfo>
              <DrugName>Drug B</DrugName>
              <DrugTime>Take at 12 PM</DrugTime>
              <DrugPurpose>Purpose: ...</DrugPurpose>
            </DrugInfo> */}
                                    {/* Add more DrugInfo components as needed */}
                                </PrescriptionDetails>
                            </PrescriptionDesigned>
                            <TotalDrugs>
                                <ScriptNo>Script No: {Authority_script_number}</ScriptNo>
                                <DrugsPrescribed>Total: {appointment.data.prescription_details[0].medicines.reduce((accumulator, currentValue) => {
                                    // console.log(currentValue)
                                    // currentValue.intake_time
                                    // "MN": false,
                                    // "EV": true,
                                    // "AF": true,
                                    // "NT"
                                    let countTotal = 0
                                    if (currentValue.intake_time.MN) countTotal += 1
                                    if (currentValue.intake_time.EV) countTotal += 1
                                    if (currentValue.intake_time.AF) countTotal += 1
                                    if (currentValue.intake_time.NT) countTotal += 1
                                    console.log(currentValue.intake_time.MN, currentValue.intake_time.EV, 'currentValue.intake_time.MNcurrentValue.intake_time.MN', currentValue.num_days * countTotal, currentValue.num_days, countTotal, currentValue)
                                    return accumulator + (currentValue.num_days * countTotal)
                                },
                                    0)} drugs</DrugsPrescribed>
                                <ItemsPrinted>{appointment.data.prescription_details[0].medicines.length} Items Printed</ItemsPrinted>
                                <DoctorNameAndDesignation>{appointment.doctorName}<ItemsPrinted> {appointment.doctor.specialities.map(x => x + ', ')}
                                </ItemsPrinted></DoctorNameAndDesignation>
                            </TotalDrugs>
                            <TurnOverNote>Turn over for privacy note</TurnOverNote>
                            <Declaration>
                                <DeclarationText>
                                    I declare that I have received these medicines and the information relating to any entitlement to a pharmaceutical benefit is correct.
                                </DeclarationText>
                            </Declaration>
                        </MainBody>
                        <Footer>
                            <PatientSignature>Signature: _______________</PatientSignature>
                            <DateOfSupply>Date of Supply: {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}</DateOfSupply>
                            <AddressAndDoctorSignature>
                                Address: ___________________ <br /><br /><br />
                                Doctor's Signature: _______________
                            </AddressAndDoctorSignature>
                        </Footer>
                    </Container>
                </div>
            </div>
            }
        </>
    );
}
