import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';
// import { getApptData } from './data';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Apilib from "../../api";
import GoogleMap from '../Agora/utils/GoogleMap';
import { useHistory } from 'react-router-dom';
import { types } from 'sass';
import Notify from '../../Notify';

const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  margin: 10px 10px;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 5px;
  margin-left:5px;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width:-webkit-fill-available;
  padding: 5px;
  margin:5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
    width:95%;
    display: inline-grid;
    align-items: center;
`;

const Div1 = styled.div`
  width: 95%;
`;

const Div2 = styled.div`
  width: 95%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content:flex-start;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: #50d8fa;
`;


const Field = styled.div`
  margin-bottom: 16px;
`;

function GenerateAuthority_script_number(n) {
    return (n - 1) % 9 + 1;
}

const AddMedicine = (route) => {

    const history = useHistory()
    const [loading, setloading] = useState(false);

    const [medicineName, setMedicineName] = useState('');
    const [batchNumber, setbatchNumber] = useState('');
    const [quantity, setQuantity] = useState('');
    const [units, setUnits] = useState('');
    const [dose, setDose] = useState('');
    const [mfgDate, setMfgDate] = useState('');
    const [expDate, setExpDate] = useState('');




    const [fullName, setFullName] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [date, setDate] = useState(new Date());
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [patientList, setPatientList] = useState(null)
    const [inputBoxes, setInputBoxes] = useState([{
        'Medicine_Name': '',
        'Batch_Number': '',
        'Quantity': '',
        'Units': '',
        'Dose': '',
        'MfgDate': Date.now(),
        'ExpDate': Date.now(),
        'Price': '',
        'Description': '',
        'Contents': '',
        'isPrescription': 'prescription required (no)',

    }]);

    useEffect(() => {
        let API = new Apilib();
        console.log(route, 'this is route')
        API.getUsers(5, route.userData.hospitalId)
            .then((result) => {
                let res = result.data
                console.log(res);

                let sorted = res.patients;
                sorted.sort((a, b) => {
                    return b.created_at - a.created_at;
                })
                setPatientList(sorted)


            })
            .catch((error) => {
                // this.setState({
                //     loading: false,
                // });
            });
    }, [])


    const handleObjectChange = (event) => {
        const objectId = event.target.value
        console.log(objectId)
        const selected = patientList.find((obj) => obj.uid === objectId);
        console.log(selected)
        setSelectedPatient(selected);
        // address, Patient_Medicare_No

    };

    const typeofmedicine = [{ type: 'Device', }, { type: 'Syrup', }, { type: 'Tablet', }, { type: 'Injection', }, { type: 'other', }]
    const handleAddBox = () => {
        const values = [...inputBoxes];
        values.push({
            'Medicine_Name': '',
            'Batch_Number': '',
            'Quantity': '',
            'Units': '',
            'Dose': '',
            'MfgDate': Date.now(),
            'ExpDate': Date.now(),
            'Price': '',
            'type': '',
            'isPrescription': 'prescription required (no)',
        });
        setInputBoxes(values);
    };

    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
    };


    useEffect(() => {
        let newDate = inputBoxes
        setInputBoxes(newDate)
    }, [inputBoxes])



    const reusableInputState = (label, valueVar, onChaneVar, type) => {
        return <Containers>
            <Div1>
                <Label>{label}</Label>
            </Div1>
            <Div2>
                <Input type={type} value={valueVar} onChange={onChaneVar} />
            </Div2>
        </Containers>
    }

    const reusableDivState = (label) => {
        return <Div1>
            <Label>{label}</Label>
        </Div1>
    }

    const reusableMenuState = (label, onClickHandleMethod, icon) => {
        return <SidebarOption onClick={() => onClickHandleMethod()}>
            <SidebarOptionText> <i className={icon}></i>{label}</SidebarOptionText>
        </SidebarOption>
    }
    const onClickHandle = () => {
        console.log('hi there')
    }

    const handleInputChange = (index, event, field) => {
        console.log(inputBoxes)
        const values = [...inputBoxes];
        if (field == 'MfgDate' || field == 'ExpDate') {
            values[index][field] = event;
            return
        }
        values[index][field] = event.target.value;
        setInputBoxes(values);
    };





    return (
        <Container>
            {loading && <Loader />}
            <div style={{ display: 'flex' }}>

                <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
                    <Content>

                        <div style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '95%', backgroundColor: '#fff' }} >


                            <Heading>Add Medicine</Heading>
                            <div style={{ border: 'solid #000 .05px' }}></div>

                            <div>

                                {/* {reusableInputState('Medicine Name:', medicineName, (e) => setMedicineName(e.target.value), 'text')}
                                {reusableInputState('Batch Number:', batchNumber, (e) => setbatchNumber(e.target.value), 'text')}
                                {reusableInputState('Quantity:', quantity, (e) => setQuantity(e.target.value), 'number')}
                                {reusableInputState('Units:', units, (e) => setUnits(e.target.value), 'number')}
                                {reusableInputState('Dose:', dose, (e) => setDose(e.target.value), 'text')}
                                <Containers>
                                    <Div1>
                                        <Label>MFG Date :</Label>
                                    </Div1>
                                    <Div2>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                inputVariant="outlined"
                                                value={date}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    setMfgDate(e)
                                                }}
                                                animateYearScrolling
                                                name="dateOfBirth"
                                                disableFuture
                                                format="dd/MM/yyyy"
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Div2>
                                </Containers>
                                <Containers>
                                    <Div1>
                                        <Label>Expiry Date :</Label>
                                    </Div1>
                                    <Div2>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                inputVariant="outlined"
                                                value={date}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    setExpDate(e)
                                                }}
                                                animateYearScrolling
                                                name="dateOfBirth"
                                                disablePast
                                                format="dd/MM/yyyy"
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Div2>
                                </Containers> */}


                                {inputBoxes.map((inputState, index) => (
                                    <div key={index} style={{
                                        // backgroundColor: '#EAFCFF', padding: '10px', margin: '5px', borderRadius: '11px',
                                        // display: 'grid',
                                        // // grid- template - columns: repeat(auto-fill, minmax(300px, 1fr));
                                        // // gridTemplateColumns: '300px calc(100vh - 100px) 50px'
                                        backgroundColor: '#EAFCFF',
                                        padding: '10px',
                                        margin: '5px',
                                        borderRadius: '11px',
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                        gap: '10px',
                                        '@media (max-width: 767px)': {
                                            gridTemplateColumns: '1fr',
                                        },
                                    }}>

                                        <Containers>
                                            {reusableDivState('Medicine Name')}
                                            <Div2>
                                                <Input type="text" value={inputState.Medicine_Name} onChange={(event) => handleInputChange(index, event, 'Medicine_Name')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Batch Number')}
                                            <Div2>
                                                <Input type="text" value={inputState.Batch_Number} onChange={(event) => handleInputChange(index, event, 'Batch_Number')} />
                                            </Div2>
                                        </Containers>


                                        <Containers>
                                            <Div1>
                                                <Label>MFG Date :</Label>
                                            </Div1>
                                            <Div2>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        inputVariant="outlined"
                                                        value={inputState.MfgDate}
                                                        onChange={(event) => handleInputChange(index, event, 'MfgDate')}
                                                        animateYearScrolling
                                                        name="dateOfBirth"
                                                        disableFuture
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>

                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            <Div1>
                                                <Label>Expiry Date :</Label>
                                            </Div1>
                                            <Div2>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        inputVariant="outlined"
                                                        value={inputState.ExpDate}
                                                        onChange={(event) => handleInputChange(index, event, 'ExpDate')}
                                                        animateYearScrolling
                                                        name="dateOfBirth"
                                                        disablePast
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>

                                            </Div2>
                                        </Containers>

                                        <Containers>
                                            {reusableDivState('Quantity')}
                                            <Div2>
                                                <Input type="text" value={inputState.Quantity} onChange={(event) => handleInputChange(index, event, 'Quantity')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Price')}
                                            <Div2>
                                                <Input type="text" value={inputState.Price} onChange={(event) => handleInputChange(index, event, 'Price')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Units')}
                                            <Div2>
                                                <Input type="text" value={inputState.Units} onChange={(event) => handleInputChange(index, event, 'Units')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Dose')}
                                            <Div2>
                                                <Input type="text" value={inputState.Dose} onChange={(event) => handleInputChange(index, event, 'Dose')} />
                                            </Div2>
                                        </Containers>

                                        <Containers>
                                            {reusableDivState('Description')}
                                            <Div2>
                                                <Input type="text" value={inputState.Description} onChange={(event) => handleInputChange(index, event, 'Description')} />
                                            </Div2>
                                        </Containers>
                                        <Containers>
                                            {reusableDivState('Contents')}
                                            <Div2>
                                                <Input type="text" value={inputState.Contents} onChange={(event) => handleInputChange(index, event, 'Contents')} />
                                            </Div2>
                                        </Containers>
                                        {/* <Containers> */}
                                        <div style={{ justifyContent: 'center', display: 'grid' }}>
                                            <select style={{ padding: '5px', margin: '5px' }} value={inputState.type} onChange={(event) => handleInputChange(index, event, 'type')} required>
                                                <option value="">Type</option>
                                                {typeofmedicine.map((obj) => (
                                                    <option key={obj.type} value={obj.type}>
                                                        {obj.type}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                        <div style={{ justifyContent: 'center', display: 'grid' }}>
                                            <select style={{ padding: '5px', margin: '5px' }} value={inputState.isPrescription} onChange={(event) => handleInputChange(index, event, 'isPrescription')} required>
                                                <option value="prescription required (no)">prescription required (no)</option>
                                                {['no', 'yes'].map((obj) => (
                                                    <option value={obj}>
                                                        {obj}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                        {/* </Containers> */}


                                    </div>))}

                                <Button onClick={handleAddBox}>Add new medicine</Button>

                                <CheckboxLabel>
                                    <Checkbox type="checkbox" checked={agreeTerms}
                                        onChange={() => {
                                            console.log(agreeTerms)
                                            setAgreeTerms(!agreeTerms)
                                        }} />
                                    Agree with the terms and conditions
                                </CheckboxLabel>

                            </div>
                            <ButtonGroup>
                                <Button style={{ fontWeight: '400', fontSize: '16px' }}
                                    onClick={async () => {
                                        if (!agreeTerms) {
                                            alert('Please agree to the terms and conditions')
                                            return
                                        }
                                        let m = { pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1', medicines: { supplierMedicines: inputBoxes }, supplierName: 'ram', mobileNumber: '972224616', Email: 'ram@gmail.com', number: '451236', address: 'rohili,barmer', city: 'barmer', pharmacy: 'KAoOnOt37RMTwAxsr6sqi1aOler1' }

                                        let inputbox = inputBoxes.map((x) => {
                                            x.pharmacyId = route.userData.uid
                                            if (x.Medicine_Name) return x

                                        })
                                        // medicines.supplierMedicines
                                        // body.pharmacy_id
                                        // body.uid
                                        // body.pharmacy
                                        //         supplierName: body.supplierName,
                                        //             mobileNumber:body.mobileNumber,
                                        // Email:body.Email,
                                        // number:body.number,
                                        // address:body.address,
                                        // city:body.city,
                                        // date:new Date.now(),
                                        // medicines:medicines,

                                        // https://australia-southeast1-medvatic-438af.cloudfunctions.net/pharmacy/test
                                        let API = new Apilib();

                                        // { pharmacyId: 'KAoOnOt37RMTwAxsr6sqi1aOler1', medicines: {supplierMedicines: inputBoxes }, supplierName: 'ram', mobileNumber: '972224616', Email: 'ram@gmail.com', number: '451236', address: 'rohili,barmer', city: 'barmer', pharmacy: 'KAoOnOt37RMTwAxsr6sqi1aOler1' }


                                        let data = {
                                            medicines: inputbox,
                                            pharmacyId: route.userData.uid,
                                        }


                                        console.log(data)

                                        API.createPharmacyMedicine(data)
                                            .then(async (res) => {
                                                console.log(res)
                                                console.log('this is response')
                                                // res.data.code==200
                                                if (res.data.code == 200) Notify({
                                                    type: "success",
                                                    alert: true,
                                                    title: "Medicines added successfully",
                                                })
                                                else Notify({
                                                    type: "error",
                                                    alert: true,
                                                    title: "There was an error processing your request, Please try again",
                                                })

                                            })
                                            .catch((error) => {
                                                console.log('error', error)
                                                Notify({
                                                    type: "error",
                                                    alert: true,
                                                    title: "There was an error processing your request, Please try again",
                                                })
                                            });

                                    }}
                                > Save Medicines</Button>
                                <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }}
                                    onClick={() => history.goBack()}>
                                    Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </Content>
                </div>
            </div>
        </Container >
    );
};

export default AddMedicine;
