// jshint esversion:6

import React, { Component } from "react";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";

export default class Practitioner extends Component {
  render() {
    return (
      <>
        <section className="section practitioner-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9">
                <div className="section-header text-center">
                  <h3 className="header-title">Practitioner Benefits</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content departments" style={{"backgroundColor": "#fff"}}>
          <div className="container">
            <div className="row">
              <div className="col-12"></div>
            </div>
            <div className="row department-row">
              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="67"
                        height="80"
                        alt="Dentists"
                        src="assets/img/efficiency.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">EFFICIENCY</a>
                  </h4>
                  <p>
                    The ability to treat more patients in a shorter period of
                    time, increasing patient and practitioner satisfaction.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="63"
                        height="80"
                        alt="Neurology"
                        src="assets/img/doctor.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">CARE TEAM </a>
                  </h4>
                  <p>
                    Reimburse by medicare for services provided to patients
                    under Medvatic Care Team plan.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="92"
                        height="80"
                        alt="Opthalmology"
                        src="assets/img/flexibility.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">FLEXIBILITY</a>
                  </h4>
                  <p>
                    Practitioners will be able to attend to patients remotely.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="40"
                        height="80"
                        alt="Orthopedics"
                        src="assets/img/reliable.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">RELIABILITY</a>
                  </h4>
                  <p>
                    Practitioners will be able to access patients' test results
                    and medical history in real-time.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="76"
                        height="80"
                        alt="Cancer Department"
                        src="assets/img/organization.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">EFFECTIVENESS</a>
                  </h4>
                  <p>
                    Practitioners will be able to request a second opinion from
                    another practitioner or a predictive recover medicine
                    Artificial Intelligence (AI), increasing practitioner
                    productivity.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src="assets/img/notification.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">SIMPLICITY </a>
                  </h4>
                  <p>
                    Our simplified alerts and remainder allow practitioners to
                    deliver higher quality care to patients for both preventive
                    health care and management of diabetes and helps ensure that
                    timely clinical interventions are initiated.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src="assets/img/knowledge.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">MEDKNOWLEDGE </a>
                  </h4>
                  <p>For real-time decision support.</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="dept-box">
                  <div className="dept-img">
                    <a href="department-details.html">
                      <img
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src="assets/img/online.png"
                      />
                    </a>
                  </div>
                  <h4>
                    <a href="department-details.html">MEDONLINE</a>
                  </h4>
                  <p>
                    Built your referral network through our cloud hospital
                    system and our unique streamlined consultation platform.
                  </p>
                </div>
              </div>
            </div>

            <div className="row ">
          <div className="col-12">
            <div className="see-all m-t-0  product-bottom">
              <p className="product-p"> WANT TO KNOW MORE? </p>
              <a
                type="button"
                href="#/Contact"
                className="btn btn-primary see-all-btn practitioner-button"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
          </div>
        </section>
      </>
    );
  }
}
