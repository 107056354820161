// jshint esversion:6

import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import Loader from "../Loader/Loader";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";
import "./style.css";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userData: null,
      pp_url: "",
    };
  }

  makeDownloadUrl = (path) => {
    this.setState({ loading: true });
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    gsReference
      .getDownloadURL()
      .then((url) => {
        console.log(url);
        this.setState({
          pp_url: url,
          loading: false,
        });

        console.log("PPurl>>.", this.state.pp_url);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  async componentDidMount() {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ loading: true });
        API.getUserDetails(user.displayName, user.uid)
          .then((res) => {
            let data = res.data
            console.log("ressss", data.user);
            this.setState({
              loading: false,
              userData: data.user,
            });
            // this.props.updateUserData(data.user);
            if (data.user.profile_picture) {
              console.log("USE PROFILE", data.user.profile_picture);
              // this.makeDownloadUrl(data.user.profile_picture);
              this.setState({
                pp_url: data.user.profile_picture,
                loading: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log(err);
          });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  render() {
    let userData = this.state.userData || {};
    // console.log(userData);
    return (
      <>
        {this.state.loading ? <Loader /> : null}
        {userData.type == 5 ? (
          <div className="page-header profile-header">
            <div className="content profile-content">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-3 mt-md-5 doctor-sidebar">
                    <div className="doctor-list doctor-view">
                      <div className="doctor-inner">
                        <img
                          className="img-fluid"
                          alt=""
                          src={
                            this.state.pp_url !== ""
                              ? this.state.pp_url
                              : "../assets/img/doctor-06.jpg"
                          }
                        ></img>
                        <div className="doctor-details">
                          <div className="doctor-info">
                            <h4 className="doctor-name">
                              {this.props.user && (
                                <span>
                                  {userData.firstName}{" "}
                                  {userData?.middleName?.length > 15
                                    ? userData.middleName[0]
                                    : userData.middleName || ""}{" "}
                                  {userData.lastName}
                                </span>
                              )}
                            </h4>
                            {/* <p>
                              <span class="depart">Physical Therapist</span>
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-9">
                    <div className="about-doctor">
                      <div className="d-flex justify-content-end mb-4 ">
                        <Link
                          to="/add-member"
                          className="btn custom-btn btn-primary d-block btn-rounded"
                        >
                          Add Family Account
                        </Link>
                        <Link
                          to="/edit-profile"
                          className="btn custom-btn btn-primary ml-2 d-block  btn-rounded"
                        >
                          Edit Profile
                        </Link>
                      </div>
                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <div className="experience-box">
                            <h3 className="sub-title">Contact No.</h3>
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.phoneno}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Email Id</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.email}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Date of Birth</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>
                                    {moment(userData.dateOfBirth).format(
                                      "DD/MM/YYYY"
                                    ) || ""}
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Height</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.height}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Weight</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.weight}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Allergies</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>
                                    {userData.allergies
                                      ? userData.allergies.join(", ")
                                      : ""}
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Gender</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.gender}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Address</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.address}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">City/State/Country</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.geoLocation}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Postal Code</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.postalcode}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {userData?.preferredPharmacy && (
                          <div className="experience-widget col-md-4">
                            <h3 className="sub-title">Preferred Pharmacy</h3>
                            <div className="experience-box">
                              <ul className="experience-list">
                                <li>
                                  <div className="timeline-content">
                                    <h4>{userData?.preferredPharmacy.name}</h4>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Emergency contact Name</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.emergencyContactName}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">
                            Emergency contact Number
                          </h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.emergencyContactDetails}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Insurance Number</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.insuranceNumber || ""}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="page-header profile-header">
            <div className="content profile-content">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-3 mt-md-5 doctor-sidebar">
                    <div className="doctor-list doctor-view">
                      <div className="doctor-inner">
                        <img
                          className="img-fluid"
                          alt=""
                          src={
                            this.state.pp_url !== ""
                              ? this.state.pp_url
                              : "../assets/img/doctor-06.jpg"
                          }
                        ></img>
                        <div className="doctor-details">
                          <div className="doctor-info">
                            <h4 className="doctor-name">
                              {this.props.user && (
                                <span>
                                  {userData.firstName} {userData.lastName}
                                </span>
                              )}
                            </h4>
                            {/* <p>
                              <span class="depart">Physical Therapist</span>
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-9">
                    <div className="about-doctor">
                      {/* <div className="d-flex justify-content-end mb-4 ">
                        <Link
                          to="/add-member"
                          className="btn custom-btn btn-primary d-block btn-rounded"
                        >
                          Add Family Account
                        </Link>
                        <Link
                          to="/edit-profile"
                          className="btn custom-btn btn-primary ml-2 d-block  btn-rounded"
                        >
                          Edit Profile
                        </Link>
                      </div> */}

                      {/* <div className="about-doctor">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta luctus est interdum pretium. Fusce id tortor fringilla, suscipit turpis ac, varius
                      ex. Cras vel metus ligula. Nam enim ligula, bibendum a iaculis ut, cursus id augue. Proin vitae purus id tortor vehicula scelerisque non in libero.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta luctus est interdum pretium. Fusce id tortor fringilla, suscipit turpis ac, varius
                      ex. Cras vel metus ligula. Nam enim ligula, bibendum a iaculis ut, cursus id augue. Proin vitae purus id tortor vehicula scelerisque non in libero.</p>
                    </div> */}
                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <div className="experience-box">
                            <h3 className="sub-title">Contact No.</h3>
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.phoneno}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Email Id</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.email}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">
                            Emergency contact details
                          </h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.emergencyContactDetails}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* 
                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Height</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.height}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Weight</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.weight}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Allergies</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>
                                    {userData.allergies
                                      ? userData.allergies.join(", ")
                                      : ""}
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}

                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Hospital Code</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.gender}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Address</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.address}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Country</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.country}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">City</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.city}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">State</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.State}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="experience-widget col-md-4">
                          <h3 className="sub-title">Postal Code</h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="timeline-content">
                                  <h4>{userData.postalcode}</h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* </div> */}
      </>
    );
  }
}
