import { Formik } from "formik";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import BaseSelect from "react-select";
import * as Yup from "yup";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";
import { firestore } from "../../firebase";
import { Link } from "react-router-dom";
import { customAlphabet } from "nanoid";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const specialities = [
  { value: "Cardiologist", label: "Cardiologist" },
  { value: "Dentist", label: "Dentist" },
  { value: "Gynaecologist", label: "Gynaecologist" },
  { value: "Paediatrician", label: "Paediatrician" },
  { value: "Psychiatrists", label: "Psychiatrists" },
];

const options = [
  {
    label: '<Fontawesome class="fas fa-briefcase-medical"></i>',
    value: '<i class="fas fa-briefcase-medical"></i>',
  },
  {
    label: "Icon2",
    value: "Icon2",
  },
  {
    label: "Icon3",
    value: "Icon3",
  },
  {
    label: "Icon4",
    value: "Icon4",
  },
];
export default class AddPharmacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      success: false,
      chain_name: "",
      createdSpecialities: [],

      // update test
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
      pharmacyName: '',
      pharmacyAddrs: '',
      email: '',
      name: '',
      pharmacyId: '',
      password: '',
      // models state
      modalOpen: false,
      modelLoading: false,

      confirmModelLoading: false,
      confirmModalOpen: false,

      inputList: [{ name: "", medicinePrice: "", medicineId: '', medicineIngredients: '', medicineExpiry: '', discount: '', delivery: '', stock: '', misc: '' }]
    };
  }
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
  };
  handleRemoveClick = (index) => {
    const list = [...this.state.inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    // list.splice(index, 1);
    this.setState({ inputList: remove });
  };
  handleAddClick = () => {
    let inputListTemp = [...this.state.inputList]
    inputListTemp.push({ name: "", medicinePrice: "", medicineId: '', medicineIngredients: '', medicineExpiry: '', discount: '', delivery: '', stock: '', misc: '' })
    this.setState({ inputList: inputListTemp });
  };
  componentDidMount() {
    this.getSpeciality();
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
    });
  }

  handleComfirmModalClose = () => {
    this.setState({
      confirmModalOpen: false,
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
    });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e, { resetForm }) => {
    console.log('clicked this in submit ', this.state.pharmacyName)
    console.log('clicked this in submit ', this.state.pharmacyAddrs, this.state.name, this.state.pharmacyId, this.state.email)
    let callApi = {
      email: this.state.email,
      name: this.state.name,
      orderCount: 0,
      pharmacyId: this.state.pharmacyId,
      pharmacyName: this.state.pharmacyName,
      Sales_License_Number: this.state.Sales_License_Number,
      pharmacyContact: this.state.pharmacyContact,
      pharmacyAddrs: this.state.pharmacyAddrs

    }
    try {
      this.setState({
        error: "",
        loading: true,
      });
      let API = new Apilib();

      const nanoid = customAlphabet(
        "1234567890abcdefghijklmnopqrstuvwxyz",
        10
      );
      let data = {
        type: 6,
        password: this.state.password,
        email: this.state.email,
        name: this.state.name,
        orderCount: 0,
        pharmacyId: this.state.pharmacyId,
        pharmacyName: this.state.pharmacyName,
        Sales_License_Number: this.state.Sales_License_Number,
        pharmacyContact: this.state.pharmacyContact,
        pharmacyAddrs: this.state.pharmacyAddrs
      };

      // console.log("API data Doctor::", data);
      API.create(JSON.stringify(data))
        .then((result) => {
          let res = result.data;
          console.log(res, 'this is res')
          this.setState({
            loading: false,
          });

          Notify({
            alert: true,
            type: "success",
            title: "Pharmacy Added Successfully",
          });
          // this.props.history.push("/hospital/doctors");
        })
        .catch((error) => {
          // Notify({
          //   alert: true,
          //   type: "error",
          //   title: "Failed to add doctor",
          // });
          console.log(error, 'this is catch error')
          if (error?.response?.data?.code == "auth/email-already-exists") {
            this.setState({
              error: error.response.data.message,
              loading: false,
            })
            Notify({
              type: "error",
              alert: true,
              title: "An account with same email is already present, please use other email to create an account",
            })
          }
          else this.setState({
            error: error.message,
            success: false,
            loading: false,
          });
        });



      // API.addPharmacy(callApi).then((result) => {
      //   let res = result.data;
      //   // if (!res.ok) {
      //   //   let msg = await res.json();
      //   //   throw new Error(msg.message);
      //   // }
      //   Notify({
      //     alert: true,
      //     type: "success",
      //     title: "Pharmacy added",
      //   });
      //   resetForm();
      //   this.setState({
      //     selectedSpeciality: {
      //       speciality: "",
      //       id: "",
      //     },
      //     pharmacyAddrs: '',
      //     pharmacyId: '',
      //     pharmacyName: '',
      //     name: '',
      //     email: ''
      //   });
      //   this.getSpeciality();
      // });
    } catch (error) {
      console.log(error);
      this.setState(
        {
          loading: false,
        },
        () =>
          Notify({
            alert: true,
            type: "error",
            title: error.message,
          })
      );
    }
  };

  onSpecialitySelect(value) {
    let arr = [];
    value.map((val, index) => {
      arr.push(val.value);
    });
    this.setState({
      specialities: arr,
      specialitiesObj: value,
    });
  }

  getSpeciality = async () => {
    try {
      this.setState({
        loading: true,
      });
      const API = new Apilib();
      API.getPharmacies()
        .then((result) => {
          let res = result.data

          console.log("Pharm data", res.data);
          // setStatePharmacies(res.data);
          this.setState({
            createdSpecialities: res.data,
            loading: false,
          });
        })
        .catch((err) => {
          // setLoading(false);
          console.log(err);
        });

      //   API.getSpecialities().then((result) => {
      //     let res = result.data;
      //     console.log(res.data, 'this is speccilaties format')
      //     return this.setState({
      //       createdSpecialities: res.data,
      //       loading: false,
      //     });
      //     // return this.setState({
      //     //   loading: false,
      //     //   error: "Unable to fetch specialities",
      //     //   success: false,
      //     // });
      //   });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message,
        success: false,
      });
    }
  };

  handleSpecialityUpdate = async () => {
    try {
      const API = new Apilib();
      await API.updateSpeciality(this.state.selectedSpeciality)
        .then((result) => {
          let res = result.data
          this.setState(
            {
              modelLoading: false,
              modalOpen: false,
            },
            () => {
              Notify({
                alert: true,
                type: "success",
                title: "Speciality Updated Successfully",
              });
              this.setState({
                selectedSpeciality: {
                  speciality: "",
                  id: "",
                },
              });
              this.getSpeciality();
            }
          );
          // if (!res.ok) {
          //   let msg = await res.json();
          //   throw new Error(msg.message);
          // }
        });

    } catch (error) {
      console.log(error);
      this.setState(
        {
          modelLoading: false,
        },
        () => {
          Notify({
            alert: true,
            type: "error",
            title: error.message,
          });
        }
      );
    }
  };

  handleSpecialityDelete = async () => {
    try {
      const API = new Apilib();
      await API.deletePharmacy(
        JSON.stringify({ id: this.state.selectedSpeciality.id })
      );
      this.setState(
        {
          confirmModelLoading: false,
          confirmModalOpen: false,
          selectedSpeciality: {
            speciality: "",
            id: "",
          },
        },
        () => {
          Notify({
            alert: true,
            type: "success",
            title: "Pharmacy Deleted Successfully",
          });
          this.getSpeciality();
        }
      );
    } catch (error) {
      console.log(error);
      this.setState(
        {
          confirmModelLoading: false,
          confirmModalOpen: false,
        },
        () => {
          Notify({
            alert: true,
            type: "error",
            title: "Failed to delete",
          });
        }
      );
    }
  };


  render() {
    const styless = {
      inputBoxStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#000',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '0.469rem 0.75rem',
        // border-color: #eaeaea;
        fontSize: '14px',
        minHeight: '40px',
        minWidth: '50%'
      },
      inputContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flex: 1,
        alignContent: 'space-evenly',
        // alignItems:''
      }
    }

    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Add Pharmacy </h4>
          </div>
        </div>
        {this.state.error && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            {this.state.error}
          </div>
        )}
        {this.state.success && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            Pharmacy added successfully
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <Formik
              validationSchema={Yup.object().shape({
                speciality: Yup.string()
                  // .required()
                  // .min(0, "Speciality name should be of minimum 3 characters")
                  .matches(
                    /^[aA-zZ\s,0-9]+$/,
                    "Only alphabets are allowed"
                  )
                  .label("Speciality name")
                  .trim(),
              })}
              onSubmit={this.onSubmit}
              initialValues={{ speciality: "" }}
            >
              {({
                handleSubmit,
                submitForm,
                values,
                errors,
                handleChange,
                setErrors,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.setState({
                      selectedSpeciality: {
                        speciality: values.speciality.trim(),
                        id: this.state.selectedSpeciality.id,
                      },
                    });
                    submitForm();
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <label>
                        Pharmacy Name<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter the pharmacy name"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          console.log(e.target.value, 'this i s each char')
                          this.setState({ pharmacyName: e.target.value })
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("speciality", e.target.value);
                        }}
                        value={values.pharmacyName}
                      />
                      {/* <small className="form-text text-danger">
                        {errors?.speciality}
                      </small> */}
                    </div>

                    {/* email */}

                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter Email"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          console.log(e.target.value, 'this i s each char')
                          this.setState({ email: e.target.value })
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("email", e.target.value);
                        }}
                        value={values.email}
                      />
                      {/* <small className="form-text text-danger">
                        {errors?.speciality}
                      </small> */}
                    </div>

                    {/* name */}

                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter Owner Name"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({ name: e.target.value })
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("name", e.target.value);
                        }}
                        value={values.name}
                      />
                      {/* <small className="form-text text-danger">
                        {errors?.speciality}
                      </small> */}
                    </div>

                    {/* pharmacyId */}

                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter Pharmacy Id"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({ pharmacyId: e.target.value })
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("pharmacyId", e.target.value);
                        }}
                        value={values.pharmacyId}
                      />
                      {/* <small className="form-text text-danger">
                        {errors?.speciality}
                      </small> */}
                    </div>
                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Sales License Number"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({ Sales_License_Number: e.target.value })
                          // pharmacyContact
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("Sales_License_Number", e.target.value);
                        }}
                        value={values.Sales_License_Number}
                      />
                      {/* <small className="form-text text-danger">
                        {errors?.speciality}
                      </small> */}
                    </div>

                    {/* pharmacyId */}

                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter Pharmacy Contact Number"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({ pharmacyContact: e.target.value })
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("pharmacyContact", e.target.value);
                        }}
                        value={values.pharmacyContact}
                      />
                      {/* <small className="form-text text-danger">
                        {errors?.speciality}
                      </small> */}
                    </div>

                    <div className="col-12">
                      {/* <label>
                        Choose Icon<span className="text-danger">*</span>
                      </label> */}
                      {/* <input
                        required
                        className="form-control"
                        placeholder="Enter the pharmacy address"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({
                            selectedSpeciality: {
                              pharmacy: e.target.value,
                              id: this.state.selectedSpeciality.id,
                            },
                          });
                          setFieldValue("pharmacy", e.target.value);
                        }}
                        value={values.pharmacy}
                      /> */}
                    </div>
                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter the pharmacy address"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({
                            pharmacyAddrs: e.target.value,
                          });

                          setFieldValue("pharmacyAddrs", e.target.value);
                          console.log(this.state, 'this is the state')
                        }}
                        value={values.pharmacyAddrs}
                      />
                      {/* <select className="form-control" type="text">
                        <option>Select...</option>
                        <option
                          data-icon="glyphicon glyphicon-eye-open"
                          data-subtext="petrification"
                        >
                          Doctor
                        </option>
                      </select> */}
                    </div>
                    <div className="form-group col-sm-6">
                      <input
                        required
                        className="form-control"
                        placeholder="Enter Password"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({ password: e.target.value })
                          // this.setState({
                          //   selectedSpeciality: {
                          //     speciality: e.target.value,
                          //     pharmacy: e.target.value,
                          //     id: this.state.selectedSpeciality.id,
                          //   },
                          // });
                          setFieldValue("password", e.target.value);
                        }}
                        value={values.password}
                      />
                    </div>
                    <div className="col-sm-6 form-group d-flex align-items-start">
                      <div className="text-center">
                        <button className="btn btn-primary submit-btn">
                          Create Pharmacy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <div
                        className="alert alert-blue alert-dismissible fade show"
                        role="alert"
                        style={{
                          marginTop: 0,
                        }}
                      >
                        Note :- Pharmacy added here will be used in add doctor
                        section
                      </div> */}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        {!this.state.loading && (
          <Table
            tableOptions={{
              columnDefs: [{ orderable: false, targets: 1, width: "180px" }],
            }}
            columns={["Pharmacy", "View", "Edit"]}
            rows={this.state.createdSpecialities.map((speciality) => (
              <tr>
                <td>{speciality.pharmacyName}</td>
                <td>
                  {/* <button
                  onClick={() => { */}
                  {/* <div className="btn btn-small btn-secondary mr-2"> */}
                  <Link
                    // to="/admin/edit-pharmacy"
                    // className="btn btn-small btn-secondary mr-2"
                    to={{
                      pathname: "/admin/edit-pharmacy",
                      idfrom: speciality.uid,
                      pharmacyName: speciality.pharmacyName,

                    }}
                  >
                    <span
                      class="iconify"
                      data-icon="akar-icons:eye"
                      data-inline="false"
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                    ></span> View Pharmacy
                  </Link>
                  {/* </div> */}
                  {/* }
                  }
                  className="btn btn-small btn-secondary mr-2"
                >
                  <span
                    class="iconify"
                    data-icon="akar-icons:edit"
                    data-inline="false"
                    style={{
                      marginRight: ".5em",
                      marginTop: "-.1em",
                      display: "inline-block",
                    }}
                  ></span>
                  View Pharmacy
                </button></td> */}

                </td>
                <td>
                  <button
                    onClick={() => {
                      this.setState({
                        modalOpen: true,
                        selectedSpeciality: speciality,
                      })
                      console.log(speciality)
                    }
                    }
                    className="btn btn-small btn-secondary mr-2"
                  >
                    <span
                      class="iconify"
                      data-icon="akar-icons:edit"
                      data-inline="false"
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                    ></span>
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        confirmModalOpen: true,
                        selectedSpeciality: speciality,
                      });
                    }}
                    className="btn btn-small btn-danger mr-2"
                  >
                    <span
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                      class="iconify"
                      data-icon="ant-design:delete-twotone"
                      data-inline="false"
                    ></span>
                    Delete
                  </button>
                </td>
              </tr>
            ))
            }
          />
        )}
        <Modal
          backdrop="static"
          show={this.state.modalOpen}
          onHide={() => this.handleClose()}
          animation={false}
          size="lg"
          dialogClassName="modal-100w"

          scrollable={true}
        // fullscreen={true}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Add medicines</Modal.Title>
          </Modal.Header>
          <Formik
            validationSchema={Yup.object().shape({
              speciality: Yup.string()
                .required()
                .matches(
                  /^[aA0-zZ9\s]+$/,
                  "Only alphabets are allowed for speciality name"
                )
                .min(3, "Pharmacy name should be of minimum 3 characters")
                .label("Pharmacy name")
                .trim(),

            })}
            onSubmit={this.handleSpecialityUpdate}
            initialValues={{
              speciality: this.state.selectedSpeciality.speciality,
            }}
          >
            {({
              handleSubmit,
              submitForm,
              values,
              errors,
              handleChange,
              setErrors,
              setFieldValue,
            }) => (
              <>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Pharmacy Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter the pharmacy"
                          type="text"
                          onChange={(e) => {
                            console.log(values)
                            setFieldValue("pharmacy", e.target.value);
                          }}
                          onBlur={() => {
                            if (!!values.speciality === false)
                              setErrors({ speciality: "" });
                          }}
                          value={this.state.selectedSpeciality.pharmacyName}
                        />
                        {/* <span className="form-text text-danger">
                          {errors.speciality}
                        </span> */}
                      </div>
                    </div>
                    <div className="col-lg-12">


                      {/* <div className="form-group">
                        <label>Icon</label>

                        <select className="form-control" type="text">
                          <option>Select...</option>
                          <option
                            data-icon="glyphicon glyphicon-eye-open"
                            data-subtext="petrification"
                          >
                            Doctor
                          </option>
                        </select>
                        <span className="form-text text-danger">
                          {errors.speciality}
                        </span>
                      </div> */}

                      {this.state.inputList.map((x, i) => {
                        // { name: "", medicinePrice: "", medicineId: '', medicineIngredients: '', medicineExpiry: '', discount: '', delivery: '', stock: '', misc: '' }
                        return (
                          <div className="box">
                            <div className="form-group"
                              style={styless.inputContainer}
                            >
                              <input
                                // className="form-control col-sm-5 row"
                                name="name"
                                placeholder="Enter Medicine Name"
                                value={x.name}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                              <input
                                // className="form-control col-sm-5 row"
                                style={styless.inputBoxStyle}
                                name="medicinePrice"
                                placeholder="Enter Medicine price"
                                value={x.medicinePrice}
                                onChange={(e) => this.handleInputChange(e, i)}
                              />

                            </div>
                            <div className="form-group"
                              style={styless.inputContainer}
                            >
                              <input
                                // className="form-control col-sm-5"
                                name="medicineId"
                                placeholder="Enter Medicine Id"
                                value={x.medicineId}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />

                              <input
                                // className="form-control"
                                name="medicineIngredients"
                                placeholder="Enter Medicine Ingredients"
                                value={x.medicineIngredients}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                            </div>
                            <div className="form-group"
                              style={styless.inputContainer}
                            >

                              <input
                                // className="form-control"
                                name="medicineExpiry"
                                placeholder="Enter Medicine Expiry(dd-mm-yyyy)"
                                value={x.medicineExpiry}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />


                              <input
                                // className="form-control"
                                name="discount"
                                placeholder="Enter Discount if any (in %)"
                                value={x.discount}
                                type={'number'}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                            </div>

                            <div className="form-group"
                              style={styless.inputContainer}
                            >


                              <input
                                // className="form-control"
                                name="delivery"
                                placeholder="Enter Medicine delivery conditions (if any)"
                                value={x.delivery}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />

                              <input
                                // className="form-control"
                                name="stock"
                                placeholder="Enter Medicine stock available"
                                value={x.stock}
                                onChange={(e) => this.handleInputChange(e, i)}
                                style={styless.inputBoxStyle}
                              />
                            </div>

                            <input
                              className="form-control"
                              name="misc"
                              placeholder="Enter misc info"
                              value={x.misc}
                              onChange={(e) => this.handleInputChange(e, i)}

                            />


                            <div className="btn-box">
                              {this.state.inputList.length !== 1 && (
                                <Button
                                  variant="secondary" className="mr10" onClick={() => this.handleRemoveClick(i)}>
                                  Remove
                                </Button>
                              )}
                              {this.state.inputList.length - 1 === i && (
                                <Button onClick={this.handleAddClick} variant="primary">Add more medicines</Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ marginTop: 20 }}>{JSON.stringify(this.state.inputList)}</div>

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.handleClose();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={this.state.modelLoading}
                    variant="primary"
                    onClick={async () => {
                      // this.setState({
                      //   selectedSpeciality: {
                      //     speciality: values.speciality.trim(),
                      //     id: this.state.selectedSpeciality.id,
                      //   },
                      // });
                      // submitForm();

                      console.log(this.state.inputList)
                      let newDetails = []
                      this.state.inputList.map((x) => {
                        console.log('mapping htis')
                        console.log(x.name)
                        if (x.medicineId == '' || x.medicineId == undefined) console.log('this cant be added')
                        else newDetails.push(x)
                      })
                      console.log('mapping htis')
                      console.log(this.state.selectedSpeciality)
                      // addNewMedicines
                      let API = new Apilib();

                      // let pharmacyDocRef = firebaseAdmin.firestore().collection('pharmacy').doc(id);



                      // let gotData = await firestore
                      //   .collection("pharmacy")
                      //   .doc(this.state.selectedSpeciality.id).get();

                      // let newArray = gotData.data().pharmacy[0].medicines

                      // this.state.inputList.map((x) => {
                      //   newArray.push(x)
                      // })

                      // console.log('newArray')
                      // console.log(newArray)


                      // console.log('gotData')
                      // console.log(gotData)
                      // console.log('gotData')
                      // console.log(gotData.data().pharmacy[0].medicines)

                      this.setState({ loading: true })
                      API.addNewMedicines({ id: this.state.selectedSpeciality.id, data: newDetails }).then((result) => {
                        let res = result.data;
                        // if (!res.ok) {
                        //   let msg = await res.json();
                        //   throw new Error(msg.message);
                        // }


                        this.setState({ loading: false })
                        console.log('this is response')
                        console.log(res)
                        Notify({
                          alert: true,
                          type: "success",
                          title: "Medicines Added successfully",
                        });

                      }).catch((e) => {
                        this.setState({ loading: false })
                        console.log('some error', e)
                        Notify({
                          alert: true,
                          type: "error",
                          title: "Some error while adding medicines",
                        });
                      });


                    }}
                  >
                    Add medicines
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
        <Modal
          backdrop="static"
          show={this.state.confirmModalOpen}
          onHide={() => this.handleConfirmModalClose()}
          animation={false}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Delete Pharmacy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete{" "}
              <b>{this.state.selectedSpeciality.speciality}</b>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleComfirmModalClose();
              }}
            >
              Close
            </Button>
            <Button
              disabled={this.state.confirmModelLoading}
              variant="danger"
              onClick={this.handleSpecialityDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}
