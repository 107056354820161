/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import csc from "country-state-city";
import { useFormik } from "formik";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseSelect from "react-select/creatable";
import * as Yup from "yup";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import { useStateValue } from "../../StateProvider";
import Loader from "../Loader/Loader";
import "./style.css";
import { Modal } from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GoogleMap from "../Agora/utils/GoogleMap";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const EditProfileHospital = (props) => {
  const profilePictureRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [change_pp, setChange_pp] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [geoLocation, setGeoLocation] = useState();
  const [geoLatLng, setGeoLatLng] = useState();
  const [cities, setCities] = useState([]);
  const [allPharmacies, setAllPharmacies] = useState([]);
  const [preferredPharmacies, setPreferredPharmacies] = useState([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [pp_url, setpp_url] = useState("");
  const [country_id, setCountry_id] = useState("");
  const [state_id, setState_id] = useState("");
  const [city_id, setCity_id] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [preferredPharmacyId, setPreferredPharmacyId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [statePharmacies, setStatePharmacies] = useState([]);
  const [{ userPP }, dispatch] = useStateValue();

  const formik = useFormik({
    initialValues: {
      firstName: null,
      middleName: null,
      lastName: null,
      emergencyContactDetails: null,
      geoLocation: null,
      emergencyContactName: null,
      height: null,
      weight: null,
      address: null,
      postalcode: null,
      dateOfBirth: null,
      insuranceNumber: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "First Name should contain alphabets and may contain '"
        )
        .label("First Name"),
      middleName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Middle Name should contain alphabets and may contain '"
        )
        .label("Middle Name"),
      lastName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Last Name should contain alphabets and may contain '"
        )
        .label("Last Name"),
      dateOfBirth: Yup.date(),
      emergencyContactName: Yup.string()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Name should contain alphabets and may contain '"
        )
        .label("Emergency Name"),
      emergencyContactDetails: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(8)
        .label("Emergency Contact no"),
      height: Yup.number(),
      weight: Yup.number(),
      address: Yup.string().required().min(3).label("Address"),
      postalcode: Yup.string()
        .required()
        .min(4)
        .matches(/^[0-9]+$/, "Must be only digits")
        .label("Postal Code"),
      insuranceNumber: Yup.number(),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const makeDownloadUrl = async (path) => {
    if (!path) return
    setLoading(true);
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    let url_1 = null;
    try {
      url_1 = await gsReference.getDownloadURL();
      // const url_1 = path
      console.log("profile url", url_1, path);
      setpp_url(url_1);
      dispatch({
        type: "SET_USER_PP",
        url: url_1,
      });
      setChange_pp(false);
      setLoading(false);
    } catch (err) {
      setChange_pp(true);
      setLoading(false);
      Notify({
        alert: true,
        type: "error",
        title: err.message,
      });
    }
    if (url_1) return url_1
  };

  useEffect(() => {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(true);
        API.getUserDetails(user.displayName, user.uid)
          .then(async (result) => {
            let res = result.data
            let path = res?.user?.profile_picture
            console.log("ressss", res.user, path);
            // await makeDownloadUrl(path);
            if (path) setpp_url(path)
            setLoading(false);
            setUserDetails(res.user);
            setGeoLocation(res.user.geoLocation);
            props.updateUserData(res.user);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });

        API.getPharmacies()
          .then((result) => {
            let res = result.data

            console.log("Pharm data", res.data);
            setStatePharmacies(res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        props.history.push("/login");
      }
    });
    let data = csc.getAllCountries();
    setCountries(data);
    setAllPharmacies();
  }, []);

  useEffect(() => {
    if (userDetails)
      formik.setValues({
        hospital_chain: userDetails.hospital_chain,
        hospital_name: userDetails.hospital_name || "",
        hospital_code: userDetails.hospital_code,
        hospital_regno: userDetails.hospital_regno,
        address: userDetails.address,
        emergencyContactDetails: userDetails.emergencyContactDetails,
        emergency_contact: userDetails.emergency_contact,
        geoLocation: userDetails.geoLocation,
        height: !!userDetails.height ? userDetails.height.split(" ")[0] : "",
        weight: !!userDetails.weight ? userDetails.weight.split(" ")[0] : "",
        pincode: userDetails.pincode,
        dateOfBirth: userDetails.dateOfBirth || "",
        insuranceNumber: userDetails.insuranceNumber || "",
      });
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      for (let key of countries) {
        if (userDetails.country && key.name === userDetails.country) {
          setCountry_id(key.id);
          let stateData = csc.getStatesOfCountry(key.id);
          setStates(stateData);
        }
      }

      if (userDetails?.preferredPharmacies) {
        setPreferredPharmacies(userDetails?.preferredPharmacies);

        let tempArr = userDetails?.preferredPharmacies;
        tempArr[0].label = tempArr[0].name;
        tempArr[0].value = tempArr[0].name;

        setSelectedPharmacies(tempArr);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      for (let key of states) {
        if (userDetails.State && key.name === userDetails.State) {
          setState_id(key.id);
          let cityData = csc.getCitiesOfState(key.id);
          setCities(cityData);
        }
      }
    }
  }, [states]);

  useEffect(() => {
    if (userDetails) {
      for (let key of cities) {
        if (userDetails.city && key.name === userDetails.city) {
          setCity_id(key.id);
        }
      }
    }
  }, [cities]);

  useEffect(() => {
    for (let key of preferredPharmacies) {
      console.log("Keys", key);
      if (key.name === userDetails?.preferredPharmacy.name) {
        setPreferredPharmacyId(key.id);
      }
    }
    return () => { };
  }, []);

  const handleUpload = (e) => {
    e.preventDefault();
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl(1);
    if (PP.state.status == "EMPTY") {
      return
    }
    console.log("file", PP);

    const ref = storage.ref();
    if (imageAsDataURL) {
      const name = userDetails.uid;
      const path = "profile_images/patients/" + name;
      setpp_url(imageAsDataURL);
      const task = ref.child(path).putString(imageAsDataURL, "data_url");
      task
        .then(async () => {
          let picDownloadLink = await makeDownloadUrl(path).catch(e => console.log(e));
          setpp_url(picDownloadLink);
          setLoading(true);
          setError("");
          let API = new Apilib();
          let data = {
            ...userDetails,
            type: 2,
            uid: props.user.uid,
            geoLocation: geoLocation,
            profile_picture: picDownloadLink,
            ...geoLatLng
          };

          API.updateUser(data)
            .then(async (result) => {
              let res = result.data

              console.log('this is res from hospital image')
              console.log(res)
              console.log('this is res from hospital image')
              setLoading(false);

              // if (res.ok) {
              setLoading(false);
              setChange_pp(false);
              // await makeDownloadUrl(path);
              Notify({
                alert: true,
                type: "success",
                title: "Profile picture uploaded successfully!",
              });
              // } else {
              //   res.text().then((data) => {
              //     setError(JSON.parse(data).message);
              //     setChange_pp(false);
              //     Notify({
              //       alert: true,
              //       type: "error",
              //       title: JSON.parse(data).message,
              //     });
              //   });
              // }
            })
            .catch((error) => {
              setError(error.message);
              setChange_pp(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
          setChange_pp(false);
          Notify({
            alert: true,
            type: "error",
            title: err.message,
          });
        });
    }
  };

  const onChange = (e) => {
    let data = { ...userDetails };
    // for date, e will be directly the date value, thus e.target will be undefined. PS. sorry for bad code.
    if (e.target === undefined) {
      data["dateOfBirth"] = e;
      setUserDetails(data);
      return;
    }
    let inputName = e.target.name;
    let value = "";

    switch (inputName) {
      case "country":
        console.log("country", countries);
        for (let key of countries) {
          if (key.id === e.target.value) {
            value = key.name;
            setCountry_id(e.target.value);
          }
        }
        let stateData = csc.getStatesOfCountry(e.target.value);
        setStates(stateData);
        setState_id("");
        setCity_id("");
        break;

      case "State":
        for (let key of states) {
          if (key.id === e.target.value) {
            value = key.name;
            setState_id(e.target.value);
          }
        }
        let cityData = csc.getCitiesOfState(e.target.value);
        setCities(cityData);
        setCity_id("");
        break;

      case "city":
        for (let key of cities) {
          if (key.id === e.target.value) {
            value = key.name;
            setCity_id(e.target.value);
          }
        }
        break;

      case "preferredPharmacy":
        if (preferredPharmacies.length > 0) {
          for (let key of preferredPharmacies) {
            if (parseInt(key.pharmacyId) === e.target.value) {
              value = key;
              setPreferredPharmacyId(e.target.value);
            }
          }
        }
        break;

      // no default
    }
    if (
      inputName === "country" ||
      inputName === "State" ||
      inputName === "city" ||
      inputName === "preferredPharmacy"
    ) {
      data[inputName] = value;
    } else if (inputName === "weight") {
      data[inputName] = e.target.value + " kg";
    } else if (inputName === "height") {
      data[inputName] = e.target.value + " cm";
    } else {
      data[inputName] = e.target.value;
    }
    setUserDetails(data);
  };

  const onSelect = (val) => {
    let formObj = { ...userDetails };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val);
    let arr = [];
    if (val && val.length) {
      val.map((value, index) => {
        arr.push(value.value);
      });
    }
    formObj["allergies"] = arr;
    setUserDetails(formObj);
    // console.log("formdata", formObj, val);
  };

  const onSelectPharmacy = (val) => {
    let formObj = { ...userDetails };
    let preferredPharmaciesMenu = [];

    if (val && val.length) {
      val.map((pharm, i) => {
        preferredPharmaciesMenu.push(pharm);
      });
    }

    setSelectedPharmacies(preferredPharmaciesMenu);
    setPreferredPharmacies(preferredPharmaciesMenu);
    formObj["preferredPharmacies"] = preferredPharmaciesMenu;
    setUserDetails(formObj);
  };
  const customInput = (props) => {
    return (
      <input
        type="text"
        className="form-control"
        onChange={props.inputProps.onChange}
        placeholder={props.placeholder}
        value={props.value}
        onClick={props.onClick}
      />
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // if (!formik.isValid) return;
    setError("");
    setLoading(true);

    let API = new Apilib();
    let data = {
      ...userDetails,
      type: 2,
      uid: props.user.uid,
      geoLocation: geoLocation,
      ...geoLatLng
    };

    console.log("dataaa", data)
    delete data.loading;
    delete data.error;
    delete data.success;
    API.updateUser(data)
      .then((result) => {
        let res = result.data
        setLoading(false);
        // if (res.ok) {
        setLoading(false);
        setUserDetails(null);
        setError("");
        setSuccess(true);
        props.history.push("/my-profile");
        Notify({
          alert: true,
          type: "success",
          title: "Profile updated successfully!",
        });
        // } else {
        //   res.text().then((data) => {
        //     setError(JSON.parse(data).message);
        //   });
        // }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  let allBloodGroups = [
    {
      id: "1",
      name: "A+",
    },
    {
      id: "2",
      name: "A-",
    },
    {
      id: "3",
      name: "B+",
    },
    {
      id: "4",
      name: " B-",
    },
    {
      id: "5",
      name: "AB+",
    },
    {
      id: "6",
      name: "AB-",
    },
    {
      id: "7",
      name: "O+",
    },
    {
      id: "8",
      name: "O-",
    },
  ];
  let arr = [
    "Brazil nuts",
    "Almonds",
    "Cashews",
    "Macadamia nuts",
    "Pistachios",
    "Pine nuts",
    "Walnuts",

    "Cow’s Milk Allergies",

    "Milk",
    "Milk powder",
    "Cheese",
    "Butter",
    "Margarine",
    "Yogurt",
    "Cream",
    "Ice cream",

    "Shellfish Allergies",

    "Shrimp",
    "Prawns",
    "Crayfish",
    "Lobster",
    "Squid",
    "Scallops",

    "Peanuts Allergies",

    "Eggs Allergies",

    "Fish Allergies",

    "Soy Allergies",

    "Dog Allergies",

    "Pollen Allergies",

    "Pet Hair Allergies",

    "Dust Mites Allergies",

    "Insect bites Allergies",

    "Wheat Allergies",

    "  Antibiotic Allergies",
  ];
  let allegies = arr.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  let bloodGroup = null;
  if (allBloodGroups.length !== 0) {
    bloodGroup = allBloodGroups.map((item) => {
      return (
        <MenuItem key={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      );
    });
  }

  let allergies = [];
  if (userDetails) {
    if (userDetails.allergies && userDetails.allergies.length) {
      userDetails.allergies.map((allergy, i) => {
        allergies.push({
          value: allergy,
          label: allergy,
        });
      });
    }
  }
  let button = change_pp ? (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleUpload(e);
      }}
      className="btn custom-btn text-white"
      style={{ backgroundColor: "#31d4eb" }}
    >
      Save Image
    </button>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();
        setChange_pp(true);
      }}
      className="btn custom-btn btn-primary"
      style={{ backgroundColor: "#31d4eb" }}
    >
      Change Profile Pic
    </button>
  );

  let countriesMenu = null;
  if (countries && countries?.length !== 0) {
    countriesMenu = countries.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let statesMenu = null;
  if (states && states?.length !== 0) {
    statesMenu = states.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let citiesMenu = null;
  if (cities && cities?.length !== 0) {
    citiesMenu = cities.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let pharmaciesMenu = [];
  if (statePharmacies?.length > 0) {
    if (userDetails?.preferredPharmacy) {
      statePharmacies.filter((pharm) => {
        if (userDetails?.preferredPharmacy.pharmacyId === pharm.pharmacyId) {
          return false;
        } else {
          return true;
        }
      });
    }
    pharmaciesMenu = statePharmacies.map((pharm) => {
      return {
        ...pharm,
        value: pharm.name,
        label: pharm.name + (pharm.location ? ` - ${pharm.location}` : ""),
      };
    });
  }

  let preferredPharmacyMenu = null;
  if (preferredPharmacies?.length > 0) {
    preferredPharmacyMenu = preferredPharmacies.map((item, index) => {
      return (
        <MenuItem key={index} value={parseInt(item.pharmacyId)}>
          {item.name}
          {item.location ? ` - ${item.location}` : ""}
        </MenuItem>
      );
    });
  }

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Pharmacy in Preference List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            required
            placeholder="Enter Pharmacies"
            onChange={(item) => onSelectPharmacy(item)}
            value={selectedPharmacies}
            options={pharmaciesMenu}
            noOptionsMessage={() => "Enter pharmacy and press enter"}
            isMulti={true}
            closeMenuOnSelect={false}
          />
        </Modal.Body>
      </Modal>

      {loading && <Loader />}
      <div className="page-header profile-header">
        <div className="content profile-content edit-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="page-title about-doctor">Edit Profile</h4>
              </div>
            </div>
            {userDetails ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    onSubmit={(e) => {
                      if (
                        document.activeElement.classList.contains(
                          "eXEoGG26iwSi6UiZD8FI6"
                        )
                      ) {
                        e.preventDefault();
                        return;
                      }
                      onSubmit(e);
                    }}
                  >
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        {error}
                      </div>
                    )}
                    {success && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        Profile Updated successfully
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12 row p-0 m-0">
                        <div className="col-sm-3">
                          <div
                            className="form-group"
                            style={{ textAlign: "center" }}
                          >
                            {change_pp ? (
                              <ProfilePicture
                                minImageSize={0}
                                ref={profilePictureRef}
                                useHelper={true}
                                frameFormat="rounded-square"
                                image={pp_url != "" ? pp_url : ""}
                              />
                            ) : (
                              <img className="Profile_pic" src={pp_url} />
                            )}
                            {button}
                          </div>
                        </div>
                        <div className="col-sm-9 row pr-0 m-0">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Hospital Chain Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="hospital_chain"
                                value={userDetails.hospital_chain}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "hospital_chain",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.hospital_chain}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Hospital Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="hospital_name"
                                value={userDetails.hospital_name}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "hospital_name",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.hospital_name}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Hospital Code <span className="text-danger">*</span>
                              </label>
                              <input
                                name="hospital_code"
                                value={userDetails.hospital_code}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "hospital_code",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                readOnly
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.hospital_code}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Registration Number</label>
                              <input
                                name="hospital_regno"

                                value={userDetails.hospital_regno}
                                onChange={(e) => onChange(e)}

                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Address</label>
                              <input
                                name="address"

                                value={userDetails.address}
                                onChange={(e) => onChange(e)}

                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>City/State/Country</label>
                              <GoogleMap
                                style={{ width: 100 }}
                                value={geoLocation}
                                onChange={(e) => {
                                }}
                                setGeoLocation={(LatLng) => {
                                  console.log("Place API", LatLng)
                                  setGeoLatLng(LatLng)
                                }}
                                getAddress={(address) => {
                                  console.log("getAddress API", address)
                                  setGeoLocation(address);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Pincode</label>
                              <input
                                name="pincode"

                                value={userDetails.pincode}
                                onChange={(e) => onChange(e)}

                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Contact Number</label>
                              <input
                                name="emergency_contact"

                                value={userDetails.emergency_contact}
                                onChange={(e) => onChange(e)}

                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                name="email"
                                readOnly
                                value={userDetails.email}
                                onChange={(e) => onChange(e)}
                                disabled
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>




                        </div>
                      </div>

                      {/* <div className="col-sm-12 row">
                                                        
                                                    </div> */}










                    </div>
                    <div className="m-t-20 text-center">
                      <Link to="/my-profile">
                        <button className="btn custom-btn btn-secondary mr-3">
                          Close
                        </button>
                      </Link>
                      <button
                        className="btn custom-btn btn-primary"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditProfileHospital;
