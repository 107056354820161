import { createPlugin } from "@fullcalendar/core";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import { Formik } from "formik";
import "jquery/dist/jquery.min.js";
import React, { Component, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { default as Api, default as Apilib } from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";


const PluginHospital = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [pluginApiKey, setPluginApiKey] = useState([]);


  useEffect(() => {
    PluginApi();
  }, []);


  function PluginApi() {
    setLoading(true);
    let API = new Apilib();
    API.PluginApiKey()
      .then((result) => {
        let res = result.data;
        console.log("PluginApiKey", res);
        setPluginApiKey(res.data);

        // setSelectedKey(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }


  function AddToPlugin(formikValues, formikHelpers) {
    // let hospitalId = props.userData.hospitalId
    console.log("API1")
    console.log("API14", props,)
    setError();
    setLoading();
    let data = {
      domainName: formikValues.domainName,
      userData: {
        hospitalId: props.userData.hospitalId,
        type: props.userData.type
      }
    };
    console.log("API13", data)
    let API = new Apilib();
    for (const key of pluginApiKey) {
      if (key.hospitalId == props.userData.hospitalId && key.api_key != ""
      ) {
        console.log("API144", key)
        return Notify({
          type: "error",
          alert: true,
          title: "Busissness Plugin is already added",
        });
      }

    }
    API.createApiToken(data)
      .then((res) => {
        console.log("API2", res.data)
        setLoading(false);
        PluginApi()
        setDomainName();
        Notify({
          type: "success",
          alert: true,
          title: "Busissness Plugin added successfully",
        });
        formikHelpers.resetForm();
        PluginApi()

      })
      .catch((error) => {
        setLoading(false);
      },
        () => {
          Notify({
            type: "error",
            alert: true,
            title: error.message,
          });
        });

    // API.createApiToken(data)

  };
  return (
    <div>
      {loading && <Loader />}
      <div className="row">
        <div className="col-sm-4 col-3">
          <h4 className="page-title">Activate Plugin</h4>
        </div>
      </div>
      {error && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Close</span>
          </button>

        </div>
      )}

      {success && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Close</span>
          </button>
          Plugin activated successfully
        </div>
      )}

      <div className="row">
        <div className="col-md-12">
          <Formik
            validationSchema={Yup.object().shape({
              domainName: Yup.string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[.com]+&?)?$/,
                  'Enter correct url!'
                )
                .required('Please enter website'),

            })}
            onSubmit={AddToPlugin}
            initialValues={{ domainName: "" }}

          >
            {({ handleSubmit, values, errors, handleChange, setErrors }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    Business Domain Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        className="form-control"
                        name="domainName"
                        value={values.domainName}
                        onChange={handleChange("domainName")}
                        type="text"
                        //   onBlur={() => {
                        //     if (!!values.domainName === false)
                        //       setErrors({ domainName: "" });
                        //   }}
                        placeholder="Enter Buissness Domain Name"
                      />
                      <small className="form-text text-danger">
                        {errors?.domainName}
                      </small>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-left pt-2 pt-sm-0">
                        <button className="btn btn-primary submit-btn">
                          Create Buissness Plugin
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6 form-group d-flex align-items-end"></div> */}
              </form>
            )}
          </Formik>

          <div className="table-responsive">
            <table className="table table-border table-striped custom-table datatable m-b-0">
              <thead>
                <tr>
                  <th>Domain Name</th>
                  <th>Business Key</th>
                  <th>Plugin Key</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody style={{ overflowY: "scroll" }}>
                {pluginApiKey &&
                  pluginApiKey.map((domain) => {
                    if (domain.api_key && domain.hospitalId == props.userData.hospitalId) {
                      return (
                        <tr>
                          <td className="UserName">{domain?.domain_Name}</td>
                          {/* <td></td> */}
                          <td>
                            <textarea
                              rows="2" cols="30"
                              value={domain?.hospitalId}
                            />

                          </td>
                          <td>
                            <textarea
                              rows="4" cols="50"
                              value={domain?.api_key}
                            />

                          </td>
                          <td>
                            <div
                            // onClick={() => {
                            //   PluginStat(domain);
                            // }}
                            >
                              {domain?.api_key_activated
                                ? "Active"
                                : "Inactive"}
                            </div>
                          </td>

                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>

        </div>


      </div>
      {/* )} */}
    </div>
  );
};

export default PluginHospital;
