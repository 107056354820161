import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';
// import { getApptData } from './data';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Apilib from "../../api";
import { useHistory } from 'react-router-dom';
import Notify from '../../Notify';

const Container = styled.div`
  align-items: center;
  padding: 20px;
    border: 1px solid #000;
  @media (max-width: 100%) {
    padding: 10px;
  }
`;


const Sidebar = styled.div`
  background-color: #00A1BA;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left:0px;
  
`;

const SidebarHeading = styled.h2`
  margin-bottom: 20px;
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
`;

const SidebarOptionText = styled.span`
  margin-left: 10px;
`;

const Content = styled.div`
  
  padding: 20px;
  width:100%;
  
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #009FB8;
  color: #fff;
  margin: 10px 10px;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;
const ButtonNormal = styled.button`
  background-color: #009FB8;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:25%
`;

const Form = styled.form`
  width: 100%;
   max-width: 95%;
`;

const Heading = styled.h2`
  color: #00A1BA;
  margin-bottom: 10px;
  text-align: left;
`;

const SubNote = styled.p`
  color: #666;
  margin-bottom: 5px;
  margin-left:5px;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width:100%
`;

const Label1 = styled.label`
  color: #333;
  
  margin-bottom: 10px;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 95%;
  padding: 18px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color:#EAFCFF;
  justify-content: flex-end;
`;

const Containers = styled.div`
width:50%;
  display: inline-grid;
  align-items: center;
`;

const Div1 = styled.div`
  width: 95%;
`;

const Div2 = styled.div`
  width: 95%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content:flex-start;
  color: #333;
  margin-bottom: 3px;
  align-items:center;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled(Button)`
  background-color: #50d8fa;
`;


const Field = styled.div`
  margin-bottom: 16px;
`;

function GenerateAuthority_script_number(n) {
  return (n - 1) % 9 + 1;
}

const InvoicePage = (route) => {

  const [loading, setloading] = useState(false);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [date, setDate] = useState(new Date());
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [patientList, setPatientList] = useState(null)
  const [medicineList, setmedicineList] = useState(null)
  const history = useHistory();

  useEffect(() => {
    let API = new Apilib();
    console.log(route, 'this is route')


    let data = { pharmacyId: route.userData.uid }
    API.getAllMedicines(data)
      .then(async (res) => {
        console.log(res.data.medicines[0].medicines)
        console.log('this is response medicines')

        let allMed = res.data.medicines[0].medicines.map(x => {
          let details = x
          details.itemName = x.Medicine_Name
          return details
        })
        // setinvoiceData(invoiceDataDb)
        console.log(allMed)
        setmedicineList(allMed)
      })
      .catch((error) => {
        console.log('error', error)
      });



    API.getUsers(5, route.userData.hospitalId)
      .then((result) => {
        let res = result.data
        console.log(res);

        let sorted = res.patients;
        sorted.sort((a, b) => {
          return b.created_at - a.created_at;
        })
        setPatientList(sorted)


      })
      .catch((error) => {
        // this.setState({
        //     loading: false,
        // });
      });
  }, [])


  const handleObjectChange = (event) => {
    const objectId = event.target.value
    console.log(objectId)
    const selected = patientList.find((obj) => obj.uid === objectId);
    console.log(selected)
    setSelectedPatient(selected);

    setAddress(selected.address);
    setCity(selected.geoLocation)
    // address, Patient_Medicare_No

  };

  const handleAddBox = () => {
    const values = [...inputBoxes];
    values.push({
      'itemName': '',
      'itemDescription': '',
      'quantity': '',
      'Price': '',
    });
    setInputBoxes(values);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };


  useEffect(() => {
    let newDate = date
    setDate(newDate)
  }, [date])



  const reusableInputState = (label, valueVar, onChaneVar, type) => {
    return <Containers>
      <Div1>
        <Label>{label}</Label>
      </Div1>
      <Div2>
        <Input type={type} value={valueVar} onChange={onChaneVar} />
      </Div2>
    </Containers>
  }

  const reusableDivState = (label) => {
    return <Div1>
      <Label>{label}</Label>
    </Div1>
  }

  const handleInputChange = (index, event, field) => {

    console.log(inputBoxes)
    const values = [...inputBoxes];
    values[index][field] = event.target.value;
    setInputBoxes(values);
  };

  const [inputBoxes, setInputBoxes] = useState([{
    'itemName': '',
    'itemDescription': '',
    'quantity': '',
    'Price': '',
  }]);

  const [selectedMedicines, setSelectedMedicines] = useState(null);


  const handleObjectChangeMedicines = (event) => {
    const objectId = event.target.value

    console.log(objectId)
    if (typeof objectId == "undefined") return
    console.log(objectId)
    const selected = medicineList.find((obj) => obj.itemName === objectId);
    console.log(selected)
    selected.itemName = selected.Medicine_Name
    setSelectedMedicines(selected);
    setInputBoxes([...inputBoxes, selected])

    let API = new Apilib();

    // API.getOrderDetails(data)
    //   .then(async (res) => {
    //     console.log(res)
    //     console.log('this is response')
    //     console.log(res.data.invoiceList)
    //     console.log('pharmacy data')

    //     setPharmacyInvoices(res.data.invoiceList)
    //     // setpharmacyData(res.data.invoices)
    //     // { Prescription: 'Cap. DDR 30mg ', Last_Refilled: "21 June 2023", Next_Refill: '20 september 2023', Prescriber: 'John Smith', Order_Status: 'Ready For Pickup' }
    //     // setmedicationManagement



    //   })
    //   .catch((error) => {
    //     console.log('error', error)
    //   });

  };



  return (
    <Container>
      {loading && <Loader />}
      <div style={{ display: 'flex' }}>

        <div style={{ flex: 1, backgroundColor: '#EAFCFF', width: '100%' }}>
          <Content>

            <div style={{ border: 'solid #ccc', margin: '30px', padding: '30px', width: '95%', backgroundColor: '#fff' }} >


              <Heading>Add Invoice</Heading>
              <div style={{ border: 'solid #000 .05px' }}></div>

              <div>

                {patientList && <div style={{ justifyContent: 'center', display: 'grid' }}>
                  <select style={{ padding: '5px', margin: '5px' }} value={selectedPatient?.uid} onChange={handleObjectChange} required>
                    <option value="">Select a patient to create  prescription</option>
                    {patientList.map((obj) => (
                      <option key={obj.uid} value={obj.uid}>
                        {obj.firstName}
                      </option>
                    ))}
                  </select>

                </div>}


                {reusableInputState('Address:', address, (e) => setAddress(e.target.value), 'text')}
                {reusableInputState('City:', city, (e) => setCity(e.target.value), 'text')}
                {reusableInputState('Invoice Number:', invoiceNumber, (e) => setInvoiceNumber(e.target.value), 'number')}
                {/* {reusableInputState('Date :', date, (e) => setDate(e.target.value), 'text')} */}

                <Containers>
                  <Div1>
                    <Label>Date :</Label>
                  </Div1>
                  <Div2>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        inputVariant="outlined"
                        value={date}
                        onChange={(e) => {
                          console.log(e)
                          setDate(e)
                        }}
                        animateYearScrolling
                        name="dateOfBirth"
                        disablePast
                        format="dd/MM/yyyy"
                      />
                    </MuiPickersUtilsProvider>

                  </Div2>
                </Containers>

                {inputBoxes.map((inputState, index) => (
                  <div key={index} style={{ backgroundColor: '#EAFCFF', padding: '10px', margin: '5px', borderRadius: '11px' }}>

                    <Containers>
                      {reusableDivState('Item Name')}
                      <Div2>
                        <Input type="text" value={inputState.itemName} onChange={(event) => handleInputChange(index, event, 'itemName')} />
                      </Div2>
                    </Containers>
                    <Containers>
                      {reusableDivState('Item Description')}
                      <Div2>
                        <Input type="text" value={inputState.itemDescription} onChange={(event) => handleInputChange(index, event, 'itemDescription')} />
                      </Div2>
                    </Containers>
                    <Containers>
                      {reusableDivState('Quantity')}
                      <Div2>
                        <Input type="text" value={inputState.quantity} onChange={(event) => handleInputChange(index, event, 'quantity')} />
                      </Div2>
                    </Containers>
                    <Containers>
                      {reusableDivState('Price')}
                      <Div2>
                        <Input type="text" value={inputState.Price} onChange={(event) => handleInputChange(index, event, 'Price')} />
                      </Div2>
                    </Containers>
                  </div>))}


                <CheckboxLabel>
                  <Checkbox type="checkbox" checked={agreeTerms}
                    onChange={() => {
                      console.log(agreeTerms)
                      setAgreeTerms(!agreeTerms)
                    }} />
                  Agree with the terms and conditions
                </CheckboxLabel>

              </div>
              <ButtonGroup>

                {medicineList && <div >
                  <select style={{ padding: '11px', margin: '5px', borderRadius: '5px', }} value={selectedMedicines?.uid} onChange={handleObjectChangeMedicines} required>
                    <option value="">Select medicine to add</option>
                    {medicineList.map((obj) => (
                      <option key={Math.random()} value={obj.Medicine_Name}>
                        {obj.Medicine_Name}
                      </option>
                    ))}
                  </select>

                </div>}

                <Button onClick={handleAddBox}>Add new medicine</Button>
                <Button style={{ fontWeight: '400', fontSize: '16px' }}
                  onClick={async () => {

                    if (selectedPatient == null) {
                      alert('Please select a patient first')
                      return
                    }

                    if (!agreeTerms) {
                      alert('Please agree to the terms and conditions')
                      return
                    }

                    // https://australia-southeast1-medvatic-438af.cloudfunctions.net/pharmacy/test
                    let API = new Apilib();

                    // Batch_Number: "51263"
                    // Dose: "NA"
                    // ExpDate: "2023-10-31T06:38:00.000Z"
                    // Medicine_Name: "Digital thermometer"
                    // MfgDate: "2023-10-01T06:38:00.000Z"
                    // Price: "150"
                    // Quantity: "10"
                    // Units: "5"
                    // imageLink: "https://5.imimg.com/data5/SELLER/Default/2021/10/JA/WR/YY/47022212/clinical-digital-thermometer.jpg"
                    // pharmacyId: "KAoOnOt37RMTwAxsr6sqi1aOler1"
                    // qty: 1
                    // type: "Device"

                    let filteredData = inputBoxes.map(x => {
                      return {
                        Medicine_Name: x.itemName,

                        Batch_Number: 'NA',
                        Dose: "NA",
                        Units: "NA",
                        ExpDate: "2024-10-31T06:38:00.000Z",
                        // MfgDate: "2023-10-01T06:38:00.000Z",
                        imageLink: "https://images.pexels.com/photos/3873145/pexels-photo-3873145.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                        pharmacyId: route.userData.uid,
                        qty: x.quantity,
                        type: "NA",
                        itemName: x.itemName,
                        itemDescription: x.itemDescription,
                        quantity: x.quantity,
                        Price: x.Price
                      }
                    })

                    let data = {
                      name: fullName,
                      address: address,
                      city: city,
                      invoiceNumber: invoiceNumber,
                      date: date,
                      invoice: { invoiceMedicines: filteredData },
                      userData: selectedPatient,
                      uid: route.userData.uid
                    }


                    console.log(data)
                    setloading(true)
                    API.createInvoice(data)
                      .then(async (res) => {
                        console.log(res)
                        console.log('this is response')
                        setloading(false)
                        // Notify
                        Notify({
                          alert: true,
                          type: "success",
                          title: `Invoice saved successfully`,
                        });
                        setInputBoxes([{
                          'itemName': '',
                          'itemDescription': '',
                          'quantity': '',
                          'Price': '',
                        }])
                      })
                      .catch((error) => {
                        setloading(false)
                        console.log('error', error)
                      });

                  }}
                >
                  Save Invoice</Button>
                <Button style={{ fontWeight: '400', fontSize: '16px', backgroundColor: '#ccc', textDecorationColor: '#000' }}
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancel</Button>
              </ButtonGroup>
            </div>
          </Content>
        </div>
      </div >
    </Container >
  );
};

export default InvoicePage;
