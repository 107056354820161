import React, { useState, useEffect } from 'react';
import Apilib from "../../api";
import Loader from '../Loader/Loader';
import { Link } from "react-router-dom";
import order from '../../assets/img/orderImg.png'
import moment from "moment";
import { useHistory, Redirect, useLocation } from 'react-router-dom'

const OrderHistory = (route) => {
    // getPatientOrders
    const history = useHistory();

    const [orderData, setOrderData] = useState(null)
    console.log(route.userData.uid, 'route.userData.uid')
    let API = new Apilib();
    // setloading(true)
    useEffect(() => {
        API.getPatientOrders({ uid: route.userData.uid })
            .then(async (res) => {
                console.log(res)
                let ml = []
                if (!res.data.orders.length) return
                res.data.orders.map(x => {
                    console.log(x, 'objobjobjobj')
                    let sorting = x.prescription
                    let addrs = null
                    if (x.deliveryAddrs == "normal") {
                        let newaddrs = {
                            citystatecountry: route.userData?.geoLocation || "NA",
                            email: route.userData?.email || "NA",
                            name: route.userData?.firstName + route.userData?.lastName || "NA",
                            phone: route.userData?.phoneno || "NA",
                            postCode: route.userData?.postalcode || "NA",
                            street: route.userData?.address || "NA"
                        }
                        addrs = newaddrs
                    } else addrs = route.userData?.newaddress[x.deliveryAddrs] || 'NA'
                    let obj = {
                        prescription: sorting,
                        createdAt: x.date,
                        deliveryAddrs: addrs,
                        orderId: x.orderId,
                        orderStatus: x.orderStatus
                    }
                    ml.push(obj)
                })
                console.log(ml, 'objobjobjobj')

                setOrderData(ml)
                // setproducts(res.data.medicines.medicines)
                // setproductsearch(res.data.medicines.medicines)
                // medicineList
            })
            .catch((error) => {
                console.log('error', error)
            });

        // sort and create object for each 
    }, []);

    if (orderData == null) return <Loader />
    else return (
        <div style={{ justifyContent: 'center', textAlign: '-webkit-center' }}>
            <div style={{ justifyContent: 'center', textAlign: '-webkit-center', fontSize: '21px', fontWeight: '600' }}>Order List</div>
            {orderData == null && <div style={{
                display: 'flex', width: '90%', justifyContent: 'space-between', margin: '5px', padding: '5px', alignItems: 'center', fontSize: '21px'
            }}>No order available</div>}
            {orderData && orderData.map(x => {
                let result = x.prescription.reduce(function (acc, obj) { return acc + (obj.Price * obj.qty); }, 0);
                return <div style={{
                    display: 'flex', width: '90%', justifyContent: 'space-between', margin: '5px', padding: '5px', border: 'solid 1px #000', borderRadius: '10px', alignItems: 'center'
                }}>
                    <img src={order} alt="order" style={{ width: '50px', height: '50px', }} />
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}> {x.prescription.length} Items</div>
                        <div style={{ fontSize: '11px', }}>  {`${x?.deliveryAddrs?.street ? x?.deliveryAddrs?.street : 'NA'} ${x?.deliveryAddrs?.citystatecountry ? x?.deliveryAddrs?.citystatecountry : 'NA'}`}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Order Date</div>
                        <div>{moment(x.date).format('YYYY-MMM-DD')}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Order ID </div>
                        <div>#{x.orderId}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Total Price</div>
                        <div>${result}</div>
                    </div>
                    <div style={{ width: '19%' }}>
                        <div style={{ fontSize: '16px', borderRadius: '5px', margin: '5px', padding: '5px', backgroundColor: '#00869a', cursor: 'pointer' }} onClick={() => {
                            // `/pharmacy/orders/order-details/${x.orderId}`
                            console.log('history pushing');
                            history.push({
                                pathname: `/OrderDetail/${x.orderId}`,
                                state: { orderId: x.orderId },
                            });
                        }}>Order History </div>
                        {/* <div style={{ fontSize: '16px', borderRadius: '5px', margin: '5px', padding: '5px', backgroundColor: '#26d4ff', cursor: 'pointer' }}>Order Track </div> */}
                    </div>
                    <br />

                </div>
            })}
        </div>
    );
}

export default OrderHistory;
